/**
 * (c) CareerLabs. All rights reserved.
 **/
import { STUDENT } from '../Action';
import { GET } from '../../axios/Axios';
import { DEFAULT_URL, _COOKIE } from '../../api/API';
import Cookies from 'js-cookie';
import { decrypt } from '../../utils/Crypto';
import { catchError } from '../../utils/methods/Helper';

export const getStudentInfo = id => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}`,
      {},
      response => {
        dispatch({
          type: STUDENT.getStudent,
          payload: response.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const userLogout = () => {
  return dispatch => {
    dispatch({
      type: STUDENT.userLogout,
    });
    Cookies.remove('accessToken', { path: '/', domain: _COOKIE.DOMAIN });
    Cookies.remove('studentId', { path: '/', domain: _COOKIE.DOMAIN });
    Cookies.remove('lmsaccessToken', { path: '/', domain: _COOKIE.DOMAIN });
    Cookies.remove('lmsstudentId', { path: '/', domain: _COOKIE.DOMAIN });
    Cookies.remove('refreshToken', { path: '/', domain: _COOKIE.DOMAIN });
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = '/auth';
  };
};

export const getClickSourceDetails = (clickSource, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/clsaclicks`,
      { clickSource: clickSource },
      response => {
        callback(response.data);
      },
      error => {
        callback(catchError(error));
      }
    );
  };
};
