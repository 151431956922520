/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Day,
  IconButton,
  LeftBox,
  RightText,
} from '../../../../assets/css/dashboard/CalendarComponents';
import {
  Landing,
  LandingSubTitle,
  LandingTitle as Month,
} from '../../../../assets/css/StyledComponent';
import CalendarIcon from '../../../../assets/icons/CalendarIcon.svg';
import leftIcon from '../../../../assets/images/leftIcon.png';
import rightIcon from '../../../../assets/images/rightIcon.png';
import { DAYS, MONTH } from '../../../../constant/Variables';
import { WebinarCard } from './WebinarCard';

function CalendarHeader({
  currentDate,
  handleCalendarClick,
  handleNextClick,
  handlePreviousClick,
  webinar,
  user,
  removeWebinar,
  onWebinarDelete,
  ...props
}) {
  let date = new Date(currentDate);

  return (
    <Landing>
      <LeftBox>
        <Day>{date.getDate()}</Day>
        <div className="middle-box">
          <Month>{MONTH[date.getMonth()]}</Month>
          <LandingSubTitle>{DAYS[date.getDay()]}</LandingSubTitle>
        </div>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridGap="10px"
          alignItems="center"
        >
          <div>
            <IconButton disableRipple onClick={handlePreviousClick}>
              <img src={leftIcon} alt="leftIcon" />
            </IconButton>
            <IconButton disableRipple onClick={handleNextClick}>
              <img src={rightIcon} alt="rightIcon" />
            </IconButton>
          </div>
        </Box>
      </LeftBox>
      <Box
        display="grid"
        alignItems="center"
        gridAutoFlow={'column'}
        gridGap={'24px'}
      >
        <WebinarCard
          data={{ ...webinar, date: date.getDate() }}
          user={user}
          removeWebinar={removeWebinar}
          onWebinarDelete={onWebinarDelete}
        />
        <RightText>View Monthly Study Plan</RightText>
        <IconButton disableRipple onClick={handleCalendarClick}>
          <img src={CalendarIcon} alt="" />
        </IconButton>
      </Box>
    </Landing>
  );
}

export default connect(() => {}, {})(withRouter(CalendarHeader));
