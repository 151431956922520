/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Header } from '../../../../assets/css/dashboard/Landing';
import { FlexFiller } from '../../../../assets/css/StyledComponent';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Body,
  ConceptTitle,
  DateLabel,
  ProgressIcon,
  TaskTitle,
  Time,
  TopicContent,
  TopicTitle,
  TypeIcon,
} from '../../../../assets/css/StyledDashboard';
import {
  TextIcon,
  TextVideoIcon,
  VideoIcon,
} from '../../../../assets/icons/DashboardIcons';
import StatusIcon from '../../../../assets/icons/StatusIcon';
import NoPendingTask from '../../../../assets/images/emptyPendingTopics.svg';
import { EVENTS } from '../../../../clevertap/Events';
import { MONTHS } from '../../../../constant/Variables';
import * as track from '../../../../mixpanel/Events';
import { routePaths } from '../../../../routes/RoutePath';
import clevertap from 'clevertap-web-sdk';

class PendingTopicCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTopic: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      track.pendingTopic(
        {
          'Product Name': JSON.parse(localStorage.getItem('userProduct'))
            .productName,
        },
        this.props.pendingTask
      );
    }
  }

  // Open close accordion
  handleAccordionClick = e => {
    if (parseInt(e.currentTarget.id) === this.state.activeTopic)
      this.setState({ activeTopic: null });
    else this.setState({ activeTopic: parseInt(e.currentTarget.id) });
  };

  // Icon colored based on the status
  getIcon = ({ type, status }) => {
    if (type === 'TEXT') return TextIcon(status);
    else if (type === 'VIDEO') return VideoIcon(status);
    else if (type === 'TEXT_VIDEO') return TextVideoIcon(status);
  };

  // Routing to task detail page
  handleTaskClick = (event, topicId, taskId, taskObj) => {
    event.stopPropagation();
    clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
      'Watched source': 'Via pending tasks - dashboard section',
    });
    clevertap.event.push(EVENTS.LMSUserClicksThePendingTasks, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
    });
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskId}&type=normal&prevPage=dashboard_pending_tasks`
    );
  };

  render() {
    const { t } = this.props;
    const { pendingTask } = this.props;

    if (pendingTask.length > 0) {
      let i = 0;
      return (
        <Container>
          <Header color={'#B14D59'}>{t('Pending Topics')}</Header>
          <Body>
            {pendingTask.map(({ date, topics }) => {
              return (
                <div>
                  <DateLabel>{date}</DateLabel>
                  {topics.map(item => {
                    i++;
                    return (
                      <Accordion
                        id={i}
                        onClick={this.handleAccordionClick}
                        square
                        expanded={this.state.activeTopic === i}
                      >
                        <AccordionSummary>
                          {this.state.activeTopic === i ? (
                            <RemoveOutlinedIcon className={'open-close-icon'} />
                          ) : (
                            <AddOutlinedIcon className={'open-close-icon'} />
                          )}
                          <TopicTitle>{item.topicName}</TopicTitle>
                          <FlexFiller />
                          <ConceptTitle>{item.conceptName}</ConceptTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item.tasks.map(task => {
                            return (
                              <TopicContent>
                                <ProgressIcon>
                                  <img src={StatusIcon(task.status)} alt="" />
                                </ProgressIcon>
                                <TypeIcon>
                                  {this.getIcon({
                                    status: task.status,
                                    type: task.type,
                                  })}
                                </TypeIcon>
                                <TaskTitle
                                  status={task.status}
                                  onFocus={event => event.stopPropagation()}
                                  aria-label="task"
                                  onClick={event =>
                                    this.handleTaskClick(
                                      event,
                                      item.id,
                                      task.id,
                                      {
                                        conceptName: item.conceptName,
                                        topicName: item.topicName,
                                        taskName: task.name,
                                      }
                                    )
                                  }
                                >
                                  {task.name}
                                </TaskTitle>
                                <FlexFiller />
                                <Time>{task.duration}</Time>
                              </TopicContent>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              );
            })}
          </Body>
        </Container>
      );
    }
    // Empty card
    else
      return (
        <Container>
          <Header color={'#B14D59'}>
            {t('No Pending Topics')}
            <Box fontSize={12} color={'#5C5C5C'} fontWeight={'normal'}>
              {new Date().getDate() +
                ' ' +
                MONTHS[new Date().getMonth()] +
                ' ' +
                new Date().getFullYear()}
            </Box>
          </Header>
          {/* <TopicHeader>
             <Box flex={1}>{t('No Pending Topics')}</Box>
             <Box>
               <Box fontSize={12} color={'#5C5C5C'} fontWeight={'normal'}>
                 {new Date().getDate() +
                   ' ' +
                   MONTHS[new Date().getMonth()] +
                   ' ' +
                   new Date().getFullYear()}
               </Box>
             </Box>
           </TopicHeader> */}
          <Body>
            <div className="empty-card">
              <img
                src={NoPendingTask}
                alt={'no pending task'}
                width="70%"
                height="70%"
              />
              <Box
                color={'#052A4E'}
                fontSize={16}
                textAlign={'center'}
                fontWeight={500}
              >
                No Pending Topics
              </Box>
            </div>
          </Body>
        </Container>
      );
  }
}

const mapStateToProps = state => {
  return {
    pendingTask: state.dashboardLandingReducer.pendingTopic,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PendingTopicCard)));
