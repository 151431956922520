/**
 * (c) CareerLabs. All rights reserved.
 **/

export const isValdAlpNumSpcUdscrHyph = value => {
  return (
    value !== undefined &&
    value !== null &&
    value.match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    )
  );
};
export const isEmpty = value => {
  return value === undefined || value === null || value.length === 0;
};

export const isMobilePhone = value => {
  return value !== undefined && value !== null && value.match(/^\d/);
};

export const isText = value => {
  return value !== undefined && value !== null && value.match(/^[a-zA-Z]+$/);
};
export const isOtp = value => {
  return value !== undefined && value !== null && value.match(/^\d{4}$/);
};
export const isPassword = value => {
  return (
    value !== undefined ||
    value !== null ||
    value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/)
  );
};

export const isEmailValid = value => {
  return (
    value !== undefined ||
    value !== null ||
    value.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
  );
};

export const isEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isNumber = value => {
  var pattern = /[^0-9]/g;
  return value.match(pattern);
};
export const getDate = fullDate => {
  if (fullDate) {
    let date = JSON.stringify(new Date(fullDate));
    date = date.slice(1, 11);
    return date;
  }
  return null;
};
export const isNumbers = evt => {
  return (
    (evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57
  );
};

export const formattedDate = date => {
  if (date) {
    var dateArray = date.split('-');
    var newDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
    return newDate;
  }
  return;
};
