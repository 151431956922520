/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import {
  Card,
  CardTime,
  SideHeading,
  Title,
  VideoDate,
  VideoHeading,
} from '../../../assets/css/practice/WebinarComponents';
import Player from '../../../utils/Player';
import VideoCard from './components/VideoCard';

function WatchVideo({ playVideo, recommended, handleWatchVideoClick }) {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <div className="videoWrapper">
            <Player
              otp={playVideo.videoOtp}
              playBackInfo={playVideo.videoPlaybackInfo}
            />
          </div>

          <Box pt={3} pb={1}>
            <VideoHeading>{playVideo.title}</VideoHeading>
          </Box>
          <VideoDate>{playVideo.date}</VideoDate>
        </Grid>
        <Grid item xs={3}>
          <SideHeading>Recommended Videos to watch</SideHeading>
          {recommended.map(post => (
            <Box p={1}>
              <Card>
                <VideoCard
                  src={post.videoUrl}
                  onClick={() => handleWatchVideoClick(post)}
                  otp={post.videoOtp}
                  playBackInfo={post.videoPlaybackInfo}
                />
                <Title>{post.title}</Title>
                <CardTime>{post.cardDate}</CardTime>
              </Card>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default WatchVideo;
