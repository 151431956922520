import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NoTask } from '../../../assets/css/StyledQuestionComponent';
import '../../../assets/css/subject/Landing.css';
import {
  Accordion,
  AccordionHead,
  Container,
  SideDiv,
} from '../../../assets/css/subject/LandingComponents';
import { getOfficialGuideDetails } from '../../../redux/action/Practice';
import PageLoader from '../../../utils/components/PageLoader';
import Calendar from '../calendar/Calendar';
import StrengthsCard from '../user/component/StrengthsCard';
import WeaknessCard from '../user/component/WeaknessCard';
import Concept from './Concept';
import Topic from './Topic';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      activeConcept: null,
      activeTopic: null,
      data: [],
    };
  }

  handleActiveConcept = index => (event, newExpanded) => {
    const { activeConcept } = this.state;
    this.setState({ activeConcept: index });
    if (activeConcept === index) this.setState({ activeConcept: null });
  };

  handleTopic = index => (event, newExpanded) => {
    const { activeTopic } = this.state;
    if (activeTopic !== index) this.setState({ activeTopic: index });
    else this.setState({ activeTopic: null });
  };

  componentDidMount() {
    this.props.getOfficialGuideDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { officialGuideDetails } = this.props;
    if (
      officialGuideDetails &&
      prevProps.officialGuideDetails !== officialGuideDetails
    ) {
      if (officialGuideDetails.success) {
        this.setState({
          data: officialGuideDetails.data,
        });
      }
    }
  }

  render() {
    const { t, loading } = this.props;
    const { data, activeTab, activeTopic, activeConcept } = this.state;
    if (loading) return <PageLoader />;
    else {
      return data.length !== 0 ? (
        <Container>
          <Grid container>
            <Grid item xs={9} style={{ paddingRight: '20px' }}>
              {/* Header */}
              {/* Tab */}
              <Box className={'border_style'}>
                {data.map((tab, index) => (
                  <div
                    className={
                      activeTab === index
                        ? 'active-topic-name plain-topic-name'
                        : 'plain-topic-name'
                    }
                    onClick={() => this.setState({ activeTab: index })}
                  >
                    {t(tab.title)}
                  </div>
                ))}
              </Box>

              {data[activeTab].concepts.map((concept, index) => {
                return (
                  <Accordion expanded={activeConcept === index}>
                    <AccordionHead id={index} item xs={12}>
                      <Concept
                        {...this.props}
                        concept={concept}
                        index={index}
                        activeConcept={activeConcept}
                        handleClick={this.handleActiveConcept}
                      />
                    </AccordionHead>

                    <div>
                      {concept.topics.map((topic, index) => {
                        return (
                          <Topic
                            {...this.props}
                            aria-label="Topic"
                            id={index}
                            activeTopic={activeTopic}
                            topic={topic}
                            index={index}
                            onTopic={this.handleTopic}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                );
              })}
            </Grid>
            <Grid item container xs={3} id="right-grid">
              <Grid item xs={12} className={'cards-spacing-Landing'}>
                <SideDiv>
                  <Calendar />
                </SideDiv>
              </Grid>
              <Grid item xs={12} className={'cards-spacing-Landing '}>
                <SideDiv>
                  <StrengthsCard />
                </SideDiv>
              </Grid>
              <Grid item xs={12} className={'cards-spacing-Landing'}>
                <SideDiv>
                  <WeaknessCard />
                </SideDiv>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <NoTask>{'Tasks not yet Discovered'}</NoTask>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state.PracticeReducer,
  };
};
export default connect(mapStateToProps, { getOfficialGuideDetails })(
  withTranslation()(Index)
);
