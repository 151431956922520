import React from 'react';
// import VideoCard from './components/VideoCard';
import {
  Container,
  Description,
  SearchCard,
  TextBox,
  Heading,
  DateText,
  ImageBox,
  // SearchVideo as Video,
} from '../../../assets/css/practice/WebinarComponents';
// import VideoCard from './components/VideoCard';
import Video from './components/SearchVideo';
import PaginationComponent from '../../../utils/components/PaginationComponent';
import { Grid } from '@material-ui/core';
import PageLoader from '../../../utils/components/PageLoader';
import EmptyBox from '../../../assets/images/EmptyBox.svg';

function SearchContent({
  posts,
  handlePageChange,
  totalPages,
  handleWatchVideoClick,
  loading,
}) {
  if (loading) return <PageLoader />;
  else
    return (
      <div>
        {posts.length > 0 ? (
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Container>
                {posts.map(post => (
                  <SearchCard>
                    <Video
                      src={post.videoUrl}
                      onClick={() => handleWatchVideoClick(post)}
                      otp={post.videoOtp}
                      playBackInfo={post.videoPlaybackInfo}
                    />
                    <TextBox>
                      <Heading>{post.title}</Heading>
                      <Description>{post.description}</Description>
                      <DateText>{post.date}</DateText>
                    </TextBox>
                  </SearchCard>
                ))}

                <PaginationComponent
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                />
              </Container>
            </Grid>
          </Grid>
        ) : (
          <ImageBox>
            <img src={EmptyBox} alt="EmptyBox" />
          </ImageBox>
        )}
      </div>
    );
}

export default SearchContent;
