import { Card } from '@material-ui/core';
import React from 'react';
import { SubHeading } from '../../../../assets/css/StyledComponent';

function TaskTitleCard({ topicData }) {
  return (
    <Card className={'test-topic-title-card'}>
      <SubHeading style={{ padding: '20px' }}>{topicData.title}</SubHeading>
    </Card>
  );
}

export default TaskTitleCard;
