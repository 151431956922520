import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/StyledPractice';
import {
  getStudyPlanConcepts,
  getStudyPlanSubjects,
  getStudyPlanTopics,
} from '../../../redux/action/Dashboard';
import {
  getPassageList,
  getPassageQuestions,
  setSolveByPassageState,
  setFieldValue,
} from '../../../redux/action/Practice';
import { routePaths } from '../../../routes/RoutePath';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import Header from './Header';
import Left from './Left';
import Right from './Right';
import PageLoader from '../../../utils/components/PageLoader';
import QueryString from 'qs';
import { QS_PROPS } from '../../../constant/Variables';
import DialogComponent from '../../../utils/components/DialogComponent';
import RocketIcon from '../../../assets/icons/BlueRocket.svg';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import simulateMouseClick from '../../../utils/methods/SimulateMouseClick';

class SolveByPassage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      boosterDialog: false,
      currenElement: null,
    };
    this.breadCrumbsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Practise',
              path: routePaths.dashboard.solveByPassage,
            },
            {
              name: 'Question bank',
              path: routePaths.dashboard.solveByPassage,
            },
            {
              name: 'Solve by Passage',
              path: routePaths.dashboard.solveByPassage,
            },
          ],
        },
      },
      back: true,
    };
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // Score boost route
    window.document.addEventListener('click', this.handleGeneralClick, true);
    window.document.addEventListener('change', this.handleGeneralClick, true);

    const {
      subjectValue,
      topicValue,
      activePassage,
      attemptStatus,
      difficultyLevel,
      conceptValue,
    } = this.props.solveByPassageState;

    const { subjectId, conceptId, topicId, level, boost } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (boost) {
      this.props.getStudyPlanSubjects(response => {
        if (response.success)
          this.props.getStudyPlanConcepts(
            subjectId,
            conceptResponse => {
              if (conceptResponse.success) {
                this.props.getStudyPlanTopics('all', topicResponse => {
                  if (topicResponse.success) {
                    this.props.getPassageList(
                      topicId,
                      conceptId,
                      passageResponse => {
                        let obj = {
                          ...this.props.solveByPassageState,
                          subjectValue: subjectId,
                          conceptValue: conceptId,
                          topicValue: topicId,
                          difficultyLevel: level,
                          attemptStatus: 'ALL',
                        };
                        if (passageResponse.success) {
                          if (passageResponse.data.length) {
                            this.props.getPassageQuestions(
                              passageResponse.data[0].id,
                              topicId,
                              'ALL',
                              level,
                              conceptId,
                              questionResponse => {
                                if (questionResponse.success) {
                                  this.props.setSolveByPassageState({
                                    ...obj,
                                    activePassage: passageResponse.data[0].id,
                                  });
                                }
                                this.setState({
                                  loading: false,
                                });
                              }
                            );
                          } else {
                            this.props.setSolveByPassageState({
                              ...obj,
                            });
                            this.setState({
                              loading: false,
                            });
                          }
                        }
                      }
                    );
                  }
                });
              }
            },
            'passage'
          );
      }, 'passage');
    } else if (!subjectValue) {
      this.props.getStudyPlanSubjects(response => {
        if (response.success)
          this.props.getStudyPlanConcepts(
            response.data[0].id,
            conceptResponse => {
              if (conceptResponse.success) {
                this.props.getStudyPlanTopics('all', topicResponse => {
                  if (topicResponse.success) {
                    if (topicResponse.data.length) {
                      console.log(topicResponse, 'topic happened');
                      this.props.getPassageList(
                        'all',
                        conceptResponse.data[0].id,
                        passageResponse => {
                          let obj = {
                            ...this.props.solveByPassageState,
                            subjectValue: response.data[0].id,
                            conceptValue: conceptResponse.data[0].id,
                            topicValue: 'all',
                          };
                          if (passageResponse.success) {
                            if (passageResponse.data.length) {
                              this.props.getPassageQuestions(
                                passageResponse.data[0].id,
                                topicResponse.data[0]?.id || 'all',
                                attemptStatus,
                                difficultyLevel,
                                conceptResponse.data[0].id,
                                questionResponse => {
                                  if (questionResponse.success) {
                                    this.props.setSolveByPassageState({
                                      ...obj,
                                      activePassage: passageResponse.data[0].id,
                                    });
                                  }
                                  this.setState({
                                    loading: false,
                                  });
                                }
                              );
                            } else {
                              this.props.setSolveByPassageState({
                                ...obj,
                              });
                              this.setState({
                                loading: false,
                              });
                            }
                          }
                        }
                      );
                    } else {
                      this.setState({
                        loading: false,
                      });
                      let obj = {
                        ...this.props.solveByPassageState,
                        subjectValue: response.data[0].id,
                        conceptValue: conceptResponse.data[0].id,
                        topicValue: 'all',
                      };
                      this.props.setSolveByPassageState({
                        ...obj,
                      });
                    }
                  }
                });
              }
            },
            'passage'
          );
      }, 'passage');
    } else {
      this.props.getStudyPlanSubjects(() => {}, 'passage');
      this.props.getStudyPlanConcepts(subjectValue, () => {}, 'passage');
      this.props.getStudyPlanTopics('all', () => {});
      if (activePassage) {
        this.props.getPassageQuestions(
          activePassage,
          topicValue,
          attemptStatus,
          difficultyLevel,
          conceptValue,
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      } else
        this.setState({
          loading: false,
        });
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
  }

  handleChange = event => {
    const { attemptStatus, difficultyLevel, conceptValue } =
      this.props.solveByPassageState;
    const { name, value } = event.target;
    switch (name) {
      case 'subjectValue': {
        this.props.getStudyPlanConcepts(
          value,
          response => {
            if (response.success) {
              this.props.getStudyPlanTopics('all', topicResponse => {
                if (topicResponse.success) {
                  this.props.getPassageList(
                    topicResponse.data[0]?.id || 'all',
                    response.data[0].id,
                    passageResponse => {
                      if (passageResponse.success) {
                        let obj = {
                          ...this.props.solveByPassageState,
                          subjectValue: event.target.value,
                          conceptValue: response.data[0].id,
                          topicValue: 'all',
                        };
                        if (passageResponse.data.length)
                          this.props.getPassageQuestions(
                            passageResponse.data[0].id,
                            topicResponse.data[0]?.id || 'all',
                            attemptStatus,
                            difficultyLevel,
                            response.data[0].id,
                            questionResponse => {
                              if (questionResponse.success)
                                this.props.setSolveByPassageState({
                                  ...obj,
                                  activePassage: passageResponse.data[0].id,
                                });
                            }
                          );
                        else {
                          this.props.setSolveByPassageState({
                            ...obj,
                            activePassage: null,
                          });
                          this.props.setFieldValue('passageQuestions', {});
                        }
                      }
                    }
                  );
                }
              });
            }
          },
          'passage'
        );
        break;
      }
      case 'conceptValue': {
        this.props.getStudyPlanTopics('all', response => {
          if (response.success) {
            this.props.getPassageList(
              response.data[0].id,

              passageResponse => {
                if (passageResponse.success) {
                  let obj = {
                    ...this.props.solveByPassageState,
                    conceptValue: value,
                    topicValue: 'all',
                  };

                  if (passageResponse.data.length)
                    this.props.getPassageQuestions(
                      passageResponse.data[0].id,
                      response.data[0].id,
                      attemptStatus,
                      difficultyLevel,
                      value,
                      questionResponse => {
                        if (questionResponse.success)
                          this.props.setSolveByPassageState({
                            ...obj,
                            activePassage: passageResponse.data[0].id,
                          });
                      }
                    );
                  else {
                    this.props.setSolveByPassageState({
                      ...obj,
                      activePassage: null,
                    });
                    this.props.setFieldValue('passageQuestions', {});
                  }
                }
              }
            );
          }
        });
        break;
      }
      case 'topicValue': {
        this.props.getPassageList(value, conceptValue, response => {
          if (response.success) {
            let obj = {
              ...this.props.solveByPassageState,
              topicValue: value,
            };
            if (response.data.length)
              this.props.getPassageQuestions(
                response.data[0].id,
                value,
                attemptStatus,
                difficultyLevel,
                conceptValue,
                questionResponse => {
                  if (questionResponse.success)
                    this.props.setSolveByPassageState({
                      ...obj,
                      activePassage: response.data[0].id,
                    });
                }
              );
            else {
              this.props.setSolveByPassageState({
                ...obj,
                activePassage: null,
              });
              this.props.setFieldValue('passageQuestions', {});
            }
          }
        });
        break;
      }
      default:
        break;
    }
  };

  handleActivePassage = id => {
    const { attemptStatus, difficultyLevel, topicValue, conceptValue } =
      this.props.solveByPassageState;
    this.props.getPassageQuestions(
      id,
      topicValue,
      attemptStatus,
      difficultyLevel,
      conceptValue,
      questionResponse => {
        if (questionResponse.success)
          this.props.setSolveByPassageState({
            ...this.props.solveByPassageState,
            activePassage: id,
          });
      }
    );
  };

  handleAttemptStatus = event => {
    const { value } = event.target;
    const { activePassage, difficultyLevel, topicValue, conceptValue } =
      this.props.solveByPassageState;
    this.props.getPassageQuestions(
      activePassage,
      topicValue,
      value,
      difficultyLevel,
      conceptValue,
      questionResponse => {
        if (questionResponse.success) {
          this.props.setSolveByPassageState({
            ...this.props.solveByPassageState,
            attemptStatus: value,
          });
        }
      }
    );
  };

  handleCheckBox = event => {
    const { name } = event.target;
    const {
      activePassage,
      attemptStatus,
      difficultyLevel,
      topicValue,
      conceptValue,
    } = this.props.solveByPassageState;
    if (difficultyLevel?.indexOf(name) > -1) {
      let newDifficultyLevel = [...difficultyLevel].filter(item => {
        return item !== name;
      });
      this.props.getPassageQuestions(
        activePassage,
        topicValue,
        attemptStatus,
        newDifficultyLevel,
        conceptValue,
        questionResponse => {
          if (questionResponse.success)
            this.props.setSolveByPassageState({
              ...this.props.solveByPassageState,
              difficultyLevel: newDifficultyLevel,
            });
        }
      );
    } else
      this.props.getPassageQuestions(
        activePassage,
        topicValue,
        attemptStatus,
        [...difficultyLevel, name],
        conceptValue,
        questionResponse => {
          if (questionResponse.success)
            this.props.setSolveByPassageState({
              ...this.props.solveByPassageState,
              difficultyLevel: [...difficultyLevel, name],
            });
        }
      );
  };

  // handleClick = (questionId, testQuestionId, isAnswered) => {
  // this.props.history.push(
  // `${routePaths.dashboard.question}?questionId=${questionId}&testQuestionId=${testQuestionId}&isAnswered=${isAnswered}`
  // );
  // };
  handleClick = (filterQuestionList, testQuestionsSetId) => {
    console.log('clicked');
    sessionStorage.setItem(
      'official_guide_gmat_question',
      JSON.stringify(filterQuestionList)
    );

    console.log('clicked');
    this.props.history.push(
      `${routePaths.dashboard.questionBankTestInstruction}?testQuestionId=${testQuestionsSetId}&og_course=QUESTIONBANK&qbank_type=PASSAGE`
    );
  };

  handleGeneralClick(e) {
    const { boost } = QueryString.parse(this.props.location.search, QS_PROPS);

    const clsName = e.target.className.baseVal || e.target.className;
    if (boost && _.isString(clsName) && clsName.indexOf('on-boost-mode') > -1) {
      e.preventDefault();
      e.target.onclick = null;
      e.stopPropagation();
      this.setState({ boosterDialog: true, currenElement: e.target });
    }
  }

  handleButton1Click = () => {
    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
    this.props.history.replace(routePaths.dashboard.solveByPassage);
    simulateMouseClick(this.state.currenElement);
    this.setState({ boosterDialog: false });
  };

  render() {
    const { data: subjectData } = this.props.studyPlanSubjects;
    const { data: conceptData } = this.props.studyPlanConcepts;
    const { data: topicData } = this.props.studyPlanTopics;
    const { data: passageData } = this.props.passageList;
    const { data: passageQuestions } = this.props.passageQuestions;
    const {
      subjectValue,
      conceptValue,
      topicValue,
      activePassage,
      attemptStatus,
      difficultyLevel,
    } = this.props.solveByPassageState;
    const { t } = this.props;
    console.log(passageQuestions, 'echbjhb');
    let combination = {
      subjectValue: subjectValue,
      conceptValue: conceptValue,
      topicValue: topicValue,
      passageValue: activePassage,
      difficultyLevel: difficultyLevel?.toString(),
    };
    sessionStorage.setItem('combination2', JSON.stringify(combination));

    return this.state.loading ? (
      <PageLoader />
    ) : (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BreadCrumbs {...this.breadCrumbsProps} />
          </Grid>
          <Grid item xs={12}>
            <Header
              subjectData={subjectData}
              conceptData={conceptData}
              topicData={topicData}
              subjectValue={subjectValue}
              conceptValue={conceptValue}
              topicValue={topicValue}
              handleChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Left
              passageData={passageData}
              activePassage={activePassage}
              handleActivePassage={this.handleActivePassage}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Right
              attemptStatus={attemptStatus}
              handleAttemptStatus={this.handleAttemptStatus}
              passageQuestions={passageQuestions}
              difficultyLevel={difficultyLevel}
              handleCheckBox={this.handleCheckBox}
              handleClick={this.handleClick}
            />
          </Grid>
        </Grid>
        <DialogComponent
          open={this.state.boosterDialog}
          dialogContent={{
            icon: <img src={RocketIcon} alt="" />,
            title: t(`You are current on the track to boost your score`),
            body: t('Try pushing your limits to give your best'),
            button1: t('Quit for now'),
            button2: t('Continue'),
          }}
          handleButton1Click={this.handleButton1Click}
          handleButton2Click={() => this.setState({ boosterDialog: false })}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.PracticeReducer,
    ...state.DashboardQuestionBankReducer,
  };
};

export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getStudyPlanTopics,
  getPassageList,
  getPassageQuestions,
  setSolveByPassageState,
  setFieldValue,
})(withTranslation()(SolveByPassage));
