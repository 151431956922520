import { Collapse, Grid, List, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, RemoveOutlined } from '@material-ui/icons';
import React from 'react';
import {
  BoostButton,
  BoostIcon,
  BoostText,
  StyledCard,
  StyledCardHeader,
  StyledCollapse,
} from '../../../../assets/css/dashboard/viewmore/StrengthStyles.js';
import {
  AvatarImg,
  BorderCard,
  BottomArrow,
  CardTitle,
  Divider,
  EmptyContainer,
  EmptyContent,
  EmptyImg,
  EmptyTypeContent,
  EndFlex,
  InnerDiv,
  InsideContainer,
  RightArrow,
  RightContent,
  SideContainer,
  SideContent,
  SideIcon,
  StyledListItem,
  StyledListItemIcon,
  StyledTopicBox,
  SubHeader,
  TextAvatar,
  Title,
  TitleBody,
  TitleImg,
  TopicBottomArrow,
  TopicHeader,
  TypeContainer,
  TypeHeader,
  TypeIcon,
} from '../../../../assets/css/dashboard/viewmore/StyledStrengthComponent.js';
import '../../../../assets/css/loader.css';
import boostIcon from '../../../../assets/icons/boostIcon.svg';
import dislike from '../../../../assets/icons/dislike.svg';
import qmark from '../../../../assets/icons/questionMark.svg';
import right from '../../../../assets/icons/right.svg';
import target from '../../../../assets/icons/target.svg';
import time from '../../../../assets/icons/time1.svg';
import wrong from '../../../../assets/icons/wrong.svg';
import easy from '../../../../assets/images/easy.png';
import hard from '../../../../assets/images/Liner.png';
import medium from '../../../../assets/images/medium.png';

function Card(props) {
  const {
    data,
    handleBoostClick,
    t,
    topicOpenList,
    openList,
    handleTopicClick,
    handleClick,
  } = props;
  return (
    <>
      {data.map((item, index1) => {
        return (
          <Grid item sm={12} md={6} lg={6} key={index1}>
            <BorderCard>
              <TitleBody>
                <TitleImg src={item.icon === 'target' ? target : dislike} />
                <Title>{t(item.title) + ' (' + item.count + ') '}</Title>
              </TitleBody>
              <InnerDiv>
                {item.concepts.length !== 0 ? (
                  item.concepts.map((concept, conceptIndex) => {
                    return (
                      <StyledCard>
                        <StyledCardHeader
                          avatar={<AvatarImg src={concept.image} />}
                          action={
                            <EndFlex>
                              <SubHeader>{t('Skill score')}</SubHeader>
                              <TextAvatar color={item.title === 'Weakness'}>
                                {concept.score}
                              </TextAvatar>
                            </EndFlex>
                          }
                          title={<CardTitle>{concept.name}</CardTitle>}
                          onClick={() => handleClick(concept.id)}
                          bottom={openList.indexOf(concept.id) > -1}
                        />
                        <StyledCollapse
                          in={openList.indexOf(concept.id) > -1}
                          timeout="auto"
                          unmountOnExit
                        >
                          <StyledTopicBox>
                            {concept.topics.length !== 0 &&
                              concept.topics.map((topic, topicIndex) => {
                                return (
                                  <StyledCard>
                                    <StyledCardHeader
                                      avatar={<AvatarImg src={topic.image} />}
                                      title={
                                        <CardTitle>{topic.name}</CardTitle>
                                      }
                                      action={
                                        <EndFlex>
                                          <SubHeader>
                                            {t('Skill score')}
                                          </SubHeader>
                                          <TextAvatar
                                            color={item.title === 'Weakness'}
                                          >
                                            {topic.score}
                                          </TextAvatar>
                                        </EndFlex>
                                      }
                                      onClick={() =>
                                        handleTopicClick(
                                          index1,
                                          conceptIndex,
                                          topicIndex,
                                          topic.id,
                                          Boolean(topic.insights)
                                        )
                                      }
                                      bottom={
                                        topicOpenList.indexOf(topic.id) > -1
                                      }
                                    />
                                    <StyledCollapse
                                      in={topicOpenList.indexOf(topic.id) > -1}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      {topic.insights &&
                                      topic.insights.length !== 0 ? (
                                        <TopicHeader>
                                          {t('Topic Test Insights')}
                                        </TopicHeader>
                                      ) : (
                                        <TopicHeader />
                                      )}

                                      {topic.insights ? (
                                        topic.insights.length !== 0 && (
                                          <div>
                                            {topic.insights.map(type => {
                                              return (
                                                <List>
                                                  <StyledListItem
                                                    button
                                                    onClick={() =>
                                                      handleClick(type.id)
                                                    }
                                                    disableRipple
                                                  >
                                                    <StyledListItemIcon>
                                                      <TypeIcon
                                                        src={
                                                          type.icon === 'easy'
                                                            ? easy
                                                            : type.icon ===
                                                              'medium'
                                                            ? medium
                                                            : hard
                                                        }
                                                      />
                                                    </StyledListItemIcon>
                                                    <ListItemText
                                                      primary={
                                                        <TypeHeader>
                                                          {t(type.name)}
                                                        </TypeHeader>
                                                      }
                                                    />
                                                    <RightArrow
                                                      color={
                                                        openList.indexOf(
                                                          type.id
                                                        ) > -1
                                                      }
                                                    >
                                                      {openList.indexOf(
                                                        type.id
                                                      ) > -1 ? (
                                                        <ExpandLess />
                                                      ) : (
                                                        <ExpandMore />
                                                      )}
                                                    </RightArrow>
                                                  </StyledListItem>

                                                  <Collapse
                                                    in={
                                                      openList.indexOf(
                                                        type.id
                                                      ) > -1
                                                        ? true
                                                        : false
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <TypeContainer>
                                                      <Grid
                                                        container
                                                        spacing={3}
                                                        style={{
                                                          position: 'relative',
                                                        }}
                                                      >
                                                        {type.status.length !==
                                                        0 ? (
                                                          type.status.map(
                                                            item => {
                                                              return (
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={6}
                                                                  md={6}
                                                                  lg={6}
                                                                >
                                                                  <InsideContainer>
                                                                    <SideContainer>
                                                                      <SideIcon
                                                                        src={
                                                                          item.icon ===
                                                                          'qmark'
                                                                            ? qmark
                                                                            : item.icon ===
                                                                              'time'
                                                                            ? time
                                                                            : item.icon ===
                                                                              'right'
                                                                            ? right
                                                                            : wrong
                                                                        }
                                                                        //icon size
                                                                        size={
                                                                          item.icon ===
                                                                            'right' ||
                                                                          item.icon ===
                                                                            'wrong'
                                                                            ? true
                                                                            : false
                                                                        }
                                                                      />
                                                                      <SideContent>
                                                                        {t(
                                                                          item.name
                                                                        )}
                                                                      </SideContent>
                                                                    </SideContainer>

                                                                    <RightContent>
                                                                      {
                                                                        item.result
                                                                      }
                                                                    </RightContent>
                                                                  </InsideContainer>
                                                                </Grid>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <EmptyTypeContent>
                                                            {t(
                                                              'Please attempt the test'
                                                            )}
                                                          </EmptyTypeContent>
                                                        )}
                                                        {type.status.length !==
                                                        0 ? (
                                                          <Divider />
                                                        ) : (
                                                          ''
                                                        )}
                                                      </Grid>
                                                    </TypeContainer>
                                                  </Collapse>
                                                </List>
                                              );
                                            })}
                                          </div>
                                        )
                                      ) : (
                                        <center>
                                          <span className={'loader'}></span>
                                        </center>
                                      )}

                                      <BoostButton
                                        id={topic.id}
                                        onClick={e =>
                                          handleBoostClick(e, {
                                            conceptName: concept.name,
                                            topicName: topic.name,
                                          })
                                        }
                                      >
                                        <BoostText>
                                          <BoostIcon src={boostIcon} />
                                          {t('Revise now')}
                                        </BoostText>
                                      </BoostButton>
                                    </StyledCollapse>
                                    {topicOpenList.indexOf(topic.id) > -1 && (
                                      <TopicBottomArrow
                                        onClick={() =>
                                          handleTopicClick(
                                            index1,
                                            conceptIndex,
                                            topicIndex,
                                            topic.id,
                                            Boolean(topic.insights)
                                          )
                                        }
                                      >
                                        <RemoveOutlined fontSize={'small'} />
                                      </TopicBottomArrow>
                                    )}
                                  </StyledCard>
                                );
                              })}
                          </StyledTopicBox>
                        </StyledCollapse>
                        {openList.indexOf(concept.id) > -1 && (
                          <BottomArrow onClick={() => handleClick(concept.id)}>
                            <ExpandLess />
                          </BottomArrow>
                        )}
                      </StyledCard>
                    );
                  })
                ) : (
                  <EmptyContainer>
                    <EmptyImg src={item.icon === 'target' ? target : dislike} />
                    <EmptyContent>
                      {t(`No ${item.title} Discovered`)}
                    </EmptyContent>
                  </EmptyContainer>
                )}
              </InnerDiv>
            </BorderCard>
          </Grid>
        );
      })}
    </>
  );
}

export default Card;
