/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import { Pie as Graph } from 'react-chartjs-2';
import styled from 'styled-components';

export default class Pie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '',
    };
  }

  componentDidMount() {
    var ctx = document.getElementById('pie-chart').getContext('2d');
    var my_gradient = ctx.createLinearGradient(160, 0, 0, 0);
    my_gradient.addColorStop(0, '#CEFFA5');
    my_gradient.addColorStop(1, '#FDFFFB');
    this.setState({ color: my_gradient });
  }
  render() {
    const { value, description, animation, maintainAspectRatio } = this.props;
    const data = {
      datasets: [
        {
          label: 'My First Dataset',
          data: [value, value - 100],
          backgroundColor: [this.state.color, '#F2FAE8'],
          circumference: 180,
          // cutout:80,
        },
      ],
    };

    return (
      <Container>
        <Text>
          <Percentage>{value}%</Percentage>
          <Description>{description}</Description>
        </Text>
        <GraphContainer>
          <Graph
            data={data}
            id={'pie-chart'}
            options={{
              animation: animation,
              maintainAspectRatio: maintainAspectRatio,
              responsive: true,
              plugins: {
                title: {
                  display: false,
                },
                tooltip: false,
              },
              hover: { mode: null },
            }}
          />
        </GraphContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  width: 211px;
`;

const GraphContainer = styled.div`
  width: 211px;
  transform: rotate(270deg);
`;

const Text = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Percentage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  color: #052a4e;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
`;
