/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { Fragment } from 'react';
import '../../../assets/css/practice/Webinar.css';
import {
  StyledSelect,
  listItem,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';

import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  IconButton,
  BackIconButton,
} from '../../../assets/css/practice/WebinarComponents';
import FilterIcon from '../../../assets/icons/filter.svg';
import SortIcon from '../../../assets/icons/SortArrows.svg';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ExpandMore } from '@material-ui/icons';

const DEFAULT_OBJECT = {
  id: 'null',
  name: 'Concept',
};
function SearchBar({
  webinarFilter,
  webinarConceptFilter,
  webinarFilterValue,
  webinarConceptValue,
  handleFilterClick,
  handleConceptClick,
  handleSortClick,
  sortValue,
  searchText,
  handleTextChange,
  handleSearchClick,
  searchMode,
  handleChange,
  conceptValue,
  conceptList,
  handleBackClick,
  videoMode,
}) {
  // const webinarConceptFilterList = webinarConceptFilter || [];
  // console.log(webinarConceptValue, 'conept');
  // console.log(webinarFilterValue, 'filter value');
  // const ConceptFilter = {
  //   id: 'default',
  //   name: 'Concept',
  //   disabled: true,
  // };
  return (
    <div>
      {!searchMode && !videoMode && (
        <Box pb={3}>
          <div className="h1">Recorded Webinars</div>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={8} container direction="row" wrap="nowrap" spacing={2}>
          {(searchMode || videoMode) && (
            <Grid item xs={1} className="topic_test_button">
              <IconButton onClick={handleBackClick}>
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={searchMode || videoMode ? 11 : 12}>
            <TextField
              id={'no-outline'}
              value={searchText}
              onChange={handleTextChange}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleSearchClick();
                }
              }}
              fullWidth
              variant="filled"
              color="primary"
              InputProps={{
                disableUnderline: true,
                className: 'search-field',
                startAdornment: (
                  <InputAdornment>
                    <BackIconButton
                      disableFocusRipple
                      onClick={handleSearchClick}
                    >
                      <SearchIcon color="primary" />
                    </BackIconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                className: 'inner-input-component',
              }}
              placeholder="Search"
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            paddingTop: '15px',
            paddingLeft: '21px',
            marginRight: '10px',
          }}
        >
          <ThemeProvider theme={listItem}>
            <StyledSelect
              style={style.dropDownStyle}
              name={'Concept'}
              id="concept"
              value={webinarConceptValue}
              IconComponent={ExpandMore}
              disableUnderline
              MenuProps={DROP_DOWN_PROPS}
              onChange={handleConceptClick}
            >
              {[DEFAULT_OBJECT, ...(webinarConceptFilter || [])].map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </StyledSelect>
          </ThemeProvider>
        </Grid>
        <Grid item xs={1}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <Fragment>
                <Button
                  fullWidth
                  value={webinarFilterValue}
                  {...bindTrigger(popupState)}
                  startIcon={<img src={FilterIcon} alt="" />}
                >
                  Filter
                </Button>
                <Menu {...bindMenu(popupState)} {...DROP_DOWN_PROPS}>
                  {webinarFilter.map(item => (
                    <MenuItem
                      value={item.id}
                      onClick={e => handleFilterClick(item)}
                      selected={webinarFilterValue.indexOf(item) > -1}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Fragment>
            )}
          </PopupState>
        </Grid>
        <Grid item xs={1}>
          <PopupState>
            {popupState => {
              return (
                <Fragment>
                  <Button
                    fullWidth
                    startIcon={<img src={SortIcon} alt="" />}
                    {...bindTrigger(popupState)}
                  >
                    Sort by
                  </Button>
                  <Menu {...bindMenu(popupState)} {...DROP_DOWN_PROPS}>
                    <MenuItem
                      value="ASC"
                      selected={sortValue === 'ASC'}
                      onClick={() => {
                        popupState.close();
                        handleSortClick('ASC');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      value="DESC"
                      selected={sortValue === 'DESC'}
                      onClick={() => {
                        popupState.close();
                        handleSortClick('DESC');
                      }}
                    >
                      Descending
                    </MenuItem>
                  </Menu>
                </Fragment>
              );
            }}
          </PopupState>
        </Grid>
      </Grid>
    </div>
  );
}
const style = {
  dropDownStyle: {
    minWidth: '210px',
    maxWidth: '210px',
  },
};

export default SearchBar;
