/**
 * (c) CareerLabs. All rights reserved.
 **/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * @param {String} otp
 * @param {String} playBackInfo
 * @param {Function} handlePause
 * @param {Function} handleEnd
 * @param {Number} startTime
 */
 
class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoObject: null,
    };
  }

  componentDidMount() {
    if (window.VdoPlayer) {
    
      
      return this.loadPlayer();
    }
    const playerScript = document.createElement('script');
    playerScript.src =
      'https://player.vdocipher.com/playerAssets/1.6.10/vdo.js';
    document.body.appendChild(playerScript);
    playerScript.addEventListener('load', () => {
      return this.loadPlayer();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.playBackInfo !== this.props.playBackInfo &&
      prevProps.otp !== this.props.otp
    )
      this.loadPlayer();
  }

  loadPlayer() {
  
    window.playerContainer = this.refs.container;
    const vdoObj = new window.VdoPlayer({
      otp: this.props.otp,
      playbackInfo: this.props.playBackInfo,
      theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
      container: this.refs.container,
    });
   
    vdoObj.addEventListener('play', () => {
      let startTime = Math.trunc(this.props.startTime / 1000);

      if (startTime) {
        vdoObj.seek(startTime);
      }
   
   
      this.props.handlePlay && this.props.handlePlay(vdoObj);
    });

    vdoObj.addEventListener('pause', () => {
      if (this.props.handlePause) this.props.handlePause(vdoObj);
    });

    vdoObj.addEventListener('seeking', () => {
   

      if (this.props.handleSeek) this.props.handleSeek(vdoObj);
   
    });

    vdoObj.addEventListener('ended', () => {
      if (this.props.handleEnd) this.props.handleEnd(vdoObj);
    });

     vdoObj.addEventListener('progress', () => {
      if (this.props.handleProgress) this.props.handleProgress(vdoObj);
    
     });


    if (this.props.handleLoad) this.props.handleLoad(vdoObj);

    if (this.props.isSlider) {
      var pauseNextButton = document.querySelector('.slick-arrow.slick-next');
      if (pauseNextButton) {
        pauseNextButton.addEventListener('click', function () {
          vdoObj.pause();
        });
      }
      var pausePrevButton = document.querySelector('.slick-arrow.slick-prev');
      if (pausePrevButton) {
        pausePrevButton.addEventListener('click', function () {
          vdoObj.pause();
        });
      }
      var pauseSliderDotButton = document.querySelectorAll('.slick-dots li');
      if (pauseSliderDotButton && pauseSliderDotButton.length > 1) {
        let nodeArr = [...pauseSliderDotButton];
        for (let j = 0; j < nodeArr.length; j++) {
          nodeArr[j].addEventListener('click', function () {
            vdoObj.pause();
          });
        }
      }
    }
  }
  

  render() {
  
    return (
      <div
        ref="container"
        style={{
          position:"relative",
          height: 'auto',
          width: '100%',
          
          
        }}
      >
        
      </div>
    );
  }
}

Player.propTypes = {
  otp: PropTypes.string.isRequired,
  playBackInfo: PropTypes.string.isRequired,
};

export default Player;
