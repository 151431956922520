import moment from 'moment';

/**
 *
 * @param {String} type
 * @param {Object} error
 * @param {Boolean} loading
 * @returns Object
 */
export const errorHandler = (type, error, loading) => {
  var message = 'Expectation Failed';
  if (
    error.response &&
    typeof error.response.data === 'string' &&
    error.response.data.length !== 0
  )
    message = error.response.data;
  else if (
    error.response &&
    error.response.data.message &&
    error.response.data.message.length !== 0
  )
    message = error.response.data.message;
  return {
    type: type,
    payload: {
      success: false,
      message: message,
      ...error.response,
    },
    loading: loading,
  };
};

/**
 *
 * @param {Object} error
 * @returns Object
 */
export const catchError = error => {
  var message = 'Expectation Failed';
  if (error) {
    if (
      error.response &&
      typeof error.response.data === 'string' &&
      error.response.data.length !== 0
    )
      message = error.response.data;
    else if (
      error.response &&
      error.response.data.message &&
      error.response.data.message.length !== 0
    )
      message = error.response.data.message;
  }
  return {
    success: false,
    message: message,
    ...error?.response,
  };
};

/**
 * Get number of weeks in a month
 * @param {Number} year
 * @param {Number} month_number
 * @returns Number
 */
export const weekCount = (year, month_number) => {
  var firstOfMonth = new Date(year, month_number - 1, 1);
  var lastOfMonth = new Date(year, month_number, 0);

  var used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil(used / 7);
};

/**
 * Get week of month
 * @param {Date} date
 * @returns Number
 */
export const getWeekOfMonth = dateValue => {
  var d = dateValue;
  var date = d.getDate();
  var day = d.getDay();
  var weekOfMonth = Math.ceil((date + 6 - day) / 7);
  return weekOfMonth;
};

/**
 * Validate a next week
 * @param {Date} date
 * @returns Boolean
 */
export const nextWeek = date => {
  var nextWeekDate = new Date();
  nextWeekDate.setDate(nextWeekDate.getDate() + 7);
  nextWeekDate.setDate(nextWeekDate.getDate() + (6 - nextWeekDate.getDay()));
  return (
    moment(date).format('YYYY-MM-DD') ===
    moment(nextWeekDate).format('YYYY-MM-DD')
  );
};
export const textToDownloadFile = (text, fileName, format) => {
  let existType = fileName.split('.').pop() === format;
  let newPath = existType ? fileName : `${fileName}.${format || 'docx'}`;
  const downloadUrl = window.URL.createObjectURL(new Blob([text]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', newPath);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Get Weeks Difference
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns Number
 */
export const getWeeksDiff = (startDate, endDate) => {
  const msInWeek = 1000 * 60 * 60 * 24 * 7;
  return Math.round(Math.abs(endDate - startDate) / msInWeek);
};

/**
 * Get Format Date
 * @param {Date} date
 * @param {String} format
 * @returns String
 */
export const getFormatDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};
