/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Button as MuiButton } from '@material-ui/core';
import { DEVICE } from '../../../constant/Shared';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;
  min-height: 100vh;
  width: 100%;
  background: #f7f8fc;
`;

export const TopicStyles = {
  root: {
    height: '198px',
    paddingTop: '20px',
    paddingLeft: '20px',
    background: '#d1ffd9', //#ffffff
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
  },
  clockIcon: {
    color: '#1093FF',
  },
};

export const TopicHeadingContainer = styled.div``;

export const TopicHeadingText = styled.div`
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  color: #052a4e;
`;

export const BodyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #052a4e;
  padding-right: 25px;
  padding-left: 14px;
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #052a4e;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #222222;

  opacity: 0.8;
`;

// Concept

export const Accordion = withStyles({
  root: {
    marginBottom: '20px',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
    '&::before': {
      // background: '#FFFFFF',
      position: 'unset',
    },
  },
})(MuiAccordion);

export const AccordionHead = withStyles({
  root: {
    background: '#ffffff',
    minHeight: '198px',
    borderRadius: '8px',
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
  },
})(MuiAccordionSummary);

export const AccordionBody = withStyles({
  root: {
    display: 'flex',
  },
})(AccordionDetails);

// Topic
export const TopicContainer = withStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px !important',
    cursor: 'default',

    '&::before': {
      background: '#FFFFFF',
      top: '0',
    },
  },

  expanded: {
    background: '#f3faff',
    margin: '0 !important',
  },
})(MuiAccordion);

export const TopicHead = withStyles({
  root: {
    minHeight: '54px',
    borderTop: 'solid',
    borderWidth: '1px !important',
    borderColor: '#1093ff !important',
    padding: '0 0',
    margin: '0 16px',

    // '&::first-child': {
    //   borderTop: 'none',
    // },
  },
})(MuiAccordionSummary);

export const TopicBody = withStyles({
  root: {
    background: '#f3faff',
  },
})(MuiAccordionDetails);

export const TopicHeadText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;
export const TopicHeadText2 = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #052a4e;
  margin-right: 16px;
`;

export const TopicBodyDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
`;

export const TaskHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a66c2;
  cursor: pointer;
`;

export const TopicStatusText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5a5a5a;
`;

export const TaskContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Button = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#1093FF',
    borderRadius: '30px',
    alignSelf: 'flex-start',
    minHeight: '33px',
    minWidth: '196px',
    marginTop: '32px',

    '&:hover': {
      background: '#1093FF',
    },
  },
})(MuiButton);

export const SideDiv = styled.div`
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  background-color: white;
`;

export const ProgressPosition = styled.div`
  position: absolute;
  z-index: 1;
  padding-bottom: 16px;
  width: calc(178% - 10px);

  @media ${DEVICE.tablet} {
    width: calc(178% - 8px);
  }
  @media ${DEVICE.laptopL} {
    width: calc(179% - 10px);
  }
  @media ${DEVICE.desktop} {
    width: calc(179% - 8px);
  }
`;
