/**
 * (c) CareerLabs. All rights reserved.
 **/
import { COURSE } from '../Action';

const initialState = {
  courseImage: null,
  courseData: null,
  courseInsights: null,
  goals: null,
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case COURSE.getCourseImage: {
      return {
        ...state,
        courseImage: action.payload,
      };
    }
    case COURSE.getCourseData: {
      return {
        ...state,
        courseData: action.payload,
      };
    }
    case COURSE.getInsights: {
      return {
        ...state,
        courseInsights: action.payload,
      };
    }
    case COURSE.getGoals: {
      return {
        ...state,
        goals: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

export default ProfileReducer;
