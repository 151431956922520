/**
 * (c) CareerLabs. All rights reserved.
 **/
import Cookies from 'js-cookie';
import { DEFAULT_URL } from '../../api/API';
import { GET as Get, POST, PUT } from '../../axios/Axios';
import { decrypt } from '../../utils/Crypto';
import { COURSE } from '../Action';

export const getCourseImage = (data, callback) => {
  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/contentReference/product/${data.courseId}/key/${data.endPoint}`,
      {},
      response => {
        dispatch({
          type: COURSE.getCourseImage,
          payload: response.date,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
export const getCoursesData = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/courseReviewReport`,
      {},
      response => {
        dispatch({
          type: COURSE.getCourseData,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getInsights = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const userProduct = JSON.parse(localStorage.getItem('userProduct'));
  const testExecutionId = localStorage.getItem('testExecutionId');
  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/courseReport`,
      {},
      response => {
        dispatch({
          type: COURSE.getInsights,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
export const getQbankInsights = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const userProduct = JSON.parse(localStorage.getItem('userProduct'));
  const testExecutionId = userProduct.testExecutionId;
  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/qbtestreport
      `,
      {},
      response => {
        dispatch({
          type: COURSE.getQbankInsights,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getGoals = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const userProduct = JSON.parse(localStorage.getItem('userProduct'));
  const productName = userProduct.productName;

  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/students/${studentId}/goalSettings`,
      {
        product: productName,
      },
      response => {
        dispatch({
          type: COURSE.getGoals,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const sendSupportEmail = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/email`,
      data,
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updatePlayTime = (videoId, data, callback = () => {}) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';

  return () => {
    PUT(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/task/${videoId}/video/status`,
      data,
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getVideoPlayInfo = (videoId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    Get(
      `${DEFAULT_URL}/students/${studentId}/taskContentVideo/${videoId}`,
      {},
      response => {
        callback(response.data.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
