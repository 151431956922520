/**
 * (c) CareerLabs. All rights reserved.
 **/
import Cookies from 'js-cookie';
import { DEFAULT_URL } from '../../api/API';
import { GET, POST, PUT } from '../../axios/Axios';
import { decrypt } from '../../utils/Crypto';
import { catchError } from '../../utils/methods/Helper';
import { TEST } from '../Action';

export const startTest = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecutions`,
      {},
      {
        questionSetName: `${
          localStorage.getItem('userProduct')
            ? JSON.parse(localStorage.getItem('userProduct')).productName
            : ''
        } Calibration Test`,
      },
      response => {
        callback(response.data);
        dispatch({
          type: TEST.startTest,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const submitAnswer = (data, og_course, filterId, noTimer, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  let params =
    og_course === 'GMAT' || og_course === 'QUESTIONBANK'
      ? {
          filterId: filterId,
        }
      : {};
  let nopopupTimer = og_course === 'GMAT' ? { noTimer: noTimer } : {};
  return dispatch => {
    PUT(
      og_course === 'GMAT' || og_course === 'QUESTIONBANK'
        ? `${DEFAULT_URL}/lms/student/${studentId}/answer`
        : `${DEFAULT_URL}/lms/students/${studentId}/answers`,
      data,
      {
        ...params,
        ...nopopupTimer,
      },
      response => {
        dispatch({
          type: TEST.submitAnswer,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const testAnswers = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const testExecutionId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).testExecutionId
    : '';
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/answer`,
      {},
      response => {
        dispatch({
          type: TEST.testAnswers,
          payload: response.data,
        });

        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const viewExplanation = (questionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/question/${questionId}/explanation`,
      {},
      response => {
        dispatch({
          type: TEST.viewExplanation,
          payload: response.data,
        });

        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
export const updateExamDetails = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/goalSettings`,
      data,
      {},
      response => {
        dispatch({
          type: TEST.updateExamDetails,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const pauseExam = (questionId, testExecutionId, time, callback) => {
  return dispatch => {
    const studentId =
      localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecutions/${testExecutionId}/question/${questionId}/pause`,
      {},
      {
        time: time,
      },
      response => {
        callback(response.data);
        dispatch({
          type: TEST.pauseTest,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getInstructions = (questionSetId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${questionSetId}/testQuestionSetDetails`,
      {},
      response => {
        callback(response.data);
        dispatch({
          type: TEST.getInstructions,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const personalizedStudyPlan = (pid, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${pid}/studyPlans`,
      {},
      {},
      response => {
        dispatch({
          type: TEST.personalizedStudyPlan,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getTestSection = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const testQuestionSetId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).testQuestionSetId
    : '';

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testQuestionsSet/${testQuestionSetId}/testSections`,
      {},
      response => {
        dispatch({
          type: TEST.getTestSectionInstruction,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const sectionOrder = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/testSection/order`,
      data,
      {},
      response => {
        callback(response.data.success);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const cleanUp = () => {
  return dispatch => {
    dispatch({
      type: TEST.cleanUp,
    });
  };
};

export const startTest2 = (testQuestionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testExecutions`,
      {},

      {
        retake: false,
      },

      response => {
        dispatch({
          type: TEST.startTest2,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
export const getIntroductionVideo = callback => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/introductionvideo?name=lmsIntro`,
      {},
      response => {
        dispatch({
          type: TEST.getIntroductionVideo,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
        callback(catchError(error));
      }
    );
  };
};

export const getIntroductionVideos = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  let params = { studentId, productId };
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/introductionvideos`,
      { ...params },
      response => {
        dispatch({
          type: TEST.getIntroductionVideos,
          payload: response.data.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
        callback(catchError(error));
      }
    );
  };
};

export const getStudyPlanDetails = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    GET(
      `${DEFAULT_URL}/student/${studentId}/product/${productId}/studyplan/details`,
      {},
      response => {
        callback(response.data);
      },
      error => {
        callback(catchError(error));
      }
    );
  };
};

export const getCleverTabCommonProperties = (callback = () => {}) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    if (studentId && productId)
      GET(
        `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/clevertapchanges`,
        {},
        response => {
          if (response.data.success) {
            const {
              name,
              email,
              mobileNumber,
              userName,
              collage,
              department,
              productType,
              aspiringCollege,
              targetedGreExamTimeline,
              targetGreScore,
              greAttemptedTimes,
              calibrationTestAttemptedStatus,
              studyPlanDuration,
            } = response.data.data;

            /* CleverTap Common Properties - User */
            let properties = {
              Name: name,
              Email: email,
              Identity: studentId,
              'Mobile number': mobileNumber,
              'User name': userName,
              College: collage,
              Department: department,
              'Product type': productType,
              'Aspiring colleges': aspiringCollege.join(', '),
              'Targeted GRE Exam Timeline': targetedGreExamTimeline || 'Nil',
              'Target GRE score': targetGreScore || 'Nil',
              'GRE test attempts': greAttemptedTimes || 'Nil',
              'Calibration test attempt status': calibrationTestAttemptedStatus,
              'Study plan assigned': `${studyPlanDuration} month`,
            };
            /* CleverTap Common Properties - User */
            window.localStorage.setItem(
              'user_common_properties',
              JSON.stringify(properties)
            );
          }
          callback(response.data);
        },
        error => {
          callback(callback(catchError(error)));
        }
      );
  };
};
