import React from 'react';
import {
  LeftContainer,
  TopicTitleWhite,
  TopicTitleBlue,
  TopicTitleBlur,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';

export const totalCount = data => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count = count + data[i].count;
  }
  return count;
};

const addAllTopics = topicData => {
  if (topicData.length === 0) return [{ title: 'No Questions' }];
  if (topicData.length === 1) return topicData;
  else return [{ id: 'all', title: 'All' }, ...topicData];
};

export default function Left(props) {
  const { topicData, activeTopic, handleActiveTopic } = props;
  if (topicData !== null) {
    let allTopics = addAllTopics(topicData);
    return (
      <LeftContainer>
        {allTopics.map(topic => {
          return (
            <>
              {topic.id === activeTopic ? (
                <TopicTitleBlue
                  key={topic.id}
                  onClick={() => handleActiveTopic(topic.id)}
                >
                  {topic.title}
                </TopicTitleBlue>
              ) : (
                <TopicTitleWhite
                  key={topic.id}
                  onClick={() => handleActiveTopic(topic.id)}
                >
                  {topic.title}
                </TopicTitleWhite>
              )}
            </>
          );
        })}
      </LeftContainer>
    );
  }
}
