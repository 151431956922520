/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/test/Style.css';
import {
  Container,
  Div,
  Explanation,
  ExplanationContainer,
  ExplanationTitle,
  Footer,
  LeftIcon,
  QuestionBody as Body,
  QuestionCount,
  QuestionDivider,
  QuestionTitle,
  RightIcon,
  TestTitle,
  TitleContainer,
  TitleHeader,
} from '../../../../assets/css/test/TestComponent';
import leftIcon from '../../../../assets/images/leftIcon.png';
import rightIcon from '../../../../assets/images/rightIcon.png';
import {
  pauseExam,
  startTest,
  submitAnswer,
  testAnswers,
  viewExplanation,
} from '../../../../redux/action/Test';
import Carousel from '../../../../utils/Carousel';
import PageLoader from '../../../../utils/components/PageLoader';
import VideoPlayerComponent from '../../../../utils/components/VideoPlayerComponent';
import Latex from '../../../../utils/LatexViewer';
import Passage from './Passage';
import SingleSelect from './SingleSelect';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { routePaths } from '../../../../routes/RoutePath';
import QueryString from 'qs';
import { QS_PROPS } from '../../../../constant/Variables';
import Binary from './Binary';
import DropDown from './DropDown';
import MultiTab from './MultiTab';
import TwoPart from './TwoPart';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      selectedChoice: [],
      time: 0,
      answer: '',
      bundleSelect: [],
      modelOpen: false,
      count: parseInt(this.props.match.params.id) - 1,
      answer1: '',
      questions: [],
    };
    this.videosObj = [];
  }
  componentDidMount() {
    const { ss } = this.state;
    let query = new URLSearchParams(this.props.location.search);
    this.setState({ ss: query.get('questionId') });
    console.log('kathir', this.props.location.search);
    // var ss = query.get('questionId');
    this.setState({ ss: query.get('questionId') });
    console.log(ss, this.props.location.search);
    // this.props.testAnswers(({ success, data }) => {
    //   if (success) {
    //     console.log(data, 'success');
    //     this.setState({ questions: data });
    //   }
    // });
    if (!query.get('questionId')) {
      this.props.testAnswers(({ success, data }) => {
        if (success) {
          console.log(data, 'success');
          this.setState({ questions: data });
        }
      });
    } else {
      this.props.viewExplanation(query.get('questionId'), data => {
        if (data) {
          console.log(data.data, 'success');
          this.setState({ questions: data.data, count: 0 });
        }
      });
    }
  }

  onSelect = choice => {
    this.setState({
      selectedChoice: choice.id,
    });
  };

  onBundleChange = (item, choice) => {
    const { bundleSelect } = this.state;
    var arr = [];
    if (bundleSelect.some(exist => exist.bundleNo === item.bundleNo)) {
      if (
        bundleSelect.some(
          exist => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = bundleSelect.filter(
          exist => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = bundleSelect
          .filter(exist => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = bundleSelect.concat({ ...item, ...choice });
    }
    this.setState({ bundleSelect: arr });
  };

  renderQuestion = ({
    type,
    question,
    choices,
    description,
    correctChoices,
    selectedChoices,
    answer,
    isHaveDescription,
    isHaveImage,
    imgURL,
    answer1,
    optionalType,
    unit,
    choiceAnswers,
    multiDescription,
    twoPartAnswers,
    firstSelectedName,
    secondSelectedName,
  }) => {
    if (type === 'SINGLE_SELECT') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          imgUrl={imgURL}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
        />
      );
    } else if (type === 'SINGLE_SELECT_PASSAGE') {
      return (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
        />
      );
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return (
        <Passage
          // para={question}
          question={question}
          subjective={true}
          answer={answer}
          description={description}
          optionalType={optionalType}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'FRACTION') {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          fraction={true}
          onChange={value => this.setState({ answer: value })}
          onChangeCapture={value => this.setState({ answer1: value })}
          fraction1={answer}
          fraction2={answer1}
          imgUrl={imgURL}
          optionalType={optionalType}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'BUNDLE') {
      return (
        <Passage
          description={description}
          para={question}
          bundle={true}
          onChange={this.onBundleChange}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map(item => item.bundleNo)
          )}
          selectedChoice={selectedChoices ? selectedChoices : []}
          correctChoice={correctChoices ? correctChoices : []}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'MULTI_CHOICE') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          imgUrl={imgURL}
          isMulti={true}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          isMulti={true}
        />
      );
    } else if (type === 'BINARY')
      return (
        <Binary
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
        />
      );
    else if (type === 'DROP_DOWN')
      return (
        <DropDown
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
        />
      );
    else if (type === 'MULTI_TAB')
      return (
        <MultiTab
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          multiDescription={multiDescription}
          imgUrl={imgURL}
        />
      );
    else if (type === 'TWO_PART')
      return (
        <TwoPart
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
          twoPartAnswers={twoPartAnswers}
          firstSelectedName={firstSelectedName}
          secondSelectedName={secondSelectedName}
        />
      );
  };

  previous = () => {
    const { count } = this.state;
    if (count > 0) {
      this.setState({
        count: count - 1,
      });
    } else {
      this.setState({
        count: count,
      });
    }
  };

  next = () => {
    const { count, questions } = this.state;
    if (count < questions.length - 1) {
      this.setState({
        count: count + 1,
      });
    } else {
      this.setState({
        count: count,
      });
    }
  };

  getVideoObj = vdoObj => {
    this.videosObj = vdoObj;
  };

  renderVideo = () => {
    const { count, questions } = this.state;
    let videoArr = [...questions[count].video];

    return videoArr.length !== 0 ? (
      <>
        <Carousel>
          {videoArr.map((item, index) => (
            <VideoPlayerComponent
              key={index}
              videoMillSec={0}
              videoOtp={item.videoOtp}
              videoPlaybackInfo={item.videoPlaybackInfo}
              getVideoObj={this.getVideoObj}
              isSlider={true}
            />
          ))}
        </Carousel>
      </>
    ) : null;
  };

  render() {
    if (this.state.questions.length !== 0) {
      const { count, questions } = this.state;
      const { t } = this.props;
      const { renderVideo } = this;
      console.log(questions.length, 'ggg');
      let query = new URLSearchParams(this.props.location.search);
      const { ViewExplain } = QueryString.parse(
        this.props.location.search,
        QS_PROPS
      );
      return (
        <>
          {ViewExplain && (
            <IconButton
              style={{
                position: 'absolute',
                left: 18,
                top: 100,
                zIndex: 2,
                background: 'rgba(16, 147, 255, 0.2)',
              }}
              onClick={() =>
                ViewExplain === 'solveByQuestion'
                  ? this.props.history.push(
                      routePaths.dashboard.solveByQuestion
                    )
                  : ViewExplain === 'solveByPassage'
                  ? this.props.history.push(routePaths.dashboard.solveByPassage)
                  : this.props.history.push(routePaths.dashboard.guide)
              }
            >
              <ArrowBack style={{ color: '#1093FF' }} />
            </IconButton>
          )}
          <Container>
            <Box pl={4}>
              <TestTitle>{t('Test Answerss')}</TestTitle>
              <TitleContainer>
                <TitleHeader>
                  <Div display={'flex'}>
                    {query.get('questionId') ? (
                      <QuestionCount bold="bold">
                        {questions[count].question.conceptName}
                      </QuestionCount>
                    ) : (
                      <>
                        <Box
                          display={'flex'}
                          gridGap={13}
                          className={'nav__min__width'}
                        >
                          {count !== 0 && (
                            <LeftIcon onClick={this.previous}>
                              <img src={leftIcon} alt="leftIcon" />
                            </LeftIcon>
                          )}
                          {count !== this.state.questions.length - 1 && (
                            <RightIcon onClick={this.next}>
                              <img src={rightIcon} alt="rightIcon" />
                            </RightIcon>
                          )}
                        </Box>
                        <QuestionDivider></QuestionDivider>
                        <QuestionCount bold="bold">{count + 1}</QuestionCount>
                        <QuestionCount>/{questions.length}</QuestionCount>
                        <QuestionTitle flex={'unset'}>
                          {questions[count].question.conceptName}
                        </QuestionTitle>
                      </>
                    )}

                    {questions[count].orderNo === null ? (
                      ''
                    ) : (
                      <Box
                        sx={{ fontSize: 18 }}
                        display={'flex'}
                        gridGap={13}
                        flex={1}
                        justifyContent={'flex-end'}
                      >
                        <b style={{ marginRight: '2rem' }}>
                          OG Question Number - {questions[count].orderNo}{' '}
                        </b>
                      </Box>
                    )}
                  </Div>
                </TitleHeader>
              </TitleContainer>
              <Body>
                {questions
                  .slice(count, count + 1)
                  .map(
                    (
                      {
                        correctChoices,
                        answer,
                        question,
                        selectedChoices,
                        answer1,
                        twoPartAnswers,
                        choiceAnswers,
                      },
                      index
                    ) => {
                      return (
                        <React.Fragment key={index}>
                          {this.renderQuestion({
                            selectedChoices,
                            correctChoices,
                            answer,
                            answer1,
                            ...question,
                            twoPartAnswers,
                            choiceAnswers,
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
              </Body>
              <Footer>
                {questions
                  .slice(count, count + 1)
                  .map(({ explanation, explanationVideo }) => {
                    if (
                      (explanation && explanation.trim().length !== 0) ||
                      (questions[count].video &&
                        questions[count].video.length !== 0)
                    )
                      return (
                        <>
                          <ExplanationContainer>
                            <ExplanationTitle>
                              {t('Explanatory Answer')}
                            </ExplanationTitle>
                            <Explanation>
                              <Latex math={explanation || ''} />
                            </Explanation>

                            {questions[count].video &&
                              questions[count].video.length !== 0 && (
                                <Box
                                  p={'1rem 5.5rem 2rem 5.5rem'}
                                  className={'explain_video'}
                                >
                                  {renderVideo()}
                                </Box>
                              )}
                          </ExplanationContainer>
                        </>
                      );
                    else return <></>;
                  })}
              </Footer>
            </Box>
          </Container>
        </>
      );
    } else {
      return <PageLoader />;
    }
  }
}

const mapStateToProps = state => {
  return {
    answers: state.testReducer.testAnswers,
    viewExplanation: state.testReducer.viewExplanation,
  };
};

export default connect(mapStateToProps, {
  startTest,
  submitAnswer,
  pauseExam,
  testAnswers,
  viewExplanation,
})(withTranslation()(Index));
