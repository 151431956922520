/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Box,
  Grid,
  IconButton,
  Link,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CONTACT_US_URL } from '../../../../src/constant/Variables';
import '../../../assets/css/App.css';
import '../../../assets/css/LeftContainer.css';
import { buttonTheme } from '../../../assets/css/MuiStyles';
import { AuthTitle, SubTitle } from '../../../assets/css/StyledComponent';
import { forgotPassword } from '../../../redux/action/Authentication';
import { routePaths } from '../../../routes/RoutePath';
import Button from '../../../utils/components/Button';
import TextField from '../../../utils/components/TextField';
import { isEmail, isEmpty } from '../../../utils/Validation';
class Forgotpassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: false,
      emailMsg: '',
      isLoading: false,
    };
  }
  handleReset = () => {
    this.setState({ isLoading: true });
    if (isEmpty(this.state.email)) {
      this.setState({
        emailError: true,
        emailMsg: 'Please fill the required field',
        isLoading: false,
      });
    } else if (!isEmail(this.state.email)) {
      this.setState({
        emailError: true,
        emailMsg: 'Invalid email',
        isLoading: false,
      });
    } else {
      let obj = {
        email: this.state.email,
      };
      this.props.forgotPassword(obj, response => {
        this.setState({ isLoading: false });
        if (response.success) {
          this.props.history.push(routePaths.sentEmail);
        } else {
          this.setState({
            ...this.state,
            emailError: true,
            emailMsg: 'Please enter registered email',
          });
        }
      });
    }
    window.sessionStorage.setItem('email', this.state.email);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container>
          <IconButton
            style={{
              backgroundColor: '#C4E4FD',
              position: 'absolute',
              left: 18,
              top: 100,
              zIndex: 2,
            }}
            onClick={() => this.props.history.goBack()}
          >
            <ArrowBack style={{ color: '#1093FF' }} />
          </IconButton>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <AuthTitle>{t('Forgot Password')}</AuthTitle>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <SubTitle>
              <Typography className={'_forgot_pass_subTitle'}>
                {t('Confirm your email id and we’ll send the instructions')}
              </Typography>
            </SubTitle>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container>
              <Box width={'100%'}>
                <TextField
                  id={'no-outline'}
                  color="#052A4E"
                  error={this.state.emailError}
                  helperText={t(this.state.emailMsg)}
                  value={this.state.email}
                  label={t('Email')}
                  name="email"
                  fullWidth
                  onChange={e => {
                    this.setState({ emailMsg: '', emailError: false });
                    this.setState({ email: e.target.value });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container>
              <Box pt={0} width={'100%'}>
                <ThemeProvider theme={buttonTheme}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this.handleReset}
                    id={'PasswordButton'}
                    loading={this.state.isLoading}
                  >
                    {t('Reset Password')}
                  </Button>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            style={{ textAlign: 'center' }}
          >
            <Box pt={5}>
              <Typography className={'_forgot_pass_bottom_text'}>
                {/* For any login issues except the password reset, please connect
                with us on the */}
                {t(
                  'Have any questions? We’d love to hear from you.Contact us on'
                )}
              </Typography>
            </Box>
            <Box pt={0}>
              <Typography>
                <Link onClick={() => window.open(CONTACT_US_URL)}>
                  {t('testprep@thecareerlabs.com')}
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { forgotPasswordResponse: state.AuthReducer.forgotPasswordData };
};

export default connect(mapStateToProps, { forgotPassword })(
  withTranslation()(Forgotpassword)
);
