/**
 * List of CleverTap events
 */
const EVENTS = {
  LMSUserLandsOnTheDashboard: 'LMS - User lands on the LMS Dashboard',
  LMSUserSelectsNavigationTab: 'LMS - User selects navigation tab',
  LMSUserSelectsStudyPlan: 'LMS - User selects study plan',
  LMSUserLandsOnTheTopicPage: 'LMS - User lands on the topic page',
  LMSUserCompletesVideo: 'LMS - User completes video',
  LMSUserCompletesATest: 'LMS - User completes a test',
  LMSUserLandsOnWeaknessAndStrengthSections:
    'LMS - User lands on Weakness and strength sections',
  LMSUserReviseAWeakTopics: 'LMS - User revise a weak topics',
  LMSUserClicksThePendingTasks: 'LMS - User clicks the Pending tasks',
  LMSUserClicksTheDailyTask: 'LMS - User clicks the Daily task',
  LMSUserClicksOnTheProgressTracker:
    'LMS - user clicks on the Progress tracker',
  LMSUserClicksOnTakeCalibrationTest:
    'LMS - User clicks on Take Calibration Test',
  LMSUserCompletesTheCalibrationTest:
    'LMS - User completes the Calibration Test',
  LMSUserLoggedIn: 'LMS - User logged in',
};

export { EVENTS };
