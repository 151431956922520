import { Box, Grid } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QueryString from 'qs';
import CloseIcon from '@material-ui/icons/Close';

import {
  BottomTextContainer,
  CardView,
  Heading,
  IntroHeading,
  IntroVideo,
  IntroWarning,
  SkipButton,
  Text,
  Video,
} from '../../../../assets/css/StyledComponent';
import Card from '@mui/material/Card';
import Carousel from 'react-material-ui-carousel';
import { QS_PROPS } from '../../../../constant/Variables';
import {
  FlexEnd,
  IntroFlexEnd,
} from '../../../../assets/css/StyledDashboardComponent';
import { getIntroductionVideos } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import PageLoader from '../../../../utils/components/PageLoader';
import VideoPlayerComponent from '../../../../utils/components/VideoPlayerComponent';
import { flexbox } from '@mui/system';
import Slider from './swiper';

class Introduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: {},
      loading: true,
      profile: false,
    };
    this.videosObj = [];
    this.settings = {
      effect: 'coverflow',
      centeredSlides: true,
      slidesPerView: 2,
      coverflowEffect: {
        rotate: 0, // Slide rotate in degrees
        stretch: 30, // Stretch space between slides (in px)
        // depth: 300, // Depth offset in px (slides translate in Z axis)
        modifier: 1, // Effect multipler
        slideShadows: false, // Enables slides shadows
      },
    };
  }

  componentDidMount() {
    const { profile } = QueryString.parse(this.props.location.search, QS_PROPS);
    window.scrollTo(0, 0);
    this.props.getIntroductionVideos(response => {
      if (response.success) {
        this.setState({
          video: response.data,
          loading: false,
        });
        if (profile) {
          this.setState({
            profile: true,
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  getVideoObj = (vdoObj, index) => {
    this.videosObj[index] = vdoObj;
    const { video, loading, videoEnable } = this.state;

    this.setState({
      videoEnable: index,
    });
  };

  handleClick = () => {
    console.log(this.state.profile, 'profile value');

    if (this.state.profile === true) {
      this.props.history.push(routePaths.dashboard.home);
    } else {
      this.props.history.push(routePaths.start);
    }
  };

  render() {
    const { video, loading, videoEnable } = this.state;

    if (videoEnable === 1) {
      console.log('hjhj');
      this.videosObj[0]?.pause();
    } else {
      this.videosObj[1]?.pause();
    }

    return loading ? (
      <PageLoader />
    ) : (
      <div>
        {this.state.profile === true ? (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
          >
            {' '}
            <CloseIcon
              style={{ backgroundColor: 'darkgrey' }}
              onClick={this.handleClick}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
        ></Grid>

        <Slider settings={this.settings}>
          {video.videos?.map((el, index) => {
            return (
              <Grid item md={6} key={index}>
                <IntroHeading> {el.title}</IntroHeading>

                <IntroFlexEnd>
                  <IntroVideo pt={8}>
                    <VideoPlayerComponent
                      videoMillSec={0}
                      videoOtp={el.videoOtp}
                      videoPlaybackInfo={el.videoPlaybackInfo}
                      getVideoObj={this.getVideoObj}
                      index={index}
                      isSlider={false}
                      isAutoRouting={true}
                      handleAutoRoute={this.handleClick}
                    />
                  </IntroVideo>
                </IntroFlexEnd>
              </Grid>
            );
          })}
        </Slider>
        {this.state.profile !== true ? (
          <Grid item xs={12} md={12}>
            <Box pt={5}>
              <BottomTextContainer>
                <IntroWarning>
                  {' '}
                  We strongly recommend to <b>NOT</b> skip this video
                </IntroWarning>

                <SkipButton
                  className={'gmat_button_align'}
                  variant="contained"
                  color="primary"
                  onClick={this.handleClick}
                >
                  Skip anyway
                </SkipButton>
              </BottomTextContainer>
            </Box>
          </Grid>
        ) : (
          ''
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    video: state.testReducer.videos,
  };
};

export default connect(mapStateToProps, {
  getIntroductionVideos,
})(withRouter(Introduction));
