/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Badge, Box, ThemeProvider } from '@material-ui/core';
import { Circle } from 'rc-progress';
import React, { Component } from 'react';
import { GreenTick, GreyTick } from '../../../assets/css/StyledComponent';
import { CircleHead, CircleText, dotTheme } from '../../../assets/css/Styles';
import Dot from '../../../assets/images/dot.png';
import { routePaths } from '../../../routes/RoutePath';
import QueryString from 'qs';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { QS_PROPS } from '../../../constant/Variables';

class CompletedProgress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      text: 'Completed',
    };
  }

  timer = null;
  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        progress: prevState.progress >= 100 ? 0 : prevState.progress + 10,
      }));
    }, 800);
  }

  componentDidUpdate(previousState) {
    const {
      testExecutionId,
      test_type,
      qbank_type,
      og_course,
      diagnostic,
      topicId,
      subject,
      type,
    } = QueryString.parse(this.props.location.search, QS_PROPS);
    if (this.state.progress === 100) {
      clearInterval(this.timer);
      setTimeout(() => {
        if (test_type === 'OG_PRACTISETEST' && og_course) {
          if (og_course === 'GRE')
            this.props.history.push(
              `${routePaths.dashboard.greOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
            );
          // else if(og_course === 'QUESTIONBANK')
          // this.props.history.push(
          //   `${routePaths.dashboard.questionBankReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
          // );
          else
            this.props.history.push(
              `${routePaths.dashboard.gmatOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
            );
        } else if (test_type === 'QUESTIONBANK') {
          this.props.history.push(
            `${routePaths.dashboard.questionBankReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}&qbank_type=${qbank_type}`
          );
        } else if (diagnostic === 'diagnostic-engine') {
          this.props.history.push(
            `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
          );
        } else if (subject === 'subject') {
          this.props.history.push(
            `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}&subject=subject&topicId=${topicId}&type=${type}`
          );
        } else
          this.props.history.push(
            `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}`
          );
      }, 3000);
    }
  }

  render() {
    const { topic } = QueryString.parse(this.props.location.search, QS_PROPS);
    const { t } = this.props;
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Box position="relative" display="inline-flex" pt={14}>
          <ThemeProvider theme={dotTheme}>
            <Badge
              // badgeContent={<img src={Dot} alt="" />}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Circle
                style={{
                  width: '262',
                  height: '262',
                  transform: 'rotate(-45deg)',
                }}
                percent={this.state.progress}
                trailWidth="5"
                strokeWidth="5"
                strokeColor="#37E288"
                gapDegree="20"
              />
            </Badge>
          </ThemeProvider>
          <Box
            top={100}
            left={0}
            bottom={5}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {this.state.progress === 100 ? <GreenTick /> : <GreyTick />}
          </Box>
        </Box>
        <Box pt={8}>
          <CircleHead>
            {t(
              `${topic} Successfully ${
                this.state.progress === 100 ? 'Submitted' : 'Completed'
              }`
            )}
          </CircleHead>
          <CircleText style={{ paddingTop: '30px' }}>
            {t('Taking you to your results')}
          </CircleText>
        </Box>
      </Box>
    );
  }
}

export default withRouter(withTranslation()(CompletedProgress));