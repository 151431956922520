/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  BeforeIcon,
  Landing,
  LandingSubTitle,
  LandingTitle,
  NextIcon,
} from '../../assets/css/StyledComponent';
import leftIcon from '../../assets/images/leftIcon.png';
import rightIcon from '../../assets/images/rightIcon.png';
import { MONTH } from '../../constant/Variables';

class LandingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: new Date().toLocaleString('default', { month: 'long' }),
      year: new Date().getFullYear(),
    };
  }

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  //  d = new Date();
  //  monthName= this.months[new Date().getMonth() +1]; // "July" (or current month)

  handleBefore = () => {
    this.setState({
      month: this.months[new Date().getMonth() - 0],
    });
  };

  handleNext = () => {
    this.setState({
      month: this.months[new Date().getMonth() + 1],
    });
  };

  getMonthAndYear() {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let arr = [];
    for (let i = 0; i <= 6; i++) {
      var today = new Date();
      if (i === 0) {
        today.setMonth(today.getMonth());
      } else {
        today.setMonth(today.getMonth() + i);
      }

      let obj = {
        year: today.getFullYear(),
        month: months[today.getMonth()],
      };
      arr.push(obj);
    }
    return arr;
  }

  render() {
    const { data, month, currentLen, year, t } = this.props;
    return (
      <Landing>
        <Box display="flex" flexDirection="column">
          <Box display="flex" pb={1.5}>
            <LandingTitle>
              {MONTH[month]} {year}
            </LandingTitle>

            <Box pl={5}>
              {data.length > 1 && (
                <BeforeIcon onClick={this.props.prev} disabled={currentLen < 1}>
                  <img src={leftIcon} alt="leftIcon" />
                </BeforeIcon>
              )}
            </Box>
            <Box pl={2}>
              {data.length !== currentLen && data.length > 1 && (
                <NextIcon
                  onClick={this.props.next}
                  disabled={data.length - 1 <= currentLen}
                >
                  <img src={rightIcon} alt="rightIcon" />
                </NextIcon>
              )}
            </Box>
          </Box>
          <LandingSubTitle>{t('Your Personalized Study Plan')}</LandingSubTitle>
        </Box>
      </Landing>
    );
  }
}

export default withRouter(withTranslation()(LandingCard));
