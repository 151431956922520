/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  CalendarBody,
  Card,
  CardContent,
  CardHeader,
  Task,
} from '../../../../assets/css/dashboard/CalendarComponents';
import StatusIcon from '../../../../assets/icons/StatusIcon';

function Body({ topics, handleTaskClick }) {
  return (
    <CalendarBody id="calendar-scroll-box">
      {topics.map(item => {
        return (
          <Card>
            <CardHeader title={item.topicName} />
            <CardContent>
              {item.tasks.map(task => {
                return (
                  <Task
                    status={task.status}
                    onClick={() => handleTaskClick(item.id, task.id)}
                  >
                    <img src={StatusIcon(task.status)} alt="" />
                    &nbsp;
                    {task.name}
                  </Task>
                );
              })}
            </CardContent>
          </Card>
        );
      })}
    </CalendarBody>
  );
}

export default Body;
