/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../assets/css/LeftContainer.css';
import { buttonTheme } from '../../../assets/css/MuiStyles';
import { resetPassword } from '../../../redux/action/Authentication';
import { routePaths } from '../../../routes/RoutePath';
import Button from '../../../utils/components/Button';
import TextField from '../../../utils/components/TextField';
import { isValdAlpNumSpcUdscrHyph } from '../../../utils/Validation';

class Resetpassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPass: null,
      newPassErr: false,
      newPassMsg: '',
      showNewPass: '',
      confirmPass: null,
      confirmPassErr: false,
      confirmPassMsg: '',
      showConfirmPass: '',
      isLoading: false,
    };
  }

  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (!this.state.newPass && !this.state.confirmPass) {
      this.setState({
        newPassErr: true,
        confirmPassErr: true,
        newPassMsg: 'Please fill the required field',
        confirmPassMsg: 'Please fill the required field',
        isLoading: false,
      });
    }
    if (!this.state.newPass) {
      this.setState({
        newPassErr: true,
        newPassMsg: 'Please fill the required field',
        isLoading: false,
      });
    } else if (!this.state.confirmPass) {
      this.setState({
        confirmPassErr: true,
        confirmPassMsg: 'Please fill the required field',
        isLoading: false,
      });
    } else if (this.state.newPass !== this.state.confirmPass) {
      this.setState({
        // newPassErr: true,
        confirmPassErr: true,
        // newPassMsg: "Password mismatch",
        confirmPassMsg: 'Password mismatch',
        isLoading: false,
      });
    } else if (
      !isValdAlpNumSpcUdscrHyph(this.state.newPass) &&
      !isValdAlpNumSpcUdscrHyph(this.state.confirmPass)
    ) {
      this.setState({
        newPassErr: true,
        confirmPassErr: true,
        newPassMsg: 'Password format incorrect',
        confirmPassMsg: 'Password format incorrect',
        isLoading: false,
      });
    } else {
      let query = new URLSearchParams(this.props.location.search);

      let obj = {
        password: this.state.confirmPass,
        token: query.get('token'),
      };
      this.props.resetPassword(obj, response => {
        this.setState({ isLoading: false });
        if (response.success) this.props.history.push(routePaths.verifyEmail);
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box>
              <h2 className={'_reset_password_title'}>
                {t('Reset Your Password')}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={4}>
              <Typography className={'_reset_password_subTitle'}>
                {t(
                  'Your new password must be different from previously used passwords'
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container>
              <Box pt={3} width={'100%'}>
                <Tooltip title="Password must be a combination of at least 8 alphanumeric and special characters">
                  <TextField
                    id={'no-outline'}
                    required
                    fullWidth
                    name={'new password'}
                    label={t('New Password')}
                    type={this.state.showNewPass ? 'text' : 'password'}
                    value={this.state.newPass}
                    onChange={e => {
                      this.setState({ newPassMsg: '', newPassErr: false });
                      this.setState({ newPass: e.target.value });
                    }}
                    // id="resetPassField"
                    inputProps={{
                      id: 'resetPassField',
                    }}
                    error={this.state.newPassErr}
                    helperText={t(this.state.newPassMsg)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({
                                showNewPass: !this.state.showNewPass,
                              })
                            }
                          >
                            {this.state.showNewPass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid container>
              <Box pt={3} width={'100%'}>
                <TextField
                  id={'no-outline'}
                  required
                  fullWidth
                  name={'confirm password'}
                  label={t('Confirm Password')}
                  type={this.state.showConfirmPass ? 'text' : 'password'}
                  value={this.state.confirmPass}
                  onChange={e => {
                    this.setState({
                      confirmPassMsg: '',
                      confirmPassErr: false,
                    });
                    this.setState({ confirmPass: e.target.value });
                  }}
                  error={this.state.confirmPassErr}
                  helperText={t(this.state.confirmPassMsg)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            this.setState({
                              showConfirmPass: !this.state.showConfirmPass,
                            })
                          }
                        >
                          {this.state.showConfirmPass ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={3} fullWidth>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  id={'resetPassSubmitBtn'}
                  loading={this.state.isLoading}
                >
                  {t('Submit')}
                </Button>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { resetPasswordResponse: state.AuthReducer.resetPasswordData };
};

export default connect(mapStateToProps, { resetPassword })(
  withTranslation()(Resetpassword)
);
