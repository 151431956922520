/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import GuideContent from './GuideContent';
import { connect } from 'react-redux';
import {
  removeDemo,
  isVisited,
} from '../../../../redux/action/DashboardLanding';
import '../../../../assets/css/dashboard/UserGuide.css';

export class Guide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGuide: false,
      focusComponent: '',
    };

    this.list = [
      '.on-focus-2nd',
      '.on-focus-3rd',
      '.on-focus-4th',
      '.on-focus-5th',
      '.on-focus-6th',
    ];
    this.value = 0;
  }

  componentDidMount() {
    const htmlElement = document.querySelector('body');
    const header = document.getElementById('app-bar-style');

    if (JSON.parse(localStorage.getItem('showDashboardGuide'))) {
      window.addEventListener('click', this.handleClick, true);

      htmlElement.classList.add('overflow-hidden');

      header.classList.add('header-z-index-class');

      this.setState({
        showGuide: true,
        focusComponent: '.on-focus-1st',
      });
    } else {
      this.setState({ showGuide: false, focusComponent: '' });
    }
  }

  handleClick = e => {
    e.preventDefault();
    e.target.onclick = null;
    e.stopPropagation();

    this.setState({ focusComponent: this.list[this.value] });
    this.value++;
    if (this.value === 6) {
      const htmlElement = document.querySelector('body');
      const header = document.getElementById('app-bar-style');

      this.setState({ showGuide: false });

      this.props.removeDemo('DASHBOARD', res => {
        this.props.isVisited('DASHBOARD');
      });

      window.removeEventListener('click', this.handleClick, true);

      htmlElement.classList.remove('overflow-hidden');

      header.classList.remove('header-z-index-class');

      localStorage.setItem('showDashboardGuide', 'false');
    }
  };

  render() {
    return (
      <GuideContent
        isOpen={this.state.showGuide}
        focusComponent={this.state.focusComponent}
        handleClick={this.handleClick}
      />
    );
  }
}

export default connect(() => {}, {
  removeDemo,
  isVisited,
})(Guide);
