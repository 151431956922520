/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import {
  DialogTitle,
  CloseSharp,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '../../../../../assets/css/GmatStyles';
import LoadingSpinners from '../../../../../utils/components/LoadingSpinner';

function Confirmation({ onYes, onNo, open, loading }) {
  return (
    <Dialog fullWidth maxWidth={'xs'} open={open}>
      <DialogTitle disableTypography>
        <span className="confirmation_dialog_header">Answer Confirmation</span>
        <IconButton onClick={onNo}>
          <CloseSharp color="secondary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="confirmation_dialog_body">
          Click Yes to confirm your answer and continue to the next Question
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={onYes}
          style={
            loading === true
              ? { background: 'grey', color: '#fff' }
              : { color: '#fff' }
          }
        >
          <Box
            display={'flex'}
            gridGap={15}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {loading && <LoadingSpinners type="circle" />}
            <Box>Yes</Box>
          </Box>
        </Button>
        <Button variant="contained" color="primary" onClick={onNo}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirmation;
