import { Box, Card, Grid } from '@material-ui/core';
import React from 'react';
import '../../../../assets/css/App.css';
import {
  FlatTextContent,
  OfficialGuideTaskCard,
} from '../../../../assets/css/StyledComponent';
import activeTextVideo from '../../../../assets/icons/ActiveTextVideo.svg';
import TextVideo from '../../../../assets/icons/TextVideo.svg';
import activeBook from '../../../../assets/images/activeBook.png';
import activeVideo from '../../../../assets/images/activeVideo.png';
import Book from '../../../../assets/images/Book.png';
import ShapeImage from '../../../../assets/images/Shape.png';

function LeftCard(props) {
  const topicTypeIcon = type => {
    if (type === 'TEXT') return Book;
    if (type === 'VIDEO') return ShapeImage;
    if (type === 'TEXT_VIDEO') return TextVideo;
  };

  const activeTopicTypeIcon = type => {
    if (type === 'TEXT') return activeBook;
    if (type === 'VIDEO') return activeVideo;
    if (type === 'TEXT_VIDEO') return activeTextVideo;
  };

  const { topicData } = props;
  return (
    <Grid container direction="column">
      <Card className={'left-side-card official-guide-left-task-card'}>
        {topicData.tasks &&
          topicData.tasks.map(item => {
            return (
              <OfficialGuideTaskCard
                id={item.id}
                active={props.selectedStep === item.id}
                onClick={props.handleLeftCardClick}
              >
                <Grid
                  container
                  justifyContent={'space-between'}
                  wrap={'nowrap'}
                  alignItems={'center'}
                  id={item.id}
                >
                  <Box pr={2} id={item.id}>
                    <FlatTextContent id={item.id}>{item.title}</FlatTextContent>
                  </Box>
                  <div className={'right-side-card'} id={item.id}>
                    {props.selectedStep === item.id ? (
                      <img
                        src={activeTopicTypeIcon(item.type)}
                        alt="Icons"
                        id={item.id}
                      />
                    ) : (
                      <img
                        src={topicTypeIcon(item.type)}
                        alt="Icons"
                        id={item.id}
                      />
                    )}
                  </div>
                </Grid>
              </OfficialGuideTaskCard>
            );
          })}
      </Card>
    </Grid>
  );
}
export default LeftCard;
