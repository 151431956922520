/**
 * (c) CareerLabs. All rights reserved.
 **/
import { STUDENT } from '../Action';

const initialState = {
  user: [],
};

const StudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT.getStudent: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case STUDENT.userLogout: {
      return initialState;
    }
    default:
      break;
  }
  return state;
};

export default StudentReducer;
