/**
 * (c) CareerLabs. All rights reserved.
 **/
import { MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { Component } from 'react';
import '../../../../assets/css/Style.css';
import { SubHeading, SubItem } from '../../../../assets/css/StyledComponent';
import {
  Colorbox,
  Flexdoughnut,
  FlexEnd,
} from '../../../../assets/css/StyledDashboardComponent.js';
import { DROP_DOWN_PROPS } from '../../../../constant/Variables';
import Doughnut from '../../course/graph/Doughnut';

export default class DoughnutCard extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data;
  }

  render() {
    const { scoreTitle, score, colorCode, overall } = this.props.data;
    const { handleClick, active, concept, t } = this.props;
    var data = {
      datasets: [
        {
          labels: scoreTitle,
          data: score,
          backgroundColor: colorCode,
          // cutout:80,
        },
      ],
      percentage: overall.percentage,
      summaryName: overall.title,
      total: overall.total,
      count: overall.score,
    };

    return (
      <>
        {concept && concept.length !== 0 && (
          <FlexEnd>
            <Select
              override={true}
              onChange={handleClick}
              label="Concept"
              IconComponent={ExpandMore}
              disableUnderline
              value={active}
              MenuProps={{ ...DROP_DOWN_PROPS }}
            >
              {concept.map(({ title }, index) => (
                <MenuItem value={index}>{title}</MenuItem>
              ))}
            </Select>
          </FlexEnd>
        )}
        <Flexdoughnut>
          <Doughnut data={data} t={t} />
          <div>
            <p>{t('Total Summary')}</p>
            {/* <h4>{data.count+"/"+data.total}</h4>   */}
            <div className={'task-align'}>
              <SubHeading>{data.count}/</SubHeading>
              <SubItem>{data.total}</SubItem>
            </div>
          </div>
        </Flexdoughnut>
        <Flexdoughnut style={{ marginTop: '30px' }}>
          {scoreTitle.map((item, index) => {
            return (
              <div>
                <Colorbox style={{ background: colorCode[index] }} />
                <p>{t(item)}</p>
                <h5>{score[index]}</h5>
              </div>
            );
          })}
        </Flexdoughnut>
      </>
    );
  }
}
