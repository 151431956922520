/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: 10vh;
  background: linear-gradient(180deg, #eef6ff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const TitleText = styled.div`
  font-size: 120px;
  font-weight: 600;
  color: #052a4e;
`;

export const IntroText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;

export const BodyText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
  padding-top: 2rem;
`;
export const HeadText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 61px;
  color: #052a4e;
`;
export const CourseHeading = styled.div`
  width: 1366px;
  height: 149px;

  background: linear-gradient(
    180deg,
    rgba(255, 213, 0, 0.1) 0%,
    rgba(255, 233, 119, 0) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const FooterNumber = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  margin-bottom: 10px;
  line-height: 41px;

  /* Text Color 1 - 00012C */

  color: #00012c;
`;

export const FooterText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Text Color 1 - 00012C */

  color: #00012c;
`;

export const ReviewHeading = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  text-align: center;

  /* text */

  color: #052a4e;
`;

export const CourseDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px 1px;
  grid-gap: 20;
`;

export const CourseInner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px 1px;
`;
