import React from 'react';
import LatexViewer from '../../../../utils/LatexViewer';
import { Box, Divider, Grid } from '@material-ui/core';
import { Question } from '../../../../assets/css/StyledComponent';
import { Div, AnserFlex } from '../../../../assets/css/test/TestComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function DropDown(props) {
  const {
    question,
    options,
    choiceAnswers,
    isHaveDescription,
    description,
    imgUrl,
  } = props;

  return (
    <Grid container>
      {isHaveDescription && (
        <Grid md={isHaveDescription === false ? 12 : 6}>
          <Box height={'100%'} display={'flex'}>
            <Box flex={1} className={'question-left-container'}>
              <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                <LatexViewer math={description} />
              </Box>
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
                )}
              </Box>
            </Box>
            <Box>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item md={isHaveDescription === false ? 12 : 6}>
        <Box>
          <Div>
            <Question>
              <LatexViewer math={question} />
            </Question>

            {options.map((item, index) => {
              const getcorrect = choiceAnswers.filter(
                (correct, correctIndex) => correct.id === item.id
              );
              return (
                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                  <Grid item xs style={{ alignSelf: 'center' }}>
                    <LatexViewer math={item.text} />
                    <AnserFlex>
                      <h4>Answer:</h4>
                      <b>
                        {getcorrect[0].score === 1
                          ? getcorrect[0].correctAnswer
                          : getcorrect[0].wrongAnswer}
                      </b>
                      {getcorrect[0].score === 1 ? (
                        <CheckCircleIcon
                          sx={{
                            color: 'green !important',
                          }}
                        />
                      ) : (
                        <CancelIcon
                          sx={{
                            color: 'red !important',
                          }}
                        />
                      )}
                    </AnserFlex>
                    {getcorrect[0].score !== 1 && (
                      <AnserFlex>
                        <h4>Correct Answer:</h4>
                        <b>{getcorrect[0].correctAnswer}</b>
                        <CheckCircleIcon
                          sx={{
                            color: 'green !important',
                          }}
                        />
                      </AnserFlex>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Div>
        </Box>
      </Grid>
    </Grid>
  );
}
