/**
 * (c) CareerLabs. All rights reserved.
 **/
import axios from 'axios';
import { DEFAULT_URL } from '../api/API';

axios.interceptors.request.use(
  function (request) {
    request.headers = {
      ...request.headers,
      // 'Access-Control-Allow-Origin': '*',
    };
    request.baseURL = DEFAULT_URL;
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const GET = (url, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .get(url, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};
export const GET_OG = (url, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .get(url, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const POST = (url, data, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .post(url, data, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const PUT = (url, data, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .put(url, data, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};
