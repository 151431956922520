/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

function Back({ onClick }) {
  return (
    <IconButton
      style={{
        backgroundColor: '#C4E4FD',
        position: 'absolute',
        left: 18,
        top: 100,
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <ArrowBack style={{ color: '#1093FF' }} />
    </IconButton>
  );
}

export default Back;
