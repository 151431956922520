import React, { Component } from 'react';
import AccountSetting from './AccountSetting';
import { getStudentInfo } from '../../../redux/action/Student';
import { connect } from 'react-redux';

class Index extends Component {
  componentDidMount() {
    this.props.getStudentInfo();
  }

  render() {
    return (
      <AccountSetting
        data={this.props.student}
        courses={this.props.courses && this.props.courses.data}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    student: state.studentReducer.user,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, { getStudentInfo })(Index);
