/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  linkButton,
  primaryButtonTheme,
} from '../../../../assets/css/MuiStyles';
import {
  AddMoreButton,
  NextButton,
  PreviousButton,
  RemoveButton,
} from '../../../../assets/css/StyledComponent';
import { updateEmployeeInfo } from '../../../../redux/action/Profile';
import { getStudentInfo } from '../../../../redux/action/Student';
import { routePaths } from '../../../../routes/RoutePath';
import DatePicker from '../../../../utils/components/DatePicker';
import Autocomplete from '../../../../utils/components/DropDown';
import TextField from '../../../../utils/components/TextField';
import CustomizedSwitch from '../../../../utils/CustomizedSwitch';
import { dateFormat } from '../../../../utils/methods/DateFormat';

class EmployeeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: [
        {
          employmentType: '',
          employmentStatus: false,
          organization: '',
          role: '',
          startDate: '',
          endDate: '',
          description: '',
        },
      ],
      error: false,
      errorMsg: [{}],
    };
  }

  componentDidMount() {
    if (this.props.user.length === 0) this.props.getStudentInfo();
    else this.fetchData();
  }

  fetchData() {
    const { employment } = this.props.user;
    const errorMsg = [];

    if (employment) {
      var employmentList = employment;
      for (let i = 0; i < employmentList.length; i++) {
        errorMsg.push({});
        if (employmentList[i].employmentStatus) {
          employmentList[i].endDate = 'none';
        }
      }
      if (employmentList.length > 0) {
        this.setState({
          employee: employmentList,
          errorMsg,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.fetchData();
    }
  }

  onChange(event, index) {
    const { errorMsg } = this.state;

    let items = this.state.employee;
    var item = {
      ...items[index],
      [event.target.name]: event.target.value,
    };
    if (event.target.name === 'employmentStatus') {
      if (event.target.value) {
        item = {
          ...items[index],
          [event.target.name]: event.target.value,
          endDate: '00-00-0000',
        };
        errorMsg[index][`endDateErr`] = '';
      } else {
        item = {
          ...items[index],
          [event.target.name]: event.target.value,
          endDate: '',
        };
      }
    }

    items[index] = item;
    errorMsg[index][`${event.target.name}Err`] = '';
    this.setState({
      employee: items,
      errorMsg,
    });
  }

  removeItem = index => {
    if (this.state.employee.length > 1) {
      // let items = this.state.employee;
      const { errorMsg, employee } = this.state;
      employee.splice(index, 1);
      errorMsg.splice(index, 1);

      this.setState({
        employee,
        errorMsg,
      });
    }
  };

  onDropDownValue = (name, value, index, id) => {
    const { errorMsg } = this.state;

    if (value !== null) {
      let items = this.state.employee;
      let item = {
        ...items[index],
        id: id,
        [name]: value.value,
      };
      items[index] = item;
      errorMsg[index][`${name}Err`] = '';

      this.setState({ employee: items, errorMsg });
    }
  };

  addDetail = () => {
    let obj = {
      employmentType: '',
      employmentStatus: false,
      organization: '',
      role: '',
      startDate: '',
      endDate: '',
      description: '',
    };
    let arr = this.state.employee.concat(obj);

    const { errorMsg } = this.state;
    errorMsg.push({});

    this.setState({ employee: arr, errorMsg });
  };

  handleSubmit = () => {
    const { employee, errorMsg } = this.state;

    var error = false;

    for (let i = 0; i < employee.length; i++) {
      for (const [key, value] of Object.entries(employee[i])) {
        if (
          key !== 'month' &&
          key !== 'localStartDate' &&
          key !== 'localEndDate'
        ) {
          if (value === '' || value === null) {
            error = true;
            errorMsg[i][`${key}Err`] = 'Please fill the required field';
          }
        }
      }

      if (
        employee[i].endDate !== 'none' &&
        employee[i].endDate !== '00-00-0000'
      ) {
        if (
          employee[i].endDate !== '' &&
          new Date(employee[i].startDate) >= new Date(employee[i].endDate)
        ) {
          error = true;
          errorMsg[i][`endDateErr`] =
            'End date should be greater than the Start date';
        }
      }

      this.setState({ errorMsg });
    }

    // Submitting the form
    if (!error) {
      let requestBody = JSON.parse(JSON.stringify(employee));
      for (let i = 0; i < requestBody.length; i++) {
        requestBody[i]['startDate'] = requestBody[i]['startDate']
          ? dateFormat(new Date(requestBody[i]['startDate']))
          : requestBody[i]['startDate'];
        requestBody[i]['endDate'] = requestBody[i]['endDate']
          ? dateFormat(new Date(requestBody[i]['endDate']))
          : requestBody[i]['endDate'];
      }

      this.props.updateEmployeeInfo(requestBody, res => {
        this.props.history.push(routePaths.educationDetail);
      });
    }
  };

  getLabel = value => {
    if (value === 'FULL_TIME') {
      return { name: 'Full Time', value: value };
    } else if (value === 'PART_TIME') {
      return { name: 'Part Time', value: value };
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Grid container className={'grid__container'} spacing={3}>
        {this.state.employee.map((item, index) => {
          return (
            <Grid
              container
              spacing={3}
              item
              xs={12}
              className={index !== 0 ? 'employee__detail__card' : ''}
              style={{ position: 'relative' }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box position={'absolute'} right={50}>
                  <RemoveButton
                    visible={!(this.state.employee.length === 1)}
                    onClick={() => this.removeItem(index)}
                  >
                    {t('Remove')}
                  </RemoveButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                <Autocomplete
                  id="no-outline"
                  options={[
                    { name: 'Full Time', value: 'FULL_TIME' },
                    { name: 'Part Time', value: 'PART_TIME' },
                  ]}
                  name={'employmentType'}
                  getOptionLabel={option => option.name}
                  fullWidth
                  value={{
                    name:
                      item.employmentType === 'FULL_TIME'
                        ? 'Full Time'
                        : item.employmentType === 'PART_TIME'
                        ? 'Part Time'
                        : '',
                  }}
                  onChange={(e, value) =>
                    this.onDropDownValue(
                      'employmentType',
                      value,
                      index,
                      item.id
                    )
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('Employment')}
                      name={'employmentType'}
                      variant="standard"
                      error={
                        this.state.errorMsg[index] &&
                        this.state.errorMsg[index][`employmentTypeErr`] !==
                          '' &&
                        this.state.errorMsg[index][`employmentTypeErr`] !==
                          undefined
                      }
                      helperText={t(
                        this.state.errorMsg[index]
                          ? this.state.errorMsg[index][`employmentTypeErr`]
                          : ''
                      )}
                      autoComplete={true}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                <CustomizedSwitch
                  name={'employmentStatus'}
                  label={t('Currently Employed')}
                  onChange={(e, value) =>
                    this.onChange(
                      { target: { name: 'employmentStatus', value: value } },
                      index
                    )
                  }
                  value={item.employmentStatus}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                <TextField
                  id="no-outline"
                  variant="standard"
                  label={t('Organisation')}
                  required
                  fullWidth
                  value={item.organization}
                  error={
                    this.state.errorMsg[index] &&
                    this.state.errorMsg[index][`organizationErr`] !== '' &&
                    this.state.errorMsg[index][`organizationErr`] !== undefined
                  }
                  helperText={t(
                    this.state.errorMsg[index]
                      ? this.state.errorMsg[index][`organizationErr`]
                      : ''
                  )}
                  name={'organization'}
                  onChange={e => this.onChange(e, index)}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                <TextField
                  id="no-outline"
                  variant="standard"
                  label={t('Current Role')}
                  required
                  name={'role'}
                  fullWidth
                  value={item.role}
                  error={
                    this.state.errorMsg[index] &&
                    this.state.errorMsg[index][`roleErr`] !== '' &&
                    this.state.errorMsg[index][`roleErr`] !== undefined
                  }
                  helperText={t(
                    this.state.errorMsg[index]
                      ? this.state.errorMsg[index][`roleErr`]
                      : ''
                  )}
                  onChange={e => this.onChange(e, index)}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                <TextField
                  variant="standard"
                  label={t('Role Description')}
                  required
                  name={'description'}
                  multiline
                  fullWidth
                  value={item.description}
                  error={
                    this.state.errorMsg[index] &&
                    this.state.errorMsg[index][`descriptionErr`] !== '' &&
                    this.state.errorMsg[index][`descriptionErr`] !== undefined
                  }
                  helperText={t(
                    this.state.errorMsg[index]
                      ? this.state.errorMsg[index][`descriptionErr`]
                      : ''
                  )}
                  onChange={e => this.onChange(e, index)}
                  InputProps={{
                    maxlength: 100,
                  }}
                />
                <Box
                  display="flex"
                  justifyContent={'flex-end'}
                  color="#686868"
                  pt={1}
                  fontSize={10}
                >
                  {item.description === null ? 0 : item.description.length}
                  /100
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                    <DatePicker
                      id={'no-outline'}
                      onChange={date =>
                        this.onChange(
                          { target: { value: date, name: 'startDate' } },
                          index
                        )
                      }
                      value={item.startDate}
                      error={
                        this.state.errorMsg[index] &&
                        this.state.errorMsg[index][`startDateErr`] !== '' &&
                        this.state.errorMsg[index][`startDateErr`] !== undefined
                      }
                      helperText={t(
                        this.state.errorMsg[index]
                          ? this.state.errorMsg[index][`startDateErr`]
                          : ''
                      )}
                      label={t('Start Date')}
                      disableFuture
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                    {!item.employmentStatus && (
                      <DatePicker
                        id={'no-outline'}
                        onChange={date =>
                          this.onChange(
                            { target: { value: date, name: 'endDate' } },
                            index
                          )
                        }
                        disableFuture
                        value={item.endDate}
                        error={
                          this.state.errorMsg[index] &&
                          this.state.errorMsg[index][`endDateErr`] !== '' &&
                          this.state.errorMsg[index][`endDateErr`] !== undefined
                        }
                        helperText={t(
                          this.state.errorMsg[index]
                            ? this.state.errorMsg[index][`endDateErr`]
                            : ''
                        )}
                        label={t('End Date')}
                        required
                        fullWidth
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            </Grid>
          );
        })}
        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
          <ThemeProvider theme={linkButton}>
            {!this.state.employee[this.state.employee.length - 1]
              .employmentStatus && (
              <AddMoreButton fullWidth onClick={this.addDetail}>
                {t('+Add more Employment Details')}
              </AddMoreButton>
            )}
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}></Grid>

        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          xl={12}
          container
          justify={'space-between'}
        >
          <Grid item xs={12} md={4} sm={4} lg={4} xl={4}>
            <ThemeProvider theme={primaryButtonTheme}>
              <PreviousButton
                color="primary"
                variant="outlined"
                onClick={() =>
                  this.props.history.push(routePaths.personalDetail)
                }
              >
                {t('Previous')}
              </PreviousButton>
            </ThemeProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            lg={6}
            xl={6}
            className={'button_alignment'}
          >
            <ThemeProvider theme={primaryButtonTheme}>
              <NextButton
                onClick={this.handleSubmit}
                color="primary"
                variant="contained"
              >
                {t('Next')}
              </NextButton>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}></Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    employeeInfo: state.profileReducer.employeeInfo,
  };
};

export default connect(mapStateToProps, { updateEmployeeInfo, getStudentInfo })(
  withTranslation()(EmployeeDetail)
);
