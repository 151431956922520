/**
 * (c) CareerLabs. All rights reserved.
 **/
import { POST, PUT } from '../../axios/PublicAxios';
import { DEFAULT_URL, _COOKIE } from '../../api/API';
import { AUTHENTICATION } from '../Action';
import Cookies from 'js-cookie';
import { encrypt } from '../../utils/Crypto';

export const emailLogin = data => {
  return dispatch => {
    POST(
      `${DEFAULT_URL}/login`,
      data,
      {},
      response => {
        dispatch({
          type: AUTHENTICATION.validatePhone,
          payload: response.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const login = (data, callback) => {
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/login`,
      data,
      {},
      response => {
        if (response.data.success) {
          let token = {
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
          };
          localStorage.setItem(
            'demoBookmark',
            response.data.data.visitedPages.some(item => item === 'BOOKMARK')
          );

          const showDashboardGuide = response.data.data.visitedPages.some(
            item => item === 'DASHBOARD'
          );

          localStorage.setItem('showDashboardGuide', !showDashboardGuide);

          Cookies.set('accessToken', encrypt(token.accessToken), {
            expires: parseInt(_COOKIE.EXPIRE) / 24, // Days to Hours
            domain: _COOKIE.DOMAIN,
          });
          Cookies.set('refreshToken', encrypt(token.refreshToken), {
            expires: parseInt(_COOKIE.EXPIRE) / 24, // Days to Hours
            domain: _COOKIE.DOMAIN,
          });
          Cookies.set(
            'studentId',
            encrypt(response.data.data.id.replace(/['"]+/g, '')),
            { expires: parseInt(_COOKIE.EXPIRE), domain: _COOKIE.DOMAIN }
          );
          // setCookie('accessToken',token.accessToken);
          // setCookie('refreshToken',token.refreshToken);
        }
        dispatch({ type: AUTHENTICATION.login, payload: response.data });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};
export const validate = (data, callback) => {
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/login/validateUser`,
      data,
      {},
      response => {
        dispatch({
          type: AUTHENTICATION.validate,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const forgotPassword = (data, callback) => {
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/generateResetLink`,
      data,
      {},
      response => {
        dispatch({
          type: AUTHENTICATION.forgotPassword,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const resetPassword = (data, callback) => {
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/resetPassword`,
      data,
      {},
      response => {
        dispatch({
          type: AUTHENTICATION.resetPassword,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};
