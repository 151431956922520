import { Box, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  AccountChangeSubText,
  AccountPassChange,
  ErrorMessage,
  ErrorMessageContainer,
  NavigateBackIcon,
  ValidatorCard,
  ValidatorContainer,
  ValidatorHeader,
  ValidatorIcon,
  ValidatorTitle,
} from '../../../assets/css/StyledComponent';
import close from '../../../assets/icons/close.svg';
import done from '../../../assets/icons/done.svg';
import passwordIcon from '../../../assets/icons/password_validate.svg';
import navigateBack from '../../../assets/images/navigateBack.png';
import { changePassword } from '../../../redux/action/Profile';
import { routePaths } from '../../../routes/RoutePath';
import Button from '../../../utils/components/LoadingButton';
import TextField from '../../../utils/components/TextField';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewPass: null,
      showReTypePass: null,
      newPass: '',
      reTypePass: '',
      isPasswordValid: false,
      reTypePassError: false,
      isLowerCase: false,
      isNumber: false,
      isLength: false,
      isSubmit: false,
      loading: false,
      disabled: false,
    };

    this.validate = this.validate.bind(this);
  }

  onHandleChange = e => {
    this.setState({ newPass: e.target.value, reTypePassError: false });
    this.validate(e.target.value);
  };

  componentDidUpdate() {
    this.onPasswordValid();
  }

  onPasswordValid = () => {
    const { newPass, reTypePass, isPasswordValid, isSubmit } = this.state;
    const isMatch = newPass === reTypePass;
    if (isSubmit !== true) {
      if (
        isMatch &&
        !isPasswordValid &&
        newPass.trim().length !== 0 &&
        reTypePass.trim().length !== 0
      )
        this.setState({ isSubmit: true });
    }
  };

  handlePasswordMatch = e => {
    const { newPass } = this.state;
    const { value } = e.target;
    const isMatch = newPass === value;
    if (newPass.trim().length !== 0)
      this.setState({ reTypePassError: !isMatch });
  };

  onSubmit = event => {
    event.preventDefault();
    const { newPass, isSubmit } = this.state;
    this.setState({ loading: true, disabled: true });
    if (isSubmit) {
      this.props.changePassword(
        {
          newPassword: newPass,
        },

        response => {
          if (response)
            this.props.history.push(routePaths.dashboard.passwordSuccess);
        }
      );
    }

    return false;
  };

  validate = password => {
    if (password.trim().length !== 0) {
      const length = password.length > 7;
      const lowerCase = /[a-z]/.test(password);
      const number = /[0-9]/.test(password);

      this.setState({
        isLength: length,
        isLowerCase: lowerCase,
        isNumber: number,
        isPasswordValid: !length || !lowerCase || !number,
        isSubmit: false,
      });
    } else this.setState({ isPasswordValid: false });
  };

  render() {
    const {
      isPasswordValid,
      reTypePass,
      showNewPass,
      showReTypePass,
      isLength,
      isLowerCase,
      isNumber,
      reTypePassError,
      newPass,
      isSubmit,
      loading,
      disabled,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Grid
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ position: 'relative' }}
        >
          <NavigateBackIcon
            src={navigateBack}
            onClick={() =>
              this.props.history.push(routePaths.dashboard.myAccount)
            }
          />
          <Box className={'account-card-align'}>
            <form onSubmit={this.onSubmit} autoComplete="off">
              <Box pb={2} mt={3}>
                <AccountPassChange>{t('Change Password')}</AccountPassChange>
              </Box>
              <AccountChangeSubText>
                {t('Create a new Password that is 8 Characters Long')}
              </AccountChangeSubText>
              <TextField
                id="no-outline"
                fullWidth
                className={'account-pass-field'}
                name={'New Password'}
                label={t('New Password')}
                type={showNewPass ? 'text' : 'password'}
                value={newPass}
                onChange={this.onHandleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            showNewPass: !showNewPass,
                          })
                        }
                      >
                        {showNewPass ? (
                          <Visibility style={{ color: '#1093FF' }} />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ValidatorCard display={isPasswordValid ? true : false}>
                <ValidatorHeader>
                  <ValidatorIcon src={passwordIcon} />
                  <ValidatorTitle>{t('Your Password must :')}</ValidatorTitle>
                </ValidatorHeader>
                <ValidatorContainer>
                  <ErrorMessageContainer>
                    <ValidatorIcon src={isLength ? done : close} />
                    <ErrorMessage color={isLength ? true : false}>
                      {t('Be atleast 8 Characters')}
                    </ErrorMessage>
                  </ErrorMessageContainer>
                  <ErrorMessageContainer>
                    <ValidatorIcon src={isLowerCase ? done : close} />
                    <ErrorMessage color={isLowerCase ? true : false}>
                      {t('Include a lowercase letter')}
                    </ErrorMessage>
                  </ErrorMessageContainer>
                  <ErrorMessageContainer>
                    <ValidatorIcon src={isNumber ? done : close} />
                    <ErrorMessage color={isNumber ? true : false}>
                      {t('Include a number')}
                    </ErrorMessage>
                  </ErrorMessageContainer>
                </ValidatorContainer>
              </ValidatorCard>
              <TextField
                id="no-outline"
                fullWidth
                className={'account-pass-field'}
                name={'Re-type new Password'}
                label={t('Re-type new Password')}
                type={showReTypePass ? 'text' : 'password'}
                value={reTypePass}
                onChange={e => {
                  this.setState({
                    reTypePass: e.target.value,
                    reTypePassError: false,
                    isSubmit: false,
                  });
                }}
                onBlur={this.handlePasswordMatch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            showReTypePass: !showReTypePass,
                          })
                        }
                      >
                        {showReTypePass ? (
                          <Visibility style={{ color: '#1093FF' }} />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  this.state.reTypePass.length !== 0 &&
                  this.state.newPass !== this.state.reTypePass
                    ? 'Password mismatch'
                    : ''
                }
                error={
                  this.state.reTypePass.length !== 0 &&
                  this.state.newPass !== this.state.reTypePass
                }
              />

              <Box style={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant={'contained'}
                  className={
                    !!(isSubmit && !disabled) ? 'account-btn' : 'btn__blur'
                  }
                  disabled={!isSubmit || disabled}
                  loading={loading}
                >
                  {t('Save')}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    successStatus: state.profileReducer.passwordChangeRespone,
  };
};

export default connect(mapStateToProps, { changePassword })(
  withRouter(withTranslation()(ChangePassword))
);
