/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Button, Collapse, Tooltip } from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Select } from '@material-ui/core';
import styled from 'styled-components';

export const StyledSelect = withStyles(theme => ({
  root: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#052A4E',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '30px',
    padding: '8px 12px',
  },
  select: {
    paddingRight: '40px !important',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '30px !important',
  },
  icon: {
    marginRight: '10px',
    color: '#1093FF',
  },
}))(Select);

export const listItem = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        margin: '2px 8px',

        '&$selected': {
          backgroundColor: '#F2F9FF !important',
        },
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: '30px !important',
      },
    },
  },
});

export const StyledCard = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
    margin: '20px 0px',
    position: 'relative',
    overflow: 'visible',
  },
}))(Card);

export const StyledCardHeader = withStyles(theme => ({
  root: {
    padding: props =>
      props.bottom ? '16px 26px 5px 16px' : '16px 26px 10px 16px',
    transition: 'padding 0.3s',
    cursor: 'pointer',
  },
  content: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#052A4E',
  },
  action: {
    alignSelf: 'center',
    paddingTop: '8px',
  },
}))(CardHeader);

export const StyledTooltip = withStyles(theme => ({
  arrow: {
    color: '#052A4E',
    left: '178px !important',
  },
  tooltip: {
    position: 'relative',
    top: '-6px !important',
    right: '40px !important',
    fontSize: '15px',
    backgroundColor: '#052A4E',
    maxWidth: '300px !important',
    padding: '5px 10px 10px !important',
  },
}))(Tooltip);

export const StyledCollapse = withStyles(theme => ({
  wrapper: {
    padding: '0px 16px 20px 16px',
  },
}))(Collapse);

export const BoostButton = styled(Button)`
  background: #4db71b !important;
  border-radius: 30px !important;
  width: 164px;
  height: 40px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  display: block !important;
  margin: 18px auto !important;
`;

export const BoostText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoostIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
