/**
 * (c) CareerLabs. All rights reserved.
 **/
import { ThemeProvider } from '@material-ui/core';
import MuiTextfield from '@material-ui/core/TextField';
import React from 'react';
import { TextFieldStyle } from '../../assets/css/MuiStyles';
import './assets/styles/Theme.css';

const Textfield = ({ onChange, autoComplete, ...rest }) => {
  return (
    <ThemeProvider theme={TextFieldStyle}>
      {autoComplete ? (
        <MuiTextfield {...rest} variant="standard" onChange={onChange} />
      ) : (
        <MuiTextfield
          {...rest}
          variant="standard"
          onChange={onChange}
          inputProps={{
            maxLength: rest.name === 'description' ? 100 : 200,
          }}
        />
      )}
    </ThemeProvider>
  );
};

export default Textfield;
