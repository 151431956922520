import React, { Component } from 'react';
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import {
  CardShadow,
  InstructionDivider,
  TestScore,
  TestScores,
} from '../../../assets/css/StyledComponent';
// import { buttonTheme } from '../../../../assets/css/MuiStyles';
import { buttonTheme } from '../../../assets/css/MuiStyles';
import NotePad from '../../../assets/images/notepad.png';
import '../../../assets/css/App.css';
import { withTranslation } from 'react-i18next';

class Retake extends Component {
  render() {
    const { data, t } = this.props;
    let date = new Date(data.date);
    let month = date.getMonth();

    let year = date.getFullYear();
    let date1 = date.getDate();
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let monthName = months[month];
    let startDate = date1 + ' ' + monthName + ' ' + year;
    return (
      <div>
        <CardShadow
          style={{
            padding: '2px',
          }}
        >
          <Grid container>
            {/* notepad image */}
            <Grid item md={1}>
              <Box pl={3}>
                <img
                  className={'topic_test_image_style'}
                  src={NotePad}
                  alt="NotePad"
                />
              </Box>
              <Box pl={2} className={'retake_date_align'}>
                {startDate}
              </Box>
            </Grid>

            <Grid item md={1}></Grid>

            {/* your score */}
            <Grid item md={2} className={'question_grid'}>
              <Box pt={0} pr={20}>
                <TestScore className={'text_style'}>
                  {t('Your score')}
                </TestScore>
              </Box>
              <Box pt={1} pr={20}>
                <TestScores>
                  {data.score}/{data.totalQuestion}
                </TestScores>
              </Box>
            </Grid>

            {/* divider */}
            <Grid item md={1}>
              <InstructionDivider className={'instruction_divider'} />
            </Grid>

            {/* test time */}

            <Grid item md={2} className={'question_grid'}>
              <Box pt={0} pr={15}>
                <TestScore className={'text_style'}>{t('Test Duration')}</TestScore>
              </Box>
              <Box pt={1} pr={15}>
                <TestScores className={'text_style'}>
                  {data.timeSpend}
                </TestScores>
              </Box>
            </Grid>

            <Grid item md={1}></Grid>

            {/* button */}
            <Grid item md={4} className={'topic_test_button'}>
              <div>
                <Box pt={4}>
                  <ThemeProvider theme={buttonTheme}>
                    <Button
                      className={'btn__alignment'}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => this.props.onClick(data.id, data.status)}
                      style={{
                        marginTop: 0,
                        maxWidth: '300px',
                        width: '280px',
                      }}
                    >
                      {t('Re-take')}
                    </Button>
                  </ThemeProvider>
                </Box>
              </div>
            </Grid>
          </Grid>
        </CardShadow>
      </div>
    );
  }
}
export default withTranslation()(Retake);
