/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/App.css';
import { CLEVERTAP_ACCOUNT_ID, ENV } from './utils/Config';
import APP from './component/Index';
import './localization/i18n';
import store from './redux/configs/Store';
import clevertap from 'clevertap-web-sdk';

clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
clevertap.init(CLEVERTAP_ACCOUNT_ID);

clevertap.spa = true;

const App = () => {
  /**
   * Disabled console (logs/error/warn/debug)
   */
  if (ENV === 'PRODUCTION') {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.debug = () => {};
  }

  return (
    <Provider store={store}>
      <Router>
        <APP />
      </Router>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
