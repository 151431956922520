import React, { Component } from 'react';
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import {
  CardShadow,
  InstructionDivider,
  ContinueTitle,
  ContinueSubTitle,
} from '../../../assets/css/StyledComponent';
import '../../../assets/css/App.css';
// import { buttonTheme } from '../../../../assets/css/MuiStyles';
import {buttonTheme} from '../../../assets/css/MuiStyles';
import NotePad from '../../../assets/images/notepad.png';
import { withTranslation } from 'react-i18next';

class Continue extends Component {
  render() {
    const { data, t } = this.props;

    let date = new Date(data.date);
    let month = date.getMonth();

    let year = date.getFullYear();
    let date1 = date.getDate();
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let monthName = months[month];
    let startDate = date1 + ' ' + monthName + ' ' + year;

    return (
      <div>
        <CardShadow style={{ padding: '10px' }}>
          <Grid container>
            {/* notepad image */}
            <Grid
              item
              md={1}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <Box pl={3}>
                <img
                  className={'topic_test_image_style'}
                  src={NotePad}
                  alt="NotePad"
                />
              </Box>
              <Box pl={2} className={'date_align'}>
                {startDate}
              </Box>
            </Grid>

            <Grid item md={1}></Grid>

            <Grid item md={2} className={'topic_test_grid'}>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueTitle>{t('Total Questions')}</ContinueTitle>
                  <Box>
                    <ContinueTitle>{data.totalQuestion}</ContinueTitle>
                  </Box>
                </div>
              </Box>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueSubTitle>{t('Questions Left')}</ContinueSubTitle>
                  <Box>
                    <ContinueSubTitle>{data.questionLeft}</ContinueSubTitle>
                  </Box>
                </div>
              </Box>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueSubTitle>{t('Questions Attempts')}</ContinueSubTitle>
                  <Box>
                    <ContinueSubTitle>{data.questionAttempt}</ContinueSubTitle>
                  </Box>
                </div>
              </Box>
            </Grid>

            {/* divider */}
            <Grid item md={1}>
              <Box pl={3}>
                <InstructionDivider />
              </Box>
            </Grid>

            {/* time */}
            <Grid item md={2} className={'topic_test_grid'}>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueTitle>{t('Total Time')}</ContinueTitle>

                  <ContinueTitle>{data.totalTime}</ContinueTitle>
                </div>
              </Box>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueSubTitle>{t('Time Left')}</ContinueSubTitle>
                  <Box>
                    <ContinueSubTitle>{data.timeLeft}</ContinueSubTitle>
                  </Box>
                </div>
              </Box>
              <Box>
                <div className={'topic_test_box'}>
                  <ContinueSubTitle>{t('Time Spend')}</ContinueSubTitle>
                  <Box>
                    <ContinueSubTitle>{data.timeSpend}</ContinueSubTitle>
                  </Box>
                </div>
              </Box>
            </Grid>

            <Grid item md={2}></Grid>

            {/* button */}
            <Grid item md={3} className={'continue_button_align'}>
              <div>
                <Box pt={4}>
                  <ThemeProvider theme={buttonTheme}>
                    <Button
                      className={'btn__alignment'}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => this.props.onClick(data.id, data.status)}
                      style={{
                        marginTop: 0,
                        maxWidth: '300px',
                        width: '280px',
                      }}
                    >
                      {t('Continue')}
                    </Button>
                  </ThemeProvider>
                </Box>
              </div>
            </Grid>
          </Grid>
        </CardShadow>
      </div>
    );
  }
}
export default withTranslation()(Continue);
