// /**
//  * (c) CareerLabs. All rights reserved.
//  **/
// import { Grid, Dialog, Box } from '@material-ui/core';
// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import {
//   DialogBox,
//   DialogButtonBox,
//   DialogHeadText,
//   FlexView,
//   ReviseButton,
//   Close,
//   Content,
//   TopTabs,
//   TopTab,
//   Container,
// } from '../../../../assets/css/StyledComponent';
// import CloseIcon from '@material-ui/icons/Close';
// import {
//   BodyContainer,
//   HeaderCard,
// } from '../../../../assets/css/StyledDashboardComponent.js';
// import { routePaths } from '../../../../routes/RoutePath';
// import BreadCrumbs from '../../../../utils/BreadCrumbs';
// // import Steper from './Steper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import WallLanding from './Toptabs';
// import { getWrongConcept} from '../../../../redux/action/DashboardLanding';
// import {getWrongCalibration} from '../../../../redux/action/DashboardLanding';

// class DashboardTop extends Component {
//   constructor(props) {
//     super(props);
//   }
//   componentDidMount() {
//     window.scrollTo(0, 0);
//     this.props.getWrongConcept();
//     this.props.getWrongCalibration();
//   }

//   render() {
//     const concept = this.props.wrongConcept;
//     console.log(concept,'edad')
//     const calibration=this.props.wrongCalibration;
//     console.log(calibration, 'fgg');
//     const steps = ['Analyse', 'Revise', 'Access', 'Act'];

//     const breadCrumbsProps = {
//       topics: {
//         data: {
//           customData: [
//             {
//               name: 'Back to Dashboard',
//               path: routePaths.dashboard.home,
//             },
//           ],
//         },
//       },
//       backEvent: true,
//       goBack: () => {
//         this.props.history.push(routePaths.dashboard.home);
//       },
//     };

//     return (
//       //   <BodyContainer>
//       <Container>
//         <Grid container>
//           <Grid item xs={12}>
//             <BreadCrumbs {...breadCrumbsProps} />
//           </Grid>

//           <Grid item xs={12}>
//             <WallLanding   concept={calibration}  data={concept}/>
//           </Grid>

//         </Grid>
//       </Container>
//       //   </BodyContainer>
//     );
//   }
// }
// const mapStateToProps = state => {
//   return {
//     ...state.dashboardLandingReducer,
//   };
// };

// export default connect(mapStateToProps, {
//   getWrongConcept,
//   getWrongCalibration,
// })(DashboardTop);
