import React from 'react';
import { Question } from '../../../../assets/css/StyledComponent';
import { Div } from '../../../../assets/css/test/TestComponent';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import LatexViewer from '../../../../utils/LatexViewer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function Binary(props) {
  const {
    question,
    options,
    choiceAnswers,
    isHaveDescription,
    description,
    imgUrl,
  } = props;

  return (
    <Grid container>
      {isHaveDescription && (
        <Grid md={isHaveDescription === false ? 12 : 6}>
          <Box height={'100%'} display={'flex'}>
            <Box flex={1} className={'question-left-container'}>
              <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                <LatexViewer math={description} />
              </Box>
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
                )}
              </Box>
            </Box>
            <Box>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item md={isHaveDescription === false ? 12 : 6}>
        <Box>
          <Div>
            <Question>
              <LatexViewer math={question} />
            </Question>
            <FormControl>
              <Box display={'flex'}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: '600' }}
                >
                  <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Typography>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: '600' }}
                >
                  <span>No</span>
                </Typography>
              </Box>

              {options.map((item, index) => {
                const getcorrect = choiceAnswers.filter(
                  (correct, correctIndex) => correct.id === item.id
                );
                return (
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{
                          alignItems: 'center',
                          height: '100%',
                          padding: '10px',
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={''}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              icon={
                                getcorrect[0].score === 0 ? (
                                  getcorrect[0].firstSelected ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: 'green !important',
                                      }}
                                    />
                                  ) : (
                                    <CancelIcon
                                      sx={{
                                        color: 'red !important',
                                      }}
                                    />
                                  )
                                ) : getcorrect[0].score === 1 &&
                                  getcorrect[0].firstSelected ? (
                                  <CheckCircleIcon
                                    sx={{
                                      color: 'green !important',
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )
                              }
                            />
                          }
                          style={{ marginBottom: '0px', marginRight: '25px' }}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              icon={
                                getcorrect[0].score === 0 ? (
                                  getcorrect[0].secondSelected ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: 'green !important',
                                      }}
                                    />
                                  ) : (
                                    <CancelIcon
                                      sx={{
                                        color: 'red !important',
                                      }}
                                    />
                                  )
                                ) : getcorrect[0].score === 1 &&
                                  getcorrect[0].secondSelected ? (
                                  <CheckCircleIcon
                                    sx={{
                                      color: 'green !important',
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )
                              }
                            />
                          }
                          style={{
                            marginRight: '0px',
                            marginBottom: '0px',
                            margin: '0px',
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <LatexViewer math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </Div>
        </Box>
      </Grid>
    </Grid>
  );
}
