/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import MuiAutoComplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from './TextField';
import { AutoComplete } from './Props';
import { ThemeProvider } from '@material-ui/core';
import { DropdownTheme } from '../../assets/css/MuiStyles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();

const Select = ({
  optionLabel,
  textProps,
  checkBoxOption,
  creatable,
  onChange,
  ...rest
}: AutoComplete) => {
  return (
    <ThemeProvider theme={DropdownTheme}>
      {checkBoxOption ? (
        <MuiAutoComplete
          multiple
          debug
          getOptionLabel={option => option[optionLabel]}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              autoComplete={true}
              {...textProps}
            />
          )}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
              />
              {option[optionLabel]}
            </React.Fragment>
          )}
          onChange={(e, value) => onChange(value)}
          disableCloseOnSelect
          {...rest}
        />
      ) : creatable ? (
        <MuiAutoComplete
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                [optionLabel]: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              onChange(event, { [optionLabel]: newValue });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              onChange(event, { [optionLabel]: newValue.inputValue });
            } else {
              onChange(event, newValue);
            }
          }}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option[optionLabel];
          }}
          renderOption={option => option[optionLabel]}
          freeSolo
          renderInput={params => (
            <TextField autoComplete={true} {...params} {...textProps} />
          )}
          {...rest}
        />
      ) : (
        <MuiAutoComplete
          getOptionLabel={option => option[optionLabel]}
          renderInput={params => (
            <TextField
              autoComplete={true}
              {...params}
              variant="standard"
              {...textProps}
            />
          )}
          onChange={(e, value) => onChange(e, value)}
          {...rest}
        />
      )}
    </ThemeProvider>
  );
};

export default Select;
