/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routePaths } from '../../../../../routes/RoutePath';
import { ThemeProvider, Typography } from '@material-ui/core';
import {
  Content,
  gmatTheme,
  HeaderBox,
  Inline,
  TimerBox,
  Header,
  Box,
  Filler,
} from '../../../../../assets/css/GmatStyles';

import QueryString from 'qs';
import Footer from './Footer';
import PauseExamPopup from './PauseExamPopup';
import {
  getInstructions,
  getTestSection,
  sectionOrder,
  cleanUp,
  pauseExam,
  startTest2,
  submitAnswer,
} from '../../../../../redux/action/Test';
import { connect } from 'react-redux';

import GmatInstruction from './GmatInstruction';
import Section from './Section';
import EndSection from './EndSection';
import SubHeader from './SubHeader';
import _ from 'lodash';
import Test from './Test';
import { retakeExam } from '../../../../../redux/action/Dashboard';
import MessageIcon from '../../../../../assets/icons/MessageIconWhite.svg';
import ClockIcon from '../../../../../assets/icons/ClockIconWhite.svg';
import Timer from '../../../../../utils/components/Timer';
import Confirmation from './Confirmation';
import RequiredPopup from './RequiredPopup';
import DialogComponent from '../../../../../utils/components/DialogComponent';
import {
  AddBookmarks,
  removeDemoBookmark,
} from '../../../../../redux/action/Practice';
import BookmarkIcon from '../../../../../assets/icons/Bookmarks.svg';
import { withTranslation } from 'react-i18next';
import { QS_PROPS } from '../../../../../constant/Variables';
import { EVENTS } from '../../../../../clevertap/Events';
import clevertap from 'clevertap-web-sdk';
import { EventNoteTwoTone } from '@material-ui/icons';

export class GmatLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelOpen: false,
      selectedChoice: [],
      textAnswer: '',
      bundleSelect: [],
      time: -1,
      required: false,
      confirmation: false,
      stopTimer: false,
      resetTime: false,
      bookmarkDialog: false,
      isBookmarked: false,
      answer1: '',
      binary: '',
      error: '',
      isLoading: false,
      bundleSelectedChoice: [],
      instructionLoading: false,
    };
    console.log(this.props?.testResponse?.data, 'ppp');
    this.gmatRoutes = [
      {
        path: `${routePaths.gmat.instruction}`,
        render: props => (
          <GmatInstruction {...this} {...this.props} {...this.state} />
        ),
        exact: true,
      },
      {
        path: `${routePaths.gmat.section}`,
        render: props => <Section {...this} {...this.props} />,
        exact: true,
      },
      {
        path: `${routePaths.gmat.endSection}`,
        render: props => <EndSection {...this} {...this.props} />,
        exact: true,
      },
      {
        path: `${routePaths.gmat.test}`,
        render: () => <Test {...this} {...this.props} />,
        exact: true,
      },
    ];
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    window.history.forward();
    const { testQuestionSetId, section } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.setState({ instructionLoading: true });
    this.props.getInstructions(testQuestionSetId, response => {
      if (response.success === true) {
        this.setState({ instructionLoading: false });
      }
    });
  }

  // Pause button on the bottom
  handlePause = pathName => {
    this.setState({ modelOpen: true });
  };

  // Closing the pause dialog
  handleContinueClick = () => {
    this.setState({ modelOpen: false });
  };

  // Previous button ont he footer
  handlePrevious = () => {
    const { pathname: pathName } = this.props.location;

    const { testQuestionSetId, section, resume } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (section)
      this.props.history.push(
        routePaths.gmat.section + '?testQuestionSetId=' + testQuestionSetId
      );
    else if (pathName === routePaths.gmat.section)
      this.props.history.push(
        routePaths.gmat.instruction + '?testQuestionSetId=' + testQuestionSetId
      );
    else if (pathName === routePaths.gmat.instruction && resume) {
      // Test Resume Route

      this.props.history.push(routePaths.allCourses);
    } else if (pathName === routePaths.gmat.instruction)
      this.props.history.push(routePaths.start);
  };

  // Next button on footer
  handleNext = () => {
    const { pathname: pathName } = this.props.location;

    const { testQuestionSetId, section, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (pathName === routePaths.gmat.instruction && !section) {
      if (this.props.getInstructionsResponse?.data?.exist === null) {
        this.props.history.push(
          routePaths.gmat.section + '?testQuestionSetId=' + testQuestionSetId
        );
      } else {
        this.props.history.push(
          `${routePaths.gmat.test}?testQuestionSetId=${testQuestionSetId}&section=true`
        );
      }
    } else if (pathName === routePaths.gmat.section) {
      this.props.history.push(
        `${routePaths.gmat.instruction}?testQuestionSetId=${testQuestionSetId}&section=true`
      );
    } else if (pathName === routePaths.gmat.instruction && section)
      this.props.history.push(
        `${routePaths.gmat.test}?testQuestionSetId=${testQuestionSetId}&section=true`
      );
    else if (pathName === routePaths.gmat.endSection) {
      if (this.props.testResponse !== null)
        if (this.props.testResponse.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': this.props.testResponse.data.testTitle,
            'Topic name': this.props.testResponse.data.topicName || '',
            'Concept name': this.props.testResponse.data.conceptName,
            'Attempted questions':
              this.props.testResponse.noOfQuestionsAnswered,
            'Count of times attempted the test':
              this.props.testResponse.data.count || 1,
          });
          this.props.history.push(routePaths.progress);
          //this.props.history.push(`${routePaths.progress}&og_course=${og_course}`);
        } else if (this.props.testResponse.message === 'Next TestSection') {
          this.props.history.push(
            `${routePaths.gmat.instruction}?testQuestionSetId=${testQuestionSetId}&section=true`
          );
        }
    } else if (pathName === routePaths.gmat.test) {
      const filteredArray = this.state.bundleSelectedChoice.filter(
        item => item !== null
      );

      if (
        (this.props.testResponse?.data?.type === 'BINARY' ||
          this.props.testResponse?.data?.type === 'DROP_DOWN' ||
          this.props.testResponse?.data?.type === 'MULTI_TAB') &&
        this.state.bundleSelectedChoice?.length <
          this.props.testResponse?.data?.choices.length
      ) {
        this.setState({ required: true });
      } else if (
        this.props.testResponse?.data?.type === 'TWO_PART' &&
        filteredArray.length < 2
      ) {
        this.setState({ required: true });
      } else if (
        this.props.testResponse?.data?.type !== 'TWO_PART' &&
        this.props.testResponse?.data?.type !== 'DROP_DOWN' &&
        this.props.testResponse?.data?.type !== 'BINARY' &&
        this.props.testResponse?.data?.type !== 'MULTI_TAB' &&
        this.state.textAnswer === '' &&
        this.state.selectedChoice?.length === 0 &&
        this.state.bundleSelect?.length !==
          this.props.testResponse?.data?.totalBundle
      ) {
        this.setState({ required: true });
      } else this.setState({ confirmation: true });
    }
  };

  handleDropDown = (e, index, item) => {
    const getname = item.multiChoices.filter(
      itemfilter => itemfilter.id === e.target.value
    );

    let selectedChoicetext = [...this.state.bundleSelectedChoice];
    let getdropDownIndex = selectedChoicetext.findIndex(
      dropwdown =>
        dropwdown.id === this.props.testResponse.data.choices[index].id
    );
    console.log(getdropDownIndex, 'akjdgakg');
    if (getdropDownIndex !== -1) {
      selectedChoicetext[getdropDownIndex] = {
        id: item.id,
        dropDownValue: getname[0].text,
        value: e.target.value,
      };
    } else {
      selectedChoicetext.push({
        id: item.id,
        dropDownValue: getname[0].text,
        value: e.target.value,
      });
    }
    this.setState({ bundleSelectedChoice: selectedChoicetext });
  };

  pauseExam = () => {
    const { pathname: pathName } = this.props.location;

    const { testQuestionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (
      pathName === routePaths.gmat.instruction ||
      pathName === routePaths.gmat.section ||
      pathName === routePaths.gmat.endSection
    ) {
      this.props.history.push(routePaths.allCourses);
    } else if (pathName === routePaths.gmat.test) {
      this.props.pauseExam(
        this.props.testResponse.data.id,
        this.props.testResponse.data.testExecutionId,
        this.state.time,
        response => {
          if (response.success) {
            if (this.props.testResponse.data.testType === 'CALIBRATION') {
              this.props.history.push(routePaths.allCourses);
            }
          }
        }
      );
    }
  };

  // Handling section selection page radio change
  handleRadioChange = e => {
    let obj = {
      testSections:
        this.props.getInstructionsResponse.data.testSections[
          parseInt(e.target.value)
        ],
    };

    const { testQuestionSetId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.props.sectionOrder(obj, res => {
      if (res) this.props.getInstructions(testQuestionSetId, response => {});
    });
  };

  onSelect = e => {
    this.setState({
      selectedChoice: e.target.value,
    });
  };

  onMultiSelect = event => {
    const { selectedChoice } = this.state;
    const { value, checked } = event.target;
    let arr = selectedChoice;
    if (checked) arr.push(value);
    else arr = arr.filter(item => item !== value);
    this.setState({
      selectedChoice: arr,
    });
  };

  onBundleChange = (item, choice) => {
    const { bundleSelect } = this.state;
    var arr = [];
    if (bundleSelect.some(exist => exist.bundleNo === item.bundleNo)) {
      if (
        bundleSelect.some(
          exist => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = bundleSelect.filter(
          exist => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = bundleSelect
          .filter(exist => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = bundleSelect.concat({ ...item, ...choice });
    }
    this.setState({ bundleSelect: arr });
  };

  onTextChange = e => {
    this.setState({ textAnswer: e.target.value });
  };

  onFractionChange = e => {
    this.setState({ answer1: e.target.value });
  };

  handleChangeBinary = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.props.testResponse.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    const existingChoiceIndex = newBundleSelectedChoice.findIndex(
      choice => choice.id === selectedId
    );

    if (existingChoiceIndex !== -1) {
      newBundleSelectedChoice[existingChoiceIndex] = {
        selectedValue: selectedValue,
        id: selectedId,
        correctChoice: selectedValue === 'true' ? selectedValue : 'false',
        wrongChoice:
          selectedValue === 'true'
            ? 'false'
            : selectedValue === 'false'
            ? 'true'
            : selectedValue,
      };
    } else {
      newBundleSelectedChoice.push({
        selectedValue: selectedValue,
        id: selectedId,
        correctChoice: selectedValue === 'true' ? selectedValue : 'false',
        wrongChoice:
          selectedValue === 'true'
            ? 'false'
            : selectedValue === 'false'
            ? 'true'
            : selectedValue,
      });
    }

    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };

  handleChangeTwo = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.props.testResponse.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    newBundleSelectedChoice[0] = {
      id: selectedId,
      correctChoice: selectedValue === 'true' ? selectedValue : 'false',
    };
    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };
  handleChangeTwo1 = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.props.testResponse.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    newBundleSelectedChoice[1] = {
      id: selectedId,
      wrongChoice: selectedValue,
    };
    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };
  // Required Popup Ok Button Click && Confirmation Dialog No Click
  handleOkClick = () => {
    this.setState({ required: false, confirmation: false });
  };

  handleYesClick = () => {
    this.setState({ isLoading: true });
    const { testQuestionSetId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let obj = {
      testExecutionId: this.props.testResponse.data.testExecutionId,
      questionId: this.props.testResponse.data.id,
      ...(this.props.testResponse.data.type !== 'MULTI_TAB' &&
      this.props.testResponse.data.type !== 'BINARY' &&
      this.props.testResponse.data.type !== 'TWO_PART' &&
      this.props.testResponse.data.type !== 'DROP_DOWN'
        ? {
            choices:
              this.state.textAnswer === ''
                ? this.props.testResponse.data.type === 'BUNDLE'
                  ? this.state.bundleSelect.map(item => item.id)
                  : this.props.testResponse.data.type === 'MULTI_CHOICE'
                  ? this.state.selectedChoice
                  : [this.state.selectedChoice]
                : [],
          }
        : {}),
      answer: this.state.textAnswer,
      time: this.state.time,
      answer1: this.state.answer1,
      bundleSelectedChoice: this.state.bundleSelectedChoice,
    };

    this.props.submitAnswer(obj, null, null, false, response => {
      // this.setState({ isLoading: false });
      if (response.success) {
        if (response.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': this.props.testResponse.data.testTitle,
            'Topic name': response.data.topicName,
            'Concept name': this.props.testResponse.data.conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
          this.props.history.push(
            `${routePaths.gmat.endSection}?testQuestionSetId=${testQuestionSetId}&section=true`
          );
        }
        if (response.message === 'Next TestSection') {
          this.props.history.push(
            `${routePaths.gmat.endSection}?testQuestionSetId=${testQuestionSetId}&section=true`
          );
        }
        //  if(response.message==="Question" || response.message==="Next TestSection")
        // {
        //   this.setState({isLoading:false});
        // }
        setTimeout(() => this.setState({ isLoading: false }), 1000);
        this.setState({
          selectedChoice: [],
          textAnswer: '',
          answer1: '',
          bundleSelect: [],
          confirmation: false,
          bundleSelectedChoice: [],
        });
      }
    });
  };

  timeOver = () => {
    const { testQuestionSetId, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    let obj = {
      testExecutionId: this.props.testResponse.data.testExecutionId,
      questionId: this.props.testResponse.data.id,
      choices: [],
      answer: this.state.textAnswer,
      time: -1,
      answer1: this.state.answer1,
      bundleSelectedChoice: this.state.bundleSelectedChoice,
    };
    this.props.submitAnswer(obj, null, null, false, response => {
      if (response.success) {
        if (response.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': this.props.testResponse.data.testTitle,
            'Topic name': response.data.topicName,
            'Concept name': this.props.testResponse.data.conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
          this.props.history.push(routePaths.progress);

          this.props.history.push(`${routePaths.progress}`);
          // }
        } else if (response.message === 'Next TestSection') {
          this.props.history.push(
            `${routePaths.gmat.instruction}?testQuestionSetId=${testQuestionSetId}&section=true`
          );
        } else {
          this.setState({
            stopTimer: false,
            selectedChoice: [],
            answer: '',
            answer1: '',
            bundleSelect: [],
            resetTime: true,
            bundleSelectedChoice: [],
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.time !== this.state.time) {
      if (this.state.time === 0) {
        this.setState({ stopTimer: true });
        this.timeOver();
      }
    }

    if (prevProps.testResponse !== this.props.testResponse) {
      this.setState({
        resetTime: !this.state.resetTime,
        isBookmarked: this.props.testResponse.data.isBookmarked,
      });
    }
  }

  // Bookmark dialog buttons

  handleBookmarkIconClick = () => {
    if (this.state.isBookmarked) this.setState({ bookmarkDialog: true });
    else this.handleButton2Click();
  };

  handleButton1Click = () => {
    this.setState({ bookmarkDialog: false });
  };

  handleButton2Click = () => {
    const id = this.props.testResponse.data.id;
    const bookmarkData = { id: id, type: 'question' };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        if (false) {
          this.removeDemoBookmarkFunction();
        }
        this.setState({
          isBookmarked: response.data.isBookmarked,
          bookmarkDialog: false,
        });
      }
    });
  };

  render() {
    const { section } = QueryString.parse(this.props.location.search, QS_PROPS);
    const { pathname: pathName } = this.props.location;
    const { t } = this.props;
    console.log(this.state, 'hello');
    const productName = JSON.parse(
      localStorage.getItem('userProduct')
    ).productName;
    return (
      <Box style={{ height: '100vh' }}>
        <ThemeProvider theme={gmatTheme}>
          {/* Header */}
          <Header>
            {productName === 'GMAT FOCUS' ? (
              <Typography variant="h1">GMAT FOCUS Calibration Test</Typography>
            ) : (
              <Typography variant="h1">{t('GMAT Calibration Test')}</Typography>
            )}
            {pathName === routePaths.gmat.test && this.props.testResponse && (
              <HeaderBox>
                <Inline>
                  {/* <Filler /> */}
                  <img src={ClockIcon} alt="" className="white_clock" />
                  <Typography variant="body1" className="inline_class">
                    {t(`Time Remaining`)}
                  </Typography>

                  <Typography variant="body1">
                    <TimerBox>
                      <Timer
                        time={1000 * this.props.testResponse.data.remainingTime}
                        getRemainingTime={time => this.setState({ time })}
                        reset={this.state.resetTime}
                        stop={this.state.stopTimer}
                      />
                    </TimerBox>
                  </Typography>
                </Inline>
                <Inline>
                  <Filler />
                  <img src={MessageIcon} alt="" className="white_clock" />
                  <Typography variant="body1">
                    {this.props.testResponse.data.currentQuestionNo}
                    {` of `}
                    {this.props.testResponse.data.totalNoOfQuestion}
                  </Typography>
                </Inline>
              </HeaderBox>
            )}
          </Header>
          <SubHeader
            sectionTitle={
              !_.isEmpty(this.props.section) &&
              this.props.section.data.testSection.name
            }
            section={section}
            location={this.props.location}
            bookmarkIconClick={this.handleBookmarkIconClick}
            isBookmarked={this.state.isBookmarked}
          />
          <Content style={{ justifyContent: 'center' }}>
            <Switch>
              {this.gmatRoutes.map(item => (
                <Route {...item} />
              ))}
            </Switch>
          </Content>

          {/* -------- Footer --------- */}
          <Footer {...this} {...this.props} />
          <RequiredPopup onOk={this.handleOkClick} open={this.state.required} />
          <Confirmation
            open={this.state.confirmation}
            onNo={this.handleOkClick}
            onYes={this.handleYesClick}
            loading={this.state.isLoading}
          />
        </ThemeProvider>
        <PauseExamPopup {...this} />
        <DialogComponent
          open={this.state.bookmarkDialog}
          dialogContent={{
            icon: <img src={BookmarkIcon} />,
            title: t(`Are you sure you want remove bookmark?`),
            button1: t('Cancel'),
            button2: t('Yes'),
          }}
          handleButton1Click={this.handleButton1Click}
          handleButton2Click={this.handleButton2Click}
        />
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    getInstructionsResponse: state.testReducer.instructions,
    section: state.testReducer.testSection,
    testResponse: state.testReducer.testResponse,
  };
};

export default connect(mapStateToProps, {
  getInstructions,
  getTestSection,
  sectionOrder,
  cleanUp,
  pauseExam,
  startTest2,
  submitAnswer,
  retakeExam,
  AddBookmarks,
  removeDemoBookmark,
})(withTranslation()(GmatLayout));
