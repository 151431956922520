/**
 * (c) CareerLabs. All rights reserved.
 **/
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/css/practice/Webinar.css';
import { ImageBox, Main } from '../../../assets/css/practice/WebinarComponents';
import EmptyBox from '../../../assets/images/EmptyBox.svg';
import {
  getPosts,
  getWebinar,
  getWebinarFilter,
  getWebinarConceptFilter,
  registerUser,
  search,
} from '../../../redux/action/DashboardLanding';
import { routePaths } from '../../../routes/RoutePath';
import PageLoader from '../../../utils/components/PageLoader';
import joinMeeting from '../../../utils/components/ZoomMeeting';
import JoinWebinar from '../../dashboard/user/component/JoinWebinar';
import LiveWebinarBanner from './LiveWebinarBanner';
import MostPopular from './MostPopular';
import RecentlyAdded from './RecentlyAdded';
import SearchBar from './SearchBar';
import SearchContent from './SearchContent';
import WatchVideo from './WatchVideo';

const GET_POST_SIZE = 16;
const SEARCH_POST_SIZE = 5;

var popularSlideIndex = 10;
var recentSlideIndex = 10;
var recentPostPageNo = 0;
var popularPostPageNo = 0;

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarFilterValue: [],
      webinarConceptValue: null,

      webinarConceptFilter: [],
      sortValue: 'ASC',
      recentPostPage: 0,
      popularPostPage: 0,
      recentPosts: [],
      popularPosts: [],

      searchMode: false,
      searchText: '',
      searchPage: 1,

      videoMode: false,
      playVideo: {},

      openWebinarPopup: false,
      joinWebinarObj: {},

      loading: false,
    };
  }

  componentDidMount() {
    this.props.getWebinarFilter();
    this.props.getWebinar();
    this.props.getWebinarConceptFilter();
    this.setState({ webinarConceptValue: 'null' });
  }

  componentDidUpdate(prevProps, prevState) {
    // Filter and sort change

    // Search page filter and sort
    if (this.state.searchMode) {
      if (
        prevState.webinarFilterValue !== this.state.webinarFilterValue ||
        prevState.webinarConceptValue !== this.state.webinarConceptValue ||
        prevState.sortValue !== this.state.sortValue ||
        prevState.searchPage !== this.state.searchPage
      ) {
        this.handleSearchClick();
      }
    }
    //landing page filter and sort
    else if (
      (prevState.webinarFilterValue !== this.state.webinarFilterValue ||
        prevState.webinarConceptValue !== this.state.webinarConceptValue ||
        prevState.sortValue !== this.state.sortValue) &&
      this.state.webinarFilterValue.length !== 0
    ) {
      const bodyObj = {
        lmsPostCategories:
          this.state.webinarFilterValue.length !== 0
            ? this.state.webinarFilterValue
            : this.props.webinarFilter,
        order: this.state.sortValue,
      };

      // Recent Posts
      let obj = {
        bodyObj: bodyObj,
        pageNo: 0,
        queryText: 'createdAt',
        size: GET_POST_SIZE,
        conceptId: this.state.webinarConceptValue,
      };
      this.props.getPosts(obj, response => {
        // Resetting the slide arrow api call that appends elements
        recentPostPageNo = 0;
        recentSlideIndex = 10;
        this.setState({ recentPosts: response.content });
      });

      // Most Viewed
      obj.queryText = 'totalViews';
      this.props.getPosts(obj, response => {
        // Resetting the slide arrow api call that appends elements
        popularPostPageNo = 0;
        popularSlideIndex = 10;
        this.setState({ popularPosts: response.content });
      });
    }

    // First time setting slide values and setting slide values when filter is empty
    if (
      prevProps.webinarFilter !== this.props.webinarFilter ||
      (this.state.webinarFilterValue.length === 0 &&
        !!(
          prevState.webinarFilterValue !== this.state.webinarFilterValue ||
          prevState.sortValue !== this.state.sortValue
        ))
    ) {
      const bodyObj = {
        lmsPostCategories: this.props.webinarFilter,
        order: this.state.sortValue,
      };

      // Recent Posts
      let obj = {
        bodyObj: bodyObj,
        pageNo: 0,
        queryText: 'createdAt',
        size: GET_POST_SIZE,
        conceptId: this.state.webinarConceptValue,
      };
      this.props.getPosts(obj, response => {
        // Resetting the slide arrow api call that appends elements
        recentPostPageNo = 0;
        recentSlideIndex = 10;
        this.setState({ recentPosts: response.content });
      });

      // Most Viewed
      obj.queryText = 'totalViews';
      obj.pageNo = this.state.popularPostPage;

      this.props.getPosts(obj, response => {
        // Resetting the slide arrow api call that appends elements
        popularPostPageNo = 0;
        popularSlideIndex = 10;
        this.setState({ popularPosts: response.content });
      });
    }
  }

  handleFilterClick = item => {
    let webinarFilterValue = this.state.webinarFilterValue;

    if (webinarFilterValue.indexOf(item) > -1) {
      webinarFilterValue.splice(webinarFilterValue.indexOf(item), 1);
      this.setState({
        webinarFilterValue: [...webinarFilterValue],
        searchPage: 1,
      });
    } else {
      this.setState({
        webinarFilterValue: [...webinarFilterValue, item],
        searchPage: 1,
      });
    }
  };

  handleConceptClick = event => {
    console.log('hiiii');
    const { name, value } = event.target;
    console.log(name, 'name');
    console.log(this.props.webinarConceptFilter, 'filter');
    console.log(value, 'value');
    // console.log(item,"itemmmmm")
    let webinarConceptValue = this.state.webinarConceptValue;
    this.setState({
      webinarConceptValue: value,
      searchPage: 1,
    });
    const bodyObj = {
      lmsPostCategories: this.props.webinarFilter,
      order: this.state.sortValue,
    };

    let obj = {
      bodyObj: bodyObj,
      pageNo: 0,
      queryText: 'createdAt',
      size: GET_POST_SIZE,
      conceptId: value,
    };
    this.props.getPosts(obj, response => {
      // Resetting the slide arrow api call that appends elements
      recentPostPageNo = 0;
      recentSlideIndex = 10;
      this.setState({ recentPosts: response.content });
    });

    // Most Viewed
    obj.queryText = 'totalViews';
    this.props.getPosts(obj, response => {
      // Resetting the slide arrow api call that appends elements
      popularPostPageNo = 0;
      popularSlideIndex = 10;
      this.setState({ popularPosts: response.content });
    });
  };

  handleSortClick = value => {
    if (this.state.sortValue !== value) {
      this.setState({ sortValue: value });
    }
  };

  handleTextChange = e => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleSearchClick = () => {
    this.setState({ searchMode: true });

    const bodyObj = {
      lmsPostCategories:
        this.state.webinarFilterValue.length !== 0
          ? this.state.webinarFilterValue
          : this.props.webinarFilter,
      order: this.state.sortValue,
    };

    let obj = {
      bodyObj: bodyObj,
      pageNo: this.state.searchPage - 1,
      queryText: this.state.searchText,
      size: SEARCH_POST_SIZE,
      conceptId: this.state.webinarConceptValue,
    };

    this.setState({ loading: true });
    this.props.search(obj, response => {
      if (response) {
        this.setState({ loading: false });
      }
    });
  };

  handleBackClick = () => {
    this.setState({
      searchMode: false,
      searchText: '',
      searchPage: 1,

      videoMode: false,
      playVideo: {},
    });
  };

  handlePageChange = (e, value) => {
    this.setState({ searchPage: value });
  };

  handleWatchVideoClick = post => {
    this.handleBackClick();
    window.scroll(0, 0);
    this.setState({ videoMode: true, playVideo: post });
  };

  // Updating the new set of values
  beforePopularSlideChange = (prevIndex, newIndex) => {
    if (newIndex === popularSlideIndex) {
      popularPostPageNo++;
      popularSlideIndex += GET_POST_SIZE;

      // recentSlideIndex = recentSlideIndex * 2 + 4;

      const bodyObj = {
        lmsPostCategories:
          this.state.webinarFilterValue.length === 0
            ? this.props.webinarFilter
            : this.state.webinarFilterValue,
        order: this.state.sortValue,
      };

      // Recent Posts
      let obj = {
        bodyObj: bodyObj,
        pageNo: popularPostPageNo,
        queryText: 'totalViews',
        size: GET_POST_SIZE,
        conceptId: this.state.webinarConceptValue,
      };

      this.props.getPosts(obj, response => {
        let arr = [...this.state.popularPosts, ...response.content];
        this.setState({
          popularPosts: arr,
        });
      });
    }
  };

  // Updating the new set of values
  beforeRecentSlideChange = (prevIndex, newIndex) => {
    if (newIndex === recentSlideIndex) {
      recentPostPageNo++;
      recentSlideIndex += GET_POST_SIZE;

      const bodyObj = {
        lmsPostCategories:
          this.state.webinarFilterValue.length === 0
            ? this.props.webinarFilter
            : this.state.webinarFilterValue,
        order: this.state.sortValue,
      };

      // Recent Posts
      let obj = {
        bodyObj: bodyObj,
        pageNo: recentPostPageNo,
        queryText: 'createdAt',
        size: GET_POST_SIZE,
        conceptId: this.state.webinarConceptValue,
      };

      this.props.getPosts(obj, response => {
        let arr = [...this.state.recentPosts, ...response.content];
        this.setState({
          recentPosts: arr,
        });
      });
    }
  };

  handleWebinarActionClick = (obj, text) => {
    const userData = this.props.user;

    if (text === 'View Calendar') {
      this.props.history.push(`${routePaths.dashboard.studyPlan}`);
    } else {
      const joinWebinarObj = {
        heading: obj.title,
        webinarOverview: obj.description,
        profilePic: obj.hostImageUrl,
        hostName: obj.hostName,
        dialogDate: obj.date,
        dialogTime: obj.time,
        duration: obj.remainingHours,
        status: obj.status,
        id: obj.id,
        wallWebinar: obj.wallWebinar,
        zoomLink: obj.zoomLink,
        openDialog: true,
        userName: userData ? `${userData.firstName} ${userData.lastName}` : '',
        email: userData ? userData.email : '',
        phone: userData ? userData.phoneNumber : '',
        handleDialogClose: this.handleDialogClose,
        checkhrs: obj.remainingHours,
      };

      this.setState({
        joinWebinarObj: joinWebinarObj,
      });
    }
  };

  handleCancelClick = () => {
    this.setState({
      joinWebinarObj: {},
    });
  };

  // handleWebinarPopupActionClick = () => {
  //   if (this.state.joinWebinarObj.wallWebinar !== null) {
  //     joinMeeting(
  //       `${this.props.user.firstName} ${this.props.user.lastName}`,
  //       this.props.user.email,
  //       this.state.joinWebinarObj.zoomLink
  //     );
  //   } else {
  //     this.props.registerUser(this.state.joinWebinarObj.id, res => {
  //       if (res.success)
  //         joinMeeting(
  //           `${this.props.user.firstName} ${this.props.user.lastName}`,
  //           this.props.user.email,
  //           this.state.joinWebinarObj.zoomLink
  //         );
  //     });
  //   }

  //   this.handleCancelClick();
  // };
  handleWebinarPopupActionClick = text => {
    if (text === 'Join now') {
      if (this.state.joinWebinarObj.wallWebinar !== null) {
        const url = new URL(this.state.joinWebinarObj.wallWebinar.join_url);
        joinMeeting(
          `${this.props.user.firstName} ${this.props.user.lastName}`,
          this.props.user.email,
          this.state.joinWebinarObj.zoomLink,
          url.searchParams.get('tk')
        );
      } else {
        this.props.registerUser(this.state.joinWebinarObj.id, res => {
          if (res.success) {
            const url = new URL(this.state.joinWebinarObj.wallWebinar.join_url);
            joinMeeting(
              `${this.props.user.firstName} ${this.props.user.lastName}`,
              this.props.user.email,
              this.state.joinWebinarObj.zoomLink,
              url.searchParams.get('tk')
            );
          }
        });
      }
    } else if (text === 'Add to Calendar') {
      this.props.registerUser(this.state.joinWebinarObj.id, res => {
        if (res.success) this.props.getWebinar();
      });
    }

    this.handleCancelClick();
  };

  render() {
    const { webinarFilter, webinarConceptFilter, searchData } = this.props;

    const {
      webinarFilterValue,
      webinarConceptValue,
      sortValue,
      searchText,
      searchMode,
      searchPage,
      videoMode,
      playVideo,
      recentPosts,
      popularPosts,
      loading,
    } = this.state;

    const {
      handleFilterClick,
      handleConceptClick,
      handleCancelClick,
      handleSortClick,
      handleTextChange,
      handleBackClick,
      handleSearchClick,
      handlePageChange,
      handleWatchVideoClick,
      beforePopularSlideChange,
      beforeRecentSlideChange,
    } = this;

    const searchBarProps = {
      webinarFilter,
      webinarConceptFilter,
      webinarFilterValue,
      webinarConceptValue,
      handleFilterClick,
      handleConceptClick,
      handleSortClick,
      sortValue,
      searchText,
      handleTextChange,
      handleSearchClick,
      handleBackClick,
      searchMode,
      videoMode,
    };

    const recentlyAddedProps = {
      posts: !_.isEmpty(recentPosts) ? recentPosts : [],
      handleWatchVideoClick,
      beforeChange: beforeRecentSlideChange,
    };

    const mostPopularProps = {
      posts: !_.isEmpty(popularPosts) ? popularPosts : [],
      handleWatchVideoClick,
      beforeChange: beforePopularSlideChange,
    };

    const searchContentProps = {
      posts: !_.isEmpty(searchData) ? searchData.content : [],
      totalElements: !_.isEmpty(searchData) ? searchData.totalElements : 0,
      totalPages: !_.isEmpty(searchData) ? searchData.totalPages : 0,
      handlePageChange,
      page: searchPage,
      handleWatchVideoClick,
      loading,
    };

    const watchVideoProps = {
      playVideo,
      recommended: !_.isEmpty(recentPosts) ? recentPosts : [],
      handleWatchVideoClick,
    };

    const webinarProps = {
      openDialog: this.state.openWebinarPopup,
      userData: this.props.user,
      handleActionButtonClick: this.handleWebinarActionClick,
    };

    if (loading) return <PageLoader />;
    else
      return (
        <div>
          {this.props.webinarData.length !== 0 && !this.state.videoMode && (
            <LiveWebinarBanner
              webinarData={this.props.webinarData}
              webinarProps={webinarProps}
            />
          )}
          <Main>
            <SearchBar {...searchBarProps} />
            {!searchMode ? (
              <>
                {videoMode ? (
                  <WatchVideo {...watchVideoProps} />
                ) : (
                  <>
                    {recentPosts.length > 0 ? (
                      <>
                        <RecentlyAdded {...recentlyAddedProps} />
                        <MostPopular {...mostPopularProps} />
                      </>
                    ) : (
                      <ImageBox>
                        <img src={EmptyBox} alt="EmptyBox" />
                      </ImageBox>
                    )}
                  </>
                )}
              </>
            ) : (
              <SearchContent {...searchContentProps} />
            )}
            <JoinWebinar
              {...this.state.joinWebinarObj}
              handleDialogClose={this.handleCancelClick}
              handleActionClick={this.handleWebinarPopupActionClick}
            />
          </Main>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    webinarFilter: state.dashboardLandingReducer.webinarFilter,
    webinarConceptFilter: state.dashboardLandingReducer.webinarConceptFilter,
    //conceptFilter: state.dashboardLandingReducer.conceptFilter,
    recentPosts: state.dashboardLandingReducer.recentPosts,
    popularPosts: state.dashboardLandingReducer.popularPosts,
    searchData: state.dashboardLandingReducer.search,
    webinarData: state.dashboardLandingReducer.webinar,
    user: state.studentReducer.user,
  };
};

export default connect(mapStateToProps, {
  getWebinarFilter,
  getWebinarConceptFilter,
  getPosts,
  search,
  getWebinar,
  registerUser,
})(Index);
