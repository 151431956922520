import { Table, TableContainer, TableRow } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import {
  StatusToolTip,
  TableBodyStyle,
  TableHeadCell,
  TableHeader,
  TableItemBlack,
  TableItemBlue,
  TableItemUnderline,
} from '../../../../assets/css/Styles';
import Status1 from '../../../../assets/images/status1.png';
import Status2 from '../../../../assets/images/status2.png';
import Status3 from '../../../../assets/images/status3.png';
import { QS_PROPS } from '../../../../constant/Variables';
import { routePaths } from '../../../../routes/RoutePath';

class ReviewCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          quesNo: 1,
          topic: 'Primes',
          concept: 'Arithmetic',
          difficulty: 'Easy',
          timeTaken: 62,
          cumulativeTime: 62,
          attemptStatus: 'Incorrect',
        },
        {
          quesNo: 2,
          topic: 'Probability',
          concept: 'Arithmetic',
          difficulty: 'Medium',
          timeTaken: 62,
          cumulativeTime: 94,
          attemptStatus: 'Not Attempted',
        },
        {
          quesNo: 3,
          topic: 'Linear Inq',
          concept: 'Arithmetic',
          difficulty: 'Hard',
          timeTaken: 62,
          cumulativeTime: 83,
          attemptStatus: 'Correct',
        },
      ],
    };
  }

  convertTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (minutes !== 0 && seconds !== 0) return minutes + 'm ' + seconds + 's';
    if (minutes === 0) return seconds + 's';
    if (seconds === 0) return minutes + 's';
  }

  render() {
    const { items } = this.props;
    const {
      testExecutionId,
      test_type,
      og_course,
      topicId,
      diagnostic,
      subject,
      type,
    } = QueryString.parse(this.props.location.search, QS_PROPS);
    return (
      <TableContainer>
        <Table className={'review__table'} style={{ paddingTop: 0 }}>
          <TableHeader>
            <TableHeadCell>Que No</TableHeadCell>
            <TableHeadCell style={{ textAlign: 'left' }}>Topic</TableHeadCell>
            <TableHeadCell>Concept</TableHeadCell>
            <TableHeadCell>Difficulty</TableHeadCell>
            <TableHeadCell>Time Taken</TableHeadCell>
            <TableHeadCell>Cumulative Time</TableHeadCell>
            <TableHeadCell>Attempt Status</TableHeadCell>
          </TableHeader>

          <TableBodyStyle>
            {items.review?.map(item => {
              return (
                <TableRow id={item.quesNo} className={'review__table__row'}>
                  <TableItemUnderline
                    onClick={() => {
                      let userProduct = JSON.parse(
                        localStorage.getItem('userProduct')
                      );
                      userProduct.testExecutionId = testExecutionId;
                      localStorage.setItem(
                        'userProduct',
                        JSON.stringify(userProduct)
                      );
                      test_type === 'OG_PRACTISETEST'
                        ? this.props.history.push(
                            `${routePaths.answer}/${item.quesNo}?type=TOPIC&testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
                          )
                        : diagnostic === 'diagnostic-engine'
                        ? this.props.history.push(
                            `${routePaths.answer}/${item.quesNo}?type=TOPIC&testExecutionId=${testExecutionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
                          )
                        : subject === 'subject'
                        ? this.props.history.push(
                            `${routePaths.answer}/${item.quesNo}?type=TOPIC&testExecutionId=${testExecutionId}&subject=subject&topicId=${topicId}&Type=${type}`
                          )
                        : this.props.history.push(
                            `${routePaths.answer}/${item.quesNo}?type=TOPIC&testExecutionId=${testExecutionId}`
                          );
                    }}
                  >{`Ques ${item.quesNo}`}</TableItemUnderline>
                  <TableItemBlue style={{ textAlign: 'left' }}>
                    {item.topic}
                  </TableItemBlue>
                  <TableItemBlue>{item.concept}</TableItemBlue>
                  <TableItemBlack>{item.difficulty}</TableItemBlack>
                  <TableItemBlack>
                    {this.convertTime(item.timeTaken)}
                  </TableItemBlack>
                  <TableItemBlack>
                    {this.convertTime(item.cumulativeTime)}
                  </TableItemBlack>
                  <StatusToolTip
                    arrow
                    placement="bottom-start"
                    title={item.attemptStatus}
                  >
                    <TableItemBlack>
                      {
                        <img
                          src={
                            item.attemptStatus === 'Not Attempted'
                              ? Status1
                              : item.attemptStatus === 'Correct'
                              ? Status2
                              : item.attemptStatus === 'Incorrect'
                              ? Status3
                              : null
                          }
                          alt={item.attemptStatus}
                        />
                      }
                    </TableItemBlack>
                  </StatusToolTip>
                </TableRow>
              );
            })}
          </TableBodyStyle>
        </Table>
      </TableContainer>
    );
  }
}

export default ReviewCard;
