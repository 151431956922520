import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { _COOKIE } from '../../../api/API';
import { AuthTitle, SubTitle } from '../../../assets/css/StyledComponent';
import {
  buttonTheme,
  colorScheme,
  useStyles,
} from '../../../assets/css/Styles';
import { CONTACT_US_URL, REGISTER_URL } from '../../../constant/Variables.js';
import * as track from '../../../mixpanel/Events';
import { login, validate } from '../../../redux/action/Authentication';
import { checkUserLogin, getCourses } from '../../../redux/action/Profile';
import { routePaths } from '../../../routes/RoutePath';
import Button from '../../../utils/components/Button';
import TextField from '../../../utils/components/TextField';
import { isEmail, isNumber } from '../../../utils/Validation';
import { decrypt, encrypt } from '../../../utils/Crypto';
import PageLoader from '../../../utils/components/PageLoader';
import clevertap from 'clevertap-web-sdk';
import { getCleverTabCommonProperties } from '../../../redux/action/Test';
import { EVENTS } from '../../../clevertap/Events';

function PhoneNumberLogin({ t, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    userId: '',
    password: '',
    error: false,
    userIdError: '',
    passwordError: '',
    showPassword: false,
    show: false,
  });
  const [isClearInterval, setClearInterval] = useState(false);
  const CheckAuthentication = () => {
    const userProduct = localStorage.getItem('userProduct');
    if (Cookies.get('lmsproductId') !== undefined) {
      setClearInterval(true);
      Cookies.remove('accessToken', {
        path: '/',
        domain: _COOKIE.DOMAIN,
      });
      Cookies.remove('studentId', { path: '/', domain: _COOKIE.DOMAIN });
      Cookies.set(
        'studentId',
        encrypt(Cookies.get('lmsstudentId').replace(/['"]+/g, '')),
        { expires: parseInt(_COOKIE.EXPIRE), domain: _COOKIE.DOMAIN }
      );
      Cookies.set('accessToken', encrypt(Cookies.get('lmsaccessToken')), {
        expires: parseInt(_COOKIE.EXPIRE),
        domain: _COOKIE.DOMAIN,
      });

      localStorage.setItem('lmsproductId', Cookies.get('lmsproductId'));
      Cookies.remove('lmsproductId', { path: '/', domain: _COOKIE.DOMAIN });
    } else if (Cookies.get('accessToken') && Cookies.get('studentId')) {
      props.checkUserLogin(response => {
        if (response.success) {
          // if (response.data.isProfileSetup === 'INCOMPLETE')
          //   props.history.push(routePaths.profileSetup);
          // else {
          //   if (userProduct) props.history.push(routePaths.dashboard.home);
          //   else {
          //     let productId = localStorage.getItem('lmsproductId');

          //     if (productId) {
          //       props.getCourses(response => {
          //         let acceptCourse = response.data.find(
          //           product =>
          //             product.isStudyPlanCreated &&
          //             !(
          //               (product.isProductActive && product.isProductExpired) ||
          //               !product.isProductActive
          //             ) &&
          //             !product.isCoursePaused &&
          //             product.id === productId
          //         );
          //         if (acceptCourse) {
          //           localStorage.setItem(
          //             'userProduct',
          //             JSON.stringify(acceptCourse)
          //           );
          //           props.history.push(routePaths.dashboard.home);
          //         } else {
          //           props.history.push(routePaths.allCourses);
          //         }
          //         localStorage.removeItem('lmsproductId');
          //       });
          //     } else props.history.push(routePaths.allCourses);
          //   }
          // }

          if (response.data.isProfileSetup === 'INCOMPLETE') {
            props.history.push(routePaths.profileSetup);
          } else {
            props.getCourses(response => {
              let acceptCourse = response.data.find(
                product =>
                  product.isStudyPlanCreated &&
                  !(
                    (product.isProductActive && product.isProductExpired) ||
                    !product.isProductActive
                  ) &&
                  !product.isCoursePaused
              );
              if (acceptCourse) {
                localStorage.setItem(
                  'userProduct',
                  JSON.stringify(acceptCourse)
                );
                props.history.push(routePaths.dashboard.home);
              } else {
                props.history.push(routePaths.allCourses);
                localStorage.removeItem('lmsproductId');
              }
            });
          }
        } else {
          Cookies.remove('accessToken', {
            path: '/',
            domain: _COOKIE.DOMAIN,
          });
          Cookies.remove('studentId', { path: '/', domain: _COOKIE.DOMAIN });
          Cookies.remove('lmsstudentId', { path: '/', domain: _COOKIE.DOMAIN });
          Cookies.remove('lmsaccessToken', {
            path: '/',
            domain: _COOKIE.DOMAIN,
          });
          Cookies.remove('refreshToken', {
            path: '/',
            domain: _COOKIE.DOMAIN,
          });
          window.localStorage.clear();
          window.sessionStorage.clear();
        }
      });
    }
  };
  // To check if already the cookies are token then route to the corresponding pages
  useEffect(() => {
    CheckAuthentication();
  }, []);

  useEffect(() => {
    if (isClearInterval) {
      CheckAuthentication();
      setClearInterval(false);
    }
  }, [isClearInterval]);
  const [isLoading, setLoading] = useState(false);

  const handlePasswordChange = e =>
    setState({
      ...state,
      password: e.target.value,
      passwordError: '',
    });

  const handleUserIdChange = e => {
    const { value } = e.target;
    if (value && !isNumber(value) && value.length > 10) {
      e.preventDefault();
    } else {
      setState({
        ...state,
        userId: value,
        userIdError: '',
        show: false,
        password: '',
        passwordError: '',
      });
    }
  };

  const handleClick = () => {
    setLoading(true);
    let validateUser = {
      email: state.userId,
      source: 'LMS',
    };
    if (state.userId !== '') {
      if (!isNumber(state.userId)) {
        /**
         * Mobile Number Login
         */
        if (state.userId.length === 10) {
          let validateUserObj = {
            phoneNumber: state.userId,
            source: 'LMS',
          };
          props.validate(validateUserObj, response => {
            if (response.success) {
              track.noOfTimeLogin('Phone number');
              track.phoneNumberEntered({}, true);
              props.history.push(routePaths.otpVerify);
            } else {
              setLoading(false);
              track.phoneNumberEntered({}, false);
              setState({
                ...state,
                userIdError: 'Please enter registered mobile number',
              });
            }
          });
        } else {
          setLoading(false);
          setState({
            ...state,
            userIdError: 'Please enter a valid mobile number',
          });
        }
      } else if (isEmail(state.userId)) {
        props.validate(validateUser, response => {
          setLoading(false);
          if (response.success && state.password !== '') {
            // Email login
            let loginObj = {
              email: state.userId,
              password: state.password,
              source: 'LMS',
            };
            props.login(loginObj, response => {
              if (response.success) {
                const { id, firstName, lastName, email } = response.data;
                /** Mixpanel */
                track.noOfTimeLogin('Email');
                track.successfulLogin(
                  {
                    id: id,
                    $name: `${firstName} ${lastName}`,
                    $email: email,
                    'login date': new Date().toDateString(),
                    timestamp: new Date(),
                  },
                  true
                );

                /* Getting common_properties from localStorage and send it onUserLogin. */
                setTimeout(() => {
                  dispatch(
                    getCleverTabCommonProperties(res => {
                      console.log(res, 'getCleverTabCommonProperties-2');
                      if (res.success) {
                        let common_properties = JSON.parse(
                          window.localStorage.getItem(
                            'user_common_properties'
                          ) || '{}'
                        );
                        clevertap.onUserLogin.push({
                          Site: {
                            ...common_properties,
                          },
                        });
                      }
                    })
                  );
                }, 3000);
                /* Getting common_properties from localStorage and send it onUserLogin. */

                /* Event triggers when user adds their user id and password and logged in to the platform */
                clevertap.event.push(EVENTS.LMSUserLoggedIn, {
                  'Count of login': response.data.lmsLoginCount,
                });
                /* Event triggers when user adds their user id and password and logged in to the platform */

                if (response.data.isProfileSetup === 'INCOMPLETE') {
                  props.history.push(routePaths.profileSetup);
                } else {
                  props.getCourses(response => {
                    let acceptCourse = response.data.find(
                      product =>
                        product.isStudyPlanCreated &&
                        !(
                          (product.isProductActive &&
                            product.isProductExpired) ||
                          !product.isProductActive
                        ) &&
                        !product.isCoursePaused
                    );
                    if (acceptCourse) {
                      localStorage.setItem(
                        'userProduct',
                        JSON.stringify(acceptCourse)
                      );
                      props.history.push(routePaths.dashboard.home);
                    } else {
                      props.history.push(routePaths.allCourses);
                    }
                  });
                }
              } else {
                setState({
                  ...state,
                  passwordError: response.message,
                });
              }
            });
          } else if (response.success && state.show) {
            track.emailEntered({}, true);
            if (state.password === '') {
              setLoading(false);
              setState({
                ...state,
                passwordError: 'Please fill the required field',
              });
            }
          } else if (response.success) {
            track.emailEntered({}, false);
            setState({
              ...state,
              show: true,
            });
          } else if (!response.success) {
            track.emailEntered({}, false);
            setState({
              ...state,
              userIdError: response.message,
            });
          }
        });
      } else {
        setLoading(false);
        setState({
          ...state,
          userIdError: 'Please enter a valid email',
        });
      }
    } else {
      setLoading(false);
      setState({
        ...state,
        userIdError: 'Please enter a valid email or mobile number',
      });
    }
    window.sessionStorage.setItem('phone', state.userId);
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // const handleRememberMe = event => {
  // setRemember(event.target.checked);
  // };
  if (
    !Cookies.get('lmsaccessToken') &&
    !Cookies.get('lmsstudentId') &&
    !localStorage.getItem('lmsproductId')
  ) {
    return (
      <div>
        <Box display={'flex'} flexDirection="column" pt={1}>
          <AuthTitle>{t('Log in')}</AuthTitle>
          <SubTitle>
            {t(
              'Enter your registered email id or mobile number to login to your LMS Platform'
            )}
          </SubTitle>
          <Box pt={1} pb={3}>
            <TextField
              fullWidth
              name="userId"
              id={'userId'}
              label={t('Email/ Mobile ')}
              onChange={handleUserIdChange}
              error={state.userIdError !== ''}
              helperText={t(state.userIdError)}
              value={state.userId}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  handleClick();
                }
              }}
            />
          </Box>
          <Box pb={2}>
            {state.show ? (
              <div>
                <TextField
                  fullWidth
                  name={'password'}
                  id={'password'}
                  label={t('Password')}
                  type={state.showPassword ? 'text' : 'password'}
                  value={state.password}
                  onChange={handlePasswordChange}
                  error={state.passwordError !== ''}
                  helperText={state.passwordError}
                  onKeyUp={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      handleClick();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  {/* <FormControlLabel
  onChange={handleRememberMe}
  control={<Checkbox color='primary' />}
  label={
  <Typography variant='subtitle2'>
  {t('Remember me')}
  </Typography>
  }
  labelPlacement='End'
  /> */}
                  <span />
                  <Typography variant="subtitle2" className={classes.underLine}>
                    <Link
                      href=""
                      onClick={() => props.history.push(routePaths.emailEnter)}
                    >
                      {t('Forgot Password?')}
                    </Link>
                  </Typography>
                </Grid>
              </div>
            ) : null}
          </Box>

          <Box pt={2} pb={2}>
            <ThemeProvider theme={buttonTheme}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleClick}
                id={'loginButton'}
                loading={isLoading}
              >
                {t('Login')}
              </Button>
            </ThemeProvider>
          </Box>

          {/* <Box pt={2}>
  <div class='separator divider__text'>{orContinueWith}</div>
  </Box> */}

          <Box display={'flex'} justifyContent={'center'} p={1} pt={3} pb={3}>
            {/* <Box p={1}>
  <IconButton>
  <img src={google} alt='' width={32} />
  </IconButton>
  </Box>
  <Box p={1}>
  <IconButton>
  <img src={facebookIcon} alt='' width={32} />
  </IconButton>
  </Box>
  <Box p={1}>
  <IconButton>
  <img src={linkedInIcon} alt='' width={32} />
  </IconButton>
  </Box> */}
          </Box>
        </Box>
        <Box>
          <Box display={'flex'} justifyContent={'center'} pb={3}>
            <Typography variant="subtitle2" className={classes.underLine}>
              {t('Don’t have an Account?')}&nbsp;&nbsp;
            </Typography>
            <Typography variant="subtitle2" className={classes.underLine}>
              <Link onClick={() => window.open(REGISTER_URL)} href="#">
                {t('Register')}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box pt={2}>
          <Box paddingTop={1} paddingBottom={1}>
            <ThemeProvider theme={colorScheme}>
              <Typography variant="h6" color="secondary">
                {t('Need help signing up?')}
              </Typography>
            </ThemeProvider>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="grey">
              {''}
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="subtitle2" className={classes.underLine}>
              <Link onClick={() => window.open(CONTACT_US_URL)} href="#">
                {t('Contact us')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </div>
    );
  } else return <PageLoader />;
}

const mapStateToProps = state => {
  return {
    validateResponse: state.AuthReducer.validateResponse,
    loginResponse: state.AuthReducer.loginResponse,
  };
};

export default connect(mapStateToProps, {
  validate,
  login,
  getCourses,
  checkUserLogin,
})(withTranslation()(PhoneNumberLogin));

/**
 * (c) CareerLabs. All rights reserved.
 **/
// import {
//   Box,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Link,
//   ThemeProvider,
//   Typography,
// } from '@material-ui/core';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Cookies from 'js-cookie';
// import React, { useEffect, useState } from 'react';
// import { withTranslation } from 'react-i18next';
// import { connect } from 'react-redux';
// import { _COOKIE } from '../../../api/API';
// import { AuthTitle, SubTitle } from '../../../assets/css/StyledComponent';
// import {
//   buttonTheme,
//   colorScheme,
//   useStyles,
// } from '../../../assets/css/Styles';
// import { CLEVERTAP } from '../../../clevertap/Config';
// import { CONTACT_US_URL, REGISTER_URL } from '../../../constant/Variables.js';
// import * as track from '../../../mixpanel/Events';
// import { login, validate } from '../../../redux/action/Authentication';
// import { checkUserLogin, getCourses } from '../../../redux/action/Profile';
// import { routePaths } from '../../../routes/RoutePath';
// import Button from '../../../utils/components/Button';
// import TextField from '../../../utils/components/TextField';
// import { isEmail, isNumber } from '../../../utils/Validation';

// function PhoneNumberLogin({ t, ...props }) {
//   const classes = useStyles();
//   const [state, setState] = useState({
//     userId: '',
//     password: '',
//     error: false,
//     userIdError: '',
//     passwordError: '',
//     showPassword: false,
//     show: false,
//   });

//   // To check if already the cookies are token then route to the corresponding pages
//   useEffect(() => {
//     const userProduct = localStorage.getItem('userProduct');
//     var productDetails = userProduct && JSON.parse(userProduct);
//     if (Cookies.get('accessToken') && Cookies.get('studentId')) {
//       props.checkUserLogin(response => {
//         if (response.success) {
//           if (response.data.isProfileSetup === 'INCOMPLETE')
//             props.history.push(routePaths.profileSetup);
//           else {
//             if (productDetails && productDetails.isStudyPlanCreated)
//               props.history.push(routePaths.dashboard.home);
//             else props.history.push(routePaths.allCourses);
//           }
//         } else {
//           Cookies.remove('accessToken', { path: '/', domain: _COOKIE.DOMAIN });
//           Cookies.remove('studentId', { path: '/', domain: _COOKIE.DOMAIN });
//           Cookies.remove('refreshToken', { path: '/', domain: _COOKIE.DOMAIN });
//           window.localStorage.clear();
//           window.sessionStorage.clear();
//         }
//       });
//     }
//   }, []);

//   const [isLoading, setLoading] = useState(false);

//   const handlePasswordChange = e =>
//     setState({
//       ...state,
//       password: e.target.value,
//       passwordError: '',
//     });

//   const handleUserIdChange = e => {
//     const { value } = e.target;
//     if (value && !isNumber(value) && value.length > 10) {
//       e.preventDefault();
//     } else {
//       setState({
//         ...state,
//         userId: value,
//         userIdError: '',
//         show: false,
//         password: '',
//         passwordError: '',
//       });
//     }
//   };

//   const handleClick = () => {
//     setLoading(true);
//     let validateUser = {
//       email: state.userId,
//       source: 'LMS',
//     };
//     if (state.userId !== '') {
//       if (!isNumber(state.userId)) {
//         /**
//          *  Mobile Number Login
//          */
//         if (state.userId.length === 10) {
//           let validateUserObj = {
//             phoneNumber: state.userId,
//             source: 'LMS',
//           };
//           props.validate(validateUserObj, response => {
//             if (response.success) {
//               track.noOfTimeLogin('Phone number');
//               track.phoneNumberEntered({}, true);
//               props.history.push(routePaths.otpVerify);
//             } else {
//               setLoading(false);
//               track.phoneNumberEntered({}, false);
//               setState({
//                 ...state,
//                 userIdError: 'Please enter registered mobile number',
//               });
//             }
//           });
//         } else {
//           setLoading(false);
//           setState({
//             ...state,
//             userIdError: 'Please enter a valid  mobile number',
//           });
//         }
//       } else if (isEmail(state.userId)) {
//         props.validate(validateUser, response => {
//           setLoading(false);
//           if (response.success && state.password !== '') {
//             // Email login
//             let loginObj = {
//               email: state.userId,
//               password: state.password,
//               source: 'LMS',
//             };
//             props.login(loginObj, response => {
//               if (response.success) {
//                 const { id, firstName, lastName, email } = response.data;
//                 /** Mixpanel */
//                 track.noOfTimeLogin('Email');
//                 track.successfulLogin(
//                   {
//                     id: id,
//                     $name: `${firstName} ${lastName}`,
//                     $email: email,
//                     'login date': new Date().toDateString(),
//                     timestamp: new Date(),
//                   },
//                   true
//                 );
//                 CLEVERTAP.profile({
//                   Site: {
//                     Name: `${response.data.firstName} ${response.data.lastName}`,
//                     Email: response.data.email,
//                     'Phone Number': response.data.phoneNumber,
//                     'User Name': response.data.email,
//                     College: response.data.collage,
//                     Department: response.data.department,
//                   },
//                 });
//                 if (response.data.isProfileSetup === 'INCOMPLETE') {
//                   props.history.push(routePaths.profileSetup);
//                 } else {
//                   props.getCourses(response => {
//                     let acceptCourse = response.data.find(
//                       product =>
//                         product.isStudyPlanCreated &&
//                         !(
//                           (product.isProductActive &&
//                             product.isProductExpired) ||
//                           !product.isProductActive
//                         ) &&
//                         !product.isCoursePaused
//                     );
//                     if (acceptCourse) {
//                       localStorage.setItem(
//                         'userProduct',
//                         JSON.stringify(acceptCourse)
//                       );
//                       props.history.push(routePaths.dashboard.home);
//                     } else {
//                       props.history.push(routePaths.allCourses);
//                     }
//                   });
//                 }
//               } else {
//                 setState({
//                   ...state,
//                   passwordError: response.message,
//                 });
//               }
//             });
//           } else if (response.success && state.show) {
//             track.emailEntered({}, true);
//             if (state.password === '') {
//               setLoading(false);
//               setState({
//                 ...state,
//                 passwordError: 'Please fill the required field',
//               });
//             }
//           } else if (response.success) {
//             track.emailEntered({}, false);
//             setState({
//               ...state,
//               show: true,
//             });
//           } else if (!response.success) {
//             track.emailEntered({}, false);
//             setState({
//               ...state,
//               userIdError: response.message,
//             });
//           }
//         });
//       } else {
//         setLoading(false);
//         setState({
//           ...state,
//           userIdError: 'Please enter a valid email',
//         });
//       }
//     } else {
//       setLoading(false);
//       setState({
//         ...state,
//         userIdError: 'Please enter a valid email or mobile number',
//       });
//     }
//     window.sessionStorage.setItem('phone', state.userId);
//   };

//   const handleClickShowPassword = () => {
//     setState({ ...state, showPassword: !state.showPassword });
//   };

//   const handleMouseDownPassword = event => {
//     event.preventDefault();
//   };

//   // const handleRememberMe = event => {
//   //   setRemember(event.target.checked);
//   // };

//   return (
//     <div>
//       <Box display={'flex'} flexDirection="column" pt={1}>
//         <AuthTitle>{t('Log in')}</AuthTitle>
//         <SubTitle>
//           {t(
//             'Enter your registered email id or mobile number to login to your LMS Platform'
//           )}
//         </SubTitle>
//         <Box pt={1} pb={3}>
//           <TextField
//             fullWidth
//             name="userId"
//             id={'userId'}
//             label={t('Email/ Mobile ')}
//             onChange={handleUserIdChange}
//             error={state.userIdError !== ''}
//             helperText={t(state.userIdError)}
//             value={state.userId}
//             onKeyUp={e => {
//               if (e.keyCode === 13) {
//                 e.preventDefault();
//                 handleClick();
//               }
//             }}
//           />
//         </Box>
//         <Box pb={2}>
//           {state.show ? (
//             <div>
//               <TextField
//                 fullWidth
//                 name={'password'}
//                 id={'password'}
//                 label={t('Password')}
//                 type={state.showPassword ? 'text' : 'password'}
//                 value={state.password}
//                 onChange={handlePasswordChange}
//                 error={state.passwordError !== ''}
//                 helperText={state.passwordError}
//                 onKeyUp={e => {
//                   if (e.keyCode === 13) {
//                     e.preventDefault();
//                     handleClick();
//                   }
//                 }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         onMouseDown={handleMouseDownPassword}
//                       >
//                         {state.showPassword ? (
//                           <Visibility />
//                         ) : (
//                           <VisibilityOff />
//                         )}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <Grid item container justify="space-between" alignItems="center">
//                 {/* <FormControlLabel
//                   onChange={handleRememberMe}
//                   control={<Checkbox color='primary' />}
//                   label={
//                     <Typography variant='subtitle2'>
//                       {t('Remember me')}
//                     </Typography>
//                   }
//                   labelPlacement='End'
//                 /> */}
//                 <span />
//                 <Typography variant="subtitle2" className={classes.underLine}>
//                   <Link
//                     href=""
//                     onClick={() => props.history.push(routePaths.emailEnter)}
//                   >
//                     {t('Forgot Password?')}
//                   </Link>
//                 </Typography>
//               </Grid>
//             </div>
//           ) : null}
//         </Box>

//         <Box pt={2} pb={2}>
//           <ThemeProvider theme={buttonTheme}>
//             <Button
//               fullWidth
//               color="primary"
//               variant="contained"
//               onClick={handleClick}
//               id={'loginButton'}
//               loading={isLoading}
//             >
//               {t('Login')}
//             </Button>
//           </ThemeProvider>
//         </Box>

//         {/* <Box pt={2}>
//           <div class='separator divider__text'>{orContinueWith}</div>
//         </Box> */}

//         <Box display={'flex'} justifyContent={'center'} p={1} pt={3} pb={3}>
//           {/* <Box p={1}>
//             <IconButton>
//               <img src={google} alt='' width={32} />
//             </IconButton>
//           </Box>
//           <Box p={1}>
//             <IconButton>
//               <img src={facebookIcon} alt='' width={32} />
//             </IconButton>
//           </Box>
//           <Box p={1}>
//             <IconButton>
//               <img src={linkedInIcon} alt='' width={32} />
//             </IconButton>
//           </Box> */}
//         </Box>
//       </Box>
//       <Box>
//         <Box display={'flex'} justifyContent={'center'} pb={3}>
//           <Typography variant="subtitle2" className={classes.underLine}>
//             {t('Don’t have an Account?')}&nbsp;&nbsp;
//           </Typography>
//           <Typography variant="subtitle2" className={classes.underLine}>
//             <Link onClick={() => window.open(REGISTER_URL)} href="#">
//               {t('Register')}
//             </Link>
//           </Typography>
//         </Box>
//       </Box>
//       <Box pt={2}>
//         <Box paddingTop={1} paddingBottom={1}>
//           <ThemeProvider theme={colorScheme}>
//             <Typography variant="h6" color="secondary">
//               {t('Need help signing up?')}
//             </Typography>
//           </ThemeProvider>
//         </Box>
//         <Box>
//           <Typography variant="subtitle2" color="grey">
//             {''}
//           </Typography>
//         </Box>
//         <Box pt={1}>
//           <Typography variant="subtitle2" className={classes.underLine}>
//             <Link onClick={() => window.open(CONTACT_US_URL)} href="#">
//               {t('Contact us')}
//             </Link>
//           </Typography>
//         </Box>
//       </Box>
//     </div>
//   );
// }

// const mapStateToProps = state => {
//   return {
//     validateResponse: state.AuthReducer.validateResponse,
//     loginResponse: state.AuthReducer.loginResponse,
//   };
// };

// export default connect(mapStateToProps, {
//   validate,
//   login,
//   getCourses,
//   checkUserLogin,
// })(withTranslation()(PhoneNumberLogin));
