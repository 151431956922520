/**
 * (c) CareerLabs. All rights reserved.
 **/
import CircularProgress from '@material-ui/core/CircularProgress';
import PulseLoader from 'react-spinners/PulseLoader';

const LoadingSpinners = ({ type, size }) => {
  if (type === 'pulse') {
    return <PulseLoader size={size || 10} color={'#1093FF'} />;
  } else if (type === 'circle') {
    return <CircularProgress size={25} thickness={6} color={'inherit'} />;
  }
};

export default LoadingSpinners;
