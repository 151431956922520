/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/StyledPractice';
import {
  getAllSubjectsByProduct,
  getAllConceptsBySubject,
  getAllTopicsByConcept,
} from '../../../redux/action/Dashboard';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { QS_PROPS } from '../../../constant/Variables';
import QueryString from 'qs';
import Slide from '@material-ui/core/Slide';
import {
  getGmatOgQuestions,
  getGreOgDetails,
  getOgAttemptedTests,
  setOfficialGuideState,
  getGreOgOldReport,
} from '../../../redux/action/OfficialGuide';
import { routePaths } from '../../../routes/RoutePath';
import GmatCard from './GmatCard';
import GreCard from './GreCard';
import Header from './Header';
import TimerPopup from '../subject/topicTestInstruction/TimerPopup';

class GuideLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: '',
      testCount: null,
      isOverallReport: false,
      testFilterId: null,
      snackBarOpen: false,
      dialogState: false,
      timetestQuestionsSetId: '',
      Data: {
        attemptTest: 'Attempted test Reports',
        reportTitle: 'Report Date : ',
        subTitle: {
          title1: 'Test Summary',
          title2: 'Error Log',
        },
      },
    };
  }
  handleClick = () => {
    this.setState({ dialogState: true });
  };

  dialogClose = () => {
    this.setState({ dialogState: false });
  };
  isOfficialGuide = () =>
    Boolean(
      localStorage.getItem('userProduct') &&
        JSON.parse(localStorage.getItem('userProduct')).isOfficialGuidePresent
    );
  componentDidMount() {
    window.scroll(0, 0);
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    const {
      difficultyLevel,
      attemptStatus,
      subjectValue,
      conceptValue,
      topicValue,
      attemptValue,
    } = this.props.officialGuideState;
    if (product === 'GMAT') {
      sessionStorage.removeItem('official_guide_gmat_question');
      if (subjectValue && conceptValue && topicValue) {
        this.props.getGmatOgQuestions(
          topicValue,
          conceptValue,
          subjectValue,
          difficultyLevel,
          attemptStatus,
          gmatResponse => {
            if (gmatResponse.success) {
              this.setState({
                testCount: gmatResponse.data.count,
                isOverallReport: gmatResponse.data.isOverAllSummary,
                testFilterId: gmatResponse.data.testFilterId,
              });
            }
          }
        );
      } else {
        this.props.getAllSubjectsByProduct(response => {
          if (response.success) {
            this.props.getAllConceptsBySubject(
              response.data[0].id,
              conceptResponse => {
                if (conceptResponse.success) {
                  this.props.getAllTopicsByConcept(
                    response.data[0].id,
                    'all',
                    topicResponse => {
                      if (topicResponse.success) {
                        this.props.getGmatOgQuestions(
                          'all',
                          'all',
                          response.data[0].id,
                          difficultyLevel,
                          attemptStatus,
                          gmatResponse => {
                            if (gmatResponse.success) {
                              this.setState({
                                testCount: gmatResponse.data.count,
                                isOverallReport:
                                  gmatResponse.data.isOverAllSummary,
                                testFilterId: gmatResponse.data.testFilterId,
                              });
                              let obj = {
                                ...this.props.officialGuideState,
                                subjectValue: response.data[0].id,
                                conceptValue: 'all',
                                topicValue: 'all',
                              };

                              this.props.setOfficialGuideState(obj);
                            }
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        });
      }
    } else {
      if (subjectValue) {
        this.props.getGreOgDetails(
          difficultyLevel,
          attemptStatus,
          subjectValue,
          () => {}
        );
      } else {
        this.props.getAllSubjectsByProduct(response => {
          if (response.success) {
            this.props.getGreOgDetails(
              difficultyLevel,
              attemptStatus,
              'all',
              ogResponse => {
                if (ogResponse.success) {
                  let obj = {
                    ...this.props.officialGuideState,
                    subjectValue: 'all',
                    conceptValue: null,
                    topicValue: 'all',
                  };
                  this.props.setOfficialGuideState(obj);
                }
              }
            );
          }
        });
      }
    }
    this.props.getOgAttemptedTests(response => {
      if (response.success) {
        // console.log(response.data[0].testExecutionId,"eeghyhty")
        let obj = {
          ...this.props.officialGuideState,
          attemptValue: 'Select',
        };
        console.log(attemptValue, 'response');
        // this.props.setOfficialGuideState(obj);
      }
    });
  }
  handleSackBarToggle = () => {
    this.setState({
      snackBarOpen: !this.state.snackBarOpen,
    });
  };
  handleChange = event => {
    const { name, value } = event.target;
    const { difficultyLevel, attemptStatus, conceptValue, subjectValue } =
      this.props.officialGuideState;
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    switch (name) {
      case 'subjectValue': {
        if (product === 'GRE') {
          this.props.getGreOgDetails(
            difficultyLevel,
            attemptStatus,
            value,
            response => {
              if (response.success) {
                let obj = {
                  ...this.props.officialGuideState,
                  subjectValue: value,
                };
                this.props.setOfficialGuideState(obj);
              }
            }
          );
        } else {
          this.props.getAllConceptsBySubject(value, response => {
            if (response.success) {
              this.props.getAllTopicsByConcept(value, 'all', topicResponse => {
                if (topicResponse.success) {
                  this.props.getGmatOgQuestions(
                    'all',
                    'all',
                    value,
                    difficultyLevel,
                    attemptStatus,
                    gmatResponse => {
                      if (gmatResponse.success) {
                        this.setState({
                          testCount: gmatResponse.data.count,
                          isOverallReport: gmatResponse.data.isOverAllSummary,
                          testFilterId: gmatResponse.data.testFilterId,
                        });
                        let obj = {
                          ...this.props.officialGuideState,
                          subjectValue: value,
                          conceptValue: 'all',
                          topicValue: 'all',
                        };
                        this.props.setOfficialGuideState(obj);
                      }
                    }
                  );
                }
              });
            }
          });
        }
        break;
      }
      case 'conceptValue': {
        this.props.getAllTopicsByConcept(subjectValue, value, response => {
          if (response.success) {
            this.props.getGmatOgQuestions(
              'all',
              value,
              subjectValue,
              difficultyLevel,
              attemptStatus,
              gmatResponse => {
                if (gmatResponse.success) {
                  this.setState({
                    testCount: gmatResponse.data.count,
                    isOverallReport: gmatResponse.data.isOverAllSummary,
                    testFilterId: gmatResponse.data.testFilterId,
                  });
                  let obj = {
                    ...this.props.officialGuideState,
                    conceptValue: value,
                    topicValue: 'all',
                  };
                  this.props.setOfficialGuideState(obj);
                }
              }
            );
          }
        });
        break;
      }
      case 'topicValue': {
        this.props.getGmatOgQuestions(
          value,
          conceptValue,
          subjectValue,
          difficultyLevel,
          attemptStatus,
          gmatResponse => {
            if (gmatResponse.success) {
              this.setState({
                testCount: gmatResponse.data.count,
                isOverallReport: gmatResponse.data.isOverAllSummary,
                testFilterId: gmatResponse.data.testFilterId,
              });
              let obj = {
                ...this.props.officialGuideState,
                topicValue: value,
              };
              this.props.setOfficialGuideState(obj);
            }
          }
        );
        break;
      }
      default:
        break;
    }
  };
  handleTestReport = event => {
    console.log('fgfgh');
    const { value } = event.target;
    const { attemptValue } = this.props.officialGuideState;
    console.log(value, 'ehbj');
    const { test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    // this.props.getGreOgOldReport(attemptValue, response => {
    this.props.history.replace(
      `${routePaths.dashboard.gmatOgReport}?testExecutionId=${value}&test_type=OG_PRACTISETEST&og_course=GMAT&isReportGenerated=true`
    );
    //   this.setState({
    //     ...this.state,
    //     attemptValue:value,
    //     report: response.data,
    //   });
    // });
  };

  handleAttemptStatus = event => {
    const { value } = event.target;
    const { difficultyLevel, topicValue, conceptValue, subjectValue } =
      this.props.officialGuideState;
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    if (product === 'GMAT') {
      this.props.getGmatOgQuestions(
        topicValue,
        conceptValue,
        subjectValue,
        difficultyLevel,
        value,
        gmatResponse => {
          if (gmatResponse.success) {
            this.setState({
              testCount: gmatResponse.data.count,
              isOverallReport: gmatResponse.data.isOverAllSummary,
              testFilterId: gmatResponse.data.testFilterId,
            });
            let obj = {
              ...this.props.officialGuideState,
              attemptStatus: value,
            };
            this.props.setOfficialGuideState(obj);
          }
        }
      );
    } else {
      this.props.getGreOgDetails(
        difficultyLevel,
        value,
        subjectValue,
        response => {
          if (response.success) {
            let obj = {
              ...this.props.officialGuideState,
              attemptStatus: value,
            };
            this.props.setOfficialGuideState(obj);
          }
        }
      );
    }
  };
  handleDifficultyLevel = event => {
    const { name } = event.target;
    const {
      difficultyLevel,
      attemptStatus,
      topicValue,
      conceptValue,
      subjectValue,
    } = this.props.officialGuideState;
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    let arr = [...difficultyLevel];
    let index = arr.indexOf(name);
    if (index > -1) arr.splice(index, 1);
    else arr.push(name);
    if (product === 'GMAT') {
      this.props.getGmatOgQuestions(
        topicValue,
        conceptValue,
        subjectValue,
        arr,
        attemptStatus,
        gmatResponse => {
          if (gmatResponse.success) {
            this.setState({
              testCount: gmatResponse.data.count,
              isOverallReport: gmatResponse.data.isOverAllSummary,
              testFilterId: gmatResponse.data.testFilterId,
            });
            let obj = {
              ...this.props.officialGuideState,
              difficultyLevel: arr,
            };
            this.props.setOfficialGuideState(obj);
          }
        }
      );
    } else {
      this.props.getGreOgDetails(arr, attemptStatus, subjectValue, response => {
        if (response.success) {
          let obj = {
            ...this.props.officialGuideState,
            difficultyLevel: arr,
          };
          this.props.setOfficialGuideState(obj);
        }
      });
    }
  };
  handleButtonClick = testQuestionId => {
    this.props.history.push(
      `${routePaths.dashboard.officialGuideTestInstruction}?testQuestionId=${testQuestionId}&og_course=GRE`
    );
  };
  handleOnClick = e => {
    this.props.history.push({
      pathname: routePaths.dashboard.gmatOverallSummary,
    });
  };
  getGreOGData = res => {
    if (res && res.success) return res.data || [];
    else return [];
  };
  getOgReportData = res => {
    if (res && res.success) return res.data || [];
    else return [];
  };
  getGmatOGData = res => {
    if (res && res.success) return res.data?.questions || [];
    else return [];
  };
  handleGmatQuestion = (
    filterQuestionList,
    testQuestionsSetId,
    testCount,
    testFilterId
  ) => {
    sessionStorage.setItem(
      'official_guide_gmat_question',
      JSON.stringify(filterQuestionList)
    );
    this.setState({ timetestQuestionsSetId: testQuestionsSetId });
    if (testFilterId === null) {
      if (testCount >= 5) {
        this.setState({
          snackBarOpen: true,
        });
      } else {
        this.props.history.push(
          `${routePaths.dashboard.officialGuideTestInstruction}?testQuestionId=${testQuestionsSetId}&og_course=GMAT`
        );
      }
    } else this.setState({ dialogState: true });
  };

  dialogYesBtn = () => {
    const { timetestQuestionsSetId } = this.state;
    const { subjectValue, conceptValue, topicValue, difficultyLevel } =
      this.props.officialGuideState;
    let combination = {
      subjectValue: subjectValue,
      conceptValue: conceptValue,
      topicValue: topicValue,
      difficultyLevel: difficultyLevel?.toString(),
      testFilterId: null,
    };
    sessionStorage.setItem('combination', JSON.stringify(combination));
    this.props.history.push(
      `${routePaths.dashboard.officialGuideTestInstruction}?testQuestionId=${timetestQuestionsSetId}&og_course=GMAT`
    );
  };
  dialogNoBtn = () => {
    const { timetestQuestionsSetId } = this.state;
    this.props.history.push(
      `${routePaths.dashboard.officialGuideTestInstruction}?testQuestionId=${timetestQuestionsSetId}&og_course=GMAT`
    );
  };
  handleReportClick = testExecutionId => {
    this.props.history.push(
      `${routePaths.dashboard.greOgReport}?testExecutionId=${testExecutionId}&test_type="OG_PRACTISETEST"&og_course=GRE`
    );
  };
  render() {
    const { t } = this.props;
    const { data: subjectData } = this.props.allSubjectList;
    const { data: conceptData } = this.props.allConceptList;
    const { data: topicData } = this.props.allTopicList;
    const { greOgDetails, gmatOgQuestions, officialGuideState, OgReport } =
      this.props;
    const {
      subjectValue,
      conceptValue,
      topicValue,
      difficultyLevel,
      attemptStatus,
      attemptValue,
    } = officialGuideState;
    const { dialogClose, dialogYesBtn, dialogNoBtn } = this;
    const { dialogState, testFilterId } = this.state;
    const greOG_Data = this.getGreOGData(greOgDetails);
    const gmatOG_Question = this.getGmatOGData(gmatOgQuestions);
    const OG_Report = this.getOgReportData(OgReport);
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    if (!this.isOfficialGuide())
      this.props.history.push(routePaths.dashboard.home);
    console.log(OG_Report, 'ogreport');
    console.log(subjectData, 'subjectData');
    console.log(conceptData, ' conceptData');
    console.log(topicData, 'topicData');
    console.log(subjectValue, 'subjectValue');
    console.log(conceptValue, 'conceptValue');
    console.log(topicValue, 'topicValue');
    console.log(attemptStatus, 'attemptStatus');
    console.log(difficultyLevel, 'difficultyLevel');
    console.log(attemptValue, 'attemptValue');
    console.log(this.state.testCount, 'testcount');
    let combination = {
      subjectValue: subjectValue,
      conceptValue: conceptValue,
      topicValue: topicValue,
      difficultyLevel: difficultyLevel?.toString(),
      testFilterId: testFilterId,
    };
    console.log(combination, 'combination', combination.testFilterId);
    sessionStorage.setItem('combination', JSON.stringify(combination));
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Header
              t={t}
              subjectData={subjectData}
              conceptData={conceptData}
              topicData={topicData}
              subjectValue={subjectValue}
              conceptValue={conceptValue}
              topicValue={topicValue}
              ogReport={OG_Report}
              attemptValue={attemptValue}
              handleTestReport={this.handleTestReport}
              attemptStatus={attemptStatus}
              difficultyLevel={difficultyLevel}
              handleChange={this.handleChange}
              handleAttemptStatus={this.handleAttemptStatus}
              handleDifficultyLevel={this.handleDifficultyLevel}
              handleOnClick={this.handleOnClick}
              product={product}
              isOverallReport={this.state.isOverallReport}
            />
          </Grid>
          {product === 'GRE' && (
            <Grid item xs={12}>
              <Box pt={1} />
              <GreCard
                t={t}
                greOG_Data={greOG_Data}
                handleButtonClick={this.handleButtonClick}
                handleReportClick={this.handleReportClick}
              />
            </Grid>
          )}
          {product === 'GMAT' && (
            <Grid item xs={12}>
              <GmatCard
                gmatOG_Question={gmatOG_Question}
                onClick={this.handleGmatQuestion}
                testCount={this.state.testCount}
                testFilterId={this.state.testFilterId}
              />
            </Grid>
          )}
        </Grid>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleSackBarToggle}
          TransitionComponent={Slide}
        >
          <Alert severity="warning" variant="filled">
            Attend the same combination of Test not more than 5 times !!
          </Alert>
        </Snackbar>
        <TimerPopup
          dialogState={dialogState}
          dialogClose={dialogClose}
          testFilterId={testFilterId}
          dialogYesBtn={dialogYesBtn}
          dialogNoBtn={dialogNoBtn}
        />
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state.DashBoardReducer,
    ...state.OfficialGuideReducer,
  };
};
export default connect(mapStateToProps, {
  getAllSubjectsByProduct,
  getAllConceptsBySubject,
  getAllTopicsByConcept,
  getOgAttemptedTests,
  getGreOgDetails,
  getGmatOgQuestions,
  getGreOgOldReport,
  setOfficialGuideState,
})(withTranslation()(GuideLanding));
