/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import { Select, Divider } from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';

export const HeadDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  overflow-x: auto;
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
  padding-left: 16px;
`;

export const LeftContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  overflow-y: scroll !important;
  height: 70vh;

  @media only screen and (min-width: ${1478}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 70vh;
  }

  @media only screen and (min-width: ${1680}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 72vh;
  }

  @media only screen and (min-width: ${1848}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 74vh;
  }
`;

export const TopicTitleWhite = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding-right: 184px;
  padding: 20px;
  cursor: ${props =>
    props.pointer || props.pointer === undefined ? 'pointer' : 'default'};
  margin: 16px;
`;

export const TopicTitleBlue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  background: #1093ff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding-right: 184px;
  padding: 20px;
  cursor: pointer;
  margin: 16px;
`;

export const StyledSelect = withStyles(theme => ({
  root: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1093ff',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '30px',
    padding: '8px 12px',
  },
  select: {
    paddingRight: '40px !important',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '30px !important',
  },
  icon: {
    marginRight: '10px',
    color: '#1093FF',
  },
}))(Select);

export const listItem = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        margin: '2px 8px',

        '&$selected': {
          backgroundColor: '#F2F9FF !important',
        },
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: '30px !important',
      },
    },
  },
});

export const MuiDivider = withStyles({
  root: {
    border: '1px solid #1093ff',
    flexGrow: 1,
    background: '#1093ff',
  },
})(Divider);

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.p};
`;

export const TopicTitleBlur = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  background: #1093ff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding-right: 184px;
  padding: 20px;
  margin: 16px;
  opacity: 0.1;
`;

export const QuestionDiv = styled.div`
  overflow: auto;
  padding-right: 16px;
  @media only screen and (min-width: ${1848}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    height: 67vh;
  }

  @media only screen and (max-width: ${1680}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    height: 63vh;
  }

  @media only screen and (max-width: ${1479}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    height: 63vh;
  }
`;
