/**
 * (c) CareerLabs. All rights reserved.
 **/
import axios from 'axios';
import Cookies from 'js-cookie';
import { DEFAULT_URL } from '../../api/API';
import { GET } from '../../axios/Axios';
import { GET_OG } from '../../axios/PublicAxios';
import { decrypt } from '../../utils/Crypto';
import { catchError, errorHandler } from '../../utils/methods/Helper';
import { OFFICIAL_GUIDE } from '../Action';

/**
 * Get Gre Official Guide Details
 * @param {Array} difficultyLevel
 * @param {String} attemptStatus
 * @param {Function} callback
 * @returns Function
 */
export const getGreOgDetails = (
  difficultyLevel,
  attemptStatus,
  subjectId,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/officialguidetest`,
      {
        difficultyLevel: difficultyLevel?.toString(),
        status: attemptStatus === 'ALL' ? null : attemptStatus,
        subjectId: subjectId,
      },
      response => {
        dispatch({
          type: OFFICIAL_GUIDE.getGreOGDetails,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        dispatch(errorHandler(OFFICIAL_GUIDE.getGreOGDetails, error, false));
        callback(catchError(error));
      }
    );
  };
};

/**
 * Get Gmat Official Guide Details
 * @param {String} topicId
 * @param {Array} difficultyLevel
 * @param {String} attemptStatus
 * @param {Function} callback
 * @returns Function
 */
export const getGmatOgQuestions = (
  topicId,
  conceptId,
  subjectId,
  difficultyLevel,
  attemptStatus,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/gmatofficialguidetest/topic/${topicId}`,
      {
        difficultyLevel: difficultyLevel?.toString() || null,
        status: attemptStatus === 'ALL' ? null : attemptStatus,
        conceptId: conceptId,
        subjectId: subjectId,
      },
      response => {
        callback(response.data);
        dispatch({
          type: OFFICIAL_GUIDE.getGmatOGQuestions,
          payload: response.data,
        });
      },
      error => {
        dispatch(errorHandler(OFFICIAL_GUIDE.getGmatOGQuestions, error, false));
        callback(catchError(error));
      }
    );
  };
};

/**
 * Set Field Value
 * @param {String} fieldName
 * @param {ANY_TYPE} fieldValue
 * @returns Function
 */
export const setFieldValue = (fieldName, fieldValue) => {
  return dispatch =>
    dispatch({
      type: OFFICIAL_GUIDE.setFieldValue,
      fieldName,
      fieldValue,
    });
};

/**
 * Set Official Guide State
 * @param {Object} obj
 * @returns Function
 */
export const setOfficialGuideState = obj => {
  return dispatch => {
    dispatch({
      type: OFFICIAL_GUIDE.setOfficialGuideState,
      payload: obj,
    });
  };
};

export const getGreOgReport = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/topicTestReport`,
      {},

      response => {
        callback(response.data);
        dispatch({
          type: OFFICIAL_GUIDE.getGreOgReport,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getExcel = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    axios
      .get(
        `${DEFAULT_URL}/lms/student/${studentId}/testexecution/${testExecutionId}/excelreport`,
        {
          responseType: 'blob',
        }
      )
      .then(result => {
        callback(result);
      })
      .catch(error => {
        callback(catchError(error));
      });
  };
};
export const getGmatExcel = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    axios
      .get(
        `${DEFAULT_URL}/lms/student/${studentId}/testexecution/${testExecutionId}/excelreport`,
        {
          responseType: 'blob',
        }
      )
      .then(result => {
        callback(result);
      })
      .catch(error => {
        callback(catchError(error));
      });
  };
};

export const getTopicTestExcel = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    axios
      .get(
        `${DEFAULT_URL}/lms/student/${studentId}/testExecution/${testExecutionId}/topictest/excel`,
        {
          responseType: 'blob',
        }
      )
      .then(result => {
        callback(result);
      })
      .catch(error => {
        callback(catchError(error));
      });
  };
};

export const getGmatOgReport = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/topicTestReport`,
      {},

      response => {
        callback(response.data);
        dispatch({
          type: OFFICIAL_GUIDE.gmatOgReport,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getOverAllSummary = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const userProduct = JSON.parse(localStorage.getItem('userProduct'));
  // const testExecutionId = userProduct.testExecutionId;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/report/student/${studentId}`,
      {},

      response => {
        callback(response.data);
        dispatch({
          type: OFFICIAL_GUIDE.overAllReport,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getGreAttemptedTests = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  console.log(studentId);
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/attemptedtests`,
      {},
      response => {
        dispatch({
          type: OFFICIAL_GUIDE.getGreAttemptedTests,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getOgAttemptedTests = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  console.log(studentId);
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/attemptedtests?status=true`,
      {},
      response => {
        dispatch({
          type: OFFICIAL_GUIDE.getOgAttemptedTests,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getGreOgOldReport = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/greReport`,
      {},

      response => {
        callback(response.data);
        dispatch({
          type: OFFICIAL_GUIDE.gmatOgReport,

          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};
