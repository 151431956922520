/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import ProfileViewCard from '../../../../utils/ProfileViewCard';
import time from '../../../../assets/images/time.svg';

export default class EmployeeDetail extends Component {
  render() {
    const { role, type, company, startDate, endDate, roleDescription, t } =
      this.props;
    return (
      <div>
        <ProfileViewCard>
          <Grid container>
            {/* Role ,type */}
            <Grid item xs={8} sm={8} md={7} lg={7} xl={7}>
              <h3 className="profile__detail__card">{role}</h3>
            </Grid>
            <Grid item xs={4} sm={4} md={5} lg={5} xl={5}>
              <h6 className="profile__detail__card">{type}</h6>
            </Grid>
            {/* company */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box pt={1} />
              <h6 className="profile__detail__card">{company}</h6>
            </Grid>
            {/* Date */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display={'flex'} alignItems="center" pt={1}>
                <Box mr={1}>
                  <img alt="" src={time} />
                </Box>
                <h6 className="profile__detail__card">
                  {startDate + ' - ' + endDate}
                </h6>
              </Box>
            </Grid>
            {/* Role Description */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" flexDirection="column" pt={1}>
                <h6 className="profile__detail__card">
                  {t('Role Description')}
                </h6>
                <p className="profile__detail__card">{roleDescription}</p>
              </Box>
            </Grid>
          </Grid>
        </ProfileViewCard>
      </div>
    );
  }
}
