/**
 * (c) CareerLabs. All rights reserved.
 **/

import React from 'react';
import { FlexFiller } from '../../../assets/css/StyledComponent';
import {
  B1,
  Container3,
  Div1,
  Div2,
  Divider,
  Button,
  Tooltip,
  Grey,
  Div3,
  ButtonBox,
} from '../../../assets/css/StyledPractice';
import { FilterContainer as HeadDiv } from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import NoMistake from '../../../assets/images/NoMistake.svg';
import Latex from '../../../utils/LatexViewer';

const MONTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

let convertDate = dateString => {
  let date = new Date(dateString);
  let day = date.getDate();
  let month = MONTH[date.getMonth()];
  let year = date.getFullYear();
  return day + ' ' + month + ' ' + year;
};
export default function Right(props) {
  let index = 0;
  const { topicsQuestions, activeTopic, handleRetakeClick, t } = props;
  if (topicsQuestions !== null) {
    let topics = topicsQuestions.data;
    if (topics.length > 0)
      return (
        <div>
          {topics
            .filter(
              topic => activeTopic === 'default' || topic.id === activeTopic
            )
            .map(topic => (
              <Container3 key={topic.id}>
                {activeTopic === 'default' && (
                  <HeadDiv p="8px">
                    <B1 pr={'6px'}>{`${topic.topic}(${topic.count})`}</B1>
                    <Divider />
                  </HeadDiv>
                )}
                {topic.questions.map(question => {
                  index = index + 1;
                  return (
                    <Div1 key={question.id}>
                      <span className="number_space self-align-start">
                        {index}.
                      </span>
                      <div className="self-align-start latex-question-div">
                        <Latex math={question.question} />
                      </div>
                      <FlexFiller />
                      <Tooltip
                        placement="top-end"
                        arrow
                        title={
                          <Div3>
                            <Grey>{t('last attempt on:')}</Grey>
                            <Grey>{convertDate(question.attemptTime)}</Grey>
                          </Div3>
                        }
                      >
                        <ButtonBox>
                          <Button
                            variant="outlined"
                            color="primary"
                            id={index}
                            onClick={e =>
                              handleRetakeClick(
                                question.id,
                                question.testQuestionSetId,
                                e.currentTarget.id
                              )
                            }
                          >
                            {t('Re-Attempt')}
                          </Button>
                        </ButtonBox>
                      </Tooltip>
                    </Div1>
                  );
                })}
              </Container3>
            ))}
        </div>
      );
    else
      return (
        <Div2>
          <img
            style={{ alignSelf: 'center' }}
            src={NoMistake}
            alt="You have no mistake"
          />
        </Div2>
      );
  } else return null;
}
