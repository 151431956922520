/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Divider, Grid, Box } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import React, { Component } from 'react';
import { FlexFiller } from '../../../assets/css/StyledComponent';
import {
  Button,
  TaskContainer,
  TaskHead,
  TopicBody,
  TopicBodyDescription,
  TopicContainer,
  TopicHead,
  TopicHeadText,
  TopicHeadText2,
  TopicStatusText,
} from '../../../assets/css/subject/LandingComponents';
import CalendarImg from '../../../assets/icons/Calendar.svg';
import TextIcon from '../../../assets/icons/Text.png';
import VideoIcon from '../../../assets/icons/Video.svg';
import TextVideo from '../../../assets/icons/DimTextVideo.svg';
import { routePaths } from '../../../routes/RoutePath';
import StatusIcon from '../../../assets/icons/StatusIcon';
import { EVENTS } from '../../../clevertap/Events';
import clevertap from 'clevertap-web-sdk';

export default class Topic extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // Submit Button Text
  handleButtonText = status => {
    if (status === 'COMPLETED') return 'Revise Topic';
    if (status === 'PROGRESS') return 'Resume Learning';
    if (status === 'TODO') return 'Start Learning';
  };

  // Video or Text Lesson Icon
  topicTypeIcon = type => {
    if (type === 'VIDEO') return VideoIcon;
    if (type === 'TEXT') return TextIcon;
    if (type === 'TEXT_VIDEO') return TextVideo;
  };

  taskStatusText = type => {
    const { t } = this.props;
    if (type === 'COMPLETED')
      return (
        <>
          <Divider
            className={'topic-vertical-divider'}
            orientation="vertical"
            flexItem
          />
          <TopicStatusText>{t('Completed')}</TopicStatusText>
        </>
      );
    if (type === 'PROGRESS')
      return (
        <>
          <Divider
            className={'topic-vertical-divider'}
            orientation="vertical"
            flexItem
          />
          <TopicStatusText>{t('In Progress')}</TopicStatusText>
        </>
      );
    else return null;
  };

  handleTaskClick = (event, topicId, taskId, taskObj) => {
    event.stopPropagation();
    clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
      'Watched source': 'Via subject concepts',
    });
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskId}&type=normal&prevPage=subject_concepts`
    );
  };

  handleTopicClick = (event, id, taskObj) => {
    event.stopPropagation();
    clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
      'Watched source': 'Via subject concepts',
    });
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${id}&type=normal&prevPage=subject_concepts`
    );
  };

  render() {
    const { activeTopic, index, onTopic, topic, conceptName, t } = this.props;
    return (
      <TopicContainer
        square
        expanded={activeTopic === index}
        onClick={onTopic(index)}
      >
        <TopicHead style={{ borderTop: index === 0 ? 'none' : null }}>
          {activeTopic === index ? (
            <RemoveOutlinedIcon className={'open-close-icon'} />
          ) : (
            <AddOutlinedIcon className={'open-close-icon'} />
          )}

          <TopicHeadText>{topic.title}</TopicHeadText>

          <FlexFiller />

          <Box width={'25px'}>
            <img src={StatusIcon(topic.status)} alt="Progress icon" />
          </Box>
        </TopicHead>
        <TopicBody>
          <Grid container direction="column">
            <TopicBodyDescription>{topic.description}</TopicBodyDescription>
            {topic.tasks.map(task => {
              return (
                <TaskContainer aria-label="Expand">
                  <img
                    id="task-icon"
                    src={this.topicTypeIcon(task.type)}
                    alt="Type icon"
                  />
                  <TaskHead
                    onFocus={event => event.stopPropagation()}
                    aria-label="task"
                    onClick={event =>
                      this.handleTaskClick(event, topic.id, task.id, {
                        conceptName: conceptName,
                        topicName: topic.title,
                        taskName: task.title,
                      })
                    }
                  >
                    {task.title}
                  </TaskHead>
                  <FlexFiller />
                  {/* Rights content of the task */}
                  {topic.status !== 'TODO' && (
                    <>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        width={'120px'}
                        alignItems={'flex-start'}
                      >
                        <img
                          src={CalendarImg}
                          alt="Calendar icon"
                          id="calendar-icon"
                        />
                        <TopicHeadText2>{task.schedule}</TopicHeadText2>
                      </Box>
                      <Divider
                        className={'topic-vertical-divider'}
                        orientation="vertical"
                        flexItem
                      />
                      <Box
                        width={'130px'}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'flex-start'}
                      >
                        <QueryBuilderIcon
                          id="topic-clock-icon"
                          color="primary"
                        />
                        <TopicHeadText2>{task.duration}</TopicHeadText2>
                      </Box>
                      <Divider
                        className={'topic-vertical-divider'}
                        orientation="vertical"
                        flexItem
                      />
                      <Box width={'25px'}>
                        <img
                          src={StatusIcon(task.status)}
                          alt="Progress icon"
                        />
                      </Box>
                    </>
                  )}
                </TaskContainer>
              );
            })}
            <Button
              onFocus={event => event.stopPropagation()}
              aria-label="button"
              onClick={event =>
                this.handleTopicClick(event, topic.id, {
                  conceptName: conceptName,
                  topicName: topic.title,
                  taskName: topic.tasks[0]?.title,
                })
              }
            >
              {t(this.handleButtonText(topic.status))}
            </Button>
          </Grid>
        </TopicBody>
      </TopicContainer>
    );
  }
}
