import {
  AppBar,
  Box,
  Card,
  ClickAwayListener,
  Dialog,
  IconButton,
  MenuItem,
  ThemeProvider,
  Toolbar,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Cookies from 'js-cookie';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { headerTheme } from '../assets/css/MuiStyles';
import '../assets/css/Style.css';
import {
  AccountImage,
  AccountImageContainer,
  ButtonBox,
  HeaderContainer,
  HeaderPopupItemText,
  LangItem,
  LangPopper,
  LangText,
  LogoutButton,
  LogoutCloseIcon,
  LogoutDialog,
  LogoutHeaderText,
  NavBarItem,
  NavItemContainer,
} from '../assets/css/StyledComponent';
import { Menu } from '../assets/css/StyledPractice';
import close from '../assets/icons/closeblue.svg';
import MistakeLog from '../assets/icons/MistakeLog .svg';
import QAIcon from '../assets/icons/QandA.svg';
import WebinarIcon from '../assets/icons/WebinarIcon.svg';
import CheckAll from '../assets/icons/Check All.svg';
import OfficialGuide from '../assets/icons/OfficialGuide.svg';
import Report from '../assets/icons/report.svg';
import Avatar from '../assets/images/avatar.png';
import Logo from '../assets/images/logo.png';
import Quiz from '../assets/images/Quiz.png';
import {
  IMAGE_URL,
  LanguagesMenu,
  MenuList,
  GreMenuList,
  NavItem,
} from '../constant/Variables';
import * as track from '../mixpanel/Events';
import { getCourses, viewProfileImage } from '../redux/action/Profile';
import { getStudentInfo, userLogout } from '../redux/action/Student';
import { routePaths } from '../routes/RoutePath';
import { ReactComponent as TopicTestIcon } from '../assets/icons/ViewDetails.svg';
import clevertap from 'clevertap-web-sdk';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      open: false,
      logoutPopupOpen: false,
      openPractice: false,
      questionBankAnchorEl: null,
      anchorEl: null,
      languagePopupAnchorEl: null,
      activeLanguage: localStorage.getItem('I18N_LANGUAGE'),
      popupState: null,
    };
    this.avoid = [
      routePaths.instruction,
      routePaths.report,
      routePaths.submit,
      routePaths.start,
      routePaths.progress,
      routePaths.review,
      routePaths.exam,
      routePaths.test,
      routePaths.score,
      routePaths.examScheduled,
      routePaths.dashboard.home,
      routePaths.profileUpdate,
      routePaths.profileDetail,
      routePaths.contactUs,
      routePaths.allCourses,
      routePaths.introduction,
    ];
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    const {
      dashboard: {
        home,
        subject,
        studyPlan,
        taskDetail,
        questionBank,
        question,
        wall,
        notes,
        support,
        practice,
        strengthsWeakness,
        myAccount,
        mistakeLogs,
        bookmarks,
        progressTracker,
        diagnosticEngine,
        dailyCalendar,
        webinar,
        solveByPassage,
        guide,
        topic,
        mail,
      },
      report,
      profileDetail,
      profileUpdate,
      personalDetail,
      employeeDetail,
      educationDetail,
    } = routePaths;

    this.accept = [
      myAccount,
      strengthsWeakness,
      report,
      home,
      subject,
      studyPlan,
      taskDetail,
      questionBank,
      question,
      wall,
      notes,
      support,
      practice,
      profileDetail,
      profileUpdate,
      personalDetail,
      employeeDetail,
      educationDetail,
      mistakeLogs,
      bookmarks,
      progressTracker,
      diagnosticEngine,
      dailyCalendar,
      webinar,
      solveByPassage,
      guide,
      topic,
      mail,
    ];

    this.acceptNavItem = [
      home,
      subject,
      studyPlan,
      taskDetail,
      questionBank,
      wall,
      notes,
      support,
      profileDetail,
      profileUpdate,
      myAccount,
    ];

    this.practiceMenuItems = [
      {
        icon: <TopicTestIcon color="primary" className="on-boost-mode" />,
        text: 'Topic Test',
        path: routePaths.dashboard.topic,
      },
      {
        icon: <img src={QAIcon} alt="Q and A Icon" className="on-boost-mode" />,
        text: 'Question Bank',
      },

      {
        icon: <BookmarkRoundedIcon color="primary" className="on-boost-mode" />,
        text: 'Bookmark',
        path: routePaths.dashboard.bookmarks,
      },
      {
        icon: (
          <img src={MistakeLog} alt="Mistake Log" className="on-boost-mode" />
        ),
        text: 'Mistake Log ',
        path: routePaths.dashboard.mistakeLogs,
      },
      {
        icon: <img src={WebinarIcon} alt="Webinar" className="on-boost-mode" />,
        text: 'Webinar & Live classes',
        path: routePaths.dashboard.webinar,
      },
    ];

    this.questionBankMenuItems = [
      {
        icon: null,
        text: 'Solve By Question',
        path: routePaths.dashboard.solveByQuestion,
      },

      {
        icon: null,
        text: 'Solve By Passage',
        path: routePaths.dashboard.solveByPassage,
      },
    ];
  }

  fetchCalibrationTestItem() {
    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    console.log(product, 'GREE');
    if (
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')) &&
      JSON.parse(localStorage.getItem('userProduct')).productName === 'GRE' &&
      !this.practiceMenuItems.some(
        ({ text }) => text === 'Learn Vocabulary Innovatively'
      )
    ) {
      this.practiceMenuItems.push({
        icon: <img src={CheckAll} alt="Webinar" className="on-boost-mode" />,
        text: 'Learn Vocabulary Innovatively',
        path: routePaths.dashboard.mail,
      });
    }
    if (!this.practiceMenuItems.some(({ text }) => text === 'Mock Test')) {
      this.practiceMenuItems.push({
        icon: (
          <img src={Quiz} alt="Webinar" className="on-boost-mode" width="30" />
        ),
        text: 'Mock Test',
        path: routePaths.dashboard.support,
      });
    }
    if (
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')) &&
      JSON.parse(localStorage.getItem('userProduct')).isCalibrationCompleted &&
      !this.practiceMenuItems.some(
        ({ text }) => text === 'Calibration Test Report'
      )
    ) {
      this.practiceMenuItems.push({
        icon: (
          <img
            width={'24px'}
            src={Report}
            alt="report"
            className="on-boost-mode"
          />
        ),
        text: 'Calibration Test Report',
        path: `${routePaths.report}?page=insights`,
      });
    }
  }

  componentDidMount() {
    if (
      this.props.user !== null &&
      this.props.user.length === 0 &&
      Cookies.get('accessToken')
      // !Cookies.get('lmsproductId')
    ) {
      this.props.getStudentInfo(() => {});
      this.props.viewProfileImage(() => {});
    }

    this.fetchCalibrationTestItem();

    // To get all courses
    this.props.getCourses(res => {
      localStorage.setItem('testExecutionId', res.data[0].testExecutionId);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Getting the course data if the use come from the all course page
    if (
      prevProps.location.pathname === routePaths.allCourses &&
      prevProps.personalStudyPlans !== this.props.personalStudyPlans
    ) {
      this.props.getCourses(() => {});
    }

    this.fetchCalibrationTestItem();

    let taskVideoFeedback = window.sessionStorage.getItem(
      'task_video_feedback'
    );

    // To remove task video feedback session storage
    if (
      taskVideoFeedback &&
      window.location.pathname != routePaths.dashboard.taskDetail
    )
      window.sessionStorage.removeItem('task_video_feedback');
  }

  isStudyPlanCreated = () => {
    let studyPlanCreated = true;
    if (
      this.props.courses &&
      this.props.courses.data.length !== 0 &&
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct'))
    ) {
      var currentProductData = this.props.courses.data.find(
        item =>
          item.productName ===
          JSON.parse(localStorage.getItem('userProduct')).productName
      );
      if (currentProductData)
        studyPlanCreated = currentProductData.isStudyPlanCreated;
      else if (
        JSON.parse(localStorage.getItem('userProduct')).isStudyPlanCreated
      )
        studyPlanCreated = true;
      else studyPlanCreated = false;
    }

    if (
      localStorage.getItem('userProduct') &&
      !JSON.parse(localStorage.getItem('userProduct'))
    )
      studyPlanCreated = false;

    return studyPlanCreated;
  };

  renderLogoutPopup = () => {
    const { logoutPopupOpen } = this.state;
    const { t } = this.props;

    return (
      <Dialog
        open={logoutPopupOpen}
        maxWidth="sm"
        fullWidth
        style={{ background: 'rgb(0 0 0 / 80%)' }}
        PaperProps={{
          style: {
            borderRadius: '12px',
          },
        }}
      >
        <LogoutDialog>
          <div>
            <LogoutHeaderText>
              {t('Are you sure you want to Log out?')}
            </LogoutHeaderText>
            <LogoutCloseIcon
              src={close}
              onClick={() => {
                this.setState({ logoutPopupOpen: false });
              }}
            />
          </div>
          <ButtonBox>
            <LogoutButton name="yes" onClick={this.handleLogOutClick}>
              {t('Yes')}
            </LogoutButton>
            <LogoutButton
              name="no"
              onClick={() => {
                this.setState({ logoutPopupOpen: false });
              }}
            >
              {t('No')}
            </LogoutButton>
          </ButtonBox>
        </LogoutDialog>
      </Dialog>
    );
  };

  handleLogOutClick = () => {
    this.props.userLogout();
    // CleverTap Logout
    clevertap.logout();
    clevertap.clear();
  };

  handleLanguagePopupClose = e => {
    e.stopPropagation();
    this.setState({
      languagePopupAnchorEl: null,
    });

    this.state.popupState.close();
  };

  handleLanguage = e => {
    const { id } = e.currentTarget;
    this.setState({ activeLanguage: id });
    this.props.i18n.changeLanguage(id);
    localStorage.setItem('I18N_LANGUAGE', id);
    this.handleLanguagePopupClose(e);
  };

  renderLanguagePopup = anchorEl => (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      classes={{
        root: 'language__popup__align',
        paper: 'language__popup__radius',
      }}
      onClose={this.handleLanguagePopupClose}
    >
      <LangPopper>
        {LanguagesMenu.map(lang => (
          <LangItem id={lang.name} onClick={this.handleLanguage}>
            <LangText active={this.state.activeLanguage === lang.name}>
              {lang.text}
            </LangText>
          </LangItem>
        ))}
      </LangPopper>
    </Popover>
  );

  isNotLanguageORLogout = text => {
    return !(text === 'Languages' || text === 'Log out');
  };

  renderAccountDetail = () => {
    const { pathname } = this.props.location;
    const { avoid } = this;

    const product =
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).productName;
    console.log(product, 'product');

    return (
      avoid.some(item => pathname.startsWith(item)) &&
      this.props.user && (
        <div className={'header-style margin__account__info '}>
          <AccountImageContainer>
            {' '}
            {this.props.profileImage && this.props.profileImage !== '' ? (
              <AccountImage
                src={`data:image;base64,${this.props.profileImage}`}
                alt="Avatar"
              />
            ) : (
              <img src={Avatar} alt="Avatar" />
            )}
          </AccountImageContainer>
          <h5
            className={'header-text'}
          >{`${this.props.user.firstName} ${this.props.user.lastName}`}</h5>
          <PopupState
            variant="popover"
            popupId="demo-popup-popover"
            style={{ borderRadius: '12px' }}
          >
            {popupState => (
              <div>
                <KeyboardArrowDownIcon
                  className={'keyboard__arrow__style'}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  style={{
                    position: 'absolute',
                    top: 48,
                  }}
                  PaperProps={{
                    style: {
                      borderRadius: '12px',
                    },
                  }}
                >
                  {product === 'GMAT' ||
                  product === 'GMAT FOCUS' ||
                  product === null ? (
                    <Box p={2} className={'header__menu__popup__width'}>
                      {MenuList.map(item => {
                        return (
                          <div
                            className={`header-nav-align ${
                              this.isNotLanguageORLogout(item.text)
                                ? 'on-boost-mode'
                                : ''
                            }`}
                          >
                            <NavBarItem
                              className={`header__menu_item__style ${
                                this.isNotLanguageORLogout(item.text)
                                  ? 'on-boost-mode'
                                  : ''
                              }`}
                              exact={item.exact}
                              to={
                                this.isNotLanguageORLogout(item.text)
                                  ? item.path
                                  : `${this.props.location.pathname}${this.props.location.search}`
                              }
                              onClick={e =>
                                this.handleRightPopUpClick(item, e, popupState)
                              }
                            >
                              <div
                                className={`header-menu-align ${
                                  this.isNotLanguageORLogout(item.text)
                                    ? 'on-boost-mode'
                                    : ''
                                }`}
                              >
                                <img
                                  src={item.icon}
                                  className={`header-menu-align ${
                                    this.isNotLanguageORLogout(item.text)
                                      ? 'on-boost-mode'
                                      : ''
                                  }`}
                                  alt=""
                                />
                                <HeaderPopupItemText
                                  className={`${
                                    this.isNotLanguageORLogout(item.text)
                                      ? 'on-boost-mode'
                                      : ''
                                  }`}
                                  bold={
                                    item.path === pathname ||
                                    item.text === 'Languages' ||
                                    (item.text === 'Log out' &&
                                      Boolean(this.state.languagePopupAnchorEl))
                                  }
                                >
                                  {item.text}
                                </HeaderPopupItemText>
                              </div>
                              {item.text === 'Languages' ||
                                (item.text === 'Log out' &&
                                  Boolean(this.state.languagePopupAnchorEl) &&
                                  this.renderLanguagePopup(
                                    this.state.languagePopupAnchorEl
                                  ))}
                            </NavBarItem>
                          </div>
                        );
                      })}
                    </Box>
                  ) : product === 'GRE' ? (
                    <Box p={2} className={'header__menu__popup__width'}>
                      {GreMenuList.map(item => {
                        return (
                          <div
                            className={`header-nav-align ${
                              this.isNotLanguageORLogout(item.text)
                                ? 'on-boost-mode'
                                : ''
                            }`}
                          >
                            <NavBarItem
                              className={`header__menu_item__style ${
                                this.isNotLanguageORLogout(item.text)
                                  ? 'on-boost-mode'
                                  : ''
                              }`}
                              exact={item.exact}
                              to={
                                this.isNotLanguageORLogout(item.text)
                                  ? item.path
                                  : `${this.props.location.pathname}${this.props.location.search}`
                              }
                              onClick={e =>
                                this.handleRightPopUpClick(item, e, popupState)
                              }
                            >
                              <div
                                className={`header-menu-align ${
                                  this.isNotLanguageORLogout(item.text)
                                    ? 'on-boost-mode'
                                    : ''
                                }`}
                              >
                                <img
                                  src={item.icon}
                                  className={`header-menu-align ${
                                    this.isNotLanguageORLogout(item.text)
                                      ? 'on-boost-mode'
                                      : ''
                                  }`}
                                  alt=""
                                />
                                <HeaderPopupItemText
                                  className={`${
                                    this.isNotLanguageORLogout(item.text)
                                      ? 'on-boost-mode'
                                      : ''
                                  }`}
                                  bold={
                                    item.path === pathname ||
                                    item.text === 'Languages' ||
                                    (item.text === 'Log out' &&
                                      Boolean(this.state.languagePopupAnchorEl))
                                  }
                                >
                                  {item.text}
                                </HeaderPopupItemText>
                              </div>
                              {item.text === 'Languages' ||
                                (item.text === 'Log out' &&
                                  Boolean(this.state.languagePopupAnchorEl) &&
                                  this.renderLanguagePopup(
                                    this.state.languagePopupAnchorEl
                                  ))}
                            </NavBarItem>
                          </div>
                        );
                      })}
                    </Box>
                  ) : (
                    ''
                  )}
                </Popover>
              </div>
            )}
          </PopupState>
        </div>
      )
    );
  };

  handleRightPopUpClick = (item, e, popupState) => {
    switch (item.text) {
      case 'Log out': {
        popupState.close();
        this.setState({
          logoutPopupOpen: true,
        });
        break;
      }

      case 'Languages':
        this.setState({
          languagePopupAnchorEl: e.currentTarget,
          popupState: popupState,
        });
        break;

      default:
        popupState.close();
    }
  };

  renderLeftMenu = item => {
    if (item.text !== 'Log out' && item.text !== 'Languages') {
      return item.path;
    } else return !item.path;
  };

  renderProduct = () => {
    const { pathname } = this.props.location;
    const { accept } = this;
    const handleProductClick = item => {
      track.DashboardFirstAction({
        'First Action': 'Product Change',
      });

      // If the user click any expired or not active product
      if (
        !item.isProductActive ||
        item.isProductExpired ||
        item.isCoursePaused
      ) {
        this.props.history.push(routePaths.allCourses);
      }

      if (item.isStudyPlanCreated) {
        if (
          this.props.location.pathname === routePaths.report &&
          item.isNoCalibration
        )
          this.props.history.push(routePaths.dashboard.home);
        else {
          localStorage.setItem('userProduct', JSON.stringify(item));
          window.location.reload();
        }
      } else {
        this.props.history.push(routePaths.allCourses);
      }
    };
    if (
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct'))
    ) {
      return (
        this.props.courses &&
        this.props.courses.data.length > 1 &&
        accept.some(item => item.startsWith(pathname)) && (
          <div className={'header-style'} style={{ paddingTop: 15 }}>
            <img
              src={`${IMAGE_URL}${
                JSON.parse(localStorage.getItem('userProduct')).images
                  .productImage
              }`}
              alt="GmatLogo"
              className={'img-style-for-gre'}
            />
            {this.props.courses && this.props.courses.data.length > 1 && (
              <IconButton
                onClick={() => this.setState({ display: !this.state.display })}
                className={'product__arrow__style'}
              >
                {this.state.display ? (
                  <ClickAwayListener
                    onClickAway={() =>
                      this.setState({ display: !this.state.display })
                    }
                  >
                    <Card className={'dropdown-card'}>
                      {this.props.courses.data
                        .filter(
                          item =>
                            item.images.productImage !==
                            JSON.parse(localStorage.getItem('userProduct'))
                              .images.productImage
                          // &&
                          // !(!item.isProductActive || item.isProductExpired)
                        )
                        .map((item, index) => {
                          return (
                            <div className={'header-img-hover'}>
                              <img
                                src={`${IMAGE_URL}${item.images.productImage}`}
                                alt="GmatLogo"
                                onClick={() => handleProductClick(item)}
                                className={'on-boost-mode'}
                              />
                            </div>
                          );
                        })}
                    </Card>
                  </ClickAwayListener>
                ) : null}
                <KeyboardArrowDownIcon style={{ color: '#02A1E5' }} />
              </IconButton>
            )}
          </div>
        )
      );
    } else return <></>;
  };

  practiceActiveRoute = () => {
    const PracticePaths = [
      routePaths.dashboard.bookmarks,
      routePaths.dashboard.mistakeLogs,
      routePaths.dashboard.solveByQuestion,
      routePaths.dashboard.webinar,
      routePaths.dashboard.officialGuide,
      routePaths.dashboard.officialGuideTask,
      routePaths.dashboard.solveByPassage,
      routePaths.dashboard.practice,
      routePaths.dashboard.topic,
      // routePaths.dashboard.support,
    ];
    return PracticePaths.includes(this.props.location.pathname);
  };

  questionBankActiveRoute = () => {
    const QuestionBankPaths = routePaths.dashboard.practice || '';
    return QuestionBankPaths.match(this.props.location.pathname);
  };

  dashboardActiveRoutes = () => {
    if (
      this.props.location.pathname === routePaths.dashboard.progressTracker ||
      this.props.location.pathname === routePaths.dashboard.home ||
      this.props.location.pathname === routePaths.dashboard.diagnosticEngine ||
      this.props.location.pathname === routePaths.dashboard.strengthsWeakness ||
      this.props.location.pathname === routePaths.dashboard.studyPlan ||
      this.props.location.pathname === routePaths.dashboard.dailyCalendar
    )
      return true;
    else return false;
  };

  isOfficialGuide = () => {
    return Boolean(
      localStorage.getItem('userProduct') &&
        JSON.parse(localStorage.getItem('userProduct')).isOfficialGuidePresent
    );
  };

  renderHeaderNavigation = () => {
    const { pathname } = this.props.location;
    const { acceptNavItem } = this;
    return (
      acceptNavItem.some(item => pathname.indexOf(item) > -1) &&
      NavItem({ isOfficialGuide: this.isOfficialGuide() }).map(item => {
        const Icon = item.icon;
        const show = item.show;
        return (
          show && (
            <NavBarItem
              exact={item.exact}
              to={
                item.name !== 'Practise'
                  ? item.path
                  : `${this.props.location.pathname}${this.props.location.search}`
              }
              className={`nav__link 
  ${!(item.name === 'Practise') && 'on-boost-mode'}
  ${item.name === 'Subject' && 'on-focus-6th'}
  `}
              activeClassName="nav__link__active"
              isActive={
                (item.name === 'Practise' &&
                  (() => this.practiceActiveRoute())) ||
                (item.name === 'Dashboard' &&
                  (() => this.dashboardActiveRoutes()))
              }
              onClick={() => {
                track.DashboardFirstAction({
                  'First Action': item.name,
                });
              }}
            >
              <Box
                display={'flex'}
                fontSize={16}
                className={`nav__item 
  ${!(item.name === 'Practise') && 'on-boost-mode'}
  `}
                gridGap={'2vh'}
                mt={'20px'}
                flex={1}
                padding={'10px 10px'}
                id={item.name === 'Practise' && 'PracticeDownArrow'}
                onClick={
                  item.name === 'Practise'
                    ? this.handlePracticeArrowClick
                    : null
                }
              >
                <Icon />
                {item.name}
                {item.name === 'Practise' && <KeyboardArrowDownIcon />}
              </Box>
              <Box />
            </NavBarItem>
          )
        );
      })
    );
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handlePracticeArrowClick = e => {
    this.setState({
      ...this.state,
      openPractice: true,
      anchorEl: e.currentTarget,
    });
  };

  handleLinkClick = (path, name) => {
    switch (name) {
      case 'Question Bank': {
        break;
      }
      default: {
        this.props.history.push(path);
        this.handleCloseClick();
      }
    }
  };

  handleCloseClick = () => {
    this.setState({ anchorEl: null, openPractice: false });
  };

  renderPracticeMenu = () => {
    return (
      <Menu
        id={'34'}
        anchorEl={this.state.anchorEl}
        onClose={this.handleCloseClick}
        open={this.state.openPractice}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={'practice__menu'}
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '6px 12px',
          },
        }}
        MenuProps={{ disableScrollLock: true }}
      >
        {this.practiceMenuItems.map(item => (
          <MenuItem
            className="practice_menu_options on-boost-mode"
            onClick={() => {
              track.errorLogPageLanding(item.text);
              this.handleLinkClick(item.path, item.text);
            }}
          >
            <span
              className="on-boost-mode"
              style={{
                width: '100%',
              }}
            >
              <Box
                display={'flex'}
                fontSize={16}
                gridGap={'2vh'}
                flex={1}
                padding={'5px 5px'}
                onClick={e =>
                  item.text === 'Question Bank' &&
                  this.handleQuestionBankArrowClick(e)
                }
              >
                <span style={{ width: '42px' }} className="on-boost-mode">
                  {item.icon}
                </span>
                {item.text}
                {item.text === 'Question Bank' && (
                  <Box flex={1} textAlign={'right'}>
                    <KeyboardArrowDownIcon />
                  </Box>
                )}
              </Box>
            </span>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  renderQuestionBankMenu = () => {
    return (
      <Menu
        anchorEl={this.state.questionBankAnchorEl}
        onClose={this.handleQuestionBankClose}
        open={Boolean(this.state.questionBankAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={'question_bank__menu'}
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '12px 24px',
          },
        }}
        MenuProps={{ disableScrollLock: true }}
      >
        {this.questionBankMenuItems.map(item => (
          <MenuItem
            className="question_bank_menu_options on-boost-mode"
            onClick={() => {
              track.errorLogPageLanding(item.text);
              this.handleQuestionBankLink(item.path);
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  handleQuestionBankLink = path => {
    this.props.history.push(path);
    this.handleQuestionBankClose();
  };

  handleQuestionBankArrowClick = e => {
    this.setState({
      ...this.state,
      questionBankAnchorEl: e.currentTarget,
    });
  };

  handleQuestionBankClose = () => {
    this.setState({ questionBankAnchorEl: null });
    this.handleCloseClick();
  };

  shouldRenderNavItems = () => {
    // False means no header nav items

    // NO Product screen
    if (this.props.courses && this.props.courses.data.length === 0) {
      return false;
    }

    // If the product is not active
    if (
      this.props.courses &&
      this.props.courses.data.length === 1 &&
      !this.props.courses.data[0].isProductActive
    ) {
      return false;
    }

    // If the course is expired
    if (
      this.props.courses &&
      this.props.courses.data.length === 1 &&
      this.props.courses.data[0].isProductExpired
    ) {
      return false;
    }

    // If the course is paused
    if (
      this.props.courses &&
      this.props.courses.data.length === 1 &&
      this.props.courses.data[0].isCoursePaused
    ) {
      return false;
    }

    if (this.props.courses) {
      return true;
    }
  };

  shouldRenderProducts = () => {
    return (
      this.props.location.pathname !== routePaths.dashboard.question &&
      this.props.location.pathname !== routePaths.dashboard.resultProgress
    );
  };
  render() {
    return (
      <ThemeProvider theme={headerTheme}>
        <AppBar
          id={'app-bar-style'}
          style={{
            visibility:
              this.props.location.pathname === routePaths.dashboard.mail
                ? 'hidden'
                : 'visible',
          }}
        >
          <Toolbar>
            <HeaderContainer>
              <div className={'header-style'}>
                <div>
                  <img alt="" className="logo" src={Logo} />
                </div>
                {this.shouldRenderProducts() && this.renderProduct()}
                <NavItemContainer display={'flex'}>
                  {this.props.location.pathname !==
                    routePaths.dashboard.question &&
                    this.props.location.pathname !==
                      routePaths.dashboard.changePassword &&
                    this.props.location.pathname !==
                      routePaths.dashboard.passwordSuccess &&
                    this.props.location.pathname !==
                      routePaths.dashboard.instruction &&
                    this.props.location.pathname !==
                      routePaths.dashboard.resultProgress &&
                    this.props.location.pathname !==
                      routePaths.dashboard.report &&
                    this.isStudyPlanCreated() &&
                    this.shouldRenderNavItems() &&
                    this.renderHeaderNavigation()}
                </NavItemContainer>
              </div>
              {this.renderLogoutPopup()}
              {this.renderAccountDetail()}
              {this.renderPracticeMenu()}
              {this.renderQuestionBankMenu()}
            </HeaderContainer>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    profileImage: state.profileReducer.profileImage,
    courses: state.profileReducer.courses,
    personalStudyPlans: state.testReducer.personalStudyPlans,
  };
};

export default connect(mapStateToProps, {
  getStudentInfo,
  viewProfileImage,
  getCourses,
  userLogout,
})(withTranslation()(withRouter(Header)));
