/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import {
  Bullet,
  BulletBox,
  ButtonBox,
  Main,
  MainStyle,
  SubTitle,
  Text,
} from '../../../../../assets/css/GmatStyles';
import ButtonImg from '../../../../../assets/images/ButtonImg.svg';
import { QS_PROPS } from '../../../../../constant/Variables';
import PageLoader from '../../../../../utils/components/PageLoader';

function GmatInstruction(props) {
  const { questionSet, section } = QueryString.parse(
    props.location.search,
    QS_PROPS
  );
  const [sectionloader, setSectionLoader] = useState(false);

  useEffect(() => {
    if (section) setSectionLoader(true);
    props.getTestSection(res => {
      if (res.success === true) {
        setSectionLoader(false);
      }
    });
  }, []);

  const instructions = instructionData => {
    if (instructionData !== null)
      return instructionData.data.instruction.map(item => {
        console.log(instructionData.data.instruction, 'sd');
        return (
          <Text>
            <BulletBox>
              <Bullet />
            </BulletBox>
            <Typography variant="body1" color="textSecondary">
              {item}
            </Typography>
          </Text>
        );
      });
  };

  const description = descriptionData => {
    if (descriptionData !== null)
      return descriptionData?.data?.testSection?.description.map(item => {
        console.log(descriptionData.data, 'sdfsd');
        return (
          <Text>
            <BulletBox>
              <Bullet />
            </BulletBox>
            <Typography variant="body1" color="textSecondary">
              {item}
            </Typography>
          </Text>
        );
      });
  };

  console.log(instructions, 'sdsd');
  console.log(props.getInstructionsResponse, props, 'dc');
  console.log(props.getTestSection, 'dsc');

  const productName = JSON.parse(
    localStorage.getItem('userProduct')
  ).productName;
  return (
    <MainStyle>
      {section ? (
        <>
          <Typography variant="h3" color="textPrimary" className={'gmat_head'}>
            {!_.isEmpty(props.section) && props.section.data.testSection.name}
            {` Section Instructions`}
          </Typography>

          <Typography
            variant="h3"
            color="textPrimary"
            className={'gmat_head_section'}
          >
            {!_.isEmpty(props.section) &&
              props.section.data.testSection.noOfQuestions}
            {` Questions`}
          </Typography>

          <Typography
            variant="h3"
            color="textPrimary"
            className={'gmat_head_section'}
          >
            {`Time- `}
            {!_.isEmpty(props.section) &&
              props.section.data.testSection.duration}
            {` minutes`}
          </Typography>
        </>
      ) : productName === 'GMAT FOCUS' ? (
        <Typography variant="h3" color="textPrimary" className={'gmat_head'}>
          GMAT FOCUS Calibration Test
        </Typography>
      ) : (
        <Typography variant="h3" color="textPrimary" className={'gmat_head'}>
          GMAT Calibration Test
        </Typography>
      )}
      <Main>
        <Typography variant="h2" color="textPrimary">
          Test Instructions
        </Typography>
      </Main>

      <Grid>
        {props.instructionLoading || sectionloader ? (
          <div className="parent-pageloader-height">
            <PageLoader />
          </div>
        ) : (
          <>
            {!section ? (
              <>
                <SubTitle>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ paddingLeft: '16px' }}
                  >
                    {props.getInstructionsResponse !== null &&
                      props.getInstructionsResponse.data.instructionTitle}
                  </Typography>
                </SubTitle>
                {instructions(props.getInstructionsResponse)}
              </>
            ) : (
              <SubTitle>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{ paddingLeft: '16px' }}
                >
                  {!_.isEmpty(props.section) &&
                    props.section.data.testSection.descriptionTitle}
                </Typography>
                {description(props.section)}
              </SubTitle>
            )}
            <ButtonBox>
              <Typography
                variant="h4"
                color="textPrimary"
                className="button_image_style"
              >
                Click &nbsp;
                <img src={ButtonImg} alt="" />
                &nbsp; to continue
              </Typography>
            </ButtonBox>
          </>
        )}
      </Grid>
    </MainStyle>
  );
}

export default GmatInstruction;
