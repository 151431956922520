/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { buttonTheme, dropdownTheme } from '../../../../assets/css/MuiStyles';
import { CLEVERTAP } from '../../../../clevertap/Config';
import { EVENTS } from '../../../../clevertap/Events';
import {
  getAspirationUniversity,
  getCourses,
  updateProfileSetup,
} from '../../../../redux/action/Profile';
import { getStudentInfo } from '../../../../redux/action/Student';
import { routePaths } from '../../../../routes/RoutePath';
import DatePicker from '../../../../utils/components/DatePicker';
import TextField from '../../../../utils/components/TextField';
import {
  dataURLtoFile,
  generateDownload,
} from '../../../../utils/generateImage';
import { dateFormat } from '../../../../utils/methods/DateFormat';
import { isEmpty } from '../../../../utils/Validation';
import CreatableDropdown from './CreatableDropdown';

const filter = createFilterOptions();

class ProfileSetUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      firstName: '',
      lastName: '',
      dob: null,
      college: [],

      crop: { x: 0, y: 0 },
      zoom: 1,
      rotate: 0,

      profilePic: null,
      previewImage: null,
      image: null,
      croppedAreaPixels: null,
      visible: true,
      updatedProfileImageFile: null,
      updatedProfileImage: null,

      firstNameError: false,
      lastNameError: false,
      dateError: false,
      collegeError: false,
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      dateMsg: '',
      collegeMsg: '',
    };
  }

  componentDidMount() {
    window.sessionStorage.setItem('isLogout', false);
    window.addEventListener('resize', () =>
      this.setState({ width: window.innerWidth })
    );
    this.props.getAspirationUniversity();
    this.props.getStudentInfo();
  }

  onFileChange = e => {
    const { type } = e.target.files[0];
    if (
      type.endsWith('jpeg') ||
      type.endsWith('png') ||
      type.endsWith('jpg') ||
      type.endsWith('gif')
    ) {
      this.setState({
        profilePic: e.target.files[0],
        previewImage: URL.createObjectURL(e.target.files[0]),
        visible: false,
      });
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result });
      });
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };

  getImage = async () => {
    const blobImage = await (
      await generateDownload(
        this.state.image,
        this.state.croppedAreaPixels,
        this.state.rotate
      )
    ).toDataURL();
    const image = dataURLtoFile(blobImage, this.state.profilePic.name);
    this.setState({
      updatedProfileImageFile: image,
      updatedProfileImage: URL.createObjectURL(image),
      visible: true,
      rotate: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
    });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  onCancel = () =>
    this.setState({ visible: true, rotate: 0, crop: { x: 0, y: 0 }, zoom: 1 });

  fileToUrl = () => {
    this.setState({ image: URL.createObjectURL(this.state.profilePic) });
  };

  onRotate = () => {
    if (this.state.rotate === 180) {
      this.setState({ rotate: 0 });
    } else {
      this.setState({ rotate: this.state.rotate + 90 });
    }
  };

  handleClick = () => {
    const { dob } = this.state;

    if (isEmpty(dob)) {
      this.setState({
        dateError: true,
        dateMsg: 'Please fill the required field',
      });
    } else if (new Date(this.state.dob).getFullYear() < 1950) {
      this.setState({
        dateError: true,
        dateMsg: 'Date of birth can be beyond 1st Jan 1950',
      });
    } else if (!isEmpty(dob)) {
      this.setState({
        dateError: false,
        dateMsg: '',
      });
    } else {
      this.setState({
        dateError: false,
        dateMsg: '',
      });
    }
    if (this.state.college.length === 0) {
      this.setState({
        collegeError: true,
        collegeMsg: 'Please fill the required field',
      });
    } else if (this.state.college.length < 3) {
      this.setState({
        collegeError: true,
        collegeMsg: 'Choose atleast 3 colleges',
      });
    }
    if (
      !isEmpty(dob) &&
      !(new Date(this.state.dob).getFullYear() < 1950) &&
      this.state.college.length >= 3
    ) {
      let obj = {
        dob: dateFormat(new Date(this.state.dob)),
        aspirationUniversity: this.state.college,
      };
      this.props.updateProfileSetup(obj, response => {});
      this.props.getCourses(response => {
        this.props.history.push(routePaths.allCourses);
      });
    }
  };

  handleName = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value.replace(/[^a-z]|\s+|\r?\n|\r/gim, ''),
      [e.target.name + 'Error']: false,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      const { firstName, lastName, dob, aspirationUniversity } =
        this.props.user;
      let date = null;
      if (dob) date = new Date(dob);

      this.setState({
        firstName: firstName,
        lastName: lastName,
        college: aspirationUniversity,
        dob: date,
      });
    }
  }

  handleChange = (e, newValue) => {
    new Promise((resolve, reject) => {
      let arr = [];
      if (typeof newValue === 'string') {
        arr.push({
          id: null,
          name: newValue?.trim(),
        });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        arr.push({
          id: null,
          name: newValue.inputValue?.trim(),
        });
      } else {
        for (let i = 0; i < newValue.length; i++) {
          if (typeof newValue[i] === 'string') {
            arr.push({
              id: null,
              name: newValue[i]?.trim(),
            });
          } else {
            arr.push({ ...newValue[i] });
          }
        }
      }
      resolve(arr);
    }).then(value => {
      // To remove duplicate options
      let resultArr = [...value].filter(
        (e, i, a) =>
          a.findLastIndex(x => (x.id && x.id === e.id) || x.name === e.name) ===
            i &&
          a.findIndex(y => (y.id && y.id === e.id) || y.name === e.name) === i
      );
      this.setState({
        college: resultArr,
        collegeError: false,
        collegeMsg: '',
      });
    });
  };

  render() {
    const { t } = this.props;
    const { handleChange } = this;

    return (
      <div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={'profile__fit__right__container'}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box mb={3}>
                <h3>{t('Profile setup')}</h3>
              </Box>
            </Grid>
            {/* first name */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container>
                <Grid item xs={12} sm={6} md={5}>
                  <Box mb={2}>
                    <TextField
                      error={this.state.firstNameError}
                      helperText={this.state.firstNameErrorMsg}
                      name="firstName"
                      variant="standard"
                      label={t('First Name')}
                      disabled={true}
                      size={'medium'}
                      value={this.state.firstName}
                      fullWidth
                    />
                  </Box>
                </Grid>
                {/* last  name */}
                <Grid item xs={12} sm={6} md={5}>
                  <Box mb={2} ml={this.state.width > 600 ? 3 : 0}>
                    <TextField
                      id={'no-outline'}
                      error={this.state.lastNameError}
                      helperText={this.state.lastNameErrorMsg}
                      name="lastName"
                      variant={'standard'}
                      label={t('Last Name')}
                      disabled={true}
                      size={'medium'}
                      defaultValue={this.props.user.firstName}
                      value={this.state.lastName}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box mb={2}>
                        <DatePicker
                          onChange={date =>
                            this.setState({
                              dob: date,
                              dateError: false,
                              dateMsg: '',
                            })
                          }
                          label={t('Date of Birth')}
                          value={this.state.dob}
                          error={this.state.dateError}
                          helperText={t(this.state.dateMsg)}
                          fullWidth
                          required
                          disableFuture
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box mb={2}>
                        <ThemeProvider theme={dropdownTheme}>
                          <CreatableDropdown
                            id={'no-outline'}
                            name={'aspiration_college'}
                            value={this.state.college}
                            onChange={handleChange}
                            optionLabel={'name'}
                            options={
                              this.props.universityList !== null
                                ? this.props.universityList.length !== 0
                                  ? this.props.universityList
                                  : []
                                : []
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              if (params.inputValue !== '') {
                                filtered.push({
                                  id: null,
                                  name: params.inputValue,
                                });
                              }

                              return filtered;
                            }}
                            textProps={{
                              required: true,
                              label: t('Aspiring Colleges'),
                              error: this.state.collegeError,
                              helperText: t(this.state.collegeMsg),
                            }}
                          />
                        </ThemeProvider>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid
                    container
                    justify={this.state.width > 500 ? 'unset' : 'center'}
                  >
                    <Grid item xs={8} sm={4} md={4}>
                      <ThemeProvider theme={buttonTheme}>
                        <Button
                          color={'primary'}
                          variant="contained"
                          fullWidth
                          onClick={this.handleClick}
                        >
                          {t('Submit')}
                        </Button>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    universityList: state.profileReducer.university,
    user: state.studentReducer.user,
    loginUser: state.AuthReducer.user.data,
  };
};
export default connect(mapStateToProps, {
  getAspirationUniversity,
  getStudentInfo,
  updateProfileSetup,
  getCourses,
})(withTranslation()(ProfileSetUp));
