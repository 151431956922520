// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/calender.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * (c) CareerLabs. All rights reserved.\n **/\ninput[type='date']::-webkit-calendar-picker-indicator {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n", "",{"version":3,"sources":["webpack://src/utils/components/assets/styles/Theme.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,6DAAmD;AACrD","sourcesContent":["/**\n * (c) CareerLabs. All rights reserved.\n **/\ninput[type='date']::-webkit-calendar-picker-indicator {\n  background: url('../images/calender.png') no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
