/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { primaryButtonTheme } from '../../../../assets/css/MuiStyles';
import {
  AddressTitle,
  NextButton,
} from '../../../../assets/css/StyledComponent';
import { updateProfile } from '../../../../redux/action/Profile';
import { getStudentInfo } from '../../../../redux/action/Student';
import { routePaths } from '../../../../routes/RoutePath';
import DatePicker from '../../../../utils/components/DatePicker';
import TextField from '../../../../utils/components/TextField';
import { dateFormat } from '../../../../utils/methods/DateFormat';
import { isEmpty } from '../../../../utils/Validation';

class PersonalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      dob: '',
      address: '',

      apartmentName: '',
      street1: '',
      street2: '',
      landmark: '',
      pincode: '',
      state: '',
      city: '',

      emailId: '',
      phoneNumber: '',
      facebookUrl: '',
      linkedInUrl: '',
      twitterUrl: '',

      firstNameMsg: '',
      lastNameMsg: '',
      dobMsg: '',
      addressMsg: '',
      emailIdMsg: '',
      phoneNumberMsg: '',
      linkedInUrlErr: '',
      fbUrlErr: '',
      twitterUrlErr: '',

      firstNameErr: false,
      lastNameErr: false,
      dobErr: false,
      addressErr: false,
      emailIdErr: false,
      phonenumbErerr: false,
    };
  }

  componentDidMount() {
    window.sessionStorage.setItem('isLogout', true);
    if (this.props.user.length === 0) this.props.getStudentInfo();
    else this.fetchData();
  }

  fetchData() {
    const {
      firstName,
      lastName,
      dob,
      address,
      email,
      phoneNumber,
      faceBookUrl,
      linkedInUrl,
      twitterUrl,
    } = this.props.user;

    if (address !== null) {
      const {
        suitNoApartmentNo,
        streetAddressOne,
        streetAddressTwo,
        landMark,
        pincode,
        city,
        state,
      } = address;

      this.setState({
        apartmentName: suitNoApartmentNo,
        street1: streetAddressOne,
        street2: streetAddressTwo,
        landmark: landMark,
        pincode: pincode,
        city: city,
        state: state,
      });
    }
    let date = dob && new Date(dob);
    this.setState({
      firstName: firstName,
      lastName: lastName,
      dob: date,
      emailId: email,
      phoneNumber: phoneNumber,
      faceBookUrl: faceBookUrl,
      twitterUrl: twitterUrl,
      linkedInUrl: linkedInUrl,
    });
  }

  validateUrl = (url = '', domain) => {
    var facebookUrl = /^(http|https)\:\/\/(www.|)facebook.com\/.*/i;
    var twitterUrl = /^(http|https)\:\/\/(www.|)twitter.com\/.*/i;
    var linkedInUrl = /^(http|https)\:\/\/(www.|)linkedin.com\/.*/i;

    if (domain === 'facebook') {
      if (!url.match(facebookUrl)) {
        return {
          errorMsg: 'Invalid facebook url',
          error: true,
        };
      }
    } else if (domain === 'twitter') {
      if (!url.match(twitterUrl)) {
        return {
          errorMsg: 'Invalid twitter url',
          error: true,
        };
      }
    } else if (domain === 'linkedIn') {
      if (!url.match(linkedInUrl)) {
        return {
          errorMsg: 'Invalid linkedIn url',
          error: true,
        };
      }
    }
    return false;
  };

  handleSubmit = () => {
    const {
      dob,
      emailId,
      phoneNumber,
      faceBookUrl,
      linkedInUrl,
      twitterUrl,
      apartmentName,
      street1,
      street2,
      landmark,
      pincode,
      state,
      city,
    } = this.state;

    var keys = [
      'apartmentName',
      'street1',
      'street2',
      'pincode',
      'state',
      'city',
    ];

    var error = false;

    for (let i = 0; i < keys.length; i++) {
      if (isEmpty(eval(keys[i]))) {
        this.setState({
          [keys[i] + 'Err']: 'Please fill the required field',
        });
        error = true;
      } else if (keys[i] === 'pincode') {
        if (eval(keys[i]).length < 6) {
          error = true;
          this.setState({
            [keys[i] + 'Err']: 'Invalid pincode',
          });
        }
      }
    }

    if (isEmpty(dob)) {
      this.setState({
        dobErr: true,
        dobMsg: 'Please fill the required field',
      });
    } else if (new Date(this.state.dob).getFullYear() < 1950) {
      this.setState({
        dobErr: true,
        dobMsg: 'Date of birth can be beyond 1st Jan 1950',
      });
    } else {
      this.setState({
        dobErr: false,
        dobMsg: '',
      });
    }
    if (isEmpty(emailId)) {
      this.setState({
        emailIdErr: true,
        emailIdMsg: 'Please fill the required field',
      });
    } else {
      this.setState({
        emailIdErr: false,
        emailIdMsg: '',
      });
    }
    if (isEmpty(phoneNumber)) {
      this.setState({
        phoneNumberErr: true,
        phoneNumberMsg: 'Please fill the required field',
      });
    } else {
      this.setState({
        phoneNumberErr: false,
        phoneNumberMsg: '',
      });
    }
    var fbValid = this.validateUrl(faceBookUrl || '', 'facebook');
    var twitterValid = this.validateUrl(twitterUrl || '', 'twitter');
    var linkedInValid = this.validateUrl(linkedInUrl || '', 'linkedIn');

    if (faceBookUrl && faceBookUrl.length !== 0 && fbValid) {
      this.setState({
        fbUrlErr: fbValid.errorMsg,
      });
      error = fbValid;
    } else {
      this.setState({
        fbUrlErr: '',
      });
    }

    if (twitterUrl && twitterUrl.length !== 0 && twitterValid) {
      this.setState({
        twitterUrlErr: twitterValid.errorMsg,
      });
      error = twitterValid.error;
    } else {
      this.setState({
        twitterUrlErr: '',
      });
    }

    if (linkedInUrl && linkedInUrl.length !== 0 && linkedInValid) {
      this.setState({
        linkedInUrlErr: linkedInValid.errorMsg,
      });
      error = linkedInValid.error;
    } else {
      this.setState({
        linkedInUrlErr: '',
      });
    }

    if (
      !isEmpty(dob) &&
      !isEmpty(emailId) &&
      !isEmpty(phoneNumber) &&
      !(new Date(this.state.dob).getFullYear() < 1950) &&
      !error
    ) {
      let obj = {
        dob: dateFormat(new Date(dob)),
        faceBookUrl: faceBookUrl,
        linkedInUrl: linkedInUrl,
        twitterUrl: twitterUrl,
        address: {
          suitNoApartmentNo: apartmentName,
          streetAddressOne: street1,
          streetAddressTwo: street2,
          landMark: landmark,
          pincode: pincode,
          city: city,
          state: state,
        },
      };
      this.props.updateProfile(obj, res => {
        this.props.getStudentInfo();
        this.props.history.push(routePaths.employeeDetail);
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.fetchData();
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Grid container className="grid__container" spacing={3}>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('First Name')}
              disabled={true}
              fullWidth
              value={this.state.firstName}
              error={this.state.firstNameErr}
              helperText={t(this.state.firstNameMsg)}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Last Name')}
              fullWidth
              value={this.state.lastName}
              error={this.state.lastNameErr}
              disabled={true}
              helperText={t(this.state.lastNameMsg)}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <DatePicker
              id="no-outline"
              onChange={date => {
                this.setState({ dobMsg: '', dobErr: false });
                this.setState({ dob: date });
              }}
              label={t('Date of Birth')}
              value={this.state.dob}
              error={this.state.dobErr}
              helperText={t(this.state.dobMsg)}
              fullWidth
              required
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Email Id')}
              fullWidth
              disabled={true}
              value={this.state.emailId}
              error={this.state.emailIdErr}
              helperText={t(this.state.emailIdMsg)}
              onChange={e => {
                this.setState({ emailIdMsg: '', emailIdErr: false });
                this.setState({ emailId: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Mobile Number')}
              fullWidth
              inputProps={{ maxLength: 10 }}
              type={'Number'}
              disabled={true}
              value={this.state.phoneNumber}
              error={this.state.phoneNumberErr}
              helperText={t(this.state.phoneNumberMsg)}
              onChange={e => {
                this.setState({ phoneNumberMsg: '', phoneNumberErr: false });
                this.setState({ phoneNumber: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField
              id="no-outline"
              variant="standard"
              label={t('Facebook URL (Optional)')}
              fullWidth
              value={this.state.faceBookUrl || ''}
              onChange={e =>
                this.setState({
                  faceBookUrl: e.target.value,
                })
              }
              error={this.state.fbUrlErr && this.state.fbUrlErr.length !== 0}
              helperText={t(this.state.fbUrlErr)}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              id="no-outline"
              variant="standard"
              label={t('LinkedIn URL (Optional)')}
              fullWidth
              value={this.state.linkedInUrl}
              onChange={e =>
                this.setState({
                  linkedInUrl: e.target.value,
                })
              }
              helperText={t(this.state.linkedInUrlErr)}
              error={
                this.state.linkedInUrlErr &&
                this.state.linkedInUrlErr.length !== 0
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField
              id="no-outline"
              variant="standard"
              label={t('Twitter URL (Optional)')}
              fullWidth
              value={this.state.twitterUrl}
              onChange={e =>
                this.setState({
                  twitterUrl: e.target.value,
                })
              }
              helperText={t(this.state.twitterUrlErr)}
              error={
                this.state.twitterUrlErr &&
                this.state.twitterUrlErr.length !== 0
              }
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <AddressTitle padding="20px 0 0 0">
              {t('Address Details')}
            </AddressTitle>
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Suite No / Apartment Name')}
              required
              multiline
              fullWidth
              value={this.state.apartmentName}
              error={
                this.state.apartmentNameErr !== '' &&
                this.state.apartmentNameErr !== undefined
              }
              helperText={t(this.state.apartmentNameErr)}
              onChange={e => {
                this.setState({
                  apartmentNameErr: '',
                  apartmentName: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Street Address 1')}
              required
              multiline
              fullWidth
              value={this.state.street1}
              error={
                this.state.street1Err !== '' &&
                this.state.street1Err !== undefined
              }
              helperText={t(this.state.street1Err)}
              onChange={e => {
                this.setState({ street1Err: '', street1: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Street Address 2')}
              required
              multiline
              fullWidth
              value={this.state.street2}
              error={
                this.state.street2Err !== '' &&
                this.state.street2Err !== undefined
              }
              helperText={t(this.state.street2Err)}
              onChange={e => {
                this.setState({ street2Err: '', street2: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Landmark (Optional)')}
              multiline
              fullWidth
              value={this.state.landmark}
              // error={this.state.street2Err!==""&&this.state.street2Err!==undefined}
              // helperText={t(this.state.street2Err)}
              onChange={e => {
                this.setState({ landmark: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('Pincode')}
              required
              type="number"
              inputProps={{ maxLength: 6 }}
              multiline
              fullWidth
              value={this.state.pincode}
              error={
                this.state.pincodeErr !== '' &&
                this.state.pincodeErr !== undefined
              }
              helperText={t(this.state.pincodeErr)}
              onKeyPress={evt => {
                if (
                  (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
                  evt.which > 57
                ) {
                  evt.preventDefault();
                }
                if (this.state.pincode.length > 5) evt.preventDefault();
              }}
              onChange={e => {
                this.setState({ pincodeErr: '', pincode: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('State')}
              required
              multiline
              fullWidth
              value={this.state.state}
              error={
                this.state.stateErr !== '' && this.state.stateErr !== undefined
              }
              onKeyPress={e => {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if (
                  (keycode >= 97 && keycode <= 122) ||
                  (keycode >= 65 && keycode <= 90) ||
                  keycode === 32
                ) {
                } else {
                  e.preventDefault();
                }
              }}
              helperText={t(this.state.stateErr)}
              onChange={e => {
                this.setState({ stateErr: '', state: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <TextField
              variant="standard"
              label={t('City')}
              required
              multiline
              fullWidth
              value={this.state.city}
              error={
                this.state.cityErr !== '' && this.state.cityErr !== undefined
              }
              onKeyPress={e => {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if (
                  (keycode >= 97 && keycode <= 122) ||
                  (keycode >= 65 && keycode <= 90) ||
                  keycode === 32
                ) {
                } else {
                  e.preventDefault();
                }
              }}
              helperText={t(this.state.cityErr)}
              onChange={e => {
                this.setState({ cityErr: '', city: e.target.value });
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            xl={12}
            className={'button_alignment'}
          >
            <ThemeProvider theme={primaryButtonTheme}>
              <NextButton
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                {t('Next')}
              </NextButton>
            </ThemeProvider>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    personalInfo: state.profileReducer.personalInfo,
  };
};

export default connect(mapStateToProps, { updateProfile, getStudentInfo })(
  withTranslation()(PersonalDetail)
);
