/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Divider, Grid, Snackbar } from '@material-ui/core';

import QueryString from 'qs';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  BookmarkPosition,
  BookmarkPosition1,
} from '../../../../assets/css/StyledComponent';
import '../../../../assets/css/subject/QuestionBank.css';
import {
  BackIcon,
  Container,
  Explanation,
  ExplanationContainer,
  ExplanationTitle,
  GreenAlert,
  IconButtonDiv,
  RedAlert,
  ShowButton,
} from '../../../../assets/css/subject/QuestionBankComponents';
import {
  Main,
  QuestionTitle,
  TestTitle,
} from '../../../../assets/css/test/TestComponent';
import BookmarkIcon from '../../../../assets/icons/Bookmarks.svg';
import RightIcon from '../../../../assets/icons/right.svg';
import WrongIcon from '../../../../assets/icons/wrong.svg';
import { QS_PROPS } from '../../../../constant/Variables';
import {
  getAnswerExplanation,
  getOneQuestion,
  getQuestionAnswer,
} from '../../../../redux/action/DashboardLanding';
import {
  AddBookmarks,
  removeDemoBookmark,
} from '../../../../redux/action/Practice';
import Carousel from '../../../../utils/Carousel';
import { RenderBookMark } from '../../../../utils/components/Bookmark';
import DialogComponent from '../../../../utils/components/DialogComponent';
import PageLoader from '../../../../utils/components/PageLoader';
import VideoPlayerComponent from '../../../../utils/components/VideoPlayerComponent';
import Latex from '../../../../utils/LatexViewer';
import PassageAnswer from '../../course/answer/Passage';
import SingleSelectAnswer from '../../course/answer/SingleSelect';
import PassageQuestion from '../../course/exam/Passage';
import SingleSelectQuestion from '../../course/exam/SingleSelect';
import Passage from './Passage';
import SingleSelect from './SingleSelect';
import { isEmpty } from '../../../../utils/Validation';
import { routePaths } from '../../../../routes/RoutePath';
import DropDown from './DropDown';
import Binary from './Binary';
import TwoPart from './TwoPart';
import MultiTab from './MultiTab';
var bookmarkTimeout = null;
class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      buttonText: 'Submit',
      correctChoices: [],
      selectedChoices: [],
      activeChoice: [],
      answer: '',
      bundleSelect: [],
      testType: '',
      bundleSize: 0,

      snackBarGreen: false,
      snackBarRed: false,

      explanation: '',
      showExplanation: false,
      isLoading: false,
      demoBookmark: null,
      bookMarked: false,
      question_Id: null,
      dialogOpen: false,
      video: null,
      answer1: '',
      error: '',
      bundleSelectedChoice: [],
      choiceAnswers: null,
      twoPartAnswers: null,
    };
    this.videosObj = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      questionId,
      testQuestionId,
      isAnswered,
      type = '',
    } = QueryString.parse(this.props.location.search, QS_PROPS);

    this.showBookmark({ questionId });

    let obj = { questionId: questionId, testQuestionSetId: testQuestionId };
    this.props.getOneQuestion(obj, response => {
      let obj = {
        questionId: questionId,
        testQuestionSetId: testQuestionId,
        type,
      };

      this.props.getOneQuestion(obj, response => {
        if (response.success)
          this.setState({
            data: response.data,
            bookMarked: response.data.isBookmarked,
            testType: response.data.type,
            bundleSize: Number(response.data.totalBundle),
          });

        // For Refresh
        if (isAnswered === 'true') {
          let answerObj = {
            questionId: questionId,
            testQuestionSetId: testQuestionId,
          };
          this.props.getAnswerExplanation(answerObj, response => {
            const greenStatus = response.data.attemptStatus === 'Correct';
            const redStatus = response.data.attemptStatus === 'Incorrect';
            let dataObj = response.data.question;
            dataObj.answer = response.data.answer;

            this.setState({
              ...this.state,
              data: dataObj,
              bookMarked: response.data.question.isBookmarked,
              selectedChoices: response.data.selectedChoices,
              correctChoices: response.data.correctChoices,
              buttonText: 'Show Explanation',
              snackBarGreen: greenStatus,
              snackBarRed: redStatus,
              explanation: response.data.explanation,
              video: response.data.video,
              answer: response.data.answer,
              answer1: response.data.answer1,
            });
          });
        }
      });
    });
  }

  showBookmark = ({ questionId }) => {
    const getDemoBookmark = JSON.stringify(
      localStorage.getItem('demoBookmark')
    );
    const demoBookmark = eval(getDemoBookmark) === 'false' ? true : false;
    this.setState({ question_Id: questionId, demoBookmark: demoBookmark });
    if (demoBookmark) {
      bookmarkTimeout = setTimeout(this.removeDemoBookmarkFunction, 5000);
    }
  };

  // Button Click
  handleAnswer = () => {
    this.setState({ isLoading: true });
    if (this.state.buttonText === 'Show Explanation') {
      this.setState({
        ...this.state,
        showExplanation: true,
        snackBarGreen: false,
        snackBarRed: false,
        isLoading: false,
      });
    } else if (!this.disabled()) {
      const { type = '', isBookmarked } = QueryString.parse(
        this.props.location.search,
        QS_PROPS
      );
      let obj = {
        testExecutionId: this.state.data.testExecutionId,
        questionId: this.state.data.id,
        choices: this.getChoices(),
        answer: this.state.answer?.trim(),
        answer1: this.state.answer1,
        bundleSelectedChoice: this.state.bundleSelectedChoice,
      };
      this.props.getQuestionAnswer(obj, type, isBookmarked, response => {
        // this.setState({ isLoading: false });
        if (response.success) {
          const { questionId, testQuestionId } = QueryString.parse(
            this.props.location.search,
            QS_PROPS
          );
          let answerObj = {
            questionId: questionId,
            testQuestionSetId: testQuestionId,
          };
          this.props.getAnswerExplanation(answerObj, isBookmarked, response => {
            this.setState({ isLoading: false });
            const greenStatus = response.data.attemptStatus === 'Correct';
            const redStatus = response.data.attemptStatus === 'Incorrect';
            this.setState({
              ...this.state,
              data: response.data.question,
              bookMarked: response.data.question.isBookmarked,
              selectedChoices: response.data.selectedChoices,
              correctChoices: response.data.correctChoices,
              buttonText: 'Show Explanation',
              snackBarGreen: greenStatus,
              snackBarRed: redStatus,
              explanation: response.data.explanation,
              video: response.data.video,
              choiceAnswers: response.data.choiceAnswers,
              twoPartAnswers: response.data.twoPartAnswers,
            });
          });
        }
      });
    } else this.setState({ isLoading: false });
  };

  getChoices = () => {
    if (this.state.answer === '') {
      if (this.state.selectedChoices.length > 0)
        return this.state.selectedChoices;
      else if (this.state.activeChoice.length > 0)
        return this.state.activeChoice;
      else if (this.state.bundleSelect.length > 0)
        return this.state.bundleSelect.map(item => item.id);
      else return [];
    } else return [];
  };

  onSelect = choice => {
    this.setState({
      ...this.state,
      activeChoice: [choice.id],
    });
  };

  onMultiSelect = event => {
    const { selectedChoices } = this.state;
    const { id, checked } = event.target;
    let arr = selectedChoices;
    if (checked) arr.push(id);
    else arr = arr.filter(item => item !== id);
    this.setState({
      selectedChoices: arr,
    });
  };

  onBundleChange = (item, choice) => {
    const { bundleSelect } = this.state;
    var arr = [];
    if (bundleSelect.some(exist => exist.bundleNo === item.bundleNo)) {
      if (
        bundleSelect.some(
          exist => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = bundleSelect.filter(
          exist => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = bundleSelect
          .filter(exist => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = bundleSelect.concat({ ...item, ...choice });
    }
    this.setState({ bundleSelect: arr });
  };

  handleDropDown = (e, index, item) => {
    console.log(index, item, e.target.value);
    const getname = item.multiChoices.filter(
      itemfilter => itemfilter.id === e.target.value
    );

    let selectedChoicetext = [...this.state.bundleSelectedChoice];
    let getdropDownIndex = selectedChoicetext.findIndex(
      dropwdown => dropwdown.id === this.state.data.choices[index].id
    );
    if (getdropDownIndex !== -1) {
      selectedChoicetext[getdropDownIndex] = {
        id: item.id,
        dropDownValue: getname[0].text,
        value: e.target.value,
      };
    } else {
      selectedChoicetext.push({
        id: item.id,
        dropDownValue: getname[0].text,
        value: e.target.value,
      });
    }
    this.setState({ bundleSelectedChoice: selectedChoicetext });
  };

  handleChangeBinary = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.state.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    const existingChoiceIndex = newBundleSelectedChoice.findIndex(
      choice => choice.id === selectedId
    );

    if (existingChoiceIndex !== -1) {
      newBundleSelectedChoice[existingChoiceIndex] = {
        selectedValue: selectedValue,
        id: selectedId,
        correctChoice: selectedValue === 'true' ? selectedValue : 'false',
        wrongChoice:
          selectedValue === 'true'
            ? 'false'
            : selectedValue === 'false'
            ? 'true'
            : selectedValue,
      };
    } else {
      newBundleSelectedChoice.push({
        selectedValue: selectedValue,
        id: selectedId,
        correctChoice: selectedValue === 'true' ? selectedValue : 'false',
        wrongChoice:
          selectedValue === 'true'
            ? 'false'
            : selectedValue === 'false'
            ? 'true'
            : selectedValue,
      });
    }

    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };

  handleChangeTwo = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.state.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    newBundleSelectedChoice[0] = {
      id: selectedId,
      correctChoice: selectedValue === 'true' ? selectedValue : 'false',
    };
    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };
  handleChangeTwo1 = (event, index) => {
    const selectedValue = event.target.value;
    const selectedId = this.state.data.choices[index].id;
    const newBundleSelectedChoice = [...this.state.bundleSelectedChoice];
    newBundleSelectedChoice[1] = {
      id: selectedId,
      wrongChoice: selectedValue,
    };
    this.setState({ bundleSelectedChoice: newBundleSelectedChoice });
  };

  renderQuestion = (
    data,
    selectedChoices,
    correctChoices,
    choiceAnswers,
    twoPartAnswers
  ) => {
    const {
      question,
      type,
      choices,
      description,
      answer,
      imgURL,
      isHaveDescription,
      isHaveImage,
      unit,
      optionalType,
      answer1,
      firstSelectedName,
      multiDescription,
      secondSelectedName,
    } = data;

    if (type === 'SINGLE_SELECT') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          question={question}
          choices={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          activeChoice={this.state.activeChoice}
          description={description}
          imgUrl={imgURL}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          activeChoice={this.state.activeChoice}
        />
      );
    } else if (type === 'SINGLE_SELECT_PASSAGE') {
      return (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          activeChoice={this.state.activeChoice}
        />
      );
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          subjective={true}
          answer={answer}
          onChange={value => this.setState({ answer: value })}
          onlyShow={this.state.buttonText === 'Show Explanation'}
          optionalType={optionalType}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'FRACTION') {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          fraction={true}
          onChange={value => this.setState({ answer: value })}
          onChangeCapture={value => this.setState({ answer1: value })}
          fraction1={this.state.answer}
          fraction2={this.state.answer1}
          imgUrl={imgURL}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'BUNDLE') {
      return (
        <Passage
          para={question}
          description={description}
          bundle={true}
          onChange={this.onBundleChange}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map(item => item.bundleNo)
          )}
          selectedChoice={
            this.state.buttonText === 'Show Explanation'
              ? selectedChoices
                ? selectedChoices
                : []
              : this.state.bundleSelect
          }
          correctChoice={correctChoices ? correctChoices : []}
          onlyShow={this.state.buttonText === 'Show Explanation'}
          activeChoice={this.state.activeChoice}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'SINGLE_SELECT_IMAGE') {
      return (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={
            selectedChoices ? selectedChoices.map(item => item.id) : []
          }
          onSelect={this.onSelect}
          choiceImage={true}
          correctChoice={
            correctChoices ? correctChoices.map(item => item.id) : []
          }
          activeChoice={this.state.activeChoice}
        />
      );
    } else if (type === 'MULTI_CHOICE') {
      return (
        <>
          {this.state.buttonText === 'Show Explanation' ? (
            // Displaying Answer
            <>
              {isHaveDescription || isHaveImage ? (
                <PassageAnswer
                  description={description}
                  question={question}
                  choices={choices}
                  selectedChoice={
                    selectedChoices ? selectedChoices.map(item => item.id) : []
                  }
                  onSelect={this.onSelect}
                  correctChoice={
                    correctChoices ? correctChoices.map(item => item.id) : []
                  }
                  imgUrl={imgURL}
                  isMulti={true}
                />
              ) : (
                <SingleSelectAnswer
                  question={question}
                  options={choices}
                  selectedChoice={
                    selectedChoices ? selectedChoices.map(item => item.id) : []
                  }
                  onSelect={this.onSelect}
                  correctChoice={
                    correctChoices ? correctChoices.map(item => item.id) : []
                  }
                  isMulti={true}
                />
              )}
            </>
          ) : (
            <>
              {isHaveDescription || isHaveImage ? (
                <PassageQuestion
                  description={description}
                  question={question}
                  choices={choices}
                  imgUrl={imgURL}
                  isMulti={true}
                  selectedChoice={selectedChoices ? selectedChoices : []}
                  onSelect={this.onMultiSelect}
                />
              ) : (
                <SingleSelectQuestion
                  question={question}
                  options={choices}
                  selectedChoice={selectedChoices ? selectedChoices : []}
                  onSelect={this.onMultiSelect}
                  description={description}
                  imgUrl={imgURL}
                  isMulti={true}
                />
              )}
            </>
          )}
        </>
      );
    } else if (type === 'DROP_DOWN') {
      return (
        <DropDown
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
          handleDropDown={this.handleDropDown}
          bundleSelectedChoice={this.state.bundleSelectedChoice}
        />
      );
    } else if (type === 'BINARY') {
      return (
        <Binary
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
          handleChangeBinary={this.handleChangeBinary}
          bundleSelectedChoice={this.state.bundleSelectedChoice}
        />
      );
    } else if (type === 'TWO_PART') {
      return (
        <TwoPart
          question={question}
          options={choices}
          twoPartAnswers={twoPartAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
          data={data}
          firstSelectedName={firstSelectedName}
          secondSelectedName={secondSelectedName}
          handleChangeTwo={this.handleChangeTwo}
          handleChangeTwo1={this.handleChangeTwo1}
          bundleSelectedChoice={this.state.bundleSelectedChoice}
        />
      );
    } else if (type === 'MULTI_TAB') {
      return (
        <MultiTab
          question={question}
          options={choices}
          choiceAnswers={choiceAnswers}
          isHaveDescription={isHaveDescription}
          description={description}
          imgUrl={imgURL}
          multiDescription={multiDescription}
          handleChangeBinary={this.handleChangeBinary}
          bundleSelectedChoice={this.state.bundleSelectedChoice}
        />
      );
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackBarRed: false, snackBarGreen: false });
  };

  handleBookmarkClick = () => {
    const { bookMarked } = this.state;
    if (bookMarked) this.setState({ dialogOpen: true });
    else this.handleButton2Click();
  };

  removeDemoBookmarkFunction = () => {
    this.props.removeDemoBookmark(res => {
      if (res.success) {
        localStorage.setItem('demoBookmark', true);
        this.setState({
          demoBookmark: false,
        });
        clearTimeout(bookmarkTimeout);
      }
    });
  };

  handleButton1Click = () => {
    this.setState({ dialogOpen: false });
  };

  handleButton2Click = () => {
    const { question_Id, demoBookmark } = this.state;
    var bookmarkData = { id: question_Id, type: 'question' };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        if (demoBookmark) {
          this.removeDemoBookmarkFunction();
        }
        this.setState({
          bookMarked: response.data.isBookmarked,
          dialogOpen: false,
        });
      }
    });
  };

  disabled = () => {
    const { testType: type } = this.state;

    const filteredArray = this.state.bundleSelectedChoice.filter(
      item => item !== null
    );
    if (this.state.isLoading) {
      return true;
    } else if (
      type === 'SINGLE_SELECT' ||
      type === 'SINGLE_SELECT_PASSAGE' ||
      type === 'SINGLE_SELECT_IMAGE'
    ) {
      return this.state.activeChoice.length === 0;
    } else if (type === 'MULTI_CHOICE') {
      return this.state.selectedChoices.length === 0;
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return this.state.answer.trim().length === 0;
    } else if (type === 'FRACTION') {
      return (
        this.state.answer.trim().length === 0 ||
        this.state.answer1.trim().length === 0
      );
    } else if (type === 'BUNDLE') {
      return this.state.bundleSize !== this.state.bundleSelect.length;
    } else if (
      type === 'DROP_DOWN' ||
      type === 'BINARY' ||
      type === 'MULTI_TAB'
    ) {
      return (
        this.state.bundleSelectedChoice.length < this.state.data.choices.length
      );
    } else if (type === 'TWO_PART') {
      return filteredArray.length < 2;
    }
    return true;
  };

  getVideoObj = vdoObj => {
    this.videosObj = vdoObj;
  };

  renderVideo = () => {
    const { video } = this.state;
    return video && video.length !== 0 ? (
      <>
        <Carousel>
          {video.map((item, index) => (
            <VideoPlayerComponent
              key={index}
              videoMillSec={0}
              videoOtp={item.videoOtp}
              videoPlaybackInfo={item.videoPlaybackInfo}
              getVideoObj={this.getVideoObj}
              isSlider={true}
            />
          ))}
        </Carousel>
      </>
    ) : null;
  };

  handleBack = () => {
    const { isBookmarked } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (isBookmarked === 'true')
      this.props.history.push(`${routePaths.dashboard.bookmarks}`);
    else this.props.history.push(`${routePaths.dashboard.mistakeLogs}`);
  };

  render() {
    const { index } = QueryString.parse(this.props.location.search, QS_PROPS);

    const {
      data,
      selectedChoices,
      correctChoices,
      explanation,
      showExplanation,
      demoBookmark,
      bookMarked,
      dialogOpen,
      video,
      choiceAnswers,
      twoPartAnswers,
    } = this.state;

    const {
      handleButton1Click,
      handleButton2Click,
      handleBookmarkClick,
      renderVideo,
    } = this;

    console.log(this.state.bundleSelectedChoice, 'bundleselected');

    const { t } = this.props;
    if (data) {
      const { conceptName } = this.state.data;
      return (
        <Container>
          {demoBookmark && (
            <BookmarkPosition1>
              <RenderBookMark
                bookMarked={bookMarked}
                onClick={handleBookmarkClick}
                demoBookmark={demoBookmark}
              />
            </BookmarkPosition1>
          )}
          <Main>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              style={{
                marginTop: '1em',
                // height: '78vh'
              }}
            >
              <Grid item xs={12} className={'center row'}>
                <IconButtonDiv
                  onClick={this.handleBack}
                  //   this.props.history.push(`${routePaths.dashboard.bookmarks}`)
                  // }
                >
                  {/* <div>asfsjfsfj</div> */}
                  <BackIcon />
                </IconButtonDiv>
                <TestTitle>
                  {t('Question')} {index}
                </TestTitle>
                {!demoBookmark && (
                  <BookmarkPosition>
                    <RenderBookMark
                      bookMarked={bookMarked}
                      onClick={this.handleBookmarkClick}
                    />
                  </BookmarkPosition>
                )}
              </Grid>
              <QuestionTitle id="topic-title">{conceptName}</QuestionTitle>
              <Grid
                id="question-container"
                item
                xs={12}
                style={{ overflow: 'auto', height: '63vh' }}
              >
                {this.renderQuestion(
                  data,
                  selectedChoices,
                  correctChoices,
                  choiceAnswers,
                  twoPartAnswers
                )}
              </Grid>
            </Grid>
          </Main>
          <Grid
            container
            direction="row"
            justifyContent={showExplanation ? 'flex-start' : 'flex-end'}
          >
            <Grid item xs={12}>
              <Divider
                orientation="horizontal"
                id="question-divider"
                flexItem
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex' }}
              justifyContent={'space-between'}
            >
              {showExplanation &&
              ((explanation && explanation.trim().length !== 0) ||
                (video && video.length !== 0)) ? (
                <ExplanationContainer>
                  <ExplanationTitle>{t('Explanatory Answer')}</ExplanationTitle>
                  <Explanation>
                    <Latex math={explanation || ''} />
                  </Explanation>
                  {video && video.length !== 0 && (
                    <Box
                      p={'1rem 5.5rem 2rem 5.5rem'}
                      className={'explain_video'}
                    >
                      {renderVideo()}
                    </Box>
                  )}
                </ExplanationContainer>
              ) : (
                <>
                  <div />
                  <ShowButton
                    loading={this.state.isLoading}
                    onClick={this.handleAnswer}
                    disabled={this.disabled()}
                  >
                    {t(this.state.buttonText)}
                  </ShowButton>
                </>
              )}
            </Grid>
          </Grid>

          {/* Toast */}
          <Snackbar open={this.state.snackBarGreen} onClose={this.handleClose}>
            <GreenAlert
              icon={
                <img
                  className={'toast-icon-style'}
                  src={RightIcon}
                  alt="Right Icon"
                />
              }
              severity="success"
            >
              {t('You are Correct')}
            </GreenAlert>
          </Snackbar>
          <Snackbar open={this.state.snackBarRed} onClose={this.handleClose}>
            <RedAlert
              icon={
                <img
                  className={'toast-icon-style'}
                  src={WrongIcon}
                  alt="Wrong Icon"
                />
              }
            >
              {t('You are wrong')}
            </RedAlert>
          </Snackbar>
          <DialogComponent
            open={dialogOpen}
            dialogContent={{
              icon: <img src={BookmarkIcon} alt="" />,
              title: t(`Are you sure you want remove bookmark?`),
              button1: t('Cancel'),
              button2: t('Yes'),
            }}
            handleButton1Click={handleButton1Click}
            handleButton2Click={handleButton2Click}
          />
        </Container>
      );
    } else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    singleQuestion: state.DashBoardReducer.singleQuestion,
    questionAnswer: state.DashBoardReducer.questionAnswer,
    answerExplanation: state.DashBoardReducer.answerExplanation,
  };
};
export default connect(mapStateToProps, {
  getOneQuestion,
  getQuestionAnswer,
  getAnswerExplanation,
  AddBookmarks,
  removeDemoBookmark,
})(withTranslation()(Question));
