/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  default as Close,
  default as CloseIcon,
} from '@material-ui/icons/Close';
import React from 'react';
import {
  CancelButton,
  MuiButton,
  MuiMenu,
  MuiMenuItem,
  NoteSaveButton,
} from '../../../assets/css/MuiStyles';
import {
  AddNotesBox,
  BoxArrow,
  CommentBox,
  CommentContent,
  CommentDate,
  CommentFlex,
  CommentTitle,
  FlexFiller,
  Header,
  HeadText,
  HighlightTextNoCount,
  HightLightTaskContainer,
  LeftLineBorder,
  NoteContainer,
  NumberOfNotes,
  TextBox,
} from '../../../assets/css/StyledComponent';
import comment from '../../../assets/icons/commentIcon.svg';
import DownArrow from '../../../assets/icons/downArrow.svg';
import Expand from '../../../assets/icons/expand.svg';
import Arrow from '../../../assets/icons/polygon.svg';
import UpArrow from '../../../assets/icons/upArrow.svg';
import ThreeDot from '../../../assets/icons/VerticalThreeDot.svg';

const MONTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const Notes = props => {
  const {
    noteData,
    menu,
    onCloseClick,
    commentBoxOpen,
    handleMenu,
    handleMenuClose,
    anchorEl,
    handleMenuItem,
    handleChange,
    editableId,
    error,
    handleSave,
    t,
  } = props;

  const getDateFormat = dateString => {
    let date = new Date(dateString);
    let day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    let month = MONTH[date.getMonth()];
    return strTime + ' ' + month + ' ' + day;
  };

  return (
    <NoteContainer style={{ display: !commentBoxOpen && 'none' }}>
      <Header>
        <IconButton disabled>
          <img src={comment} alt="Comment Icon" />
        </IconButton>
        <HeadText style={{ marginLeft: '0px' }}>{t('All notes')}</HeadText>
        <FlexFiller />
        <IconButton onClick={() => handleMenuItem('resize')}>
          <img src={Expand} alt="Expand Icon" />
        </IconButton>
        <IconButton onClick={onCloseClick}>
          <CloseIcon className={'icon-styles'} />
        </IconButton>
      </Header>
      <NumberOfNotes>
        {noteData.length} {t('notes')}
      </NumberOfNotes>
      <div style={{ overflowY: 'auto' }}>
        {noteData.length !== 0 &&
          noteData.map((item, index) => {
            return (
              <CommentBox>
                <CommentFlex>
                  <CommentDate>{getDateFormat(item.date)}</CommentDate>
                  <IconButton onClick={e => handleMenu(item.id, e)}>
                    <img src={ThreeDot} alt="" />
                  </IconButton>
                  <MuiMenu
                    open={anchorEl}
                    anchorEl={anchorEl}
                    anchorReference={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={handleMenuClose}
                  >
                    {menu.map(option => {
                      return (
                        <MuiMenuItem
                          onClick={() => handleMenuItem(option.name)}
                        >
                          <Typography>{option.text}</Typography>
                        </MuiMenuItem>
                      );
                    })}
                  </MuiMenu>
                </CommentFlex>
                <div style={{ position: 'relative' }}>
                  <CommentTitle>{item.data}</CommentTitle>
                  <LeftLineBorder />
                </div>
                <CommentContent>
                  {editableId === item.id ? (
                    <div>
                      <TextField
                        shrink={true}
                        inputProps={{
                          style: { color: '#052a4e' },
                        }}
                        key={item.id}
                        multiline
                        label={t('Edit Text')}
                        defaultValue={item.notes}
                        placeholder={t('Edit Text')}
                        error={error}
                        variant="outlined"
                        onChange={e => handleChange(index, e)}
                        fullWidth
                        helperText={(error && 'Invalid') || ''}
                      />
                      <MuiButton onClick={() => handleSave(index)}>
                        {t('Save')}
                      </MuiButton>
                    </div>
                  ) : (
                    item.notes
                  )}
                </CommentContent>
              </CommentBox>
            );
          })}
      </div>
    </NoteContainer>
  );
};

export const RenderAddNotePopup = ({
  notes,
  handleTextChange,
  handleMenuItem,
  right,
  top,
}) => {
  return (
    <ClickAwayListener onClickAway={() => handleMenuItem('cancel')}>
      <AddNotesBox top={top} right={right}>
        <TextBox>
          <TextField
            value={notes}
            onChange={handleTextChange}
            title="Add Notes"
            placeholder="Add Notes"
            multiline
            maxRows={5}
            inputProps={{
              style: {
                fontSize: '16px',
                color: '#052A4E',
                padding: '2px 3px',
              },
            }}
            fullWidth
          />
        </TextBox>
        <Grid container justifyContent={'space-between'}>
          <NoteSaveButton
            disabled={notes.trim().length === 0}
            onClick={() => handleMenuItem('notesSave')}
          >
            Save Notes
          </NoteSaveButton>
          <CancelButton
            variant={'outlined'}
            onClick={() => handleMenuItem('cancel')}
          >
            Cancel
          </CancelButton>
        </Grid>
        <BoxArrow src={Arrow} />
      </AddNotesBox>
    </ClickAwayListener>
  );
};

export const RenderHighlightedTask = ({
  start,
  end,
  noteData,
  selectedNoteId,
  popoverX,
  popoverTask,
  handlePopoverTaskClose,
  handleArrowClick,
}) => {
  const selectedTask = noteData.find(el => el.id === selectedNoteId);
  const task = selectedTask ? selectedTask.notes.split(' ') : '';
  return (
    <HightLightTaskContainer
      className={'popover-tasks'}
      id={'popover-task'}
      top={popoverX}
      visible={popoverTask}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box flex={1} display={'flex'} gridGap={10} alignItems={'center'}>
          <IconButton
            onClick={start > 0 && (() => handleArrowClick(start - 38, start))}
          >
            <img src={UpArrow} alt="" />
          </IconButton>
          <IconButton
            onClick={
              end < task.length && (() => handleArrowClick(end, end + 38))
            }
          >
            <img src={DownArrow} alt="" />
          </IconButton>
          <HighlightTextNoCount>
            {`${Math.round(start / 38) + 1} of ${Math.round(task.length / 38)}`}
          </HighlightTextNoCount>
        </Box>
        <Box>
          <IconButton onClick={handlePopoverTaskClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box>{task.length > 0 && task.slice(start, end).join(' ')}</Box>
    </HightLightTaskContainer>
  );
};
