/**
 *  Important Notes:
 * ******************
 * 1) After created a zoom account, We just disable all the custom questions in the Zoom Marketplace settings.
 * Then only we can able to join in the meeting.
 * 2) Here We used SDK key & SDK secret key of Zoom - 1 account.
 * 3) Signature was created using API call that was configured in server.js file.
 **/

import KJUR from 'jsrsasign';
import { ZoomMtg } from '@zoomus/websdk';
import '@zoomus/websdk/dist/css/bootstrap.css';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
const sdkSecret = process.env.REACT_APP_ZOOM_SDK_SECRET_KEY;
const leaveUrl = `${process.env.REACT_APP_ZOOM_LEAVE_URL}${window.location.pathname}`;

function getSignature(userName, userEmail, meetingNumber, registrantToken) {
  const iat = Math.round(new Date().getTime() / 1000) - 30;
  const exp = iat + 60 * 60 * 2;

  const oHeader = { alg: 'HS256', typ: 'JWT' };

  const oPayload = {
    sdkKey: sdkKey,
    mn: meetingNumber,
    role: 0,
    iat: iat,
    exp: exp,
    appKey: sdkSecret,
    tokenExp: iat + 60 * 60 * 2,
  };

  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(oPayload);
  const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret);

  startMeeting(signature, userName, userEmail, meetingNumber, registrantToken);
}

function startMeeting(
  signature,
  userName,
  userEmail,
  meetingNumber,
  registrantToken
) {
  document.getElementById('zmmtg-root').style.display = 'block';

  ZoomMtg.init({
    leaveUrl: leaveUrl,
    success: success => {
      ZoomMtg.showRecordFunction({
        show: true,
      });

      ZoomMtg.join({
        signature: signature,
        meetingNumber: meetingNumber,
        userName: userName,
        sdkKey: sdkKey,
        userEmail: userEmail,
        tk: registrantToken,
        success: success => {
          console.log(success);
        },
        error: error => {
          console.log(error);
        },
      });
    },
  });
}

export default getSignature;
