import { Container, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { questionBankTheme } from '../../../../assets/css/MuiStyles';
import Topics from './Topics';
import {
  getAllTopics,
  getTopicDetail,
} from '../../../../redux/action/Dashboard';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import QueryString from 'qs';
import { QS_PROPS } from '../../../../constant/Variables';
import { routePaths } from '../../../../routes/RoutePath';
import BreadCrumbs from '../../../../utils/BreadCrumbs';
import DailogComponent from '../../../practice/topicTest/DialogComponent';

class TopicTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: null,
      open: false,
      questionId: null,
    };
  }

  handleButtonClick = (topicIds, allowHard) => {
    const { topicId, subject, type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (allowHard === true) {
      this.setState({
        questionId: topicIds,
      });
      if (subject === 'subject') {
        this.props.history.push(
          `${routePaths.dashboard.instruction}?testQuestionId=${topicIds}&topicId=${topicId}&subject=subject&type=${type}`
        );
      } else {
        window.localStorage.setItem('testPage', 'topicTest');
        this.props.history.push(
          `${routePaths.dashboard.instruction}?testQuestionId=${topicIds}`
        );
      }
    } else {
      this.setState({
        open: true,
        questionId: topicIds,
      });
    }
  };

  handlePopUpClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleButtonClick1 = () => {
    // const topicId = this.state.topics[2].id;
    const id = this.state.questionId;
    const { topicId, subject, type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (subject === 'subject') {
      this.props.history.push(
        `${routePaths.dashboard.instruction}?testQuestionId=${id}&topicId=${topicId}&subject=subject&type=${type}`
      );
    } else {
      window.localStorage.setItem('testPage', 'topicTest');
      this.props.history.push(
        `${routePaths.dashboard.instruction}?testQuestionId=${id}`
      );
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { topicId, type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.props.getAllTopics(topicId, response => {
      console.log(response.data.allowHard);
      this.setState({
        topics: response.data,
      });
    });

    this.props.getTopicDetail(topicId, '', '', type, response => {
      this.setState({ breadCrumbData: response });
    });
  }
  handleReportClick = testExecutionId => {
    let userProduct = JSON.parse(localStorage.getItem('userProduct'));
    userProduct.testExecutionId = testExecutionId;
    localStorage.setItem('userProduct', JSON.stringify(userProduct));

    window.localStorage.setItem('testPage', 'topicTest');
    this.props.history.push(
      `${routePaths.dashboard.report
      }?testExecutionId=${testExecutionId}&isRetaken=${true}`
    );
  };
  render() {
    const { t } = this.props;
    const { topicId, boost } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    return (
      <>
        <ThemeProvider theme={questionBankTheme}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <BreadCrumbs
                  topics={this.state.breadCrumbData}
                  isQuestionBank={true}
                  goBack={() =>
                    this.props.history.push(
                      `${routePaths.dashboard.taskDetail}${localStorage.getItem(
                        'URLPath'
                      )}`
                    )
                  }
                  backEvent={!boost}
                />
              </Grid>
              <Grid item xs={12}>
                <Topics
                  item={this.state.topics}
                  t={t}
                  handleButtonClick={this.handleButtonClick}
                  handleReportClick={this.handleReportClick}
                />
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
        <DailogComponent
          open={this.state.open}
          handlePopUpClose={this.handlePopUpClose}
          handleButtonClick1={this.handleButtonClick1}
          item={this.state.topics}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    topicsResponse: state.DashBoardReducer.allTopics,
    topics: state.dashboardReducer.topic,
  };
};
export default connect(mapStateToProps, {
  getAllTopics,
  getTopicDetail,
})(withTranslation()(TopicTest));
