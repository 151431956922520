/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, ThemeProvider, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../assets/css/App.css';
import {
  BodyText,
  CourseDiv,
  CourseInner,
  FooterNumber,
  FooterText,
  HeadText,
  IntroText,
} from '../../assets/css/CourseComponent';
import {
  CardBelowContent,
  CardShadow,
  CardView,
  DisabledBlockedBox,
  DisabledOrBlocked,
  EmptyCourseImg,
  HeaderWrap,
  SubDescription,
  SupportLink,
  TopHeading,
  TopSubTitle,
} from '../../assets/css/StyledComponent';
import { submitButtonTheme } from '../../assets/css/Styles';
import EmptyCourse from '../../assets/icons/emptyCourse.svg';
import NewIcon from '../../assets/icons/new.svg';
import { IMAGE_URL, MONTHS } from '../../constant/Variables';
import { getCourseImage } from '../../redux/action/Course';
import { getCourses } from '../../redux/action/Profile';
import { personalizedStudyPlan } from '../../redux/action/Test';
import { routePaths } from '../../routes/RoutePath';
import Button from '../../utils/components/Button';
import PageLoader from '../../utils/components/PageLoader';

const PRODUCT_SCORE = [
  {
    title: 'Mock Tests',
    score: '10',
  },
  {
    title: 'Concept Booster',
    score: '280+',
  },
  {
    title: 'Questions',
    score: '1600+',
  },
  {
    title: 'Topic Tests',
    score: '120+',
  },
];
const COMMON_SCORE = [
  {
    title: 'Mock Tests',
    score: '8',
  },
  {
    title: 'Concept Booster',
    score: '90',
  },
  {
    title: 'Practice Questions',
    score: '3500',
  },
  {
    title: 'Customized Quizzes',
    score: '100',
  },
];
class AllCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      images: [],
      img: '',
      //  mockTest: 10,
      //  conceptBooster: 280,
      //  practiceQues: 1600,
      //  customizedQues: 120,

      progress1: 0,
      progress2: 0,
      validityText1: '',
      validityText2: '',
      isLoading: false,
      allCourseBlockedOrDisabled: [],
    };
  }

  componentDidMount() {
    this.props.getCourses(response => {
      var arr = [];

      response.data.map(item => {
        console.log(item);
        if (
          (item.isProductActive && item.isProductExpired) ||
          !item.isProductActive ||
          item.isCoursePaused
        ) {
          arr.push(item);
          this.setState({ allCourseBlockedOrDisabled: arr });
        }
      });

      this.setState({
        data: response.data,
      });
    });
  }

  parseDate(date) {
    var arr = date.split(' ');
    return arr[2] + ' ' + arr[1] + ' ' + arr[3];
  }

  handleClick = item => {
    localStorage.setItem('userProduct', JSON.stringify(item));

    this.setState({ isLoading: true });
    if (item) {
      if (item.isNoCalibration && !item.isStudyPlanCreated) {
        this.props.history.push(routePaths.examScheduled);
      } else if (!item.isNoCalibration && !item.isStudyPlanCreated) {
        this.setState({ isLoading: true });
        if (item.productName === 'GMAT' || item.productName === 'GMAT FOCUS') {
          this.props.history.push(
            `${routePaths.gmat.instruction}?testQuestionSetId=${
              item ? item.testQuestionSetId : null
            }&resume=true`
          );
        } else if (item.productName === 'GRE') {
          this.props.history.push(
            `${routePaths.gre.instruction}?testQuestionSetId=${
              item ? item.testQuestionSetId : ''
            }&resume=true`
          );
        } else
          this.props.history.push(
            routePaths.instruction +
              `?questionSet=${item ? item.testQuestionSetId : null}&resume=true`
          );
      }
    } else {
      this.setState({ isLoading: true });
    }
  };

  handleContact = () => {
    this.props.history.push(routePaths.contactUs);
  };

  renderContent = () => {
    const { t } = this.props;

    if (this.state.data.length === 0)
      return (
        <Fragment>
          <Grid item xs={12}>
            <TopHeading>{t('Welcome to LMS')}</TopHeading>
          </Grid>
          <Grid item xs={12}>
            <TopSubTitle>{t('You got Access for LMS')}</TopSubTitle>
          </Grid>
          <Grid item xs={12}>
            <SubDescription>
              {t('(You don’t have any product for now)')}
            </SubDescription>
          </Grid>
          <Grid item xs={12}>
            <EmptyCourseImg src={EmptyCourse} />
          </Grid>
        </Fragment>
      );
    else if (
      this.state.data.length === 1 &&
      this.state.data[0].isProductActive &&
      !this.state.data[0].isProductExpired &&
      !this.state.data[0].isCoursePaused
    ) {
      const [product] = this.state.data;
      return (
        <Fragment>
          <Grid xs={1} />
          <Grid
            item
            xs={12}
            md={4}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img
              src={IMAGE_URL + product.images.productImage}
              alt=""
              className="single-course-image"
            />

            <BodyText>
              {t(product.subTitle)}
              {` ${new Date(product.expiryDate).getDate()} ${
                MONTHS[new Date(product.expiryDate).getMonth()]
              } ${new Date(product.expiryDate).getFullYear()}`}
            </BodyText>
          </Grid>
          <Grid item xs={1} md={1} />
          {/* Right Side */}

          <Grid
            item
            xs={8}
            md={6}
            container
            direction="column"
            justify="center"
          >
            <Box paddingTop={1} />
            <IntroText>{t('Start your Journey to success with us')}</IntroText>
            <HeadText>{t('Get the Personalized Learning Experience')}</HeadText>
            <CourseDiv>
              {(product.isProductActive && product.isProductExpired) ||
              product.isCoursePaused ||
              !product.isProductActive ? null : (!product.isNoCalibration &&
                  !product.isStudyPlanCreated &&
                  !product.isCalibrationCompleted) ||
                (product.isNoCalibration && !product.isStudyPlanCreated) ? (
                <CourseInner>
                  <ThemeProvider theme={submitButtonTheme}>
                    <Box paddingTop={4}>
                      <Button
                        variant={'contained'}
                        onClick={() => {
                          this.handleClick(product);
                        }}
                      >
                        {!product.isNoCalibration && !product.isStudyPlanCreated
                          ? t('Continue')
                          : t('Get Started')}
                      </Button>
                    </Box>
                  </ThemeProvider>
                </CourseInner>
              ) : null}

              {(product.isProductActive && product.isProductExpired) ||
              product.isCoursePaused ||
              !product.isProductActive ? null : (product.isNoCalibration &&
                  product.isStudyPlanCreated) ||
                (!product.isNoCalibration && product.isStudyPlanCreated) ||
                product.isCalibrationCompleted ? (
                <Box display={'flex'} gridGap={10}>
                  {((!product.isNoCalibration && product.isStudyPlanCreated) ||
                    product.isCalibrationCompleted) && (
                    <ThemeProvider theme={submitButtonTheme}>
                      <Box paddingTop={4}>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            localStorage.setItem(
                              'userProduct',
                              JSON.stringify(product)
                            );
                            this.props.history.push(
                              routePaths.report + '?page=insights'
                            );
                          }}
                        >
                          {t('Go to Report')}
                        </Button>
                      </Box>
                    </ThemeProvider>
                  )}

                  {(!product.isNoCalibration && product.isStudyPlanCreated) ||
                  (product.isNoCalibration && product.isStudyPlanCreated) ||
                  product.isCalibrationCompleted ? (
                    <ThemeProvider theme={submitButtonTheme}>
                      <Box paddingTop={4}>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            localStorage.setItem(
                              'userProduct',
                              JSON.stringify(product)
                            );
                            this.props.personalizedStudyPlan(
                              product.id,
                              res => {
                                this.props.history.push(
                                  routePaths.dashboard.home
                                );
                              }
                            );
                          }}
                          loading={this.state.isLoading}
                        >
                          {t('Go to Dashboard')}
                        </Button>
                      </Box>
                    </ThemeProvider>
                  ) : null}
                </Box>
              ) : null}
            </CourseDiv>
          </Grid>
          <Grid item xs={1} md={1} />
        </Fragment>
      );
    } else
      return (
        <Fragment>
          <Grid item xs={12}>
            <TopHeading>
              <HeaderWrap>
                {t('Start your Journey to success with us')}
              </HeaderWrap>
            </TopHeading>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}></Box>
          </Grid>

          {this.state.data.map(item => {
            return (
              <Grid item xs={12} sm={6} className="self__align">
                <CardView>
                  <CardShadow
                    className={'card_bottom_align'}
                    minHeight={'322px'}
                    style={{ position: 'relative' }}
                  >
                    {!item.isProductActive ||
                    item.isProductExpired ||
                    item.isCoursePaused ? (
                      <>
                        <DisabledBlockedBox />
                        <DisabledOrBlocked
                          blocked={
                            item.isProductActive && item.isCoursePaused
                              ? false
                              : item.isProductActive
                          }
                        />
                      </>
                    ) : (
                      item.isNoCalibration &&
                      !item.isStudyPlanCreated && (
                        <Box position={'absolute'} left={20} top={15}>
                          <img src={NewIcon} alt={''} />
                        </Box>
                      )
                    )}

                    <div className={'card_grid_align'}>
                      <img
                        src={`${IMAGE_URL}${item.images.productImage}`}
                        alt={''}
                      />
                      {(item.isProductActive && item.isProductExpired) ||
                      item.isCoursePaused ||
                      !item.isProductActive ? null : (!item.isNoCalibration &&
                          !item.isStudyPlanCreated &&
                          !item.isCalibrationCompleted) ||
                        (item.isNoCalibration && !item.isStudyPlanCreated) ? (
                        <CourseDiv>
                          <ThemeProvider theme={submitButtonTheme}>
                            <Box paddingTop={4}>
                              <Button
                                variant={'contained'}
                                onClick={() => {
                                  this.handleClick(item);
                                }}
                              >
                                {item &&
                                !item.isNoCalibration &&
                                !item.isStudyPlanCreated
                                  ? t('Continue')
                                  : t('Get Started')}
                              </Button>
                            </Box>
                          </ThemeProvider>
                        </CourseDiv>
                      ) : null}

                      {(item.isProductActive && item.isProductExpired) ||
                      item.isCoursePaused ||
                      !item.isProductActive ? null : (item.isNoCalibration &&
                          item.isStudyPlanCreated) ||
                        (!item.isNoCalibration && item.isStudyPlanCreated) ||
                        item.isCalibrationCompleted ? (
                        <Box display={'flex'} gridGap={10}>
                          {((!item.isNoCalibration &&
                            item.isStudyPlanCreated) ||
                            item.isCalibrationCompleted) && (
                            <ThemeProvider theme={submitButtonTheme}>
                              <Box paddingTop={4}>
                                <Button
                                  variant={'contained'}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'userProduct',
                                      JSON.stringify(item)
                                    );
                                    this.props.history.push(
                                      routePaths.report + '?page=insights'
                                    );
                                  }}
                                >
                                  {t('Go to Report')}
                                </Button>
                              </Box>
                            </ThemeProvider>
                          )}

                          {(!item.isNoCalibration && item.isStudyPlanCreated) ||
                          (item.isNoCalibration && item.isStudyPlanCreated) ||
                          item.isCalibrationCompleted ? (
                            <ThemeProvider theme={submitButtonTheme}>
                              <Box paddingTop={4}>
                                <Button
                                  variant={'contained'}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'userProduct',
                                      JSON.stringify(item)
                                    );
                                    console.log(item);
                                    this.props.personalizedStudyPlan(
                                      item.id,
                                      res => {
                                        this.props.history.push(
                                          routePaths.dashboard.home
                                        );
                                      }
                                    );
                                  }}
                                  loading={this.state.isLoading}
                                >
                                  {t('Go to Dashboard')}
                                </Button>
                              </Box>
                            </ThemeProvider>
                          ) : null}
                        </Box>
                      ) : null}
                    </div>
                  </CardShadow>

                  {(item.isProductActive && item.isProductExpired) ||
                  item.isCoursePaused ||
                  !item.isProductActive ? null : ((!item.isNoCalibration &&
                      !item.isStudyPlanCreated) ||
                      (((!item.isProductActive &&
                        !item.isProductExpired &&
                        !item.isCoursePaused) ||
                        item.isProductActive) &&
                        !item.isNoCalibration &&
                        item.isStudyPlanCreated)) &&
                    item.status !== 0 &&
                    item.status !== null ? (
                    <Box mt={-5}>
                      <Box display="flex" justifyContent="flex-end">
                        <Box minWidth={35} zIndex={2}>
                          <Typography variant="body2" color="textSecondary">
                            {`${Math.round(item.status)}%`}
                          </Typography>
                        </Box>
                      </Box>

                      <Box width="100%">
                        <LinearProgress
                          variant="determinate"
                          value={item.status}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <CardBelowContent>
                    {t(item.subTitle)}
                    {(item.isProductActive && item.isProductExpired) ||
                    item.isCoursePaused ||
                    !item.isProductActive
                      ? null
                      : ` ${new Date(item.expiryDate).getDate()} ${
                          MONTHS[new Date(item.expiryDate).getMonth()]
                        } ${new Date(item.expiryDate).getFullYear()}`}
                  </CardBelowContent>
                </CardView>
              </Grid>
            );
          })}
        </Fragment>
      );
  };

  render() {
    const { t } = this.props;
    const product = PRODUCT_SCORE;
    const common = COMMON_SCORE;
    const GRE_DATA = this.state.data?.find(
      item => item?.productName === 'GRE'
    )?.productName;

    return (
      <div>
        <Box className={'card_outer'}>
          {this.state.data !== null ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ minHeight: '80vh' }}
            >
              {this.renderContent()}

              {this.state.allCourseBlockedOrDisabled.length !==
                this.state.data.length && this.state.data.length > 1 ? (
                <Grid item xs={12}>
                  <Box className={'below_content_align'} pt={3} pb={6}>
                    {t('Please choose the course to continue')}
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}></Grid>
              )}

              {this.state.data.length !== 0 &&
              this.state.allCourseBlockedOrDisabled.length !== 0 ? (
                <Grid item xs={12}>
                  <SupportLink isAlign={this.state.data.length === 0}>
                    {t('Need support?')}{' '}
                    <span onClick={this.handleContact}>{t('Contact us')}</span>
                  </SupportLink>
                </Grid>
              ) : null}
              {GRE_DATA === 'GRE' && this.state.data.length === 1
                ? product?.map(item => {
                    return (
                      <Fragment>
                        <Grid item xs={6} sm={3} md={3}>
                          <Box className={'card_grid_align'} pt={3} pb={3}>
                            <FooterNumber>{item.score}</FooterNumber>
                            <FooterText>{item.title}</FooterText>
                          </Box>
                        </Grid>
                      </Fragment>
                    );
                  })
                : common?.map(items => {
                    return (
                      <Fragment>
                        <Grid item xs={6} sm={3} md={3}>
                          <Box className={'card_grid_align'} pt={3} pb={3}>
                            <FooterNumber>{items.score}+</FooterNumber>
                            <FooterText>{items.title}</FooterText>
                          </Box>
                        </Grid>
                      </Fragment>
                    );
                  })}
            </Grid>
          ) : (
            <PageLoader />
          )}
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    courses: state.profileReducer.courses,
    personalStudyPlanResponse: state.testReducer.personalizedStudyPlans,
  };
};

export default connect(mapStateToProps, {
  getCourses,
  getCourseImage,
  personalizedStudyPlan,
})(withTranslation()(AllCourses));
