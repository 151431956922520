import { Box, FormControlLabel, Checkbox } from '@material-ui/core';
import {
  SingleSelect as Style,
  Div,
} from '../../../../assets/css/test/TestComponent';
import IncorrectIcon from '../../../../assets/icons/incorrect.svg';
import CorrectIcon from '../../../../assets/icons/correct.svg';
import Latex from '../../../../utils/LatexViewer';

const SingleSelect = ({
  question,
  options,
  onSelect,
  selectedChoice,
  choiceImage,
  correctChoice,
  activeChoice,
  isMulti,
}) => {
  const { Question, OptionContainer, OptionDiv, OptionBox, OptionTitle } =
    Style;
  const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

  const renderChoices = () => {
    if (isMulti) {
      return (
        <>
          {options.map((choice, index) => {
            if (choice.choiceImage)
              return (
                <FormControlLabel
                  id={choice.id}
                  control={
                    <Checkbox
                      name={choice.id}
                      id={choice.id}
                      color="primary"
                      checked={selectedChoice.some(
                        selected => selected === choice.id
                      )}
                    />
                  }
                  label={
                    <Box display={'flex'}>
                      <span>{alpha[index]})&nbsp;</span>
                      <Latex math={choice.text} />
                    </Box>
                  }
                  onChange={onSelect}
                  style={{ gridGap: 10, padding: '10px 0px' }}
                />
              );
            else
              return (
                <FormControlLabel
                  id={choice.id}
                  control={
                    <Checkbox
                      name={choice.id}
                      id={choice.id}
                      color="primary"
                      checked={selectedChoice.some(
                        selected => selected === choice.id
                      )}
                    />
                  }
                  label={
                    <Box display={'flex'}>
                      <span>{alpha[index]})&nbsp;</span>
                      <Latex math={choice.text} />
                    </Box>
                  }
                  onChange={onSelect}
                  style={{ gridGap: 10, padding: '10px 0px' }}
                />
              );
          })}
        </>
      );
    } else
      return options.map((choice, index) => {
        if (choice.choiceImage) {
          if (
            correctChoice.indexOf(choice.id) > -1 &&
            selectedChoice.indexOf(choice.id) > -1
          ) {
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={CorrectIcon} alt={''} />
                </OptionBox>
                <img src={choice.choiceImage} alt="" />
              </OptionDiv>
            );
          } else if (selectedChoice.indexOf(choice.id) > -1) {
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={IncorrectIcon} alt={''} />
                </OptionBox>
                <img src={choice.choiceImage} alt="" />
              </OptionDiv>
            );
          }
          if (correctChoice.indexOf(choice.id) > -1)
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={CorrectIcon} alt="" />
                </OptionBox>
                <img src={choice.choiceImage} alt="" />
              </OptionDiv>
            );
          else
            return (
              <OptionDiv
                onClick={() => onSelect(choice)}
                active={
                  activeChoice.indexOf(choice.id) > -1 &&
                  !correctChoice.length > 0
                }
              >
                <OptionBox
                  active={
                    activeChoice.indexOf(choice.id) > -1 &&
                    !correctChoice.length > 0
                  }
                >
                  {alpha[index]}
                </OptionBox>
                {/* <Latex math={choice.text} /> */}

                <img src={choice.choiceImage} alt="" />
              </OptionDiv>
            );
        } else {
          if (
            correctChoice.indexOf(choice.id) > -1 &&
            selectedChoice.indexOf(choice.id) > -1
          ) {
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={CorrectIcon} alt={''} />
                </OptionBox>
                <OptionTitle>
                  <Latex math={choice.text} />
                </OptionTitle>
              </OptionDiv>
            );
          } else if (selectedChoice.indexOf(choice.id) > -1) {
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={IncorrectIcon} alt={''} />
                </OptionBox>
                <OptionTitle>
                  <Latex math={choice.text} />
                </OptionTitle>
              </OptionDiv>
            );
          }
          if (correctChoice.indexOf(choice.id) > -1)
            return (
              <OptionDiv>
                <OptionBox active={selectedChoice.indexOf(choice.id) > -1}>
                  <img src={CorrectIcon} alt="" />
                </OptionBox>
                <OptionTitle>
                  <Latex math={choice.text} />
                </OptionTitle>
              </OptionDiv>
            );
          else
            return (
              <OptionDiv
                active={
                  activeChoice.indexOf(choice.id) > -1 &&
                  !correctChoice.length > 0
                }
                onClick={() => onSelect(choice)}
              >
                <OptionBox
                  active={
                    activeChoice.indexOf(choice.id) > -1 &&
                    !correctChoice.length > 0
                  }
                >
                  {alpha[index]}
                </OptionBox>
                <OptionTitle>
                  <Latex math={choice.text} />
                </OptionTitle>
              </OptionDiv>
            );
        }
      });
  };
  return (
    <Box>
      <Div>
        <Question>
          <Latex math={question} />
        </Question>
        <OptionContainer>{renderChoices()}</OptionContainer>
      </Div>
    </Box>
  );
};

export default SingleSelect;
