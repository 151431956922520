/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  ActionButton,
  CircleView,
  DescriptionText,
  FlexButtonView,
  FlexJustifyView,
  FlexView,
  GreenSubText,
  HeaderCard,
  HeaderIcon,
  HeaderSmallText,
  HeaderTitle,
  HeaderWrapper,
  SubContainerTitle,
  SubContainerWrapper,
} from '../../../assets/css/StyledDashboardComponent.js';
import BoosterIcon from '../../../assets/icons/headerBoosterIcon.svg';
import Steper from './Steper';

export default props => {
  const {
    activeStep,
    handleNext,
    handleSteperClick,
    Type,
    steps,
    data,
    handleTask,
    handleTestClick,
    Icons,
  } = props;
  const isTestvar = data;
  const subsu = props.data;
  console.log(subsu, 'ed');
  console.log(isTestvar, 'sdf');
  const { title, skillScoreColorCode, skillScore, contents, headerSubTitle } =
    data;
  const contentsData = contents[activeStep];
  const { buttonText, type, content, step, subTitle, status } = contentsData;
  return (
    <div>
      <HeaderCard>
        <HeaderWrapper>
          <FlexView>
            <HeaderIcon src={BoosterIcon} />
            <HeaderTitle>{title}</HeaderTitle>
          </FlexView>
          <FlexView>
            <HeaderSmallText>Skill score</HeaderSmallText>
            <CircleView color={skillScoreColorCode}>{skillScore}</CircleView>
          </FlexView>
        </HeaderWrapper>
      </HeaderCard>

      <HeaderCard>
        <HeaderCard noBottom={true}>
          <SubContainerWrapper noBottomPadding={true}>
            <SubContainerTitle>{headerSubTitle}</SubContainerTitle>
            {steps.length !== 0 ? (
              <Steper
                activeStep={activeStep}
                handleSteperClick={handleSteperClick}
                steps={steps}
              />
            ) : null}
            <FlexJustifyView>
              <div>
                <GreenSubText>{step}</GreenSubText>
                <DescriptionText>{subTitle}</DescriptionText>
              </div>
              <FlexButtonView>
                <ActionButton name={buttonText} onClick={handleNext}>
                  {buttonText}
                </ActionButton>
              </FlexButtonView>
            </FlexJustifyView>
          </SubContainerWrapper>
        </HeaderCard>
        <SubContainerWrapper>
          {
            <Type
              data={content}
              IconsType={Icons}
              type={type}
              handleTask={handleTask}
              handleTestClick={handleTestClick}
            />
          }
        </SubContainerWrapper>
      </HeaderCard>
    </div>
  );
};
