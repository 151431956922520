import React, { Fragment, useState } from 'react';
import { Question } from '../../../../assets/css/StyledComponent';
import { Div } from '../../../../assets/css/test/TestComponent';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import LatexViewer from '../../../../utils/LatexViewer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TabPanel from '@mui/material/Tabs';

export default function MultiTab(props) {
  const {
    question,
    options,
    choiceAnswers,
    multiDescription,
    description,
    imgUrl,
  } = props;

  const tabValues = [0, 1, 2];
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Grid container>
      <Grid md={6}>
        <Box flex={1} className={'question-left-container'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              onChange={handleChangeTab}
              value={tabValue}
              aria-label="lab API tabs example"
            >
              {tabValues.map(value => (
                <Tab key={value} label={`Item ${value + 1}`} value={value} />
              ))}
            </Tabs>
          </Box>
          <Box
            fontSize={16}
            color={'#052A4E'}
            lineHeight={'30px'}
            padding={'10px 12px'}
          >
            {tabValues.map(index => (
              <div
                key={index}
                hidden={tabValue !== index}
                style={{ padding: '15px' }}
              >
                <TabPanel
                  value={tabValue}
                  index={index}
                  className="tabs-question-tab"
                >
                  <Box
                    fontSize={16}
                    color={'#052A4E'}
                    lineHeight={'30px'}
                    className="9999999"
                    overflow={'scroll'}
                  >
                    <LatexViewer
                      math={multiDescription[tabValue]['description']}
                    />
                  </Box>
                </TabPanel>
              </div>
            ))}
          </Box>
          <Box>
            <Divider variant="middle" orientation={'vertical'} />
          </Box>
        </Box>
      </Grid>

      <Grid item md={6}>
        <Box>
          <Div>
            <Question>
              <LatexViewer math={question} />
            </Question>
            <FormControl>
              <Box display={'flex'}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: '600' }}
                >
                  <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Typography>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: '600' }}
                >
                  <span>No</span>
                </Typography>
              </Box>

              {options.map((item, index) => {
                const getcorrect = choiceAnswers.filter(
                  (correct, correctIndex) => correct.id === item.id
                );
                return (
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{
                          alignItems: 'center',
                          height: '100%',
                          padding: '10px',
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={''}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              icon={
                                getcorrect[0].score === 0 ? (
                                  getcorrect[0].firstSelected ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: 'green !important',
                                      }}
                                    />
                                  ) : (
                                    <CancelIcon
                                      sx={{
                                        color: 'red !important',
                                      }}
                                    />
                                  )
                                ) : getcorrect[0].score === 1 &&
                                  getcorrect[0].firstSelected ? (
                                  <CheckCircleIcon
                                    sx={{
                                      color: 'green !important',
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )
                              }
                            />
                          }
                          style={{ marginBottom: '0px', marginRight: '25px' }}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              icon={
                                getcorrect[0].score === 0 ? (
                                  getcorrect[0].secondSelected ? (
                                    <CheckCircleIcon
                                      sx={{
                                        color: 'green !important',
                                      }}
                                    />
                                  ) : (
                                    <CancelIcon
                                      sx={{
                                        color: 'red !important',
                                      }}
                                    />
                                  )
                                ) : getcorrect[0].score === 1 &&
                                  getcorrect[0].secondSelected ? (
                                  <CheckCircleIcon
                                    sx={{
                                      color: 'green !important',
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )
                              }
                            />
                          }
                          style={{
                            marginRight: '0px',
                            marginBottom: '0px',
                            margin: '0px',
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <LatexViewer math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </Div>
        </Box>
      </Grid>
    </Grid>
  );
}
