/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import { Container } from '../../../assets/css/StyledPractice';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import { routePaths } from '../../../routes/RoutePath';
import Header from './Header';
import { Grid } from '@material-ui/core';
import {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
} from '../../../redux/action/Dashboard';
import {
  getTopicsAndTasksAndQuestions,
  AddBookmarks,
  handleBookmarkState,
} from '../../../redux/action/Practice';
import BookmarkIcon from '../../../assets/icons/Bookmarks.svg';
import { connect } from 'react-redux';
import LeftCard from './LeftCard';
import RightCard from './RightCard';
import DialogComponent from '../../../utils/components/DialogComponent';
import { withTranslation } from 'react-i18next';

const sortDateData = [{ id: 'DESC', title: 'Sort by date' }];

class Bookmarks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftTabData: [],
      rightCardData: [],
      id: null,
      headData: [],
      dialogOpen: false,
      bookmarkId: null,
      bookmarkType: '',
      order: 'DESC',
      loader: true,
    };

    this.breadCrumbsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Practise',
            },
            {
              name: 'Bookmarks',
            },
          ],
        },
      },
      back: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.bookmarkState.subjectValue === 'default')
      this.props.getStudyPlanSubjects(response => {
        if (response.success)
          this.props.getStudyPlanConcepts(
            response.data[0].id,
            conceptResponse => {
              if (conceptResponse.success)
                this.props.handleBookmarkState({
                  subjectValue: 'default',
                  conceptValue: 'default',
                  activeTab: this.props.bookmarkState.activeTab,
                  activeLeftTab: this.props.bookmarkState.activeLeftTab,
                });
              // this.setState({});
            }
          );
      });
    else {
      // Handing drop down changes in the reducer
      // Setting let and right data
      if (
        this.state.leftTabData.length === 0 ||
        this.state.rightCardData === 0
      ) {
        this.setState({ loader: false });
        this.props.getTopicsAndTasksAndQuestions(
          this.props.bookmarkState.conceptValue,
          'DESC',
          res => {
            if (res) {
              this.props.bookmarkLandingData &&
                this.setLeftData({
                  bookmarkLandingData: this.props.bookmarkLandingData,
                  activeTab: this.props.bookmarkState.activeTab,
                });

              this.props.bookmarkLandingData &&
                this.setRightData({
                  bookmarkLandingData: this.props.bookmarkLandingData,
                  activeTab: this.props.bookmarkState.activeTab,
                  activeLeftTab: this.props.bookmarkState.activeLeftTab,
                });
              this.setState({ loader: true })
            }
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { leftTabData, rightCardData } = this.state;
    const { activeTab, activeLeftTab } = this.props.bookmarkState;
    const { bookmarkLandingData } = this.props;
    if (
      bookmarkLandingData !== null &&
      prevProps.bookmarkLandingData !== bookmarkLandingData
    ) {
      var headTitles =
        bookmarkLandingData.data.length !== 0
          ? bookmarkLandingData.data.map(item => ({
            title: item.title,
          }))
          : [];
      this.setState({
        headData: headTitles,
      });
    }

    if (
      prevState.leftTabData === leftTabData &&
      bookmarkLandingData !== null &&
      prevProps.bookmarkState !== this.props.bookmarkState
    ) {
      this.setState({ loader: true });
      this.setLeftData({ bookmarkLandingData, activeTab });
    }

    if (
      (prevProps.bookmarkState.activeLeftTab !== activeLeftTab &&
        bookmarkLandingData !== null) ||
      (prevState.rightCardData === rightCardData &&
        bookmarkLandingData !== null &&
        prevProps.bookmarkState !== this.props.bookmarkState)
    ) {
      this.setState({ loader: true });
      this.setRightData({ bookmarkLandingData, activeTab, activeLeftTab });
    }

    if (prevProps.bookmarkLandingData !== bookmarkLandingData) {
      this.setLeftData({ bookmarkLandingData, activeTab });
      this.setRightData({ bookmarkLandingData, activeTab, activeLeftTab });
    }
  }

  setLeftData = ({ bookmarkLandingData, activeTab }) => {
    let count = this.totalCount(
      bookmarkLandingData.data[activeTab]['bookmarks']
    );
    var topics = bookmarkLandingData.data[activeTab]['bookmarks'];
    var allTopics =
      topics.length !== 0
        ? [{ id: 'default', topic: 'All', count }, ...topics]
        : [];

    this.setState({
      leftTabData: allTopics,
    });
  };

  setRightData = ({ bookmarkLandingData, activeTab, activeLeftTab }) => {
    var questionsArray = bookmarkLandingData.data[activeTab]['bookmarks']
      .filter(item => activeLeftTab === 'default' || item.id === activeLeftTab)
      .map(topic => topic.bookmarks);
    var questions = [];
    for (let i = 0; i < questionsArray.length; i++) {
      questions.push(...questionsArray[i]);
    }
    this.setState({
      rightCardData: questions,
    });
  };

  totalCount = data => {
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      count = count + data[i].count;
    }
    return count;
  };

  handleActiveLeftTab = id => {
    // Handing drop down changes in the reducer
    this.props.handleBookmarkState({
      ...this.props.bookmarkState,
      activeLeftTab: id,
    });
  };

  handleChange = event => {
    this.props.getStudyPlanConcepts(event.target.value, response => {
      if (response.success)
        this.props.getTopicsAndTasksAndQuestions(response.data[0].id, 'DESC');

      // Handing drop down changes in the reducer
      this.props.handleBookmarkState({
        ...this.props.bookmarkState,
        subjectValue: event.target.value,
        conceptValue: response.data[0].id,
      });
    });
  };

  handleConceptChange = event => {
    const { value } = event.target;
    this.props.getTopicsAndTasksAndQuestions(value, 'DESC');

    // Handing drop down changes in the reducer
    this.props.handleBookmarkState({
      ...this.props.bookmarkState,
      conceptValue: value,
    });
  };

  handleOrderChange = event => {
    const { value } = event.target;

    this.setState({
      order: value,
    });

    this.props.getTopicsAndTasksAndQuestions(
      this.props.bookmarkState.conceptValue,
      value
    );
  };

  handleButton1Click = () => {
    this.setState({ bookmarkId: null, dialogOpen: false });
  };

  handleButton2Click = () => {
    const { bookmarkId, bookmarkType } = this.state;
    const { conceptValue } = this.props.bookmarkState;
    var bookmarkData = { id: bookmarkId, type: bookmarkType };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        this.props.getTopicsAndTasksAndQuestions(conceptValue, 'DESC');
        this.setState({
          bookmarkId: null,
          bookmarkType: '',
          dialogOpen: false,
          loader: true,
        });
      }
    });
  };

  handleBookmarkClick = (id, type, event) => {
    event.stopPropagation();
    this.setState({ bookmarkId: id, bookmarkType: type, dialogOpen: true });
  };

  handleQuestionClick = (id, testQuestionSetId, type, topicId) => {
    if (type === 'question')
      this.props.history.push(
        `${routePaths.dashboard.question}?questionId=${id}&testQuestionId=${testQuestionSetId}&type=Reattempt&isBookmarked=true`
      );
    if (type === 'task')
      this.props.history.push(
        `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${id}&prevPage=bookmarks`
      );
  };

  render() {
    const { leftTabData, rightCardData, headData, dialogOpen, order, loader } =
      this.state;

    const { subjectValue, conceptValue, activeLeftTab, activeTab } =
      this.props.bookmarkState;

    const { data: subjectData } = this.props.studyPlanSubjects;
    const { data: conceptData } = this.props.studyPlanConcepts;
    const {
      handleChange,
      handleConceptChange,
      handleActiveLeftTab,
      handleBookmarkClick,
      handleButton1Click,
      handleButton2Click,
      handleQuestionClick,
      handleOrderChange,
    } = this;
    const { t } = this.props;

    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BreadCrumbs {...this.breadCrumbsProps} />
          </Grid>
          <Grid item xs={12}>
            <Header
              subjectData={subjectData}
              conceptData={conceptData}
              subjectValue={subjectValue}
              conceptValue={conceptValue}
              handleChange={handleChange}
              handleConceptChange={handleConceptChange}
              order={order}
              sortDateData={sortDateData}
              handleSortDateChange={handleOrderChange}
              activeTab={activeTab}
              data={headData}
              handleClick={index =>
                this.props.handleBookmarkState({
                  ...this.props.bookmarkState,
                  activeTab: index,
                })
              }
              t={t}
            />
          </Grid>

          <>
            <Grid item xs={12} md={3}>
              <LeftCard
                leftTabData={leftTabData}
                activeLeftTab={activeLeftTab}
                handleActiveLeftTab={handleActiveLeftTab}
                t={t}
                loader={loader}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <RightCard
                data={rightCardData}
                handleBookmarkClick={handleBookmarkClick}
                handleQuestionClick={handleQuestionClick}
                t={t}
                loader={loader}
              />
            </Grid>
          </>
        </Grid>
        <DialogComponent
          open={dialogOpen}
          dialogContent={{
            icon: <img src={BookmarkIcon} alt="" />,
            title: t(`Are you sure you want remove bookmark?`),
            button1: t('Cancel'),
            button2: t('Yes'),
          }}
          handleButton1Click={handleButton1Click}
          handleButton2Click={handleButton2Click}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    studyPlanSubjects: state.DashboardQuestionBankReducer.studyPlanSubjects,
    studyPlanConcepts: state.DashboardQuestionBankReducer.studyPlanConcepts,
    conceptsSubjects: state.PracticeReducer.conceptsSubjects,
    bookmarkLandingData: state.PracticeReducer.bookmarkLandingData,
    bookmarkState: state.PracticeReducer.bookmarkState,
  };
};

export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getTopicsAndTasksAndQuestions,
  AddBookmarks,
  handleBookmarkState,
})(withTranslation()(Bookmarks));