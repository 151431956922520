/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Button, ThemeProvider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../../assets/css/LeftContainer.css';
import { buttonTheme } from '../../../assets/css/MuiStyles';
import passreset from '../../../assets/images/passreset.png';
import { routePaths } from '../../../routes/RoutePath';

class Passwordchanged extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box className={'_password_changed_image'}>
              <img src={passreset} alt="passreset" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={10}>
              <h2 className={'_password_changed_title'}>
                {t('Password Reset Successfully')}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={3}>
              <Typography className={'_password_changed_bottomText'}>
                {t(
                  'You can now use your new password to login to your account'
                )}{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={0} fullWidth>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.history.push(routePaths.login)}
                  id={'passwordChangeLoginBtn'}
                >
                  {t('Login')}
                </Button>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(Passwordchanged);
