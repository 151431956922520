import React, { Component } from 'react';
import { routePaths } from '../../../routes/RoutePath';
import { withRouter } from 'react-router-dom';
import success from '../../../assets/images/successfully.png';
import {
  SuccessContainer,
  SuccessImg,
  SuccessTitle,
  SuccessSubTitle,
  SuccessButton,
} from '../../../assets/css/StyledComponent';
import { withTranslation } from 'react-i18next';
class PasswordSuccess extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <SuccessContainer>
          <SuccessImg src={success} />
          <SuccessTitle>
            {t('Password has been Successfully Changed')}
          </SuccessTitle>
          <SuccessSubTitle>
            {t('You can now use your new password to log in to your account')}
          </SuccessSubTitle>
          <SuccessButton
            onClick={() =>
              this.props.history.push(routePaths.dashboard.myAccount)
            }
          >
            {t('Ok')}
          </SuccessButton>
        </SuccessContainer>
      </>
    );
  }
}

export default withRouter(withTranslation()(PasswordSuccess));
