import { IconButton } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayCircleFilledRounded';
import React from 'react';
import Player from '../../../../utils/Player';

function VideoCard(props) {
  return (
    <div className="video-card">
      <Player otp={props.otp} playBackInfo={props.playBackInfo} />
      <div className="icon-box">
        <IconButton onClick={props.onClick}>
          <PlayIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
}

export default VideoCard;
