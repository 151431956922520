import React from 'react';
import { Question } from '../../../../assets/css/StyledComponent';
import { Div } from '../../../../assets/css/test/TestComponent';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import LatexViewer from '../../../../utils/LatexViewer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function TwoPart(props) {
  const {
    question,
    options,

    isHaveDescription,
    description,
    imgUrl,
    twoPartAnswers,
    firstSelectedName,
    data,
    secondSelectedName,
    handleChangeTwo,
    handleChangeTwo1,
    bundleSelectedChoice,
  } = props;

  console.log('dataadfaffa', data);
  return (
    <Grid container>
      {isHaveDescription && (
        <Grid md={isHaveDescription === false ? 12 : 6}>
          <Box height={'100%'} display={'flex'}>
            <Box flex={1} className={'question-left-container'}>
              <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                <LatexViewer math={description} />
              </Box>
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
                )}
              </Box>
            </Box>
            <Box>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item md={isHaveDescription === false ? 12 : 6}>
        <Box>
          <Div>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography variant="h5" color="textPrimary">
                  <LatexViewer math={question} />
                </Typography>
              </FormLabel>
              <table className="two-part-table">
                <tr>
                  <th>{firstSelectedName}</th>
                  <th>{secondSelectedName}</th>
                  <th></th>
                </tr>
                {twoPartAnswers === null ? (
                  <tr>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((item, index) => {
                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{
                                  justifyContent: 'center',
                                  padding: '0px',
                                }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name={`left-${index}`}
                                value={
                                  bundleSelectedChoice[0]?.id === item.id
                                    ? bundleSelectedChoice[0]?.correctChoice
                                    : ''
                                }
                                onChange={event =>
                                  handleChangeTwo(event, index)
                                }
                              >
                                <FormControlLabel
                                  value={'true'}
                                  control={<Radio />}
                                  style={{ margin: '0px' }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((item, index) => {
                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{
                                  justifyContent: 'center',
                                  padding: '0px',
                                }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name={`right-${index}`}
                                value={
                                  bundleSelectedChoice[1]?.id === item.id
                                    ? bundleSelectedChoice[1]?.wrongChoice
                                    : ''
                                }
                                onChange={event =>
                                  handleChangeTwo1(event, index)
                                }
                              >
                                <FormControlLabel
                                  value={'true'}
                                  control={<Radio />}
                                  style={{ margin: '0px' }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((choice, index) => (
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{ margin: '10px' }}
                          >
                            {choice.text ? (
                              <LatexViewer math={choice.text} />
                            ) : (
                              <img src={choice.choiceImage} alt="" />
                            )}
                          </Typography>
                        ))}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((item, index) => {
                          console.log(twoPartAnswers, 'pppp');
                          const first =
                            twoPartAnswers &&
                            twoPartAnswers.first.correctAnswer;

                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{
                                  justifyContent: 'center',
                                  padding: '0px',
                                }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name={`left-${index}`}
                                value={''}
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      icon={
                                        (first === item.id &&
                                          twoPartAnswers.first.userSelected ===
                                            item.id) ||
                                        item.id === first ? (
                                          <CheckCircleIcon
                                            sx={{
                                              color: 'green !important',
                                            }}
                                          />
                                        ) : item.id ===
                                          twoPartAnswers.first.userSelected ? (
                                          <CancelIcon
                                            sx={{
                                              color: 'red !important',
                                            }}
                                          />
                                        ) : (
                                          <RadioButtonUncheckedIcon />
                                        )
                                      }
                                    />
                                  }
                                  style={{ margin: '0px' }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((item, index) => {
                          const second =
                            twoPartAnswers &&
                            twoPartAnswers.second.correctAnswer;
                          return (
                            <Grid item>
                              <RadioGroup
                                row
                                style={{
                                  justifyContent: 'center',
                                  padding: '0px',
                                }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name={`right-${index}`}
                                value={''}
                              >
                                <FormControlLabel
                                  // value={'true'}
                                  control={
                                    <Radio
                                      icon={
                                        (second === item.id &&
                                          twoPartAnswers.second.userSelected ===
                                            item.id) ||
                                        item.id === second ? (
                                          <CheckCircleIcon
                                            sx={{
                                              color: 'green !important',
                                            }}
                                          />
                                        ) : item.id ===
                                          twoPartAnswers.second.userSelected ? (
                                          <CancelIcon
                                            sx={{
                                              color: 'red !important',
                                            }}
                                          />
                                        ) : (
                                          <RadioButtonUncheckedIcon />
                                        )
                                      }
                                    />
                                  }
                                  style={{ margin: '0px' }}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {options.map((choice, index) => (
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{ margin: '10px' }}
                          >
                            {choice.text ? (
                              <LatexViewer math={choice.text} />
                            ) : (
                              <img src={choice.choiceImage} alt="" />
                            )}
                          </Typography>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            </FormControl>
          </Div>
        </Box>
      </Grid>
    </Grid>
  );
}
