/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  SearchVideo as Video,
  SearchVideoCard,
  SearchIconBox,
} from '../../../../assets/css/practice/WebinarComponents';
import PlayIcon from '@material-ui/icons/PlayCircleFilledRounded';
import { IconButton } from '@material-ui/core';

function SearchVideo(props) {
  return (
    <SearchVideoCard>
      <Video src={props.src} />

      <SearchIconBox>
        <IconButton onClick={props.onClick}>
          <PlayIcon color="primary" fontSize="small" />
        </IconButton>
      </SearchIconBox>
    </SearchVideoCard>
  );
}

export default SearchVideo;
