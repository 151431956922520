import { Grid, MenuItem, ThemeProvider } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  listItem,
  StyledSelect,
  StyledTooltip,
} from '../../../../assets/css/dashboard/viewmore/StrengthStyles.js';
import {
  Container,
  EndFlex,
  InfoIcon,
  SelectBox,
  SkillContainer,
  SkillContent,
} from '../../../../assets/css/dashboard/viewmore/StyledStrengthComponent.js';
import info from '../../../../assets/icons/info_outline.svg';
import { EVENTS } from '../../../../clevertap/Events.js';
import { DROP_DOWN_PROPS } from '../../../../constant/Variables.js';
import {
  getStrengthsAndWeakness,
  getStudyPlanSubjects,
  getTopicInsights,
} from '../../../../redux/action/Dashboard';
import { routePaths } from '../../../../routes/RoutePath.js';
import BreadCrumbs from '../../../../utils/BreadCrumbs';
import PageLoader from '../../../../utils/components/PageLoader.js';
import Card from './Card';
import { getClickSourceDetails } from '../../../../redux/action/Student';
import clevertap from 'clevertap-web-sdk';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectValue: null,
      subjectData: [],
      strengthsAndWeaknessData: [],
      topicOpenList: [],
      openList: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getStudyPlanSubjects(response => {
      if (response.success) {
        this.props.getStrengthsAndWeakness(
          response.data[0].id,
          strengthsAndWeaknessResponse => {
            this.setState({
              subjectData: response.data,
              subjectValue: response.data[0].id,
              strengthsAndWeaknessData: strengthsAndWeaknessResponse.data,
            });
          }
        );
        this.setState({
          subjectData: response.data,
          subjectValue: response.data[0].id,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.strengthsAndWeakness !== prevProps.strengthsAndWeakness) {
      const { data } = this.props.strengthsAndWeakness;
      this.setState({ strengthsAndWeaknessData: data });
    }
  }

  handleChange = event => {
    this.props.getStrengthsAndWeakness(event.target.value, () => {});
    this.setState({
      subjectValue: event.target.value,
    });
  };

  handleBoostClick = (e, obj) => {
    const { id } = e.currentTarget;
    this.props.getClickSourceDetails(
      'Revise now button from strength & weakness page',
      res => {
        if (res.success) {
          clevertap.event.push(EVENTS.LMSUserReviseAWeakTopics, {
            'Topic Name': obj.topicName,
            'Concept Name': obj.conceptName,
            'Count of clicks': res.data.count,
          });
          this.props.history.push(
            `${routePaths.dashboard.diagnosticEngine}?topicId=${id}`
          );
        }
      }
    );
  };

  handleInsightChange = (index, conceptIndex, topicIndex, value) => {
    let arr = [...this.state.strengthsAndWeaknessData];
    var topicValue = arr[index]['concepts'][conceptIndex]['topics'][topicIndex];
    arr[index]['concepts'][conceptIndex]['topics'][topicIndex] = {
      ...topicValue,
      insights: value,
    };
    this.setState({ strengthsAndWeaknessData: arr });
  };

  handleTopicClick = (
    indexValue,
    conceptIndex,
    topicIndex,
    topicId,
    isInsights
  ) => {
    var topicList = this.state.topicOpenList;
    var index = topicList.indexOf(topicId);

    if (index > -1) {
      topicList.splice(index, 1);
    } else {
      topicList.push(topicId);
      if (!isInsights) {
        this.props.getTopicInsights(topicId, response => {
          if (response.success) {
            this.handleInsightChange(
              indexValue,
              conceptIndex,
              topicIndex,
              response.data.insights
            );
          }
        });
      }
    }
    this.setState({ topicOpenList: topicList });
  };

  handleClick = id => {
    var openList = this.state.openList;
    var index = openList.indexOf(id);

    if (index > -1) openList.splice(index, 1);
    else openList.push(id);

    this.setState({ openList: openList });
  };

  render() {
    const {
      subjectValue,
      subjectData,
      strengthsAndWeaknessData,
      topicOpenList,
      openList,
    } = this.state;
    const { t } = this.props;
    const content = t(
      "It's the weighted accuracy of all questions solved so far across easy, medium, hard questions. Want to improve your skill score, solve more questions"
    );

    const breadCrumpsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Home',
              path: routePaths.dashboard.home,
            },
            {
              name: 'Strength & weakness',
              path: routePaths.dashboard.strengthsWeakness,
            },
          ],
        },
      },

      back: false,
      backEvent: true,
      goBack: () => {
        this.props.history.push(routePaths.dashboard.home);
      },
    };

    if (this.state.strengthsAndWeaknessData.length !== 0)
      return (
        <>
          <Container>
            <Grid container style={{ margin: '6px 0px 12px' }}>
              <Grid item sm={6} md={6} lg={6}>
                <BreadCrumbs {...breadCrumpsProps} />
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <EndFlex>
                  <SkillContainer>
                    <SkillContent>{t('skill score')}</SkillContent>
                    <StyledTooltip title={content} arrow>
                      <InfoIcon src={info} />
                    </StyledTooltip>
                  </SkillContainer>

                  <SelectBox>
                    <ThemeProvider theme={listItem}>
                      <StyledSelect
                        onChange={this.handleChange}
                        name="subjectValue"
                        IconComponent={ExpandMore}
                        disableUnderline
                        value={subjectValue}
                        MenuProps={DROP_DOWN_PROPS}
                      >
                        {subjectData.map(subject => {
                          return (
                            <MenuItem value={subject.id}>
                              {subject.title}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                    </ThemeProvider>
                  </SelectBox>
                </EndFlex>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Card
                data={strengthsAndWeaknessData}
                handleBoostClick={this.handleBoostClick}
                topicOpenList={topicOpenList}
                openList={openList}
                handleTopicClick={this.handleTopicClick}
                handleClick={this.handleClick}
                t={t}
              />
            </Grid>
          </Container>
        </>
      );
    else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    ...state.dashboardReducer,
  };
};

export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStrengthsAndWeakness,
  getTopicInsights,
  getClickSourceDetails,
})(withTranslation()(Index));
