/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Container, Grid, Typography } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React from 'react';
import {
  Description,
  TopicHeadingText,
} from '../../../assets/css/subject/LandingComponents';

function Topic(props) {
  const { image, title, description } = props.concept;
  const { index, activeConcept, handleClick, t } = props;

  return (
    <Container maxWidth="xl">
      <Grid container alignItems="stretch">
        {/* Topic Card */}
        <Grid item xs={12} container alignItems="center">
          {/* Topic Icon */}
          <img src={image} alt={'icon'} className={'clock-image-space'} />
          <TopicHeadingText>{title}</TopicHeadingText>
          <div style={{ flexGrow: 1 }} />
        </Grid>
        <Grid id="content-description" item xs={12}>
          <Description>{description}</Description>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
          <Box
            display="flex"
            alignItems="stretch"
            style={{ cursor: 'pointer' }}
            onClick={handleClick(index)}
            id="center-box"
          >
            <Typography id="topic-button">
              {t(`${activeConcept === index ? 'Hide' : 'View'} Topics`)}
            </Typography>
            <ExpandMoreRoundedIcon
              className={
                activeConcept === index
                  ? 'topic-button-arrow-active topic-button-arrow'
                  : 'topic-button-arrow'
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Topic;
