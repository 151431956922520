import { Grid } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DoneButton,
  InsideBox,
  NoteImg,
  ReportContainer,
  ReportDate,
  ReportTitle,
  SubTitle,
  ViewContainer,
} from '../../../../assets/css/report/Report';
import notepad from '../../../../assets/images/notepad.png';
import { MONTHS, QS_PROPS } from '../../../../constant/Variables';
import * as track from '../../../../mixpanel/Events';
import { getReport } from '../../../../redux/action/Dashboard';
import { getTopicTestExcel } from '../../../../redux/action/OfficialGuide';
import { routePaths } from '../../../../routes/RoutePath';
import InsightsCard from './InsightsCard';
import ReviewCard from './ReviewCard';
import PageLoader from '../../../../utils/components/PageLoader';
import { textToDownloadFile } from '../../../../utils/methods/Helper';
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: '',
      loading: true,
      data: {
        title: 'Report',

        test: {
          name: 'Test 2 Arithmetic',
          reportTitle: 'Report Date',
          reportDate: '14 Jun 2021',
        },
        subTitle: {
          title1: 'Insights',
          title2: 'Review',
        },
      },
    };
  }

  componentDidMount() {
    const { testExecutionId, isRetaken } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    track.reportFirstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Topic test',
    });

    this.props.getReport(testExecutionId, isRetaken, response => {
      this.setState({
        reports: response.data,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    let timeSpend = new Date() - this.state.spentTime;
    var timestamp = timeSpend / 1000;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var second = timestamp % 60;
    function pad(d) {
      return d < 10 ? '0' + d.toString() : d.toString();
    }
    track.reportSessionTime({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Topic Test',
      'Total Time Spend': `${pad(hours)} : ${pad(minutes)} : ${pad(second)}`,
    });

    track.reportFirstAction(
      {
        'Product Name': JSON.parse(localStorage.getItem('userProduct'))
          .productName,
        'Test Type': 'Topic test',
        'First Action': 'Done',
      },
      'Topic'
    );
  }

  handleClick = topicId => {
    track.reportFirstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Topic test',
      'First Action': 'Done',
    });

    const boostScore = localStorage.getItem('boostScore');
    const diagnosticEngine = localStorage.getItem('diagnosticEngine');
    // Diagnostic engine path

    const { test_type, diagnostic, subject, type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const testPage = window.localStorage.getItem('testPage');
    if (test_type === 'OG_PRACTISETEST') {
      this.props.history.push(routePaths.dashboard.guide);
    } else if (subject === 'subject') {
      this.props.history.push(
        `${routePaths.dashboard.topicTest}?topicId=${topicId}&type=${type}&subject=subject`
      );
    } else if (boostScore) {
      this.props.history.push(
        `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
      );
      localStorage.removeItem('boostScore');
    } else if (diagnostic === 'diagnostic-engine') {
      this.props.history.push(
        `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
      );
    } else if (testPage === 'practiseTopicTest') {
      this.props.history.push(routePaths.dashboard.topic);
    } else if (diagnosticEngine) {
      this.props.history.push(
        `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
      );
      localStorage.removeItem('diagnosticEngine');
    }
    // Regular topic test path
    else
      this.props.history.push(
        `${routePaths.dashboard.topicTest}?topicId=${topicId}`
      );
  };

  handleExcelDownload = () => {
    const { testExecutionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.props.getTopicTestExcel(testExecutionId, res => {
      textToDownloadFile(res.data, 'Topic test Report', 'xls');
    });
  };

  render() {
    const { reports } = this.state;
    const { data } = this.state;
    let date = new Date(this.state.reports.reportedDate);
    let month = date.getMonth();

    let year = date.getFullYear();
    let date1 = date.getDate();

    let monthName = MONTHS[month];
    let startDate = date1 + ' ' + monthName + ' ' + year;
    if (this.state.loading) {
      return <PageLoader />;
    } else {
      return (
        <>
          <ViewContainer>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <ReportContainer>
                  <ReportTitle>
                    <NoteImg src={notepad} />

                    <span>{`${data.title} - ${reports.title || ''}`}</span>
                  </ReportTitle>

                  <ReportDate>
                    {`${data.test.reportTitle}: ${startDate}`}
                  </ReportDate>
                </ReportContainer>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ReportContainer>
                  <InsideBox>
                    <SubTitle>{data.subTitle.title1}</SubTitle>
                  </InsideBox>
                  <InsideBox style={{ display: 'flex', gap: '2em' }}>
                    <DoneButton onClick={this.handleExcelDownload}>
                      Download
                    </DoneButton>
                    <DoneButton
                      onClick={() => this.handleClick(reports.topicId)}
                    >
                      Done
                    </DoneButton>
                  </InsideBox>
                </ReportContainer>
                {this.state.reports && (
                  <InsightsCard item={this.state.reports} />
                )}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <SubTitle>{data.subTitle.title2}</SubTitle>
                {this.state.reports && (
                  <ReviewCard items={this.state.reports} {...this.props} />
                )}
              </Grid>
            </Grid>
          </ViewContainer>
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    reportResponse: state.DashBoardReducer.report,
  };
};

export default connect(mapStateToProps, {
  getReport,
  getTopicTestExcel,
})(Report);
