import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  ActionButton,
  Card1,
  Container,
  DownloadButton,
  ExcelButton,
  ErrorTitle,
  FlexView,
  Header,
  InsideBox,
  ReportContainer,
  ReportSubTitle,
  ReportTime,
  ReportTitle,
  SubTitle,
  Title,
} from '../../../../../assets/css/GreReportStyledComponent';
import { QS_PROPS } from '../../../../../constant/Variables';
import { Grid } from '@material-ui/core';
import InsightCard from './GmatInsightCard';
import GmatErrorLog from './GmatErrorLog';
import ReportCard from './ReportCard';
import QueryString from 'qs';
import { routePaths } from '../../../../../routes/RoutePath';
import {
  getGmatOgReport,
  getGmatExcel,
} from '../../../../../redux/action/OfficialGuide';
import PageLoader from '../../../../../utils/components/PageLoader';
import { createPdfFromHtml } from './GmatPdfMaker';
import { textToDownloadFile } from '../../../../../utils/methods/Helper';
import { getGreOgOldReport } from '../../../../../redux/action/OfficialGuide';

// import { response } from 'express';

class GreReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: [],
      report: null,
      Data: {
        text: 'Click here : ',
        heading: 'Overall Summary',
        reportDate: 'Report Date : ',
        subTitle: {
          title1: 'Test Summary',
          title2: 'Error Log',
        },
      },
      variantIndex: 0,
      isLoading: false,
      variantName: '',
      spentTime: new Date(),
      attemptValue: null,
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    const { testExecutionId, isReportGenerated } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (isReportGenerated) {
      this.props.getGreOgOldReport(testExecutionId, response => {
        console.log(response.data, 'data');
        let userProduct = JSON.parse(localStorage.getItem('userProduct'));
        userProduct.testExecutionId = testExecutionId;
        localStorage.setItem('userProduct', JSON.stringify(userProduct));
        this.setState({
          report: response.data,
          isGmatOGReportCompleted: true,
        });
      });
    } else {
      this.props.getGmatOgReport(testExecutionId, response => {
        this.setState({
          report: response.data,
          isGmatOGReportCompleted: true,
        });
      });
    }
  }

  handleClick = () => {
    const { test_type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (test_type === 'OG_PRACTISETEST')
      this.props.history.push(routePaths.dashboard.guide);
  };

  handleOnClick = e => {
    const { testExecutionId, test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    // this.props.history.push({
    //   pathname: routePaths.dashboard.gmatOverallSummary,
    // });
    this.props.history.push(
      `${routePaths.dashboard.gmatOverallSummary}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
    );
  };

  handleDownload = () => {
    var node = window.document.getElementById(
      'Gmat_og_practise_test_download_container'
    );
    var selectorNode = node.parentNode.querySelector('.study__plan__button');
    if (selectorNode) selectorNode.remove();
    createPdfFromHtml(node, 'Gmat og practise test Report.pdf');
  };
  handleExcelDownload = () => {
    const { testExecutionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.props.getGmatExcel(testExecutionId, res => {
      textToDownloadFile(res.data, 'Gmat og practise test Report', 'xls');
    });
  };
  render() {
    const { t } = this.props;
    const { report, Data, attemptValue } = this.state;

    const isDownloadReport = this.state.isGmatOGReportCompleted;

    const Sheet = () => {
      if (this.state.data !== null && this.state.report !== null) {
        return (
          <Container>
            <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Header>
                    <Title>{report?.title}</Title>
                    <FlexView>
                      <ReportTitle>{t(Data.text)}</ReportTitle>
                      <ReportSubTitle onClick={this.handleOnClick}>
                        {t(Data.heading)}
                      </ReportSubTitle>
                    </FlexView>
                  </Header>
                  <ReportTime>
                    {t(Data.reportDate)}
                    {report?.reportedDateStr}
                  </ReportTime>
                  <FlexView>
                    {isDownloadReport && (
                      <DownloadButton
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={this.handleDownload}
                      >
                        {t('Download Pdf Report')}
                      </DownloadButton>
                    )}
                    {isDownloadReport && (
                      <ExcelButton
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={this.handleExcelDownload}
                      >
                        {t('Download Excel Report')}
                      </ExcelButton>
                    )}
                    <ActionButton onClick={this.handleClick}>
                      {t('Done')}
                    </ActionButton>
                  </FlexView>
                </Grid>

                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <ReportContainer>
                      <InsideBox>
                        <Card1>
                          <SubTitle>{Data.subTitle.title1}</SubTitle>
                        </Card1>
                      </InsideBox>
                    </ReportContainer>
                    {this.state.report && (
                      <InsightCard item={this.state.report} />
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    {this.state.report && (
                      <ReportCard
                        apiData={this.props.gmatOgReport}
                        handleTopicChange={this.handleTopicChange}
                        attemptValue={attemptValue}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <ErrorTitle>{'Error Log'}</ErrorTitle>
                    {this.state.report && (
                      <GmatErrorLog apiData={this.props.gmatOgReport} />
                    )}
                  </Grid>
                </>
              </>
            </Grid>
          </Container>
        );
      } else {
        return <PageLoader />;
      }
    };
    return (
      <div id={'Gmat_og_practise_test_download_container'}>
        <Sheet />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.OfficialGuideReducer,
  };
};

export default connect(mapStateToProps, {
  getGmatOgReport,
  getGmatExcel,
  getGreOgOldReport,
})(withTranslation()(GreReport));
