import { Box, Card, Grid } from '@material-ui/core';
import QueryString from 'qs';
import React from 'react';
import '../../../assets/css/App.css';
import {
  FlatTextContent,
  ImageSideText,
  PositionedImageView,
  PositionedImage,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledExpandMoreIcon,
  TaskCard,
  TopicIconContainer,
  TopicTestButton,
  WrapContent,
  // PositionedImage,
} from '../../../assets/css/StyledComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import activeTextVideo from '../../../assets/icons/ActiveTextVideo.svg';
import StatusIcon from '../../../assets/icons/StatusIcon';
import TextVideo from '../../../assets/icons/TextVideo.svg';
import { ReactComponent as NotePadIcon } from '../../../assets/icons/topicNotePad.svg';
import activeBook from '../../../assets/images/activeBook.png';
import Book from '../../../assets/images/Book.png';
import ClockImage from '../../../assets/images/ClockImage.png';
import PauseIcon from '../../../assets/images/pauseIcon.png';
import ShapeImage from '../../../assets/images/Shape.png';
import { QS_PROPS } from '../../../constant/Variables';
import { routePaths } from '../../../routes/RoutePath';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import {
//   CircularProgressbar,
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function LeftCard(props) {
  const topicTypeIcon = type => {
    if (type === 'TEXT') return Book;
    if (type === 'VIDEO') return ShapeImage;
    if (type === 'TEXT_VIDEO') return TextVideo;
  };

  const activeTopicTypeIcon = type => {
    if (type === 'TEXT') return activeBook;
    if (type === 'VIDEO') return activeVideo;
    if (type === 'TEXT_VIDEO') return activeTextVideo;
  };

  const { topicId, type } = QueryString.parse(props.location.search, QS_PROPS);
  const { t } = props;

  const { activeVideo, selectedTask } = props;
  return (
    <Grid container direction="column" className={'left-container-task-view'}>
      <Card className={'left-side-card'}>
        {props.topicResponse &&
          props.topicResponse.tasks.map(item => {
            console.log(item.completedPercentage, 'restf', item);
            return (
              <div class="step">
                <div>
                  {item.completedPercentage === 100 ? (
                    <PositionedImageView>
                      <img src={StatusIcon(item.status)} alt="Icons" />{' '}
                    </PositionedImageView>
                  ) : (
                    <PositionedImage>
                      <CircularProgressbar
                        strokeWidth={20}
                        value={item.completedPercentage}
                        styles={buildStyles({
                          // pathColor: "green",

                          // width:"20px",

                          trailColor: 'white',

                          // strokeLinecap: "butt"
                        })}

                      // text={${item.completedPercentage}%}
                      />
                    </PositionedImage>
                  )}
                  <div className={'circle-line'}></div>
                </div>
                <div>
                  <StyledAccordion
                    id={item.id}
                    expanded={selectedTask.includes(item.id)}
                  >
                    <StyledAccordionSummary
                      expandIcon={<StyledExpandMoreIcon />}
                      id={item.id}
                      onClick={() => props.handleTaskClick(item.id)}
                    >
                      <FlatTextContent id={item.id}>
                        {item.title}
                      </FlatTextContent>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginTop={'12px'}
                      >
                        <div className={'right-side-card'} id={item.id}>
                          <img src={ClockImage} alt="clock" id={item.id} />
                          <ImageSideText id={item.id}>
                            {item.duration}
                          </ImageSideText>
                        </div>
                        <div className={'right-side-card'} id={item.id}>
                          {item.type === 'TEXT' &&
                            <img
                              width={'15px'}
                              src={topicTypeIcon('TEXT')}
                              alt="Text"
                              id={item.id}
                            />}
                          {
                            item.type === 'TEXT_VIDEO' &&
                            <img
                              width={'15px'}
                              src={topicTypeIcon('TEXT_VIDEO')}
                              alt="Text & Video"
                              id={item.id}
                            />
                          }
                          {
                            item.type === 'VIDEO' &&
                            <img
                              width={'15px'}
                              src={ShapeImage}
                              alt="Video"
                              id={item.id}
                            />
                          }
                          <ImageSideText id={item.id}>
                            {`${item.completedVideos}/${item.totalNoOfVideos}`}
                          </ImageSideText>
                        </div>
                        <div />
                      </Box>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      {item.contentVideo &&
                        item.contentVideo.length !== 0 &&
                        item.contentVideo.map(videoOption => (
                          <Box position={'relative'}>
                            <PositionedImageView
                              isVideo={true}
                              active={activeVideo === videoOption?.id}
                            >
                              <img
                                src={StatusIcon(videoOption?.status || 'TODO')}
                                alt="Icons"
                              />
                            </PositionedImageView>
                            <TaskCard
                              id={videoOption?.id}
                              active={activeVideo === videoOption?.id}
                              onClick={e =>
                                props.handleLeftCardClick(e, item?.id)
                              }
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gridGap={'9px'}
                                id={videoOption?.id}
                              >
                                {
                                  item.type === 'VIDEO' && (
                                    activeVideo === videoOption?.id ? (
                                      <img
                                        src={PauseIcon}
                                        alt="Icons"
                                        id={videoOption?.id}
                                      />
                                    ) : (
                                      <img
                                        width={'16px'}
                                        src={topicTypeIcon('VIDEO')}
                                        alt="Icons"
                                        id={videoOption?.id}
                                      />)
                                  )}

                                {item.type === 'TEXT_VIDEO' &&
                                  (activeVideo === videoOption?.id ? (
                                    <img
                                      src={activeTopicTypeIcon("TEXT_VIDEO")}
                                      alt="Icons"
                                      id={videoOption?.id}
                                    />
                                  ) : (
                                    <img
                                      width={'16px'}
                                      src={topicTypeIcon('TEXT_VIDEO')}
                                      alt="Icons"
                                      id={videoOption?.id}
                                    />)
                                  )}

                                <WrapContent
                                  id={videoOption?.id}
                                  active={activeVideo === videoOption?.id}
                                >
                                  {videoOption?.title &&
                                    videoOption?.title?.length > 32
                                    ? `${videoOption?.title?.substring(
                                      0,
                                      32
                                    )}...`
                                    : videoOption?.title}
                                </WrapContent>
                              </Box>
                            </TaskCard>
                          </Box>
                        ))}
                      {item.type === 'TEXT' && (
                        <Box position={'relative'}>
                          <PositionedImageView
                            isVideo={true}
                            active={activeVideo === item?.id}
                          >
                            <img
                              src={StatusIcon(item?.status || 'TODO')}
                              alt="Icons"
                            />
                          </PositionedImageView>
                          <TaskCard
                            id={item?.id}
                            active={activeVideo === item?.id}
                            onClick={e =>
                              props.handleLeftCardClick(e, item?.id)
                            }
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gridGap={'9px'}
                              id={item?.id}
                            >
                              {activeVideo === item?.id ? (
                                <img
                                  width={'16px'}
                                  src={activeTopicTypeIcon('TEXT')}
                                  alt="Icons"
                                  id={item?.id}
                                />
                              ) : (
                                <img
                                  width={'16px'}
                                  src={topicTypeIcon('TEXT')}
                                  alt="Icons"
                                  id={item?.id}
                                />
                              )}

                              <WrapContent
                                id={item?.id}
                                active={activeVideo === item?.id}
                              >
                                {'Notes'}
                              </WrapContent>
                            </Box>
                          </TaskCard>
                        </Box>
                      )}
                    </StyledAccordionDetails>
                  </StyledAccordion>
                </div>
              </div>
            );
          })}
      </Card>
      <Box mt={3} pt={3} pb={3} className={'left-side-below-card'}>
        <Box className={'topic_button_container'}>
          <TopicIconContainer>
            <NotePadIcon />
          </TopicIconContainer>
          <TopicTestButton
            variant={'outlined'}
            className="on-boost-mode"
            onClick={() =>
              props.history.push(
                `${routePaths.dashboard.topicTest}?topicId=${topicId}&type=${type}&subject=subject`
              )
            }
          >
            {'Practice Topic Test'}
          </TopicTestButton>
        </Box>
      </Box>
    </Grid>
  );
}
export default LeftCard;