import React from 'react';
import {
  Emoji,
  SpaceIcon,
  Welbox,
  WelCard,
  Welcometitle,
  WelContainer,
} from '../../../assets/css/StyledDashboardComponent';
import space from '../../../assets/images/dash/space.svg';

const WelcomeCard = ({ username, description, imageSrc }) => {
  return (
    <WelCard>
      <WelContainer>
        <Welbox>
          <Emoji src={imageSrc} alt="Not supported"></Emoji>
          <Welcometitle>
            <h3>Hi, {username}</h3>
            <p>{description}</p>
          </Welcometitle>
        </Welbox>
        <SpaceIcon src={space} alt="Space Icon"></SpaceIcon>
      </WelContainer>
    </WelCard>
  );
};

export default WelcomeCard;
