/**
 * (c) CareerLabs. All rights reserved.
 **/

import React from 'react';
import {
  DiagnosticButton,
  DiagnosticShadowCard,
  DiagnosticTitle,
  FlexView,
  LeftIcon,
  RightIcon,
  WelContainer,
} from '../../../../assets/css/StyledDashboardComponent';
import boost from '../../../../assets/icons/AeroplaneIcon.svg';

const DiagnosticEngineBoostCard = ({
  description,
  subDescription,
  buttonText,
  imageSrc,
  handleClick,
  continueTopicId,
}) => {
  return (
    <DiagnosticShadowCard>
      <WelContainer>
        <FlexView>
          <LeftIcon src={imageSrc} alt="Not supported" />
          <DiagnosticTitle>
            <p>{subDescription}</p>
            <h3>{description}</h3>
          </DiagnosticTitle>
        </FlexView>
        {buttonText && (
          <FlexView>
            <RightIcon src={boost} alt="Boost Icon" />

            <DiagnosticButton
              onClick={() => handleClick(buttonText, continueTopicId)}
            >
              {buttonText}
            </DiagnosticButton>
          </FlexView>
        )}
      </WelContainer>
    </DiagnosticShadowCard>
  );
};

export default DiagnosticEngineBoostCard;
