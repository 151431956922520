import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/StyledPractice';
import Header from './Header';
import TopicTestCard from './TopicTestCard';
import {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getStudyPlanTopics,
} from '../../../redux/action/Dashboard';
import {
  setTopicTestState,
  getTopicTestName,
} from '../../../redux/action/Practice';
import { routePaths } from '../../../routes/RoutePath';
import { QS_PROPS } from '../../../constant/Variables';
import QueryString from 'qs';
import PageLoader from '../../../utils/components/PageLoader';
import DailogComponent from './DialogComponent';
class Topic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      questionId: null,
    };
  }

  componentDidMount = () => {
    window.scroll(0, 0);
    const {
      topicValue,
      subjectValue,
      conceptValue,
      difficultyLevel,
      attemptStatus,
    } = this.props.topicTestState;
    this.props.getStudyPlanSubjects(response => {
      if (response.success) {
        this.props.getStudyPlanConcepts(subjectValue, conceptResponse => {
          if (conceptResponse.success) {
            this.props.getStudyPlanTopics(
              conceptValue,
              topicResponse => {
                if (topicResponse.success) {
                  this.props.getTopicTestName(
                    topicValue,
                    conceptValue,
                    subjectValue,
                    difficultyLevel,
                    attemptStatus,
                    () => {
                      this.setState({ loading: false });
                    }
                  );
                }
              },
              subjectValue
            );
          }
        });
      }
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { difficultyLevel, attemptStatus, conceptValue, subjectValue } =
      this.props.topicTestState;
    switch (name) {
      case 'subjectValue': {
        this.props.getStudyPlanConcepts(value, response => {
          if (response.success) {
            this.props.getStudyPlanTopics(
              value,
              topicResponse => {
                if (topicResponse.success) {
                  this.props.getTopicTestName(
                    'all',
                    'all',
                    value,
                    difficultyLevel,
                    attemptStatus,
                    topicTestResponse => {
                      if (topicTestResponse.success) {
                        let obj = {
                          ...this.props.topicTestState,
                          topicValue: 'all',
                          conceptValue: 'all',
                          subjectValue: value,
                        };
                        this.props.setTopicTestState(obj);
                      }
                    }
                  );
                }
              },
              value
            );
          }
        });
        break;
      }
      case 'conceptValue': {
        this.props.getStudyPlanTopics(
          value,
          response => {
            if (response.success) {
              this.props.getTopicTestName(
                'all',
                value,
                subjectValue,
                difficultyLevel,
                attemptStatus,
                topicTestResponse => {
                  if (topicTestResponse.success) {
                    let obj = {
                      ...this.props.topicTestState,
                      topicValue: 'all',
                      conceptValue: value,
                    };
                    this.props.setTopicTestState(obj);
                  }
                }
              );
            }
          },
          subjectValue
        );
        break;
      }
      case 'topicValue': {
        this.props.getTopicTestName(
          value,
          conceptValue,
          subjectValue,
          difficultyLevel,
          attemptStatus,
          response => {
            if (response.success) {
              let obj = {
                ...this.props.topicTestState,
                topicValue: value,
              };
              this.props.setTopicTestState(obj);
            }
          }
        );
        break;
      }
      default:
        break;
    }
  };

  handleAttemptStatus = event => {
    const { value } = event.target;
    const { topicValue, conceptValue, difficultyLevel, subjectValue } =
      this.props.topicTestState;
    this.props.getTopicTestName(
      topicValue,
      conceptValue,
      subjectValue,
      difficultyLevel,
      value,
      response => {
        if (response.success) {
          let obj = {
            ...this.props.topicTestState,
            attemptStatus: value,
          };
          this.props.setTopicTestState(obj);
        }
      }
    );
  };

  handleDifficultyChange = event => {
    const { name } = event.target;
    const {
      topicValue,
      conceptValue,
      difficultyLevel,
      attemptStatus,
      subjectValue,
    } = this.props.topicTestState;
    let arr = [...difficultyLevel];
    let index = arr.indexOf(name);

    if (index > -1) arr.splice(index, 1);
    else arr.push(name);
    this.props.getTopicTestName(
      topicValue,
      conceptValue,
      subjectValue,
      arr,
      attemptStatus,
      response => {
        if (response.success) {
          let obj = {
            ...this.props.topicTestState,
            difficultyLevel: arr,
          };
          this.props.setTopicTestState(obj);
        }
      }
    );
  };

  handleButtonClick = (id, status, allowHard) => {
    console.log(id, allowHard, 'fghjk');
    if (allowHard === true) {
      this.setState({
        questionId: id,
      });
      window.localStorage.setItem('testPage', 'practiseTopicTest');
      this.props.history.push(
        `${routePaths.dashboard.instruction}?testQuestionId=${id}&topicTest=Topictest`
      );
    } else {
      this.setState({
        ...this.state,
        open: true,
        questionId: id,
      });
    }
  };

  handlePopUpClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  getTopicTestData = res => {
    if (res && res.success) return res.data || [];
    else return [];
  };

  handleReportClick = testExecutionId => {
    window.localStorage.setItem('testPage', 'practiseTopicTest');
    this.props.history.push(
      `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}&isRetaken=true`
    );
  };

  handleButtonClick1 = () => {
    let Id = this.state.questionId;
    window.localStorage.setItem('testPage', 'practiseTopicTest');
    console.log(Id, 'fghjk');
    this.props.history.push(
      `${routePaths.dashboard.instruction}?testQuestionId=${Id}&topicTest=Topictest`
    );
  };

  render() {
    const {
      subjectValue,
      conceptValue,
      topicValue,
      attemptStatus,
      difficultyLevel,
    } = this.props.topicTestState;
    const { data: subjectData } = this.props.studyPlanSubjects;
    const { data: conceptData } = this.props.studyPlanConcepts;
    const { data: topicData } = this.props.studyPlanTopics;
    const { topicTestName } = this.props;
    const testData = this.getTopicTestData(topicTestName);
    if (this.state.loading) {
      return <PageLoader />;
    } else {
      // if (testData && testData.length !== 0)
      return (
        <>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Header
                  subjectValue={subjectValue}
                  subjectData={subjectData}
                  conceptValue={conceptValue}
                  conceptData={conceptData}
                  topicValue={topicValue}
                  topicData={topicData}
                  attemptStatus={attemptStatus}
                  difficultyLevel={difficultyLevel}
                  handleAttemptStatus={this.handleAttemptStatus}
                  handleChange={this.handleChange}
                  handleDifficultyChange={this.handleDifficultyChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TopicTestCard
                  testData={testData}
                  handleButtonClick={this.handleButtonClick}
                  handleReportClick={this.handleReportClick}
                />
              </Grid>
            </Grid>
          </Container>

          <DailogComponent
            open={this.state.open}
            handlePopUpClose={this.handlePopUpClose}
            handleButtonClick1={this.handleButtonClick1}
          />
        </>
      );
      // else return <PageLoader />;
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state.DashboardQuestionBankReducer,
    ...state.PracticeReducer,
  };
};

export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getStudyPlanTopics,
  getTopicTestName,
  setTopicTestState,
})(withTranslation()(Topic));
