import { Grid, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { listItem } from '../../../../../assets/css/dashboard/viewmore/StrengthStyles';
import {
  Container,
  DownloadButton,
  ErrorTitle,
  FlexView,
  Header,
  InsideBox,
  ReportContainer,
  ReportTime,
  ReportTitle,
  StyledSelect,
  SubTitle,
  Title,
  ActionButton,
  Card1,
  ExcelButton,
} from '../../../../../assets/css/GreReportStyledComponent';
import { DROP_DOWN_PROPS, QS_PROPS } from '../../../../../constant/Variables';
import ErrorLog from '../greReport/ErrorLog';
import InsightCard from './InsightCard';
import {
  getGreOgReport,
  getGreAttemptedTests,
  getGreOgOldReport,
  getExcel,
} from '../../../../../redux/action/OfficialGuide';
import { routePaths } from '../../../../../routes/RoutePath';
import { createPdfFromHtml } from './GrePdfMaker';
import PageLoader from '../../../../../utils/components/PageLoader';
import { textToDownloadFile } from '../../../../../utils/methods/Helper';
class GreReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: '',
      isOgPractiseTestCompleted: false,
      attemptValue: null,
      loading: true,
      Data: {
        attemptTest: 'Attempted test Reports',
        reportTitle: 'Report Date : ',
        subTitle: {
          title1: 'Test Summary',
          title2: 'Error Log',
        },
      },
    };
  }

  componentDidMount = () => {
    window.scroll(0, 0);
    const { testExecutionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.props.getGreOgReport(testExecutionId, response => {
      let userProduct = JSON.parse(localStorage.getItem('userProduct'));
    userProduct.testExecutionId = testExecutionId;
    localStorage.setItem('userProduct', JSON.stringify(userProduct));
      this.setState({
        report: response.data,
        isOgPractiseTestCompleted: true,
        attemptValue: testExecutionId,
        loading: false,
      });
    });
    this.props.getGreAttemptedTests(() => {});
  };

  handleAttemptStatus = event => {
    const { value } = event.target;
    const { test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.props.getGreOgOldReport(value, response => {
      let userProduct = JSON.parse(localStorage.getItem('userProduct'));
    userProduct.testExecutionId = value;
    localStorage.setItem('userProduct', JSON.stringify(userProduct));
      this.props.history.replace(
        `${routePaths.dashboard.greOgReport}?testExecutionId=${value}&test_type=${test_type}&og_course=${og_course}`
      );
      this.setState({
        ...this.state,
        attemptValue: value,
        report: response.data,
      });
    });
  };

  handleClick = () => {
    this.props.history.push(routePaths.dashboard.guide);
  };

  handleDownload = () => {
    var node = window.document.getElementById(
      'Gre_og_practise_test_download_container'
    );
    var selectorNode = node.parentNode.querySelector('.study__plan__button');
    if (selectorNode) selectorNode.remove();
    createPdfFromHtml(node, 'Gre og practise test Report.pdf');
  };
  handleExcelDownload = () => {
    const { testExecutionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.props.getExcel(testExecutionId, res => {
      textToDownloadFile(res.data, 'Gre og practise test Report', 'xls');
    });
  };

  render() {
    const { t } = this.props;
    const { report, Data, attemptValue } = this.state;
    const isDownloadReport = this.state.isOgPractiseTestCompleted;
    const { data: testData } = this.props.test;
    if (this.state.loading) {
      return <PageLoader />;
    } else {
      return (
        <>
          <Container id={'Gre_og_practise_test_download_container'}>
            <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Header>
                    <Title>{report.title}</Title>
                    <FlexView>
                      <ReportTitle>{Data.attemptTest}</ReportTitle>
                      <ThemeProvider theme={listItem}>
                        <StyledSelect
                          onChange={this.handleAttemptStatus}
                          name={'attemptValue'}
                          value={attemptValue}
                          disableUnderline
                          MenuProps={DROP_DOWN_PROPS}
                          IconComponent={ExpandMore}
                        >
                          {testData &&
                            testData.map(item => {
                              return (
                                <MenuItem value={item.testExecutionId}>
                                  {item.testQuestionsSetName}
                                </MenuItem>
                              );
                            })}
                        </StyledSelect>
                      </ThemeProvider>
                    </FlexView>
                  </Header>
                  <ReportTime>
                    {Data.reportTitle} {report.reportedDateStr}
                  </ReportTime>
                  <FlexView>
                    {isDownloadReport && (
                      <DownloadButton onClick={this.handleDownload}>
                        {t('Download Pdf Report')}
                      </DownloadButton>
                    )}
                    {isDownloadReport && (
                      <ExcelButton onClick={this.handleExcelDownload}>
                        {t('Download Excel Report')}
                      </ExcelButton>
                    )}
                    <ActionButton onClick={this.handleClick}>
                      {t('Done')}
                    </ActionButton>
                  </FlexView>
                </Grid>

                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <ReportContainer>
                      <InsideBox>
                        <Card1>
                          <SubTitle>{Data.subTitle.title1}</SubTitle>
                        </Card1>
                      </InsideBox>
                    </ReportContainer>

                    {this.state.report && (
                      <InsightCard item={this.state.report} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <ErrorTitle>{Data.subTitle.title2}</ErrorTitle>
                    {this.state.report && (
                      <ErrorLog tableData={this.state.report} />
                    )}
                  </Grid>
                </>
              </>
            </Grid>
          </Container>
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state.OfficialGuideReducer,
  };
};

export default connect(mapStateToProps, {
  getGreOgReport,
  getGreAttemptedTests,
  getGreOgOldReport,
  getExcel,
})(withTranslation()(GreReport));
