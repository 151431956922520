/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Badge, Box, ThemeProvider } from '@material-ui/core';
import { Circle } from 'rc-progress';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GreenTick, GreyTick } from '../../../../assets/css/StyledComponent';
import {
  CircleHead,
  CircleText,
  dotTheme,
} from '../../../../assets/css/Styles';
import Dot from '../../../../assets/images/dot.png';
import { routePaths } from '../../../../routes/RoutePath';
import * as track from '../../../../mixpanel/Events';
class Progress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      text: 'Completed',
    };
  }

  timer = null;
  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        progress: prevState.progress >= 100 ? 0 : prevState.progress + 10,
      }));
    }, 800);
    track.numberOfSectionFinished();
  }

  componentDidUpdate(previousState) {
    if (this.state.progress === 100) {
      clearInterval(this.timer);
      // this.setState({ text: 'Submitted' });
      setTimeout(() => {
        this.props.history.push(routePaths.report + '?page=insights');
      }, 3000);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Box position="relative" display="inline-flex">
          <ThemeProvider theme={dotTheme}>
            <Badge
              // badgeContent={<img src={Dot} alt="" />}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Circle
                style={{
                  width: '262',
                  height: '262',
                  transform: 'rotate(-45deg)',
                }}
                percent={this.state.progress}
                trailWidth="5"
                strokeWidth="5"
                strokeColor="#37E288"
                gapDegree="20"
              />
            </Badge>
          </ThemeProvider>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {this.state.progress === 100 ? <GreenTick /> : <GreyTick />}
          </Box>
        </Box>
        <Box>
          <CircleHead>
            {t('Calibration Test Successfully')}
            {this.state.progress === 100 ? ' Submitted' : ' Completed'}
          </CircleHead>
          <CircleText style={{ paddingTop: '30px' }}>
            {t('Taking you to your results')}
          </CircleText>
        </Box>
      </Box>
    );
  }
}
export default withTranslation()(Progress);
