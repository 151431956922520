/**
 * (c) CareerLabs. All rights reserved.
 **/
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import QueryString from 'qs';
import React from 'react';
import {
  Button,
  Filler,
  Footer as Main,
} from '../../../../../assets/css/GmatStyles';
import { QS_PROPS } from '../../../../../constant/Variables';
import { routePaths } from '../../../../../routes/RoutePath';

function Footer({
  location,
  handlePrevious,
  handleNext,
  handlePause,
  section: sectionData,
}) {
  const { pathname: pathName } = location;
  const { section, resume } = QueryString.parse(location.search, QS_PROPS);

  const renderPrevious = () => {
    return !(
      pathName === routePaths.gmat.test ||
      pathName === routePaths.gmat.endSection ||
      !!(
        routePaths.gmat.instruction &&
        section &&
        !_.isEmpty(sectionData) &&
        sectionData.data.currentSection > 1
      )
    );
  };

  const renderPauseButton = () => {
    return !(
      pathName === routePaths.gmat.instruction ||
      pathName === routePaths.gmat.section
    );
  };

  return (
    <Main>
      {renderPauseButton() && (
        <Button variant="contained" color="primary" onClick={handlePause}>
          <Typography variant="body1">Pause Exam</Typography>
        </Button>
      )}
      <Filler />
      {renderPrevious() && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={handlePrevious}
        >
          <Typography variant="body1">Previous</Typography>
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        className="border_right"
        onClick={handleNext}
      >
        <Typography variant="body1">Next</Typography>
      </Button>
    </Main>
  );
}

export default Footer;
