/**
 * (c) CareerLabs. All rights reserved.
 **/

import { ClearAll } from '@material-ui/icons';
import React from 'react';
import {
  DiagnosticButton,
  CalibrationButton,
  DiagnosticShadowCard,
  Diagnostic,
  FlexView,
  LeftIcon,
  RightIcon,
  WelContainer,
  WarningAlert,
} from '../../../../assets/css/StyledDashboardComponent';
import boost from '../../../../assets/icons/AeroplaneIcon.svg';
// import CheckAll from '../../../../assets/icons/CheckAll.svg';
import CardIcon from '../../../../assets/icons/CalibrationIcon.svg';

const CalibrationCard = ({
  description,
  subDescription,
  subDescription2,
  buttonText,
  imageSrc,
  handleCalibration,
  continueTopicId,
}) => {
  return (
    <DiagnosticShadowCard>
      <WelContainer>
        <FlexView>
          <LeftIcon src={CardIcon} alt="Card Icon" />
          {/* <DiagnosticTitle> */}
          <h3>{description}</h3>
          <Diagnostic>
            {' '}
            <WarningAlert sx={{ background: 'transparent' }} severity="warning">
              <span style={{ fontSize: '26px', color: 'red' }}>
                {subDescription2}
              </span>
              <span style={{ padding: '10px', fontSize: '26px', color: 'red' }}>
                to
              </span>
              <span style={{ fontSize: '26px', color: 'red' }}>
                {subDescription}
              </span>
            </WarningAlert>
          </Diagnostic>

          {/* </DiagnosticTitle> */}
        </FlexView>
        {buttonText && (
          <FlexView>
            {/* <RightIcon src={boost} alt="Boost Icon" /> */}

            <CalibrationButton onClick={handleCalibration}>
              {buttonText}
            </CalibrationButton>
          </FlexView>
        )}
      </WelContainer>
    </DiagnosticShadowCard>
  );
};

export default CalibrationCard;
