/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, Box, Divider } from '@material-ui/core';
import {
  Passage as passage,
  TextBox,
  TextBoxField,
  TextUnitField,
  TextWrapper,
  TextBoxValueShow,
  DividerLine,
  Div,
} from '../../../../assets/css/test/TestComponent';
import SingleSelect from './SingleSelect';
import Bundle from './Bundle';
import Latex from '../../../../utils/LatexViewer';
import React from 'react';
const Passage = ({
  para,
  question,
  choices,
  bundle,
  selectedChoice,
  onSelect,
  subjective,
  onChange,
  bundleLength,
  answer,
  description,
  imgUrl,
  isMulti,
  optionalType,
  fraction,
  fraction1,
  fraction2,
  onChangeCapture,
  isHaveDescription,
  unit,
}) => {
  const { Para, Question } = passage;
  const style = {
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
  };
  const { hide, show } = style;

  return (
    <Grid id="test-container" container>
      <Grid
        item
        md={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? 12
            : 6
        }
        id="inside-test-container"
      >
        <Box display={'flex'} className={'overflow-scroll'} height={'inherit'}>
          <Box flex={1} className={'question-left-container'}>
            {bundle && isHaveDescription === false && (
              <>
                <Question>
                  <Latex math={para} />
                </Question>
                <Bundle
                  bundleLength={bundleLength}
                  choices={choices}
                  onChange={onChange}
                  selectedChoice={selectedChoice}
                  question={para}
                  isHaveDescription={isHaveDescription}
                />
              </>
            )}

            <Latex math={description} />
            {subjective ? (
              // optionalType === 'NUMERICAL_ENTRY' &&
              isHaveDescription === false ? (
                <React.Fragment>
                  <Box>
                    <Div>
                      <Question>
                        <Latex math={question} />
                      </Question>
                    </Div>
                  </Box>
                  {optionalType === 'NUMERICAL_ENTRY' && (
                    <div style={{ display: 'flex', marginTop: '2rem' }}>
                      <TextBoxField
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={answer}
                        type={'number'}
                      />
                      {unit !== null ? (
                        <TextUnitField>{unit}</TextUnitField>
                      ) : null}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {fraction && isHaveDescription === false && (
              <>
                <Question>
                  <Latex math={question} />
                </Question>
                <div style={{ display: 'flex', marginTop: '2rem' }}>
                  <div>
                    <TextBoxValueShow
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={fraction1}
                      type={'number'}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      onChange={e => {
                        onChangeCapture(e.target.value);
                      }}
                      value={fraction2}
                      type={'number'}
                    />
                  </div>
                  {unit !== null ? <TextUnitField>{unit}</TextUnitField> : null}
                </div>
              </>
            )}
            <Box>
              {imgUrl && (
                <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
              )}
            </Box>
          </Box>
          {(subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false) ? (
            ''
          ) : (
            <Box>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        id="inside-test-container"
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? hide
            : show
        }
      >
        <Box className={'overflow-scroll'} id="inside-test-container">
          {bundle ? (
            <Bundle
              bundleLength={bundleLength}
              choices={choices}
              onChange={onChange}
              selectedChoice={selectedChoice}
              question={para}
              isHaveDescription={isHaveDescription}
            />
          ) : subjective ? (
            optionalType === 'NUMERICAL_ENTRY' ? (
              <div>
                <TextWrapper style={{ marginTop: '0px', paddingTop: '0px' }}>
                  {subjective && isHaveDescription === true && (
                    <Question>
                      <Latex math={question} />
                    </Question>
                  )}
                  <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <TextBoxField
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={answer}
                      type={'number'}
                    />
                    {unit !== null ? (
                      <TextUnitField>{unit}</TextUnitField>
                    ) : null}
                  </div>
                </TextWrapper>
              </div>
            ) : (
              <>
                <Box>
                  <Div>
                    {isHaveDescription === true && (
                      <Question>
                        <Latex math={question} />
                      </Question>
                    )}

                    <Box>
                      {imgUrl && (
                        <img
                          src={imgUrl}
                          alt={''}
                          width={'100%'}
                          height={'100%'}
                        />
                      )}
                    </Box>
                    {/* <OptionContainer>{renderChoices()}</OptionContainer> */}
                  </Div>
                </Box>
                {optionalType === null && (
                  <TextBox
                    type={'text'}
                    placeholder={'Enter your answer here'}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                    value={answer}
                  />
                )}
              </>
            )
          ) : fraction ? (
            <React.Fragment>
              <TextWrapper style={{ marginTop: '0px', paddingTop: '0px' }}>
                {fraction && isHaveDescription === true && (
                  <Question>
                    <Latex math={question} />
                  </Question>
                )}
                <div style={{ display: 'flex', marginTop: '2rem' }}>
                  <div>
                    <TextBoxValueShow
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={fraction1}
                      type={'number'}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      onChange={e => {
                        onChangeCapture(e.target.value);
                      }}
                      value={fraction2}
                      type={'number'}
                    />
                  </div>
                  {unit !== null ? <TextUnitField>{unit}</TextUnitField> : null}
                </div>
              </TextWrapper>
            </React.Fragment>
          ) : (
            <SingleSelect
              question={question}
              options={choices}
              selectedChoice={selectedChoice}
              onSelect={onSelect}
              isMulti={isMulti}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Passage;
