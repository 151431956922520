/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Collapse, Grid, IconButton } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Bold,
  BottomContainer,
  Button,
  Card,
  Children,
  Container,
  DownloadButton,
  Header,
  ListHeader,
  ListItemContainer,
  ListItemHeader,
  ListItemTitle,
  ReportTime,
  Section,
  TabContainer,
  TabTitle,
  Title,
} from '../../../../assets/css/report/Report';
import '../../../../assets/css/report/Report.css';
import Down from '../../../../assets/icons/down.svg';
import Up from '../../../../assets/icons/up.svg';
import { QS_PROPS } from '../../../../constant/Variables';
import * as track from '../../../../mixpanel/Events';
import { getInsights } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { setFieldValue } from '../../../../redux/action/DashboardLanding';
import {
  personalizedStudyPlan,
  getCleverTabCommonProperties,
} from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import PageLoader from '../../../../utils/components/PageLoader';
import ReportCard from './ReportCard';
import Review from './Review';
import { GetApp } from '@material-ui/icons';
import { createPdfFromHtml } from '../../../../utils/PdfMaker';
import { EVENTS } from '../../../../clevertap/Events';
import clevertap from 'clevertap-web-sdk';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: [],
      data: null,
      variantIndex: 0,
      isLoading: false,
      variantName: '',
      spentTime: new Date(),
      isCalibrationCompleted: false,
    };
  }

  componentDidMount() {
    this.props.getInsights(response => {
      if (response.success) {
        this.setState({
          data: response.data,
          variantName: response.data.subjects[0].name,
          isCalibrationCompleted: true,
        });
        clevertap.event.push(EVENTS.LMSUserCompletesTheCalibrationTest, {
          Source:
            response?.data?.isCalibrationSkipped === false
              ? 'During onboarding'
              : 'Dashboard Re-taking CTA',
        });
      }
    });

    this.props.getCleverTabCommonProperties(res => {
      if (res.success) {
        let common_properties = JSON.parse(
          window.localStorage.getItem('user_common_properties') || '{}'
        );
        clevertap.onUserLogin.push({
          Site: {
            ...common_properties,
          },
        });
      }
    });

    track.reportFirstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test type': 'Calibration',
    });
  }

  componentWillUnmount() {
    let timeSpend = new Date() - this.state.spentTime;
    var timestamp = timeSpend / 1000;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var second = timestamp % 60;
    function pad(d) {
      return d < 10 ? '0' + d.toString() : d.toString();
    }
    track.reportSessionTime({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Total Time Spend': `${pad(hours)} : ${pad(minutes)} : ${pad(second)}`,
      'Test type': 'Calibration',
    });

    track.reportFirstAction({
      'Next Screen': 'Exit',
    });
  }

  handleCollapse = obj => {
    if (this.state[obj.category + obj.subtitle + obj.index])
      this.setState({
        [obj.category + obj.subtitle + obj.index]:
          !this.state[obj.category + obj.subtitle + obj.index],
      });
    else this.setState({ [obj.category + obj.subtitle + obj.index]: true });
  };

  handleDownload = () => {
    var node = window.document.getElementById('calibration_download_container');
    var selectorNode = node.parentNode.querySelector('.study__plan__button');
    if (selectorNode) selectorNode.remove();
    createPdfFromHtml(node, 'Calibration_Test_Report.pdf');
  };

  render() {
    const { page } = QueryString.parse(this.props.location.search, QS_PROPS);
    if (this.state.data !== null) {
      var date = new Date(this.state.data.reportDate);
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const { t } = this.props;

      const isDownloadReport = this.state.isCalibrationCompleted;

      const Sheet = () => {
        return (
          <Container>
            <Header>
              <Title>{t('Calibration Test Report')}</Title>
              <ReportTime>
                {t('Report Date')}
                {': '}
                {`${date.getDate()} ${
                  monthNames[date.getMonth()]
                } ${date.getFullYear()} `}
              </ReportTime>
            </Header>
            <Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TabContainer>
                    <TabTitle>
                      <NavLink
                        exact
                        to={'/calibration/report?page=insights'}
                        className={'nav__link__report'}
                        activeClassName={
                          page === 'insights' ? 'nav__link__report__active' : ''
                        }
                        onClick={() =>
                          track.reportFirstAction({
                            'Next Screen': 'Insights',
                          })
                        }
                      >
                        {t('Insights')}
                      </NavLink>
                      <NavLink
                        exact
                        to={'/calibration/report?page=review'}
                        className={'nav__link__report'}
                        activeClassName={
                          page === 'review' ? 'nav__link__report__active' : ''
                        }
                        onClick={() =>
                          track.reportFirstAction({
                            'Next Screen': 'Review',
                          })
                        }
                      >
                        {t('Review')}
                      </NavLink>
                    </TabTitle>
                    <div className="study__plan__button">
                      {isDownloadReport && (
                        <DownloadButton
                          color={'primary'}
                          variant={'contained'}
                          size={'large'}
                          startIcon={<GetApp fontSize={'large'} />}
                          onClick={this.handleDownload}
                        >
                          {'Download Report'}
                        </DownloadButton>
                      )}
                      <Button
                        onClick={() => {
                          track.reportFirstAction({
                            'Next Screen': 'Study Plan Creation',
                          });
                          if (
                            JSON.parse(localStorage.getItem('userProduct'))
                              .isStudyPlanCreated
                          ) {
                            this.props.history.push(routePaths.dashboard.home);
                          } else {
                            this.setState({ isLoading: true });
                            this.props.personalizedStudyPlan(
                              JSON.parse(localStorage.getItem('userProduct'))
                                .id,
                              res => {
                                this.setState({ isLoading: false });
                                this.props.getCleverTabCommonProperties(res => {
                                  if (res.success) {
                                    clevertap.event.push(
                                      EVENTS.LMSUserLandsOnTheDashboard,
                                      {
                                        'Page source':
                                          'Calibration test report page',
                                      }
                                    );

                                    //clearing the reducer value for 'calibrationStatus' field
                                    this.props.setFieldValue(
                                      'calibrationStatus',
                                      null
                                    );

                                    this.props.getCourses(res => {
                                      if (res.success) {
                                        localStorage.setItem(
                                          'userProduct',
                                          JSON.stringify(
                                            ...res.data.filter(
                                              ({ id }) =>
                                                id ===
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    'userProduct'
                                                  )
                                                ).id
                                            )
                                          )
                                        );

                                        this.props.history.push(
                                          routePaths.dashboard.home
                                        );
                                      }
                                    });
                                  }
                                });
                              }
                            );
                          }
                        }}
                        loading={this.state.isLoading}
                        disabled={this.state.isLoading}
                        variant="contained"
                        color="primary"
                      >
                        {t(
                          JSON.parse(localStorage.getItem('userProduct'))
                            .isStudyPlanCreated
                            ? 'Go to Dashboard'
                            : 'Get Personalized Study Plan'
                        )}
                      </Button>
                    </div>
                  </TabContainer>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -5 }}>
                  {page === 'insights' ? (
                    <ReportCard data={this.state.data} percentageLabel={true} />
                  ) : page === 'review' ? (
                    <Review />
                  ) : null}
                </Grid>
              </Grid>
            </Section>
            {page === 'insights' && (
              <>
                <Section>
                  <Grid container>
                    <Grid item md={12}>
                      <Box
                        textAlign={'center'}
                        display={'flex'}
                        justifyContent="center"
                        alignItems={'center'}
                      >
                        {this.state.data.subjects.map(item => (
                          <div
                            className={
                              this.state.variantName === item.name
                                ? 'nav__link__plain__active nav__link__plain'
                                : 'nav__link__plain'
                            }
                            onClick={() => {
                              track.reportFirstAction({
                                'Next Screen': item.name,
                              });
                              this.setState({ variantName: item.name });
                            }}
                          >
                            {t(item.name)}
                          </div>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Box borderTop="2px solid #1093FF">
                        <ReportCard
                          data={this.state.data.subjects.find(
                            item => item.name === this.state.variantName
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Section>

                <Section>
                  <BottomContainer>
                    <Grid container spacing={3}>
                      {this.state.data.subjects
                        .find(item => item.name === this.state.variantName)
                        .pie.points.sort((a, b) => {
                          if (a.title < b.title) return -1;
                          if (a.title > b.title) return 1;
                          return 0;
                        })
                        .map(item => {
                          return (
                            <Grid item md={6}>
                              <Card>
                                <ListItemHeader>
                                  {t(item.title)}
                                  <Bold fw={600} pl={'10px'}>
                                    {item.count}
                                  </Bold>
                                  <Bold fw={400}>/{item.total}</Bold>
                                </ListItemHeader>
                                <ListItemContainer>
                                  {item.subTitle.map(
                                    ({ concept, topic }, index) => {
                                      return (
                                        <>
                                          <ListHeader>
                                            <ListItemTitle flex={1}>
                                              {concept} ({topic.length})
                                            </ListItemTitle>
                                            <IconButton
                                              onClick={() =>
                                                this.handleCollapse({
                                                  category: item.title,
                                                  subtitle: concept,
                                                  index: index,
                                                })
                                              }
                                            >
                                              {this.state[
                                                item.title + concept + index
                                              ] ? (
                                                <img src={Down} alt={''} />
                                              ) : (
                                                <img src={Up} alt={''} />
                                              )}
                                            </IconButton>
                                          </ListHeader>
                                          <Collapse
                                            in={
                                              this.state[
                                                item.title + concept + index
                                              ]
                                            }
                                          >
                                            {topic.map(each => {
                                              return (
                                                <Children htmlFor="">
                                                  {each}
                                                </Children>
                                              );
                                            })}
                                          </Collapse>
                                        </>
                                      );
                                    }
                                  )}
                                </ListItemContainer>
                              </Card>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </BottomContainer>
                </Section>
              </>
            )}
          </Container>
        );
      };

      return (
        <div>
          <Sheet />
          <div style={{ position: 'fixed', top: '200vh' }}>
            <div id={'calibration_download_container'}>
              <Sheet />
            </div>
          </div>
        </div>
      );
    } else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    report: state.CourseReducer.courseInsights,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, {
  getInsights,
  personalizedStudyPlan,
  getCourses,
  getCleverTabCommonProperties,
  setFieldValue,
})(withTranslation()(Index));
