import React, { Component } from 'react';
import {
  MailContainer,
  MailLinear,
  MailQuestion,
  MailTextTitle,
} from '../../../assets/css/StyledComponent';
import { Box, Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import MailVideo from '../../../assets/images/mailVideo.png';
import MailImg from '../../../assets/images/mail.png';
import { connect } from 'react-redux';
import { supportEmail } from '../../../constant/Variables';
import * as track from '../../../mixpanel/Events';
import { sendSupportEmail } from '../../../redux/action/Course';
import DialogComponent from '../support/DialogComponent';
import { withTranslation } from 'react-i18next';
import Player from '../../../utils/Player';
import VideoPopup from './video';
import { routePaths } from '../../../routes/RoutePath';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// function Mail(props) {
class Mail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogState: false,
      dialogType: '',
      subject: '',
      subjectError: '',
      body: '',
      bodyError: '',
      firstImage: false,
      firstImage1: false,
    };
  }

  handleClick = () => {
    this.setState({
      dialogState: true,
      dialogType: 'form',
    });
  };

  handleClose = () => {
    this.setState({
      dialogState: false,
      dialogType: '',
      subject: '',
      subjectError: '',
      body: '',
      bodyError: '',
    });
  };

  handleTextChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: '',
    });
  };

  handleSend = () => {
    const obj = {
      to: supportEmail,
      subject: this.state.subject,
      body: this.state.body,
    };
    if (this.state.subject.length === 0 && this.state.body.length === 0) {
      this.setState({
        subjectError: 'Please fill the required field',
        bodyError: 'Please fill the required field',
      });
    } else if (this.state.subject.length === 0) {
      this.setState({ subjectError: 'Please fill the required field' });
    } else if (this.state.body.length === 0) {
      this.setState({ bodyError: 'Please fill the required field' });
    } else {
      this.props.sendSupportEmail(obj, response => {});
      this.setState({ dialogType: 'confirmation' });
    }
  };
  ImageClick = () => {
    this.setState({ firstImage: true });
  };
  ImageClick1 = () => {
    this.setState({ firstImage1: true });
  };
  ImageClickClose = () => {
    this.setState({ firstImage: false });
  };
  ImageClickClose1 = () => {
    this.setState({ firstImage1: false });
  };
  componentDidMount() {
    track.supportPageLanding();
  }

  render() {
    const breadcrumbs = [
      <Link>
        <Typography
          color="text.primary"
          style={{ fontWeight: '600', fontSize: '1vw', cursor: 'pointer' }}
          onClick={() =>
            this.props.history.push(`${routePaths.dashboard.home}`)
          }
        >
          {'Dashboard'}
        </Typography>
      </Link>,
      <Typography
        color="text.primary"
        style={{ fontWeight: '600', fontSize: '1vw' }}
      >
        {'Learn Vocabulary'}
      </Typography>,
    ];
    const {
      handleClick,
      handleClose,
      handleTextChange,
      handleSend,
      ImageClick,
      ImageClickClose,
      ImageClick1,
      ImageClickClose1,
    } = this;
    const {
      dialogState,
      dialogType,
      subject,
      body,
      subjectError,
      bodyError,
      firstImage,
      firstImage1,
    } = this.state;
    const { t } = this.props;

    return (
      <MailContainer>
        <div
          style={{
            textAlign: 'Center',
            fontWeight: '600',
            fontSize: '2.2vw',
            color: '#052a4e',
          }}
        >
          Learn Vocabulary Innovatively
        </div>
        <Breadcrumbs
          style={{ position: 'absolute', top: '5vh' }}
          separator={<NavigateNextIcon fontSize="2rem" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Grid container style={{ marginTop: '4vh' }}>
          <Grid item xs={4} style={{ padding: '2vh 3vh 1vh 0vh' }}>
            <MailTextTitle>Welcome to the course</MailTextTitle>
            <Box sx={{ mt: 4 }}>
              <img
                src={MailVideo}
                width={'100%'}
                onClick={ImageClick}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <img
                src={MailVideo}
                width={'100%'}
                onClick={ImageClick1}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <VideoPopup
              firstImage={firstImage}
              ImageClickClose={ImageClickClose}
              firstImage1={firstImage1}
              ImageClickClose1={ImageClickClose1}
            />
          </Grid>
          <Grid item xs={8} style={{ padding: '2vh 0vh 1vh 3vh' }}>
            <MailLinear>
              <Grid spacing={4}>
                <MailQuestion>What you'll learn</MailQuestion>
                <ol
                  style={{
                    marginTop: '1vh',
                    lineHeight: '2.5vh',
                    color: '#0455A6',
                    fontWeight: '500',
                    fontSize: '1vw',
                    listStyle: 'disc',
                  }}
                >
                  <li>
                    Learn 500+ advanced English words using power learning tools
                  </li>
                  <li>
                    Learn contextual use of high-level English words with
                    examples
                  </li>
                  <li>Associate words using themes, etymology and stories</li>
                  <li>
                    Bolster your prep for GRE by building a formidable
                    vocabulary
                  </li>
                </ol>
                <Box style={{ marginTop: '1.5vw' }}>
                  <MailQuestion>Description</MailQuestion>
                  <div
                    style={{
                      marginTop: '1vh',
                      lineHeight: '2.5vh',
                      color: '#0455A6',
                      fontWeight: '500',
                      fontSize: '1vw',
                    }}
                  >
                    This course will take you through 500-ish high-impact
                    advanced level English words. We'll discuss their origins,
                    their associated roots and words that relate to them. This
                    effectively will expand your vocabulary by a few thousands
                    words, if done right!<br></br> Vocabulary building is
                    usually a drudgery, filled with context-less rote
                    memorisation of words from word lists. This is just plain
                    torture. I've designed this course to make learning words
                    fun, relevant and meaningful.<br></br> Every word discussed
                    will be in context of an overarching theme. This is further
                    supplemented with discussion about the word's various usage,
                    the kind of situations they can be used in and other words
                    that are related to it. This will give you a complete
                    understand of what each word means. The fact that it is
                    discussed in context of themes and word roots will make
                    remembering these words super easy.
                  </div>
                </Box>
                <Box
                  style={{
                    color: '#000',
                    fontSize: '1.2vw',
                    fontWeight: '700',
                    marginTop: '1vw',
                  }}
                >
                  This course will supercharge your vocabulary.
                </Box>
                <Box style={{ marginTop: '1vw' }}>
                  <MailQuestion>Contents of the Course</MailQuestion>
                  <ol
                    style={{
                      // marginTop: '1vh',
                      lineHeight: '2.5vh',
                      color: '#0455A6',
                      fontWeight: '500',
                      fontSize: '1vw',
                    }}
                    className="roman-list"
                    // type="I"
                  >
                    <li>
                      I. Over 500 High value words discussed through word roots
                      and themes.
                    </li>
                    <li>
                      II. Chapter-wise quizzes and activities to revise and test
                      your understanding.
                    </li>
                    <li>
                      III. Illustrated flashcards as part of additional content
                      to refer and review.
                    </li>
                  </ol>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    gap: '2vw',
                    alignItems: 'center',
                    marginTop: '2vw',
                  }}
                >
                  <div
                    style={{
                      fontSize: '1.3vw',
                      fontWeight: '500',
                      color: '#000',
                    }}
                  >
                    Drop a mail to get the full and free access to this course
                  </div>
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    // startIcon={<MailOutlineRoundedIcon />}
                    startIcon={<img src={MailImg} alt="Image" />}
                    style={{
                      textTransform: 'inherit',
                      fontSize: '1.1vw',
                      borderRadius: '50px',
                      fontWeight: '600',
                      minWidth: 'max-content',
                      padding: '8px 25px',
                    }}
                  >
                    Drop a mail
                  </Button>
                </Box>
              </Grid>
            </MailLinear>
          </Grid>
        </Grid>
        <DialogComponent
          dialogState={dialogState}
          type={dialogType}
          handleClose={handleClose}
          subject={subject}
          body={body}
          handleTextChange={handleTextChange}
          handleSend={handleSend}
          subjectError={subjectError}
          bodyError={bodyError}
          t={t}
        />
      </MailContainer>
    );
  }
}

const mapStateToProps = state => ({
  user: state.studentReducer.user,
});

export default connect(mapStateToProps, { sendSupportEmail })(
  withTranslation()(Mail)
);
