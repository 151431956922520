/**
 * (c) CareerLabs. All rights reserved.
 **/
import Cookies from 'js-cookie';
import { DEFAULT_URL } from '../../api/API';
import { GET, POST, PUT } from '../../axios/Axios';
import { decrypt } from '../../utils/Crypto';
import { PRACTICE } from '../Action';
import { catchError, errorHandler } from '../../utils/methods/Helper';

export const getTestType = callback => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/mistakeLog/type`,
      {},
      response => {
        dispatch({
          type: PRACTICE.getTestType,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getTopicsQuestions = (
  subjectId,
  conceptId,
  questionType,
  testType,
  order,
  productId,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
    const productIdi = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/subject/${subjectId}/mistakeLog`,
      {
        conceptId: conceptId === 'all' ? null : conceptId,
        questionType: questionType === 'All' ? null : questionType,
        type: testType,
        order: order,
        productId:productIdi,
      },
      response => {
        dispatch({
          type: PRACTICE.getTopicsQuestions,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const AddBookmarks = (bookmarkData, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/bookmarks`,
      bookmarkData,
      {},
      response => {
        dispatch({
          type: PRACTICE.addBookmarks,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getBookmarks = (bookmarkData, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/bookmarks?method=GET`,
      bookmarkData,
      {},
      response => {
        dispatch({
          type: PRACTICE.getBookmarks,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getConceptAndSubject = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/subjects`,
      {},
      response => {
        dispatch({
          type: PRACTICE.getConceptAndSubject,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getTopicsAndTasksAndQuestions = (
  conceptId,
  order,
  callback = () => {}
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/concept/${conceptId}/bookmarks?order=${order}`,
      {},
      response => {
        dispatch({
          type: PRACTICE.getTopicsAndTasksAndQuestions,
          payload: response.data,
        });
        callback(response.data.success);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const removeDemoBookmark = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}?page=demo`,
      {
        visitedPage: 'BOOKMARK',
      },
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const handleBookmarkState = obj => {
  return dispatch => {
    dispatch({
      type: PRACTICE.bookmarkState,
      payload: obj,
    });
  };
};

export const setMistakeLogState = obj => {
  return dispatch => {
    dispatch({
      type: PRACTICE.mistakeLogState,
      payload: obj,
    });
  };
};

export const getOfficialGuideDetails = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;
  return dispatch => {
    dispatch({ type: PRACTICE.loader });
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/OfficialGuidedetails`,
      null,
      response => {
        dispatch({
          type: PRACTICE.getOfficialGuideDetails,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
        dispatch(errorHandler(PRACTICE.getOfficialGuideDetails, error, false));
      }
    );
  };
};

export const getOfficialGuideTopicDetail = (topicId, taskId, loading) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId =
    localStorage.getItem('userProduct') !== null
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : null;

  return dispatch => {
    dispatch({ type: PRACTICE.loader, loading: loading });
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/officialGuide/tasks`,
      {
        taskId: taskId,
      },
      response => {
        dispatch({
          type: PRACTICE.getOfficialGuideTopicDetail,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
        dispatch(
          errorHandler(PRACTICE.getOfficialGuideTopicDetail, error, false)
        );
      }
    );
  };
};

export const getAllQuestionTypes = () => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/questiontype/filter`,
      {
        productId: productId,
      },
      response => {
        dispatch({
          type: PRACTICE.getAllQuestionTypes,
          payload: response.data,
        });
      },
      error => {
        dispatch(errorHandler(PRACTICE.getAllQuestionTypes, error, false));
      }
    );
  };
};

export const setSolveByPassageState = obj => {
  return dispatch => {
    dispatch({
      type: PRACTICE.setSolveByPassageState,
      payload: obj,
    });
  };
};

export const getPassageList = (topicId, conceptId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/passage/topic/${topicId}`,
      {
        conceptId: conceptId,
      },
      response => {
        dispatch({
          type: PRACTICE.getPassageList,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getPassageQuestions = (
  passageId,
  topicId,
  attemptStatus,
  difficultyLevel,
  conceptId,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/passage/${passageId}/topic/${topicId}/testQuestionSet`,
      {
        type: 'QUESTIONBANK',
        difficultyLevel: difficultyLevel?.toString(),
        status: attemptStatus,
        conceptId: conceptId,
      },
      response => {
        dispatch({
          type: PRACTICE.getPassageQuestions,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
        // callback(catchError(error));
      }
    );
  };
};

export const setFieldValue = (fieldName, fieldValue) => {
  return dispatch =>
    dispatch({
      type: PRACTICE.setFieldValue,
      fieldName,
      fieldValue,
    });
};

export const setTopicTestState = obj => {
  return dispatch => {
    dispatch({
      type: PRACTICE.setTopicTestState,
      payload: obj,
    });
  };
};

export const getTopicTestName = (
  topicId,
  conceptId,
  subjectId,
  difficultyLevel,
  attemptStatus,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/topicTestName`,
      {
        difficultyLevel: difficultyLevel?.toString() || null,
        status: attemptStatus === 'ALL' ? null : attemptStatus,
        conceptId: conceptId,
        subjectId: subjectId,
      },
      response => {
        callback(response.data);
        dispatch({
          type: PRACTICE.getTopicTestName,
          payload: response.data,
        });
      },
      error => {
        dispatch(errorHandler(PRACTICE.getTopicTestName, error, false));
        callback(catchError(error));
      }
    );
  };
};
