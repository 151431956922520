/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from '../../../assets/css/dashboard/CalendarComponents';
import { QS_PROPS } from '../../../constant/Variables';
import { getStudyPlanByDate } from '../../../redux/action/Dashboard';
import { removeWebinar } from '../../../redux/action/DashboardLanding';
import { routePaths } from '../../../routes/RoutePath';
import PageLoader from '../../../utils/components/PageLoader';
import Navigation from '../../../utils/studyPlans/Navigation';
import Body from './components/Body';
import CalendarHeader from './components/CalendarHeader';

export class DailyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDate: null,
      webinarChange: false,
    };
  }

  componentDidMount() {
    const { currentDate } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.setState({ currentDate });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.getStudyPlanByDate(this.state.currentDate);
    }
  }

  handleCalendarClick = () => {
    this.props.history.push(routePaths.dashboard.studyPlan);
  };

  handleTaskClick = (topicId, taskId) => {
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskId}`
    );
  };

  handleNextClick = () => {
    const { currentDate } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let date = this.state.currentDate;

    date = new Date(date);
    date.setDate(date.getDate() + 1);

    if (new Date(currentDate).getMonth() === date.getMonth()) {
      let dateString = date.toJSON().split('T')[0];
      this.setState({ currentDate: dateString });
    }
  };

  handlePreviousClick = () => {
    const { currentDate } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let date = this.state.currentDate;

    date = new Date(date);
    date.setDate(date.getDate() - 1);

    if (new Date(currentDate).getMonth() === date.getMonth()) {
      let dateString = date.toJSON().split('T')[0];
      this.setState({ currentDate: dateString });
    }
  };

  handleWebinarDeleted = () => {
    this.setState({ webinarChange: !this.state.webinarChange });
  };

  render() {
    if (this.props.studyPlan !== null) {
      const { studyPlan } = this.props;

      return (
        <Container>
          <Box pt={2}>
            <Navigation backPath={routePaths.dashboard.studyPlan} />
          </Box>
          <Box pt={2}>
            <CalendarHeader
              currentDate={this.state.currentDate}
              handleCalendarClick={this.handleCalendarClick}
              handleNextClick={this.handleNextClick}
              handlePreviousClick={this.handlePreviousClick}
              webinar={this.props.studyPlan}
              user={this.props.user}
              removeWebinar={this.props.removeWebinar}
              onWebinarDelete={this.handleWebinarDeleted}
            />
          </Box>
          <Body
            topics={studyPlan.topics}
            handleTaskClick={this.handleTaskClick}
          />
        </Container>
      );
    } else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    studyPlan: state.DashBoardReducer.studyPlanByDate,
    user: state.studentReducer.user,
  };
};

export default connect(mapStateToProps, { getStudyPlanByDate, removeWebinar })(
  withRouter(DailyCalendar)
);
