import React from 'react';

import { Box, Divider, Grid } from '@material-ui/core';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import LatexViewer from '../../../../utils/LatexViewer';
import { Question } from '../../../../assets/css/StyledComponent';
import { AnserFlex, Div } from '../../../../assets/css/test/TestComponent';
import ClariDropDown from '../../../../utils/components/ClariDropDown';

export default function DropDown(props) {
  const {
    question,
    options,
    choiceAnswers,
    isHaveDescription,
    description,
    imgUrl,
    handleDropDown,
    bundleSelectedChoice,
  } = props;

  console.log(options, 'optionss');
  const getOption = option => {
    return [{ text: 'Select', id: 'default', disabled: true }, ...option];
  };

  const math = ii => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(ii, 'text/html').body.textContent;
    return doc;
  };
  return (
    <Grid container>
      {isHaveDescription && (
        <Grid md={isHaveDescription === false ? 12 : 6}>
          <Box height={'100%'} display={'flex'}>
            <Box flex={1} className={'question-left-container'}>
              <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                <LatexViewer math={description} />
              </Box>
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
                )}
              </Box>
            </Box>
            <Box>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item md={isHaveDescription === false ? 12 : 6}>
        <Box>
          <Div>
            <Question>
              <LatexViewer math={question} />
            </Question>

            {choiceAnswers === null
              ? options.map((item, index) => {
                  const bundleSelect =
                    bundleSelectedChoice.length !== 0 &&
                    bundleSelectedChoice.filter(
                      bundle => bundle.id === item.id
                    );
                  console.log(bundleSelect, 'alkkfh;lsfjh;');
                  return (
                    <Grid container style={{ marginBottom: '15px' }}>
                      <Grid
                        item
                        xs
                        style={{ alignSelf: 'center', lineHeight: '35px' }}
                      >
                        <span>
                          <span>
                            {math(item.text)}
                            <ClariDropDown
                              items={getOption(item.multiChoices)}
                              value={bundleSelect[0]?.value || 'default'}
                              onChange={e => handleDropDown(e, index, item)}
                              label="Select"
                            />
                          </span>
                        </span>
                      </Grid>
                    </Grid>
                  );
                })
              : options.map((item, index) => {
                  const getcorrect = choiceAnswers.filter(
                    (correct, correctIndex) => correct.id === item.id
                  );
                  return (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs style={{ alignSelf: 'center' }}>
                        <LatexViewer math={item.text} />
                        <AnserFlex>
                          <h4>Answer:</h4>
                          <b>
                            {getcorrect[0].score === 1
                              ? getcorrect[0].correctAnswer
                              : getcorrect[0].wrongAnswer}
                          </b>
                          {getcorrect[0].score === 1 ? (
                            <CheckCircleIcon
                              sx={{
                                color: 'green !important',
                              }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{
                                color: 'red !important',
                              }}
                            />
                          )}
                        </AnserFlex>
                        {getcorrect[0].score !== 1 && (
                          <AnserFlex>
                            <h4>Correct Answer:</h4>
                            <b>{getcorrect[0].correctAnswer}</b>
                            <CheckCircleIcon
                              sx={{
                                color: 'green !important',
                              }}
                            />
                          </AnserFlex>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
          </Div>
        </Box>
      </Grid>
    </Grid>
  );
}
