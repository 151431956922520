import { ThemeProvider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import {
  Button,
  Container,
  Divider,
  Heading,
  LiveBox,
  Main,
  Name,
  webinarTheme,
  WebinarCss,
} from '../../../../assets/css/dashboard/webinarStyles/Components.js';
import '../../../../assets/css/dashboard/webinarStyles/Webinar.css';
import LiveIcon from '../../../../assets/icons/Live.svg';

function Webinar(props) {
  const {
    bannerUrl,
    status,
    title,
    cardDate,
    cardTime,
    hostImageUrl,
    hostName,
    wallWebinar,
  } = props.webinarData;

  const { handleActionButtonClick } = props;

  const getActionButtonText = (status, wallWebinar) => {
    if (status === 'Live') return 'Join now';
    else if (wallWebinar) return 'View Calendar';
    else return 'Add to Calendar';
  };

  return (
    <ThemeProvider theme={webinarTheme}>
      <Main background={bannerUrl}>
        <WebinarCss background={bannerUrl}>
          <Container>
            <div className="left">
              {status === 'Live' && (
                <LiveBox>
                  <img src={LiveIcon} alt="" />
                  <div className="live-text">Live</div>
                </LiveBox>
              )}
              <Box pt={1}>
                <Heading live={status === 'Live'}>{title}</Heading>
              </Box>

              <Box pt={2} pb={2}>
                <Divider />
              </Box>
              <div className="date-time-label">{cardDate}</div>
              <div className="date-time-label">{cardTime}</div>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleActionButtonClick(
                    props.webinarData,
                    getActionButtonText(status, wallWebinar)
                  )
                }
              >
                {getActionButtonText(status, wallWebinar)}
              </Button>
            </div>
            <div className="right-image-box">
              {hostImageUrl && hostImageUrl.match(/\.(jpg|jpeg|png|gif)$/) ? (
                <img src={hostImageUrl} alt="" className="profile-pic" />
              ) : (
                ''
              )}
              <Name>{hostName}</Name>
            </div>
          </Container>
        </WebinarCss>
      </Main>
    </ThemeProvider>
  );
}

export default Webinar;
