/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, Hidden } from '@material-ui/core';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AuthContainer,
  LeftContainer,
  LoginLeftWrapper,
  LoginWrapper,
  RightContainer,
} from '../../assets/css/StyledComponent';
import { AuthenticationRoute } from '../../routes/RoutePath';
import Header from '../../utils/Header';
import RightSide from './RightSide';

export default class AuthenticationLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  renderAuthRoute = () => {
    return (
      <>
        <Header />
        <Switch>
          {AuthenticationRoute.map(item => (
            <Route {...item} />
          ))}
        </Switch>
      </>
    );
  };

  render() {
    return (
      <AuthContainer>
        <Grid container>
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <LeftContainer>
              <LoginLeftWrapper>{this.renderAuthRoute()}</LoginLeftWrapper>
            </LeftContainer>
          </Grid>
          <Hidden only={'xs'}>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <RightContainer>
                <LoginWrapper>
                  <RightSide />
                </LoginWrapper>
              </RightContainer>
            </Grid>
          </Hidden>
        </Grid>
      </AuthContainer>
    );
  }
}
