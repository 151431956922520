/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  ThemeProvider,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import {
  listItem,
  StyledSelect,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import { questionBankTheme } from '../../../assets/css/MuiStyles';
import { useState } from 'react';
import {
  CustomTypo,
  FlexViewContainer,
} from '../../../assets/css/StyledComponent';
import {
  FilterContainer,
  HeadDiv,
  Heading,
  MuiDivider,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filterIcon.svg';
import { ReactComponent as OfficialGuideIcon } from '../../../assets/icons/officialGuideIcon.svg';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';
import { ReportSubTitle } from '../../../assets/css/GreReportStyledComponent';
const DEFAULT_OBJECT = {
  id: 'all',
  title: 'ALL',
};
export default function Header(props) {
  const {
    t,
    subjectData,
    conceptData,
    topicData,
    subjectValue,
    conceptValue,
    topicValue,
    attemptStatus,
    attemptValue,
    ogReport,
    difficultyLevel,
    handleChange,
    handleTestReport,
    handleAttemptStatus,
    handleDifficultyLevel,
    handleOnClick,
    isOverallReport,
    product,
  } = props;
  // const [reportValue, setReportValue] = useState("");
  const STATUSES = [
    { value: 'ALL', title: 'All' },
    { value: 'ATTEMPTED', title: 'Attempted' },
    { value: 'NOTATTEMPTED', title: 'Not Attempted' },
  ];
  const attemptedStatus = {
    testExecutionId: 'select',
    testQuestionsSetName: 'View Test Reports',
  };
  const subjectList =
    product === 'GRE'
      ? [DEFAULT_OBJECT, ...(subjectData || [])]
      : subjectData || [];
  // const { data: testData } = this.props.OgReport;
  console.log(attemptValue, 'myvalue');
  console.log(conceptValue, 'conceptValue');
  console.log(props.product, 'product');
  console.log(props.isOverallReport, 'overallReporttttt');
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeadDiv>
            <FlexViewContainer paddingLeft={'24px'}>
              <OfficialGuideIcon />
              <Heading>{t('Official Guide Test Series')}</Heading>
            </FlexViewContainer>
            <FlexViewContainer gridGap={'12px'}>
              <FlexViewContainer gridGap={'6px'}>
                <CustomTypo variant={'subtitle2'}>
                  {'Solve by Filter'}
                </CustomTypo>
                <FilterIcon />
              </FlexViewContainer>
              <SelectBox>
                <ThemeProvider theme={listItem}>
                  <StyledSelect
                    style={style.dropDownStyle}
                    name={'subjectValue'}
                    onChange={handleChange}
                    value={subjectValue}
                    IconComponent={ExpandMore}
                    disableUnderline
                    MenuProps={DROP_DOWN_PROPS}
                  >
                    {subjectList.length !== 0 &&
                      subjectList.map(subject => {
                        return (
                          <MenuItem value={subject.id}>
                            {subject.title}
                          </MenuItem>
                        );
                      })}
                  </StyledSelect>
                  {product === 'GMAT' && (
                    <>
                      <StyledSelect
                        style={style.dropDownStyle}
                        name={'conceptValue'}
                        onChange={handleChange}
                        value={conceptValue}
                        IconComponent={ExpandMore}
                        disableUnderline
                        MenuProps={DROP_DOWN_PROPS}
                      >
                        {[DEFAULT_OBJECT, ...(conceptData || [])].map(
                          concept => {
                            return (
                              <MenuItem value={concept.id}>
                                {concept.title}
                              </MenuItem>
                            );
                          }
                        )}
                      </StyledSelect>
                      <ThemeProvider theme={listItem}>
                        {/* <ReportTitle>{Data.attemptTest}</ReportTitle> */}
                        <StyledSelect
                          style={style.dropDownStyle}
                          name={'topicValue'}
                          onChange={handleChange}
                          value={topicValue}
                          IconComponent={ExpandMore}
                          disableUnderline
                          MenuProps={DROP_DOWN_PROPS}
                        >
                          {[DEFAULT_OBJECT, ...(topicData || [])].map(topic => {
                            return (
                              <MenuItem value={topic.id}>
                                {topic.title}
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                      </ThemeProvider>
                    </>
                  )}
                </ThemeProvider>
              </SelectBox>
            </FlexViewContainer>
          </HeadDiv>
        </Grid>
        <Grid item xs={12}>
          <FilterContainer p={'0 0 10px 0'}>
            <ThemeProvider theme={listItem}>
              <StyledSelect
                name={'attemptStatus'}
                onChange={handleAttemptStatus}
                value={attemptStatus}
                IconComponent={ExpandMore}
                disableUnderline
                MenuProps={DROP_DOWN_PROPS}
              >
                {STATUSES.map(status => {
                  return (
                    <MenuItem value={status.value}>{status.title}</MenuItem>
                  );
                })}
              </StyledSelect>
              <MuiDivider orientation={'horizontal'} />
              <ThemeProvider theme={questionBankTheme}>
                <FormGroup style={{ paddingLeft: '11px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'EASY'}
                        checked={difficultyLevel.includes('EASY')}
                        onChange={handleDifficultyLevel}
                      />
                    }
                    label={t('Easy')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'MEDIUM'}
                        checked={difficultyLevel.includes('MEDIUM')}
                        onChange={handleDifficultyLevel}
                      />
                    }
                    label={t('Moderate')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'HARD'}
                        checked={difficultyLevel.includes('HARD')}
                        onChange={handleDifficultyLevel}
                      />
                    }
                    label={t('Hard')}
                  />
                </FormGroup>
              </ThemeProvider>
            </ThemeProvider>
          </FilterContainer>
          {product === 'GMAT' && (
            <>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {props.isOverallReport === true ? (
                  <>
                    <ReportSubTitle
                      style={{
                        padding: '0px',
                        margin: '0px 10px',
                        textDecorationLine: 'underline',
                        cursor: 'pointer',
                        alignItems: 'center',
                      }}
                      onClick={handleOnClick}
                    >
                      Check Overall Report
                    </ReportSubTitle>
                  </>
                ) : (
                  ''
                )}
                <StyledSelect
                  style={style.dropDownStyle}
                  name={'attemptValue'}
                  onChange={handleTestReport}
                  value={attemptValue}
                  IconComponent={ExpandMore}
                  disableUnderline
                  MenuProps={DROP_DOWN_PROPS}
                >
                  {[attemptedStatus, ...ogReport].map(status => {
                    return (
                      <MenuItem value={status.testExecutionId}>
                        {status.testQuestionsSetName}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
const style = {
  dropDownStyle: {
    minWidth: '210px',
    maxWidth: '210px',
  },
};
