/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import {
  Close,
  IntroDescription,
  IntroTitle,
  SubContent,
  TopParaSub,
  TopSubPara,
  TopTitle,
  SkipSub,
} from '../../../../assets/css/StyledComponent';
import {
  getAllDashboard,
  getWebinar,
  isVisited,
  registerUser,
  deleteStudy,
} from '../../../../redux/action/DashboardLanding';
import IntroPopUp from '../../../../assets/images/IntroPopUp.png';
import { EVENTS } from '../../../../clevertap/Events';
import { IMAGE_URL } from '../../../../constant/Variables';
import * as track from '../../../../mixpanel/Events';
import { getCourseImage } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import {
  personalizedStudyPlan,
  updateExamDetails,
  getCleverTabCommonProperties,
} from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import DialogComponent from '../../../../utils/components/DialogComponent';
import CardIcon from '../../../../assets/icons/CalibrationIcon.svg';
import { QS_PROPS } from '../../../../constant/Variables';
import QueryString from 'qs';
import clevertap from 'clevertap-web-sdk';

class CalibrationTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: '',
      open: false,
      dialogOpen: false,
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClickClose = () => {
    this.setState({
      open: false,
    });
  };

  // handleClick = () => {
  //   let productName = JSON.parse(
  //     window.localStorage.getItem('userProduct')
  //   ).productName;

  //   let obj = {
  //     productName: productName,
  //   };
  //
  //       : track.calibrationTest(
  //           {
  //             'Product Name': JSON.parse(localStorage.getItem('userProduct'))
  //               .productName,
  //           },
  //           false
  //         );

  //     //  Gmat flow

  //     if (productName === 'GMAT') {
  //       this.props.updateExamDetails(obj, res => {
  //         this.props.getCourses();
  //         this.props.history.push(
  //           `${routePaths.gmat.instruction}?testQuestionSetId=${
  //             localStorage.getItem('userProduct')
  //               ? JSON.parse(localStorage.getItem('userProduct'))
  //                   .testQuestionSetId
  //               : null
  //           }`
  //         );
  //       });
  //     } else if (productName === 'GRE') {
  //       this.props.history.push(`${
  //         routePaths.gre.instruction
  //       }?testQuestionSetId=${
  //         localStorage.getItem('userProduct')
  //           ? JSON.parse(localStorage.getItem('userProduct')).testQuestionSetId
  //           : null
  //       }
  //     `);
  //     } else
  //       this.props.updateExamDetails(obj, res => {
  //         this.props.getCourses();
  //         this.props.history.push(
  //           routePaths.instruction +
  //             `?questionSet=${
  //               localStorage.getItem('userProduct')
  //                 ? JSON.parse(localStorage.getItem('userProduct'))
  //                     .testQuestionSetId
  //                 : null
  //             }`
  //         );
  //       });
  //   }
  // };
  componentDidMount() {
    this.props.getCourses();
    let obj1 = {
      courseId: JSON.parse(window.localStorage.getItem('userProduct')).id,
      endPoint: 'Calibration test',
    };
    this.props.getCourseImage(obj1, response => {
      this.setState({ img: IMAGE_URL + response.data.value });
    });
  }

  handleSkipClick = () => {
    let id = JSON.parse(window.localStorage.getItem('userProduct')).id;
    track.calibrationTest(
      {
        'Product Name': JSON.parse(localStorage.getItem('userProduct'))
          .productName,
      },
      true
    );

    /* Getting common_properties from localStorage and send it onUserLogin. */
    this.props.getCleverTabCommonProperties(res => {
      if (res.success) {
        let common_properties = JSON.parse(
          window.localStorage.getItem('user_common_properties') || '{}'
        );
        clevertap.onUserLogin.push({
          Site: {
            ...common_properties,
          },
        });
      }
    });
    /* Getting common_properties from localStorage and send it onUserLogin. */

    this.props.personalizedStudyPlan(id, response => {
      this.props.getCourses();
      if (response.success) {
        this.props.getCleverTabCommonProperties(res => {
          if (res.success) {
            clevertap.event.push(EVENTS.LMSUserLandsOnTheDashboard, {
              'Page source': 'Skip test button',
            });
            this.props.history.push(routePaths.dashboard.home);
          }
        });
      }
    });
  };

  handleClick = () => {
    const { isTakeCalibration } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    console.log(isTakeCalibration, 'sd');
    let productName = JSON.parse(
      window.localStorage.getItem('userProduct')
    ).productName;

    /* Getting common_properties from localStorage and send it onUserLogin. */
    this.props.getCleverTabCommonProperties(res => {
      if (res.success) {
        let common_properties = JSON.parse(
          window.localStorage.getItem('user_common_properties') || '{}'
        );
        clevertap.onUserLogin.push({
          Site: {
            ...common_properties,
          },
        });
      }
    });
    /* Getting common_properties from localStorage and send it onUserLogin. */

    let obj = {
      productName: productName,
    };
    {
      isTakeCalibration === 'false'
        ? this.props.deleteStudy(response => {
            if (response.success) {
              this.props.getCourses(res => {
                if (res.success) {
                  localStorage.setItem(
                    'userProduct',
                    JSON.stringify(
                      res.data.find(
                        ({ id }) =>
                          id ===
                          JSON.parse(localStorage.getItem('userProduct')).id
                      )
                    )
                  );
                  let productName = JSON.parse(
                    window.localStorage.getItem('userProduct')
                  ).productName;

                  let obj = {
                    productName: productName,
                  };
                  if (productName === 'GMAT' || productName === 'GMAT FOCUS') {
                    this.props.updateExamDetails(obj, res => {
                      this.props.getCourses();
                      this.props.history.push(
                        `${routePaths.gmat.instruction}?testQuestionSetId=${
                          localStorage.getItem('userProduct')
                            ? JSON.parse(localStorage.getItem('userProduct'))
                                .testQuestionSetId
                            : null
                        }`
                      );
                    });
                  } else if (productName === 'GRE') {
                    this.props.history.push(`${
                      routePaths.gre.instruction
                    }?testQuestionSetId=${
                      localStorage.getItem('userProduct')
                        ? JSON.parse(localStorage.getItem('userProduct'))
                            .testQuestionSetId
                        : null
                    }
                `);
                  } else
                    this.props.updateExamDetails(obj, res => {
                      this.props.getCourses();
                      this.props.history.push(
                        routePaths.instruction +
                          `?questionSet=${
                            localStorage.getItem('userProduct')
                              ? JSON.parse(localStorage.getItem('userProduct'))
                                  .testQuestionSetId
                              : null
                          }`
                      );
                    });
                }
              });
            }
          })
        : track.calibrationTest(
            {
              'Product Name': JSON.parse(localStorage.getItem('userProduct'))
                .productName,
            },
            false
          );

      //  Gmat flow
      if (productName === 'GMAT' || productName === 'GMAT FOCUS') {
        this.props.updateExamDetails(obj, res => {
          this.props.getCourses();
          this.props.history.push(
            `${routePaths.gmat.instruction}?testQuestionSetId=${
              localStorage.getItem('userProduct')
                ? JSON.parse(localStorage.getItem('userProduct'))
                    .testQuestionSetId
                : null
            }`
          );
        });
      } else if (productName === 'GRE') {
        this.props.history.push(`${
          routePaths.gre.instruction
        }?testQuestionSetId=${
          localStorage.getItem('userProduct')
            ? JSON.parse(localStorage.getItem('userProduct')).testQuestionSetId
            : null
        }
                  `);
      } else
        this.props.updateExamDetails(obj, res => {
          this.props.getCourses();
          this.props.history.push(
            routePaths.instruction +
              `?questionSet=${
                localStorage.getItem('userProduct')
                  ? JSON.parse(localStorage.getItem('userProduct'))
                      .testQuestionSetId
                  : null
              }`
          );
        });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Box pt={1}>
          <Grid className={'profile__fit__right__container'}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={12} xs={12}>
                <Box pt={1} pb={1}>
                  <TopTitle>
                    {t(
                      `Let's get ready for the ${
                        JSON.parse(window.localStorage.getItem('userProduct'))
                          .productName === 'Aptitude test'
                          ? 'APTITUDE Calibration Test'
                          : `${
                              JSON.parse(
                                window.localStorage.getItem('userProduct')
                              ).productName
                            } Calibration Test`
                      }`
                    )}
                  </TopTitle>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box>
                  <TopSubPara>
                    <SubContent>
                      {t(
                        'The calibration test will help us know where you stand right now in your preparation, your level in different subjects & concepts. According to which our experts will curate the study plan in such a way that it gives a wonderful, personalized learning experience to you & help in improving the score in an easy & planned manner.'
                      )}
                    </SubContent>
                  </TopSubPara>
                </Box>
              </Grid>

              <Grid item md={12} xs={12}>
                <Box pt={2}>
                  <TopParaSub>
                    {t(
                      'Calibration test help us to give you the best study plan'
                    )}
                  </TopParaSub>
                </Box>
              </Grid>
              <div className={'top__grid'}>
                <Grid>
                  <img src={this.state.img} style={{ width: '80%' }} alt={''} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <SkipSub>
                    {t(
                      'If you skip, you can take the test anytime within 7 days of onboarding'
                    )}
                  </SkipSub>
                </Grid>

                <div className={'calibration_div'}>
                  <Box></Box>
                  <ThemeProvider theme={buttonTheme}>
                    <Button
                      className={'btn__alignment'}
                      variant="outlined"
                      color="primary"
                      onClick={this.handleClickOpen}
                    >
                      {t('Skip this Test')}
                    </Button>
                  </ThemeProvider>
                  <Dialog
                    className={'dialog_alignment'}
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                  >
                    <Box>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Close>
                          <CloseIcon
                            style={{ fill: '#1093FF' }}
                            onClick={this.handleClickClose}
                          />
                        </Close>
                        <Box display={'flex'} justifyContent="center">
                          <img src={IntroPopUp} alt="" />
                        </Box>
                        <Box pt={3}>
                          <IntroTitle>
                            YOU ARE ABOUT TO SKIP THE CALIBRATION TEST
                          </IntroTitle>
                        </Box>
                      </Box>
                      {/* dialog content */}
                      <DialogContent>
                        <Box pt={2}>
                          <IntroDescription>
                            This is not recommended as this data is essential
                            for us to customize the study plan for you. For best
                            results and a customized plan, take the test NOW!
                          </IntroDescription>
                        </Box>
                      </DialogContent>

                      <Box pl={3} pr={3} pb={5} display={'flex'}>
                        <ThemeProvider theme={buttonTheme}>
                          <Button
                            className={'btn__alignment'}
                            variant="outlined"
                            color="primary"
                            onClick={this.handleClickClose}
                          >
                            Cancel
                          </Button>
                        </ThemeProvider>
                        <Grid item md={2}></Grid>
                        <ThemeProvider theme={buttonTheme}>
                          <Button
                            className={'btn__alignment'}
                            variant="contained"
                            color="primary"
                            onClick={this.handleSkipClick}
                          >
                            Skip for now
                          </Button>
                        </ThemeProvider>
                      </Box>
                    </Box>
                  </Dialog>

                  <Grid item md={1} sm={1} xs={12}></Grid>
                  <ThemeProvider theme={buttonTheme}>
                    <Button
                      className={'btn__alignment'}
                      variant="contained"
                      color="primary"
                      onClick={this.handleClick}
                    >
                      {t('Start Test')}
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    examDetailsResponse: state.testReducer.examDetails,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, {
  updateExamDetails,
  getCourses,
  getCourseImage,
  personalizedStudyPlan,
  getCleverTabCommonProperties,
  deleteStudy,
})(withTranslation()(CalibrationTest));
