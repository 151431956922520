import { Grid, Box, Divider } from '@material-ui/core';
import {
  Passage as passage,
  TextBox,
  TextBoxField,
  TextUnitField,
  TextWrapper,
  TextBoxValueShow,
  DividerLine,
  ErrorStatement,
  Div,
} from '../../../../assets/css/test/TestComponent';
import SingleSelect from './SingleSelect';
import BundleQuestion from '../../course/exam/Bundle';
import BundleAnswer from '../../course/answer/Bundle';
import LatexViewer from '../../../../utils/LatexViewer';

import React from 'react';
const Passage = ({
  para,
  question,
  choices,
  bundle,
  selectedChoice,
  onSelect,
  subjective,
  onChange,
  bundleLength,
  answer,
  correctChoice,
  activeChoice,
  onlyShow,
  description,
  imgUrl,
  fraction,
  fraction1,
  fraction2,
  onChangeCapture,
  unit,
  optionalType,
  error,
  isHaveDescription,
}) => {
  const { Para, Question } = passage;
  const style = {
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
    height: {
      height: '100vh',
    },
    noheight: {
      height: 'auto',
    },
  };
  const { hide, show, height, noheight } = style;

  return (
    <Grid container>
      <Grid
        item
        md={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? 12
            : 6
        }
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? height
            : noheight
        }
      >
        <Box
          display={'flex'}
          style={{ height: '63vh' }}
          className={'overflow-scroll'}
        >
          <Box flex={1} className={'question-left-container'}>
            {bundle && isHaveDescription === false && (
              <>
                <Para>
                  <LatexViewer math={para} />
                </Para>
                {bundle && !onlyShow ? (
                  <BundleQuestion
                    bundleLength={bundleLength}
                    choices={choices}
                    onChange={onChange}
                    selectedChoice={selectedChoice}
                    correctChoice={correctChoice}
                    activeChoice={activeChoice}
                    isHaveDescription={isHaveDescription}
                    question={para}
                  />
                ) : (
                  bundle &&
                  onlyShow && (
                    <BundleAnswer
                      bundleLength={bundleLength}
                      choices={choices}
                      onChange={onChange}
                      selectedChoice={selectedChoice}
                      correctChoice={correctChoice}
                      isHaveDescription={isHaveDescription}
                      question={para}
                    />
                  )
                )}
              </>
            )}
            {description !== '' && (
              <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                <LatexViewer math={description} />
              </Box>
            )}

            {subjective ? (
              isHaveDescription === false ? (
                <React.Fragment>
                  <Box>
                    <Div>
                      <Question>
                        <LatexViewer math={question} />
                      </Question>
                    </Div>
                  </Box>
                  {optionalType === 'NUMERICAL_ENTRY' && (
                    <div style={{ display: 'flex' }}>
                      <TextBoxField
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={answer}
                        type={'number'}
                      />
                      {unit !== null ? (
                        <TextUnitField>{unit}</TextUnitField>
                      ) : null}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {fraction && isHaveDescription === false && (
              <>
                <Question>
                  <LatexViewer math={question} />
                </Question>
                <div style={{ display: 'flex' }}>
                  <div>
                    <TextBoxValueShow
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={fraction1}
                      type={'number'}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      onChange={e => {
                        onChangeCapture(e.target.value);
                      }}
                      value={fraction2}
                      type={'number'}
                    />
                  </div>
                  {unit !== null ? <TextUnitField>{unit}</TextUnitField> : null}
                </div>
              </>
            )}
            <Box>
              {imgUrl && (
                <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
              )}
            </Box>
          </Box>
          {(subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false) ? (
            ''
          ) : (
            <Box style={{ height: '63vh' }}>
              <Divider variant="middle" orientation={'vertical'} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? hide
            : show
        }
      >
        <Box
          minHeight={400}
          style={{ height: '63vh', marginRight: '1vw' }}
          className={'overflow-scroll'}
        >
          {bundle && !onlyShow ? (
            <BundleQuestion
              bundleLength={bundleLength}
              choices={choices}
              onChange={onChange}
              selectedChoice={selectedChoice}
              correctChoice={correctChoice}
              activeChoice={activeChoice}
              isHaveDescription={isHaveDescription}
              question={para}
            />
          ) : bundle && onlyShow ? (
            <BundleAnswer
              bundleLength={bundleLength}
              choices={choices}
              onChange={onChange}
              selectedChoice={selectedChoice}
              correctChoice={correctChoice}
              isHaveDescription={isHaveDescription}
              question={para}
            />
          ) : subjective ? (
            optionalType === 'NUMERICAL_ENTRY' ? (
              <div>
                <TextWrapper style={{ marginTop: '0px', paddingTop: '0px' }}>
                  {subjective && isHaveDescription === true && (
                    <Question>
                      <LatexViewer math={question} />
                    </Question>
                  )}
                  <div style={{ display: 'flex' }}>
                    <TextBoxField
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={answer}
                      type={'number'}
                    />
                    {unit !== null ? (
                      <TextUnitField>{unit}</TextUnitField>
                    ) : null}
                  </div>
                </TextWrapper>
              </div>
            ) : (
              <>
                <Box>
                  <Div>
                    {isHaveDescription === true && (
                      <Question>
                        <LatexViewer math={question} />
                      </Question>
                    )}
                    {/* <OptionContainer>{renderChoices()}</OptionContainer> */}
                  </Div>
                </Box>
                <TextBox
                  type={'text'}
                  placeholder={'Enter your answer here'}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={answer}
                  readOnly={onlyShow}
                />
              </>
            )
          ) : fraction ? (
            <React.Fragment>
              <TextWrapper style={{ marginTop: '0px', paddingTop: '0px' }}>
                {fraction && isHaveDescription === true && (
                  <Question>
                    <LatexViewer math={question} />
                  </Question>
                )}
                <div style={{ display: 'flex' }}>
                  <div>
                    <TextBoxValueShow
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={fraction1}
                      type={'number'}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      onChange={e => {
                        onChangeCapture(e.target.value);
                      }}
                      value={fraction2}
                      type={'number'}
                    />
                  </div>
                  {unit !== null ? <TextUnitField>{unit}</TextUnitField> : null}
                </div>
              </TextWrapper>
            </React.Fragment>
          ) : (
            <SingleSelect
              question={question}
              options={choices}
              selectedChoice={selectedChoice}
              onSelect={onSelect}
              correctChoice={correctChoice}
              activeChoice={activeChoice}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Passage;
