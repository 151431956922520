/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, ThemeProvider } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import CameraAlt from '@material-ui/icons/CameraAlt';
import Create from '@material-ui/icons/Create';
import Alert from '@material-ui/lab/Alert';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { profileUpdateTheme } from '../../../assets/css/MuiStyles';
import {
  EditButton,
  ProfileImageEdit,
  ProfileLeftContainer,
  RoundedEditButton,
  UploadSizeTitle,
} from '../../../assets/css/StyledComponent';
import {
  uploadProfileImage,
  viewProfileImage,
} from '../../../redux/action/Profile';
import { routePaths } from '../../../routes/RoutePath';
import { dataURLtoFile, generateDownload } from '../../../utils/generateImage';
import Upload from '../profile/setup/Upload';
import ProfileSetUp from './setup/ProfileSetUp';
import ProfileUpdate from './update/ProfileUpdate';
import ProfileDetail from './view/ProfileDetail';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      firstName: '',
      lastName: '',
      dob: '',
      college: [],

      crop: { x: 0, y: 0 },
      zoom: 1,
      rotate: 0,

      profilePic: null,
      previewImage: null,
      image: null,
      croppedAreaPixels: null,
      visible: true,
      updatedProfileImageFile: null,
      updatedProfileImage: null,
      activeTab: 0,

      snackBarOpen: false,
      snackBarSeverity: 'warning',
      message: 'The image size should be within 5mb',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () =>
      this.setState({ width: window.innerWidth })
    );

    this.props.viewProfileImage(response => {
      if (response !== '') this.setState({ updatedProfileImage: response });
    });
  }

  onFileChange = e => {
    const fileSize = e.target.files[0].size / 1024 / 1024;

    if (e.target.files[0]) {
      const { type } = e.target.files[0];
      if (
        (type.endsWith('jpeg') ||
          type.endsWith('png') ||
          type.endsWith('jpg') ||
          type.endsWith('gif')) &&
        fileSize < 5
      ) {
        this.setState({
          profilePic: e.target.files[0],
          previewImage: URL.createObjectURL(e.target.files[0]),
          visible: false,
        });
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.addEventListener('load', () => {
          this.setState({ image: reader.result });
        });
      } else {
        this.handleSackBarToggle();
      }
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };

  getImage = async () => {
    const blobImage = await (
      await generateDownload(
        this.state.image,
        this.state.croppedAreaPixels,
        this.state.rotate
      )
    ).toDataURL();
    const image = dataURLtoFile(blobImage, this.state.profilePic.name);

    var formData = new FormData();
    formData.append('file', image);
    this.props.uploadProfileImage(formData, response => {
      this.props.viewProfileImage(response => {
        if (response !== '') this.setState({ updatedProfileImage: response });
      });
    });

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.addEventListener('load', () => {
      this.setState({
        updatedProfileImage: reader.result.slice(
          reader.result.indexOf(',') + 1,
          reader.result.length
        ),
      });
    });

    this.setState({
      updatedProfileImageFile: image,
      visible: true,
      rotate: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
    });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  onCancel = () =>
    this.setState({ visible: true, rotate: 0, crop: { x: 0, y: 0 }, zoom: 1 });

  fileToUrl = () => {
    this.setState({ image: URL.createObjectURL(this.state.profilePic) });
  };

  onRotate = () => {
    if (this.state.rotate === 270) {
      this.setState({ rotate: 0 });
    } else {
      this.setState({ rotate: this.state.rotate + 90 });
    }
  };

  handleSackBarToggle = () => {
    this.setState({
      snackBarOpen: !this.state.snackBarOpen,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <ThemeProvider theme={profileUpdateTheme}>
        <div>
          {this.state.profilePic && !this.state.visible && (
            // <Hello />
            <Upload
              image={this.state.image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              onDownload={this.getImage}
              rotation={this.state.rotate}
              onCancel={this.onCancel}
              onRotate={this.onRotate}
              t={t}
            />
          )}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              className={'profile__fit__left__container'}
            >
              <ProfileLeftContainer
                style={{ display: this.state.visible ? 'unset' : 'none' }}
              >
                <input
                  type="file"
                  id="profile__upload"
                  accept={'image/png, image/jpeg'}
                  onInput={this.onFileChange}
                  onClick={e => (e.target.value = null)}
                />
                <div
                  className="add__image__circle"
                  onClick={() =>
                    document.getElementById('profile__upload').click()
                  }
                >
                  {this.state.updatedProfileImage !== null &&
                    this.state.updatedProfileImage !== '' && (
                      <ProfileImageEdit>
                        <img
                          src={`data:image;base64,${this.state.updatedProfileImage}`}
                          className="profile__image"
                          alt=""
                          id={'img'}
                        ></img>
                        {this.props.location.pathname ===
                        routePaths.profileDetail ? (
                          <RoundedEditButton>
                            <Create /> &nbsp;&nbsp;{t('Edit Image')}
                          </RoundedEditButton>
                        ) : (
                          <EditButton>
                            <Create /> &nbsp;&nbsp;{t('Edit')}
                          </EditButton>
                        )}
                      </ProfileImageEdit>
                    )}
                  {(this.state.updatedProfileImage === null ||
                    this.state.updatedProfileImage === '') && (
                    <div className="profile__add__container">
                      {/* Icon button */}
                      <CameraAlt style={{ fontSize: 55 }} />
                      {/* title */}
                      <h6>{t('Add Image')}</h6>
                    </div>
                  )}
                </div>
                {(this.state.updatedProfileImage === null ||
                  this.state.updatedProfileImage === '') && (
                  <UploadSizeTitle>
                    {t('Maximum Image size of 5Mb')}
                    {'.'}
                  </UploadSizeTitle>
                )}
              </ProfileLeftContainer>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={8}
              className={'profile__fit__right__container'}
              style={{ display: this.state.visible ? 'unset' : 'none' }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={11}>
                  <Switch>
                    <Route
                      exact
                      path={routePaths.profile}
                      component={ProfileSetUp}
                    />
                    <Route
                      exact
                      path={routePaths.profile + '/'}
                      component={ProfileSetUp}
                    />
                    <Route exact path={'/'} component={ProfileSetUp} />
                    <Route
                      path={routePaths.profileDetail}
                      component={ProfileDetail}
                    />
                    <Route
                      path={routePaths.profileSetup}
                      component={ProfileSetUp}
                    />
                    <Route
                      path={routePaths.profileUpdate}
                      component={ProfileUpdate}
                    />
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleSackBarToggle}
          TransitionComponent={Slide}
        >
          <Alert severity={this.state.snackBarSeverity} variant="filled">
            {this.state.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    profileImage: state.profileReducer.profileImage,
  };
};

export default connect(mapStateToProps, {
  uploadProfileImage,
  viewProfileImage,
})(withRouter(withTranslation()(Index)));
