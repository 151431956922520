/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Select, withStyles, IconButton } from '@material-ui/core';
import styled from 'styled-components';
// import MuiButton from '../../../utils/components/Button';
// import {
//   Box,
//   Button,
//   Card as MuiCard,
//   Popover as MuiPopover,
//   Typography,
//   withStyles,
//   Popper,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from '@material-ui/core';
export const Container = styled.div`
  /* background: #f2f9ff; */
  background: #ffffff;
  padding: 40px;
  overflow-x: hidden;
`;
export const FlexView = styled.div`
  display: flex;
  align-items: center;
  gap: 2vh;
`;
export const ExcelButton = styled.button`
  min-width: 303px !important;
  height: 40px;
  margin-bottom: 5px;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
`;
export const FlexViewItem = styled.div`
  display: flex;
  align-items: center;
  gap: 2vh;
  // width: 175vh;
  width: 175vh;
  margin-left: 15px;
  margin-bottom: 30px;
`;
export const Header = styled.header`
  display: flex;
`;

export const Title = styled.h1`
  flex: 1;
  font-style: normal;
  font-weight: ${props => props.fw || 500};
  font-size: 26px;
  line-height: 41px;
  color: #052a4e;
  margin-left: 25px;
`;
export const TopicTitle = styled.h1`
  flex: 1;
  font-style: normal;
  font-weight: ${props => props.fw || 500};
  font-size: 23px;
  line-height: 41px;
  color: #052a4e;
  margin-left: 25px;
  margin-bottom: 20px;
`;
export const ReportTitle = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: -30px;
`;

export const ReportTime = styled.h4`
  font-style: normal;
  font-weight: 400px;
  font-size: 17px;
  line-height: 20px;
  color: #052a4e;
  text-align: right;
  margin-top: -70px;
`;

export const StyledSelect = withStyles(theme => ({
  root: {
    width: '150px',
    height: '20px',
    background: '#FFFFFF',
    border: '0.5px solid #1093FF',
    borderRadius: '20px',
    boxSizing: 'borderBox',
    marginTop: '-35px',
    color: '#000000',
    padding: '8px 15px',
    textAlign: 'center',
  },
  select: {
    paddingRight: '40px !important',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '30px !important',
    marginLeft: '10px',
  },
  icon: {
    marginRight: '10px',
    color: '#1093FF',
    marginTop: '-17px',
  },
}))(Select);

// export const FlexView = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 2vh;
// `;
export const BeforeIcon = styled(IconButton)`
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  display: block !important;
  border-radius: 60% !important;
  margin-top: 10px;
  opacity: ${({ disabled }) => disabled && 0.5};
`;

// export const Header = styled.header`
//   display: flex;
// `;
export const Section = styled.section`
  padding-top: 4vh;
`;
export const SubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 20px;
  text-align: center;
  color: #1093ff;
  padding-bottom: 7px;
`;
export const ReportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`;
export const InsideBox = styled.div`
  padding-bottom: 12px;
`;

export const DownloadButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 8px 46px;
  width: auto;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #f2f2f2;
  background: #1093ff;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

export const ReportCardContainer = styled.div`
  position: relative;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 12px 12px 12px;
`;

export const PieChartContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400px;
  font-size: 21px;
  color: #686868;
  padding-bottom: 10px;
  display: ${props => props.display};
  flex-direction: ${props => props.direction};
`;

export const ChartValue = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #052a4e;
  display: flex;
`;

export const Card = styled.div`
  width: 178vh;
  padding: ${props => props.padding || '15px'};
  // background: #f7f7f7;
  // box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 2;
  margin-top: 20px;
`;

export const Indicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
`;

export const CardHeaderTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #686868;
  padding-left: 10px;
`;
export const CardHeaderTitle1 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1093ff;
  padding-left: -10px;
`;
export const CardFooter = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #002d18;
  text-align: left;
  margin-top: 15%;
`;

export const Item = styled.div`
  width: ${props => (props.width ? props.width + 'px' : 'unset')};
  margin-top: -13%;
`;

export const CardPerentage = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1093ff;
  text-align: right;
  font-weight: 600;
  margin-top: -10%;
`;

export const Card1 = styled.div`
  width: 100%;
  padding: ${props => props.padding || '10px'};
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
`;
export const Card2 = styled.div`
  width: 100%;
  padding: ${props => props.padding || '10px'};
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
`;
export const ReportText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding-top: 4%;
  padding-left: 1%;
  padding-bottom: 2%;
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
`;

export const CardItem = styled.div`
  display: flex;
  padding: 10px 0px;
  width: 100%;
`;

export const ItemCaption = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  position: absolute;
  padding-left: 45px;
`;

export const ItemTypo = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;
export const ItemIcon = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 15%;
`;

export const ImageText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  text-align: right;
  flex: 2;
  margin-right: 30px;
`;
export const ImageText1 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  flex: 1;
  text-align: right;
  margin-right: 18px;
  // margin-left: 13rem;
`;

export const ErrorTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
  padding-top: 25px;
  padding-bottom: 5px;
`;

export const ReportSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #1093ff;
  margin-top: -30px;
`;

export const CardTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const CardSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin-top: -2px;
  margin-left: 10px;
`;
export const CardSubTitle1 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1093ff;
  margin-top: 0px;
  margin-left: 12rem;
`;
export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -20px;
`;

export const CardIndicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
`;

export const ItemCaption2 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0a66c2;
`;

export const ItemCaption3 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0a66c2;
  // margin-left: 4rem;
`;

export const Percentage = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #0a66c2;
  padding-left: 3rem;
`;

export const FlexEnded = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexView2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
`;