/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  AddressTitle as DescTitle,
  BelowContent as InstructionTitle,
  TimeNumber as N1,
} from '../../../../assets/css/StyledComponent';
import {
  B1,
  ContinueButton,
  Divider,
  Footer,
  Grey,
  H1,
  H2,
  Icon,
  Next,
  Pause,
  PauseModelSubTitle,
  PauseModelTitle,
  QuitButton,
  RowBox,
  StyleDiv,
  T1,
} from '../../../../assets/css/test/TestComponent';
import Bullet from '../../../../assets/icons/Bullet.svg';
import PauseModelIcon from '../../../../assets/icons/pause.svg';
import PauseIcon from '../../../../assets/icons/pauseIcon.svg';
import * as track from '../../../../mixpanel/Events';
import { getTestSection } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import PageLoader from '../../../../utils/components/PageLoader';
import Model from '../../../../utils/Model';
import StepperComponent from './StepperComponent';

class SectionIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
    };
  }
  componentDidMount() {
    this.props.getTestSection();
  }

  renderModel = t => {
    return (
      <Model open={this.state.modelOpen}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={5}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box>
            <img src={PauseModelIcon} alt="" />
          </Box>
          <PauseModelTitle>{t('Test Paused')}</PauseModelTitle>
          <PauseModelSubTitle>
            {t('Press Continue to continue the test')}
          </PauseModelSubTitle>
          <Box display={'flex'} gridGap={25}>
            <QuitButton onClick={this.onPause}>{t('Quit')}</QuitButton>
            <ContinueButton onClick={() => this.setState({ modelOpen: false })}>
              {t('Continue')}
            </ContinueButton>
          </Box>
        </Box>
      </Model>
    );
  };

  onPause = () => {
    track.pauseExam({
      'Product Name': `${
        JSON.parse(localStorage.getItem('userProduct')).productName
      }`,
    });

    this.props.history.push(routePaths.allCourses);
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.section !== prevProps.section) {
      track.numberOfSectionFinished(
        {
          'Product Name': JSON.parse(localStorage.getItem('userProduct'))
            .productName,
          'Total Section': this.props.section.data.totalSections,
        },
        this.props.section.data.currentSection
      );
    }
  }

  render() {
    const { t } = this.props;
    const { data } = this.props.section;
    if (data)
      return (
        <StyleDiv>
          <Grid Container direction="column" alignContent="flex-start">
            {this.renderModel(t)}
            <H1>{t('Calibration Test')}</H1>
            <Box>
              <StepperComponent
                totalSteps={data.totalSections}
                activeStep={data.currentSection - 1}
              />
            </Box>

            <Divider />

            <H2>{data.testSection.name}</H2>
            <B1>{data.testSection.nameDescription}</B1>
            <RowBox>
              <T1>{t('Number of questions:')}</T1>
              <N1>{data.testSection.noOfQuestions}</N1>
              <Divider
                flexItem
                orientation="vertical"
                className={'vertical_divider'}
              />
              <T1>{t('Time required:')}</T1>
              <N1>{data.testSection.duration} min</N1>
            </RowBox>
            <DescTitle padding="34px 0 0 0">{t('Test Instructions')}</DescTitle>
            <InstructionTitle p="12px 0 0 0">
              {data.testSection.descriptionTitle}
            </InstructionTitle>
            {data.testSection.description.map(text => (
              <div className="bullet-padding">
                <img src={Bullet} alt="." />
                <Grey p="0 16px">{text}</Grey>
              </div>
            ))}

            <Footer>
              <Box width={'100%'} pt={3}>
                <Divider />
              </Box>
              <Box
                pt={2}
                pb={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                {data.currentSection > 1 ? (
                  <Pause onClick={() => this.setState({ modelOpen: true })}>
                    <Icon src={PauseIcon} alt={''} />
                    <Box whiteSpace="nowrap">{t('Pause exam')}</Box>
                  </Pause>
                ) : (
                  <div />
                )}
                <Next
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    track.afterCalibration(
                      {
                        'Product Name': `${
                          JSON.parse(localStorage.getItem('userProduct'))
                            .productName
                        }`,
                      },
                      true
                    );
                    this.props.history.push(
                      `${routePaths.exam}?testQuestionId=${
                        JSON.parse(localStorage.getItem('userProduct'))
                          .testQuestionSetId
                      }`
                    );
                  }}
                  loading={this.state.isLoading}
                >
                  {t('Next')}
                </Next>
              </Box>
            </Footer>
          </Grid>
        </StyleDiv>
      );
    else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    section: state.testReducer.testSection,
  };
};

export default connect(mapStateToProps, { getTestSection })(
  withTranslation()(SectionIntro)
);
