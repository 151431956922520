/**
 * (c) CareerLabs. All rights reserved.
 **/
import ReplayIcon from '@material-ui/icons/Replay';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../assets/css/Player.css';
import {
  Container,
  EmptyBox,
  InnerDiv,
  Message,
  replayIconStyle,
} from '../assets/css/VideoPlayerStyles';
import { getVideoPlayInfo, updatePlayTime } from '../redux/action/Course';
import {updateCompletedTask,getTopicDetail} from '../redux/action/Dashboard'
import Player from './Player';
import { QuestionPopup } from '../assets/css/VideoPlayerStyles';

/**
 * @param {String} otp
 * @param {String} playBackInfo
 * @param {Function} getVideoObj
 * @param {Number} startTime milliseconds
 * @param {Number} playCount
 */

function VideoPlayer(props) {
  const [state, setState] = useState({
    videoMillSec: 0,
    videoOtp: '',
    videoPlaybackInfo: '',
    videoPlayedCount: 0,
    time: '',
   
  });

  // To seek the previous position on the first time play only
  const [firstPlay, setFirstPlay] = useState(true);
  const [vdoObj, setVdoObj] = useState(null);
  const [vdoCompleted,setVdoCompleted] = useState(false);
  const { handleFeedBack, feedbackCount, isVideoPlayedLimit} = props;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getVdoObj();
    setFirstPlay(true);
    setVdoObj(null);
  }, [props.videoId]);

  function getVdoObj() {
    props.getVideoPlayInfo(props.videoId, res => setState(res));
  }

  function registerPlayerPosition(regObj) {

    props.updatePlayTime(props.videoId, regObj, res => {
      console.log(res.data,"ressssss")
      console.log(res.data.isVideoCompleted,"dddfffff")
    if(res.data.isVideoCompleted ===  true)
    {
      
      setVdoCompleted(true);
      localStorage.setItem(
        'vdoCompleted',
        JSON.stringify(true)
      );
     
      
    }
      getVdoObj();
    });
  }

  function handlePause(vdoObj) {
    console.log('jjhhgg');
    const pauseObj = {
      videoMillSec: Math.trunc(vdoObj.currentTime * 1000),
      totalTime: Math.trunc(vdoObj.duration * 1000),    
    };
    // if(props.vdoCompleted ===  true)
    // {
    //   localStorage.setItem(
    //     'vdoCompleted',
    //     JSON.stringify(true)
    //   );
    // }
    

    registerPlayerPosition(pauseObj);
    props.getVideoObj(vdoObj, props.index);
  }

  //feedbackCount at the end of the video player
  function handleEnd(vdoObj) {
    setVdoObj(vdoObj);
    if (handleFeedBack && !feedbackCount) {
      const obj = {
        videoMillSec: Math.trunc(vdoObj.currentTime * 1000),
        totalTime: Math.trunc(vdoObj.duration * 1000),
        feedbackCount: feedbackCount,
      };
      handleFeedBack(obj, props.videoId);
    }
  }

  function handleProgress(vdoObj) {
    console.log(vdoObj.currentTime);
    setVdoObj(vdoObj);

    console.log(props.isVideoQuestion, 'efschb');
    console.log(props.isShowVideoQuestion, 'showingquest');
    console.log(props.testAttempted, 'testAttempted');

    if (props.isVideoQuestion) {
      if (props.isVideoQuestion && !Boolean(props.isShowVideoQuestion))
        console.log(props.isShowVideoQuestion, 'show');

      if (
        !props.isShowVideoQuestion && props.testAttempted === false &&
        (vdoObj.currentTime.toFixed(1) === props.time.toFixed(1) ||
          vdoObj.currentTime.toFixed(0) === props.time.toFixed(0))
      ) {
        props.handleVideoQuestion();
      }
    }
  }

  function handleSeek(vdoObj) {}

  function handlePlay(vdoObj) {
    console.log(vdoObj,"current video")
    
    setFirstPlay(false);
  }

  function handleBlackScreenClick() {
    setVdoObj(null);
  }

  function handleLoad(vdoObj) {
    props.getVideoObj(vdoObj, props.index);
  }

  if ((vdoObj && vdoObj.ended) || state.videoPlayedCount >= 5) {
    console.log(state.videoPlayedCount, 'count');
    return (
      <Container>
        <InnerDiv>
          <EmptyBox show={true} onClick={handleBlackScreenClick}>
            {isVideoPlayedLimit && (
              <Message>You Have Played {state.videoPlayedCount}/5</Message>
            )}
            <ReplayIcon style={replayIconStyle} />
          </EmptyBox>
        </InnerDiv>
      </Container>
    );
  } else if (state.videoOtp && state.videoPlaybackInfo) {
    return (
      <>
        <Player
          otp={state.videoOtp}
          playBackInfo={state.videoPlaybackInfo}
          startTime={firstPlay && state.videoMillSec}
          handlePlay={handlePlay}
          handlePause={handlePause}
          handleSeek={handleSeek}
          handleEnd={handleEnd}
          handleLoad={handleLoad}
          handleProgress={handleProgress}
          vdoCompleted ={state.vdoCompleted}
        ></Player>
      </>
    );
  } else return <div></div>;
}

export default connect(() => {}, {
  updatePlayTime,
  updateCompletedTask,
  getTopicDetail,
  getVideoPlayInfo,
})(VideoPlayer);
