import { MenuItem, ThemeProvider } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import {
  listItem,
  StyledSelect,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import {
  HeadDiv,
  Heading,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';

const defaultObj = {
  id: 'all',
  name: 'all',
  title: 'All',
};

export default function Header(props) {
  const {
    subjectData,
    conceptData,
    topicData,
    handleChange,
    subjectValue,
    conceptValue,
    topicValue,
  } = props;
  return (
    <HeadDiv>
      <Heading>{'Solve by Passage'}</Heading>
      <SelectBox>
        <ThemeProvider theme={listItem}>
          <StyledSelect
            style={style.dropDownStyle}
            name={'subjectValue'}
            onChange={handleChange}
            value={subjectValue}
            IconComponent={() => null}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
            disabled
          >
            {subjectData &&
              subjectData.length !== 0 &&
              subjectData.map(subject => {
                return <MenuItem value={subject.id}>{subject.title}</MenuItem>;
              })}
          </StyledSelect>
          <StyledSelect
            style={style.dropDownStyle}
            name={'conceptValue'}
            onChange={handleChange}
            value={conceptValue}
            IconComponent={() => null}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
            disabled
          >
            {conceptData &&
              conceptData.length !== 0 &&
              conceptData.map(concept => {
                return <MenuItem value={concept.id}>{concept.title}</MenuItem>;
              })}
          </StyledSelect>

          {/* <StyledSelect
            style={style.dropDownStyle}
            name={'topicValue'}
            onChange={handleChange}
            value={topicValue}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
            // disabled
          >
            {[defaultObj, ...topicData].map(topic => (
              <MenuItem key={topic.id} value={topic.id}>
                {topic.title}
              </MenuItem>
            ))}
           
          </StyledSelect> */}
        </ThemeProvider>
      </SelectBox>
    </HeadDiv>
  );
}

const style = {
  dropDownStyle: {
    minWidth: '250px',
    maxWidth: '250px',
  },
};
