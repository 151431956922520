/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'inherit',
    padding: '24px 0 12px 0',

    '& .MuiStepConnector-alternativeLabel': {
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: '2px',
      borderColor: '#1093ff',
    },
    '& .MuiStepLabel-label': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '30px',
      color: '#052A4E',
      opacity: '0.8',
    },
    '& .MuiStep-alternativeLabel': {
      flex: 'unset',
    },
  },
});

function StepIcons(props) {
  const { active, completed } = props;

  return (
    <div>
      {completed ? (
        <CheckCircleIcon color="primary" />
      ) : active ? (
        <RadioButtonCheckedIcon color="primary" />
      ) : (
        <RadioButtonUncheckedIcon color="primary" />
      )}
    </div>
  );
}

export default function StepperComponent(props) {
  const classes = useStyles();
  const { totalSteps, activeStep } = props;
  return (
    <Stepper className={classes.root} alternativeLabel activeStep={activeStep}>
      {Array.from(Array(totalSteps), (e, n) => {
        return (
          <Step key={n + 1}>
            <StepLabel StepIconComponent={StepIcons}>Section {n + 1}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
