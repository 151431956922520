/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Dialog,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import DurationIcon from '@material-ui/icons/Update';
import React from 'react';
import { WebinarIconButton } from '../../../../assets/css/dashboard/CalendarComponents.js';
import {
  ActionButton,
  DialogActions,
  DialogContent,
  DialogDivider,
  DialogTitle,
  webinarTheme,
} from '../../../../assets/css/dashboard/webinarStyles/Components.js';
import '../../../../assets/css/dashboard/webinarStyles/Webinar.css';
import CalendarIcon from '../../../../assets/icons/calendar_today.svg';
import CloseIcon from '../../../../assets/icons/closeblue.svg';

function JoinWebinar(
  {
    heading,
    profilePic,
    hostName,
    openDialog,
    dialogDate,
    dialogTime,
    duration,
    checkhrs,
    webinarOverview,
    userName,
    email,
    phone,
    status,
    handleDialogClose,
    handleActionClick,
    unregister,
  },
  handleUnregisterClick = () => {}
) {
  const getActionButtonText = status => {
    if (status === 'Live') return 'Join now';
    else return 'Add to Calendar';
  };

  return (
    <ThemeProvider theme={webinarTheme}>
      <Dialog fullWidth maxWidth={'md'} open={openDialog}>
        <DialogTitle disableTypography>
          <Typography variant="h1">{heading}</Typography>
          <WebinarIconButton onClick={handleDialogClose}>
            <img src={CloseIcon} alt="" />
          </WebinarIconButton>
        </DialogTitle>
        <DialogContent>
          <div className="webinar-dialog-sub-header">
            <div className="webinar-dialog-sub-header-left">
              <Box pb={1.5}>
                <Typography variant="h2">Webinar Host</Typography>
              </Box>
              <div className="profile-container">
                {/* <img src={profilePic} alt="" className="dialog-profile" /> */}
                {profilePic && profilePic.match(/\.(jpg|jpeg|png|gif)$/) ? (
                  <img src={profilePic} alt="" className="profile-pic" />
                ) : (
                  ''
                )}
                <Typography variant="h3">{hostName}</Typography>
              </div>
            </div>
            <div className="webinar-dialog-sub-header-right">
              <div className="webinar-dialog-sub-header-right-inner">
                <img src={CalendarIcon} alt="" />
                <Typography variant="h4">{dialogDate}</Typography>
              </div>
              <div className="webinar-dialog-sub-header-right-inner">
                <QueryBuilder color="primary" />
                <Typography variant="h4">{dialogTime}</Typography>
              </div>
            </div>
          </div>

          <Box pt={2.8} pb={2.5}>
            <DialogDivider />
          </Box>
          <Grid container>
            <Grid item xs={6} className="dialog-left-content">
              <Typography variant="h2">Webinar Overview</Typography>
              <Typography>{webinarOverview}</Typography>
              <div className="duration-container">
                <DurationIcon color="primary" />
                <Typography variant="h4">{checkhrs}</Typography>
              </div>
            </Grid>

            <Grid item xs={6}>
              <Box ml={5} className="text-fields">
                <TextField
                  label={<Typography variant="h5">Full Name</Typography>}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disableUnderline: true,
                    className: 'input-field',
                  }}
                  disabled
                  value={userName}
                  fullWidth
                />
                <TextField
                  label={<Typography variant="h5">Email ID</Typography>}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disableUnderline: true,
                    className: 'input-field',
                  }}
                  disabled
                  value={email}
                  fullWidth
                />
                <TextField
                  label={<Typography variant="h5">Phone Number</Typography>}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disableUnderline: true,
                    className: 'input-field',
                  }}
                  disabled
                  value={phone}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        {unregister && (
          <Box p={6}>
            <ActionButton
              color="primary"
              onClick={() => handleActionClick(getActionButtonText(status))}
            >
              Remove from calender
            </ActionButton>
          </Box>
        )}

        {!unregister && (
          <DialogActions>
            <div className="button-container">
              <ActionButton
                color="primary"
                variant="contained"
                onClick={handleDialogClose}
              >
                Cancel
              </ActionButton>
              <ActionButton
                color="primary"
                variant="contained"
                onClick={() => handleActionClick(getActionButtonText(status))}
              >
                {getActionButtonText(status)}
              </ActionButton>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
}

export default JoinWebinar;
