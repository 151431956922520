/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class AreaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
    this.renderToday = this.renderToday.bind(this);
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.width !== window.innerWidth)
        this.setState({
          width: window.innerWidth,
        });
    }, 1000);
  }

  renderToday = seconds => {
    var hours = parseInt(seconds / 3600);
    var minutes = parseInt(seconds / 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `Today ${hours}.${minutes}`;
  };

  render() {
    const series = [
      {
        name: 'Spent time',
        data: this.props.data.days.map(item => item.timeSpend / 3600),
      },
    ];

    const options = {
      chart: {
        id: 'chart',
        foreColor: '#838383',
        toolbar: {
          autoSelected: 'selection',
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      colors: ['#A58CFC'],
      grid: {
        position: 'back',
        show: true,
        strokeDashArray: 2,
        borderColor: '#1093FF',
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories:
          this.props.value === 1
            ? this.props.data.days.map(item => item.day)
            : this.props.data.days.map(item => 'Week ' + item.weekNo),
        tickPlacement: 'between',
        axisBorder: {
          show: true,
          height: 2,
          color: '#A58CFC',
        },
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          show: true,
          width: 1,
          opacity: 0.9,
          stroke: {
            color: '#A58CFC',
            width: 2,
            dashArray: 2,
          },
        },
        labels: {
          show: true,
          offsetX: 0,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        max: this.props.value === 1 ? 6 : 42,
        tickAmount: this.props.value === 1 ? 4 : 3,
        forceNiceScale: true,
        axisBorder: {
          show: true,
          width: 1.5,
          color: '#A58CFC',
        },
        labels: {
          formatter: function (value) {
            if (value > 0) return value.toFixed(2) + ' hr';
            else return value;
          },
          offsetX: -1.4,
          offsetY: 0,
        },
      },
      stroke: {
        show: true,
        width: 2,
        lineCaps: 'round',
      },
      fill: {
        gradient: {
          enabled: true,
          shadeIntensity: 1,
          opacityFrom: 0.65,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      markers: {
        size: 5,
        colors: ['#F3F6FF'],
        strokeColor: '#A58CFC',
        strokeWidth: 3,
        offsetX: 0,
        offsetY: -0.5,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      tooltip: {
        theme: 'dark',
        enabled: true,
        x: {
          show: true,
          format: 'dd dddd',
        },
      },
      annotations: {
        position: 'front',
        points: [
          {
            x:
              this.props.value === 1
                ? this.props.data.days.find(item => item.isToday).day
                : `Week ${
                    this.props.data.days[this.props.data.days.length - 1][
                      'weekNo'
                    ]
                  }`,
            y:
              this.props.value === 1
                ? this.props.data.days.find(item => item.isToday).timeSpend /
                  3600
                : this.props.data.days[this.props.data.days.length - 1][
                    'timeSpend'
                  ] / 3600,
            marker: {
              size: 6,
              fillColor: '#FFFFFF',
              strokeColor: '#A58CFC',
              strokeWidth: 3,
            },
            label: {
              borderColor: '#1093FF',
              borderWidth: 1,
              borderRadius: 4,
              text:
                this.props.value === 1
                  ? this.renderToday(
                      this.props.data.days.find(item => item.isToday).timeSpend
                    )
                  : this.renderToday(
                      this.props.data.days[this.props.data.days.length - 1][
                        'timeSpend'
                      ]
                    ),
              offsetX: 0,
              offsetY: -8,
              style: {
                background: '#1093FF',
                color: '#92E0EE',
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: 'Montserrat',
                padding: {
                  left: 8,
                  right: 8,
                  top: 2,
                  bottom: 3,
                },
              },
            },
            customSVG: {
              SVG: `<path d="M24 11.305l-7.997 11.39L8 11.305z" fill="#1093FF" />`,
              offsetX: -16,
              offsetY: -15,
            },
          },
        ],
        xaxis: [
          {
            x:
              this.props.value === 1
                ? this.props.data.days.find(item => item.isToday).day
                : `Week ${
                    this.props.data.days[this.props.data.days.length - 1][
                      'weekNo'
                    ]
                  }`,
            borderColor: '#A58CFC',
            borderWidth: 3,
            strokeDashArray: 0,
            offsetX: 0,
            offsetY: 0,
            fillColor: '#FFFFFF',
          },
        ],
      },
    };

    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart chart__container">
            <Chart
              options={options}
              series={series}
              type="area"
              width={
                this.state.width <= 1024
                  ? this.state.width <= 768
                    ? '420'
                    : '290'
                  : this.state.width >= 1300
                  ? '630'
                  : '580'
              }
              height="220"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AreaChart;
