/**
 * (c) CareerLabs. All rights reserved.
 **/
import axios from 'axios';
import Cookies from 'js-cookie';
import { decrypt } from '../utils/Crypto';
import { _COOKIE } from '../api/API';

axios.interceptors.request.use(
  function (request) {
    let token = null;
    if (Cookies.get('accessToken')) {
      token = decrypt(Cookies.get('accessToken'));
    }
    const decryptToken = token;
    if (decryptToken) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${decryptToken}`,
        // 'Access-Control-Allow-Origin': '*',
      };
    }
    return request;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (successRes) {
    return successRes;
  },
  async function (error) {
    const { response } = error;
    if (response) {
      if (
        response.data.status === 500 ||
        response.data.status === 404 ||
        response.data.status === 403 ||
        response.data.status === 401
      ) {
        Cookies.remove('accessToken', { path: '/', domain: _COOKIE.DOMAIN });
        Cookies.remove('studentId', { path: '/', domain: _COOKIE.DOMAIN });
        Cookies.remove('refreshToken', { path: '/', domain: _COOKIE.DOMAIN });
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = '/auth';
      }
    }
    return Promise.reject(error);
  }
);

export const GET = (url, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .get(url, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const POST = (url, data, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .post(url, data, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const PUT = (url, data, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .put(url, data, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const DELETE = (url, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .delete(url, {
      params: {
        ...param,
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};

export const IMAGE__PUT = (url, data, params, response, error) => {
  const param = params !== undefined ? params : {};
  return axios
    .put(url, data, {
      params: {
        ...param,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      response(res);
    })
    .catch(err => {
      error(err);
    });
};
