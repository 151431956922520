import { Box, Grid, LinearProgress } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CustomTypo,
  CustomTypo1,
  CustomTypography,
  FlexFiller,
  FlexViewContainer,
} from '../../../assets/css/StyledComponent';
import { FlexView } from '../../../assets/css/StyledDashboardComponent';
import {
  ActionLink,
  ContinueLink,
  StartLink,
  BottomIcon,
  BoxContainer,
  Card,
  FlexRow,
  Progress,
  ResultIcon,
  TestContainer,
  TestSubTitle,
  TestTitle,
} from '../../../assets/css/StyledOfficialGuideComponents';
import complete from '../../../assets/icons/complete.svg';
import clock from '../../../assets/images/ClockImage.png';
import easy from '../../../assets/images/Easy.svg';
import hard from '../../../assets/images/Hard.svg';
import medium from '../../../assets/images/Medium.svg';
import notepad from '../../../assets/images/notepad.png';
import topicReport from '../../../assets/icons/topicReport.svg';

const STATUS_OPTIONS = {
  RETAKE: {
    buttonText: 'Re-attempt',
    button: ActionLink,
  },
  PROGRESS: {
    buttonText: 'Continue',
    button: ContinueLink,
  },
  START: {
    buttonText: 'Start',
    button: StartLink,
  },
};

const ICONS = {
  EASY: easy,
  MEDIUM: medium,
  HARD: hard,
};

const EXAM_PROGRESS = ['RETAKE', 'PROGRESS'];

class TopicTestCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { testData, handleButtonClick, handleReportClick } = this.props;

    return (
      <Grid container spacing={4}>
        {testData &&
          Array.isArray(testData) &&
          testData?.length !== 0 &&
          testData?.map((item, id) => {
            const BUTTON = STATUS_OPTIONS[item.status]['button'];
            return (
              <Grid item xs={12} sm={6}>
                <Card key={id}>
                  <BoxContainer>
                    <FlexRow>
                      <TestContainer>
                        <TestTitle>{item.title}</TestTitle>
                      </TestContainer>

                      <BUTTON
                        className={'on-boost-mode'}
                        onClick={() =>
                          handleButtonClick(
                            item.id,
                            item.status,
                            item.allowHard
                          )
                        }
                      >
                        {STATUS_OPTIONS[item.status]['buttonText']}
                      </BUTTON>
                    </FlexRow>
                    <FlexView>
                      <TestSubTitle>{item.startDateTablet}</TestSubTitle>
                    </FlexView>
                    <Progress>
                      <FlexRow>
                        {item.status === 'RETAKE' ? (
                          <FlexViewContainer gridGap={'8px'}>
                            <ResultIcon src={complete} />
                            <CustomTypo {...style.progressText}>
                              {'Completed'}
                            </CustomTypo>
                          </FlexViewContainer>
                        ) : item.status === 'PROGRESS' ? (
                          <FlexViewContainer gridGap={'8px'}>
                            <ResultIcon src={complete} />
                            <CustomTypo {...style.progressText}>
                              {`${item.progress}%`}
                            </CustomTypo>
                            <FlexFiller />
                            <CustomTypo {...style.progressText}>
                              {'In Progress'}
                            </CustomTypo>
                          </FlexViewContainer>
                        ) : (
                          <Box pl={1}>
                            <CustomTypo {...style.progressText}>
                              {'Progress'}
                            </CustomTypo>
                          </Box>
                        )}
                      </FlexRow>
                      <LinearProgress
                        variant={'determinate'}
                        style={style.progressBar}
                        value={
                          EXAM_PROGRESS.includes(item.status)
                            ? item.progress
                            : 0
                        }
                      />
                    </Progress>

                    <FlexViewContainer padding={'20px 10px 10px'}>
                      <FlexViewContainer>
                        <BottomIcon src={ICONS[item.difficultyLevel]} />
                        <CustomTypo
                          {...style.bottomText}
                          className={'capitalize'}
                        >
                          {item.difficultyLevel.toLowerCase()}
                        </CustomTypo>
                      </FlexViewContainer>

                      <FlexViewContainer>
                        <BottomIcon src={clock} />
                        <CustomTypo {...style.bottomText}>
                          {' '}
                          {`${item.duration} Mins`}
                        </CustomTypo>
                      </FlexViewContainer>

                      <FlexViewContainer>
                        <BottomIcon src={notepad} />
                        <CustomTypo {...style.bottomText}>
                          {item.status === 'RETAKE'
                            ? `${item.score}/${item.totalQuestions}`
                            : `${item.totalQuestions} Q`}
                        </CustomTypo>
                      </FlexViewContainer>
                      {item.status === 'RETAKE' ? (
                        <FlexViewContainer>
                          <BottomIcon src={topicReport} />
                          <CustomTypography
                            {...style.bottomText}
                            onClick={() =>
                              handleReportClick(item.testExecutionId)
                            }
                          >
                            {'Report'}
                          </CustomTypography>
                        </FlexViewContainer>
                      ) : (
                        ''
                      )}
                      <div />
                    </FlexViewContainer>
                  </BoxContainer>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    );
  }
}

export default withRouter(TopicTestCard);

export const style = {
  progressBar: {
    background: 'rgba(215, 212, 214, 0.5)',
    margin: '6px 0',
  },
  bottomText: {
    textColor: '#052a4e',
    padding: '3px 0 0',
    fontWeight: 500,
  },
  progressText: {
    textColor: '#838383',
    padding: '3px 0 0',
    fontWeight: 500,
  },
  overflow: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 260px)',
  },
};

