/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  StrengthCard,
  TopicAvatar,
  TopicContainer,
  TopicContent,
  TopicHeader,
} from '../../../../assets/css/dashboard/Landing';
import { IMAGE_URL } from '../../../../constant/Variables';
import { routePaths } from '../../../../routes/RoutePath';
import * as track from '../../../../mixpanel/Events';
import { EVENTS } from '../../../../clevertap/Events';
import { getClickSourceDetails } from '../../../../redux/action/Student';
import clevertap from 'clevertap-web-sdk';

class StrengthsCard extends Component {
  render() {
    const { t } = this.props;

    return (
      <TopicContainer height={'382px'}>
        <TopicHeader>
          <Box display={'flex'} alignItems={'center'} gridGap={10}>
            <TopicAvatar
              src={`${IMAGE_URL}image+dashboard/strengthlogo.png`}
              height={'32px'}
              width={'32px'}
            />
            <Box fontSize={20} fontWeight={500} color={'#1093FF'}>
              {t('Strengths')}
            </Box>
          </Box>
        </TopicHeader>
        <TopicContent height={'280px'} overflow={'unset'}>
          {this.props.strengthTask && this.props.strengthTask.length !== 0 ? (
            this.props.strengthTask.slice(0, 4).map(({ title, image }) => {
              return (
                <StrengthCard>
                  <TopicAvatar
                    src={`${image}`}
                    alt="nt"
                    width={24}
                    height={24}
                  />
                  {title}
                </StrengthCard>
              );
            })
          ) : (
            <img
              src={`${IMAGE_URL}image+dashboard/strengthlogo.png`}
              alt={''}
              height={'60%'}
              style={{ margin: '0px auto' }}
            />
          )}
        </TopicContent>
        <Box
          textAlign={'center'}
          padding={2}
          fontWeight={500}
          fontSize={14}
          color={'#1093FF'}
          style={{ cursor: 'pointer', backgroundColor: '#fff' }}
          display={
            this.props.strengthTask
              ? this.props.strengthTask.length !== 0
                ? 'unset'
                : 'none'
              : 'none'
          }
          onClick={() => {
            this.props.getClickSourceDetails(
              'View more button from strength',
              res => {
                if (res.success) {
                  track.DashboardFirstAction({
                    'Product Name': JSON.parse(
                      localStorage.getItem('userProduct')
                    ).productName,
                    'First Action': 'Strengths',
                  });
                  clevertap.event.push(
                    EVENTS.LMSUserLandsOnWeaknessAndStrengthSections,
                    {
                      'Click source': 'View more button from strength',
                      'Count of clicks': res.data.count,
                    }
                  );
                  this.props.history.push(
                    routePaths.dashboard.strengthsWeakness
                  );
                }
              }
            );
          }}
        >
          {t('View More')}
        </Box>
      </TopicContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    strengthTask: state.dashboardLandingReducer.strengthTopic,
  };
};

export default connect(mapStateToProps, { getClickSourceDetails })(
  withRouter(withTranslation()(StrengthsCard))
);
