/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Box,
  Grid,
  Hidden,
  ThemeProvider,
  withStyles,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import { CLEVERTAP } from '../../../../clevertap/Config';
import { EVENTS } from '../../../../clevertap/Events';
import { IMAGE_URL } from '../../../../constant/Variables';
import { getCourseImage, getGoals } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { updateExamDetails } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import Button from '../../../../utils/components/Button';

class Score extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: '',
      value:
        JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'GMAT FOCUS'
          ? 200
          : 550,
      marks: [],
      isLoading: false,
    };
  }

  handleClick = () => {
    this.setState({ isLoading: true });
    let obj = {
      productName: JSON.parse(window.localStorage.getItem('userProduct'))
        .productName,
      targetScore: this.state.value,
    };
    this.props.updateExamDetails(obj, res => {
      this.setState({ isLoading: false });
      this.props.getCourses();
      this.props.history.push(routePaths.test);
    });
  };

  componentDidMount() {
    this.props.getCourses();
    this.props.getGoals(response => {
      if (response.success) {
        this.setState({
          value: response.data.targetScore,
          marks: response.data.marks.map(item => {
            return { label: item.label, value: parseInt(item.value) };
          }),
        });
      }
    });
    let obj1 = {
      courseId: JSON.parse(window.localStorage.getItem('userProduct')).id,
      endPoint: 'target score',
    };
    this.props.getCourseImage(obj1, response => {
      this.setState({ img: IMAGE_URL + response.data.value });
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { t } = this.props;
    const local = JSON.parse(
      window.localStorage.getItem('userProduct')
    ).productName;
    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={'gmat_grid_container'}
        >
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className={'grid_align'}
          >
            <Box pt={5}>
              <h2 className={'gmat_title'}>
                {t(
                  `What is your target ${
                    JSON.parse(window.localStorage.getItem('userProduct'))
                      .productName
                  } Score ?`
                )}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} className={'gmat_exam_align'}>
            <Box pt={8}>
              <Hidden smDown>
                <img src={this.state.img} alt="" />
              </Hidden>
              <Hidden mdUp>
                <img style={{ width: '100%' }} src={this.state.img} alt="" />
              </Hidden>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
            <Box pt={10}>
              <Hidden smDown>
                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                  {/* <Slider style={{width:"400px"}}
                    ValueLabelComponent={this.ValueLabelComponent}
                    aria-label="custom thumb label"
                    defaultValue={20}
                  /> */}

                  {this.state.marks.length !== 0 && (
                    <PrettoSlider
                      key={`slider-a`}
                      className={'pretto_slider_align'}
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                      marks={this.state.marks}
                      step={local === 'GMAT FOCUS' ? 5 : 1}
                      defaultValue={Math.min(
                        ...this.state.marks.map(item => parseInt(item.value))
                      )}
                      min={Math.min(
                        ...this.state.marks.map(item => parseInt(item.value))
                      )}
                      max={Math.max(
                        ...this.state.marks.map(item => parseInt(item.value))
                      )}
                      onChange={this.handleChange}
                      value={this.state.value}
                      // ValueLabelComponent={this.ValueLabelComponent}
                    />
                  )}
                </div>
              </Hidden>
              <Hidden mdUp>
                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                  <PrettoSlider
                    className={'pretto_slider_align'}
                    valueLabelDisplay="on"
                    aria-label="pretto slider"
                    marks={this.state.marks}
                    step={local === 'GMAT FOCUS' ? 5 : 1}
                    defaultValue={Math.min(
                      ...this.state.marks.map(item => parseInt(item.value))
                    )}
                    min={Math.min(
                      ...this.state.marks.map(item => parseInt(item.value))
                    )}
                    max={Math.max(
                      ...this.state.marks.map(item => parseInt(item.value))
                    )}
                    onChange={this.handleChange}
                    value={this.state.value}
                    // ValueLabelComponent={this.ValueLabelComponent}
                  />
                </div>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={2} mt={-3}>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  className={'gmat_button_align'}
                  variant="contained"
                  color="primary"
                  onClick={this.handleClick}
                  loading={this.state.isLoading}
                >
                  {t('Next')}
                </Button>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const PrettoSlider = withStyles({
  root: {
    color: '#1093FF',
    height: 8,
    width: '800px',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '4px solid #003396',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + -4px)',
  },
  track: {
    height: 8,
    borderRadius: 2,
  },
  rail: {
    height: 8,
    borderRadius: 2,
  },
})(Slider);

const mapStateToProps = state => {
  return {
    examDetailsResponse: state.testReducer.examDetails,
    courses: state.profileReducer.courses,
    goal: state.CourseReducer.goals,
  };
};

export default connect(mapStateToProps, {
  updateExamDetails,
  getCourses,
  getCourseImage,
  getGoals,
})(withTranslation()(Score));
