/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import { Container } from '../../assets/css/CourseComponent';
import Header from '../../utils/Header';
import Index from '../dashboard/course/Index';

export default class CourseLayout extends Component {
  render() {
    return (
      <div>
        <Container>
          <Header />
          <Index />
        </Container>
      </div>
    );
  }
}
