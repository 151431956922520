/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, withStyles, Zoom } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../../assets/css/Cal.css';
import { LightTooltip } from '../../../assets/css/dashboard/CalendarComponents';
import {
  Header,
  TaskSubTitle,
  TopicAvatar,
  TopicItem,
  TopicListContainer,
  TopicTitleContainer,
} from '../../../assets/css/dashboard/Landing';
import { PopOver } from '../../../assets/css/StyledComponent';
import { EVENTS } from '../../../clevertap/Events';
import { MONTHS } from '../../../constant/Variables';
import * as track from '../../../mixpanel/Events';
import { getStudyPlan, getPlanDetails } from '../../../redux/action/Dashboard';
import { routePaths } from '../../../routes/RoutePath';
import WeeklyCalender from '../../../utils/components/calendar/WeeklyCalendar';
import { getWeekOfMonth } from '../../../utils/methods/Helper';
import { styles } from './Styles';
import clevertap from 'clevertap-web-sdk';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      currentMonth: new Date().getMonth() + 1,
      open: null,
      selectedItem: null,
    };
  }

  weekDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  componentDidMount() {
    this.props.getStudyPlan(() => {});
    this.props.getPlanDetails();
  }

  componentWillUnmount() {
    this.enable();
  }

  renderTableBody = month => {
    const { studyPlan } = this.props;
    if (studyPlan.success) {
      var obj;
      var arr = [];
      for (let j = 0; j < studyPlan.data.length; j++) {
        if (studyPlan.data[j].month === month) {
          for (let i = 0; i < studyPlan.data[j].days.length; i++) {
            obj = {
              ...obj,
              [studyPlan.data[j].days[i].day]: studyPlan.data[j].days[i],
            };

            if (i === studyPlan.data[j].days.length - 1) {
              arr.push(obj);
              obj = null;
            }
            if (i !== 0 && (i + 1) % 7 === 0) {
              arr.push(obj);
              obj = null;
            }
          }
        }
      }

      const MyComponent = React.forwardRef(function MyComponent(props, ref) {
        return (
          <div {...props} ref={ref}>
            {props.children}
          </div>
        );
      });
      const Topics = ({ topics, date, year }) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            {/* Header */}
            <Box
              textAlign={'right'}
              p={1}
              fontWeight={600}
              fontSize={16}
              lineHeight={'20px'}
              color={'#1093FF'}
            >
              {date + ' ' + MONTHS[month] + ' ' + year}
            </Box>
            <Box display={'flex'} flexDirection={'column'} gridGap={10}>
              {topics.map(
                ({ id, concept, title, imageUrl, progress, task }) => {
                  return (
                    <TopicItem
                      onClick={() =>
                        this.props.history.push(
                          routePaths.dashboard.taskDetail + '?topicId=' + id
                        )
                      }
                    >
                      <TopicAvatar src={`${imageUrl}`} alt="" />
                      <TopicTitleContainer flex={1}>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          gridGap={10}
                        >
                          {concept}
                        </Box>
                        <Box
                          fontSize={10}
                          fontWeight={500}
                          lineHeight={'12px'}
                          color={'#052A4E'}
                        >
                          {title}
                        </Box>
                      </TopicTitleContainer>
                      <TaskSubTitle></TaskSubTitle>
                    </TopicItem>
                  );
                }
              )}
            </Box>
          </Box>
        );
      };

      return arr.map((data, index) => {
        return (
          <tr className={'calender__table__body'}>
            <td>
              {data &&
                data.Mon &&
                (data.Mon.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Mon.topics}
                        date={data.Mon.date}
                        year={data.Mon.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Mon.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Mon && data.Mon.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Tue &&
                (data.Tue.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Tue.topics}
                        date={data.Tue.date}
                        year={data.Tue.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Tue.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Tue && data.Tue.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Wed &&
                (data.Wed.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Wed.topics}
                        date={data.Wed.date}
                        year={data.Wed.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Wed.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Wed && data.Wed.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Thu &&
                (data.Thu.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Thu.topics}
                        date={data.Thu.date}
                        year={data.Thu.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Thu.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Thu && data.Thu.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Fri &&
                (data.Fri.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Fri.topics}
                        date={data.Fri.date}
                        year={data.Fri.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Fri.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Fri && data.Fri.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Sat &&
                (data.Sat.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Sat.topics}
                        date={data.Sat.date}
                        year={data.Sat.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Sat.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Sat && data.Sat.date}</MyComponent>
                ))}
            </td>
            <td>
              {data &&
                data.Sun &&
                (data.Sun.topics.length !== 0 ? (
                  <LightTooltip
                    TransitionComponent={Zoom}
                    title={
                      <Topics
                        topics={data.Sun.topics}
                        date={data.Sun.date}
                        year={data.Sun.year}
                      />
                    }
                    arrow
                  >
                    <MyComponent>{data.Sun.date}</MyComponent>
                  </LightTooltip>
                ) : (
                  <MyComponent>{data && data.Sun && data.Sun.date}</MyComponent>
                ))}
            </td>
          </tr>
        );
      });
    }
  };

  onHover = (event, item) => {
    const { studyPlan } = this.props;
    if (studyPlan.success) {
      var filterObj =
        studyPlan.data.find(
          obj => obj.month === item.month + 1 && obj.year === item.year
        ) &&
        studyPlan.data
          .find(obj => obj.month === item.month + 1)
          .days.find(d => d.date === item.date);
      if (filterObj && filterObj.topics.length !== 0) {
        this.setState({ open: event.currentTarget, selectedItem: filterObj });
      } else {
        this.handleClose();
      }
    }
  };

  handleClose = e => {
    this.setState({ open: null, selectedItem: null });
  };

  renderPopOver = () => {
    const { handleClose } = this;

    const Topics = ({ topics, date, year }) => {
      return (
        <Box display={'flex'} flexDirection={'column'}>
          {/* Header */}
          <Box
            textAlign={'right'}
            p={1}
            fontWeight={600}
            fontSize={16}
            lineHeight={'20px'}
            color={'#1093FF'}
            padding={'0 14px 7px'}
          >
            {date + ' ' + MONTHS[this.state.currentMonth] + ' ' + year}
          </Box>
          <TopicListContainer>
            {topics.map(
              ({
                id,
                concept,
                title,
                imageUrl,
                progress,
                task,
                count,
                duration,
              }) => {
                return (
                  <TopicItem
                    onClick={() => {
                      clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
                        'Concept name': concept,
                        'Topic name': title,
                        'Watched source': `Via study plan weekly calendar - ${
                          this.props.location.pathname.match(
                            routePaths.dashboard.subject
                          )
                            ? 'subject'
                            : 'dashboard'
                        } section`,
                      });
                      this.props.history.push(
                        routePaths.dashboard.taskDetail +
                          '?topicId=' +
                          id +
                          '&CalenderPopup=CalenderPopup'
                      );
                    }}
                  >
                    <TopicAvatar src={`${imageUrl}`} alt="" />
                    <TopicTitleContainer flex={1}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        gridGap={10}
                      >
                        {concept}
                      </Box>
                      <Box
                        fontSize={10}
                        fontWeight={500}
                        lineHeight={'12px'}
                        color={'#052A4E'}
                      >
                        {title}
                      </Box>
                    </TopicTitleContainer>
                    <TaskSubTitle>
                      <Box whiteSpace="nowrap">{duration}</Box>
                      <Box whiteSpace="nowrap">{count} Tasks</Box>
                    </TaskSubTitle>
                  </TopicItem>
                );
              }
            )}
          </TopicListContainer>
        </Box>
      );
    };
    return (
      <PopOver
        id={'mouse-over-popover'}
        open={Boolean(this.state.open)}
        anchorEl={this.state.open}
        className={this.props.classes.calendarPaper}
        // placement={'bottom-start'}
        transition
      >
        {this.state.selectedItem && (
          <>
            <Topics
              topics={this.state.selectedItem.topics}
              date={this.state.selectedItem.date}
              year={this.state.selectedItem.year}
            />
          </>
        )}
      </PopOver>
    );
  };

  preventScroll = e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  disable = () => {
    document
      .querySelector('#root')
      .addEventListener('wheel', this.preventScroll);
  };

  enable = () => {
    document
      .querySelector('#root')
      .removeEventListener('wheel', this.preventScroll);
  };

  render() {
    const { t, planDetails } = this.props;

    if (this.state.open) this.disable();
    else this.enable();
    return (
      <>
        <Header color={'#398F3E'}>{t('Your Customized Study Plan')}</Header>
        <Box p={2}>
          <WeeklyCalender
            studyPlanStartDate={planDetails.data?.startDate}
            studyPlanEndDate={planDetails.data?.endDate}
            month={this.state.currentMonth}
            year={new Date().getFullYear()}
            currentWeek={getWeekOfMonth(new Date())}
            onMouseOut={this.handleClose}
            onClose={this.handleClose}
            onHover={this.onHover}
            renderContainer={this.renderPopOver}
            onMonthChange={month => this.setState({ currentMonth: month })}
          />

          <button
            className="study__button"
            onClick={() => {
              track.calenderView({
                'Product Name': JSON.parse(localStorage.getItem('userProduct'))
                  .productName,
              });
              track.DashboardFirstAction({
                'Product Name': JSON.parse(localStorage.getItem('userProduct'))
                  .productName,
                'First Action': 'Study Plan',
              });
              clevertap.event.push(EVENTS.LMSUserSelectsStudyPlan, {
                'Click source': this.props.location.pathname.match(
                  routePaths.dashboard.subject
                )
                  ? 'Subject page'
                  : 'Dashboard page',
              });
              this.props.history.push(
                routePaths.dashboard.studyPlan +
                  '?month=' +
                  this.state.currentMonth
              );
            }}
          >
            {t('View Study Plan')}
          </button>
        </Box>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    studyPlan: state.DashBoardReducer.studyPlan,
    planDetails: state.DashBoardReducer.planDetails,
  };
};

export default connect(mapStateToProps, { getStudyPlan, getPlanDetails })(
  withRouter(withTranslation()(withStyles(styles)(Index)))
);
