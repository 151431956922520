/**
 * (c) CareerLabs. All rights reserved.
 **/

const URL = process.env.REACT_APP_API_URL;

export const _COOKIE = {
  EXPIRE: process.env.REACT_APP_COOKIE_EXPIRE_TIME,
  DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
};

export const DEFAULT_URL = `${URL}/api/v1`;
