/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../../../assets/css/App.css';
import {
  Card,
  CardBody,
  CardContent,
  CardFooter,
  CardHeader,
  CardHeaderTitle,
  CardItem,
  CardTitle,
  ChartTitle,
  ChartValue,
  Indicator,
  Item,
  ItemCaption,
  ItemIcon,
  PieChartContainer,
  PieChartDescription,
  PieChartLabel,
  PieChartValue,
  PieChartWrapper,
  ReportCardContainer,
  SummaryText,
} from '../../../../assets/css/report/Report';
import Circle from '../../../../assets/icons/circle.svg';
import InfoIcon from '../../../../assets/icons/info_outline1.svg';
import Right from '../../../../assets/icons/right.svg';
import Wrong from '../../../../assets/icons/wrong.svg';
import Doughnut from '../graph/Doughnut';
import Pie from '../graph/Pie';

class ReportCard extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.data === nextProps.data) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { donut, pie, name } = this.props.data;

    var data = {
      datasets: [
        {
          labels: donut.scoreTitle,
          data: donut.score,
          backgroundColor: donut.colorCode,
        },
      ],
      percentage: donut.percentage,
      summaryName: donut.title,
      total: donut.overall.total,
      count: donut.overall.score,
      labelCards: donut.total,
      card: pie.card,
      pieScore: pie.score,
    };
    const { t } = this.props;
    return (
      <ReportCardContainer>
        <SummaryText>{t(`${name} Summary`)}</SummaryText>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item xs={12} sm={4} md={4}>
            <PieChartContainer>
              <Item width={200}>
                <Doughnut
                  data={data}
                  animation={false}
                  maintainAspectRatio={false}
                />
              </Item>
              <Item display={'flex'} direction={'column'}>
                <ChartTitle>{t(data.summaryName)}</ChartTitle>
                <ChartValue>
                  {`${data.count}`} <Box fontWeight={400}>/{data.total}</Box>{' '}
                </ChartValue>
              </Item>
            </PieChartContainer>
          </Grid>
          {data.datasets.map(dataset =>
            data.labelCards.map((item, index) => {
              return (
                <Grid item xs={12} sm={2} md={2}>
                  <Card>
                    <CardHeader>
                      <Indicator color={dataset.backgroundColor[index]} />
                      <CardHeaderTitle>{t(item.label)}</CardHeaderTitle>
                    </CardHeader>
                    <CardBody>
                      <b>{dataset.data[index]}</b>
                      {item.total !== null ? '/' : null}
                      {item.total}
                    </CardBody>
                    {item.percentage !== null ? (
                      <CardFooter>{item.percentage}%</CardFooter>
                    ) : null}
                  </Card>
                </Grid>
              );
            })
          )}
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            {this.props.percentageLabel && (
              <Box
                display={'flex'}
                pb={'5px'}
                alignItems={'center'}
                width={'100%'}
                justifyContent={'flex-end'}
                gridGap={10}
                mt={'-35px'}
              >
                <Box fontSize={14} fontWeight={400}>
                  {t('Percentile Rankings')}
                </Box>
                <Box>
                  <img title={'Percentile Rankings'} src={InfoIcon} alt={''} />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems={'flex-start'}>
          {data.card.map(item => (
            <Grid item md={4}>
              <Card minHeight={'200px'}>
                <CardTitle>{t(item.title)}</CardTitle>
                <CardContent>
                  {item.events.map(event => {
                    return (
                      <CardItem>
                        <ItemIcon>
                          <img
                            src={
                              event.icon === 'Circle'
                                ? Circle
                                : event.icon === 'Right'
                                ? Right
                                : event.icon === 'Wrong'
                                ? Wrong
                                : null
                            }
                            alt=""
                          />
                        </ItemIcon>
                        <ItemCaption flex={event.title === 'Mean Time' ? 0 : 1}>
                          {t(event.title)}
                        </ItemCaption>
                        {event.title === 'Mean Time' && (
                          <ItemCaption flex={1} pl={'5px'}>
                            <img
                              title={
                                'Time taken divided by total number of questions'
                              }
                              src={InfoIcon}
                              alt={''}
                            />
                          </ItemCaption>
                        )}
                        <ItemCaption fw={600}>
                          {event.time.toFixed(2) + ' mins'}
                        </ItemCaption>
                      </CardItem>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item md={4}>
            <Card maxHeight={'180px'} minHeight={'200px'}>
              <Box display={'flex'} flexDirection="row">
                <Box>
                  <PieChartWrapper>
                    <Pie
                      value={data.pieScore}
                      animation={false}
                      maintainAspectRatio={false}
                    />
                    <PieChartDescription>{t(pie.title)}</PieChartDescription>
                  </PieChartWrapper>
                </Box>
                <Box textAlign={'center'} width={'100%'} pt={3}>
                  {pie.time.map(item => {
                    return (
                      <Box pb={2}>
                        <PieChartLabel>{t(item.title)}</PieChartLabel>
                        <PieChartValue>{item.value}</PieChartValue>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </ReportCardContainer>
    );
  }
}
export default withTranslation()(ReportCard);
