import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TaskContainer } from '../../../../assets/css/StyledComponent';
import { getOfficialGuideTopicDetail } from '../../../../redux/action/Practice';
import { routePaths } from '../../../../routes/RoutePath';
import BreadCrumbs from '../../../../utils/BreadCrumbs';
import TaskTitleCard from './TaskTitleCard';
import LeftCard from './LeftCard';
import RightCard from './RightCard';
import { QS_PROPS } from '../../../../constant/Variables';
import QueryString from 'qs';
import PageLoader from '../../../../utils/components/PageLoader';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topicDetail: null,
      selectedStep: null,
      topicData: {},
    };
    this.videosObj = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { topicId, taskId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (!taskId) this.props.getOfficialGuideTopicDetail(topicId, null, true);
    else {
      this.setState({ selectedStep: taskId });
      this.props.getOfficialGuideTopicDetail(topicId, taskId, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { officialGuideTopicDetails } = this.props;
    const { selectedStep } = this.state;
    if (
      officialGuideTopicDetails &&
      prevProps.officialGuideTopicDetails !== officialGuideTopicDetails
    ) {
      if (officialGuideTopicDetails.success) {
        this.setState({
          topicData: officialGuideTopicDetails.data,
          selectedStep: selectedStep
            ? selectedStep
            : officialGuideTopicDetails.data.tasks[0].id,
          topicDetail: officialGuideTopicDetails,
        });
      }
    }
  }

  handleLeftCardClick = e => {
    this.setState({ selectedStep: e.target.id });
  };

  getVideoObj = (vdoObj, index) => {
    this.videosObj[index] = vdoObj;
  };

  render() {
    const { selectedStep, topicData, topicDetail } = this.state;
    const { t, loading } = this.props;

    const leftCardProps = {
      location: this.props.location,
      history: this.props.history,
      topicData: topicData,
      selectedStep: selectedStep,
      handleLeftCardClick: this.handleLeftCardClick,
      t: t,
    };

    const rightCardProps = {
      content: topicData.contents
        ?.filter(item => item.id === selectedStep && item)
        .pop(),
      getVideoObj: this.getVideoObj,
      t: t,
    };

    return loading ? (
      <PageLoader />
    ) : (
      <TaskContainer>
        <Box paddingBottom={2}>
          <BreadCrumbs
            topics={topicDetail}
            goBack={() =>
              this.props.history.push(routePaths.dashboard.officialGuide)
            }
            isOfficialGuide
            backEvent
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TaskTitleCard topicData={topicData} t={t} />
          </Grid>
          <Grid item xs={3}>
            <LeftCard {...leftCardProps} />
          </Grid>

          <Grid item xs={9}>
            <RightCard {...rightCardProps} />
          </Grid>
        </Grid>
      </TaskContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.PracticeReducer,
  };
};

export default connect(mapStateToProps, {
  getOfficialGuideTopicDetail,
})(withRouter(withTranslation()(Index)));
