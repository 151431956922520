import { Mixpanel } from './Config';

/*
 * COMMON PROPERTIES
 */

const COMMON = {
  name: '',
  email: '',
  mobileNumber: '',
  visitNumber: '',
  lastOpen: '',
  timeStamp: '',
};

/*
 * LOGIN EVENTS
 */

const emailEntered = (data = {}, type) => {
  Mixpanel.track('User - Email id field selected', {
    ...data,
    'Event Name': 'User - Email id field selected',
    status: type ? 'Successful' : 'Unsuccessful',
  });
};

const phoneNumberEntered = (data = {}, type = false) => {
  Mixpanel.track('User - Phone number selected', {
    ...data,
    status: type ? 'Successful' : 'Unsuccessful',
  });
};

const successfulLogin = (data = {}, type = true) => {
  Mixpanel.identify(data['id']);
  Mixpanel.people.set({
    ...data,
  });
};

const noOfTimeLogin = name => {
  Mixpanel.people.increment(`No. of times ${name} field selected`);
};

/*
 * ONBOARDING
 */

const collegeClick = (collegeLength = 0) => {
  Mixpanel.track('User - Clicks on the college field', {
    'Event Name': 'User - Clicks on the college field',
    'college fields': `${collegeLength} of field click`,
  });
};

/*
 * CALIBRATION TEST
 */

const calibrationTest = (data = {}, skip = true) => {
  Mixpanel.track(
    'User- selected skip the test option or User click on Start the test',
    {
      ...data,
      Start: skip ? 'No' : 'Yes',
      Skip: skip ? 'Yes' : 'No',
    }
  );
};

const afterCalibration = (data = {}, status) => {
  Mixpanel.track(
    'User- Click on continue after reaching on Calibration test screen',
    {
      ...data,
      'Exam taken': status ? 'Yes' : 'No',
    }
  );
};

const pauseExam = (data = {}, noOfClick = 0) => {
  Mixpanel.track('User click on pause exam', {
    ...data,
  });
  Mixpanel.people.increment('pause exam');
};

const numberOfSectionFinished = () => {
  // Mixpanel.track("No of section to finish Calibration test", {
  //   ...data,
  //   "No of section": numberOfSection,
  // });
  Mixpanel.people.increment('No of session to finish Calibration test');
};

/**
 * REPORT PAGE
 */

const reportSessionTime = (data = {}) => {
  Mixpanel.track('Report session time', {
    ...data,
  });
};

const reportFirstAction = (data = {}, testType = 'Calibration') => {
  Mixpanel.track(`Report first action  after ${testType} test`, {
    ...data,
  });
};
/**
 * REPORT OG PAGE
 */

const reportGmatAction = (data = {}, testType = 'GmatOG') => {
  Mixpanel.track(`Report first action  after ${testType} test`, {
    ...data,
  });
};

/**
 * Dashboard
 */

const dashboardFirstAction = (data = {}) => {
  Mixpanel.track('Dashboard First Action', {
    'Dashboard First Action': data['First Action'],
  });
};

const firstAction = (data = {}) => {
  Mixpanel.track('Dashboard First Action', {
    ...data,
    'First Click': new Date().toDateString(),
  });
};

/**
 * Study plan
 */

const pendingTopic = (data = {}, pendingTopics = []) => {
  Mixpanel.track('Pending Topics', {
    ...data,
    'Pending Topic List':
      pendingTopics.length !== 0
        ? pendingTopics.map(item => item.title).join(',')
        : 'No pending topics',
    'No of topics in pending list': pendingTopics.length,
  });
};

/**
 * Task
 */

const taskVideoOpened = (data = {}) => {};

const taskVideoPaused = (data = {}) => {};

const taskVideoResumed = (data = {}) => {};

/**
 * Notes
 */

const notesPageLanding = (data = {}) => {
  Mixpanel.track('User Click on notes page', {
    ...data,
  });
  Mixpanel.people.increment('No of times notes section is clicks');
};

/**
 * Bookmark
 */

const bookmarkPageLanding = (data = {}) => {
  Mixpanel.track('User Click on bookmark option', {
    ...data,
  });
  Mixpanel.people.increment('No. of times  bookmark feature is clicked');
};

/**
 * Error Log
 */

const errorLogPageLanding = name => {
  // Mixpanel.track("User Click on error log feature", {
  //   ...data,
  //   "No. of times  error log feature is clicked": clicks,
  // });
  Mixpanel.people.increment(`No. of times ${name} feature is clicked`);
};

/**
 * Calender View
 */

const calenderView = (data = {}) => {
  Mixpanel.track('User Click on calender view', {
    ...data,
  });
  Mixpanel.people.increment('No. of times  calender view is clicked');
};

/**
 * Support Page
 */

const supportPageLanding = (data = {}, clicks) => {
  Mixpanel.track('User Click on support feature', {
    ...data,
  });
  Mixpanel.people.increment('No. of times  support feature is clicked');
};

/**
 * Live Support
 */

const liveSupportChat = (data = {}) => {
  Mixpanel.track('User Click on live support feature', {
    ...data,
  });
  Mixpanel.people.increment('No. of times  live support feature is clicked');
};

export {
  emailEntered,
  phoneNumberEntered,
  successfulLogin,
  collegeClick,
  calibrationTest,
  afterCalibration,
  pauseExam,
  numberOfSectionFinished,
  reportFirstAction,
  dashboardFirstAction as DashboardFirstAction,
  taskVideoOpened,
  taskVideoPaused,
  taskVideoResumed,
  pendingTopic,
  notesPageLanding,
  bookmarkPageLanding,
  errorLogPageLanding,
  calenderView,
  supportPageLanding,
  liveSupportChat,
  reportSessionTime,
  noOfTimeLogin,
  firstAction,
  reportGmatAction,
};
