import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  ThemeProvider,
  Checkbox,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import {
  listItem,
  StyledSelect,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import { questionBankTheme } from '../../../assets/css/MuiStyles';
import {
  CustomTypo,
  FlexViewContainer,
} from '../../../assets/css/StyledComponent';
import {
  FilterContainer,
  HeadDiv,
  Heading,
  MuiDivider,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filterIcon.svg';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';
import TopicTestIcon from '../../../assets/icons/ViewDetails.svg';
import { TopicImg } from '../../../assets/css/StyledTopicComponent';

const DEFAULT_OBJECT = {
  id: 'all',
  title: 'ALL',
};

export default function Header(props) {
  const {
    subjectValue,
    subjectData,
    conceptValue,
    conceptData,
    topicValue,
    topicData,
    attemptStatus,
    difficultyLevel,
    handleChange,
    handleAttemptStatus,
    handleDifficultyChange,
  } = props;
  const STATUS = [
    { value: 'ALL', title: 'All' },
    { value: 'ATTEMPTED', title: 'Attempted' },
    { value: 'NOTATTEMPTED', title: 'Not Attempted' },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HeadDiv>
          <FlexViewContainer paddingLeft={'20px'}>
            <TopicImg src={TopicTestIcon} />
            <Heading>{'Topic Tests'}</Heading>
          </FlexViewContainer>
          <FlexViewContainer gridGap={'12px'}>
            <FlexViewContainer gridGap={'6px'}>
              <CustomTypo variant={'subtitle1'}>{'Solve by Filter'}</CustomTypo>
              <FilterIcon />
            </FlexViewContainer>
            <SelectBox>
              <ThemeProvider theme={listItem}>
                <StyledSelect
                  style={style.dropDownStyle}
                  name={'subjectValue'}
                  onChange={handleChange}
                  value={subjectValue}
                  IconComponent={ExpandMore}
                  disableUnderline
                  MenuProps={DROP_DOWN_PROPS}
                >
                  {[DEFAULT_OBJECT, ...(subjectData || [])].map(subject => {
                    return (
                      <MenuItem value={subject.id}>{subject.title}</MenuItem>
                    );
                  })}
                </StyledSelect>

                <StyledSelect
                  style={style.dropDownStyle}
                  name={'conceptValue'}
                  onChange={handleChange}
                  value={conceptValue}
                  IconComponent={ExpandMore}
                  disableUnderline
                  MenuProps={DROP_DOWN_PROPS}
                >
                  {[DEFAULT_OBJECT, ...(conceptData || [])].map(concept => {
                    return (
                      <MenuItem value={concept.id}>{concept.title}</MenuItem>
                    );
                  })}
                </StyledSelect>

                <StyledSelect
                  style={style.dropDownStyle}
                  onChange={handleChange}
                  name={'topicValue'}
                  value={topicValue}
                  IconComponent={ExpandMore}
                  disableUnderline
                  MenuProps={DROP_DOWN_PROPS}
                >
                  {[DEFAULT_OBJECT, ...(topicData || [])].map(topic => {
                    return <MenuItem value={topic.id}>{topic.title}</MenuItem>;
                  })}
                </StyledSelect>
              </ThemeProvider>
            </SelectBox>
          </FlexViewContainer>
        </HeadDiv>
      </Grid>

      <Grid item xs={12}>
        <FilterContainer p={'0 0 10px 0'}>
          <ThemeProvider theme={listItem}>
            <StyledSelect
              name={'attemptStatus'}
              onChange={handleAttemptStatus}
              value={attemptStatus}
              IconComponent={ExpandMore}
              disableUnderline
              MenuProps={DROP_DOWN_PROPS}
            >
              {STATUS.map(status => {
                return <MenuItem value={status.value}>{status.title}</MenuItem>;
              })}
            </StyledSelect>
            <MuiDivider orientation={'horizontal'}></MuiDivider>
            <ThemeProvider theme={questionBankTheme}>
              <FormGroup style={{ paddingLeft: '11px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={'EASY'}
                      checked={difficultyLevel.includes('EASY')}
                      onChange={handleDifficultyChange}
                    />
                  }
                  label={'Easy'}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name={'MEDIUM'}
                      checked={difficultyLevel.includes('MEDIUM')}
                      onChange={handleDifficultyChange}
                    />
                  }
                  label={'Medium'}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name={'HARD'}
                      checked={difficultyLevel.includes('HARD')}
                      onChange={handleDifficultyChange}
                    />
                  }
                  label={'Hard'}
                />
              </FormGroup>
            </ThemeProvider>
          </ThemeProvider>
        </FilterContainer>
      </Grid>
    </Grid>
  );
}

const style = {
  dropDownStyle: {
    minWidth: '210px',
    maxWidth: '210px',
  },
};
