/**
 * (c) CareerLabs. All rights reserved.
 **/
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import CourseReducer from './CourseReducer';
import {
  DashboardLandingReducer,
  DashboardQuestionBankReducer,
  DashboardReducer,
} from './DashboardReducer';
import PracticeReducer from './PracticeReducer';
import ProfileReducer from './ProfileReducer';
import StudentReducer from './StudentReducer';
import TestReducer from './TestReducer';
import OfficialGuideReducer from './OfficialGuideReducer';


export default combineReducers({
  AuthReducer: AuthReducer,
  profileReducer: ProfileReducer,
  studentReducer: StudentReducer,
  testReducer: TestReducer,
  CourseReducer: CourseReducer,
  dashboardReducer: DashboardReducer,
  DashBoardReducer: DashboardReducer,
  dashboardLandingReducer: DashboardLandingReducer,
  DashboardQuestionBankReducer: DashboardQuestionBankReducer,
  PracticeReducer,
  OfficialGuideReducer,
 
});
