/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import ProfileViewCard from '../../../../utils/ProfileViewCard';

export default class EducationDetail extends Component {
  render() {
    const { degree, college, university, startDate, endDate, department } =
      this.props;

    return (
      <div>
        <ProfileViewCard>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box pt={1}>
                <h3 className="profile__detail__card">
                  {degree} {degree.length !== 0 && '|'}
                  &nbsp;&nbsp;{department}
                </h3>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box pt={1}>
                <h6 className="profile__detail__card">{college}</h6>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {university && startDate && endDate && (
                <Box pt={1}>
                  <h6 className="profile__detail__card">
                    {university + ' | ' + startDate + '-' + endDate}
                  </h6>
                </Box>
              )}
            </Grid>
          </Grid>
        </ProfileViewCard>
      </div>
    );
  }
}
