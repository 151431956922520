/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';
import {
  Card,
  CardTime,
  NextArrow,
  PrevArrow,
  Title,
} from '../../../assets/css/practice/WebinarComponents';
import VideoCard from './components/VideoCard';

function RecentlyAdded({ posts, handleWatchVideoClick, beforeChange }) {
  const slickSettings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: beforeChange,
  };
  return (
    <Box mt={5}>
      <div className="h2">Recently Added</div>
      <Slider {...slickSettings}>
        {posts.map(post => (
          <div>
            <Card>
              <VideoCard
                src={post.videoUrl}
                onClick={() => handleWatchVideoClick(post)}
                otp={post.videoOtp}
                playBackInfo={post.videoPlaybackInfo}
              />
              <Title>{post.title}</Title>
              <CardTime>{post.cardDate}</CardTime>
            </Card>
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default RecentlyAdded;
