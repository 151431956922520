/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EmptyTestContainer,
  FlexJustifyView,
  FlexView,
  HeaderBox,
  SmallIcon,
  TaskText,
  TestContainer,
  TestHeader,
  TestIcon,
  TestLevelIcon,
  TestLevelText,
  TestText,
  TextContainer,
} from '../../../assets/css/StyledDashboardComponent.js';
import easy from '../../../assets/images/easy.png';
import medium from '../../../assets/images/medium.png';
import hard from '../../../assets/images/Liner.png';
import question from '../../../assets/icons/questionMark.svg';
import right from '../../../assets/icons/right.svg';
import wrong from '../../../assets/icons/wrong.svg';
import time from '../../../assets/icons/time1.svg';
import list from '../../../assets/icons/list.svg';

const Icons = {
  easy: easy,
  medium: medium,
  hard: hard,
  qmark: question,
  right: right,
  wrong: wrong,
  time: time,
  noOfQuestion: list,
};

export const Test = props => {
  const { data, type, isTest } = props;
  return (
    <Grid container spacing={3}>
      {data.length !== 0
        ? data.map(item => {
            const { name, status, icon, colorCode } = item;
            return (
              <Grid item xs={12} md={6}>
                <HeaderBox>
                  <TestHeader color={colorCode}>
                    <FlexView>
                      <TestLevelText>
                        {type !== 'QBTEST' ? (
                          <>
                            {'Difficulty level'}
                            <b>{` (${name})`}</b>
                          </>
                        ) : (
                          <>
                            <b>{name}</b>
                            {` Questions`}
                          </>
                        )}
                      </TestLevelText>
                      <TestLevelIcon src={Icons[icon]} />
                    </FlexView>
                  </TestHeader>
                  {status.length !== 0 ? (
                    <TestContainer>
                      <Grid container spacing={3}>
                        {status.map(item => {
                          const { icon, name, result } = item;
                          return (
                            <Grid item xs={12} lg={6}>
                              <FlexJustifyView>
                                <FlexView>
                                  <TestIcon
                                    padding={
                                      icon === 'right' || icon == 'wrong'
                                    }
                                    src={Icons[icon]}
                                  />
                                  <TestText>{name}</TestText>
                                </FlexView>
                                <FlexView>
                                  <TestText bold={true}>{result}</TestText>
                                </FlexView>
                              </FlexJustifyView>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </TestContainer>
                  ) : (
                    <EmptyTestContainer>
                      <div>You have not taken</div>
                      <div>{`${name} ${
                        type !== 'QBTEST' ? 'topic test' : 'questions'
                      } yet`}</div>
                    </EmptyTestContainer>
                  )}
                </HeaderBox>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export const Task = props => {
  const { data, handleTask, IconsType, handleTestClick } = props;
  console.log(props.isTest, 'sushi');
  return (
    <Grid container spacing={3}>
      {data.length !== 0
        ? data.map(item => {
            const { id, status, title, isTest } = item;

            return (
              <Grid item xs={12}>
                <FlexView>
                  <SmallIcon src={IconsType[status]} />
                  <TaskText
                    id={id}
                    // onClick={
                    //   // isTest === true &&
                    //   status === 'PROGRESS' || status === 'TODO '
                    //     ? handleTask(item)
                    //     : // handleTestClick
                    //       () => {}
                    // }
                    onClick={e =>
                      status === 'PROGRESS' || status === 'TODO'
                        ? handleTask(e, item)
                        : () => {}
                    }
                    cursor={status === 'PROGRESS' || status === 'TODO'}
                    bold={status === 'PROGRESS'}
                  >
                    {title}
                  </TaskText>
                </FlexView>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export const Text = props => {
  const { data } = props;
  return <TextContainer>{data}</TextContainer>;
};
