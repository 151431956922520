import React, { Component } from 'react';
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import {
  CardShadow,
  InstructionDivider,
  TestScore,
  TestScores,
} from '../../../../assets/css/StyledComponent';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import NotePad from '../../../../assets/images/notepad.png';
import '../../../../assets/css/App.css';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import TimerPopup from './TimerPopup';

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogState: false,
      dialogType: '',
      testFilterId: null,
    };
  }
  handleClick = () => {
    this.setState({ dialogState: true });
  };

  dialogClose = () => {
    this.setState({ dialogState: false });
  };

  dialogYesBtn = () => {
    localStorage.removeItem('lmsTimerpopup');
    this.props.onClick(this.props.data.id, this.props.data.status);
  };

  dialogNoBtn = () => {
    this.props.onClick(this.props.data.id, this.props.data.status);
    localStorage.setItem('lmsTimerpopup', 'TimerPopup');
  };

  render() {
    const { data, t, og_course } = this.props;
    let date = new Date(data.date);
    let month = date.getMonth();

    let year = date.getFullYear();
    let date1 = date.getDate();
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let monthName = months[month];
    let startDate = date1 + ' ' + monthName + ' ' + year;
    const { dialogState, testFilterId } = this.state;
    const { dialogClose, dialogYesBtn, dialogNoBtn } = this;
    return (
      <div>
        <CardShadow>
          <Grid container>
            {/* notepad image */}
            <Grid item md={1}>
              <Box pl={3}>
                <img
                  className={'topic_test_image_style'}
                  src={NotePad}
                  alt="NotePad"
                />
              </Box>
              <Box pl={2} className={'date_align'}>
                {startDate}
              </Box>
            </Grid>
            <Grid item md={1}></Grid> {/* your score */}
            <Grid item md={2} className={'question_grid'}>
              <Box pt={0} pr={20}>
                <TestScore className={'text_style'}>
                  {t('Total Questions')}
                </TestScore>
              </Box>
              <Box pt={1} pr={20}>
                <TestScores>{data && data.totalQuestion}</TestScores>
              </Box>
            </Grid>
            {/* divider */}
            <Grid item md={1}>
              <Box>
                <InstructionDivider className={'start_instruction_divider'} />
              </Box>
            </Grid>
            <Grid item md={1}>
              <Box>
                <InstructionDivider className={'start_divider'} />
              </Box>
            </Grid>
            {/* test Duration */}
            <Grid item md={2} className={'question_grid'}>
              {(!og_course || og_course === 'GRE' || og_course === 'GMAT') && (
                <>
                  <Box pt={0} pr={15}>
                    <TestScore>{t('Test Duration')}</TestScore>
                  </Box>
                  <Box pt={1} pr={15}>
                    <TestScores className={'text_style'}>
                      {data && data.totalTime}
                    </TestScores>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item md={1}></Grid> {/* button */}
            <Grid item md={3}>
              <Box pt={4}>
                <ThemeProvider theme={buttonTheme}>
                  <Button
                    className={'btn__alignment'}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      og_course === 'GMAT'
                        ? this.handleClick()
                        : this.props.onClick(data.id, data.status)
                    }
                    style={{ marginTop: 0 }}
                  >
                    {t('Start Test')}
                  </Button>
                </ThemeProvider>
              </Box>
            </Grid>
            <TimerPopup
              dialogState={dialogState}
              dialogClose={dialogClose}
              dialogYesBtn={dialogYesBtn}
              dialogNoBtn={dialogNoBtn}
              testFilterId={testFilterId}
            />
          </Grid>
        </CardShadow>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Start));
