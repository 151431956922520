import React, { Component } from 'react';
import { LinearProgress, Box } from '@material-ui/core';
import {
  Card,
  TitleContainer,
  SubTitle,
  BoxContainer,
  ResultIcon,
  TopicContainer,
  TopicImg,
  FlexRow,
  TestContainer,
  TestTitle,
  TestSub,
  ActionLink,
  BottomContent,
  BottomIcon,
  Content,
  Progress,
  ContinueLink,
  StartLink,
} from '../../../../assets/css/StyledQuestionComponent';
import notepad from '../../../../assets/images/notepad.png';
import complete from '../../../../assets/icons/correctCircle.svg';
import easy from '../../../../assets/images/easy.png';
import medium from '../../../../assets/images/medium.png';
import hard from '../../../../assets/images/Liner.png';
import clock from '../../../../assets/images/ClockImage.png';
import { withRouter } from 'react-router';
import { routePaths } from '../../../../routes/RoutePath';
import { MONTHS } from '../../../../constant/Variables';
import { FlexFiller } from '../../../../assets/css/StyledComponent';

class TopicTestCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topic: {
        title: 'Topic Test',
      },
    };
  }

  handleButtonClick = topicId => {
    this.props.history.push(
      `${routePaths.dashboard.instruction}?testQuestionId=${topicId}`
    );
  };

  render() {
    const { topic } = this.state;
    const { t } = this.props;
    return (
      <>
        <Card>
          <TopicContainer>
            <TitleContainer>
              <TopicImg src={notepad} />
              <SubTitle>{t(topic.title)}</SubTitle>
            </TitleContainer>

            {this.props.item &&
              this.props.item.map((item, index) => {
                let date = new Date(item.startDate);
                let startDate =
                  date.getDate() +
                  ' ' +
                  MONTHS[date.getMonth()] +
                  ' ' +
                  date.getFullYear();

                return (
                  <Card key={index}>
                    <BoxContainer>
                      <FlexRow>
                        <TestContainer>
                          <TestTitle>{item.title}</TestTitle>
                          {item.status === 'RETAKE' ? (
                            <TestSub>{startDate}</TestSub>
                          ) : (
                            ''
                          )}
                          {item.status === 'PROGRESS' ? (
                            <TestSub>{startDate}</TestSub>
                          ) : (
                            ''
                          )}
                        </TestContainer>
                        {item.status === 'RETAKE' ? (
                          <ActionLink
                            onClick={() => this.handleButtonClick(item.id)}
                            className="on-boost-mode"
                          >
                            {t('Re-take')}
                          </ActionLink>
                        ) : (
                          ''
                        )}
                        {item.status === 'PROGRESS' ? (
                          <ContinueLink
                            onClick={() => this.handleButtonClick(item.id)}
                            className="on-boost-mode"
                          >
                            {t('Continue')}
                          </ContinueLink>
                        ) : (
                          ''
                        )}
                        {item.status === 'START' ? (
                          <StartLink
                            onClick={() => this.handleButtonClick(item.id)}
                            className="on-boost-mode"
                          >
                            {t('Start')}
                          </StartLink>
                        ) : (
                          ''
                        )}
                      </FlexRow>

                      <Progress>
                        <FlexRow>
                          {item.status === 'RETAKE' ? (
                            <Content>
                              {item.status === 'RETAKE' ? (
                                <ResultIcon src={complete} />
                              ) : (
                                <span>{item.progress + '%'}&nbsp;</span>
                              )}
                              <span>{t('Completed')}</span>
                            </Content>
                          ) : (
                            ''
                          )}
                          {item.status === 'PROGRESS' ? (
                            <Content>
                              {item.status === 'PROGRESS' ? (
                                <span>{item.progress + '%'}&nbsp;</span>
                              ) : (
                                ''
                              )}
                              <span>{t('Completed')}</span>
                              <FlexFiller />
                              <Box>{t('In Progress')}</Box>
                            </Content>
                          ) : (
                            ''
                          )}
                        </FlexRow>

                        <LinearProgress
                          variant="determinate"
                          value={
                            item.status === 'PROGRESS'
                              ? item.progress
                              : item.status === 'START'
                              ? 0
                              : item.status === 'RETAKE'
                              ? item.progress
                              : ''
                          }
                        />
                      </Progress>

                      <FlexRow>
                        <BottomContent>
                          {item.difficultyLevel === 'EASY' ? (
                            <BottomIcon src={easy} />
                          ) : (
                            ''
                          )}
                          {item.difficultyLevel === 'MEDIUM' ? (
                            <BottomIcon src={medium} />
                          ) : (
                            ''
                          )}
                          {item.difficultyLevel === 'HARD' ? (
                            <BottomIcon src={hard} />
                          ) : (
                            ''
                          )}
                          <span className="capitalize">
                            {t(item.difficultyLevel.toLowerCase())}
                          </span>
                        </BottomContent>

                        <BottomContent>
                          <BottomIcon src={clock} />
                          <span>{item.duration} Mins</span>
                        </BottomContent>

                        <BottomContent>
                          <BottomIcon src={notepad} />
                          <span>
                            {item.status === 'RETAKE'
                              ? item.score + '/' + item.totalQuestions
                              : item.totalQuestions + ' Q.'}
                          </span>
                        </BottomContent>
                      </FlexRow>
                    </BoxContainer>
                  </Card>
                );
              })}
          </TopicContainer>
        </Card>
      </>
    );
  }
}

export default withRouter(TopicTestCard);
