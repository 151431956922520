/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import MuiDivider from '@material-ui/core/Divider';
import MuiButton from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { createTheme } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const Main = styled.div`
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  background: ${({ background }) =>
    `${background}`.match(/\.(jpg|jpeg|png|gif)$/)
      ? `url(${background})`
      : 'linear-gradient(140deg, rgba(0,0,0,1) 0%, rgba(65,0,79,0.9976365546218487) 55%, rgba(255,110,249,1) 100%)'};
  border-radius: 8px;
  height: 180px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  * {
    z-index: 1;
  }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   left: 0px;
  //   border-radius: 8px;
  // }
`;

export const Container = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

export const WebinarCss = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ background }) =>
    `${background}`.match(/\.(jpg|jpeg|png|gif)$/)
      ? 'rgba(0, 0, 0, 0.3)'
      : 'none'};
`;

export const LiveBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  width: fit-content;
  align-items: center;
`;

export const Heading = styled.div`
  font-weight: ${props => (props.live ? 600 : 500)};
  font-size: ${props => (props.live ? '12px' : '16px')};
  line-height: ${props => (props.live ? '15px' : '20px;')};
  color: #ffffff;
`;

export const Divider = styled(MuiDivider)`
  background: white;
  width: 25%;
`;

export const Name = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding-top: 12px;
`;

export const Button = withStyles({
  root: {
    borderRadius: '30px',
    padding: '5px 18px',
    textTransform: 'none',
    minWidth: '90px',
    marginTop: '18px',
  },
})(MuiButton);

export const DialogTitle = withStyles({
  root: {
    background: '#EAF5FF',
    borderRadius: '8px 8px 0px 0px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px 40px',
  },
})(MuiDialogTitle);

export const DialogContent = withStyles({
  root: {
    padding: '26px 50px 0 50px',

    display: 'flex',
    flexDirection: 'column',
  },
})(MuiDialogContent);

export const webinarTheme = createTheme({
  palette: {
    primary: {
      main: '#1093ff',
    },
    secondary: { main: '#052A4E' },
  },
  typography: {
    h1: {
      fontWeight: '600',
      fontSize: '28px',
      lineHeight: '34px',
      color: '#1093FF',
    },
    h2: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#052A4E',
    },
    h3: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '22px',
      color: '#052A4E',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#052A4E',
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      color: '#052A4E',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
      color: '#838383',
    },
  },
});

export const DialogDivider = withStyles({
  root: {
    background: 'rgba(16, 147, 255, 0.2)',
  },
})(MuiDivider);

export const ActionButton = withStyles({
  root: {
    width: '211px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'none',
    height: '48px',
    borderRadius: '30px',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
  },
})(MuiButton);

export const DialogActions = withStyles({
  root: {
    justifyContent: 'center',
    padding: '32px',
  },
})(MuiDialogActions);
