/**
 *
 * @param {} el html element
 *
 */

const simulateMouseClick = async el => {
  let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
  el.dispatchEvent(new MouseEvent('mousedown', opts));
  await new Promise(r => setTimeout(r, 50));
  el.dispatchEvent(new MouseEvent('mouseup', opts));
  el.dispatchEvent(new MouseEvent('click', opts));
};

export default simulateMouseClick;
