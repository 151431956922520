/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/css/App.css';
import { RoutePageName } from '../../assets/css/StyledComponent';
import NavigateBack from '../../assets/images/navigateBack.png';
import NavigateNext from '../../assets/images/navigateNext.png';
import { routePaths } from '../../routes/RoutePath';

class Navigation extends Component {
  handleClick = () => {
    this.props.history.push(routePaths.dashboard.home);
  };

  handleBackClick = () => {
    this.props.history.push(this.props.backPath);
  };

  render() {
    return (
      <div className={'navigation_main_div'}>
        <Box>
          <img
            src={NavigateBack}
            className="cursor"
            alt=""
            onClick={this.handleBackClick}
          />
        </Box>
        <Box>
          <Breadcrumbs
            className={'breadCrumbs'}
            separator={
              <Box pl={1} pr={1}>
                <img src={NavigateNext} alt={''} className="cursor" />
              </Box>
            }
            aria-label="breadcrumb"
          >
            <RoutePageName onClick={this.handleClick}>Dashboard</RoutePageName>
            <RoutePageName className="disable-click">Study plan</RoutePageName>
          </Breadcrumbs>
        </Box>
      </div>
    );
  }
}

export default withRouter(Navigation);
