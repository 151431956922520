/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import '../../assets/css/Player.css';
import Player from '../Player';

/**
 * @param {String} otp
 * @param {String} playBackInfo
 * @param {Function} getVideoObj
 * @param {Number} startTime milliseconds
 * @param {Number} playCount
 */

function VideoPlayerComponent(props) {
  const [state, setState] = useState({
    videoMillSec: 0,
    videoOtp: '',
    videoPlaybackInfo: '',
  });

  // To seek the previous position on the first time play only
  const [firstPlay, setFirstPlay] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getVdoObj();

    setFirstPlay(true);
  }, [props.videoPlaybackInfo]);

  function getVdoObj() {
    setState({
      ...state,
      videoMillSec: props.videoMillSec,
      videoOtp: props.videoOtp,
      videoPlaybackInfo: props.videoPlaybackInfo,
    });
  }

  function registerPlayerPosition() {
    getVdoObj();
  }

  function handlePause() {
    registerPlayerPosition();
  }

  function handleEnd() {
    // If video ended then routing automatically
    if (props.isAutoRouting && props.handleAutoRoute) {
      props.handleAutoRoute();
    }
  }

  function handleSeek() {}

  function handlePlay(vdoObj) {
    console.log(vdoObj,"playoj")
    props.getVideoObj(vdoObj,props.index);
    setFirstPlay(false);
  }

  function handleLoad(vdoObj) {
    props.getVideoObj(vdoObj,props.index);
  }

  if (state.videoOtp && state.videoPlaybackInfo) {
    return (
      <Player
        otp={state.videoOtp}
        playBackInfo={state.videoPlaybackInfo}
        startTime={firstPlay && state.videoMillSec}
        handlePlay={handlePlay}
        handlePause={handlePause}
        handleSeek={handleSeek}
        handleEnd={handleEnd}
        handleLoad={handleLoad}
        isSlider={props.isSlider}
      />
    );
  } else return <div></div>;
}

export default VideoPlayerComponent;
