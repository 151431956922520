/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Card, Container, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { dashboardTheme } from '../../../assets/css/MuiStyles';
import {
  ProgressPosition,
  SideDiv,
} from '../../../assets/css/subject/LandingComponents';
import { EVENTS } from '../../../clevertap/Events';
import * as track from '../../../mixpanel/Events';
import {
  getAllDashboard,
  getWebinar,
  isVisited,
  registerUser,
  deleteStudy,
} from '../../../redux/action/DashboardLanding';
import ConfirmationDialog from '../notes/ConfirmationDialog';
import { getClickSourceDetails } from '../../../redux/action/Student';
import {
  personalizedStudyPlan,
  updateExamDetails,
  getIntroductionVideos,
  getCleverTabCommonProperties,
} from '../../../redux/action/Test';
import { getCourses } from '../../../redux/action/Profile';
import { routePaths } from '../../../routes/RoutePath';
import PageLoader from '../../../utils/components/PageLoader';
import joinMeeting from '../../../utils/components/ZoomMeeting';
import Calendar from '../calendar/Calendar';
import AreachartCard from './component/AreachartCard';
import CalibrationCard from './component/CalibrationCard';
import DiagnosticEngineBoostCard from './component/DiagnosticEngineHeaderCard';
import DoughnutCard from './component/DoughnutCard';
import JoinWebinar from './component/JoinWebinar';
import PendingTopicCard from './component/PendingTopicCard';
import StrengthsCard from './component/StrengthsCard';
import TodaysTopicCard from './component/TodaysTopicCard';
import WeaknessCard from './component/WeaknessCard';
import Webinar from './component/Webinar';
import WelcomeCard from './component/Welcomecard';
import Guide from './userGuide/Guide';
import DialogComponent from '../../../utils/components/DialogComponent';
import CardIcon from '../../../assets/icons/CalibrationIcon.svg';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import clevertap from 'clevertap-web-sdk';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeConceptIndex: 0,
      active: 1,
      openWebinarPopup: false,
      joinWebinarObj: {},
      dialogOpen: false,
      showWebinar: false,
      open: false,
    };
    this.dialogText = {
      title: 'Do you want to see Introduction Video?',
      body: 'if not Press No',
      button1: 'No',
      button2: 'Yes',
    };
  }
  // const dialogText = {
  //   title: t('Are you sure you want to Delete ?'),
  //   body: t('if not Press Continue'),
  //   button1: t('Cancel'),
  //   button2: t('Yes'),
  // };
  componentDidMount() {
    window.scrollTo(0, 0);
    track.firstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
    });
    let popupShown = window.sessionStorage.getItem('IntroPopupShown');
    let productName = JSON.parse(
      window.localStorage.getItem('userProduct')
    ).productName;

    this.props.getWebinar();
    console.log(popupShown, 'popupshown');
    this.props.getAllDashboard(res => {
      if (productName === 'GRE') {
        if (res.data.introVideoCompleted === false) {
          console.log(popupShown, 'popupshown');
          if (popupShown !== 'true') {
            this.setState({
              dialogOpen: true,
            });
            window.sessionStorage.setItem('IntroPopupShown', true);
          }
        }
      }
      console.log(res, 'responseeeee');
    });

    this.props.isVisited('DASHBOARD');

    /* Getting common_properties from localStorage and send it onUserLogin. */
    this.props.getCleverTabCommonProperties(res => {
      if (res.success) {
        let common_properties = JSON.parse(
          window.localStorage.getItem('user_common_properties') || '{}'
        );
        clevertap.onUserLogin.push({
          Site: {
            ...common_properties,
          },
        });
      }
    });
    /* Getting common_properties from localStorage and send it onUserLogin. */
  }

  handleDelete = () => {
    this.props.history.push('/calibration/introduction-to-lms/?profile=true');
  };
  handleDialogClose = () => {
    this.props.getIntroductionVideos(response => {});
    this.setState({ dialogOpen: false });
  };
  handleDiagnosticEngineButton = (text, topicId) => {
    if (text === 'Continue') {
      this.props.getClickSourceDetails(
        'Continue button on the top banner',
        res => {
          if (res.success) {
            clevertap.event.push(
              EVENTS.LMSUserLandsOnWeaknessAndStrengthSections,
              {
                'Click source': 'Continue button on the top banner',
                'Count of clicks': res.data.count,
              }
            );
            this.props.history.push(
              `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
            );
          }
        }
      );
    } else {
      this.props.getClickSourceDetails(
        'Revise button on the top banner',
        res => {
          if (res.success) {
            clevertap.event.push(
              EVENTS.LMSUserLandsOnWeaknessAndStrengthSections,
              {
                'Click source': 'Revise button on the top banner',
                'Count of clicks': res.data.count,
              }
            );
            this.props.history.push(routePaths.dashboard.strengthsWeakness);
          }
        }
      );
    }
  };

  handleCalibration = () => {
    clevertap.event.push(EVENTS.LMSUserClicksOnTakeCalibrationTest);
    this.props.history.push(`${routePaths.start}?isTakeCalibration=false`);
  };

  handleWebinarActionClick = (obj, text) => {
    const userData = this.props.user;

    if (text === 'View Calendar') {
      this.props.history.push(`${routePaths.dashboard.studyPlan}`);
    } else {
      const joinWebinarObj = {
        heading: obj.title,
        webinarOverview: obj.description,
        profilePic: obj.hostImageUrl,
        hostName: obj.hostName,
        dialogDate: obj.date,
        dialogTime: obj.time,
        duration: obj.remainingHours,
        status: obj.status,
        id: obj.id,
        wallWebinar: obj.wallWebinar,
        zoomLink: obj.zoomLink,
        openDialog: true,
        userName: userData ? `${userData.firstName} ${userData.lastName}` : '',
        email: userData ? userData.email : '',
        phone: userData ? userData.phoneNumber : '',
        handleDialogClose: this.handleDialogClose,
        checkhrs: obj.remainingHours,
      };

      this.setState({
        joinWebinarObj: joinWebinarObj,
      });
    }
  };

  handleCancelClick = () => {
    this.setState({
      joinWebinarObj: {},
    });
  };

  handleProgressTracker = () => {
    this.props.getClickSourceDetails(
      EVENTS.LMSUserClicksOnTheProgressTracker,
      res => {
        if (res.success) {
          clevertap.event.push(EVENTS.LMSUserClicksOnTheProgressTracker, {
            'Count of times': res.data.count,
          });
          this.props.history.push(routePaths.dashboard.progressTracker);
        }
      }
    );
  };

  handleWebinarPopupActionClick = text => {
    if (text === 'Join now') {
      if (this.state.joinWebinarObj.wallWebinar !== null) {
        const url = new URL(this.state.joinWebinarObj.wallWebinar.join_url);
        joinMeeting(
          `${this.props.user.firstName} ${this.props.user.lastName}`,
          this.props.user.email,
          this.state.joinWebinarObj.zoomLink,
          url.searchParams.get('tk')
        );
      } else {
        this.props.registerUser(this.state.joinWebinarObj.id, res => {
          if (res.success) {
            const url = new URL(res.data.join_url);
            joinMeeting(
              `${this.props.user.firstName} ${this.props.user.lastName}`,
              this.props.user.email,
              this.state.joinWebinarObj.zoomLink,
              url.searchParams.get('tk')
            );
          }
        });
      }
    } else if (text === 'Add to Calendar') {
      this.props.registerUser(this.state.joinWebinarObj.id, res => {
        if (res.success) this.props.getWebinar();
      });
    }

    this.handleCancelClick();
  };

  renderTaskComponents = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SideDiv className="on-focus-3rd">
            <TodaysTopicCard />
          </SideDiv>
        </Grid>

        <Grid item xs={12}>
          <SideDiv className="on-focus-4th">
            <PendingTopicCard />
          </SideDiv>
        </Grid>
      </Grid>
    );
  };

  renderCalenderAndWeakness = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SideDiv className="on-focus-1st">
            <Calendar />
          </SideDiv>
        </Grid>
        <Grid item xs={12} className="on-focus-2nd">
          <SideDiv>
            <WeaknessCard />
          </SideDiv>
        </Grid>
        <Grid item xs={12}>
          <ProgressPosition className="on-focus-5th">
            {this.renderProgressTracker()}
          </ProgressPosition>
        </Grid>
      </Grid>
    );
  };

  renderDoughnutCardAndWebinarAndStrengths = () => {
    const { concept, t, visitedPages } = this.props;

    const dashboardVisited = visitedPages && visitedPages.DASHBOARD;

    const sliderSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: false,
      dots: false,
      speed: 700,
      infinite: true,
      cssEase: 'ease-in-out',
    };

    const webinarProps = {
      openDialog: this.state.openWebinarPopup,
      userData: this.props.user,
      handleActionButtonClick: this.handleWebinarActionClick,
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            style={{
              minHeight:
                !Boolean(
                  this.props.webinarData.length !== 0 && dashboardVisited
                ) && '375px',
            }}
          >
            {concept && concept.length !== 0 && (
              <DoughnutCard
                data={concept[this.state.activeConceptIndex]}
                handleClick={e => {
                  track.DashboardFirstAction({
                    'Product Name': JSON.parse(
                      localStorage.getItem('userProduct')
                    ).productName,
                    'First Action': 'Over all summary',
                  });
                  this.setState({
                    activeConceptIndex: e.target.value,
                  });
                }}
                active={this.state.activeConceptIndex}
                concept={concept}
                t={t}
              />
            )}
          </Card>
        </Grid>
        {this.props.webinarData.length !== 0 && dashboardVisited && (
          <Grid item xs={12} id="webinar-card">
            <Slider {...sliderSettings}>
              {this.props.webinarData.map(item => (
                <Webinar webinarData={item} {...webinarProps} />
              ))}
            </Slider>
          </Grid>
        )}
        <Grid item xs={12}>
          <SideDiv className="on-focus-2nd">
            <StrengthsCard />
          </SideDiv>
        </Grid>
      </Grid>
    );
  };

  renderHeaderCard = () => {
    const {
      user: { firstName, lastName },
    } = this.props;
    const { handleDiagnosticEngineButton } = this;
    const { handleCalibration } = this;
    const { status, diagnosticStatus, t } = this.props;
    const { calibrationStatus } = this.props;
    const handleOpen = () => this.setState({ open: true });
    const handleClose = () => this.setState({ open: false });

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      bgcolor: 'background.paper',
      borderRadius: '7px',
      boxShadow: 24,
      padding: '10px 10px 30px 10px',
    };

    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: false,
      speed: 1000,
      infinite: true,
      cssEase: 'ease-in-out',
    };

    const calibrationSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      arrows: false,
      dots: false,
      speed: 1000,
      infinite: true,
      cssEase: 'ease-in-out',
    };

    return (
      <>
        {calibrationStatus === null && (
          <Slider {...settings}>
            <div key={1}>
              <WelcomeCard
                username={`${firstName} ${lastName}`}
                description={status ? status.text : ''}
                imageSrc={status ? status.image : ''}
                t={t}
              />
            </div>
            <div key={2}>
              <DiagnosticEngineBoostCard
                description={diagnosticStatus ? diagnosticStatus.subText : ''}
                subDescription={diagnosticStatus ? diagnosticStatus.text : ''}
                imageSrc={diagnosticStatus ? diagnosticStatus.image : null}
                buttonText={
                  diagnosticStatus ? diagnosticStatus.buttonText : null
                }
                handleClick={handleDiagnosticEngineButton}
                continueTopicId={
                  diagnosticStatus ? diagnosticStatus.topicId : null
                }
              />
            </div>
          </Slider>
        )}

        {calibrationStatus !== null && (
          <Slider {...calibrationSettings}>
            <div key={3}>
              <CalibrationCard
                description={calibrationStatus ? calibrationStatus.title : ''}
                subDescription={
                  calibrationStatus ? calibrationStatus.content : ''
                }
                buttonText={
                  calibrationStatus ? calibrationStatus.buttonText : null
                }
                subDescription2={
                  calibrationStatus ? calibrationStatus.remainingDays : ''
                }
                // handleCalibration={handleCalibration}
                handleCalibration={handleOpen}
              />
            </div>
          </Slider>
        )}
        <Modal
          open={this.state.open}
          onClose={() => {
            handleClose();
            setTimeout(() => {
              handleCalibration();
            }, 300);
          }}
        >
          <Box sx={style}>
            <div
              style={{
                textAlign: 'right',
                margin: 0,
                paddingRight: 5,
              }}
            >
              <CloseIcon
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    handleCalibration();
                  }, 300);
                }}
                fontSize="medium"
                color="primary"
                style={{ cursor: 'pointer' }}
              />
            </div>
            <Typography
              style={{
                color: 'red',
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: 600,
                margin: '20px 0px',
              }}
            >
              Please note that this will reset your current progress and start a
              personalized study plan
            </Typography>
          </Box>
        </Modal>
      </>
    );
  };

  renderProgressTracker = () => {
    const { weekly, monthly, t } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {this.state.active === 1 && (
            <AreachartCard
              data={weekly}
              onChange={e => {
                track.DashboardFirstAction({
                  'Product Name': JSON.parse(
                    localStorage.getItem('userProduct')
                  ).productName,
                  'First Action': 'Progress Tracker',
                });
                this.setState({ active: e.target.value });
              }}
              handleProgressTracker={this.handleProgressTracker}
              value={this.state.active}
              t={t}
            />
          )}
          {this.state.active === 2 && (
            <AreachartCard
              data={
                monthly && {
                  days: monthly.weeks,
                  totalTimeSpend: monthly.totalTimeSpend,
                }
              }
              onChange={e => {
                track.DashboardFirstAction({
                  'Product Name': JSON.parse(
                    localStorage.getItem('userProduct')
                  ).productName,
                  'First Action': 'Progress Tracker',
                });
                this.setState({ active: e.target.value });
              }}
              handleProgressTracker={this.handleProgressTracker}
              value={this.state.active}
              t={t}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { visitedPages } = this.props;
    const { dialogOpen } = this.state;

    const dashboardVisited = visitedPages && visitedPages.DASHBOARD;

    const isWebinar = this.props.webinarData.length !== 0 && dashboardVisited;

    if (!this.props.dashboardLoading)
      return (
        <>
          <ThemeProvider theme={dashboardTheme}>
            <Container>
              {isWebinar ? (
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: 0,
                        }}
                      >
                        {this.renderHeaderCard()}
                      </Grid>
                      <Grid item xs={7}>
                        {this.renderTaskComponents()}
                      </Grid>
                      <Grid item xs={5}>
                        {this.renderCalenderAndWeakness()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    {this.renderDoughnutCardAndWebinarAndStrengths()}
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                    }}
                  >
                    {this.renderHeaderCard()}
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        {this.renderTaskComponents()}
                      </Grid>
                      <Grid item xs={5}>
                        {this.renderCalenderAndWeakness()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    {this.renderDoughnutCardAndWebinarAndStrengths()}
                  </Grid>
                </Grid>
              )}
            </Container>
            <Guide />
            {isWebinar && (
              <JoinWebinar
                {...this.state.joinWebinarObj}
                handleDialogClose={this.handleCancelClick}
                handleActionClick={this.handleWebinarPopupActionClick}
                unregister={false}
              />
            )}
          </ThemeProvider>
          <ConfirmationDialog
            open={dialogOpen}
            dialogText={this.dialogText}
            handleClose={this.handleDialogClose}
            handleDelete={this.handleDelete}
          />
        </>
      );
    else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    status: state.dashboardLandingReducer.status,
    diagnosticStatus: state.dashboardLandingReducer.diagnosticStatus,
    calibrationStatus: state.dashboardLandingReducer.calibrationStatus,
    weekly: state.dashboardLandingReducer.weekly,
    monthly: state.dashboardLandingReducer.monthly,
    concept: state.dashboardLandingReducer.concept,
    webinarData: state.dashboardLandingReducer.webinar,
    dashboardLoading: state.dashboardLandingReducer.dashboardLoading,
    visitedPages: state.dashboardLandingReducer.isVisited,
  };
};

export default connect(mapStateToProps, {
  getWebinar,
  registerUser,
  getAllDashboard,
  deleteStudy,
  isVisited,
  getClickSourceDetails,
  getCourses,
  updateExamDetails,
  getIntroductionVideos,
  getCleverTabCommonProperties,
})(withTranslation()(Dashboard));
