/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  BookIcon,
  HomeIcon,
  NotesIcon,
  PenPadIcon,
  SupportIcon,
  OfficialGuideIcon,
  VideoIcon,
} from '../assets/icons/Icon';
import accountSettings from '../assets/images/accountSettings.png';
import Avatar from '../assets/images/avatar.png';
import powerOff from '../assets/images/PowerOff.png';

import VideoIcon3 from '../assets/images/VideoIcon3.png';
// export const IMAGE_URL = 'https://careerlabslms.s3.ap-south-1.amazonaws.com/';
export const IMAGE_URL = 'https://assets.thecareerlabs.com/lms/';

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const NavItem = ({ isOfficialGuide }) => [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: HomeIcon,
    exact: true,
    show: true,
  },
  {
    name: 'Subject',
    path: '/dashboard/subject',
    icon: BookIcon,
    show: true,
  },
  {
    name: 'Practise',
    path: '/dashboard/practice',
    icon: PenPadIcon,
    show: true,
  },
  {
    name: 'Official Guide',
    path: '/dashboard/official-guide',
    icon: OfficialGuideIcon,
    show: isOfficialGuide,
  },
  {
    name: 'Notes',
    path: '/dashboard/notes',
    icon: NotesIcon,
    show: true,
  },
  {
    name: 'Support',
    path: '/dashboard/support',
    icon: SupportIcon,
    show: true,
  },
];

export const LanguagesMenu = [
  {
    icon: '',
    name: 'en',
    text: 'English',
  },
  {
    icon: '',
    name: 'hi',
    text: 'Hindi',
  },
];

export const MenuList = [
  {
    icon: Avatar,
    text: 'Profile',
    path: '/profile/view',
  },

  {
    icon: accountSettings,
    text: 'My Account',
    path: '/dashboard/my-account',
  },

  {
    icon: powerOff,
    text: 'Log out',
    path: '#',
  },
];
export const GreMenuList = [
  {
    icon: Avatar,
    text: 'Profile',
    path: '/profile/view',
  },
  {
    icon: VideoIcon3,
    text: 'Introduction Page',
    path: '/calibration/introduction-to-lms?profile=true',
  },
  {
    icon: accountSettings,
    text: 'My Account',
    path: '/dashboard/my-account',
  },

  {
    icon: powerOff,
    text: 'Log out',
    path: '#',
  },
];

export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

export const CONTACT_US_URL = 'mailto:testprep@thecareerlabs.com';
export const registerURL = 'https://careerwizard.thecareerlabs.com/';

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DROP_DOWN_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

export const REGISTER_URL = 'https://careerwizard.thecareerlabs.com/';

export const QS_PROPS = {
  ignoreQueryPrefix: true,
};
