/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import {
  activeLink,
  profileUpdateTheme,
} from '../../../../assets/css/MuiStyles';
import { routePaths } from '../../../../routes/RoutePath';
import {
  dataURLtoFile,
  generateDownload,
} from '../../../../utils/generateImage';
import EducationDetail from './EducationDetail';
import EmployeeDetail from './EmployeeDetail';
import PersonalDetail from './PersonalDetail';
import { withTranslation } from 'react-i18next';

class ProfileUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      firstName: '',
      lastName: '',
      dob: '',
      college: [],

      crop: { x: 0, y: 0 },
      zoom: 1,
      rotate: 0,

      profilePic: null,
      previewImage: null,
      image: null,
      croppedAreaPixels: null,
      visible: true,
      updatedProfileImageFile: null,
      updatedProfileImage: null,
      activeTab: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () =>
      this.setState({ width: window.innerWidth })
    );
  }

  onFileChange = e => {
    const { type } = e.target.files[0];
    if (
      type.endsWith('jpeg') ||
      type.endsWith('png') ||
      type.endsWith('jpg') ||
      type.endsWith('gif')
    ) {
      this.setState({
        profilePic: e.target.files[0],
        previewImage: URL.createObjectURL(e.target.files[0]),
        visible: false,
      });
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result });
      });
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };

  getImage = async () => {
    const blobImage = await (
      await generateDownload(
        this.state.image,
        this.state.croppedAreaPixels,
        this.state.rotate
      )
    ).toDataURL();
    const image = dataURLtoFile(blobImage, this.state.profilePic.name);
    this.setState({
      updatedProfileImageFile: image,
      updatedProfileImage: URL.createObjectURL(image),
      visible: true,
      rotate: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
    });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  onCancel = () =>
    this.setState({ visible: true, rotate: 0, crop: { x: 0, y: 0 }, zoom: 1 });

  fileToUrl = () => {
    this.setState({ image: URL.createObjectURL(this.state.profilePic) });
  };

  onRotate = () => {
    if (this.state.rotate === 180) {
      this.setState({ rotate: 0 });
    } else {
      this.setState({ rotate: this.state.rotate + 90 });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <ThemeProvider theme={profileUpdateTheme}>
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box m={1}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    xl={4}
                    lg={4}
                    className="profile__update__link"
                  >
                    <NavLink
                      to={routePaths.personalDetail}
                      activeStyle={activeLink}
                    >
                      {t('Personal Information')}
                    </NavLink>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    xl={4}
                    lg={4}
                    className="profile__update__link"
                  >
                    <NavLink
                      to={routePaths.employeeDetail}
                      activeStyle={activeLink}
                    >
                      {t('Employment Details')}
                    </NavLink>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    xl={4}
                    lg={4}
                    className="profile__update__link"
                  >
                    <NavLink
                      to={routePaths.educationDetail}
                      activeStyle={activeLink}
                    >
                      {t('Education Details')}
                    </NavLink>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={4}>
                <Switch>
                  <Route
                    path={routePaths.personalDetail}
                    component={PersonalDetail}
                  />
                  <Route
                    path={routePaths.employeeDetail}
                    component={EmployeeDetail}
                  />
                  <Route
                    path={routePaths.educationDetail}
                    component={EducationDetail}
                  />
                </Switch>
              </Box>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    );
  }
}

export default withTranslation()(ProfileUpdate);
