/**
 * (c) CareerLabs. All rights reserved.
 **/
import { MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import {
  notesTheme,
  StyledSelect,
  Text,
} from '../../../assets/css/dashboard/StyledNotesComponents';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import {
  FilterContainer,
  MuiDivider,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';

export default function Header(props) {
  const {
    subjectValue,
    subjectData,
    conceptValue,
    conceptData,
    topicValue,
    topicData,
    handleSubjectChange,
    handleConceptChange,
    handleTopicChange,
    t,
  } = props;

  return (
    <ThemeProvider theme={notesTheme}>
      <FilterContainer p={'0 0 1.5rem 0'}>
        {/* Subject */}
        <SelectBox>
          <StyledSelect
            name="subjectValue"
            onChange={handleSubjectChange}
            value={subjectValue}
            IconComponent={ExpandMore}
            disableUnderline
            className={'note-header-drop-down-style'}
            MenuProps={DROP_DOWN_PROPS}
          >
            <MenuItem value="default" disabled>
              Subject
            </MenuItem>
            {subjectData !== undefined &&
              subjectData.map(subject => {
                return <MenuItem value={subject.id}>{subject.title}</MenuItem>;
              })}
          </StyledSelect>
          {/* Concept */}
          <StyledSelect
            name="conceptValue"
            onChange={handleConceptChange}
            value={conceptValue}
            IconComponent={ExpandMore}
            disableUnderline
            className={'note-header-drop-down-style'}
            MenuProps={DROP_DOWN_PROPS}
          >
            <MenuItem value="default" disabled>
              Select Concept
            </MenuItem>
            {conceptData !== undefined &&
              conceptData.map(concept => {
                return <MenuItem value={concept.id}>{concept.title}</MenuItem>;
              })}
          </StyledSelect>
          {/* Topic */}
          <StyledSelect
            name="topicValue"
            onChange={handleTopicChange}
            value={topicValue}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
          >
            <MenuItem value="default" disabled>
              Select Topic
            </MenuItem>
            {topicData !== undefined &&
              topicData.map(topic => {
                return <MenuItem value={topic.id}>{topic.title}</MenuItem>;
              })}
          </StyledSelect>
        </SelectBox>
        <MuiDivider orientation="horizontal" />
        <Text>{t('Sort by Recent')}</Text>
        <ExpandMore className={'expand-icon-style'} />
      </FilterContainer>
    </ThemeProvider>
  );
}
