/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Typography } from '@material-ui/core';
import Bundle from './Bundle';
import Latex from '../../../../../../utils/LatexViewer';
import SingleMulti from './SingleMulti';
import {
  DescriptionBox,
  DescriptionHeader,
  DividerLine,
  ErrorStatement,
  Grid,
  OutlineLeft,
  OutlineRight,
  TextAreaStyled,
  TextBox,
  TextBoxField,
  TextBoxValueShow,
  TextNumberField,
  TextUnitField,
  TextWrapper,
  Centar,
} from '../../../../../../assets/css/GreStyles';
import React from 'react';

const Passage = ({
  question,
  choices,
  bundle,
  selectedChoice,
  onSelect,
  subjective,
  onChange,
  bundleLength,
  answer,
  description,
  imgUrl,
  isMulti,
  isCalculator,
  bottomText,
  topText,
  currentQuestionNo,
  fraction,
  fraction1,
  onChangeCapture,
  fraction2,
  optionalType,
  unit,
  isHaveDescription,
}) => {
  const style = {
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
  };
  const { hide, show } = style;
  return (
    <Grid container>
      <Grid
        item
        md={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? 12
            : 6
        }
      >
        <OutlineLeft>
          <DescriptionHeader>
            Question {currentQuestionNo} is based on this passage.
          </DescriptionHeader>
          <DescriptionBox>
            <Box flex={1} className={'question-left-container'}>
              {bundle && isHaveDescription === false && (
                <>
                  <Latex math={question} />
                  <Bundle
                    bundleLength={bundleLength}
                    choices={choices}
                    onChange={onChange}
                    selectedChoice={selectedChoice}
                    question={question}
                    isCalculator={isCalculator}
                    bottomText={bottomText}
                    topText={topText}
                    isHaveDescription={isHaveDescription}
                  />
                </>
              )}
              {description !== '' && (
                <Typography variant="h6" color="textPrimary">
                  <Latex math={description} />
                </Typography>
              )}
              {subjective &&
                // optionalType === 'NUMERICAL_ENTRY' &&
                isHaveDescription === false && (
                  <Typography variant="h5" color="textPrimary">
                    <Latex math={question} />
                  </Typography>
                )}
              {optionalType === 'NUMERICAL_ENTRY' &&
                isHaveDescription === false && (
                  <div style={{ display: 'flex' }}>
                    <TextBoxField
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={answer}
                      type={'number'}
                    />
                    {unit !== null ? (
                      <TextUnitField>{unit}</TextUnitField>
                    ) : null}
                  </div>
                )}

              {fraction && isHaveDescription === false && (
                <>
                  <Typography variant="h5" color="textPrimary">
                    <Latex math={question} />
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <TextBoxValueShow
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={fraction1}
                        type={'number'}
                      />

                      <DividerLine />
                      <TextBoxValueShow
                        onChange={e => {
                          onChangeCapture(e.target.value);
                        }}
                        value={fraction2}
                        type={'number'}
                      />
                    </div>
                    {unit !== null ? (
                      <TextUnitField>{unit}</TextUnitField>
                    ) : null}
                  </div>
                </>
              )}
              <Box>
                {imgUrl && (
                  <img src={imgUrl} alt={''} width={'100%'} height={'100%'} />
                )}
              </Box>
            </Box>
          </DescriptionBox>
        </OutlineLeft>
      </Grid>
      <Grid
        item
        md={6}
        style={
          (subjective &&
            isHaveDescription === false &&
            optionalType === 'NUMERICAL_ENTRY') ||
          (bundle && isHaveDescription === false) ||
          (fraction && isHaveDescription === false)
            ? hide
            : show
        }
      >
        <OutlineRight>
          {bundle ? (
            <Bundle
              bundleLength={bundleLength}
              choices={choices}
              onChange={onChange}
              selectedChoice={selectedChoice}
              question={question}
              isCalculator={isCalculator}
              bottomText={bottomText}
              topText={topText}
              isHaveDescription={isHaveDescription}
            />
          ) : subjective ? (
            optionalType === 'NUMERICAL_ENTRY' ? (
              <React.Fragment>
                <TextWrapper>
                  {subjective && isHaveDescription === true && (
                    <Typography variant="h5" color="textPrimary">
                      <Latex math={question} />
                    </Typography>
                  )}
                  <div style={{ display: 'flex' }}>
                    <TextBoxField
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={answer}
                      type={'number'}
                    />
                    {unit !== null ? (
                      <TextUnitField>{unit}</TextUnitField>
                    ) : null}
                  </div>
                </TextWrapper>
              </React.Fragment>
            ) : (
              <>
                <Centar>
                  {isHaveDescription === true && (
                    <Typography variant="h5" color="textPrimary">
                      <Latex math={question} />
                    </Typography>
                  )}
                </Centar>
                <TextBox
                  type={'text'}
                  placeholder={'Enter your answer here'}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={answer}
                />
              </>
            )
          ) : fraction ? (
            <React.Fragment>
              <TextWrapper>
                {fraction && isHaveDescription === true && (
                  <Typography variant="h5" color="textPrimary">
                    <Latex math={question} />
                  </Typography>
                )}
                <div style={{ display: 'flex' }}>
                  <div>
                    <TextBoxValueShow
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={fraction1}
                      type={'number'}
                    />

                    <DividerLine />
                    <TextBoxValueShow
                      onChange={e => {
                        onChangeCapture(e.target.value);
                      }}
                      value={fraction2}
                      type={'number'}
                    />
                  </div>
                  {unit !== null ? <TextUnitField>{unit}</TextUnitField> : null}
                </div>
              </TextWrapper>
            </React.Fragment>
          ) : (
            <SingleMulti
              question={question}
              options={choices}
              selectedChoice={selectedChoice}
              onSelect={onSelect}
              isMulti={isMulti}
              isCalculator={isCalculator}
              bottomText={bottomText}
              topText={topText}
            />
          )}
        </OutlineRight>
      </Grid>
    </Grid>
  );
};

export default Passage;