/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import {
  Divider as MuiDivider,
  Button as MuiButton,
  Menu as MuiMenu,
  Tooltip as MuiToolTip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

export const Container = styled.div`
  padding: 24px 40px;
  background: #f7f8fc;
  min-height: 90vh;

  @media only screen and (min-width: ${1478}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    min-height: 90vh;
  }

  @media only screen and (min-width: ${1680}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    min-height: 91vh;
  }

  @media only screen and (min-width: ${1848}px) {
    /* CSS that should be displayed if width is equal to or less than 991px goes here */
    min-height: 92vh;
  }
`;

export const Container2 = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 36px;
  align-items: center;
  padding: ${props => props.p};
`;

export const Container3 = styled.div`
  display: flex;
  flex-direction: column;
`;

export const B1 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: ${props => props.p};
  padding-right: ${props => props.pr};
`;

export const Divider = withStyles({
  root: {
    border: '1px solid #263238',
    flexGrow: 1,
  },
})(MuiDivider);

export const Div1 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  color: #052a4e;
  padding: 20px;
  margin: 8px;
  display: flex;
  align-items: center;
`;

export const Div2 = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

// export const HeadDiv = styled.div ``;
export const Button = withStyles({
  root: {
    borderRadius: '36px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    // minWidth: '150px',
    // height: '40px',
    // margin: '8px',
    whiteSpace: 'nowrap',
  },
})(MuiButton);

export const Menu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 11px rgba(55, 143, 233, 0.25)',
    top: '110px !important',
  },
})(MuiMenu);

export const Tooltip = withStyles({
  tooltip: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 11px rgba(55, 143, 233, 0.25)',

    borderRadius: '8px',
    transform: 'matrix(1, 100, 0, -1, 0, 0)',
    color: 'black',
    top: '34px',
    right: '125px',
  },
  arrow: {
    color: '#ffffff',
  },
})(MuiToolTip);

export const Grey = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;

  color: #686868;
`;

export const Div3 = styled.div`
  // display: flex;
  padding: 10px 20px;
`;

export const ButtonBox = styled.div`
  height: 30px;
  width: 120px;
  padding-left: 8px;
`;
