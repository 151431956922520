/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Card, CardActionArea } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  B1,
  B3,
  CardText,
} from '../../../assets/css/dashboard/StyledNotesComponents';
import MessageIcon from '../../../assets/icons/Message.svg';
import SupportPic from '../../../assets/images/Help.svg';
import { supportEmail } from '../../../constant/Variables';
import * as track from '../../../mixpanel/Events';
import { sendSupportEmail } from '../../../redux/action/Course';
// import twak from '../../../utils/twak';
import DialogComponent from './DialogComponent';
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogState: false,
      dialogType: '',
      subject: '',
      subjectError: '',
      body: '',
      bodyError: '',
    };
  }

  handleClick = () => {
    this.setState({
      dialogState: true,
      dialogType: 'form',
    });
  };

  handleClose = () => {
    this.setState({
      dialogState: false,
      dialogType: '',
      subject: '',
      subjectError: '',
      body: '',
      bodyError: '',
    });
  };

  handleTextChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: '',
    });
  };

  handleSend = () => {
    const obj = {
      to: supportEmail,
      subject: this.state.subject,
      body: this.state.body,
    };
    if (this.state.subject.length === 0 && this.state.body.length === 0) {
      this.setState({
        subjectError: 'Please fill the required field',
        bodyError: 'Please fill the required field',
      });
    } else if (this.state.subject.length === 0) {
      this.setState({ subjectError: 'Please fill the required field' });
    } else if (this.state.body.length === 0) {
      this.setState({ bodyError: 'Please fill the required field' });
    } else {
      this.props.sendSupportEmail(obj, response => {});
      this.setState({ dialogType: 'confirmation' });
    }
  };

  componentDidMount() {
    // twak({}, track.liveSupportChat());
    track.supportPageLanding();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.user !== this.props.user)
  //     twak({
  //       email: this.props.user.email,
  //       name: `${this.props.user.firstName} ${this.props.user.lastName}`,
  //     });
  // }

  // componentWillUnmount() {
  //   if (
  //     Object.keys(window.Tawk_API).length > 0 &&
  //     window.Tawk_API.hideWidget &&
  //     typeof window.Tawk_API.hideWidget() === 'function'
  //   )
  //     window.Tawk_API.hideWidget();
  // }

  // twakOperations() {
  //   if (window.Tawk_API && Object.keys(window.Tawk_API).length > 0)
  //     if (
  //       window.Tawk_API &&
  //       Object.keys(window.Tawk_API).length > 0 &&
  //       window.Tawk_API.showWidget &&
  //       typeof window.Tawk_API.showWidget() === 'function'
  //     ) {
  //       window.Tawk_API.showWidget();
  //     }
  // }

  render() {
    const { handleClick, handleClose, handleTextChange, handleSend } = this;
    const { dialogState, dialogType, subject, body, subjectError, bodyError } =
      this.state;
    const { t } = this.props;
    // this.twakOperations();

    return (
      <B1>
        <img
          src={SupportPic}
          alt="Hey"
          className="support_top support_padding"
        />
        <div className={'support_text_font right__side__title support_padding'}>
          {t('We are ready to help you')}
        </div>
        <B3 className={'support_padding'}>
          {t('Drop a mail to get the access for the Mock tests')}
          {/* {t('Have any question? We’d love to hear from you')} */}
        </B3>
        <Card
          onClick={handleClick}
          className={'support_card_style support_padding'}
        >
          <CardActionArea className={'button_inside_style'}>
            <img
              src={MessageIcon}
              alt="Message Icon"
              height="48px"
              width="48px"
            />
            <CardText>{t('Drop a mail')}</CardText>
          </CardActionArea>
        </Card>
        <DialogComponent
          dialogState={dialogState}
          type={dialogType}
          handleClose={handleClose}
          subject={subject}
          body={body}
          handleTextChange={handleTextChange}
          handleSend={handleSend}
          subjectError={subjectError}
          bodyError={bodyError}
          t={t}
        />
      </B1>
    );
  }
}

const mapStateToProps = state => ({
  user: state.studentReducer.user,
});

export default connect(mapStateToProps, { sendSupportEmail })(
  withTranslation()(Index)
);
