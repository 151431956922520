/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Button, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import { CardView, TopTitle } from '../../../../assets/css/StyledComponent';
import { CLEVERTAP } from '../../../../clevertap/Config';
import { EVENTS } from '../../../../clevertap/Events';
import { IMAGE_URL } from '../../../../constant/Variables';
import { getCourseImage, getGoals } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { updateExamDetails } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';

class GmatTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: '',
    };
  }
  handleClick = () => {
    let obj = {
      productName: JSON.parse(window.localStorage.getItem('userProduct'))
        .productName,
    };
    this.props.updateExamDetails(obj, res => {
      this.props.getCourses();
      this.props.history.push(routePaths.submit);
    });
  };

  componentDidMount() {
    this.props.getCourses();
    let obj1 = {
      courseId: JSON.parse(window.localStorage.getItem('userProduct')).id,
      endPoint: 'test before',
    };
    this.props.getCourseImage(obj1, response => {
      this.setState({ img: IMAGE_URL + response.data.value });
    });
  }

  render() {
    const { t } = this.props;
    const productName = JSON.parse(
      window.localStorage.getItem('userProduct')
    ).productName;
    return (
      <div>
        <CardView>
          <Box pt={3}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ textAlign: 'center' }}
            >
              <Box pb={6}>
                <TopTitle>
                  {t(
                    `Have you taken ${
                      JSON.parse(window.localStorage.getItem('userProduct'))
                        .productName
                    } test before?`
                  )}
                </TopTitle>
              </Box>
              <Grid item xs={12} md={12} className={'gmat_exam_align'}>
                <div className={'image_align'}>
                  {' '}
                  <img src={this.state.img} alt="" />
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box pb={4}></Box>
              </Grid>
              <Grid item md={3}>
                {' '}
              </Grid>
              <Grid item md={3} xs={12}>
                <ThemeProvider theme={buttonTheme}>
                  <Button
                    className={'btn__alignment'}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClick}
                  >
                    {t('Yes')}
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item md={3} xs={12}>
                <ThemeProvider theme={buttonTheme}>
                  <Button
                    className={'btn__alignment'}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      productName === 'GRE'
                        ? this.props.history.push(routePaths.introduction)
                        : this.props.history.push(routePaths.start);
                    }}
                  >
                    {t('No')}
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Box>
        </CardView>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    examDetailsResponse: state.testReducer.examDetails,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, {
  updateExamDetails,
  getCourses,
  getCourseImage,
  getGoals,
})(withTranslation()(GmatTest));
