import React from 'react';
import {
  LeftContainer,
  TopicTitleWhite,
  TopicTitleBlue,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';

export default function Left(props) {
  const { passageData, activePassage, handleActivePassage } = props;
console.log(passageData,"passageData");
  return (
    <LeftContainer>
      {passageData && passageData.length !== 0 ? (
        passageData.map(passage => {
          return (
            <>
              {passage.id === activePassage ? (
                <TopicTitleBlue onClick={() => handleActivePassage(passage.id)}>
                  {passage.name}
                </TopicTitleBlue>
              ) : (
                <TopicTitleWhite
                  onClick={() => handleActivePassage(passage.id)}
                >
                  {passage.name}
                </TopicTitleWhite>
              )}
            </>
          );
        })
      ) : (
        <TopicTitleWhite pointer={false}>{'No Passages'}</TopicTitleWhite>
      )}
    </LeftContainer>
  );
}
