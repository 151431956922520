export const styles = them => ({
  calendarPaper: {
    zIndex: them.zIndex.fab + 1,
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#ffffff',
    borderRadius: '8px',
    boxShadow: '1px 1px 5px 5px rgba(234, 246, 255, 1)',
    fontSize: 11,
    width: 400,
    padding: '14px 0',
    zIndex: 999,
    left: '-20px !important',
    top: '10px !important',
  },
});
