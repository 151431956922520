import { Table, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TableHeadCell,
  TableHeader,
  TableItemBlack,
  TableItemUnderline,
} from '../../../../../assets/css/Styles';
import { TableBodyStyle } from '../../../../../assets/css/GreReportStyledComponent';
import { QS_PROPS } from '../../../../../constant/Variables';
import QueryString from 'qs';
import { routePaths } from '../../../../../routes/RoutePath';

class GmatError extends Component {
  constructor(props) {
    super(props);
  }

  convertTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (minutes !== 0 && seconds !== 0) return minutes + 'm ' + seconds + 's';
    if (minutes === 0) return seconds + 's';
    if (seconds === 0) return minutes + 's';
  }

  handleClick = topicId => {
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&type=normal`
    );
  };

  render() {
    const { table, overAllReport, variantIndex } = this.props;
    return (
      <TableContainer>
        <Table
          className={'review__table'}
          style={{ paddingTop: 0, marginLeft: '-20px' }}
        >
          <TableBodyStyle>
            <TableHeadCell>S.No </TableHeadCell>
            <TableHeadCell>Topic </TableHeadCell>
            <TableHeadCell>Concepts</TableHeadCell>
            <TableHeadCell>Easy </TableHeadCell>
            <TableHeadCell>Medium</TableHeadCell>
            <TableHeadCell>Hard</TableHeadCell>
          </TableBodyStyle>
          {overAllReport?.data[variantIndex]?.topicWiseReport?.map(
            (item, index) => {
              return (
                <TableBodyStyle>
                  <TableRow>
                    <TableItemBlack>{`${index + 1}.`}</TableItemBlack>
                    <TableItemBlack
                      style={{ color: '#1093FF' }}
                      // onClick={() => this.handleClick(item.topicId)}
                    >
                      {item.topicName}
                    </TableItemBlack>
                    <TableItemBlack>{item.conceptName}</TableItemBlack>
                    <TableItemBlack>
                      {item.easyValue === -100 ? 'NA' : item.easyValue + '%'}
                    </TableItemBlack>
                    <TableItemBlack>
                      {item.mediumValue === -100
                        ? 'NA'
                        : item.mediumValue + '%'}
                    </TableItemBlack>
                    <TableItemBlack>
                      {item.hardValue === -100 ? 'NA' : item.hardValue + '%'}
                    </TableItemBlack>
                  </TableRow>
                </TableBodyStyle>
              );
            }
          )}
        </Table>
      </TableContainer>
    );
  }
}

export default connect()(withRouter(withTranslation()(GmatError)));
