/**
 * (c) CareerLabs. All rights reserved.
 **/

import { Button as MuiButton, IconButton, Popper } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
// import WarningAmberIcon from '@material-ui/icons/WarningAmberIcon';
import styled from 'styled-components';
import boost from '../../assets/icons/boostIcon.svg';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const WelCard = styled.div`
  background: linear-gradient(270deg, #fcf4dd 14.18%, #ffffff 100%);
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
`;

export const WelContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
`;

export const Welbox = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
`;

export const Emoji = styled.img`
  width: 64px;
  height: 64px;
`;
export const RedAlert = withStyles({
  root: {
    background: '#F96332',
    alignItems: 'center',
    borderRadius: '8px',
  },
  message: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#00250B',
    paddingRight: '40px',
  },
  icon: {
    margin: '8px 24px',
    padding: '0 0',
  },
})(Alert);
export const WarningAlert = withStyles({
  root: {
    background: 'transparent',
    alignItems: 'center',
    borderRadius: '8px',
  },
  message: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',

    color: '#00250B',
  },
  icon: {
    // margin: '8px 24px',
    padding: '0 0',
    fontSize: '35px',
    color: 'red!important',
  },
})(Alert);

export const Welcometitle = styled.div`
  padding-left: 24px;
  color: #052a4e;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 8px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const SpaceIcon = styled.img`
  width: 134px;
  height: 100px;
`;

export const GroupButton = styled.div`
  width: 295px;
  height: 33px;
  background: #eaf6ff;
  box-shadow: inset 1px 1px 1px rgba(16, 147, 255, 0.25);
  border-radius: 30px;
  margin: 10px auto;
`;

export const Selectbox = styled.div`
  width: 130px;
  text-align: center;
  height: 33px;
  background: #fff;
  border-radius: 30px;
`;
export const Button = styled.button`
  width: 147px;
  height: 33px;
  background: none;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 30px;
  color: #1093ff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: width 0.4s 0.2s ease-in-out;
`;

export const StyledButton = styled(MuiButton)`
  width: 147px;
  height: 33px;
  border-radius: 30px !important;
  text-transform: none !important;
  margin-left: 10px !important;
`;

export const Flexdoughnut = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 15px;
`;

export const Colorbox = styled.span`
  display: block;
  margin: 0 auto;
  width: 12px;
  height: 12px;
`;

export const CardContainer = styled.div`
  display: flex;
`;

export const CardTitle = styled.div`
  font-size: 120px;
  font-weight: 600;
  color: #052a4e;
  @media (max-width: 768px) {
    font-size: 70px;
  }
`;
// export const AlertTick = styled(WarningAmberIcon)`
//   height: 100px !important;
//   width: 100px !important;
//   color: #37e288 !important;
// `;
export const GreenTick = styled(CheckRoundedIcon)`
  height: 100px !important;
  width: 100px !important;
  color: #37e288 !important;
`;
export const GreenTick1 = styled(InfoOutlinedIcon)`
  height: 100px !important;
  width: 100px !important;
  color: #37e288 !important;
`;
export const ProgressContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  min-height: 1564px;
`;

export const ProgressBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px 0px 20px;
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProgressHeader = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #052a4e;
`;

export const ProgressDivider = styled.div`
  height: 0px;
  border: 0.5px solid #1093ff;
  margin-top: 24px;
`;

export const MonthStyle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #052a4e;
  padding: 0px 12px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ProgressSubHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052a4e;
  padding: 24px 20px 0px 20px;
  word-spacing: 12px;
`;

export const TopicText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

export const TopicBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`;

export const WeekDay = styled.div`
  font-size: 14px;
  color: #052a4e;
  padding: 26px 24px 14px 24px;
`;

export const TopicPopupHeaderText = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #686868;
`;

export const TopicStatusIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

export const TopicPopupDateStyle = styled.div`
  font-size: 14px;
  color: #052a4e;
`;

export const TopicPopupNavigateText = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #1093ff;
`;

export const TopicPopover = styled.div`
  position: absolute;
  width: 149px;
  height: 116px;
  background: #ffffff;
  box-shadow: 1px 1px 6px 42px rgba(1, 54, 3, 0.25);
  border-radius: 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const Iconbutton = styled(IconButton)`
  padding: 0 !important;
`;

export const TopicDialogContainer = styled.div`
  padding: 32px;
`;

export const CloseIconBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const TopicDialogTitle = styled.div`
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #052a4e;
`;

export const TaskBox = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TaskTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #052a4e;
  padding-left: 12px;
`;

export const LinkButton = styled(MuiButton)`
  height: 24px !important;
  padding: 5px !important;
  background: none !important;
  text-transform: none !important;
  color: #1093ff !important;
`;

export const CloseIconButton = styled(IconButton)`
  padding: 5px;
`;

export const PopperBox = styled.div`
  width: 149px;
  min-height: 116px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding: 16px;
  position: relative;
  inset: -14px 36px;
  &::before {
    background: #ffffff;
    content: '';
    display: block;
    position: absolute;
    width: 23px;
    height: 19px;
    bottom: -11px;
    right: 35px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(59deg);
    box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  }
`;

export const PopperText1 = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #686868;
`;

export const PopperText2 = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #052a4e;
`;

export const PopperButton = styled(MuiButton)`
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-top: 17px !important;
  line-height: 20px !important;
  color: #1093ff !important;
  text-align: left !important;
  padding: 3px 6px !important;
  left: -6px !important;
  display: block !important;
  text-transform: none !important;
  &:hover {
    background: none !important;
  }
`;

export const GraphPopperPercentage = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #052a4e;
`;

export const GraphPopperText1 = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 0px;
  text-align: center;
  color: #052a4e;
`;

export const GraphPopperText2 = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #052a4e;
`;

export const BodyContainer = styled.div`
  padding: 12px 36px;
`;

export const HeaderBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
`;

export const HeaderCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  margin: 16px 0px;
  margin-bottom: ${props => (props.noBottom ? '0px' : 'auto')};
`;
export const HeaderCard1 = styled.div`
  background: #ffffff;
  box-shadow: ${props =>
    props.noBottom ? '' : ' 0px 0px 7px rgba(183, 222, 255, 0.5)'};
  border-radius: 8px;
  // margin: 16px 0px;
  margin-bottom: ${props => (props.noBottom ? '0px' : 'auto')};
  padding: 10px;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 32px;
  justify-content: space-between;
  flex-flow: wrap;
`;

export const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
`;

export const HeaderIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 18px;
`;

export const FlexView = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderSmallText = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #052a4e;
  padding-right: 7px;
`;

export const CircleView = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #052a4e;
  border: ${props => `4px solid ${props.color}`};
  border-radius: 50%;
`;

export const SubContainerWrapper = styled.div`
  padding: 24px 40px;
  padding-bottom: ${props => (props.noBottomPadding ? '0px' : 'auto')};
  position: relative;
  min-height: ${props => (props.noBottomPadding ? 'auto' : '572px')};
`;

export const SubContainerTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #052a4e;
`;

export const GreenSubText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #4db71b;
`;

export const DescriptionText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
  padding: 4px 0px 16px 0px;
`;

export const ActionButton = styled(MuiButton)`
  min-width: 203px !important;
  height: 40px;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
`;

export const SmallIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

export const TaskText = styled.div`
  font-weight: ${props => (props.bold ? 600 : 300)};
  cursor: ${props => (props.cursor ? 'pointer' : 'default')};
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  &:active {
    color: ${props => (props.cursor ? '#1792fa' : 'auto')};
  }
`;

export const FlexJustifyView = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
`;

export const FlexButtonView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
`;

export const TestHeader = styled.div`
  padding: 16px 20px;
  background: ${props => props.color};
  border-radius: 8px 8px 0px 0px;
`;

export const TestLevelText = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #052a4e;
  padding-right: 12px;
  b {
    font-weight: 600;
  }
`;

export const TestLevelIcon = styled.img`
  width: 18px;
  height: 16px;
`;

export const TestIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0px 12px 0px 20px;
  padding: ${props => (props.padding ? '4px' : 'auto')};
`;

export const TestText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
  font-weight: ${props => (props.bold ? 600 : 'normal')};
`;

export const TestContainer = styled.div`
  padding: 16px 15px 20px 0px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    top: 20px;
    left: calc(50% + 4px);
    height: calc(100% - 40px);
    resize: horizontal;
    background: rgb(16 147 255 / 10%);
    @media (max-width: 1279px) {
      display: none;
    }
  }
`;

export const EmptyTestContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #052a4e;
  opacity: 0.4;
  padding: 32px 12px 34px 12px;
`;

export const TextContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding-top: 22px;
  text-align: ${props => props.align};
`;

export const ActiveStep = styled.span`
  font-weight: 600 !important;
`;

export const SliderAlign = styled.ul`
  position: relative;
  top: -8px;
  text-align: center !important;
  li {
    height: 8px;
    width: 14px;
  }
`;

export const DiagnosticButton = styled(MuiButton)`
  min-width: 162px !important;
  height: 40px;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
  margin-right: 32px !important;
  &::before {
    width: 16px;
    height: 16px;
    content: '';
    display: block;
    padding-right: 3px;
    background-size: cover;
    background: url(${boost});
    position: relative;
    left: 8px;
  }
`;
export const CalibrationButton = styled(MuiButton)`
  min-width: 165px !important;
  height: 45px !important;
  line-height: 18px !important;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
  margin-right: 32px !important;
  padding-left: 10px !important;
  /* &::before {
    width: 16px;
    height: 16px;
    content: '';
    display: block;
    padding-right: 3px;
    background-size: cover;
    // background: url(${boost});
    position: relative;
    // left: 8px;
  } */
`;

export const DiagnosticShadowCard = styled.div`
  background: linear-gradient(269.94deg, #aed8ff -69.24%, #ffffff 99.95%);
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
`;

export const LeftIcon = styled.img`
  width: 132px;
  height: 94px;
  position: relative;
  float: left;
  left: 0px;
`;

export const DiagnosticTitle = styled.div`
  padding-left: 18px;
  color: #052a4e;
  p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
`;
export const Diagnostic = styled.div`
  padding-left: 120px;
  color: #052a4e;
  p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
`;
export const RightIcon = styled.img`
  width: 83.77px;
  height: 100px;
  margin-right: 10px;
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
`;
export const IntroFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
`;

export const CalendarGuide = styled.div`
  position: absolute;
  right: calc(50% - 150px);
  top: 96px;
`;

export const StrengthGuide = styled.div`
  position: absolute;
  right: -160px;
  top: 96px;
`;

export const TopicGuide = styled.div`
  position: absolute;
  top: calc(50% - 360px);
  right: -350px;
`;

export const PendingGuide = styled.div`
  position: absolute;
  top: calc(50% - 250px);
  right: -360px;
`;

export const ProgressGuide = styled.div`
  position: absolute;
  left: -320px;
  top: -280px;
`;

export const SubjectGuide = styled.div`
  position: absolute;
  right: calc(50% - 160px);
  top: 76px;
`;

export const GraphPopperBox = styled(Popper)`
  width: 160px;
  min-height: 82px;
  background: ${props => (props.color ? props.color : '#ffffff')};
  border-radius: 8px;
  padding: 5px 14px 8px 14px;
  position: absolute;
  top: -18px !important;
  &::before {
    background: ${props => (props.color ? props.color : '#ffffff')};
    content: '';
    display: block;
    position: absolute;
    width: 21px;
    height: 22px;
    bottom: -11px;
    right: calc(50% - 13px);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(59deg);
  }
`;

export const ProgressTrackerTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
`;
