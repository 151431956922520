/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  Emoji,
  SpaceIcon,
  Welbox,
  WelCard,
  Welcometitle,
  WelContainer,
} from '../../../../assets/css/StyledDashboardComponent';
import space from '../../../../assets/images/dash/space.svg';

const WelcomeCard = ({ username, description, imageSrc, t }) => {
  return (
    <WelCard>
      <WelContainer>
        <Welbox>
          <Emoji src={imageSrc} alt="Not supported"></Emoji>
          <Welcometitle>
            <h3>
              {t('Hi,')} {username}
            </h3>
            <p>{description}</p>
          </Welcometitle>
        </Welbox>
        <SpaceIcon src={space} alt="Space Icon"></SpaceIcon>
      </WelContainer>
    </WelCard>
  );
};

export default WelcomeCard;
