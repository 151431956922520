/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import QueryString from 'qs';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { QS_PROPS } from '../../../constant/Variables';
import { routePaths } from '../../../routes/RoutePath';
import AllCourses from '../../calibration/AllCourses';
import Test from '../course/exam/Index';
import ContactUs from '../support/Index';
import Answer from './answer/Index';
import Report from './report/Index';
import Review from './report/Review';
import BeforeGmatScore from './test/BeforeGmatScore';
import CalibrationTest from './test/CalibrationTest';
import Exam from './test/Exam';
import GmatTest from './test/GmatTest';
import Progress from './test/Progress';
import Score from './test/Score';
import SectionIntro from './test/SectionIntro';
import TestInstruction from './test/TestInstruction';
import Introduction from './test/Introduction';

class Index extends Component {
  // constructor(props) {
  //   super(props);
  //   this.goBack = this.goBack.bind(this);
  // }
  back = pathName => {
    const { type, resume, isTakeCalibration } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    console.log(type, 'type122', pathName, resume);
    if (pathName === routePaths.examScheduled) {
      this.props.history.push(routePaths.allCourses);
    }
    if (pathName === routePaths.score) {
      this.props.history.push(routePaths.examScheduled);
    }
    if (pathName === routePaths.test) {
      this.props.history.push(routePaths.score);
    }
    if (pathName === routePaths.introduction) {
      this.props.history.push(routePaths.test);
    }
    if (pathName === routePaths.submit) {
      this.props.history.push(routePaths.test);
    }

    if (
      pathName === routePaths.start &&
      JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'GRE'
    ) {
      // this.props.history.push(routePaths.test);
      this.props.history.push(routePaths.introduction);
    }

    if (
      pathName === routePaths.start &&
      (JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'GMAT' ||
        JSON.parse(window.localStorage.getItem('userProduct')).productName ===
          'GMAT FOCUS')
    ) {
      this.props.history.push(routePaths.test);
      // this.props.history.push(routePaths.introduction);
    }

    if (
      pathName === routePaths.start &&
      JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'Aptitude test'
    ) {
      //this.props.history.push(routePaths.examScheduled);
      this.props.history.push(routePaths.introduction);
    }
    if (
      pathName === routePaths.start &&
      JSON.parse(window.localStorage.getItem('userProduct')).productName !==
        'Aptitude test' &&
      isTakeCalibration === 'false'
    ) {
      this.props.history.goBack();
      // this.props.history.push(routePaths.test);
    }
    if (
      pathName === routePaths.introduction &&
      JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'Aptitude test'
    ) {
      this.props.history.push(routePaths.examScheduled);
    }
    if (pathName === routePaths.instruction) {
      if (resume) this.props.history.goBack();
      else this.props.history.push(routePaths.start);
    }
    if (pathName === routePaths.exam) {
      this.props.history.push(routePaths.instruction);
    }
    if (
      pathName === routePaths.testInstructionSection &&
      JSON.parse(window.localStorage.getItem('calibrationSection')) &&
      JSON.parse(window.localStorage.getItem('calibrationSection')).section ===
        1
    ) {
      this.props.history.push(routePaths.instruction);
    }
    if (pathName.startsWith(routePaths.answer)) {
      if (type) {
        const {
          testExecutionId,
          test_type,
          og_course,
          qbank_type,
          diagnostic,
          topicId,
          subject,
          Type,
        } = QueryString.parse(this.props.location.search, QS_PROPS);
        console.log(type, 'type');
        if (test_type && og_course === 'QUESTIONBANK') {
          this.props.history.push(
            `${routePaths.dashboard.questionBankReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}&qbank_type=${qbank_type}`
          );
        } else {
          if (test_type) {
            if (og_course === 'GMAT') {
              this.props.history.push(
                `${routePaths.dashboard.gmatOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
              );
            } else if (og_course === 'GRE') {
              this.props.history.push(
                `${routePaths.dashboard.greOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
              );
            } else {
              this.props.history.goBack();
            }
          } else if (diagnostic === 'diagnostic-engine') {
            this.props.history.push(
              `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
            );
          } else if (subject === 'subject') {
            this.props.history.push(
              `${routePaths.dashboard.report}?testExecutionId=${testExecutionId}&subject=subject&topicId=${topicId}&type=${Type}`
            );
          } else
            this.props.history.push(
              routePaths.dashboard.report +
                '?testExecutionId=' +
                testExecutionId +
                '&isRetaken=true'
            );
        }
      } else this.props.history.push(routePaths.report + '?page=review');
    }

    if (pathName === routePaths.contactUs)
      this.props.history.push(routePaths.allCourses);
  };

  render() {
    const { pathname } = this.props.location;
    const { page } = QueryString.parse(this.props.location.search, QS_PROPS);
    const avoid = [
      routePaths.report,
      routePaths.profile,
      routePaths.profile + '/',
      routePaths.login,
      routePaths.exam,
      routePaths.progress,
      routePaths.allCourses,

      // remove back button after section 1
      !!(
        pathname === routePaths.testInstructionSection &&
        JSON.parse(window.localStorage.getItem('calibrationSection')) &&
        JSON.parse(window.localStorage.getItem('calibrationSection'))
          .section === 1
      )
        ? ''
        : routePaths.testInstructionSection,
    ];
    const { profile, ViewExplain } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    console.log(profile, 'profile');
    return (
      <Box width={'100%'} bgcolor={page === 'review' ? '#fff' : 'unset'}>
        {!avoid.some(item => item === pathname) && (
          <>
            {profile || ViewExplain ? (
              <></>
            ) : (
              <IconButton
                style={{
                  backgroundColor: '#C4E4FD',
                  position: 'absolute',
                  left: 18,
                  top: 100,
                  zIndex: 2,
                }}
                onClick={() => this.back(pathname)}
              >
                <ArrowBack style={{ color: '#1093FF' }} />
              </IconButton>
            )}
          </>
        )}
        <Switch>
          <Route exact path={`${routePaths.test}`} component={GmatTest} />
          <Route
            exact
            path={`${routePaths.submit}`}
            component={BeforeGmatScore}
          />

          <Route exact path={`${routePaths.score}`} component={Score} />
          <Route exact path={`${routePaths.answer}/:id`} component={Answer} />
          <Route
            exact
            path={`${routePaths.introduction}`}
            component={Introduction}
          />

          <Route
            exact
            path={`${routePaths.start}`}
            component={CalibrationTest}
          />
          <Route
            exact
            path={`${routePaths.instruction}`}
            component={TestInstruction}
          />
          <Route exact path={`${routePaths.progress}`} component={Progress} />
          <Route exact path={`${routePaths.examScheduled}`} component={Exam} />
          <Route exact path={`${routePaths.report}`} component={Report} />
          <Route exact path={`${routePaths.review}`} component={Review} />
          <Route exact path={`${routePaths.exam}`} component={Test} />
          <Route
            exact
            path={`${routePaths.testInstructionSection}`}
            component={SectionIntro}
          />

          <Route exact path={`${routePaths.contactUs}`} component={ContactUs} />
          <Route
            exact
            path={`${routePaths.allCourses}`}
            component={AllCourses}
          />
        </Switch>
      </Box>
    );
  }
}

export default withRouter(Index);