/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  NoteDiv,
  NoteDiv2,
  NoteDivGreyText,
  NoteDivText,
  NoteDivText2,
  ButtonIcon,
  useStyles,
} from '../../../assets/css/dashboard/StyledNotesComponents';
import { Grid, Button, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '../../../assets/icons/delete.svg';
import ExpandMore from '../../../assets/icons/expand_more.svg';

const TEXT_DISPLAY_SIZE = 300;

export default function NoteBody(props) {
  const ArrowIcon = () => (
    <img
      src={ExpandMore}
      alt="Expand More Icon"
      className={'note-expand-more-icon'}
    />
  );

  const displayText = (text, show, id) => {
    if (show.indexOf(id) > -1) return text;
    else {
      if (text.length < TEXT_DISPLAY_SIZE) return text;
      else return text.slice(0, TEXT_DISPLAY_SIZE) + '...';
    }
  };

  const classes = useStyles();
  const {
    notesData,
    show,
    handleSecondary,
    active,
    handleEdit,
    handleInputChange,
    textFieldValue,
    handlePrimary,
    handleDelete,
    t,
  } = props;
  return (
    <div>
      {notesData !== undefined &&
        notesData.map(note => (
          <NoteDiv active={active === note.id} id={note.id}>
            <NoteDiv2>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <NoteDivText>{note.data}</NoteDivText>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={3}
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  {active !== note.id && (
                    <NoteDivGreyText className={'note-grey-text'}>
                      {note.timeSpend}
                    </NoteDivGreyText>
                  )}

                  <ButtonIcon
                    onClick={() => handleEdit(note.id, note.notes)}
                    className={
                      active === note.id
                        ? 'note-edit-icon-active'
                        : 'note-edit-icon'
                    }
                  >
                    <EditIcon />
                  </ButtonIcon>
                  <ButtonIcon
                    onClick={() => handleDelete(note.id, note.task.id)}
                  >
                    <img src={TrashIcon} alt="Trash Icon" />
                  </ButtonIcon>
                </Grid>
                <Grid item xs={12} md={active === note.id ? 12 : 9} container>
                  {active === note.id ? (
                    <TextField
                      name="textFieldValue"
                      multiline
                      rows={5}
                      value={textFieldValue}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        className: classes.textField,
                      }}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <NoteDivText2 show={show.indexOf(note.id) > -1}>
                      {displayText(note.notes, show, note.id)}
                    </NoteDivText2>
                  )}
                </Grid>
                <Grid item xs={9} container direction="row" alignItems="center">
                  <NoteDivGreyText>{note.subject.name}</NoteDivGreyText>
                  {ArrowIcon()}
                  <NoteDivGreyText>{note.concept.name}</NoteDivGreyText>
                  {ArrowIcon()}
                  <NoteDivGreyText>{note.topic.name}</NoteDivGreyText>
                  {ArrowIcon()}
                  <NoteDivGreyText>{note.task.name}</NoteDivGreyText>
                  {ArrowIcon()}
                </Grid>

                <Grid item xs={3} container justifyContent="flex-end">
                  {/* Secondary Button */}

                  {note.notes.length < TEXT_DISPLAY_SIZE && // Checking if show more/show less button show
                  active !== note.id ? null : (
                    <Button
                      variant="text"
                      color="primary"
                      style={{
                        textDecoration: active === note.id ? 'underline' : null,
                      }}
                      className={'note-header-drop-down-style'}
                      onClick={() => {
                        handleSecondary(note.id);
                      }}
                    >
                      {t(
                        active === note.id
                          ? 'Cancel'
                          : show.indexOf(note.id) > -1
                          ? 'Show less'
                          : 'Show more'
                      )}
                    </Button>
                  )}

                  {/* Primary Button */}
                  <Button
                    variant={active === note.id ? 'contained' : 'outlined'}
                    color="primary"
                    className={
                      active === note.id
                        ? classes.activeButton
                        : classes.passiveButton
                    }
                    onClick={() =>
                      handlePrimary(note.id, note.task.id, note.topic.id)
                    }
                  >
                    {t(active === note.id ? 'Save' : 'Take me to topic')}
                  </Button>
                </Grid>
              </Grid>
            </NoteDiv2>
          </NoteDiv>
        ))}
    </div>
  );
}
