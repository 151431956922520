/**
 * (c) CareerLabs. All rights reserved.
 **/
import CryptoJS from 'crypto-js';

const secretKey = 'lms@123';

export function encrypt(value) {
  // var encrypt = CryptoJS.AES.encrypt(JSON.stringify(value),secretKey).toString();
  var encrypt = CryptoJS.AES.encrypt(value, secretKey);
  return encrypt;
}
export function decrypt(encryptValue) {
  var bytes = CryptoJS.AES.decrypt(encryptValue || '', secretKey);
  var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}
