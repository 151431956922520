import { Badge } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WebinarIconButton } from '../../../../assets/css/dashboard/CalendarComponents';
import WebinarIcon from '../../../../assets/icons/WebinarIcon.svg';
import { getStudyPlan } from '../../../../redux/action/Dashboard';
import { removeWebinar } from '../../../../redux/action/DashboardLanding';
import RichTooltip from '../../../../utils/components/RichToolTip';
import JoinWebinar from '../../user/component/JoinWebinar';
import WebinarPopOver from './WebinarPopOver';
export class WebinarCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      openWebinarPopup: false,
      arrowRef: null,

      webinarObj: {},
    };

    this.refObj = React.createRef();
  }

  handleWebinarIconMouseHover = e => {
    this.setState({
      anchorEl: e.currentTarget,
      openWebinarPopup: Number(e.currentTarget.id),
    });
  };

  handleWebinarIconMouseHoverLeave = e => {
    this.setState({
      anchorEl: null,
      openWebinarPopup: 0,
    });
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  handleWebinarActionClick = obj => {
    const userData = this.props.user;

    const webinarObj = {
      heading: obj.title,
      webinarOverview: obj.description,
      profilePic: obj.hostImageUrl,
      hostName: obj.hostName,
      dialogDate: obj.date,
      dialogTime: obj.time,
      duration: obj.remainingHours,
      status: obj.status,
      id: obj.id,
      wallWebinar: obj.wallWebinar,
      zoomLink: obj.zoomLink,
      openDialog: true,
      userName: userData ? `${userData.firstName} ${userData.lastName}` : '',
      email: userData ? userData.email : '',
      phone: userData ? userData.phoneNumber : '',
      handleDialogClose: this.handleDialogClose,
      checkhrs: obj.remainingHours,
    };

    this.setState({
      webinarObj: webinarObj,
    });

    this.handleWebinarIconMouseHoverLeave();
  };

  handleCancelClick = () => {
    this.setState({
      webinarObj: {},
    });
  };

  handleRemoveRegistration = () => {
    this.props.removeWebinar(this.state.webinarObj.id, response => {
      if (response.success) {
        this.handleCancelClick();
        this.props.getStudyPlan(res => {});
        this.props.onWebinarDelete();
      }
    });
  };

  render() {
    if (this.props.data.webinar.length > 0) {
      return (
        <div>
          <RichTooltip
            placement="left-end"
            open={this.state.openWebinarPopup === this.props.data.date}
            content={
              <WebinarPopOver
                items={this.props.data.webinar}
                handleWebinarClick={this.handleWebinarActionClick}
              />
            }
            onClose={this.handleWebinarIconMouseHoverLeave}
          >
            <Badge
              color="secondary"
              badgeContent={this.props.data.webinar.length}
            >
              <WebinarIconButton
                disableRipple
                disableFocusRipple
                onMouseOver={this.handleWebinarIconMouseHover}
                id={this.props.data.date}
              >
                <img src={WebinarIcon} alt="" />
              </WebinarIconButton>
            </Badge>
          </RichTooltip>
          <JoinWebinar
            {...this.state.webinarObj}
            handleDialogClose={this.handleCancelClick}
            handleActionClick={this.handleRemoveRegistration}
            unregister={true}
          />
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    studyPlan: state.DashBoardReducer.studyPlan,
    user: state.studentReducer.user,
  };
};

export default connect(mapStateToProps, { removeWebinar, getStudyPlan })(
  WebinarCard
);
