/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  ActionBox,
  Icon,
  Iconbutton,
  MonthStyle,
  ProgressBox,
  ProgressDivider,
  ProgressHeader,
  ProgressSubHeader,
} from '../../../assets/css/StyledDashboardComponent.js';
import leftIcon from '../../../assets/images/leftIcon.png';
import rightIcon from '../../../assets/images/rightIcon.png';
import LineChart from './LineChart.jsx';

export default props => {
  const {
    handleMonthChange,
    activeMonth,
    data,
    length,
    graphData,
    graphAnchorEl,
    graphPopperIndex,
    handleGraphPopperClose,
    handleGraphPopperOpen,
    anchorEl,
  } = props;
  return (
    <>
      <ProgressBox>
        <Grid container spacing={2}>
          <Grid item sm={7}>
            <ProgressHeader>A weekly progress tracker </ProgressHeader>
          </Grid>
          <Grid item sm={5}>
            <ActionBox>
              {data && activeMonth > 0 && (
                <Iconbutton id={1} name="prev" onClick={handleMonthChange}>
                  <Icon src={leftIcon} />
                </Iconbutton>
              )}
              <MonthStyle>{data && data.monthStr}</MonthStyle>
              {data && activeMonth < length - 1 && (
                <Iconbutton id={2} name="next" onClick={handleMonthChange}>
                  <Icon src={rightIcon} />
                </Iconbutton>
              )}
            </ActionBox>
          </Grid>
        </Grid>
      </ProgressBox>
      <ProgressDivider />
      <ProgressSubHeader>
        {data && `${data.monthSmall} ${data.year}`}
      </ProgressSubHeader>
      <LineChart
        graphData={graphData}
        graphAnchorEl={graphAnchorEl}
        graphPopperIndex={graphPopperIndex}
        handleGraphPopperClose={handleGraphPopperClose}
        handleGraphPopperOpen={handleGraphPopperOpen}
        anchorEl={anchorEl}
      />
    </>
  );
};
