/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Select, IconButton } from '@material-ui/core';
import { createTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const notesTheme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        margin: '2px 8px',

        '&$selected': {
          backgroundColor: '#F2F9FF !important',
        },
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#F2F9FF !important',
        },
      },
    },
    // MuiSelect: {
    //   root: {
    //     borderRadius: "30px !important",
    //   },
    // },
  },
});

export const StyledSelect = withStyles(theme => ({
  root: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#1093ff',
    //     background: #FFFFFF;
    // box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
    // border-radius: 30px;
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '30px',
    padding: '8px 12px',
  },
  select: {
    paddingRight: '40px !important',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '30px !important',
  },
  icon: {
    marginRight: '10px',
    color: '#1093FF',
  },
}))(Select);

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: stretch;
  background: #f7f8fc;
  padding: 54px 42px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #646464;
  padding-left: 8px;
`;

export const NoteDiv = styled.div`
  background: ${props => (props.active ? '#F1F1F1' : '#ffffff')};
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  border-left-style: solid;
  border-width: 8px;
  border-color: ${props => (props.active ? '#1093FF' : '#E7F4FF')};
  margin-bottom: 20px;
`;

export const NoteDiv2 = styled.div`
  padding: 20px 16px;
`;

export const NoteDivText = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #052a4e;
  border-left-style: solid;
  border-width: 3p;
  border-color: #1093ff;
  padding-left: 5px;
`;

export const NoteDivText2 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding-bottom: 8px;
  word-break: break-word;
`;

export const NoteDivGreyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #6c7082;
`;

export const ButtonIcon = withStyles({
  root: {
    padding: '8px',
    borderRadius: '4px',
    // background: '#aqua',
  },
})(IconButton);

export const useStyles = makeStyles({
  underline: {
    borderBottom: 'none',
  },

  textField: {
    fontSize: '16px',
    lineHeight: '26px',
    color: '#052A4E',
    background: '#ffffff',
    borderRadius: '8px',
    height: '147px',
    padding: '16px',
  },

  activeButton: {
    padding: '10px 43px',
    marginRight: '32px',
    borderRadius: '30px',
  },

  passiveButton: {
    borderRadius: '30px',
  },
});

export const DialogDiv = styled.div`
  padding: 60px;
  height: 308px;
  // width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const HeadText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #052a4e;
  padding-bottom: 24px;
`;

export const BodyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #052a4e;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const B1 = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
`;

export const B2 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #052a4e;
  max-width: 600px;
`;
export const B3 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000;
  max-width: 600px;
`;

export const CardText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;
