/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { LiveBannerBox } from '../../../assets/css/practice/WebinarComponents';
import Slider from 'react-slick';
import Webinar from '../../dashboard/user/component/Webinar';

function LiveWebinarBanner({ webinarData, webinarProps }) {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    arrows: false,
    adaptiveHeight: true,
    dots: true,
    dotsClass: 'top-banner-dot-class',
  };

  return (
    <LiveBannerBox>
      <div style={{ width: '480px' }}>
        <Slider {...sliderSettings}>
          {webinarData.map(item => (
            <Webinar webinarData={item} {...webinarProps} />
          ))}
        </Slider>
      </div>
    </LiveBannerBox>
  );
}

export default LiveWebinarBanner;
