/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React from 'react';
import {
  Popper,
  PopperTime,
  PopperTitle,
  WebinarBox,
} from '../../../../assets/css/dashboard/CalendarComponents';

function WebinarPopOver({ items, handleWebinarClick }) {
  const lastItem = items[items.length - 1];

  return (
    <Popper>
      {items.map(item => {
        return (
          <WebinarBox
            lastItem={lastItem === item}
            onClick={() => handleWebinarClick(item)}
          >
            <PopperTitle style={{ marginBottom: '0.7em' }}>
              {item.title}
            </PopperTitle>
            <div className="flex-box-row">
              <Box pr={1}>
                <AccessTimeIcon color="primary" />
              </Box>
              <PopperTime>{item.time}</PopperTime>
            </div>
          </WebinarBox>
        );
      })}
    </Popper>
  );
}

export default WebinarPopOver;
