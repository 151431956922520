/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { ThemeProvider, MenuItem, Box } from '@material-ui/core';
import {
  HeadDiv,
  Heading,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import {
  StyledSelect,
  listItem,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import { ExpandMore } from '@material-ui/icons';
import {
  SelectBox,
  SelectFlex,
} from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';

export default function Header(props) {
  const {
    subjectData,
    conceptData,
    handleChange,
    handleConceptChange,
    subjectValue,
    conceptValue,
    order,
    sortDateData,
    handleSortDateChange,
    activeTab,
    data,
    handleClick,
    t,
  } = props;
  return (
    <>
      <HeadDiv>
        <Heading style={{ paddingRight: '10px' }}>{t('Bookmarks')}</Heading>
        <SelectFlex>
          <ThemeProvider theme={listItem}>
            <SelectBox>
              <StyledSelect
                name="subjectValue"
                onChange={handleChange}
                value={subjectValue}
                IconComponent={ExpandMore}
                disableUnderline
                style={{ marginRight: '20px' }}
                MenuProps={DROP_DOWN_PROPS}
              >
                <MenuItem value="default" disabled>
                  Subject
                </MenuItem>
                {subjectData !== undefined &&
                  subjectData.map(subject => {
                    return (
                      <MenuItem value={subject.id}>{subject.title}</MenuItem>
                    );
                  })}
              </StyledSelect>
              <StyledSelect
                name="conceptValue"
                onChange={handleConceptChange}
                value={conceptValue}
                IconComponent={ExpandMore}
                disableUnderline
                MenuProps={DROP_DOWN_PROPS}
              >
                <MenuItem value="default" disabled>
                  Concept
                </MenuItem>
                {conceptData !== undefined &&
                  conceptData.map(concept => {
                    return (
                      <MenuItem value={concept.id}>{concept.title}</MenuItem>
                    );
                  })}
              </StyledSelect>
            </SelectBox>
            <span style={{ padding: '0 16px 0 10px' }}>
              <StyledSelect
                name="order"
                onChange={handleSortDateChange}
                value={order}
                IconComponent={ExpandMore}
                disableUnderline
                disabled
                MenuProps={DROP_DOWN_PROPS}
              >
                {sortDateData !== undefined &&
                  sortDateData.map(date => {
                    return <MenuItem value={date.id}>{t(date.title)}</MenuItem>;
                  })}
              </StyledSelect>
            </span>
          </ThemeProvider>
        </SelectFlex>
      </HeadDiv>
      <Box
        className={'border_style'}
        justifyContent={'center'}
        paddingTop={'20px'}
      >
        {data.map((tab, index) => (
          <div
            className={
              activeTab === index
                ? 'active-topic-name plain-header-name'
                : 'plain-header-name'
            }
            onClick={() => handleClick(index)}
          >
            {t(tab.title)}
          </div>
        ))}
      </Box>
    </>
  );
}
