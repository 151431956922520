/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import { makeStyles, TextField } from '@material-ui/core';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 555px !important;
  padding: 46px;
`;

export const Popup = styled(Box)`
  display: flex;
  flex-direction: column;
  height: max-content !important;
  gap: 3em;
  padding: 20px 42px 45px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const PopupHead = styled(Head)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-bottom: 0px;
  margin-right: -1em;
`;
export const H1 = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1093ff;
`;
export const PopupH1 = styled(H1)`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
  margin-top: -1em;
`;

export const Grey = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #686868;
  mix-blend-mode: normal;
  padding-bottom: 12px;
`;

export const Input = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    // fontSize: '14px',
    fontSize: '30px !important',
    lineHeight: '20px',
    color: '#052A4E',
  },
})(TextField);

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  align-self: center;
`;

export const DialogDiv = styled.div`
  padding: 20px 20px;
  min-height: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 124px;
  background: rgba(16, 147, 255, 0.05);
  border-radius: 50%;
  align-self: center;
`;

export const HeadText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #052a4e;
`;

export const BodyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #052a4e;
`;

export const useStyles = makeStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#052A4E',
    "& input[type='text']": {
      outline: 'none !important',
    },
  },
});
