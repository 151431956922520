import { Box, Divider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/test/Style.css';
import {
  BookMarkContainer,
  Container,
  ContinueButton,
  Div,
  Footer,
  Icon,
  Next,
  Pause,
  PauseModelSubTitle,
  PauseModelTitle,
  QuestionBody as Body,
  QuestionCount,
  QuestionTitle,
  QuitButton,
  TestTitle,
  TimeRemaining,
  TitleContainer,
  TitleHeader,
} from '../../../../assets/css/test/TestComponent';
import BookmarkIcon from '../../../../assets/icons/Bookmarks.svg';
import PauseModelIcon from '../../../../assets/icons/pause.svg';
import PauseIcon from '../../../../assets/icons/pauseIcon.svg';
import testEnd from '../../../../assets/images/testEnd.png';
import { EVENTS } from '../../../../clevertap/Events';
import { QS_PROPS } from '../../../../constant/Variables';
import * as track from '../../../../mixpanel/Events';
import { retakeExam } from '../../../../redux/action/Dashboard';
import {
  AddBookmarks,
  removeDemoBookmark,
} from '../../../../redux/action/Practice';
import {
  pauseExam,
  startTest,
  submitAnswer,
} from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import { RenderBookMark } from '../../../../utils/components/Bookmark';
import DialogComponent from '../../../../utils/components/DialogComponent';
import PageLoader from '../../../../utils/components/PageLoader';
import Timer from '../../../../utils/components/Timer';
import Model from '../../../../utils/Model';
import Passage from './Passage';
import SingleSelect from './SingleSelect';
import clevertap from 'clevertap-web-sdk';

var bookmarkTimeout = null;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      selectedChoice: [],
      time: -1,
      answer: '',
      bundleSelect: [],
      modelOpen: false,
      isLoading: false,
      demoBookmark: false,
      bookMarked: false,
      question_Id: null,
      dialogOpen: false,
      answer1: '',
    };
    this.time = -1;
  }

  componentDidMount() {
    console.log('aravind-devvops');
    const { testQuestionId, status, og_course, filter_id, qbank_type } =
      QueryString.parse(this.props.location.search, QS_PROPS);
    const combination =
      qbank_type === 'QUESTION'
        ? JSON.parse(sessionStorage.getItem('combination1'))
        : qbank_type === 'PASSAGE'
        ? JSON.parse(sessionStorage.getItem('combination2'))
        : qbank_type === 'undefined' || og_course === 'GMAT'
        ? JSON.parse(sessionStorage.getItem('combination'))
        : 'bel';
    const official_guide_gmat_question = sessionStorage.getItem(
      'official_guide_gmat_question'
    )
      ? JSON.parse(sessionStorage.getItem('official_guide_gmat_question'))
      : [];
    const noTimer = localStorage.getItem('lmsTimerpopup') ? true : false;
    if (testQuestionId) {
      this.props.retakeExam(
        testQuestionId,
        status,
        og_course,
        official_guide_gmat_question,
        combination,
        filter_id,
        noTimer,

        response => {
          if (response.success) {
            if (response.message === 'You already Complete the Test') {
              this.props.history.push(routePaths.report + '?page=insights');
            }
            let userProduct = JSON.parse(localStorage.getItem('userProduct'));
            userProduct.testExecutionId = response.data.testExecutionId;
            localStorage.setItem('userProduct', JSON.stringify(userProduct));
            this.setState({
              question: response.data,
              question_Id: response.data.id,
              bookMarked: response.data.isBookmarked,
            });
            if (
              (og_course && og_course === 'GMAT') ||
              (og_course && og_course === 'QUESTIONBANK')
            ) {
              if (status)
                this.props.history.replace(
                  `${routePaths.exam}?testQuestionId=${testQuestionId}&status=RETAKE&og_course=${og_course}&filter_id=${response.data.testFilterId}&qbank_type=${qbank_type}`
                );
              else
                this.props.history.replace(
                  `${routePaths.exam}?testQuestionId=${testQuestionId}&og_course=${og_course}&filter_id=${response.data.testFilterId}&qbank_type=${qbank_type}`
                );
            }
          }
        }
      );
    } else {
      this.props.startTest(response => {
        if (response.success) {
          if (response.message === 'You already Complete the Test') {
            this.props.history.push(routePaths.report + '?page=insights');
          }

          let userProduct = JSON.parse(localStorage.getItem('userProduct'));
          userProduct.testExecutionId = response.data.testExecutionId;
          localStorage.setItem('userProduct', JSON.stringify(userProduct));
          this.setState({
            question: response.data,
            question_Id: response.data.id,
            bookMarked: response.data.isBookmarked,
          });
        }
      });
    }

    const getDemoBookmark = JSON.stringify(
      localStorage.getItem('demoBookmark')
    );
    const demoBookmark = eval(getDemoBookmark) === 'false' ? true : false;
    this.setState({ demoBookmark: demoBookmark });

    if (demoBookmark) {
      bookmarkTimeout = setTimeout(this.removeDemoBookmarkFunction, 5000);
    }
  }

  onSelect = choice => {
    this.setState({
      selectedChoice: choice.id,
    });
  };

  onMultiSelect = event => {
    const { selectedChoice } = this.state;
    const { id, checked } = event.target;
    let arr = selectedChoice;
    if (checked) arr.push(id);
    else arr = arr.filter(item => item !== id);
    this.setState({
      selectedChoice: arr,
    });
  };

  onBundleChange = (item, choice) => {
    const { bundleSelect } = this.state;
    var arr = [];
    if (bundleSelect.some(exist => exist.bundleNo === item.bundleNo)) {
      if (
        bundleSelect.some(
          exist => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = bundleSelect.filter(
          exist => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = bundleSelect
          .filter(exist => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = bundleSelect.concat({ ...item, ...choice });
    }
    this.setState({ bundleSelect: arr });
  };

  renderQuestion = () => {
    const {
      type,
      question,
      choices,
      description,
      imgURL,
      isHaveDescription,
      isHaveImage,
      optionalType,
      unit,
    } = this.state.question;

    if (type === 'SINGLE_SELECT') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onSelect}
          imgUrl={imgURL}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onSelect}
          description={description}
          imgUrl={imgURL}
        />
      );
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          subjective={true}
          onChange={value => this.setState({ answer: value })}
          answer={this.state.answer}
          imgUrl={imgURL}
          optionalType={optionalType}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'FRACTION') {
      return (
        <Passage
          // para={question}
          question={question}
          description={description}
          fraction={true}
          onChange={value => this.setState({ answer: value })}
          onChangeCapture={value => this.setState({ answer1: value })}
          fraction1={this.state.answer}
          fraction2={this.state.answer1}
          imgUrl={imgURL}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'BUNDLE') {
      return (
        <Passage
          para={question}
          description={description}
          bundle={true}
          onChange={this.onBundleChange}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map(item => item.bundleNo)
          )}
          selectedChoice={this.state.bundleSelect}
          imgUrl={imgURL}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'MULTI_CHOICE') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onMultiSelect}
          imgUrl={imgURL}
          isMulti={true}
        />
      ) : (
        <SingleSelect
          question={question}
          options={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onMultiSelect}
          description={description}
          imgUrl={imgURL}
          isMulti={true}
        />
      );
    }
  };

  renderModel = t => {
    return (
      <Model open={this.state.modelOpen}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={5}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box>
            <img src={PauseModelIcon} alt="" />
          </Box>
          <PauseModelTitle>{t('Test Paused')}</PauseModelTitle>
          <PauseModelSubTitle>
            {t(`Press "Continue" to continue the test`)}
          </PauseModelSubTitle>
          <Box display={'flex'} gridGap={25}>
            <QuitButton onClick={this.pauseExam}>{t('Quit')}</QuitButton>
            <ContinueButton onClick={() => this.setState({ modelOpen: false })}>
              {t('Continue')}
            </ContinueButton>
          </Box>
        </Box>
      </Model>
    );
  };

  pauseExam = () => {
    const { testQuestionId, topicTest, diagnostic, topicId, subject, type } =
      QueryString.parse(this.props.location.search, QS_PROPS);
    this.props.pauseExam(
      this.state.question.id,
      this.state.question.testExecutionId,
      this.time,
      response => {
        if (response.success) {
          track.pauseExam({
            'Product Name': `${
              JSON.parse(localStorage.getItem('userProduct')).productName
            }`,
          });
          if (this.state.question.testType === 'CALIBRATION') {
            this.props.history.push(routePaths.allCourses);
          } else if (this.state.question.testType === 'OG_PRACTISETEST') {
            this.props.history.push(routePaths.dashboard.guide);
          } else if (testQuestionId) {
            // Diagnostics Engine path
            if (localStorage.getItem('boostScore')) {
              this.props.history.push(
                `${routePaths.dashboard.diagnosticEngine}?topicId=${this.state.question.topicId}`
              );
              localStorage.removeItem('boostScore');
            } else if (subject === 'subject') {
              this.props.history.push(
                `${routePaths.dashboard.topicTest}?topicId=${topicId}&type=${type}&subject=subject`
              );
            } else if (topicTest === 'Topictest') {
              this.props.history.push(routePaths.dashboard.topic);
            } else if (diagnostic === 'diagnostic-engine') {
              this.props.history.push(
                `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
              );
            } else
              this.props.history.push(
                routePaths.dashboard.topicTest +
                  '?topicId=' +
                  this.state.question.topicId
              );
          }
        }
      }
    );
  };

  next = () => {
    const { testTitle, conceptName } = this.state.question;
    const {
      og_course,
      filter_id,
      qbank_type,
      diagnostic,
      topicId,
      subject,
      type,
      topicTest,
    } = QueryString.parse(this.props.location.search, QS_PROPS);

    this.setState({ isLoading: true });

    let obj = {
      testExecutionId: this.state.question.testExecutionId,
      questionId: this.state.question.id,
      choices:
        this.state.answer === ''
          ? this.state.question.type === 'BUNDLE'
            ? this.state.bundleSelect.map(item => item.id)
            : this.state.question.type === 'MULTI_CHOICE'
            ? this.state.selectedChoice
            : [this.state.selectedChoice]
          : [],
      answer: this.state.answer,
      answer1: this.state.answer1,
      time: this.time,
      // og_course === 'GMAT' ? 1 : this.time,
    };
    if (topicTest === 'Topictest') {
      obj.bundleSelectedChoice = [];
    }

    const noTimer = localStorage.getItem('lmsTimerpopup') ? true : false;

    this.props.submitAnswer(obj, og_course, filter_id, noTimer, response => {
      this.setState({ isLoading: false });
      if (response.success) {
        if (response.message === 'You Complete the Test') {
          setTimeout(() => {
            localStorage.removeItem('lmsTimerpopup');
          }, 5000);
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': testTitle,
            'Topic name': response.data.topicName,
            'Concept name': conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
          if (response.data.testType === 'TOPIC') {
            if (diagnostic === 'diagnostic-engine') {
              this.props.history.push(
                `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
              );
            } else if (subject === 'subject') {
              this.props.history.push(
                `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&subject=subject&topicId=${topicId}&type=${type}`
              );
            } else
              this.props.history.push(
                routePaths.dashboard.resultProgress +
                  '?topic=' +
                  this.state.question.testTitle +
                  '&testExecutionId=' +
                  this.state.question.testExecutionId
              );
          } else if (response.data.testType === 'OG_PRACTISETEST') {
            this.props.history.push(
              `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&test_type=${response.data.testType}&og_course=${og_course}`
            );
          } else if (response.data.testType === 'QUESTIONBANK') {
            this.props.history.push(
              `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&test_type=${response.data.testType}&og_course=${og_course}&qbank_type=${qbank_type}`
            );
          } else {
            this.props.history.push(`${routePaths.progress}`);
          }
        } else if (response.message === 'Next TestSection') {
          localStorage.setItem(
            'calibrationSection',
            JSON.stringify({ section: 2 })
          );
          this.props.history.push(routePaths.testInstructionSection);
        }
        this.setState({
          question: response.data,
          question_Id: response.data.id,
          bookMarked: response.data.isBookmarked,
          selectedChoice: [],
          answer: '',
          answer1: '',
          bundleSelect: [],
        });
      }
    });
  };

  timeOver = () => {
    const { testTitle, conceptName } = this.state.question;

    const {
      og_course,
      filter_id,
      qbank_type,
      diagnostic,
      topicId,
      subject,
      type,
      topicTest,
    } = QueryString.parse(this.props.location.search, QS_PROPS);
    let obj = {
      testExecutionId: this.state.question.testExecutionId,
      questionId: this.state.question.id,
      choices: [],
      answer: this.state.answer,
      answer1: this.state.answer1,
      time: -1,
    };
    if (topicTest === 'Topictest') {
      obj.bundleSelectedChoice = [];
    }
    this.props.submitAnswer(obj, og_course, filter_id, false, response => {
      if (response.success) {
        if (response.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': testTitle,
            'Topic name': response.data.topicName,
            'Concept name': conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
          if (response.data.testType === 'TOPIC') {
            if (diagnostic === 'diagnostic-engine') {
              this.props.history.push(
                `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
              );
            } else if (subject === 'subject') {
              this.props.history.push(
                `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&subject=subject&topicId=${topicId}&type=${type}`
              );
            } else
              this.props.history.push(
                routePaths.dashboard.resultProgress +
                  '?topic=' +
                  this.state.question.testTitle +
                  '&testExecutionId=' +
                  this.state.question.testExecutionId
              );
          } else if (response.data.testType === 'OG_PRACTISETEST') {
            sessionStorage.removeItem('official_guide_gmat_question');
            this.props.history.push(
              `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&test_type=${response.data.testType}&og_course=${og_course}`
            );
          } else if (response.data.testType === 'QUESTIONBANK') {
            sessionStorage.removeItem('official_guide_gmat_question');
            this.props.history.push(
              `${routePaths.dashboard.questionBankReport}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&test_type=${response.data.testType}&og_course=${og_course}&qbank_type=${qbank_type}`
            );
          } else {
            this.props.history.push(`${routePaths.progress}`);
          }
        } else if (response.message === 'Next TestSection') {
          this.props.history.push(routePaths.testInstructionSection);
        } else {
          this.setState({
            question: response.data,
            question_Id: response.data.id,
            bookMarked: response.data.isBookmarked,
            stop: false,
            selectedChoice: [],
            answer: '',
            bundleSelect: [],
            resetTime: true,
          });
        }
      }
    });
  };

  disabled = () => {
    const { type, choices } = this.state.question;
    if (this.state.isLoading) {
      return true;
    } else if (
      type === 'SINGLE_SELECT' ||
      type === 'SINGLE_SELECT_PASSAGE' ||
      type === 'SINGLE_SELECT_IMAGE' ||
      type === 'MULTI_CHOICE'
    ) {
      return this.state.selectedChoice.length === 0;
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return this.state.answer.trim().length === 0;
    } else if (type === 'FRACTION') {
      return (
        this.state.answer.trim().length === 0 ||
        this.state.answer1.trim().length === 0
      );
    } else if (type === 'BUNDLE') {
      return (
        this.state.bundleSelect.length !==
        Math.max.apply(
          Math,
          choices.map(item => item.bundleNo)
        )
      );
    }
    return true;
  };

  handleBookmarkClick = () => {
    const { bookMarked } = this.state;
    if (bookMarked) this.setState({ dialogOpen: true });
    else this.handleButton2Click();
  };

  removeDemoBookmarkFunction = () => {
    this.props.removeDemoBookmark(res => {
      if (res.success) {
        localStorage.setItem('demoBookmark', true);
        this.setState({
          demoBookmark: false,
        });
        clearTimeout(bookmarkTimeout);
      }
    });
  };

  handleButton1Click = () => {
    this.setState({ dialogOpen: false });
  };

  handleButton2Click = () => {
    const { question_Id, demoBookmark } = this.state;
    var bookmarkData = { id: question_Id, type: 'question' };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        if (demoBookmark) {
          this.removeDemoBookmarkFunction();
        }
        this.setState({
          bookMarked: response.data.isBookmarked,
          dialogOpen: false,
        });
      }
    });
  };

  getRemainingTime = time => {
    this.time = time;
    if (time === 0) {
      this.setState({ stop: true });
      this.timeOver();
    }
  };
  handleEndTest = () => {
    const { og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.props.history.push(
      `${routePaths.dashboard.resultProgress}?topic=${this.state.question.testTitle}&testExecutionId=${this.state.question.testExecutionId}&test_type=OG_PRACTISETEST&og_course=${og_course}`
    );
    setTimeout(() => {
      localStorage.removeItem('lmsTimerpopup');
    }, 5000);
  };

  render() {
    if (this.state.question !== null) {
      const { currentQuestionNo, totalNoOfQuestion, status, conceptName } =
        this.state.question;
      const {
        handleButton1Click,
        handleButton2Click,
        handleBookmarkClick,
        handleEndTest,
      } = this;
      const { og_course } = QueryString.parse(
        this.props.location.search,
        QS_PROPS
      );
      const { t } = this.props;

      return (
        <Container>
          <BookMarkContainer
            className={
              this.state.demoBookmark
                ? 'demo__bookmark__first'
                : 'demo__bookmark__test'
            }
          >
            <RenderBookMark
              bookMarked={this.state.bookMarked}
              onClick={handleBookmarkClick}
              demoBookmark={this.state.demoBookmark}
            />
          </BookMarkContainer>
          <TestTitle>{this.state.question.testTitle}</TestTitle>
          <TitleContainer>
            <TitleHeader>
              <Div display={'flex'}>
                <QuestionCount bold="bold">{currentQuestionNo}</QuestionCount>
                <QuestionCount>/{totalNoOfQuestion}</QuestionCount>
                <QuestionTitle>{conceptName}</QuestionTitle>
                {/* {og_course === 'GMAT' ||
                  (og_course === 'QUESTIONBANK' && ( */}
                {/* <> */}
                {localStorage.getItem('lmsTimerpopup') ? (
                  ''
                ) : (
                  <>
                    <TimeRemaining>
                      {t('Time Remaining')}
                      {' -'}
                    </TimeRemaining>
                    <QuestionCount bold={500}>
                      <Timer
                        time={1000 * this.state.question.remainingTime}
                        getRemainingTime={this.getRemainingTime}
                        reset={this.state.question.resetTime}
                        stop={this.state.stop}
                      />
                    </QuestionCount>
                  </>
                )}
                {/* </> */}
                {/* ))} */}
              </Div>
              {/* <div>ajdfvshflfsfshf</div> */}
              <Div>
                <LinearProgress variant="determinate" value={status} />
              </Div>
              {this.state.question.isGmatOg === true ? (
                <TimeRemaining
                  style={{
                    float: 'right',
                    marginBottom: '-2em',
                    marginRight: '2rem',
                  }}
                >
                  {'OG Question Number - '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    {this.state.question.orderNo}
                  </span>
                </TimeRemaining>
              ) : (
                ''
              )}
            </TitleHeader>
          </TitleContainer>
          <Body>{this.renderQuestion()}</Body>
          <Footer>
            <Box width={'100%'}>
              <Divider />
            </Box>
            <Box
              pt={2}
              pb={2}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {og_course === 'GMAT' ? (
                <Pause
                  style={{
                    border: '2px dashed',
                    padding: '10px',
                    borderRadius: '50px',
                    width: 'max-content',
                    padding: '10px 35px',
                  }}
                  onClick={handleEndTest}
                >
                  <Icon src={testEnd} alt={''} />
                  <Box whiteSpace="nowrap">{t('End Test')}</Box>
                </Pause>
              ) : og_course === 'QUESTIONBANK' ? (
                <div />
              ) : (
                <Pause onClick={() => this.setState({ modelOpen: true })}>
                  <Icon src={PauseIcon} alt={''} />
                  <Box whiteSpace="nowrap">{t('Pause exam')}</Box>
                </Pause>
              )}
              <Next
                onClick={!this.disabled() ? this.next : () => {}}
                disabled={this.disabled()}
                loading={this.state.isLoading}
                color="primary"
                variant="contained"
              >
                {t('Next')}
              </Next>
            </Box>
            {this.renderModel(t)}
          </Footer>
          <DialogComponent
            open={this.state.dialogOpen}
            dialogContent={{
              icon: <img src={BookmarkIcon} alt="" />,
              title: t('Are you sure you want remove bookmark?'),
              button1: t('Cancel'),
              button2: t('Yes'),
            }}
            handleButton1Click={handleButton1Click}
            handleButton2Click={handleButton2Click}
          />
        </Container>
      );
    } else {
      return <PageLoader />;
    }
  }
}

const mapStateToProps = state => {
  return {
    questions: state.testReducer.questions,
    examResponse: state.DashBoardReducer.retake,
    sectionResponse: state.testReducer.testSection,
  };
};

export default connect(mapStateToProps, {
  startTest,
  submitAnswer,
  pauseExam,
  retakeExam,
  AddBookmarks,
  removeDemoBookmark,
})(withTranslation()(Index));
