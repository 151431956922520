import React, { Component } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { routePaths } from '../../../routes/RoutePath';
import { withRouter } from 'react-router-dom';
import '../../../assets/css/App.css';
import {
  AccountText,
  AccountLabel,
  AccountPass,
  NavigateBackIcon,
} from '../../../assets/css/StyledComponent';
import navigateBack from '../../../assets/images/navigateBack.png';
import { withTranslation } from 'react-i18next';
import PageLoader from '../../../utils/components/PageLoader';
class AccountSetting extends Component {
  handleGoBack = () => {
    this.props.history.push(routePaths.allCourses);
  };
  render() {
    const { t, courses } = this.props;
    if (courses)
      return (
        <Grid
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ position: 'relative' }}
        >
          <NavigateBackIcon
            top={'10px'}
            src={navigateBack}
            onClick={this.handleGoBack}
          />

          <Box className={'account-card-align'} style={{ paddingTop: 90 }}>
            <Box pb={6}>
              <AccountText>{t('My Account')}</AccountText>
            </Box>
            <AccountLabel>{t('Email ID')}</AccountLabel>
            <TextField
              id="no-outline"
              className={'account-text-field'}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={this.props.data.email}
            />

            <AccountLabel>{t('Mobile Number')}</AccountLabel>
            <TextField
              id="no-outline"
              className={'account-text-field'}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={this.props.data.phoneNumber}
            />

            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AccountPass
                onClick={() =>
                  this.props.history.push(routePaths.dashboard.changePassword)
                }
              >
                {t('Change Password')}
              </AccountPass>
            </Box>
          </Box>
        </Grid>
      );
    else return <PageLoader />;
  }
}

export default withRouter(withTranslation()(AccountSetting));
