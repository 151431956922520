/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/StyledPractice';
import {
  getStudyPlanConcepts as getConcepts,
  getStudyPlanSubjects as getSubjects,
} from '../../../redux/action/Dashboard';
import { getOneQuestion as retake } from '../../../redux/action/DashboardLanding';
import {
  getTestType,
  getTopicsQuestions,
  setMistakeLogState,
  getAllQuestionTypes,
} from '../../../redux/action/Practice';
import { routePaths } from '../../../routes/RoutePath';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import Left from './Left';
import Right from './Right';
import Top from './Top';

export const totalCount = data => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count = count + data[i].count;
  }
  return count;
};

class MistakeLogs extends Component {
  constructor(props) {
    super(props);

    this.breadCrumbsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Practise',
            },
            {
              name: 'Mistake Log',
            },
          ],
        },
      },
      back: true,
    };
  }

  componentDidMount() {
    const productId = localStorage.getItem('userProduct')
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : '';
    this.props.getAllQuestionTypes();

    if (this.props.mistakeLogState.subjectsValue === 'default') {
      this.props.getTestType(testTypeResponse => {
        if (testTypeResponse.success) {
          this.props.getSubjects(subjectResponse => {
            if (subjectResponse.success) {
              this.props.getConcepts('default', conceptResponse => {
                if (conceptResponse.success) {
                  this.props.setMistakeLogState({
                    ...this.props.mistakeLogState,
                    testTypeValue: 'default',
                    subjectsValue: 'default',
                    conceptsValue: 'default',
                    activeTopic: 'default',
                  });
                }
              });
            }
          });
        }
      });
    } else {
      const { conceptsValue, subjectsValue, questionType, testTypeValue } =
        this.props.mistakeLogState;
      this.props.getTopicsQuestions(
        subjectsValue,
        conceptsValue,
        questionType,
        testTypeValue,
        'DESC',
        productId,
        () => {}
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.allQuestionTypes &&
      prevProps.allQuestionTypes !== this.props.allQuestionTypes
    ) {
      if (this.props.allQuestionTypes.success) {
        this.props.setMistakeLogState({
          ...this.props.mistakeLogState,
          questionTypes: this.props.allQuestionTypes.data,
        });
      } else {
        this.props.setMistakeLogState({
          ...this.props.mistakeLogState,
          questionTypes: [],
        });
      }
    }
  }

  handleTestTypeChange = event => {
    const { subjectsValue, conceptsValue, questionType } =
      this.props.mistakeLogState;
    const productId = localStorage.getItem('userProduct')
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : '';

    this.props.getTopicsQuestions(
      subjectsValue,
      conceptsValue,
      questionType,
      event.target.value,
      'DESC',
      productId,
      topicsQuestionsResponse => {
        if (topicsQuestionsResponse.data.length === 1) {
          this.props.setMistakeLogState({
            ...this.props.mistakeLogState,
            activeTopic: topicsQuestionsResponse.data[0].id,
            testTypeValue: event.target.value,
          });
        } else
          this.props.setMistakeLogState({
            ...this.props.mistakeLogState,
            activeTopic: 'default',
            testTypeValue: event.target.value,
          });
      }
    );
  };

  handleSubjectChange = event => {
    const productId = localStorage.getItem('userProduct')
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : '';
    const { questionType } = this.props.mistakeLogState;
    const { subjects } = this.props;

    let newQuestionType = this.isQuestionDropdown(subjects, 'default')
      ? questionType
      : 'default';

    this.props.getConcepts(event.target.value, conceptResponse => {
      if (conceptResponse.success) {
        this.props.getTopicsQuestions(
          event.target.value,
          null,
          newQuestionType,
          this.props.mistakeLogState.testTypeValue,
          'DESC',
          productId,
          topicsQuestionsResponse => {
            if (topicsQuestionsResponse.success) {
              if (topicsQuestionsResponse.data.length === 1) {
                this.props.setMistakeLogState({
                  ...this.props.mistakeLogState,
                  activeTopic: topicsQuestionsResponse.data[0].id,
                  conceptsValue: conceptResponse.data[0].id,
                  subjectsValue: event.target.value,
                  questionType: newQuestionType,
                });
              } else {
                this.props.setMistakeLogState({
                  ...this.props.mistakeLogState,
                  activeTopic: 'default',
                  conceptsValue: 'default',
                  subjectsValue: event.target.value,
                  questionType: newQuestionType,
                  testTypeValue: 'default',
                });
              }
            }
          }
        );
      }
    });
  };

  handleConceptChange = event => {
    const productId = localStorage.getItem('userProduct')
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : '';
    const { subjectsValue, questionType, testTypeValue } =
      this.props.mistakeLogState;
    this.props.getTopicsQuestions(
      subjectsValue,
      event.target.value,
      questionType,
      testTypeValue,
      'DESC',
      productId,
      topicsQuestionsResponse => {
        if (topicsQuestionsResponse.success) {
          if (topicsQuestionsResponse.data.length === 1) {
            this.props.setMistakeLogState({
              ...this.props.mistakeLogState,
              conceptsValue: event.target.value,
              activeTopic: topicsQuestionsResponse.data[0].id,
            });
          } else
            this.props.setMistakeLogState({
              ...this.props.mistakeLogState,
              activeTopic: 'default',
              conceptsValue: event.target.value,
            });
        }
      }
    );
  };

  handleActiveTopic = topicId => {
    console.log(topicId, 'vel');
    this.props.setMistakeLogState({
      ...this.props.mistakeLogState,
      activeTopic: topicId,
    });
  };

  handleRetakeClick = (questionId, testQuestionSetId, index) => {
    this.props.history.push(
      `${routePaths.dashboard.question}?questionId=${questionId}&testQuestionId=${testQuestionSetId}&index=${index}&type=Reattempt&isBookmarked=false`
    );
  };

  onChange = e => {
    const productId = localStorage.getItem('userProduct')
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : '';
    const { subjectsValue, conceptsValue, questionType, testTypeValue } =
      this.props.mistakeLogState;
    const { name, value } = e.target;
    this.props.setMistakeLogState({
      ...this.props.mistakeLogState,
      [name]: value,
    });
    switch (name) {
      case 'questionType': {
        this.props.getTopicsQuestions(
          subjectsValue,
          conceptsValue,
          value,
          testTypeValue,
          'DESC',
          productId,
          () => {}
        );
        break;
      }
      default:
        break;
    }
  };

  isQuestionDropdown = (subjectOptions = [], subjectValue) => {
    return subjectOptions && subjectOptions.length !== 0 && subjectOptions.data
      ? subjectOptions.data.some(
          item => item.id === subjectValue && item.isQuestionTypeFilter
        )
      : false;
  };

  render() {
    const { testType, subjects, concepts, topicsQuestions, t } = this.props;
    const {
      testTypeValue,
      subjectsValue,
      conceptsValue,
      activeTopic,
      questionType,
      questionTypes,
    } = this.props.mistakeLogState;
    const {
      handleConceptChange,
      handleActiveTopic,
      handleTestTypeChange,
      handleSubjectChange,
      handleRetakeClick,
      onChange,
      isQuestionDropdown,
    } = this;

    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BreadCrumbs {...this.breadCrumbsProps} />
          </Grid>
          <Grid item xs={12}>
            <Top
              testType={testType}
              testTypeValue={testTypeValue}
              subjects={subjects}
              subjectsValue={subjectsValue}
              concepts={concepts}
              conceptsValue={conceptsValue}
              handleConceptChange={handleConceptChange}
              topics={topicsQuestions}
              handleTestTypeChange={handleTestTypeChange}
              handleSubjectChange={handleSubjectChange}
              onChange={onChange}
              t={t}
              questionType={questionType}
              questionTypes={questionTypes}
              isQuestionDropdown={isQuestionDropdown}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Left
              topics={topicsQuestions}
              activeTopic={activeTopic}
              handleActiveTopic={handleActiveTopic}
              t={t}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Right
              topicsQuestions={topicsQuestions}
              activeTopic={activeTopic}
              handleRetakeClick={handleRetakeClick}
              t={t}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    testType: state.PracticeReducer.testType,
    subjects: state.DashboardQuestionBankReducer.studyPlanSubjects,
    concepts: state.DashboardQuestionBankReducer.studyPlanConcepts,
    topicsQuestions: state.PracticeReducer.topicsQuestions,
    mistakeLogState: state.PracticeReducer.mistakeLogState,
    allQuestionTypes: state.PracticeReducer.allQuestionTypes,
  };
};

export default connect(mapStateToProps, {
  getTestType,
  getSubjects,
  getConcepts,
  getTopicsQuestions,
  retake,
  setMistakeLogState,
  getAllQuestionTypes,
})(withTranslation()(MistakeLogs));
