/**
 * (c) CareerLabs. All rights reserved.
 **/

import React from 'react';
import { Button, Dialog, IconButton, TextField, Grid } from '@material-ui/core';
import {
  Box,
  Head,
  H1,
  Grey,
  ButtonBox,
  DialogDiv,
  IconContainer,
  HeadText,
  BodyText,
  useStyles,
} from '../../../assets/css/StyledSupport';
import MessageIcon from '../../../assets/icons/MessageIcon.svg';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

export default function DialogComponent(props) {
  const {
    dialogState,
    type,
    handleClose,
    subject,
    body,
    handleTextChange,
    handleSend,
    subjectError,
    bodyError,
    t,
  } = props;
  const classes = useStyles();
  if (type === 'form')
    return (
      <Dialog open={dialogState} maxWidth="md" fullWidth>
        <Box>
          <Head>
            <H1>{t('Support Mail')}</H1>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon style={{ fill: '#1093FF' }} />
            </IconButton>
          </Head>
          <Grey>{t('Subject of Email')}</Grey>
          <TextField
            value={subject}
            name="subject"
            onChange={handleTextChange}
            variant="outlined"
            style={{ paddingBottom: '16px' }}
            InputProps={{ className: classes.root, required: true }}
            error={subjectError.length > 0}
            helperText={t(subjectError)}
          />
          <Grey>{t('Description of the Support')}</Grey>
          <TextField
            value={body}
            name="body"
            onChange={handleTextChange}
            variant="outlined"
            multiline
            rows={5}
            InputProps={{ className: classes.root }}
            style={{ paddingBottom: '60px' }}
            error={bodyError.length > 0}
            helperText={t(bodyError)}
          />
          <ButtonBox>
            <Button
              variant="outlined"
              color="primary"
              className={'round-button margin-style-right'}
              onClick={handleClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={'round-button margin-style-left'}
              onClick={handleSend}
            >
              {t('Submit')}
            </Button>
          </ButtonBox>
        </Box>
      </Dialog>
    );
  else if (type === 'confirmation')
    return (
      <Dialog open={dialogState} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogDiv>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12} container justifyContent="flex-end">
              <IconButton style={{ padding: '0 12px' }} onClick={handleClose}>
                <CloseRoundedIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <IconContainer>
                <img src={MessageIcon} alt="" />
              </IconContainer>
            </Grid>
          </Grid>
          <HeadText>{t('Your email has been sent successfully')}</HeadText>
          <BodyText>
            {t('Our experts will get in touch with you shortly')}
            {'.'}
          </BodyText>
          <Button
            color="primary"
            variant="contained"
            className={'button-style round-button'}
            onClick={handleClose}
            style={{ width: '162px', alignSelf: 'center', flexGrow: 0 }}
          >
            {t('Ok')}
          </Button>
        </DialogDiv>
      </Dialog>
    );
  else return null;
}
