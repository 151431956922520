import { Grid, Box } from '@material-ui/core';
import React, { Component } from 'react';
import {
  Card,
  CardContent,
  CardItem,
  CardTitle,
  ChartTitle,
  ChartValue,
  Item,
  ItemCaption,
  ItemIcon,
  PieChartContainer,
  ReportCardContainer,
} from '../../../../assets/css/report/Report';
import Doughnut from '../../course/graph/Doughnut';
import Circle from '../../../../assets/icons/circle.svg';
import Right from '../../../../assets/icons/right.svg';
import Wrong from '../../../../assets/icons/wrong.svg';

export default class ReportCard extends Component {
  render() {
    const { item } = this.props;
    let arr = [
      item && item.insights.donut.percentage,
      100 - item && item.insights.donut.percentage,
    ];
    var data = {
      datasets: [
        {
          label: 'Insights',
          data: arr,
          backgroundColor: ['rgb(33, 207, 125)', 'rgb(242, 248, 255)'],
          cutout: 40,
        },
      ],
      percentage: (item && item.insights?.donut?.percentage) || 0,
      summaryName: (item && item.insights?.donut?.title) || '',
      total: item && item.insights?.donut?.totalQuestion,
      count: item && item.insights?.donut?.score,
      isDefaultCircle: arr.every(item => item === 0),
      card: item && item.insights?.cards,
    };
    return (
      <ReportCardContainer>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={12} md={4}>
            <PieChartContainer>
              <Item width={170}>
                <Doughnut data={data} />
              </Item>
              <Item display={'flex'} direction={'column'}>
                <ChartTitle>{data.summaryName}</ChartTitle>
                <ChartValue>
                  {`${data.count}`} <Box fontWeight={400}>/{data.total}</Box>{' '}
                </ChartValue>
              </Item>
            </PieChartContainer>
          </Grid>

          {data.card &&
            data.card.map(item => (
              <Grid item sm={6} md={4}>
                <Card minHeight={'154px'} style={{ padding: '8px 20px' }}>
                  <CardTitle>{item.title}</CardTitle>
                  <CardContent>
                    {item.events.map(event => {
                      return (
                        <CardItem>
                          {event.icon !== null ? (
                            <ItemIcon>
                              <img
                                src={
                                  event.icon === 'Circle'
                                    ? Circle
                                    : event.icon === 'Right'
                                    ? Right
                                    : event.icon === 'Wrong'
                                    ? Wrong
                                    : null
                                }
                                alt=""
                              />
                            </ItemIcon>
                          ) : (
                            ''
                          )}
                          <ItemCaption flex={1} mt={'2px'} pl={'5px'}>
                            {event.title}
                          </ItemCaption>
                          <ItemCaption fw={600}>
                         
                          {event.time + ' mins'}

                          </ItemCaption>
                        </CardItem>
                      );
                    })}
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </ReportCardContainer>
    );
  }
}
