/**
 * (c) CareerLabs. All rights reserved.
 **/
import Dialog from '@material-ui/core/Dialog';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from 'react';
import '../../../assets/css/App.css';
import {
  CloseIconButton,
  TopicDialogContainer,
  CloseIconBox,
  TopicDialogTitle,
  TaskBox,
  Icon,
  TaskTitle,
  LinkButton,
  CardContainer,
} from '../../../assets/css/StyledDashboardComponent';
import todo from '../../../assets/icons/todo.svg';
import complete from '../../../assets/icons/completed.svg';
import progress from '../../../assets/icons/Progress.svg';

export default props => {
  const { open, dialogData, handleTaskClick, handleCloseIconClick, topicName } =
    props;
  if (dialogData !== null)
    return (
      <Dialog
        open={open}
        maxWidth={'sm'}
        PaperProps={{ className: 'topic__dialog' }}
        fullWidth
      >
        <TopicDialogContainer>
          <CloseIconBox>
            <CloseIconButton onClick={handleCloseIconClick}>
              <CloseRoundedIcon className={'closeIcon__color'} />
            </CloseIconButton>
          </CloseIconBox>
          <TopicDialogTitle>{topicName}</TopicDialogTitle>
          {dialogData &&
            dialogData.map(item => (
              <TaskBox>
                <CardContainer>
                  <Icon
                    src={
                      item.status.toLowerCase() === 'todo'
                        ? todo
                        : item.status.toLowerCase() === 'completed'
                        ? complete
                        : item.status.toLowerCase() === 'progress'
                        ? progress
                        : null
                    }
                  />
                  <TaskTitle>{item.task}</TaskTitle>
                </CardContainer>
                {item.status.toLowerCase() !== 'completed' && (
                  <LinkButton id={item.id} onClick={handleTaskClick}>
                    {item.status.toLowerCase() === 'todo'
                      ? 'Start now'
                      : item.status.toLowerCase() === 'progress'
                      ? 'Continue now'
                      : null}
                  </LinkButton>
                )}
              </TaskBox>
            ))}
        </TopicDialogContainer>
      </Dialog>
    );
  else return null;
};
