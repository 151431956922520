import {
  AccordionDetails,
  Accordion,
  Box,
  Grid,
  MenuItem,
} from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Card1,
  Card2,
  CardContent,
  CardItem,
  CardSubTitle,
  CardSubTitle1,
  CardTitle,
  FlexEnd,
  FlexView,
  FlexView2,
  Indicator,
  ItemCaption2,
  ItemCaption3,
  ChartTitle,
  TopicTitle,
  Title,
  CardHeader,
  CardHeaderTitle,
  CardPerentage,
  CardFooter,
  StyledSelectMenu,
  TopicCardHeader,
  Indicator2,
} from '../../../../../assets/css/GreReportStyledComponent';
import '../../../../../assets/css/GmatStyless.css';
import { Card } from '../../../../../assets/css/report/Report';
import { ExpandMore } from '@material-ui/icons';
import { AccordionSummary } from '../../../../../assets/css/StyledDashboard';

class ReportCard extends Component {
  constructor(props) {
    super(props);
  }

  renderTopics = () => {
    const { data, apiData } = this.props;
    const item = apiData?.data?.GMATOG?.summary?.concepts;
  };
  render() {
    const { data, apiData } = this.props;

    const summaryLevel = apiData?.data?.GMATOG?.summaryLevel;
    const concept = apiData?.data?.GMATOG?.summary?.concepts;
    const conceptTopic = apiData?.data?.GMATOG?.summary?.concepts?.topic;
    const topics = apiData?.data?.GMATOG?.summary?.topics;
    const summaryName = apiData?.data?.GMATOG?.summary?.name;
    console.log();
    return (
      <>
        {summaryLevel !== null ? (
          <Card1 pb={'35px'}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <CardHeaderTitle
                  style={{ display: 'flex', padding: '20px', color: '#1093ff' }}
                >
                  {summaryName}
                </CardHeaderTitle>
              </Grid>

              {summaryLevel === 'concepts' ? (
                <>
                  {concept &&
                    concept?.length !== 0 &&
                    concept?.map(item => {
                      return (
                        <Grid item md={4} spacing={2}>
                          <div style={{ padding: '2%' }}>
                            <Box pt={2}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{
                                    backgroundColor: '#f7f7f7',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    padding: '3%, 4%',
                                    width: '100%',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <CardHeader>
                                    <Indicator color={'#00cc00'} />
                                    <CardHeaderTitle
                                      style={{
                                        color: '#052a4e',
                                        width: '70%',
                                        paddingRight: '20%',
                                      }}
                                    >
                                      <b>{item.name}</b>
                                    </CardHeaderTitle>
                                    <CardHeaderTitle
                                      style={{
                                        color: '#052a4e',
                                        width: '19.5%',
                                        marginLeft: '-34px',
                                      }}
                                    >
                                      {item.correctQuestions}/
                                      {item.totalQuestions}
                                    </CardHeaderTitle>
                                    <CardHeaderTitle
                                      style={{
                                        color: '#1093ff',
                                        width: '21%',
                                        marginLeft: '-10px',
                                      }}
                                    >
                                      {item.percentage}%
                                    </CardHeaderTitle>
                                  </CardHeader>
                                </AccordionSummary>
                                <Box pt={1}>
                                  <div
                                    style={{
                                      padding: '2%',
                                    }}
                                  >
                                    <AccordionDetails
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        padding: '3%, 4%',
                                        width: '100%',
                                        borderRadius: '10px',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      {item.topics &&
                                        item.topics?.length !== 0 &&
                                        item.topics?.map(el => {
                                          return (
                                            <CardHeader
                                              style={{ marginBottom: '4%' }}
                                            >
                                              <Indicator2 color={'#000000'} />
                                              <CardHeaderTitle
                                                style={{
                                                  width: '70%',
                                                  paddingRight: '20%',
                                                }}
                                              >
                                                {el.name}
                                              </CardHeaderTitle>

                                              <CardHeaderTitle
                                                style={{
                                                  width: '19.5%',
                                                  marginLeft: '-65px',
                                                }}
                                              >
                                                {el.correctQuestions}/
                                                {el.totalQuestions}
                                              </CardHeaderTitle>
                                              <CardHeaderTitle
                                                style={{
                                                  width: '21%',
                                                  marginLeft: '-10px',
                                                }}
                                              >
                                                {' '}
                                                {el.percentage}%
                                              </CardHeaderTitle>
                                            </CardHeader>
                                          );
                                        })}
                                    </AccordionDetails>
                                  </div>
                                </Box>
                              </Accordion>
                            </Box>
                          </div>
                        </Grid>
                      );
                    })}
                </>
              ) : summaryLevel === 'topics' ? (
                <Grid container spacing={2} style={{ marginLeft: '20%' }}>
                  {/* <Grid item xs={12} sm={2} md={3}> */}
                  {topics?.map(item => {
                    return (
                      <Grid item xs={3} sm={3} md={3}>
                        <Card
                          style={{
                            backgroundColor: '#f7f7f7',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <CardHeader>
                            <Indicator color={'#000000'} />
                            <CardHeaderTitle>{item.name}</CardHeaderTitle>
                          </CardHeader>

                          <CardFooter style={{ paddingBottom: '5px' }}>
                            {item.score}
                            {item.percentage !== null ? (
                              <CardPerentage style={{ paddingTop: '3px' }}>
                                {item.percentage}%
                              </CardPerentage>
                            ) : null}
                          </CardFooter>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
            </Grid>
          </Card1>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default connect()(withTranslation()(ReportCard));
