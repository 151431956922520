/**
 * (c) CareerLabs. All rights reserved.
 **/
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiTextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Main = styled.div`
  padding: 16px 40px;
`;

export const TextField = withStyles({
  root: {},
})(MuiTextField);

export const InputAdornment = withStyles({
  root: {
    margin: 'auto 16px !important',
  },
})(MuiInputAdornment);

export const Button = withStyles({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#052A4E',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '150px',
    // height: '3rem',
    height: '48px',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
})(MuiButton);

export const Menu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 11px rgba(55, 143, 233, 0.25)',
    padding: '0.8rem',
  },
})(MuiMenu);

export const MenuItem = withStyles({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#646464',
    backgroundColor: 'white',
    height: '44px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  selected: {
    color: '#052A4E',
    backgroundColor: '#F2F9FF !important',
  },
})(MuiMenuItem);

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  width: fit-content;
  padding: 8px;
  width: 95%;
  height: max-content;
  margin: 4px auto;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #322c49;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1.4rem;
`;

export const CardTime = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #838383;
  text-align: right;
`;

export const PrevArrow = withStyles({
  root: {
    padding: '16px',
    position: 'absolute',
    transform: 'translate(0,-50%)',
    top: '50%',
    height: 48,
    width: 48,
    zIndex: 1,
    background: '#FFFFFF',
    boxShadow: '4px 4px 16px rgba(17, 121, 239, 0.4)',
    borderRadius: '50%',
    left: '-20px',

    '&:hover': {
      background: '#FFFFFF',
    },
  },
})(props => (
  <MuiIconButton {...props}>
    <ArrowBackIosIcon
      color="primary"
      style={{
        position: 'absolute',
        transform: 'translate(0,-50%)',
        left: '16px',
      }}
    />
  </MuiIconButton>
));

export const NextArrow = withStyles({
  root: {
    padding: '16px',
    position: 'absolute',
    transform: 'translate(0,-50%)',
    top: '50%',
    height: 48,
    width: 48,
    zIndex: 1,
    background: '#FFFFFF',
    boxShadow: '4px 4px 16px rgba(17, 121, 239, 0.4)',
    borderRadius: '50%',
    right: '-20px',

    '&:hover': {
      background: '#FFFFFF',
    },
  },
})(props => (
  <MuiIconButton {...props}>
    <ArrowForwardIosIcon
      color="primary"
      style={{
        position: 'absolute',
        transform: 'translate(0,-50%)',
        right: '12px',
      }}
    />
  </MuiIconButton>
));

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const IconButton = withStyles({
  root: {
    backgroundColor: 'rgba(16, 147, 255, 0.1)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(16, 147, 255, 0.1)',
    },
  },
})(props => <MuiIconButton {...props}>{props.children}</MuiIconButton>);

export const BackIconButton = withStyles({
  root: {
    backgroundColor: 'inherit',
  },
})(MuiIconButton);

export const SearchCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding: 8px;
  display: grid;
  grid-auto-flow: column;
  gap: 13px;
  justify-content: flex-start;
  margin: 6px;
`;

export const SearchVideo = styled.video`
  width: 157px;
  height: 97px;
`;

export const SearchVideoCard = styled.div`
  position: relative;
  width: fit-content;
  border-radius: 8px;
  outline: 1px solid burlywood;
`;

export const SearchIconBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Heading = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #052a4e;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #838383;
`;

export const DateText = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #838383;
`;

export const VideoHeading = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #052a4e;
`;

export const VideoDate = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;

export const SideHeading = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #052a4e;
`;

export const VideoBox = styled.div`
  border: 1px solid blue;
  height: 35rem;
  padding-top: 56.25%;

  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`;

export const LiveBannerBox = styled.div`
  height: 400px;
  background: #080026;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45rem;
`;
