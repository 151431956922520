/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../assets/css/LeftContainer.css';
import mailsent from '../../../assets/images/mailsent.png';
import { forgotPassword } from '../../../redux/action/Authentication';
import Timer from '../../../utils/components/Timer';

class EmailSent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 30000,
      reset: false,
      disable: true,
    };
  }

  handleClick = () => {
    let obj = {
      email: window.sessionStorage.getItem('email'),
    };
    this.props.forgotPassword(obj);
    this.setState({ reset: true });
  };

  getRemainingTime = time => {
    if (time === 0) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
    if (this.state.reset) this.setState({ reset: false });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Grid container>
          <IconButton
            style={{
              backgroundColor: '#C4E4FD',
              position: 'absolute',
              left: 18,
              top: 100,
              zIndex: 2,
            }}
            onClick={() => this.props.history.goBack()}
          >
            <ArrowBack style={{ color: '#1093FF' }} />
          </IconButton>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box>
              <h2 className={'_email_sent_title'}>
                {' '}
                {t('Email has been sent!')}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={4}>
              <Typography className={'_email_sent_sub_title'}>
                {t('We have sent a reset password link to')}{' '}
                {window.sessionStorage.getItem('email')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={2}>
              <img src={mailsent} alt="mailsent" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={1}>
              <Typography className={'_email_sent_botton_text'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box>{t('Didn’t receive the link?')}</Box>
                  <Box width={50} display={'flex'}>
                    &nbsp;
                    <Timer
                      time={this.state.time}
                      reset={this.state.reset}
                      isHour={false}
                      getRemainingTime={this.getRemainingTime}
                    />
                  </Box>
                  <Button
                    color="primary"
                    style={{ textTransform: 'none', fontSize: '17px' }}
                    onClick={this.handleClick}
                    id={'resendEmailButton'}
                    disabled={this.state.disable}
                  >
                    {t('resend')}
                  </Button>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { forgotPasswordResponse: state.AuthReducer.forgotPasswordData };
};

export default connect(mapStateToProps, { forgotPassword })(
  withTranslation()(EmailSent)
);
