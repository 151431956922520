/**
 * (c) CareerLabs. All rights reserved.
 **/
import Cookies from 'js-cookie';
import { DEFAULT_URL } from '../../api/API';
import { GET, PUT, POST, DELETE } from '../../axios/Axios';
import { decrypt } from '../../utils/Crypto';
import { catchError } from '../../utils/methods/Helper';
import { DASHBOARD, DASHBOARD_QUESTION_BANK } from '../Action';

export const getStudyPlan = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/monthly`,
      {},
      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.getStudyPlan,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getSubjectDetails = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = JSON.parse(localStorage.getItem('userProduct')).id;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/details`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getStudyPlanDetails,
          payload: response.date,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getPlanDetails = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = JSON.parse(localStorage.getItem('userProduct')).id;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/student/${studentId}/product/${productId}/studyplan/details`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getPlanDetails,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getTopic = (callback, topicId) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const productId =
    localStorage.getItem('userProduct') !== null
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : null;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/tasks`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getTopic,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updateCompletedTask = (data, type, isNewVideos, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const productId =
    localStorage.getItem('userProduct') !== null
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : null;

  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/task/completed`,
      data,
      { type: type, isNewVideos: Boolean(isNewVideos) },
      response => {
        dispatch({
          type: DASHBOARD.updateCompletedTask,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getAllQuestions = (level, topicId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/topic/${topicId}/testQuestionSet`,
      {
        type: `QUESTIONBANK`,
        difficultyLevel: level,
      },
      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.getAllQuestions,

          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getAllTopics = (topicId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/topic/${topicId}/testQuestionSet`,
      {
        type: `TOPIC`,
      },
      response => {
        dispatch({
          type: DASHBOARD.getAllTopics,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const pauseTask = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId =
    localStorage.getItem('userProduct') !== null
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : null;

  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/task/pause`,
      data,
      {},
      response => {
        dispatch({
          type: DASHBOARD.pauseTask,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const topicTestInstruction = (
  testQuestionId,
  og_course,
  official_guide_gmat_question,
  combination,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  let payload = og_course === 'GMAT' ? [...official_guide_gmat_question] : {};
  let params = og_course === 'GMAT' ? { ...combination } : {};

  return dispatch => {
    if (og_course === 'GMAT')
      POST(
        `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testInstruction/og`,
        payload,
        { ...params },
        response => {
          callback(response.data);
          dispatch({
            type: DASHBOARD.topicTestInstruction,
            payload: response.data,
          });
        },
        error => {
          // console.log(error);
        }
      );
    else
      GET(
        `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testInstructions`,
        {},
        response => {
          callback(response.data);
          dispatch({
            type: DASHBOARD.topicTestInstruction,
            payload: response.data,
          });
        },
        error => {
          // console.log(error);
        }
      );
  };
};

export const questionBankTestInstruction = (
  testQuestionId,
  og_course,
  official_guide_gmat_question,
  combination,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  let payload =
    og_course === 'QUESTIONBANK' ? [...official_guide_gmat_question] : {};
  let params = og_course === 'QUESTIONBANK' ? { ...combination } : {};
  return dispatch => {
    if (og_course === 'QUESTIONBANK')
      POST(
        `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testInstruction/og`,
        payload,
        { ...params },
        response => {
          callback(response.data);
          dispatch({
            type: DASHBOARD.questionBankTestInstruction,
            payload: response.data,
          });
        },
        error => {
          // console.log(error);
        }
      );
    else
      GET(
        `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testInstructions`,
        {},
        response => {
          callback(response.data);
          dispatch({
            type: DASHBOARD.questionBankTestInstruction,
            payload: response.data,
          });
        },
        error => {
          // console.log(error);
        }
      );
  };
};

export const getStudyPlanSubjects = (
  callback = () => {},
  questionBankType = ''
) => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const isOfficialGuide = Boolean(
    localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).isOfficialGuidePresent
  );
  const product_id = isOfficialGuide
    ? JSON.parse(localStorage.getItem('userProduct')).officialGuideId
    : productId;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/subjects`,
      {
        questionBankType: questionBankType || '',
      },
      response => {
        dispatch({
          type: DASHBOARD_QUESTION_BANK.getStudyPlanSubjects,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getStudyPlanConcepts = (
  subjectId,
  callback,
  questionBankType = ''
) => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const isOfficialGuide = Boolean(
    localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).isOfficialGuidePresent
  );
  const product_id = isOfficialGuide
    ? JSON.parse(localStorage.getItem('userProduct')).officialGuideId
    : productId;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/subject/${subjectId}/concepts`,
      {
        questionBankType: questionBankType || '',
      },
      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD_QUESTION_BANK.getStudyPlanConcepts,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getStudyPlanTopics = (conceptId, callback, subjectId = null) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/concept/${conceptId}/topics`,
      {
        productId: productId,
        subjectId: subjectId,
      },
      response => {
        dispatch({
          type: DASHBOARD_QUESTION_BANK.getStudyPlanTopics,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getStudyPlanQuestions = (
  callback,
  topicId,
  attemptStatus,
  difficultyLevel,
  questionType,
  subjectId,
  conceptId
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/topic/${topicId}/testQuestionSet`,
      {
        type: 'QUESTIONBANK',
        difficultyLevel: difficultyLevel?.toString(),
        status: attemptStatus,
        questionType: questionType === 'All' ? null : questionType,
        subjectId: subjectId,
        conceptId: conceptId,
        productId: productId,
      },
      response => {
        dispatch({
          type: DASHBOARD_QUESTION_BANK.getStudyPlanQuestions,
          payload: response.data.data.questions,
          difficultyLevel: difficultyLevel,
          attemptStatus: attemptStatus,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getStrengthsAndWeakness = (subjectId, callback) => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/subject/${subjectId}/strengthWeakness`,
      {},
      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.getStrengthsAndWeakness,
          payload: response.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

/**
 * To Start or retake exam
 * @param {String} testQuestionId
 * @param {String} status
 * @param {String} og_course for using GMAT OG Only
 * @param {Array} official_guide_gmat_question for using GMAT OG Only
 * @param {String} filterId for using GMAT OG Only
 * @param {Function} callback
 * @returns Function
 */
export const retakeExam = (
  testQuestionId,
  status,
  og_course,
  official_guide_gmat_question,
  combination,
  filterId,
  noTimer = false,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  let payload =
    og_course === 'GMAT' || og_course === 'QUESTIONBANK'
      ? official_guide_gmat_question
      : {};
  let params =
    og_course === 'GMAT'
      ? { filterId: filterId, ...combination }
      : og_course === 'QUESTIONBANK'
      ? { filterId: filterId, ...combination }
      : { retake: status === 'RETAKE' ? true : false };
  let nopopupTimer = og_course === 'GMAT' ? { noTimer: noTimer } : {};

  return dispatch => {
    POST(
      og_course === 'GMAT' || og_course === 'QUESTIONBANK'
        ? `${DEFAULT_URL}/lms/ogtest/start/student/${studentId}/testquestionsset/${testQuestionId}`
        : `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${testQuestionId}/testExecutions`,
      payload,
      { ...params, ...nopopupTimer },
      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.retakeExam,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};
// report

export const getReport = (testExecutionId, isRetaken, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testExecution/${testExecutionId}/topicTestReport`,
      {
        isRetaken: Boolean(isRetaken),
      },

      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.getReport,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};
export const getQbankReport = (testExecutionId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testexecution/${testExecutionId}/qbtestreport`,
      {},

      response => {
        callback(response.data);
        dispatch({
          type: DASHBOARD.getQbankReport,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getNotesWithTopic = (topicId, subjectId, conceptId) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/topic/${topicId}/notes?subjectId=${subjectId}&conceptId=${conceptId}`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getNotesWithTopic,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const setNoteChanges = (taskId, noteId, data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/task/${taskId}/notes/${noteId}`,
      data,
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const removeNote = (taskId, noteId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    DELETE(
      `${DEFAULT_URL}/lms/students/${studentId}/task/${taskId}/notes/${noteId}`,

      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getTopicDetail = (
  topicId,
  taskId,
  type,
  isNewVideos,
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId =
    localStorage.getItem('userProduct') !== null
      ? JSON.parse(localStorage.getItem('userProduct')).id
      : null;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/tasks`,
      {
        taskId: taskId,
        type: type ? type.toUpperCase() : 'NORMAL',
        isNewVideos: Boolean(isNewVideos),
      },
      response => {
        callback(response.data);

        dispatch({
          type: DASHBOARD.getTopicDetails,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getNotesWithTask = taskId => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/task/${taskId}/notes`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getNotesWithTask,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const addNotes = (taskId, notesData, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/task/${taskId}/notes`,
      notesData,
      {},
      response => {
        dispatch({
          type: DASHBOARD.addNotes,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getAllNotes = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/notes`,
      {},
      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD.getAllNotes,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const AllNotes = obj => {
  return dispatch => {
    dispatch({
      type: DASHBOARD.AllNote,
      payload: obj,
    });
  };
};

export const getStudyPlanByDate = currentDate => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans`,
      {},
      {
        field: 'studyPlanByDay',
        studyPlanDate: currentDate,
      },
      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD.studyPlanByDate,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getStudyPlanConceptTopics = (
  subjectId,
  conceptId,
  callback,
  questionBankType = ''
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/concept/${conceptId}/subject/topics`,
      {
        productId: productId,
        subjectId: subjectId,
        questionBankType: questionBankType,
      },
      response => {
        dispatch({
          type: DASHBOARD_QUESTION_BANK.getStudyPlanConceptTopics,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getTopicInsights = (topicId, callback) => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/report`,
      {},
      response => {
        callback(response.data);
      },
      error => {
        callback(catchError(error));
      }
    );
  };
};

export const getAllSubjectsByProduct = (callback = () => {}) => {
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : '';
  const isOfficialGuide = Boolean(
    localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct')).isOfficialGuidePresent
  );
  const product_id = isOfficialGuide
    ? JSON.parse(localStorage.getItem('userProduct')).officialGuideId
    : productId;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/subjects/product/${product_id}`,
      {},
      response => {
        dispatch({
          type: DASHBOARD.getAllSubjectsByProduct,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getAllConceptsBySubject = (subjectId, callback) => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/concepts/subject/${subjectId}`,
      {},
      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD.getAllConceptsBySubject,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getAllTopicsByConcept = (subjectId, conceptId, callback) => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/concept/topic/filter`,
      {
        id: conceptId,
        subjectId: subjectId,
      },
      response => {
        dispatch({
          type: DASHBOARD.getAllTopicsByConcept,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getStartInVideo = (videoId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  // const productId = localStorage.getItem('userProduct')
  //   ? JSON.parse(localStorage.getItem('userProduct')).id
  //   : '';
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/taskcontentvideo/${videoId}/videotest`,
      {},
      {},

      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD.inVideoQuestion,
          payload: response.data.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const videoAnswer = (videoId, data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/student/${studentId}/taskcontentvideo/${videoId}/answers`,
      data,
      {},
      response => {
        dispatch({
          type: DASHBOARD.updateVideoAnswer,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};
