/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Card, Container, Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardTheme } from '../../../assets/css/MuiStyles';
import {
  FlexContainer,
  ProgressContainer,
} from '../../../assets/css/StyledDashboardComponent.js';
import { MONTH } from '../../../constant/Variables';
import {
  getProgressStatus,
  getProgressTracker,
} from '../../../redux/action/DashboardLanding';
import { routePaths } from '../../../routes/RoutePath';
import PageLoader from '../../../utils/components/PageLoader';
import Calendar from '../calendar/Calendar';
import WelcomeCard from './HeaderWelcomeCard';
import ProgressCard from './ProgressCard';
import TopicDialog from './TopicDialog';
import TopicListCard from './TopicListCard';

class ProgressTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMonth: 0,
      activeWeek: 0,
      dialogData: null,
      dialogOpen: false,
      topicId: null,
      topicName: '',
      popperAnchorEl: null,
      popperData: null,
      graphAnchorEl: null,
      graphPopperIndex: 0,
      anchorEl: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevState, prevProps) {
    // Refresh Data
    if (
      this.props.progressStatus === null &&
      this.props.progressTracker === null
    ) {
      this.props.getProgressStatus();
      this.props.getProgressTracker();
    }
  }

  handleMonthChange = e => {
    const { name } = e.currentTarget;
    const { activeMonth } = this.state;
    const progressTracker =
      this.props.progressTracker && this.props.progressTracker
        ? this.props.progressTracker
        : null;
    const length = progressTracker && progressTracker.length;
    if (name === 'next' && activeMonth < length - 1)
      this.setState({ activeMonth: activeMonth + 1, activeWeek: 0 });
    if (name === 'prev' && activeMonth > 0)
      this.setState({ activeMonth: activeMonth - 1, activeWeek: 0 });
  };

  handleWeekChange = e => {
    const { name } = e.currentTarget;
    const { activeWeek, activeMonth } = this.state;

    const progressTracker =
      this.props.progressTracker && this.props.progressTracker
        ? this.props.progressTracker
        : null;
    const length =
      progressTracker &&
      progressTracker.length !== 0 &&
      progressTracker[activeMonth]['updates'].length !== 0 &&
      progressTracker[activeMonth]['updates'].length;
    if (name === 'next' && activeWeek < length - 1)
      this.setState({ activeWeek: activeWeek + 1 });
    if (name === 'prev' && activeWeek > 0)
      this.setState({ activeWeek: activeWeek - 1 });
  };

  handleTopicClick = (data, topicName, e) => {
    const { id } = e.currentTarget;
    if (data.length !== 0)
      this.setState({
        dialogData: data,
        topicName: topicName,
        dialogOpen: true,
        topicId: id,
      });
  };

  handleTaskClick = e => {
    const { topicId } = this.state;
    const { id } = e.currentTarget;
    if (id && topicId) {
      this.handleCloseIconClick();
      this.props.history.push(
        `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${id}&prevPage=progress_tracker`
      );
    }
  };

  handleCloseIconClick = () => {
    this.setState({
      dialogData: null,
      dialogOpen: false,
      topicId: null,
      topicName: '',
    });
  };

  handlePopperOpen = (status, data, e) => {
    const { id } = e.currentTarget;
    if (status && data)
      this.setState({
        topicId: id,
        popperAnchorEl: e.currentTarget,
        popperData: {
          ...data,
          assignDateText: 'Assigned date',
          completeDateText: 'Completed date',
          buttonText:
            data.status.toLowerCase() === 'progress'
              ? 'Continue now'
              : 'Start now',
        },
      });
  };

  handlePopperClose = () => {
    this.setState({ popperAnchorEl: null });
  };

  handlePopperButtonClick = e => {
    const { id } = e.currentTarget;
    if (id) {
      this.handlePopperClose();
      this.props.history.push(
        `${routePaths.dashboard.taskDetail}?topicId=${id}&prevPage=progress_tracker`
      );
    }
  };

  getDateString = date => {
    return `${date.getDate()} ${MONTH[date.getMonth()]} ${date.getFullYear()}`;
  };

  handleGraphPopperClose = () => {
    this.setState({ graphAnchorEl: null, graphPopperIndex: 0, anchorEl: null });
  };

  handleGraphPopperOpen = (values, e) => {
    if (!this.state.anchorEl) {
      const { index } = values;
      this.setState({
        graphAnchorEl: values,
        graphPopperIndex: index,
        anchorEl: e.target,
      });
    }
  };

  render() {
    const {
      activeMonth,
      activeWeek,
      dialogData,
      dialogOpen,
      topicName,
      topicId,
      popperAnchorEl,
      popperData,
      graphAnchorEl,
      graphPopperIndex,
      anchorEl,
    } = this.state;

    const {
      handleMonthChange,
      handleWeekChange,
      handleCloseIconClick,
      handleTaskClick,
      handleTopicClick,
      handlePopperClose,
      handlePopperOpen,
      handlePopperButtonClick,
      getDateString,
      handleGraphPopperClose,
      handleGraphPopperOpen,
    } = this;

    const {
      user: { firstName, lastName },
    } = this.props;
    const status = this.props.progressStatus;

    const progressTracker =
      this.props.progressTracker && this.props.progressTracker
        ? this.props.progressTracker
        : null;

    return (
      <ThemeProvider theme={dashboardTheme}>
        {progressTracker ? (
          <Container>
            <FlexContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={9}>
                  <WelcomeCard
                    username={`${firstName} ${lastName}`}
                    description={status ? status.text : ''}
                    imageSrc={status ? status.image : ''}
                  />
                  <ProgressContainer>
                    <ProgressCard
                      handleMonthChange={handleMonthChange}
                      activeMonth={activeMonth}
                      data={
                        progressTracker && progressTracker.length !== 0
                          ? progressTracker[activeMonth]
                          : null
                      }
                      length={progressTracker ? progressTracker.length : 0}
                      graphData={
                        progressTracker && progressTracker.length !== 0
                          ? progressTracker[activeMonth]['timeSpend'].length !==
                            0
                            ? progressTracker[activeMonth]['timeSpend']
                            : []
                          : []
                      }
                      graphAnchorEl={graphAnchorEl}
                      graphPopperIndex={graphPopperIndex}
                      handleGraphPopperClose={handleGraphPopperClose}
                      handleGraphPopperOpen={handleGraphPopperOpen}
                      anchorEl={anchorEl}
                    />
                    <TopicListCard
                      handleWeekChange={handleWeekChange}
                      activeWeek={activeWeek}
                      weekData={
                        progressTracker && progressTracker.length !== 0
                          ? progressTracker[activeMonth]['updates'].length !== 0
                            ? progressTracker[activeMonth]['updates'][
                                activeWeek
                              ]
                            : null
                          : null
                      }
                      weekLength={
                        progressTracker && progressTracker.length !== 0
                          ? progressTracker[activeMonth]['updates'].length !== 0
                            ? progressTracker[activeMonth]['updates'].length
                            : 0
                          : 0
                      }
                      handleTopicClick={handleTopicClick}
                      topicId={topicId}
                      popperAnchorEl={popperAnchorEl}
                      handlePopperClose={handlePopperClose}
                      handlePopperOpen={handlePopperOpen}
                      handlePopperButtonClick={handlePopperButtonClick}
                      popperData={popperData}
                      getDateString={getDateString}
                    />
                  </ProgressContainer>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Card>
                    <Calendar />
                  </Card>
                </Grid>
              </Grid>
            </FlexContainer>
            <TopicDialog
              open={dialogOpen}
              dialogData={dialogData}
              handleTaskClick={handleTaskClick}
              handleCloseIconClick={handleCloseIconClick}
              topicName={topicName}
            />
          </Container>
        ) : (
          <PageLoader />
        )}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    progressStatus: state.dashboardLandingReducer.progressStatus,
    progressTracker: state.dashboardLandingReducer.progressTracker,
  };
};

export default connect(mapStateToProps, {
  getProgressTracker,
  getProgressStatus,
})(ProgressTracker);
