/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { Card, Box } from '@material-ui/core';
import {
  QuestionResultBox,
  NoBookmark,
} from '../../../assets/css/StyledQuestionComponent';
import { BookmarkButton } from '../../../assets/css/MuiStyles';
import BookmarkFillIcon from '../../../assets/icons/BookmarkFilled.svg';
import Latex from '../../../utils/LatexViewer';
import PageLoader from '../../../utils/components/PageLoader';

export default function RightCard(props) {
  const { data, handleBookmarkClick, handleQuestionClick, t, loader } = props;
  return (
    <div>
      {data.length !== 0 ? (
        data.map(
          (
            { id, title, dateTime, testQuestionSetId, type, topicId },
            index
          ) => {
            return (
              <Card
                key={index}
                className={'question-card-question-style cursor'}
                onClick={() =>
                  handleQuestionClick(id, testQuestionSetId, type, topicId)
                }
              >
                <Box
                  padding={'18px 30px'}
                  display={'flex'}
                  gridGap={12}
                  color={'#052A4E'}
                  fontSize={14}
                >
                  <Box paddingTop={'3px'}>{index + 1}.</Box>
                  <Box whiteSpace={'break-spaces'} lineHeight={1.6} flex={1}>
                    <Latex math={title} />
                  </Box>

                  <QuestionResultBox>
                    <div style={{ left: '40px' }}>
                      <BookmarkButton
                        onClick={event => handleBookmarkClick(id, type, event)}
                      >
                        <img src={BookmarkFillIcon} alt="" />
                      </BookmarkButton>
                    </div>
                  </QuestionResultBox>
                </Box>
              </Card>
            );
          }
        )
      ) : loader ? (
        <NoBookmark>{t('You have no bookmarks')}</NoBookmark>
      ) : (
        <PageLoader />
      )}
    </div>
  );
}