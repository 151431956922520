/**
 * (c) CareerLabs. All rights reserved.
 **/

import React from 'react';
import { Route, Switch } from 'react-router';
import { routePaths } from '../../routes/RoutePath';
import End from '../dashboard/course/test/gre/End';
import Instruction from '../dashboard/course/test/gre/Instruction';
import Test from '../dashboard/course/test/gre/Test';

function GreLayout(props) {
  const GRE_ROUTES = [
    {
      path: routePaths.gre.instruction,
      component: Instruction,
      exact: true,
    },
    { path: routePaths.gre.test, component: Test, exact: true },
    { path: routePaths.gre.end, component: End, exact: true },
  ];
  return (
    <main>
      <Switch>
        {GRE_ROUTES.map(item => (
          <Route {...item} />
        ))}
      </Switch>
    </main>
  );
}

export default GreLayout;
