/**
 * (c) CareerLabs. All rights reserved.
 **/
import createBreakPoint from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { Menu, MenuItem, Button, IconButton, Tooltip } from '@material-ui/core';
const breakpoint = createBreakPoint({});

export const headerTheme = createTheme({
  palette: {
    primary: { main: '#1093ff' },
    secondary: { main: '#052A4E' },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#fff',
        boxShadow: '0px 4px 4px rgba(16, 147, 255, 0.25)',
      },
      colorPrimary: {
        backgroundColor: '#fff',
        boxShadow: '0px 4px 4px rgba(16, 147, 255, 0.25',
      },
    },
    MuiButton: {
      root: {
        color: '#838383 !important',
        minWidth: '94px !important',
        lineHeight: '3.75',
        marginLeft: '20px',
        letterSpacing: '0',
        textTransform: 'capitalize',
      },
    },
    MuiIconButton: {
      root: {
        width: '48px',
        height: '48px',
        background: props => props.background,
      },
    },
  },
});

export const dashboardTheme = createTheme({
  palette: {
    primary: { main: '#1093ff' },
  },
  overrides: {
    MuiContainer: {
      root: {
        minWidth: '100%',
        padding: '15px !important',
        margin: '0 !important',
      },
    },
    MuiTypography: {
      h5: {
        fontWeight: 'bold !important',
        fontSize: '14px !important',
      },
      body1: {
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#052A4E !important',
      },
    },
    MuiCard: {
      root: {
        minHeight: '310px',
        boxShadow: '0px 0px 7px rgb(183 222 255 / 50%) !important',
        borderRadius: '8px !important',
      },
    },
    MuiGrid: {
      root: {
        position: 'relative',
        width: props => props.width,
        marginRight: props => props.marginRight,
      },
    },
    MuiSelect: {
      select: {
        width: '90px !important',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#1093FF',
        background: '#FFFFFF',
        boxShadow: '0px 0px 7px rgb(183 222 255 / 50%)',
        borderRadius: '30px',
        padding: '5px 10px',
        paddingRight: '20px !important',
        margin: '3px 2px',
        '&:focus': {
          background: 'none !important',
          borderRadius: '30px',
        },
      },
      icon: {
        color: '#1093FF',
        marginRight: '10px',
      },
    },
    MuiListItem: {
      root: {
        margin: '6px !important',
        '&$selected': {
          background: '#F2F9FF',
        },
        '&:hover': {
          background: '#c7f7f857 !important',
        },
      },
    },
  },
});

export const questionBankTheme = createTheme({
  palette: {
    primary: { main: '#1093ff' },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        maxWidth: '100% !important',
        minHeight: '100vh',
        margin: '0px 0px !important',
        padding: '24px 40px !important',
        background: '#F7F8FC !important',

        '@media only screen and (max-width: 1366px)': {
          padding: '16px 20px !important',
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'nowrap !important',
        flexDirection: 'row !important',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#1093FF',
        },

        '&:hover': {
          background: 'none !important',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 20,
        height: '4px',
        backgroundColor: '#F3F3F3 !important',
      },
      barColorPrimary: {
        borderRadius: 20,
        backgroundColor: '#1093FF',
      },
    },
  },
});

export const profileSetUpTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: 60,
      },
    },
  },
});

export const dropdownTheme = createTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },
  },
});

export const buttonTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        backgroundColor: '#1093FF',
        marginTop: '5vh',
        textTransform: 'initial',
      },
      containedPrimary: {
        backgroundColor: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
        },
      },
      outlinedPrimary: {
        backgroundColor: '#fff',
        border: '1px solid #1093FF',
        color: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
          border: '1px solid #1093FF',
          color: '#fff',
        },
      },
    },
  },
});

export const uploadTheme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        backgroundColor: 'black',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 38,
      },
    },
  },
});

export const profileUpdateTheme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        color: '#686868',
        mixBlendMode: 'normal',
      },
    },
    MuiTextField: {
      root: {
        margin: '5px 0px',
      },
    },
    MuiIconButton: {
      root: {
        [breakpoint.only('xs')]: {
          padding: 10,
        },
      },
    },
  },
});

export const linkButton = createTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        borderColor: '#1093FF !important',
        marginTop: 20,
      },
      label: {
        fontWeight: 500,
        fontSize: 14,
        color: '#1093FF',
      },
    },
  },
});

export const primaryButtonTheme = createTheme({
  palette: {
    primary: {
      main: '#1093ff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        marginTop: '5vh',
        width: 230,
        textTransform: 'capitalize',
      },
      containedPrimary: {
        backgroundColor: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
        },
      },
    },
  },
});

export const activeLink = {
  borderBottom: '4px solid',
  borderRadius: '4px',
  borderColor: '#02A1E5',
  borderBottomLeftRadius: 4,
  paddingBottom: 10,
};

export const TextFieldStyle = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        color: '#052A4E',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#052A4E !important',
        '&.Mui-focused': {
          color: '#1093FF',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #1093FF',
        },
      },
    },
  },
});

export const rootTheme = createTheme({
  palette: {
    primary: {
      main: '#1093FF',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize !important',
      },
      label: {
        textTransform: 'initial',
      },
    },
  },
});

export const DropdownTheme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: ' rgba(0, 0, 0, 0.2)',
        '&.Mui-checked': {
          color: '#1093FF',
        },
      },
    },
    MuiAutocomplete: {
      option: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 13,
        lineHeight: 1,
        color: '#2C2C2C',
      },
    },
  },
});

export const MuiMenu = withStyles({
  paper: {
    background: '#ffffff',
    boxShadow: '0px 4px 11px 1px rgba(55, 143, 233, 0.25)',
    borderRadius: '12px',
    padding: '8px 12px',
    width: '208px',
  },
})(Menu);

export const MuiMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#F2F9FF',
    },
    '&$selected': {
      backgroundColor: '#F2F9FF',
    },
  },
})(MenuItem);

export const MuiButton = withStyles({
  root: {
    padding: 2,
    marginTop: '8px',
    background: '#1093ff',
    color: '#FFFFFF',
    '&:hover': {
      background: '#1093ff',
    },
  },
})(Button);

export const NoteSaveButton = withStyles({
  root: {
    width: '150px',
    height: '36px',
    background: '#1093ff',
    borderRadius: '30px',
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
    '&:hover': {
      background: '#1093ff',
    },
    '&:disabled': {
      background: '#C1CAD0',
      color: '#FFFFFF',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    width: '150px',
    height: '36px',
    background: '#FFFFFF',
    borderRadius: '30px',
    color: '#1093FF',
    fontWeight: 500,
    fontSize: '14px',
    border: '1px solid #1093FF',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
})(Button);

export const AddNoteButton = withStyles({
  root: {
    width: '40px',
    height: '64px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '50px',
    '&:hover': {
      background: '#FFFFFF',
    },
    '&:active': {
      background: '#F2F9FF',
    },
  },
})(IconButton);

export const BookmarkButton = withStyles({
  root: {
    width: '32px',
    height: '32px',
    background: 'rgb(16 147 255 / 10%)',
    '&:hover': {
      background: 'rgb(16 147 255 / 15%)',
    },
    '&:active': {
      background: 'rgb(16 147 255 / 10%)',
    },
  },
})(IconButton);

export const ToolTip = withStyles({
  arrow: {
    color: '#052A4E',
  },
  tooltip: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    padding: '6px 26px 8px 18px',
    backgroundColor: '#052A4E',
    borderRadius: '8px',
  },
})(Tooltip);
