import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { TextFieldStyle } from '../../assets/css/MuiStyles';

const DatePicker = ({ onChange, value, error, helperText, ...rest }) => {
  return (
    <ThemeProvider theme={TextFieldStyle}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          placeholder={'mm-dd-yyyy'}
          fullWidth
          inputProps={{
            readOnly: true,
            id: 'no-outline',
          }}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={error}
          helperText={helperText}
          minDate={new Date('1950-01-01')}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
