/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  LeftContainer,
  TopicTitleWhite,
  TopicTitleBlue,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import PageLoader from '../../../utils/components/PageLoader';

export default function LeftCard(props) {
  const { leftTabData, activeLeftTab, handleActiveLeftTab, t, loader } = props;
  return (
    <LeftContainer>
      {leftTabData.length !== 0 ? (
        leftTabData.map(item =>
          item.id === activeLeftTab ? (
            <TopicTitleBlue onClick={() => handleActiveLeftTab(item.id)}>
              {`${item.topic}(${item.count})`}
            </TopicTitleBlue>
          ) : (
            <TopicTitleWhite onClick={() => handleActiveLeftTab(item.id)}>
              {`${item.topic}(${item.count})`}
            </TopicTitleWhite>
          )
        )
      ) : loader ? (
        <TopicTitleWhite pointer={false}>{t('No Bookmarks')}</TopicTitleWhite>
      ) : (
        <PageLoader />
      )}
    </LeftContainer>
  );
}