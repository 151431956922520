/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Card, LinearProgress, createTheme } from '@material-ui/core';
import styled from 'styled-components';

const TopicContainer = styled.div`
  height: ${props => props.height || '496px'};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Container = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
`;

const TopicHeader = styled.div`
  padding: 10px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #052a4e;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  display: flex;
`;

const TopicContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  padding: 5px 10px;
  height: ${props => props.height || '87%'};
  overflow-y: ${props => props.overflow || 'auto'};
  overflow-x: hidden;
  overflow: ${props => props.overflow || 'unset'};
`;

const TopicItem = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  cursor: pointer;
`;

const TopicListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 14px;
  max-height: 340px;
  overflow-y: auto;
`;

const TopicTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  grid-gap: 10;
`;

const TaskSubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  grid-gap: 15px;
  color: #838383;
`;

const TopicAvatar = styled.img`
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  border-radius: 50%;
`;

const ProgressBar = styled(LinearProgress)`
  border-radius: 30px;
`;

const StrengthCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(183, 222, 255, 0.4);
  border-radius: 8px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
`;

const ProgressBarTheme = () =>
  createTheme({
    overrides: {
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: '#1093FF',
        },
      },
    },
  });

const ViewMore = () => styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1093ff;
`;

const Header = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ color }) => color || '#052a4e'};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

const Body = styled.div`
  padding: 8px 16px;
  overflow: hidden;
  overflow-y: auto;
  height: 488px;
`;

export {
  TopicContainer,
  TopicContent,
  TopicItem,
  TopicHeader,
  TopicAvatar,
  TaskSubTitle,
  ProgressBar,
  TopicTitleContainer,
  StrengthCard,
  ViewMore,
  ProgressBarTheme,
  Container,
  Header,
  Body,
  TopicListContainer,
};
