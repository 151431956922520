/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import _ from 'lodash';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { greTheme } from '../../../../../assets/css/GreStyles';
import {
  ContinueButton,
  PauseModelSubTitle,
  PauseModelTitle,
  QuitButton,
} from '../../../../../assets/css/test/TestComponent';
import BookmarkIcon from '../../../../../assets/icons/Bookmarks.svg';
import PauseModelIcon from '../../../../../assets/icons/pause.svg';
import { EVENTS } from '../../../../../clevertap/Events';
import { QS_PROPS } from '../../../../../constant/Variables';
import { retakeExam } from '../../../../../redux/action/Dashboard';
import {
  AddBookmarks,
  removeDemoBookmark,
} from '../../../../../redux/action/Practice';
import {
  getTestSection,
  pauseExam,
  startTest,
  submitAnswer,
} from '../../../../../redux/action/Test';
import { routePaths } from '../../../../../routes/RoutePath';
import DialogComponent from '../../../../../utils/components/DialogComponent';
import PageLoader from '../../../../../utils/components/PageLoader';
import Model from '../../../../../utils/Model';
import Bundle from './components/Bundle';
import Calculator from './components/Calculator';
import Confirmation from './components/Confirmation';
import Passage from './components/Passage';
import SingleMulti from './components/SingleMulti';
import Layout from './Layout';
import clevertap from 'clevertap-web-sdk';

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      selectedChoice: [],
      time: -1,
      answer: '',
      bundleSelect: [],
      modelOpen: false,
      isLoading: false,
      demoBookmark: false,
      bookMarked: false,
      question_Id: null,
      dialogOpen: false,
      showConfirmationDialog: false,
      showCalculator: false,
      answer1: '',
    };
    this.time = -1;
  }

  componentDidMount() {
    const { testQuestionId, status, og_course, filter_id } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const combination = sessionStorage.getItem('combination')
      ? JSON.parse(sessionStorage.getItem('combination'))
      : {};
    const official_guide_gmat_question = sessionStorage.getItem(
      'official_guide_gmat_question'
    )
      ? JSON.parse(sessionStorage.getItem('official_guide_gmat_question'))
      : [];
    if (testQuestionId) {
      this.props.retakeExam(
        testQuestionId,
        status,
        og_course,
        official_guide_gmat_question,
        combination,
        filter_id,
        response => {
          if (response.success) {
            if (response.message === 'You already Complete the Test') {
              this.props.history.push(routePaths.report + '?page=insights');
            }
            let userProduct = JSON.parse(localStorage.getItem('userProduct'));
            userProduct.testExecutionId = response.data.testExecutionId;
            localStorage.setItem('userProduct', JSON.stringify(userProduct));
            this.setState({
              question: response.data,
              question_Id: response.data.id,
              bookMarked: response.data.isBookmarked,
            });
            if (og_course && og_course === 'GMAT') {
              if (status)
                this.props.history.replace(
                  `${routePaths.exam}?testQuestionId=${testQuestionId}&status=RETAKE&og_course=${og_course}&filter_id=${response.data.testFilterId}`
                );
              else
                this.props.history.replace(
                  `${routePaths.exam}?testQuestionId=${testQuestionId}&og_course=${og_course}&filter_id=${response.data.testFilterId}`
                );
            }
          }
        }
      );
    } else {
      this.props.startTest(response => {
        if (response.success) {
          if (response.message === 'You already Complete the Test') {
            this.props.history.push(routePaths.report + '?page=insights');
          }

          let userProduct = JSON.parse(localStorage.getItem('userProduct'));
          userProduct.testExecutionId = response.data.testExecutionId;
          localStorage.setItem('userProduct', JSON.stringify(userProduct));
          this.setState({
            question: response.data,
            question_Id: response.data.id,
            bookMarked: response.data.isBookmarked,
          });
        }
      });
    }

    if (_.isEmpty(this.props.sectionResponse)) {
      this.props.getTestSection();
      this.setState({ showSection: true, showBackButton: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { testQuestionSetId, section } = QueryString.parse(
        this.props.location.search,
        QS_PROPS
      );
      if (_.isEmpty(this.props.sectionResponse)) {
        this.props.getTestSection();
      }
    }
  }

  onSelect = choice => {
    this.setState({
      selectedChoice: choice.id,
    });
  };

  onMultiSelect = event => {
    const { selectedChoice } = this.state;
    const { noOfAnswer } = this.state.question;
    const { id, checked } = event.target;
    let arr = selectedChoice;
    if (checked && arr.length < noOfAnswer) arr.push(id);
    else arr = arr.filter(item => item !== id);
    this.setState({
      selectedChoice: arr,
    });
  };

  onBundleChange = (item, choice) => {
    const { bundleSelect } = this.state;
    var arr = [];
    if (bundleSelect.some(exist => exist.bundleNo === item.bundleNo)) {
      if (
        bundleSelect.some(
          exist => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = bundleSelect.filter(
          exist => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = bundleSelect
          .filter(exist => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = bundleSelect.concat({ ...item, ...choice });
    }
    this.setState({ bundleSelect: arr });
  };

  renderQuestion = () => {
    const {
      type,
      question,
      choices,
      description,
      imgURL,
      isHaveDescription,
      isHaveImage,
      bottomText,
      topText,
      isCalculator,
      currentQuestionNo,
      optionalType,
      unit,
    } = this.state.question;
    if (type === 'SINGLE_SELECT') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          choices={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onSelect}
          imgUrl={imgURL}
          isCalculator={isCalculator}
          question={question}
          bottomText={bottomText}
          topText={topText}
          currentQuestionNo={currentQuestionNo}
        />
      ) : (
        <SingleMulti
          question={question}
          options={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onSelect}
          description={description}
          imgUrl={imgURL}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
        />
      );
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return (
        <Passage
          question={question}
          description={description}
          subjective={true}
          onChange={value => this.setState({ answer: value })}
          answer={this.state.answer}
          imgUrl={imgURL}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
          currentQuestionNo={currentQuestionNo}
          optionalType={optionalType}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'FRACTION') {
      return (
        <Passage
          question={question}
          description={description}
          fraction={true}
          onChange={value => this.setState({ answer: value })}
          onChangeCapture={value => this.setState({ answer1: value })}
          fraction1={this.state.answer}
          fraction2={this.state.answer1}
          imgUrl={imgURL}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
          currentQuestionNo={currentQuestionNo}
          unit={unit}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'BUNDLE') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          question={question}
          description={description}
          bundle={true}
          onChange={this.onBundleChange}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map(item => item.bundleNo)
          )}
          selectedChoice={this.state.bundleSelect}
          imgUrl={imgURL}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
          currentQuestionNo={currentQuestionNo}
          isHaveDescription={isHaveDescription}
        />
      ) : (
        <Bundle
          onChange={this.onBundleChange}
          choices={choices}
          bundleLength={Math.max.apply(
            Math,
            choices.map(item => item.bundleNo)
          )}
          selectedChoice={this.state.bundleSelect}
          question={question}
          bottomText={bottomText}
          topText={topText}
          isCalculator={isCalculator}
          isHaveDescription={isHaveDescription}
        />
      );
    } else if (type === 'MULTI_CHOICE') {
      return isHaveDescription || isHaveImage ? (
        <Passage
          description={description}
          question={question}
          choices={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onMultiSelect}
          imgUrl={imgURL}
          isMulti={true}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
          currentQuestionNo={currentQuestionNo}
        />
      ) : (
        <SingleMulti
          question={question}
          options={choices}
          selectedChoice={this.state.selectedChoice}
          onSelect={this.onMultiSelect}
          description={description}
          imgUrl={imgURL}
          isMulti={true}
          isCalculator={isCalculator}
          bottomText={bottomText}
          topText={topText}
        />
      );
    }
  };

  renderModel = t => {
    return (
      <Model open={this.state.modelOpen}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={5}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box>
            <img src={PauseModelIcon} alt="" />
          </Box>
          <PauseModelTitle>{t('Test Paused')}</PauseModelTitle>
          <PauseModelSubTitle>
            {t(`Press "Continue" to continue the test`)}
          </PauseModelSubTitle>
          <Box display={'flex'} gridGap={25}>
            <QuitButton onClick={this.pauseExam}>{t('Quit')}</QuitButton>
            <ContinueButton onClick={() => this.setState({ modelOpen: false })}>
              {t('Continue')}
            </ContinueButton>
          </Box>
        </Box>
      </Model>
    );
  };

  pauseExam = () => {
    const { testQuestionId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.props.pauseExam(
      this.state.question.id,
      this.state.question.testExecutionId,
      this.time,
      response => {
        if (response.success) {
          if (this.state.question.testType === 'CALIBRATION') {
            this.props.history.push(routePaths.allCourses);
          } else if (testQuestionId) {
            this.props.history.push(
              routePaths.dashboard.questionBank +
                '?topicId=' +
                this.state.question.topicId
            );
          }
        }
      }
    );
  };

  next = () => {
    this.setState({ isLoading: true });

    const { currentTestSection, totalNoOfTestSection, testTitle, conceptName } =
      this.state.question;

    const { testQuestionSetId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let obj = {
      testExecutionId: this.state.question.testExecutionId,
      questionId: this.state.question.id,
      choices:
        this.state.answer === ''
          ? this.state.question.type === 'BUNDLE'
            ? this.state.bundleSelect.map(item => item.id)
            : this.state.question.type === 'MULTI_CHOICE'
            ? this.state.selectedChoice
            : [this.state.selectedChoice]
          : [],
      answer: this.state.answer,
      answer1: this.state.answer1,
      time: this.time,
    };

    this.props.submitAnswer(obj, null, null, false, response => {
      if (response.success) {
        // this.setState({isLoading:false});
        if (response.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': testTitle,
            'Topic name': response.data.topicName,
            'Concept name': conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
        }
        // if(response.message==="Question")
        // {
        //   this.setState({isLoading:false});
        // }
        if (
          response.message === 'Next TestSection' ||
          response.message === 'You Complete the Test'
        ) {
          this.props.history.push(
            `${
              routePaths.gre.end
            }?testQuestionSetId=${testQuestionSetId.trim()}&currentSection=${currentTestSection}&totalSection=${totalNoOfTestSection}`
          );
        }
        setTimeout(() => this.setState({ isLoading: false }), 1000);
        this.setState({
          question: response.data,
          question_Id: response.data.id,
          bookMarked: response.data.isBookmarked,
          selectedChoice: [],
          answer: '',
          bundleSelect: [],
          showConfirmationDialog: false,
          answer1: '',
        });
      }
    });
  };

  timeOver = () => {
    const { testTitle, conceptName } = this.state.question;

    const { testQuestionSetId, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    let obj = {
      testExecutionId: this.state.question.testExecutionId,
      questionId: this.state.question.id,
      choices: [],
      answer: this.state.answer,
      time: -1,
    };
    this.props.submitAnswer(obj, null, null, false, response => {
      if (response.success) {
        if (response.message === 'You Complete the Test') {
          clevertap.event.push(EVENTS.LMSUserCompletesATest, {
            'Test name': testTitle,
            'Topic name': response.data.topicName,
            'Concept name': conceptName,
            'Attempted questions': response.data.noOfQuestionsAnswered,
            'Count of times attempted the test': response.data.count || 1,
          });
          if (response.data.testType === 'TOPIC') {
            this.props.history.push(
              routePaths.dashboard.resultProgress +
                '?topic=' +
                this.state.question.testTitle +
                '&testExecutionId=' +
                this.state.question.testExecutionId
            );
          } else {
            this.props.history.push(`${routePaths.progress}`);
          }
        } else if (response.message === 'Next TestSection') {
          this.props.history.push(
            `${
              routePaths.gre.instruction
            }?testQuestionSetId=${testQuestionSetId.trim()}&section=true`
          );
        } else {
          this.setState({
            question: response.data,
            question_Id: response.data.id,
            bookMarked: response.data.isBookmarked,
            stop: false,
            selectedChoice: [],
            answer: '',
            bundleSelect: [],
            resetTime: true,
            answer1: '',
          });
        }
      }
    });
  };

  disabled = () => {
    const { noOfAnswer } = this.state.question;

    const { type, choices } = this.state.question;
    if (this.state.isLoading) {
      return true;
    } else if (
      type === 'SINGLE_SELECT' ||
      type === 'SINGLE_SELECT_PASSAGE' ||
      type === 'SINGLE_SELECT_IMAGE'
    ) {
      return this.state.selectedChoice.length === 0;
    } else if (type === 'MULTI_CHOICE') {
      return this.state.selectedChoice.length !== noOfAnswer;
    } else if (type === 'SUBJECTIVE' || type === 'DESCRIPTIVE') {
      return this.state.answer.trim().length === 0;
    } else if (type === 'FRACTION') {
      return (
        this.state.answer.trim().length === 0 ||
        this.state.answer1.trim().length === 0
      );
    } else if (type === 'BUNDLE') {
      return (
        this.state.bundleSelect.length !==
        Math.max.apply(
          Math,
          choices.map(item => item.bundleNo)
        )
      );
    }
    return true;
  };

  handleBookmarkClick = () => {
    const { bookMarked } = this.state;
    if (bookMarked) this.setState({ dialogOpen: true });
    else this.handleButton2Click();
  };

  handleButton1Click = () => {
    this.setState({ dialogOpen: false });
  };

  handleButton2Click = () => {
    const { question_Id, demoBookmark } = this.state;
    var bookmarkData = { id: question_Id, type: 'question' };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        this.setState({
          bookMarked: response.data.isBookmarked,
          dialogOpen: false,
        });
      }
    });
  };

  getRemainingTime = time => {
    this.time = time;
    if (time === 0) {
      this.setState({ stop: true });
      this.timeOver();
    }
  };

  render() {
    if (this.state.question !== null) {
      const { t } = this.props;
      const { currentQuestionNo, totalNoOfQuestion, status, conceptName } =
        this.state.question;
      const { handleButton1Click, handleButton2Click, handleBookmarkClick } =
        this;

      const layoutProps = {
        // handlePauseClick: this.pauseExam,
        showPrimaryButton: true,
        showBookmarkButton: true,
        showPauseButton: true,
        showSection: true,
        primaryButtonText: t('Continue'),
        isBookmark: this.state.bookMarked,
        loading: this.state.isLoading,
        showCalculatorButton: this.state.question.isCalculator,
        question: this.state.question,
        stop: this.state.stop,
        sectionData: this.props.sectionResponse,
        disabled: this.disabled(),
        handleNextClick: !this.disabled()
          ? () => this.setState({ showConfirmationDialog: true })
          : () => {},
        getRemainingTime: this.getRemainingTime,
        handlePauseClick: () => this.setState({ modelOpen: true }),
        handleBookmarkClick: handleBookmarkClick,
        handleCalculatorClick: () => this.setState({ showCalculator: true }),
      };

      const confirmationDialogProps = {
        open: this.state.showConfirmationDialog,
        onYes: this.next,
        onNo: () => {
          this.setState({ showConfirmationDialog: false });
        },
        loading: this.state.isLoading,
      };
      return (
        <React.Fragment>
          <ThemeProvider theme={greTheme}>
            <Layout {...layoutProps}>{this.renderQuestion()}</Layout>

            {/* Dialog Components */}
          </ThemeProvider>
          <Confirmation {...confirmationDialogProps} />
          <DialogComponent
            open={this.state.dialogOpen}
            dialogContent={{
              icon: <img src={BookmarkIcon} />,
              title: t(`Are you sure you want remove bookmark?`),
              button1: t('Cancel'),
              button2: t('Yes'),
            }}
            handleButton1Click={handleButton1Click}
            handleButton2Click={handleButton2Click}
          />
          {this.renderModel(t)}
          <Calculator
            open={this.state.showCalculator}
            close={() => this.setState({ showCalculator: false })}
          />
        </React.Fragment>
      );
    } else {
      return <PageLoader />;
    }
  }
}

const mapStateToProps = state => {
  return {
    sectionResponse: state.testReducer.testSection,
  };
};

export default connect(mapStateToProps, {
  startTest,
  submitAnswer,
  pauseExam,
  retakeExam,
  AddBookmarks,
  removeDemoBookmark,
  getTestSection,
})(withTranslation()(Test));