import { Box as MuiBox } from '@material-ui/core';
import React from 'react';
import { CustomTypo } from '../../../assets/css/StyledComponent';
import { Card } from '../../../assets/css/StyledQuestionComponent';
import LatexViewer from '../../../utils/LatexViewer';
import { TableItemUnderline } from '../../../assets/css/Styles';
import { routePaths } from '../../../routes/RoutePath';
import { useHistory } from 'react-router';

export default function QuestionCard({
  gmatOG_Question,
  onClick,
  testCount,
  testFilterId,
}) {
  const history = useHistory();
  const allQuestion =
    gmatOG_Question.length !== 0
      ? gmatOG_Question.map(({ question }) => question.id)
      : [];
  console.log(testCount, 'testing count', testFilterId);
  return (
    <MuiBox mt={2} style={style.overflow}>
      {gmatOG_Question &&
        gmatOG_Question.length !== 0 &&
        gmatOG_Question.map(
          ({ question, isAnswered, attemptStatus }, index) => {
            return (
              <Card
                isAnswered={isAnswered}
                // onClick={() =>
                //   onClick(allQuestion, question.testQuestionsSetId, testCount)
                // }
              >
                <MuiBox style={style.cardWrap}>
                  <MuiBox>{question.orderNo}</MuiBox>
                  <MuiBox
                    whiteSpace={'break-spaces'}
                    lineHeight={1.6}
                    flex={1}
                    style={{ marginTop: '10px' }}
                  >
                    <LatexViewer math={question.question} />
                  </MuiBox>
                  {isAnswered && (
                    <>
                      <TableItemUnderline
                        style={{
                          margin: '0px 1.5em',
                          fontWeight: '600',
                          color: '#1093ff',
                          fontSize: '14px',
                          width: 'max-content',
                        }}
                        onClick={() => {
                          history.push(
                            `${routePaths.answer}/${question.id}?questionId=${question.id}&ViewExplain=official-guide`
                          );
                        }}
                      >
                        View Explanation
                      </TableItemUnderline>
                      <MuiBox
                        minWidth={'120px'}
                        textAlign={'center'}
                        margin={'auto'}
                      >
                        <CustomTypo
                          variant={'subtitle2'}
                          textColor={attemptStatus ? '#1AF13D' : '#F20C0C'}
                        >
                          {attemptStatus ? 'Correct' : 'Incorrect'}
                        </CustomTypo>
                      </MuiBox>
                    </>
                  )}
                  {index === 0 && (
                    <MuiBox
                      style={style.startButton}
                      onClick={() =>
                        onClick(
                          allQuestion,
                          question.testQuestionsSetId,
                          testCount,
                          testFilterId
                        )
                      }
                    >
                      Start
                    </MuiBox>
                  )}
                </MuiBox>
              </Card>
            );
          }
        )}
    </MuiBox>
  );
}

const style = {
  cardWrap: {
    padding: '16px 20px',
    display: 'flex',
    gridGap: '10px',
    color: '#052A4E',
    fontSize: '14px',
    alignItems: 'center',
    // cursor: 'pointer',
  },
  startButton: {
    color: '#1093ff',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '17px',
  },
  overflow: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 290px)',
    padding: '7px 20px 0px 7px',
  },
};
