/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Redirect } from 'react-router-dom';
import EmailSent from '../component/authentication/forgotPassword/EmailSent';
import EnterEmail from '../component/authentication/forgotPassword/EnterEmail';
import PasswordChanged from '../component/authentication/forgotPassword/PasswordChanged';
import ResetPassword from '../component/authentication/forgotPassword/ResetPassword';
import OtpVerify from '../component/authentication/login/OtpVerify';
import PhoneNumberLogin from '../component/authentication/login/PhoneNumberLogin';
import DailyCalendar from '../component/dashboard/calendar/DailyCalendar';
import MonthlyCalendar from '../component/dashboard/calendar/MonthlyCalendar';
import GmatLayout from '../component/dashboard/course/test/gmat/Index';
import SectionIntro from '../component/dashboard/course/test/SectionIntro';
import DiagnosticEngine from '../component/dashboard/diagnosticEngine/Index';
import ChangePassword from '../component/dashboard/myAccount/ChangePassword';
import MyAccount from '../component/dashboard/myAccount/Index';
import PasswordSuccess from '../component/dashboard/myAccount/PasswordSuccess';
import Notes from '../component/dashboard/notes/Index';
import Mail from '../component/dashboard/mail/mail';
import OfficialGuideLanding from '../component/dashboard/officialGuide/Landing';
import OfficialGuideTask from '../component/dashboard/officialGuide/OfficialGideTask/Index';
import ProgressTracker from '../component/dashboard/progressTracker/Index';
import CompletedProgress from '../component/dashboard/subject/CompletedProgress';
import Landing from '../component/dashboard/subject/Landing';
import Question from '../component/dashboard/subject/questionBank/Question';
import QuestionBank from '../component/dashboard/subject/questionBank/QuestionBank';
import Report from '../component/dashboard/subject/report/Report';
import TopicTestInstruction from '../component/dashboard/subject/topicTestInstruction/Instruction';
import QuestionBankTestInstruction from '../component/practice/questionBankTestInstruction/Instruction';
import Support from '../component/dashboard/support/Index';
import TestTopic from '../component/dashboard/testTopic/Index';
import SolveByQuestion from '../component/dashboard/totalquestionbank/Index';
import Dashboard from '../component/dashboard/user/Dashboard';
import StrengthsWeakness from '../component/dashboard/user/strengthsWeakness/Index';
import AuthenticationLayout from '../component/layouts/AuthenticationLayout';
import Calibration from '../component/layouts/CourseLayout';
import DashboardLayout from '../component/layouts/DashboardLayout';
import GreLayout from '../component/layouts/GreLayout';
import Profile from '../component/layouts/ProfileLayout';
import Bookmarks from '../component/practice/bookmarks/Index';
import MistakeLogs from '../component/practice/mistakeLogs/Index';
import SolveByPassage from '../component/practice/solveByPassage/Landing';
import Webinar from '../component/practice/webinar/Index';
import TopicTest from '../component/dashboard/subject/TopicTest/Index';
import GuideLanding from '../component/dashboard/guide/Index';
import GreReport from '../component/dashboard/guide/report/greReport/Index';
import GmatReport from '../component/dashboard/guide/report/gmatReport/Index';
import QuestionBankReport from '../component/practice/report/Report';
import GmatOverallSummary from '../component/dashboard/guide/report/gmatReport/OverallSummary';
import Topic from '../component/practice/topicTest/Index';
import DashboardTop from '../component/dashboard/dtkDashboard/dtkTop/index';

const layout = {
  login: '/auth',
  profile: '/profile',
  dashboard: '/dashboard',
  calibration: '/calibration',
  gmat: '/gmat',
  gre: '/gre',
};

const routePaths = {
  // Login Screens
  login: `${layout.login}`,
  emailEnter: `${layout.login}/email`,
  otpVerify: `${layout.login}/otp`,
  sentEmail: `${layout.login}/email/sent`,
  verifyEmail: `${layout.login}/verify`,
  changePassword: `${layout.login}/email/change`,
  myAccount: `${layout.login}/my-account`,
  // Profile Screens
  profileSetup: `${layout.profile}/setup`,
  profileUpdate: `${layout.profile}/update`,
  profile: `${layout.profile}`,
  personalDetail: `${layout.profile}/update/personaldetails`,
  employeeDetail: `${layout.profile}/update/employeedetails`,
  educationDetail: `${layout.profile}/update/educationdetail`,
  profileDetail: `${layout.profile}/view`,

  //calibration screen
  allCourses: `${layout.calibration}/all-courses`,
  test: `${layout.calibration}/test`,
  score: `${layout.calibration}/score`,
  courses: `${layout.calibration}/courses`,
  submit: `${layout.calibration}/submit`,
  start: `${layout.calibration}/start`,
  instruction: `${layout.calibration}/instruction`,
  progress: `${layout.calibration}/progress`,
  examScheduled: `${layout.calibration}/exam-scheduled`,
  report: `${layout.calibration}/report`,
  review: `${layout.calibration}/review`,
  exam: `${layout.calibration}/exam`,
  answer: `${layout.calibration}/answer`,
  testInstructionSection: `${layout.calibration}/section`,
  noProductScreen: `${layout.calibration}/no-product`,
  contactUs: `${layout.calibration}/contact-us`,
  introduction: `${layout.calibration}/introduction-to-lms`,

  // dashboard
  dashboard: {
    dtkTop: `${layout.dashboard}/dashboard-top`,
    home: `${layout.dashboard}`,
    subject: `${layout.dashboard}/subject`,
    studyPlan: `${layout.dashboard}/calender`,
    taskDetail: `${layout.dashboard}/subject/task-detail`,
    wall: `${layout.dashboard}/wall`,
    support: `${layout.dashboard}/support`,
    questionBank: `${layout.dashboard}/subject/task-detail/question-bank`,
    question: `${layout.dashboard}/subject/task-detail/question-bank/question`,
    instruction: `${layout.dashboard}/subject/task-detail/question-bank/question/instruction`,
    officialGuideTestInstruction: `${layout.dashboard}/official-guide/instruction`,
    questionBankTestInstruction: `${layout.dashboard}/question-bank/instruction`,
    myAccount: `${layout.dashboard}/my-account`,
    changePassword: `${layout.dashboard}/change-password`,
    passwordSuccess: `${layout.dashboard}/password-success`,
    practice: `${layout.dashboard}/practice`,
    strengthsWeakness: `${layout.dashboard}/strengths-weakness`,
    notes: `${layout.dashboard}/notes`,
    mail: `${layout.dashboard}/mail`,
    resultProgress: `${layout.dashboard}/progress`,
    report: `${layout.dashboard}/report`,
    progressTracker: `${layout.dashboard}/progress-tracker`,
    diagnosticEngine: `${layout.dashboard}/diagnostic-engine`,
    dailyCalendar: `${layout.dashboard}/daily-calendar`,
    topicTest: `${layout.dashboard}/subject/task-detail/topic-test`,
    // Question Bank
    questionBankReport: `${layout.dashboard}/question-bank-report/overall`,

    // Official Guide
    guide: `${layout.dashboard}/official-guide`,
    greOgReport: `${layout.dashboard}/gre-og-report`,
    gmatOgReport: `${layout.dashboard}/gmat-og-report/overall`,

    gmatOverallSummary: `${layout.dashboard}/gmat-og-report/overall-summary`,

    // Practice Paths
    topic: `${layout.dashboard}/practice/topic-test`,
    mistakeLogs: `${layout.dashboard}/practice/mistake-logs`,
    bookmarks: `${layout.dashboard}/practice/bookmarks`,
    webinar: `${layout.dashboard}/practice/webinar-live`,
    officialGuide: `${layout.dashboard}/practice/official-guide`,
    solveByPassage: `${layout.dashboard}/practice/solve-by-passage`,
    solveByQuestion: `${layout.dashboard}/practice/solve-by-question`,
    officialGuideTask: `${layout.dashboard}/practice/official-guide/task`,
    // GMAT
  },
  gmat: {
    instruction: `${layout.gmat}/instruction`,
    section: `${layout.gmat}/section`,
    endSection: `${layout.gmat}/end-section`,
    test: `${layout.gmat}/test`,
  },

  // GRE Screens
  gre: {
    instruction: `${layout.gre}/instruction`,
    section: `${layout.gre}/section`,
    end: `${layout.gre}/end`,
    test: `${layout.gre}/test`,
  },
};

const { login, otpVerify, emailEnter, sentEmail, verifyEmail, changePassword } =
  routePaths;

const dashboardRoutes = [
  {
    path: `${routePaths.dashboard.dtkTop}`,
    component: DashboardTop,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.home}`,
    component: Dashboard,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.subject}`,
    component: Landing,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.studyPlan}`,
    component: MonthlyCalendar,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.taskDetail}`,
    component: TestTopic,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.questionBank}`,
    component: QuestionBank,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.question}`,
    component: Question,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.instruction}`,
    component: TopicTestInstruction,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.officialGuideTestInstruction}`,
    component: TopicTestInstruction,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.officialGuideTestInstruction}`,
    component: TopicTestInstruction,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.questionBankTestInstruction}`,
    component: QuestionBankTestInstruction,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.myAccount}`,
    component: MyAccount,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.changePassword}`,
    component: ChangePassword,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.practice}`,
    component: SolveByQuestion,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.solveByQuestion}`,
    component: SolveByQuestion,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.strengthsWeakness}`,
    component: StrengthsWeakness,
    exact: true,
  },
  { path: `${routePaths.dashboard.notes}`, component: Notes, exact: true },
  { path: `${routePaths.dashboard.mail}`, component: Mail, exact: true },
  {
    path: `${routePaths.dashboard.resultProgress}`,
    component: CompletedProgress,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.report}`,
    component: Report,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.passwordSuccess}`,
    component: PasswordSuccess,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.support}`,
    component: Support,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.section}`,
    component: SectionIntro,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.progressTracker}`,
    component: ProgressTracker,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.diagnosticEngine}`,
    component: DiagnosticEngine,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.guide}`,
    component: GuideLanding,
    exact: true,
  },

  // Practice Route
  {
    path: `${routePaths.dashboard.bookmarks}`,
    component: Bookmarks,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.mistakeLogs}`,
    component: MistakeLogs,
    exact: true,
  },

  {
    path: `${routePaths.dashboard.dailyCalendar}`,
    component: DailyCalendar,
    exact: true,
  },

  {
    path: `${routePaths.dashboard.webinar}`,
    component: Webinar,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.officialGuide}`,
    component: OfficialGuideLanding,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.officialGuideTask}`,
    component: OfficialGuideTask,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.solveByPassage}`,
    component: SolveByPassage,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.topicTest}`,
    component: TopicTest,
    exact: true,
  },

  //Question Bank Report
  {
    path: `${routePaths.dashboard.questionBankReport}`,
    component: QuestionBankReport,
    // component: Report,
    exact: true,
  },

  //Official Guide Report
  {
    path: `${routePaths.dashboard.greOgReport}`,
    component: GreReport,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.gmatOgReport}`,
    // component: Report,
    component: GmatReport,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.gmatOverallSummary}`,
    component: GmatOverallSummary,
    exact: true,
  },
  {
    path: `${routePaths.dashboard.topic}`,
    component: Topic,
    exact: true,
  },
];

const AuthenticationRoute = [
  {
    path: '/',
    component: PhoneNumberLogin,
    exact: true,
  },
  {
    path: login,
    component: PhoneNumberLogin,
    exact: true,
  },
  {
    path: otpVerify,
    component: OtpVerify,
    exact: true,
  },
  {
    path: sentEmail,
    component: EmailSent,
    exact: true,
  },
  {
    path: changePassword,
    component: ResetPassword,
    exact: true,
  },
  {
    path: verifyEmail,
    component: PasswordChanged,
    exact: true,
  },
  {
    path: emailEnter,
    component: EnterEmail,
    exact: true,
  },
];

const routes = [
  {
    path: '/',
    component: () => <Redirect to={routePaths.login} />,
    exact: true,
  },
  {
    path: layout.profile,
    component: Profile,
  },
  {
    path: layout.dashboard,
    component: DashboardLayout,
  },
  {
    path: layout.calibration,
    component: Calibration,
  },
  {
    path: layout.gmat,
    component: GmatLayout,
  },
  {
    path: layout.gre,
    component: GreLayout,
  },
];

const authenticationRoute = {
  path: layout.login,
  component: AuthenticationLayout,
};

export {
  routePaths,
  routes,
  authenticationRoute,
  AuthenticationRoute,
  dashboardRoutes,
  layout,
};
