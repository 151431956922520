/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import React from 'react';
import { FlexFiller } from '../../../assets/css/StyledComponent';
import {
  Button,
  TaskContainer,
  TaskHead,
  TopicBody,
  TopicBodyDescription,
  TopicContainer,
  TopicHead,
  TopicHeadText,
} from '../../../assets/css/subject/LandingComponents';
import TextVideo from '../../../assets/icons/DimTextVideo.svg';
import TextIcon from '../../../assets/icons/Text.png';
import VideoIcon from '../../../assets/icons/Video.svg';
import { routePaths } from '../../../routes/RoutePath';

export default function Topic(props) {
  // Video or Text Lesson Icon
  const topicTypeIcon = type => {
    if (type === 'VIDEO') return VideoIcon;
    if (type === 'TEXT') return TextIcon;
    if (type === 'TEXT_VIDEO') return TextVideo;
  };

  const handleTaskClick = (event, topicId, taskId) => {
    event.stopPropagation();
    props.history.push(
      `${routePaths.dashboard.officialGuideTask}?topicId=${topicId}&taskId=${taskId}`
    );
  };

  const handleTopicClick = event => {
    const { id } = event.currentTarget;
    event.stopPropagation();
    props.history.push(
      `${routePaths.dashboard.officialGuideTask}?topicId=${id}`
    );
  };

  const { activeTopic, index, onTopic, topic, t } = props;

  return (
    <TopicContainer
      square
      expanded={activeTopic === index}
      onClick={onTopic(index)}
    >
      <TopicHead style={{ borderTop: index === 0 ? 'none' : null }}>
        {activeTopic === index ? (
          <RemoveOutlinedIcon className={'open-close-icon'} />
        ) : (
          <AddOutlinedIcon className={'open-close-icon'} />
        )}

        <TopicHeadText>{topic.title}</TopicHeadText>

        <FlexFiller />
      </TopicHead>
      <TopicBody>
        <Grid container direction="column">
          <TopicBodyDescription>{topic.description}</TopicBodyDescription>
          {topic.tasks.map(task => {
            return (
              <TaskContainer aria-label="Expand">
                <img
                  id="task-icon"
                  src={topicTypeIcon(task.type)}
                  alt="Type icon"
                />
                <TaskHead
                  onFocus={event => event.stopPropagation()}
                  aria-label="task"
                  onClick={event => handleTaskClick(event, topic.id, task.id)}
                >
                  {task.title}
                </TaskHead>
                <FlexFiller />
              </TaskContainer>
            );
          })}
          <Button
            onFocus={event => event.stopPropagation()}
            aria-label="button"
            id={topic.id}
            onClick={handleTopicClick}
          >
            {t('Start Learning')}
          </Button>
        </Grid>
      </TopicBody>
    </TopicContainer>
  );
}
