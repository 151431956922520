/**
 * (c) CareerLabs. All rights reserved.
 **/
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const AntSwitch = withStyles(theme => ({
  root: {
    width: '28px !important',
    height: '16px !important',
    padding: 0,
    display: 'flex',

    "& input[type='checkbox']": {
      margin: '0 !important',
    },
  },
  switchBase: {
    padding: '2px 2px 2px 3px !important',
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#1093FF',
        borderColor: '#1093FF',
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.common.white[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#4F4F4F',
  },
  checked: {},
}))(Switch);

export default function CustomizedSwitches({ onChange, value, label }) {
  return (
    <FormGroup>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component={'h5'}>{label}</Typography>
          </Grid>
          <Grid item>
            <AntSwitch checked={value} onChange={onChange} name="checkedC" />
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
}
