// import ClevertapReact from 'clevertap-react';

// /**
//  * Clevertab Configuration
//  */
// const CLEVERTAP = {
//   initialize: CLEVERTAP_ACCOUNT_ID =>
//     ClevertapReact.initialize(CLEVERTAP_ACCOUNT_ID),
//   event: (name, payload) => ClevertapReact.event(name, payload),
//   profile: payload => ClevertapReact.profile(payload),
//   logout: () => ClevertapReact.logout(),
// };

// export { CLEVERTAP };
