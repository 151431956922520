import { Grid, Box } from '@material-ui/core';
import React, { Component } from 'react';
import {
  ReportCardContainer,
  CardHeaderTitle,
  CardHeaderTitle1,
  ImageText1,
  FlexEnd,
  TopicTitle,
  CardSubTitle1,
  CardContent,
  CardItem,
  ItemCaption,
  ItemIcon,
  ImageText,
  ItemText,
  ItemTypo,
} from '../../../../../assets/css/GmatOgReportStyle';
import Circle from '../../../../../assets/icons/circle.svg';
import Right from '../../../../../assets/icons/right.svg';
import Wrong from '../../../../../assets/icons/wrong.svg';
import { Card } from '../../../../../assets/css/report/Report';

const COLORS = {
  easy: '#599726',
  medium: '#0A66C2',
  hard: '#D3244E',
};

const LEVELS = {
  easy: 'Easy',
  medium: 'Medium',
  hard: 'Hard',
};

export default class GmatReportCard extends Component {
  getIcon = name => {
    return name === 'overAll'
      ? Circle
      : name === 'right'
      ? Right
      : name === 'wrong'
      ? Wrong
      : null;
  };
  render() {
    const { overAllReport } = this.props;
    console.log(overAllReport, 'overall');
    return (
      <ReportCardContainer>
        <TopicTitle>{overAllReport.name}</TopicTitle>

        <Grid container spacing={3} alignItems={'flex-start'}>
          {overAllReport.insights &&
            overAllReport.insights.length !== 0 &&
            overAllReport.insights.map(({ name, details, weakTopics }) => (
              <Grid item md={4}>
                <Card>
                  <CardHeaderTitle
                    style={{
                      color: COLORS[name],
                    }}
                  >
                    <b>{LEVELS[name]}</b>
                  </CardHeaderTitle>
                  {details &&
                    details.length !== 0 &&
                    details.map(e => {
                      console.log(details, 'details');
                      return (
                        <CardContent>
                          <CardItem>
                            <ItemIcon>
                              <img src={this.getIcon(e.icon)} alt={''} />
                            </ItemIcon>
                            <ItemCaption>{e.name}</ItemCaption>
                            <ImageText1 flex={1} mt={'2px'} pl={'5px'}>
                              {e.value} 
                            </ImageText1>
                          </CardItem>
                        </CardContent>
                      );
                    })}
                </Card>
                {weakTopics && (
                  // && weakTopics.length > 0
                  <Card margin={'24px 0 10px 0'}>
                    <CardHeaderTitle1>{'Weak Topics'}</CardHeaderTitle1>
                    <FlexEnd>
                      <CardSubTitle1>{'Accuracy %'}</CardSubTitle1>
                    </FlexEnd>

                    {weakTopics.map(e => {
                      console.log(weakTopics, 'TOPics');
                      return (
                        <CardContent>
                          <CardItem>
                            <ItemTypo flex={1} mt={'2px'} pl={'5px'}>
                              {e.name}
                            </ItemTypo>
                            <ImageText1 flex={1} mt={'2px'} pl={'5px'}>
                              {e.value} %
                            </ImageText1>
                          </CardItem>
                        </CardContent>
                      );
                    })}
                  </Card>
                )}
              </Grid>
            ))}
        </Grid>
      </ReportCardContainer>
    );
  }
}