/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Button, Hidden, IconButton, ThemeProvider } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import { uploadTheme } from '../../../../assets/css/MuiStyles';
import backArrow from '../../../../assets/images/back_arrow_large.png';
import rotateIcon from '../../../../assets/images/rotateIcon.png';

class Upload extends Component {
  render() {
    const { t } = this.props;
    return (
      <ThemeProvider theme={uploadTheme}>
        <div className="App">
          <div className="upload__background"></div>
          <div className="crop__container__wrapper">
            <IconButton
              className="upload__close__button"
              onClick={this.props.onCancel}
            >
              <Close />
            </IconButton>
            <div className="crop-container">
              <div className="upload__header__container">
                <h5>{t('Upload your photo')}</h5>
              </div>
              <Cropper
                {...this.props}
                objectFit={'vertical-cover'}
                cropSize={{
                  width: 316.53,
                  height: 316.53,
                }}
                style={{
                  containerStyle: {
                    // paddingTop: '10vh',
                    backgroundColor: 'black',
                    width: 317,
                    height: 456.21,
                    margin: 'auto',
                  },
                }}
              />
              <label htmlFor="" className="upload__drag__label">
                {t('Drag the image to adjust')}
              </label>
              <div className={'image__crop__bottom__bar'}>
                <div className="inner__container">
                  {/* back button */}
                  <div className="upload__back__button__container">
                    <div className="upload__back__button__left">
                      <IconButton
                        className={'upload__back__button'}
                        onClick={this.props.onCancel}
                      >
                        <img alt="" src={backArrow} />
                      </IconButton>
                    </div>
                    <Hidden smDown>
                      <div className="upload__back__button__right">
                        {/* <h5 className={'title'}>{t('Back to Upload')}</h5> */}
                        <h6 className={'title1'}>
                          {t('Maximum Image size of 5Mb')}
                          {'.'}
                        </h6>
                      </div>
                    </Hidden>
                  </div>

                  {/* rotate */}
                  <div className="upload__rotate__container">
                    <IconButton onClick={this.props.onRotate}>
                      <img src={rotateIcon} alt="" className={'rotate__icon'} />
                    </IconButton>
                  </div>

                  {/* cancel and save */}
                  <div className="upload__actions">
                    <Hidden smDown>
                      <Button
                        variant="contained"
                        className={'upload__actions__cancel'}
                        onClick={this.props.onCancel}
                      >
                        {t('Cancel')}
                      </Button>
                    </Hidden>
                    <Button
                      variant={'contained'}
                      className={'upload__actions__save'}
                      onClick={this.props.onDownload}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default Upload;
