/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import LoadingSpinner from './LoadingSpinner';

const Spinner = () => {
  return (
    <Box
      display={'flex'}
      height={'90vh'}
      justifyContent={'center'}
      alignItems={'center'}
      className="pageloader-height"
    >
      <LoadingSpinner size={20} type={'pulse'} />
    </Box>
  );
};

export default Spinner;
