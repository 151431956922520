/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  IconButton,
} from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles({
  root: {
    padding: '28px 0',

    '& .MuiStepConnector-alternativeLabel': {
      top: 12,
      left: 'calc(-50% + 9px)',
      right: 'calc(50% + 9px)',
      zIndex: 0,
    },
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: '2px',
      borderColor: '#87C9FF',
    },
    '& .MuiStepLabel-label': {
      marginTop: '8px',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#052A4E',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      fontWeight: 600,
    },
    '& .MuiStepLabel-label.MuiStepLabel-completed': {
      color: 'rgba(5, 42, 78, 0.37)',
    },
    '& .MuiIconButton-root': {
      padding: '0 !important',
      zIndex: 1,
      '&:hover': {
        background: 'none !important',
      },
      '&:active': {
        background: 'none !important',
      },
    },
  },
});

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export default props => {
  const { activeStep, handleSteperClick, steps } = props;
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StepConnector />}
      classes={classes}
    >
      {steps.map(item => (
        <Step key={item.id}>
          <StepLabel
            StepIconComponent={() => (
              <IconButton
                id={item.id}
                disabled={!item.isAllow}
                onClick={handleSteperClick}
              >
                <Icon src={item.icon} />
              </IconButton>
            )}
          >
            {<span>{item.label}</span>}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
