/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  DASHBOARD,
  DASHBOARD_LANDING,
  DASHBOARD_QUESTION_BANK,
} from '../Action';

const initialState = {
  studyPlan: [],
  planDetails: {},
  status: null,
  concept: [],
  topic: [],
  pendingTopic: [],
  todayTask: [],
  strengthTopic: [],
  weakTopic: [],
  weekly: null,
  monthly: null,
  subjectDetails: [],
  taskComplete: [],
  allQuestions: [],
  allTopics: [],
  singleQuestion: [],
  questionAnswer: [],
  answerExplanation: [],
  topicTest: [],
  questionBankTest: [],
  studyPlanSubjects: [],
  studyPlanConcepts: [],
  studyPlanTopics: [],
  strengthsAndWeakness: [],
  studyPlanQuestions: [],
  retake: [],
  report: [],
  qbankReport: [],
  notes: [],
  AllNote: {
    subjectValue: 'default',
    conceptValue: 'default',
    topicValue: 'default',
  },
  taskNotes: [],
  topicDetails: null,
  attemptStatus: 'ALL',
  difficultyLevel: '',
  addNotes: null,
  progressTracker: null,
  wrongConcept: null,
  wrongCalibration: null,
  progressStatus: null,
  diagnosticEngine: null,
  diagnosticStatus: null,
  calibrationStatus: null,
  studyPlanByDate: null,
  webinar: [],
  webinarFilter: [],
  webinarConceptFilter: [],
  level: [],
  questionBankState: {
    subjectValue: 'all',
    conceptValue: 'all',
    activeTopic: 'all',
    questionTypes: [],
    questionType: 'All',
  },
  recentPosts: {},
  popularPosts: {},
  search: {},
  study: {},
  dashboardLoading: true,
  isVisited: null,
  studyPlanConceptTopics: {},
  allSubjectList: [],
  allConceptList: [],
  allTopicList: [],
  allVideoQuestion: [],
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.getStudyPlan: {
      return {
        ...state,
        studyPlan: action.payload,
      };
    }
    case DASHBOARD.getTopic: {
      return {
        ...state,
        topic: action.payload,
      };
    }
    case DASHBOARD.getPlanDetails: {
      return {
        ...state,
        planDetails: action.payload,
      };
    }
    case DASHBOARD.getStudyPlanDetails: {
      return {
        ...state,
        studyPlanDetails: action.payload,
      };
    }

    case DASHBOARD.getSubjectDetails: {
      return {
        ...state,
        subjectDetails: action.payload,
      };
    }
    case DASHBOARD.updateCompletedTask: {
      return {
        ...state,
        taskComplete: action.payload,
      };
    }
    case DASHBOARD.getAllQuestions: {
      return {
        ...state,
        allQuestions: action.payload,
      };
    }
    case DASHBOARD.getAllTopics: {
      return {
        ...state,
        allTopics: action.payload,
      };
    }
    case DASHBOARD.topicTestInstruction: {
      return {
        ...state,
        topicTest: action.payload,
      };
    }
    case DASHBOARD.questionBankTestInstruction: {
      return {
        ...state,
        questionBankTest: action.payload,
      };
    }
    case DASHBOARD.retakeExam: {
      return {
        ...state,
        retake: action.payload,
      };
    }
    case DASHBOARD.getReport: {
      return {
        ...state,
        report: action.payload,
      };
    }
    case DASHBOARD.getQbankReport: {
      return {
        ...state,
        qbankReport: action.payload,
      };
    }

    case DASHBOARD.getStrengthsAndWeakness: {
      return {
        ...state,
        strengthsAndWeakness: action.payload,
      };
    }
    case DASHBOARD.getNotesWithTopic: {
      return {
        ...state,
        notes: action.payload,
      };
    }
    case DASHBOARD.AllNote: {
      return {
        ...state,
        AllNote: action.payload,
      };
    }
    case DASHBOARD.getNotesWithTask: {
      return {
        ...state,
        taskNotes: action.payload,
      };
    }
    case DASHBOARD.addNotes: {
      return {
        ...state,
        addNotes: action.payload,
      };
    }
    case DASHBOARD.getTopicDetails: {
      return {
        ...state,
        topicDetails: action.payload,
      };
    }
    case DASHBOARD.getAllNotes: {
      return {
        ...state,
        notes: action.payload,
      };
    }

    case DASHBOARD.studyPlanByDate: {
      return {
        ...state,
        studyPlanByDate: action.payload,
      };
    }

    case DASHBOARD.getAllSubjectsByProduct: {
      return {
        ...state,
        allSubjectList: action.payload,
      };
    }

    case DASHBOARD.getAllConceptsBySubject: {
      return {
        ...state,
        allConceptList: action.payload,
      };
    }
    case DASHBOARD.inVideoQuestion: {
      return {
        ...state,
        allVideoQuestion: action.payload,
      };
    }
    case DASHBOARD.updateVideoAnswer: {
      return {
        ...state,
        allVideoQuestion: action.payload,
      };
    }

    case DASHBOARD.getAllTopicsByConcept: {
      return {
        ...state,
        allTopicList: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

const DashboardLandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_LANDING.getDashboardStatus: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case DASHBOARD_LANDING.getConcept: {
      return {
        ...state,
        concept: action.payload,
      };
    }
    case DASHBOARD_LANDING.getTodayTask: {
      return {
        ...state,
        todayTask: action.payload,
      };
    }
    case DASHBOARD_LANDING.getPendingTask: {
      return {
        ...state,
        pendingTopic: action.payload,
      };
    }
    case DASHBOARD_LANDING.getStrengthTask: {
      return {
        ...state,
        strengthTopic: action.payload,
      };
    }
    case DASHBOARD_LANDING.getWeakTask: {
      return {
        ...state,
        weakTopic: action.payload,
      };
    }
    case DASHBOARD_LANDING.getReport: {
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case DASHBOARD_LANDING.singleQuestion: {
      return {
        ...state,
        singleQuestion: action.payload,
      };
    }
    case DASHBOARD_LANDING.questionAnswer: {
      return {
        ...state,
        questionAnswer: action.payload,
      };
    }
    case DASHBOARD_LANDING.answerExplanation: {
      return {
        ...state,
        answerExplanation: action.payload,
      };
    }
    case DASHBOARD_LANDING.progressTracker: {
      return {
        ...state,
        progressTracker: action.payload,
      };
    }
    case DASHBOARD_LANDING.wrongConcept: {
      return {
        ...state,
        wrongConcept: action.payload,
      };
    }
    case DASHBOARD_LANDING.wrongCalibration: {
      return {
        ...state,
        wrongCalibration: action.payload,
      };
    }
    case DASHBOARD_LANDING.progressStatus: {
      return {
        ...state,
        progressStatus: action.payload,
      };
    }
    case DASHBOARD_LANDING.diagnosticEngine: {
      return {
        ...state,
        diagnosticEngine: action.payload,
      };
    }
    case DASHBOARD_LANDING.getDashboardDiagnosticStatus: {
      return {
        ...state,
        diagnosticStatus: action.payload,
      };
    }
    case DASHBOARD_LANDING.getDasboardCalibration: {
      return {
        ...state,
        calibrationStatus: action.payload,
      };
    }

    case DASHBOARD_LANDING.webinar: {
      return {
        ...state,
        webinar: action.payload,
      };
    }

    case DASHBOARD_LANDING.webinarFilter: {
      return {
        ...state,
        webinarFilter: action.payload,
      };
    }
    case DASHBOARD_LANDING.webinarConceptFilter: {
      return {
        ...state,
        webinarConceptFilter: action.payload,
      };
    }

    case DASHBOARD_LANDING.difficultyLevelChange: {
      return {
        ...state,
        level: action.payload,
      };
    }

    case DASHBOARD_LANDING.getRecentPosts: {
      return {
        ...state,
        recentPosts: action.payload,
      };
    }

    case DASHBOARD_LANDING.getPopularPosts: {
      return {
        ...state,
        popularPosts: action.payload,
      };
    }

    case DASHBOARD_LANDING.search: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case DASHBOARD_LANDING.deleteStudy: {
      return {
        ...state,
        study: action.payload,
      };
    }

    case DASHBOARD_LANDING.loading: {
      return {
        ...state,
        dashboardLoading: action.payload,
      };
    }

    case DASHBOARD_LANDING.isVisited: {
      return {
        ...state,
        isVisited: action.payload,
      };
    }

    case DASHBOARD_LANDING.setFieldValue:
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };

    default:
      break;
  }
  return state;
};

const DashboardQuestionBankReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_QUESTION_BANK.getStudyPlanSubjects: {
      return {
        ...state,
        studyPlanSubjects: action.payload,
      };
    }
    case DASHBOARD_QUESTION_BANK.getStudyPlanConcepts: {
      return {
        ...state,
        studyPlanConcepts: action.payload,
      };
    }
    case DASHBOARD_QUESTION_BANK.getStudyPlanTopics: {
      return {
        ...state,
        studyPlanTopics: action.payload,
      };
    }
    case DASHBOARD_QUESTION_BANK.getStudyPlanConceptTopics: {
      return {
        ...state,
        studyPlanConceptTopics: action.payload,
      };
    }
    case DASHBOARD_QUESTION_BANK.getStudyPlanQuestions: {
      return {
        ...state,
        studyPlanQuestions: action.payload,
        difficultyLevel: action.difficultyLevel,
        attemptStatus: action.attemptStatus,
      };
    }

    case DASHBOARD_QUESTION_BANK.questionBankState: {
      return {
        ...state,
        questionBankState: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

export {
  DashboardReducer,
  DashboardLandingReducer,
  DashboardQuestionBankReducer,
};
