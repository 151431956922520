/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import CorrectIcon from '../../../../assets/icons/correct.svg';
import IncorrectIcon from '../../../../assets/icons/incorrect.svg';
import Latex from '../../../../utils/LatexViewer';

const App = ({
  choices,
  bundleLength,
  onChange,
  selectedChoice,
  correctChoice,
  isHaveDescription,
  para,
}) => {
  var alphaOption = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
  ];
  var romanLetter = ['i', 'ii', 'iii', 'iv', 'v'];
  const renderHeader = () => {
    let arr = [];
    for (let i = 1; i <= bundleLength; i++) {
      arr.push(`blank (${romanLetter[i - 1]})`);
    }
    return arr;
  };

  const renderOptions = () => {
    let arr = [];
    for (let i = 1; i <= bundleLength; i++) {
      let choice = [];
      for (let j = 0; j < choices.length; j++) {
        if (choices[j].bundleNo === i) {
          choice.push(choices[j]);
        }
      }
      arr.push({
        bundleNo: i,
        choices: choice,
      });
    }
    return arr;
  };
  let idxx = -1;
  const style = {
    half: {
      width: '50%',
    },
    full: {
      width: '95%',
    },
  };
  const { half, full } = style;
  return (
    <div style={isHaveDescription === true ? full : half}>
      {isHaveDescription === true && <Latex math={para} />}
      <table className={'bundle__table'}>
        <thead>
          <tr>
            {renderHeader().map(item => {
              return <th>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {renderOptions().map((item, i) => (
            <td className={'td'}>
              {item.choices.map(option => {
                if (
                  selectedChoice.some(
                    selected =>
                      selected.bundleNo === item.bundleNo &&
                      selected.id === option.id
                  ) &&
                  correctChoice.some(
                    selected =>
                      selected.bundleNo === item.bundleNo &&
                      selected.id === option.id
                  )
                ) {
                  return (
                    <tr>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="optionA"
                            color="primary"
                            checked={selectedChoice.some(
                              selected =>
                                selected.bundleNo === item.bundleNo &&
                                selected.id === option.id
                            )}
                            checkedIcon={
                              <img
                                src={CorrectIcon}
                                alt=""
                                style={{
                                  width: 18,
                                  marginLeft: 3,
                                }}
                              />
                            }
                          />
                        }
                        // label={`(${alphaOption[(idxx += 1)]}) ${option.text}`}
                        label={
                          <Box display={'flex'} alignItems={'center'}>
                            {alphaOption[(idxx += 1)]})&nbsp;
                            <Latex math={option.text} />
                          </Box>
                        }
                        onChange={() => {
                          onChange(item, option);
                        }}
                      />
                    </tr>
                  );
                } else if (
                  selectedChoice.some(
                    selected =>
                      selected.bundleNo === item.bundleNo &&
                      selected.id === option.id
                  )
                ) {
                  return (
                    <tr>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="optionA"
                            color="primary"
                            checked={selectedChoice.some(
                              selected =>
                                selected.bundleNo === item.bundleNo &&
                                selected.id === option.id
                            )}
                            checkedIcon={
                              <img
                                src={IncorrectIcon}
                                alt=""
                                style={{
                                  width: 18,
                                  marginLeft: 3,
                                }}
                              />
                            }
                          />
                        }
                        // label={`(${alphaOption[(idxx += 1)]}) ${option.text}`}
                        label={
                          <Box display={'flex'}>
                            <span>{alphaOption[(idxx += 1)]})&nbsp;</span>
                            <Latex math={option.text} />
                          </Box>
                        }
                        onChange={() => {
                          onChange(item, option);
                        }}
                      />
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="optionA"
                            color="primary"
                            checked={correctChoice.some(
                              selected =>
                                selected.bundleNo === item.bundleNo &&
                                selected.id === option.id
                            )}
                            checkedIcon={
                              <img
                                src={CorrectIcon}
                                alt=""
                                style={{
                                  width: 18,
                                  marginLeft: 3,
                                }}
                              />
                            }
                          />
                        }
                        // label={`(${alphaOption[(idxx += 1)]}) ${option.text}`}
                        label={
                          <Box display={'flex'}>
                            <span>{alphaOption[(idxx += 1)]})&nbsp;</span>
                            <Latex math={option.text} />
                          </Box>
                        }
                        onChange={() => {
                          onChange(item, option);
                        }}
                      />
                    </tr>
                  );
                }
              })}
            </td>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;
