import { OFFICIAL_GUIDE } from '../Action';

const initialState = {
  greOgDetails: null,
  gmatOgQuestions: null,
  test: {},
  OgReport:{},
  greOgReport: {},
  gmatOgReport: {},
  greOldReport: [],
  excelReport: {},
  excelGmatReport: {},
  officialGuideState: {
    subjectValue: null,
    conceptValue: null,
    topicValue: 'all',
    attemptStatus: 'ALL',
   
    difficultyLevel: [],
    overAllReport: [],
    attemptValue:'select',
  },
};

const OfficialGuideReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFICIAL_GUIDE.getGreOGDetails: {
      return {
        ...state,
        greOgDetails: action.payload,
      };
    }
    case OFFICIAL_GUIDE.getGmatOGQuestions: {
      return {
        ...state,
        gmatOgQuestions: action.payload,
      };
    }
    case OFFICIAL_GUIDE.setOfficialGuideState: {
      return {
        ...state,
        officialGuideState: action.payload,
      };
    }
    case OFFICIAL_GUIDE.getGreOgReport: {
      return {
        ...state,
        greOgReport: action.payload,
      };
    }
    case OFFICIAL_GUIDE.gmatOgReport: {
      return {
        ...state,
        gmatOgReport: action.payload,
      };
    }
    case OFFICIAL_GUIDE.getExcel: {
      return {
        ...state,
        excelReport: action.payload,
      };
    }
    case OFFICIAL_GUIDE.getGmatExcel: {
      return {
        ...state,
        excelGmatReport: action.payload,
      };
    }
    case OFFICIAL_GUIDE.overAllReport: {
      return {
        ...state,
        overAllReport: action.payload,
      };
    }
    case OFFICIAL_GUIDE.setFieldValue: {
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };
    }

    case OFFICIAL_GUIDE.getGreAttemptedTests: {
      return {
        ...state,
        test: action.payload,
      };
    }
    case OFFICIAL_GUIDE.getOgAttemptedTests: {
      return {
        ...state,
        OgReport: action.payload,
      };
    }

    case OFFICIAL_GUIDE.getGreOgOldReport: {
      return {
        ...state,
        greOldReport: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

export default OfficialGuideReducer;
