/**
 * (c) CareerLabs. All rights reserved.
 **/
import { ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { rootTheme } from '../assets/css/MuiStyles';
import Routes from '../routes/Routes';

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={rootTheme}>
        <main>
          <Routes />
        </main>
      </ThemeProvider>
    );
  }
}

export default withRouter(Index);
