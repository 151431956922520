/**
 * (c) CareerLabs. All rights reserved.
 **/

import React, { Component } from 'react';
import { ProfileContainer } from '../../assets/css/StyledComponent';
import Profile from '../dashboard/profile/Index';

export default class ProfileLayout extends Component {
  render() {
    return (
      <ProfileContainer>
        <Profile />
      </ProfileContainer>
    );
  }
}
