/**
 * (c) CareerLabs. All rights reserved.
 **/
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/dashboard/StyledNotesComponents';
import { QS_PROPS } from '../../../constant/Variables';
import * as track from '../../../mixpanel/Events';
import {
  getAllNotes,
  getNotesWithTopic,
  getStudyPlanConcepts,
  getStudyPlanSubjects,
  getStudyPlanTopics,
  removeNote,
  setNoteChanges,
  AllNotes,
} from '../../../redux/action/Dashboard';
import { routePaths } from '../../../routes/RoutePath';
import ConfirmationDialog from './ConfirmationDialog';
import Header from './Header';
import NoteBody from './NoteBody';
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      active: null,
      textFieldValue: '',
      show: [],
      taskId: null,
      noteId: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getStudyPlanSubjects();
    track.notesPageLanding();

    const { subjectId, conceptId, topicId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (subjectId && conceptId && topicId) {
      this.handleSubjectChange({
        target: { name: 'subjectValue', value: subjectId },
      });
      this.handleConceptChange({
        target: { name: 'conceptValue', value: conceptId },
      });
      this.handleTopicChange({
        target: { name: 'topicValue', value: topicId },
      });
    }
    const { subjectValue, conceptValue, topicValue } = this.props.AllNote;
    if (
      subjectValue === 'default' &&
      conceptValue === 'default' &&
      topicValue === 'default'
    ) {
      this.props.getAllNotes();
    }
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      prevProps !== this.props &&
      this.subjectValue === 'default' &&
      this.conceptValue === 'default' &&
      this.topicValue === 'default'
    ) {
      this.props.getAllNotes();
    }
  }

  handleSubjectChange = event => {
    this.props.getStudyPlanConcepts(event.target.value);
    this.props.getNotesWithTopic('default', event.target.value, 'default');
    // this.setState({
    //   [event.target.name]: event.target.value,
    //   conceptValue: 'default',
    //   topicValue: 'default',
    // });
    this.props.AllNotes({
      ...this.props.AllNote,
      subjectValue: event.target.value,
    });
  };

  handleConceptChange = event => {
    this.props.getStudyPlanTopics(event.target.value);
    this.props.getNotesWithTopic('default', 'default', event.target.value);
    // this.setState({
    //   [event.target.name]: event.target.value,
    //   topicValue: 'default',
    // });
    this.props.AllNotes({
      ...this.props.AllNote,
      conceptValue: event.target.value,
    });
  };

  handleTopicChange = event => {
    this.props.getNotesWithTopic(event.target.value, 'default', 'default');
    // this.setState({ [event.target.name]: event.target.value });
    this.props.AllNotes({
      ...this.props.AllNote,
      topicValue: event.target.value,
    });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleEdit = (noteId, noteText) => {
    this.setState({ active: noteId, textFieldValue: noteText });
  };

  handleSecondary = noteId => {
    if (noteId === this.state.active) {
      this.setState({ active: null });
    } else {
      if (this.state.show.indexOf(noteId) > -1) {
        let filteredArray = this.state.show.filter(item => {
          return item !== noteId;
        });
        this.setState({ show: filteredArray });
      } else {
        this.setState({ show: [...this.state.show, noteId] });
      }
    }
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handlePrimary = (noteId, taskId, topicId) => {
    if (noteId === this.state.active) {
      let obj = {
        notes: this.state.textFieldValue,
      };
      const { subjectValue, topicValue, conceptValue } = this.props.AllNote;
      this.props.setNoteChanges(taskId, noteId, obj, response => {
        if (response.success) {
          if (
            subjectValue === 'default' &&
            conceptValue === 'default' &&
            topicValue === 'default'
          ) {
            this.props.getAllNotes();
          } else
            this.props.getNotesWithTopic(
              topicValue,
              subjectValue,
              conceptValue
            );
          this.setState({ active: null });
        }
      });
    } else if (topicId !== undefined) {
      this.props.history.push(
        `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskId}&prevPage=notes`
      );
    }
  };

  handleDelete = (noteId, taskId) => {
    if (this.state.dialogOpen) {
      const { subjectValue, topicValue, conceptValue } = this.props.AllNote;
      this.props.removeNote(this.state.taskId, this.state.noteId, response => {
        if (response.success)
          if (
            subjectValue === 'default' &&
            conceptValue === 'default' &&
            topicValue === 'default'
          ) {
            this.props.getAllNotes();
          } else
            this.props.getNotesWithTopic(
              topicValue,
              subjectValue,
              conceptValue
            );
        this.handleDialogClose();
      });
    } else {
      this.setState({ dialogOpen: true, taskId: taskId, noteId: noteId });
    }
  };

  render() {
    const { data: subjectData } = this.props.studyPlanSubjects;
    const { data: conceptData } = this.props.studyPlanConcepts;
    const { data: topicData } = this.props.studyPlanTopics;
    const { data: notesData } = this.props.notes;
    const { t } = this.props;
    const { subjectValue, conceptValue, topicValue } = this.props.AllNote;
    console.log(this.props.notes.data, this.props);
    const {
      // subjectValue,
      // conceptValue,
      // topicValue,
      dialogOpen,
      active,
      textFieldValue,
      show,
    } = this.state;
    const dialogText = {
      title: t('Are you sure you want to Delete ?'),
      body: t('if not Press Continue'),
      button1: t('Cancel'),
      button2: t('Yes'),
    };

    return (
      <Container>
        <Header
          subjectValue={subjectValue}
          subjectData={subjectData}
          conceptValue={conceptValue}
          conceptData={conceptData}
          topicValue={topicValue}
          topicData={topicData}
          handleSubjectChange={this.handleSubjectChange}
          handleConceptChange={this.handleConceptChange}
          handleTopicChange={this.handleTopicChange}
          t={t}
        />
        <NoteBody
          notesData={notesData}
          handleSecondary={this.handleSecondary}
          active={active}
          textFieldValue={textFieldValue}
          handleEdit={this.handleEdit}
          handleInputChange={this.handleInputChange}
          handlePrimary={this.handlePrimary}
          handleDelete={this.handleDelete}
          show={show}
          t={t}
        />
        <ConfirmationDialog
          open={dialogOpen}
          dialogText={dialogText}
          handleClose={this.handleDialogClose}
          handleDelete={this.handleDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    studyPlanSubjects: state.DashboardQuestionBankReducer.studyPlanSubjects,
    studyPlanConcepts: state.DashboardQuestionBankReducer.studyPlanConcepts,
    studyPlanTopics: state.DashboardQuestionBankReducer.studyPlanTopics,
    notes: state.dashboardReducer.notes,
    AllNote: state.dashboardReducer.AllNote,
  };
};
export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getStudyPlanTopics,
  getNotesWithTopic,
  setNoteChanges,
  removeNote,
  getAllNotes,
  AllNotes,
})(withTranslation()(Notes));
