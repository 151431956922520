import { Grid } from '@material-ui/core';
import _ from 'lodash';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from '../../../assets/css/StyledPractice';
import RocketIcon from '../../../assets/icons/BlueRocket.svg';
import { QS_PROPS } from '../../../constant/Variables';
import {
  getStudyPlanConcepts,
  getStudyPlanConceptTopics,
  getStudyPlanQuestions,
  getStudyPlanSubjects,
} from '../../../redux/action/Dashboard';
import { questionBankState } from '../../../redux/action/DashboardLanding';
import { getAllQuestionTypes } from '../../../redux/action/Practice';
import { routePaths } from '../../../routes/RoutePath';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import DialogComponent from '../../../utils/components/DialogComponent';
import PageLoader from '../../../utils/components/PageLoader';
import simulateMouseClick from '../../../utils/methods/SimulateMouseClick';
import Header from './Header';
import Left from './Left';
import Right from './Right';

class TotalQuestionBank extends Component {
  constructor(props) {
    super(props);

    this.breadCrumbsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Practise',
              path: routePaths.dashboard.solveByQuestion,
            },
            {
              name: 'Question bank',
              path: routePaths.dashboard.solveByQuestion,
            },
            {
              name: 'Solve by Question',
              path: routePaths.dashboard.solveByQuestion,
            },
          ],
        },
      },
      back: true,
    };

    this.state = {
      boosterDialog: false,
      currenElement: null,
      isLoading: true,
    };
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
  }

  componentDidMount() {
    const { questionType } = this.props.questionState;
    console.log(this.props.questionState, 'oo');
    this.props.getAllQuestionTypes();

    window.scrollTo(0, 0);
    // Score boost route
    window.document.addEventListener('click', this.handleGeneralClick, true);
    window.document.addEventListener('change', this.handleGeneralClick, true);

    const { subjectId, conceptId, topicId, level, boost } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (boost) {
      // Boost mode
      this.props.getStudyPlanSubjects(response => {
        if (response.success)
          this.props.getStudyPlanConcepts(
            subjectId,
            conceptResponse => {
              if (conceptResponse.success) {
                this.props.getStudyPlanConceptTopics(
                  subjectId,
                  conceptId,
                  topicResponse => {
                    if (topicResponse.success) {
                      this.props.getStudyPlanQuestions(
                        questionResponse => {
                          // Handling select subject,concept and topic in reducer
                          this.props.questionBankState({
                            ...this.props.questionState,
                            subjectValue: subjectId,
                            conceptValue: conceptId,
                            activeTopic: topicId,
                            testQuestionId: questionResponse.data.id,
                            questionType: 'All',
                          });
                        },
                        topicId,
                        'ALL',
                        level,
                        'All',
                        subjectId,
                        conceptId
                      );
                      this.setState({ isLoading: false });
                    }
                  },
                  'questions'
                );
              }
            },
            'questions'
          );
      }, 'questions');
    } else {
      if (
        this.props.questionState.conceptValue === 'all' &&
        this.props.questionState.questionType === 'All' &&
        this.props.questionState.activeTopic === 'all'
      ) {
        this.props.getStudyPlanSubjects(response => {
          if (response.success)
            this.props.getStudyPlanConcepts(
              response.data[0]?.id,
              conceptResponse => {
                if (conceptResponse.success) {
                  this.props.getStudyPlanConceptTopics(
                    response.data[0]?.id,
                    'all',

                    topicResponse => {
                      if (topicResponse.success) {
                        this.props.getStudyPlanQuestions(
                          questionResponse => {
                            // Handling select subject,concept and topic in reducer
                            this.props.questionBankState(
                              {
                                ...this.props.questionState,
                                subjectValue: response.data[0]?.id,
                                conceptValue: 'all',
                                activeTopic: 'all',
                                testQuestionId: questionResponse.data.id,
                              },
                              'questions'
                            );
                          },
                          'all',
                          this.props.attemptStatus,
                          this.props.difficultyLevel,
                          questionType,
                          'all',
                          'all'
                        );
                        this.setState({ isLoading: false });
                      }
                    },
                    'questions'
                  );
                  this.setState({ isLoading: false });
                }
              },
              'questions'
            );
          this.setState({ isLoading: false });
        }, 'questions');
      } else {
        const { activeTopic, subjectValue, conceptValue } =
          this.props.questionState;
        this.props.getStudyPlanSubjects(() => {}, 'questions');
        this.props.getStudyPlanConcepts(subjectValue, () => {}, 'questions');
        this.props.getStudyPlanConceptTopics(
          subjectValue,
          conceptValue,
          () => {},
          'questions'
        );
        this.props.getStudyPlanQuestions(
          () => {},
          activeTopic,
          this.props.attemptStatus,
          this.props.difficultyLevel,
          questionType,
          subjectValue,
          conceptValue
        );
        this.setState({ isLoading: false });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.allQuestionTypes &&
      prevProps.allQuestionTypes !== this.props.allQuestionTypes
    ) {
      if (this.props.allQuestionTypes.success) {
        this.props.questionBankState({
          ...this.props.questionState,
          questionTypes: this.props.allQuestionTypes.data,
        });
      } else {
        this.props.questionBankState({
          ...this.props.questionState,
          questionTypes: [],
        });
      }
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
  }

  onChange = e => {
    const { activeTopic, subjectValue, conceptValue } =
      this.props.questionState;

    const { name, value } = e.target;
    this.props.questionBankState({
      ...this.props.questionState,
      [name]: value,
    });
    switch (name) {
      case 'questionType': {
        this.props.getStudyPlanQuestions(
          () => {},
          activeTopic,
          this.props.attemptStatus,
          this.props.difficultyLevel,
          value,
          subjectValue,
          conceptValue
        );
        break;
      }
      default:
        break;
    }
  };

  //Subject Change
  handleChange = event => {
    const { questionType } = this.props.questionState;

    const { data: subjectData } = this.props.studyPlanSubjects;

    let newQuestionType = this.isQuestionDropdown(
      subjectData,
      event.target.value
    )
      ? questionType
      : 'All';

    this.props.getStudyPlanConcepts(
      event.target.value,
      response => {
        if (response.success)
          this.props.getStudyPlanConceptTopics(
            event.target.value,
            'all',
            topicResponse => {
              if (topicResponse.success) {
                if (topicResponse.data.length === 1) {
                  this.props.getStudyPlanQuestions(
                    questionResponse => {
                      if (questionResponse.success)
                        // Handling select subject,concept and topic in reducer
                        this.props.questionBankState({
                          ...this.props.questionState,
                          subjectValue: event.target.value,
                          conceptValue: response.data[0].id,
                          activeTopic: topicResponse.data[0].id,
                          testQuestionId: questionResponse.data.id,
                          questionType: newQuestionType,
                        });
                    },
                    topicResponse.data[0].id,
                    this.props.attemptStatus,
                    this.props.difficultyLevel,
                    newQuestionType,
                    event.target.value,
                    response.data[0].id
                  );
                } else {
                  this.props.getStudyPlanQuestions(
                    questionResponse => {
                      if (questionResponse.success)
                        // Handling select subject,concept and topic in reducer
                        this.props.questionBankState({
                          ...this.props.questionState,
                          subjectValue: event.target.value,
                          conceptValue: 'all',
                          activeTopic: 'all',
                          testQuestionId: questionResponse.data.id,
                          questionType: newQuestionType,
                        });
                    },
                    'all',
                    this.props.attemptStatus,
                    this.props.difficultyLevel,
                    newQuestionType,
                    event.target.value,
                    'all'
                  );
                }
                this.setState({ isLoading: false });
              }
            },
            'questions'
          );
      },
      'questions'
    );
  };

  handleConceptChange = event => {
    const { questionType, subjectValue } = this.props.questionState;

    this.props.getStudyPlanConceptTopics(
      this.props.questionState.subjectValue,
      event.target.value,
      response => {
        if (response.success) {
          if (response.data[0].length === 1) {
            this.props.getStudyPlanQuestions(
              questionResponse => {
                // Handling select subject,concept and topic in reducer
                this.props.questionBankState({
                  ...this.props.questionState,
                  subjectValue: this.props.questionState.subjectValue,
                  conceptValue: event.target.value,
                  activeTopic: response.data[0].id,
                  testQuestionId: questionResponse.data.id,
                });
              },
              response.data[0].id,
              this.props.attemptStatus,
              this.props.difficultyLevel,
              questionType,
              subjectValue,
              event.target.value
            );
          } else {
            this.props.getStudyPlanQuestions(
              questionResponse => {
                // Handling select subject,concept and topic in reducer
                this.props.questionBankState({
                  ...this.props.questionState,
                  subjectValue: this.props.questionState.subjectValue,
                  conceptValue: event.target.value,
                  activeTopic: 'all',
                  testQuestionId: questionResponse.data.id,
                });
              },
              'all',
              this.props.attemptStatus,
              this.props.difficultyLevel,
              questionType,
              subjectValue,
              event.target.value
            );
          }
          this.setState({ isLoading: false });
        }
      },
      'questions'
    );
  };

  handleActiveTopic = topicId => {
    const { questionType, subjectValue, conceptValue } =
      this.props.questionState;
    this.props.getStudyPlanQuestions(
      questionResponse => {
        // Handling select subject,concept and topic in reducer
        this.props.questionBankState({
          ...this.props.questionState,
          subjectValue: this.props.questionState.subjectValue,
          conceptValue: this.props.questionState.conceptValue,
          activeTopic: topicId,
          testQuestionId: questionResponse.data.id,
        });
        console.log(this.props.questionState.activeTopic, 'topic');
      },
      topicId,
      this.props.attemptStatus,
      this.props.difficultyLevel,
      questionType,
      subjectValue,
      conceptValue
    );
  };

  handleAttemptStatus = event => {
    const { questionType } = this.props.questionState;

    this.props.getStudyPlanQuestions(
      questionResponse => {
        if (questionResponse.success) {
          this.props.questionBankState({
            ...this.props.questionState,
            testQuestionId: questionResponse.data.id,
          });
          this.setState({ isLoading: false });
        }
      },
      this.props.questionState.activeTopic,
      event.target.value,
      this.props.difficultyLevel,
      questionType,
      this.props.questionState.subjectValue,
      this.props.questionState.conceptValue
    );
  };

  handleCheckBox = event => {
    const { questionType } = this.props.questionState;

    if (this.props.difficultyLevel.indexOf(event.target.name) > -1) {
      let difficultyLevel = this.props.difficultyLevel.filter(item => {
        return item !== event.target.name;
      });
      this.props.getStudyPlanQuestions(
        questionResponse => {
          this.props.questionBankState({
            ...this.props.questionState,
            testQuestionId: questionResponse.data.id,
          });
        },
        this.props.questionState.activeTopic,
        this.props.attemptStatus,
        difficultyLevel,
        questionType,
        this.props.questionState.subjectValue,
        this.props.questionState.conceptValue
      );
    } else
      this.props.getStudyPlanQuestions(
        questionResponse => {
          this.props.questionBankState({
            ...this.props.questionState,
            testQuestionId: questionResponse.data.id,
          });
        },
        this.props.questionState.activeTopic,
        this.props.attemptStatus,
        [...this.props.difficultyLevel, event.target.name],
        questionType,
        this.props.questionState.subjectValue,
        this.props.questionState.conceptValue
      );
  };

  // handleClick = (questionId, testQuestionId, isAnswered) => {
  //   this.props.history.push(
  //     `${routePaths.dashboard.question}?questionId=${questionId}&testQuestionId=${testQuestionId}&isAnswered=${isAnswered}`
  //   );
  // };
  // getQuestionBank = questionsData => {
  //   if (res && res.success) return questionsData?.questions || [];
  //   else return [];
  // };
  handleClick = (filterQuestionList, testQuestionsSetId) => {
    console.log('clicked');
    sessionStorage.setItem(
      'official_guide_gmat_question',
      JSON.stringify(filterQuestionList)
    );
    console.log('clicked');
    this.props.history.push(
      `${routePaths.dashboard.questionBankTestInstruction}?testQuestionId=${testQuestionsSetId}&og_course=QUESTIONBANK&qbank_type=QUESTION`
    );
  };

  // handleGmatQuestion = (filterQuestionList, testQuestionsSetId) => {
  //   sessionStorage.setItem(
  //     'official_guide_gmat_question',
  //     JSON.stringify(filterQuestionList)
  //   );
  //   this.props.history.push(
  //     `${routePaths.dashboard.officialGuideTestInstruction}?testQuestionId=${testQuestionsSetId}&og_course=GMAT`
  //   );
  // };

  isQuestionDropdown = (subjectOptions = [], subjectValue) => {
    return subjectOptions && subjectOptions.length !== 0
      ? subjectOptions.some(
          item => item.id === subjectValue && item.isQuestionTypeFilter
        )
      : false;
  };

  handleGeneralClick(e) {
    const { boost } = QueryString.parse(this.props.location.search, QS_PROPS);

    const clsName = e.target.className.baseVal || e.target.className;
    if (boost && _.isString(clsName) && clsName.indexOf('on-boost-mode') > -1) {
      e.preventDefault();
      e.target.onclick = null;
      e.stopPropagation();
      this.setState({ boosterDialog: true, currenElement: e.target });
    }
  }

  handleButton1Click = () => {
    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
    this.props.history.replace(routePaths.dashboard.solveByQuestion);
    simulateMouseClick(this.state.currenElement);
    this.setState({ boosterDialog: false });
  };

  render() {
    const { data: subjectData } = this.props.studyPlanSubjects;
    const { data: conceptData } = this.props.studyPlanConcepts;
    const { data: topicData } = this.props.studyPlanConceptTopics;
    const { difficultyLevel, questionState } = this.props;
    const { attemptStatus, qBank } = this.props;
    const { questionType, questionTypes } = this.props.questionState;
    const { onChange, isQuestionDropdown } = this;
    console.log('questionState', questionState, difficultyLevel);
    const { t } = this.props;
    const questionBank = this.props.studyPlanQuestions.questions;
    console.log(questionBank, 'ehffhfgghghghghghhhhhhhh');

    const { activeTopic, conceptValue, subjectValue } = questionState;
    let combination = {
      subjectValue: subjectValue,
      conceptValue: conceptValue,
      topicValue: activeTopic,
      questionType: this.props.questionState.questionType,
      difficultyLevel: difficultyLevel?.toString(),
    };
    sessionStorage.setItem('combination1', JSON.stringify(combination));

    if (topicData && topicData.length !== 0)
      return (
        <>
          {this.state.isLoading ? (
            <PageLoader />
          ) : (
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BreadCrumbs {...this.breadCrumbsProps} />
                </Grid>
                <Grid item xs={12}>
                  <Header
                    subjectData={subjectData}
                    conceptData={conceptData}
                    subjectValue={this.props.questionState.subjectValue}
                    conceptValue={this.props.questionState.conceptValue}
                    handleChange={this.handleChange}
                    handleConceptChange={this.handleConceptChange}
                    onChange={onChange}
                    questionType={questionType}
                    questionTypes={questionTypes}
                    isQuestionDropdown={isQuestionDropdown}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Left
                    topicData={topicData}
                    activeTopic={this.props.questionState.activeTopic}
                    handleActiveTopic={this.handleActiveTopic}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Right
                    questionBank={questionBank}
                    attemptStatus={attemptStatus}
                    handleAttemptStatus={this.handleAttemptStatus}
                    questionsData={this.props.studyPlanQuestions}
                    difficultyLevel={difficultyLevel}
                    handleCheckBox={this.handleCheckBox}
                    handleClick={this.handleClick}
                    testQuestionId={this.props.questionState.testQuestionId}
                  />
                </Grid>
              </Grid>
              <DialogComponent
                open={this.state.boosterDialog}
                dialogContent={{
                  icon: <img src={RocketIcon} alt="" />,
                  title: t(`You are current on the track to boost your score`),
                  body: t('Try pushing your limits to give your best'),
                  button1: t('Quit for now'),
                  button2: t('Continue'),
                }}
                handleButton1Click={this.handleButton1Click}
                handleButton2Click={() =>
                  this.setState({ boosterDialog: false })
                }
              />
            </Container>
          )}
        </>
      );
    else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    studyPlanSubjects: state.DashboardQuestionBankReducer.studyPlanSubjects,
    studyPlanConcepts: state.DashboardQuestionBankReducer.studyPlanConcepts,
    studyPlanConceptTopics:
      state.DashboardQuestionBankReducer.studyPlanConceptTopics,
    studyPlanQuestions: state.DashboardQuestionBankReducer.studyPlanQuestions,
    attemptStatus: state.DashboardQuestionBankReducer.attemptStatus,
    difficultyLevel: state.DashboardQuestionBankReducer.difficultyLevel,
    questionState: state.DashboardQuestionBankReducer.questionBankState,
    allQuestionTypes: state.PracticeReducer.allQuestionTypes,
  };
};
export default connect(mapStateToProps, {
  getStudyPlanSubjects,
  getStudyPlanConcepts,
  getStudyPlanConceptTopics,
  getStudyPlanQuestions,
  questionBankState,
  getAllQuestionTypes,
})(withTranslation()(TotalQuestionBank));
