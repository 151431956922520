/**
 * (c) CareerLabs. All rights reserved.
 **/
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  authenticationRoute,
  AuthenticationRoute,
  routePaths,
  routes,
  layout,
} from '../routes/RoutePath';
import Header from '../utils/Header';

export default class Routes extends Component {
  render() {
    let logoutHiddenPaths = [
      authenticationRoute.path,
      ...AuthenticationRoute,
      routePaths.profileSetup,
    ];
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route {...authenticationRoute} />
            <Route
              path={'/'}
              render={props => (
                <>
                  {!(
                    props.location.pathname.startsWith(layout.gmat) ||
                    props.location.pathname.startsWith(layout.gre) ||
                    props.location.pathname.startsWith(layout.calibration)
                  ) && <Header />}
                  {Cookies.get('accessToken') ? (
                    routes.map(item => <Route {...item} {...props} />)
                  ) : (
                    <Redirect to={routePaths.login} />
                  )}
                </>
              )}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
