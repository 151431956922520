/**
 * (c) CareerLabs. All rights reserved.
 **/
import { IconButton } from '@material-ui/core';
import Button from '../../../utils/components/Button';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 40px;
  padding-top: 5px;
  position: absolute;
  background: linear-gradient(180deg, #eef6ff 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: -8px;
  overflow: auto;
  padding-bottom: 8vh;
`;

export const BackIcon = withStyles({
  root: {
    fill: '#1093ff',
  },
})(ArrowBackIcon);

export const IconButtonDiv = withStyles({
  root: {
    borderRadius: '40px',
    background: 'rgba(16, 147, 255, 0.2)',
    '&:hover': {
      background: 'rgba(16, 147, 255, 0.2)',
    },
    marginRight: '10px',
  },
})(IconButton);

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopicTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #052a4e;
`;

export const ShowButton = withStyles({
  root: {
    margin: '16px',
    padding: '11px 39px',
    background: '#1093FF',
    borderRadius: '30px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#F2F2F2',
    '&:hover': {
      background: '#1093FF',
    },
  },
})(Button);

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const GreenAlert = withStyles({
  root: {
    background: ' #92E3A9',
    alignItems: 'center',
    borderRadius: '8px',
  },
  message: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#00250B',
    paddingRight: '40px',
  },
  icon: {
    margin: '8px 24px',
    padding: '0 0',
  },
})(Alert);

export const RedAlert = withStyles({
  root: {
    background: '#F96332',
    alignItems: 'center',
    borderRadius: '8px',
  },
  message: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#00250B',
    paddingRight: '40px',
  },
  icon: {
    margin: '8px 24px',
    padding: '0 0',
  },
})(Alert);

export const ExplanationContainer = styled.div`
  margin-top: 23px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  width: 1015px;
  margin-bottom: 25px;
`;

export const ExplanationTitle = styled.div`
  padding-top: 24px;
  margin-left: 17px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #052a4e;
`;

export const Explanation = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  padding-left: 20px;
  padding-bottom: 20px;
`;
