/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, Card } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Container,
  BeforeIcon,
  Header,
  TopicTitle,
  ReportTime,
  Section,
  Title,
  DownloadButton,
  ExcelButton,
} from '../../../../../assets/css/GmatOgReportStyle';
import BackArrow from '../../../../../assets/images/back_arrow_large.png';
import { QS_PROPS } from '../../../../../constant/Variables';
import * as track from '../../../../../mixpanel/Events';
import { getInsights } from '../../../../../redux/action/Course';
import { getCourses } from '../../../../../redux/action/Profile';
import PageLoader from '../../../../../utils/components/PageLoader';
import GmatReportCard from '../gmatReport/GmatReportCard';
import {
  getGmatOgReport,
  getOverAllSummary,
} from '../../../../../redux/action/OfficialGuide';
import GmatError from '../gmatReport/GmatError';
import { createPdfFromHtml } from './OverAllSummaryPdf';
import { routePaths } from '../../../../../routes/RoutePath';

class OverAllGmatReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      variantIndex: 0,
      isLoading: false,
      variantName: '',
      spentTime: new Date(),
      isGmatOGReportCompleted: false,
    };
  }

  componentDidMount() {
    this.props.getOverAllSummary(response => {
      // console.log(res);
      this.setState({
        data: response.data,
        isGmatOGReportCompleted: true,
      });
    });
  }

  handleDownload = () => {
    var node = window.document.getElementById(
      'Gmat_og_over_all_summary_download_container'
    );
    var selectorNode = node.parentNode.querySelector('.study__plan__button');
    if (selectorNode) selectorNode.remove();
    createPdfFromHtml(node, 'Overall summary Report.pdf');
  };
  // handleExcelDownload = () => {
  //   const { testExecutionId } = QueryString.parse(
  //     this.props.location.search,
  //     QS_PROPS
  //   );
  //   this.props.getExcel(testExecutionId, res => {
  //     textToDownloadFile(res.data, 'Gre og practise test Report', 'xls');
  //   });
  // };
  handleClick = () => {
    // this.props.history.goBack();
    const { testExecutionId, test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (og_course === 'GMAT') {
      this.props.history.push(
        `${routePaths.dashboard.gmatOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
      );
    } else if (og_course === 'GRE') {
      this.props.history.push(
        `${routePaths.dashboard.greOgReport}?testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
      );
    } else {
      this.props.history.push(routePaths.dashboard.guide);
    }
  };
  render() {
    const { data, variantIndex } = this.state;
    if (this.state.data !== null) {
      const { t } = this.props;
      // const reportDate =
      //   this.props.overAllReport?.data[this.state.variantIndex];

      const isDownloadReport = this.state.isGmatOGReportCompleted;

      const Sheet = () => {
        return (
          <Container style={{ width: '98vw' }}>
            <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Header>
                    <BeforeIcon>
                      <img
                        src={BackArrow}
                        alt="BackArrow"
                        onClick={this.handleClick}
                      />
                    </BeforeIcon>
                    <Title>{t('Overall Summary')}</Title>
                  </Header>
                  <ReportTime>
                    {t(`Report Date : ${data[variantIndex]?.reportedDateStr}`)}
                  </ReportTime>
                  <div className=".study__plan__button">
                    {isDownloadReport && (
                      <DownloadButton
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={this.handleDownload}
                      >
                        {t('Download Report')}
                      </DownloadButton>
                    )}
                    {/* {isDownloadReport && (
                      <ExcelButton
                        color={'primary'}
                        variant={'contained'}
                        size={'large'}
                        onClick={this.handleExcelDownload}
                      >
                        {t('Download Excel Report')}
                      </ExcelButton>
                    )} */}
                  </div>
                </Grid>
              </>
            </Grid>
            <>
              <Section>
                <Grid container>
                  <Grid item md={12}>
                    <Box
                      textAlign={'center'}
                      display={'flex'}
                      justifyContent="center"
                      alignItems={'center'}
                    >
                      {data?.map((item, index) => (
                        <div
                          className={
                            this.state.variantIndex === index
                              ? 'nav__link__plain__active nav__link__plain'
                              : 'nav__link__plain'
                          }
                          onClick={() => {
                            this.setState({
                              variantIndex: index,
                            });
                          }}
                        >
                          {item?.title}
                        </div>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    {this.state.data && (
                      <GmatReportCard
                        overAllReport={
                          this.state.data[this.state.variantIndex] || {}
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Section>
            </>
            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex' }}>
                <TopicTitle>{'Topic-wise Report'}</TopicTitle>
                <TopicTitle style={{ color: '#1093FF', marginLeft: '30px' }}>
                  {'Accuracy %'}
                </TopicTitle>
              </div>
              <GmatError
                overAllReport={this.props.overAllReport}
                variantIndex={this.state.variantIndex}
              />
            </Grid>
          </Container>
        );
      };
      return (
        <div>
          <Sheet />
          <div style={{ position: 'fixed', top: '200vh' }}>
            <div id={'Gmat_og_over_all_summary_download_container'}>
              <Sheet />
            </div>
          </div>
        </div>
      );
    } else return <PageLoader />;
  }
}

const mapStateToProps = state => {
  return {
    report: state.CourseReducer.courseInsights,
    courses: state.profileReducer.courses,
    gmatOgReport: state.OfficialGuideReducer.gmatOgReport,
    overAllReport: state.OfficialGuideReducer.overAllReport,
  };
};

export default connect(mapStateToProps, {
  getInsights,
  getCourses,
  getGmatOgReport,
  getOverAllSummary,
})(withTranslation()(OverAllGmatReport));