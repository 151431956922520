/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { SliderContainer } from '../../assets/css/StyledComponent';
import img1 from '../../assets/images/rightSide/imgf1.svg';
import img2 from '../../assets/images/rightSide/imgf11.svg';
import img3 from '../../assets/images/rightSide/imgf21.svg';

class RightSide extends Component {
  arr = [img1, img2, img3];

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: 'linear',
    };
    const { t } = this.props;
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <h3 className="right__side__title">
            {'Welcome to CareerLabs LMS Platform'}
          </h3>
          <Box pb={2}>
            <h5 className="h5">
              {t(
                'Your Personalized space for GRE/GMAT/IELTS and TOEFL preparation'
              )}
            </h5>
          </Box>
          <SliderContainer>
            <div>
              <Slider {...settings}>
                {this.arr.map((item, index) => (
                  <div key={index}>
                    <Box display={'flex'} justifyContent="center">
                      <img alt="" src={item} />
                    </Box>
                    <Box width={286} pt={3}>
                      <label className="right__side__sub__title">
                        {t(
                          'Customized as per your target score, your skills, & your pace'
                        )}
                      </label>
                    </Box>
                  </div>
                ))}
              </Slider>
              <Box display={'flex'} pt={1} justifyContent="flex-end">
                <Box>{/* <img src={googlePlay} alt="" /> */}</Box>
                <Box pl={1}>{/* <img src={appStore} alt="" /> */}</Box>
              </Box>
            </div>
          </SliderContainer>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(RightSide);
