/**
 * (c) CareerLabs. All rights reserved.
 **/
import { COLLEGE, PROFILE } from '../Action';

const initialState = {
  university: [],
  department: [],
  college: [],
  degree: [],
  personalInfo: null,
  employeeInfo: [],
  educationalInfo: [],
  profileImage: null,
  courses: null,
  passwordChangeRespone: [],
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLEGE.getAspirationUniversity: {
      return {
        ...state,
        university: action.payload,
      };
    }
    case PROFILE.updateProfile: {
      return {
        ...state,
        personalInfo: action.payload,
      };
    }
    case PROFILE.updateEmployeeInfo: {
      return {
        ...state,
        employeeInfo: action.payload,
      };
    }
    case PROFILE.updateEducationalInfo: {
      return {
        ...state,
        educationalInfo: action.payload,
      };
    }
    case COLLEGE.getDegree: {
      return {
        ...state,
        degree: action.payload,
      };
    }
    case COLLEGE.getCollege: {
      return {
        ...state,
        college: action.payload,
      };
    }
    case PROFILE.getProfileImage: {
      return {
        ...state,
        profileImage: action.payload,
      };
    }
    case COLLEGE.getDepartment: {
      return {
        ...state,
        department: action.payload,
      };
    }
    case COLLEGE.getUniversity: {
      return {
        ...state,
        university: action.payload,
      };
    }
    case PROFILE.getCourses: {
      return {
        ...state,
        courses: action.payload,
      };
    }
    case PROFILE.changePassword: {
      return {
        ...state,
        passwordChangeRespone: action.payload,
      };
    }
    default:
      break;
  }
  return state;
};

export default ProfileReducer;
