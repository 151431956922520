/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { ProfileCardBox } from '../../../../assets/css/StyledComponent';
import facebookIcon from '../../../../assets/images/fb.svg';
import linkedInIcon from '../../../../assets/images/linkedIn.svg';
import twitterIcon from '../../../../assets/images/twitter.svg';
import ProfileViewCard from '../../../../utils/ProfileViewCard';

export default class PersonalDetail extends Component {
  render() {
    const {
      name,
      address,
      dob,
      email,
      phoneNumber,
      facebook,
      linkedIn,
      twitter,
      t,
    } = this.props;

    return (
      <div>
        <ProfileViewCard>
          <Grid container className={'profile__detail__card'}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <h2 className="profile__detail__card">{name}</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <h6 className="profile__detail__card">{address}</h6>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={6} md={2} xl={2} lg={2}>
              <div className="flex-column">
                <h5 className="profile__detail__card">{t('Date of Birth')}</h5>
                <h4 className="profile__detail__card">{dob}</h4>
              </div>
            </Grid>
            <Grid item xs={8} sm={6} md={6} xl={3} lg={3}>
              <div className="flex-column">
                <h5 className="profile__detail__card">{t('Email ID')}</h5>
                <h4 className="profile__detail__card">{email}</h4>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} lg={3}>
              <ProfileCardBox pl={2}>
                <div className="flex-column">
                  <h5 className="profile__detail__card">
                    {t('Mobile Number')}
                  </h5>
                  <h4 className="profile__detail__card">{phoneNumber}</h4>
                </div>
              </ProfileCardBox>
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} lg={3}>
              <div className="flex-column">
                {(linkedIn || twitter || facebook) && (
                  <h5 className="profile__detail__card">
                    {t('Social Media URL')}
                  </h5>
                )}
                <Box flex>
                  {linkedIn && linkedIn.trim().length !== 0 && (
                    <IconButton onClick={() => window.open(linkedIn)}>
                      <img alt="" src={linkedInIcon} />
                    </IconButton>
                  )}
                  {twitter && twitter.trim().length !== 0 && (
                    <IconButton onClick={() => window.open(twitter)}>
                      <img alt="" src={twitterIcon} />
                    </IconButton>
                  )}

                  {facebook && facebook.trim().length !== 0 && (
                    <IconButton onClick={() => window.open(facebook)}>
                      <img alt="" src={facebookIcon} />
                    </IconButton>
                  )}
                </Box>
              </div>
            </Grid>
          </Grid>
        </ProfileViewCard>
      </div>
    );
  }
}
