import { Box, Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DoneButton,
  InsideBox,
  NoteImg,
  ReportContainer,
  ReportDate,
  ReportTitle,
  SubTitle,
  ViewContainer,
} from '../../../assets/css/report/Report';
import PageLoader from '../../../utils/components/PageLoader';
import notepad from '../../../assets/images/notepad.png';
import { MONTHS, QS_PROPS } from '../../../constant/Variables';
import * as track from '../../../mixpanel/Events';
import { getQbankReport } from '../../../redux/action/Dashboard';
import { routePaths } from '../../../routes/RoutePath';
import InsightsCard from './InsightsCard';
import ReviewCard from './ReviewCard';
import { createPdfFromHtml } from '../../../utils/PdfMaker';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: '',
      loading: true,

      data: {
        title: 'Report',
        test: {
          name: 'Test 2 Arithmetic',
          reportTitle: 'Report Date',
          reportDate: '14 Jun 2021',
        },
        subTitle: {
          title1: 'Insights',
          title2: 'Review',
        },
      },
    };
  }

  componentDidMount() {
    const { testExecutionId, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    track.reportFirstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Question Bank',
    });

    this.props.getQbankReport(testExecutionId, response => {
      this.setState({
        reports: response.data,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    let timeSpend = new Date() - this.state.spentTime;
    var timestamp = timeSpend / 1000;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var second = timestamp % 60;
    function pad(d) {
      return d < 10 ? '0' + d.toString() : d.toString();
    }
    track.reportSessionTime({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Question Bank',
      'Total Time Spend': `${pad(hours)} : ${pad(minutes)} : ${pad(second)}`,
    });

    track.reportFirstAction(
      {
        'Product Name': JSON.parse(localStorage.getItem('userProduct'))
          .productName,
        'Test Type': 'Topic test',
        'First Action': 'Done',
      },
      'Topic'
    );
  }

  handleClick = topicId => {
    track.reportFirstAction({
      'Product Name': JSON.parse(localStorage.getItem('userProduct'))
        .productName,
      'Test Type': 'Question Bank',
      'First Action': 'Done',
    });

    const boostScore = localStorage.getItem('boostScore');
    const questionBank = localStorage.getItem('questionBank');
    // Diagnostic engine path

    const { test_type, qbank_type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    if (test_type === 'QUESTIONBANK') {
      if (qbank_type === 'QUESTION') {
        if (questionBank) {
          this.props.history.push(
            `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
          );
          localStorage.removeItem('questionBank');
        } else this.props.history.push(routePaths.dashboard.solveByQuestion);
        // localStorage.setItem('viewExplanation1', 'viewExplanation1');
      } else if (qbank_type === 'PASSAGE') {
        this.props.history.push(routePaths.dashboard.solveByPassage);
        // localStorage.setItem('viewExplanation', 'viewExplanation');
      }
    } else if (boostScore) {
      this.props.history.push(
        `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
      );
      localStorage.removeItem('boostScore');
    }

    // Regular topic test path
    else
      this.props.history.push(
        `${routePaths.dashboard.topicTest}?topicId=${topicId}`
      );
  };

  handleDownload = () => {
    var node = window.document.getElementById(
      'Gmat_og_practise_test_download_container'
    );
    var selectorNode = node.parentNode.querySelector('.study__plan__button');
    if (selectorNode) selectorNode.remove();
    createPdfFromHtml(node, 'QuestionBank test Report.pdf');
  };

  render() {
    const breadcrumbs = [
      <Link>
        <Typography
          color="text.primary"
          style={{ fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}
          onClick={() => this.handleClick(reports.topicId)}
        >
          {'QuestionBank'}
        </Typography>
      </Link>,
      <Typography
        color="text.primary"
        style={{ fontWeight: '600', fontSize: '14px' }}
      >
        {'Report'}
      </Typography>,
    ];
    const { reports } = this.state;
    const { data } = this.state;
    let date = new Date(this.state.reports.reportedDate);
    let month = date.getMonth();

    let year = date.getFullYear();
    let date1 = date.getDate();

    let monthName = MONTHS[month];
    let startDate = date1 + ' ' + monthName + ' ' + year;
    if (this.state.loading) {
      return <PageLoader />;
    } else {
      return (
        <div id="Gmat_og_practise_test_download_container">
          <ViewContainer>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <ReportContainer>
                  <ReportTitle>
                    <Box style={{ marginBottom: '0.5em' }}>
                      <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="2rem" />}
                        aria-label="breadcrumb"
                      >
                        {breadcrumbs}
                      </Breadcrumbs>
                    </Box>
                    <NoteImg src={notepad} />

                    <span>{`${data.title} - ${reports.title || ''}`}</span>
                  </ReportTitle>

                  <ReportDate>
                    {`${data.test.reportTitle}: ${startDate}`}
                  </ReportDate>
                </ReportContainer>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ReportContainer>
                  <InsideBox>
                    <SubTitle>{data.subTitle.title1}</SubTitle>
                  </InsideBox>
                  <InsideBox style={{ marginTop: '-2em' }}>
                    <DoneButton
                      // onClick={() => this.handleClick(reports.topicId)}
                      onClick={this.handleDownload}
                    >
                      Download
                    </DoneButton>
                  </InsideBox>
                </ReportContainer>
                {this.state.reports && (
                  <InsightsCard item={this.state.reports} />
                )}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <SubTitle>{data.subTitle.title2}</SubTitle>
                {this.state.reports && (
                  <ReviewCard items={this.state.reports} {...this.props} />
                )}
              </Grid>
            </Grid>
          </ViewContainer>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    reportResponse: state.DashBoardReducer.report,
  };
};

export default connect(mapStateToProps, {
  getQbankReport,
})(Report);
