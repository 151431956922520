/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  BodyText,
  Date,
  Description,
  TopicHeadingText,
  TopicStyles,
} from '../../../assets/css/subject/LandingComponents';

class Topic extends Component {
  render() {
    const {
      image,
      title,
      duration,
      completedTopics,
      totalTopics,
      description,
    } = this.props.concept;
    const { index, activeConcept, handleClick, t } = this.props;

    return (
      <Container maxWidth="xl">
        <Grid container alignItems="stretch">
          {/* Topic Card */}
          <Grid item xs={12} container alignItems="center">
            {/* Topic Icon */}
            <img src={image} alt={'icon'} className={'clock-image-space'} />
            <TopicHeadingText>{title}</TopicHeadingText>
            <div style={{ flexGrow: 1 }} />
            {/* Clock Icon */}
            <QueryBuilderIcon color="primary" />
            <BodyText>{duration}</BodyText>
            <Date>
              {completedTopics} / {totalTopics}
              &nbsp;{t('Completed')}
            </Date>
          </Grid>
          <Grid id="content-description" item xs={12}>
            <Description>{description}</Description>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Box
              display="flex"
              alignItems="stretch"
              style={{ cursor: 'pointer' }}
              onClick={handleClick(index)}
              id="center-box"
            >
              <Typography id="topic-button">
                {t(`${activeConcept === index ? 'Hide' : 'View'} Topics`)}
              </Typography>
              <ExpandMoreRoundedIcon
                className={
                  activeConcept === index
                    ? 'topic-button-arrow-active topic-button-arrow'
                    : 'topic-button-arrow'
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(TopicStyles)(withTranslation()(Topic));
