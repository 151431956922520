/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GraphPopperBox,
  GraphPopperPercentage,
  GraphPopperText1,
  GraphPopperText2,
} from '../../../assets/css/StyledDashboardComponent';

export default props => {
  const graphData = props.graphData;

  const {
    graphAnchorEl,
    graphPopperIndex,
    handleGraphPopperClose,
    handleGraphPopperOpen,
    anchorEl,
  } = props;

  const data = graphData.map(item => {
    var xAxis = isNaN(item.percentage) ? 0 : item.percentage;
    return { name: `${item.weekTitle}`, uv: xAxis, pv: item.week, dv: 100 };
  });

  const yAxisTickFormatter = value => (value !== 0 ? `${value}%` : value);

  const GraphActiveDot = () => ({
    r: 8,
    fill: '#FFFFFF',
    stroke: '#FFBF00',
    fillOpacity: 1,
    strokeWidth: 3,
    cursor: 'pointer',
    onMouseMoveEnter: handleGraphPopperOpen,
    // When mouse enter the inside of the circle
    onMouseMove: handleGraphPopperOpen,

    onMouseLeave: handleGraphPopperClose,
  });

  const GraphPopper = ({ viewBox }) => (
    <g>
      <foreignObject
        x={viewBox.x - 79}
        y={
          graphAnchorEl && isNaN(graphData[graphPopperIndex]['percentage'])
            ? `${viewBox.y - 108}`
            : graphData[graphPopperIndex]['percentage'] !== 0
            ? `${viewBox.y - 115}`
            : `${viewBox.y - 107}`
        }
        width={160}
        height={100}
        className="rechart_label_wrapper"
      >
        {graphAnchorEl && graphData[graphPopperIndex] && (
          <GraphPopperBox
            color={graphData[graphPopperIndex]['colorCode']}
            anchorEl={anchorEl}
            open={anchorEl}
            placement="top"
          >
            <GraphPopperPercentage>
              {isNaN(graphData[graphPopperIndex]['percentage'])
                ? `0%`
                : `${parseInt(graphData[graphPopperIndex]['percentage'])}%`}
            </GraphPopperPercentage>
            <GraphPopperText1>
              {graphData[graphPopperIndex]['title']}
            </GraphPopperText1>
            <GraphPopperText2>
              {graphData[graphPopperIndex]['subtitle']}
            </GraphPopperText2>
          </GraphPopperBox>
        )}
      </foreignObject>
    </g>
  );

  return (
    <div className="rechart__container">
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 35,
            right: 65,
            left: 19,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="4 3"
            stroke={'rgba(249, 99, 50, 0.5)'}
            xAxis={{ startOffset: 50 }}
          />
          <XAxis
            dataKey="name"
            tickLine={false}
            stroke="#FFBF00"
            strokeWidth={1}
            strokeDasharray={'15 15'}
            tick={{ fill: '#3A3A3A' }}
            tickMargin={10}
          />
          <YAxis
            tickFormatter={yAxisTickFormatter}
            tickLine={false}
            textAnchor={'left'}
            stroke="#FFBF00"
            strokeWidth={1}
            tickMargin={50}
            tick={{ fill: '#3A3A3A' }}
            type="number"
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
          />

          <defs>
            <linearGradient id="splitColor" x1="0" y1="1" x2="0" y2="0">
              <stop offset={'0%'} stopColor="#FFE79F" stopOpacity={1} />
              <stop offset={'52.38%'} stopColor="#FFFFFF" stopOpacity={1} />
              <stop offset={'164.87%'} stopColor="#FFBF00" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="uv"
            strokeWidth={2}
            stroke="#FFBF00"
            fill="url(#splitColor)"
            fillOpacity={0.8}
            dot={GraphActiveDot()}
            activeDot={GraphActiveDot()}
            isAnimationActive={false}
          />
          {graphAnchorEl && graphData[graphPopperIndex] && (
            <ReferenceLine
              segment={[
                {
                  x: graphData[graphPopperIndex]['weekTitle'],
                  y: isNaN(graphData[graphPopperIndex]['percentage'])
                    ? 0
                    : graphData[graphPopperIndex]['percentage'] > 5
                    ? graphData[graphPopperIndex]['percentage'] - 5
                    : 0,
                },
                {
                  x: graphData[graphPopperIndex]['weekTitle'],
                  y: 0,
                },
              ]}
              stroke="#FFBF00"
              strokeWidth={3}
              ifOverflow="extendDomain"
            >
              <Label content={<GraphPopper />} position={'top'} />
            </ReferenceLine>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
