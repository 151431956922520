import { Box, Grid } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import {
  Bullet,
  CardContainer,
  CardShadow,
  InputContainer,
  SubHeading,
  SubPara,
  TopGridSubPara,
  TopSubPara,
  TopTitle,
} from '../../../../assets/css/StyledComponent';
import {
  BackIcon,
  IconButtonDiv,
} from '../../../../assets/css/subject/QuestionBankComponents';
import { IMAGE_URL, QS_PROPS } from '../../../../constant/Variables';
import { getCourseImage } from '../../../../redux/action/Course';
import { topicTestInstruction } from '../../../../redux/action/Dashboard';
import { routePaths } from '../../../../routes/RoutePath';
import ContinueInstruction from './Continue';
import ReTakeInstruction from './Retake';
import StartInstruction from './Start';

class Instruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: '',
      instructions: '',
    };
  }

  componentDidMount() {
    const { testQuestionId, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const official_guide_gmat_question = sessionStorage.getItem(
      'official_guide_gmat_question'
    )
      ? JSON.parse(sessionStorage.getItem('official_guide_gmat_question'))
      : [];
    const combination = sessionStorage.getItem('combination')
      ? JSON.parse(sessionStorage.getItem('combination'))
      : {};

    this.props.topicTestInstruction(
      testQuestionId,
      og_course,
      official_guide_gmat_question,
      combination,
      response => {
        this.setState({
          instructions: response.data,
        });
      }
    );
    const { instructions } = this.state;
    if (instructions.timer === 'timer') {
      localStorage.removeItem('lmsTimerpopup');
    }
    let obj = {
      courseId: JSON.parse(window.localStorage.getItem('userProduct')).id,
      endPoint: 'instructions',
    };
    this.props.getCourseImage(obj, response => {
      this.setState({ img: IMAGE_URL + response.data.value });
    });
  }

  handleClick = (testQuestionId, status) => {
    const { og_course, diagnostic, topicId, subject, type } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (og_course) {
      if (status === 'COMPLETED')
        this.props.history.push(
          `${routePaths.exam}?testQuestionId=${testQuestionId}&status=RETAKE&og_course=${og_course}`
        );
      else
        this.props.history.push(
          `${routePaths.exam}?testQuestionId=${testQuestionId}&og_course=${og_course}`
        );
    } else {
      if (status === 'COMPLETED')
        if (subject === 'subject')
          this.props.history.push(
            `${routePaths.exam}?testQuestionId=${testQuestionId}&status=RETAKE&subject=subject&topicId=${topicId}&type=${type}`
          );
        else {
          this.props.history.push(
            `${routePaths.exam}?testQuestionId=${testQuestionId}&status=RETAKE&topicTest=Topictest`
          );
        }
      else if (diagnostic === 'diagnostic-engine') {
        this.props.history.push(
          `${routePaths.exam}?testQuestionId=${testQuestionId}&diagnostic=diagnostic-engine&topicId=${topicId}`
        );
      } else if (subject === 'subject')
        this.props.history.push(
          `${routePaths.exam}?testQuestionId=${testQuestionId}&subject=subject&topicId=${topicId}&type=${type}`
        );
      else
        this.props.history.push(
          `${routePaths.exam}?testQuestionId=${testQuestionId}&topicTest=Topictest`
        );
    }
  };

  handleBack = () => {
    const { og_course, topicTest, subject, type, topicId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    if (og_course === 'GRE' || og_course === 'GMAT') {
      this.props.history.push(routePaths.dashboard.guide);
    } else if (topicTest === 'Topictest') {
      this.props.history.push(routePaths.dashboard.topic);
    } else if (subject === 'subject') {
      this.props.history.push(
        `${routePaths.dashboard.topicTest}?topicId=${topicId}&subject=subject&type=${type}`
      );
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const { og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const { t } = this.props;
    console.log('hiiiii');
    return (
      <div>
        <>
          <Box pt={1} pb={4} className={'topic_test_div'}>
            <Grid
              container
              alignItems="center"
              direction="column"
              justify="center"
            >
              <InputContainer>
                {/* test title */}
                <Grid container>
                  <Grid item md={2} xs={2} sm={2} xl={2}>
                    <Box pt={3}>
                      <IconButtonDiv
                        onClick={this.handleBack}
                        // () => this.props.history.goBack()}
                      >
                        <BackIcon />
                      </IconButtonDiv>
                    </Box>
                  </Grid>

                  <Grid item md={8} xs={8} sm={8} xl={8}>
                    <Box pt={3}>
                      <TopTitle>{this.state.instructions.title}</TopTitle>
                    </Box>
                  </Grid>
                </Grid>
                {/* test subtitle */}
                <Grid item xs={12}>
                  <Box pt={0}>
                    <TopSubPara>
                      {this.state.instructions.titleDescription}
                    </TopSubPara>
                  </Box>
                </Grid>
                {/* question and time */}
                <Box pt={3} pb={3}>
                  {this.state.instructions.status === 'START' ? (
                    <StartInstruction
                      data={this.state.instructions}
                      onClick={this.handleClick}
                      og_course={og_course}
                    />
                  ) : this.state.instructions.status === 'PROGRESS' ? (
                    <ContinueInstruction
                      data={this.state.instructions}
                      onClick={this.handleClick}
                    />
                  ) : this.state.instructions.status === 'COMPLETED' ? (
                    <ReTakeInstruction
                      data={this.state.instructions}
                      onClick={this.handleClick}
                    />
                  ) : (
                    ''
                  )}
                </Box>
                {/* instruction */}
                <CardShadow>
                  <CardContainer>
                    <div className={'card__image'}>
                      <Grid item md={9} xs={12}>
                        <Box pb={2}>
                          {' '}
                          <SubHeading>{t('Test Instructions')}</SubHeading>
                        </Box>
                        <Box pb={2}>
                          <TopGridSubPara>
                            {this.state.instructions.instructionTitle}
                          </TopGridSubPara>
                        </Box>

                        {this.state.instructions &&
                          this.state.instructions.instruction.map(item => (
                            <Box pb={2}>
                              <div className={'bullet_with__text'}>
                                {' '}
                                <div>
                                  {' '}
                                  <Bullet />
                                </div>{' '}
                                <SubPara>{item}</SubPara>
                              </div>
                            </Box>
                          ))}
                      </Grid>
                      <Grid item md={3} xs={12} className={'btn__container'}>
                        <img src={this.state.img} alt={''}></img>
                      </Grid>
                    </div>
                  </CardContainer>
                </CardShadow>
              </InputContainer>
            </Grid>
          </Box>
        </>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    instructionResponse: state.DashBoardReducer.topicTest,
  };
};

export default connect(mapStateToProps, {
  topicTestInstruction,
  getCourseImage,
})(withTranslation()(Instruction));
