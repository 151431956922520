/**
 * (c) CareerLabs. All rights reserved.
 **/
import { TableBody, TableCell, Tooltip, Typography } from '@material-ui/core';
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';

export const colorScheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        color: '#052A4E',
      },
    },
  },
  palette: {
    primary: { main: '#1093FF' },
    secondary: { main: '#052A4E' },
  },
  typography: {
    h4: {
      fontSize: '34px',
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle2: {
      color: '#525D68',
    },
  },
});
export const buttonTheme = createMuiTheme({
  palette: {
    primary: { main: '#1093FF' },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
        width: 363,
        textTransform: 'initial',
      },
    },
  },
});

export const _buttonTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        backgroundColor: '#1093FF',
        // marginTop:'5vh'
      },
      containedPrimary: {
        backgroundColor: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
        },
      },
    },
  },
});

export const useStyles = makeStyles(() => ({
  boxStyle: {
    width: '66px !important',
    height: '56px',
    padding: '1%',
    marginRight: 10,
    background: '#FFFFFF',
    border: '0.5px solid #A8A8A8',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  accountStyle: {
    textAlign: 'center',
    color: 'black',
    fontSize: '14px',
    textDecoration: 'underline',
    '& span': {
      color: '#00A2FD',
      textDecoration: 'underline',
    },
  },
  underLine: {
    textDecoration: 'underline',
  },
  imageStyle: {
    height: '385px',
    width: '388px',
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: '#cccccc',
  },
  dividerhead: {
    flexDirection: 'row',
  },
  error: {
    color: '#f44336',
  },
}));

export const otpBoxStyle = {
  width: '66px !important',
  height: '56px',
  padding: '1%',
  marginRight: 10,
  background: '#FFFFFF',
  // border: '0.5px solid #A8A8A8',
  boxSizing: 'border-box',
  borderRadius: 4,
};

// Course Path Styles

export const courseIntroStyles = theme => ({
  footerStyle: {
    background:
      'linear-gradient(0deg, rgba(255, 213, 0, 0.1) 0%, rgba(255, 233, 119, 0) 100%) !important',
  },
  footBar: {
    background: '#F4F4F4',
    top: 'auto',
    bottom: 0,
    textAlign: 'center',
    padding: '2px',
  },
  footBarText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#121212',
  },
  [theme.breakpoints.down('md')]: {
    gridItem: {
      alignItems: 'center',
      justify: 'center !important',
    },
  },
  submitButtonStyle: {
    width: '278px',
    height: '48px',
    background: '#1093FF',
    borderRadius: '30px',
  },
});

// export const submitButtonTheme = createMuiTheme({
//   palette: {
//     primary: { main: '#1093FF' },
//     secondary: { main: '#052A4E' },
//   },
// });

export const submitButtonTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '30px',
        backgroundColor: '#1093FF !important',
        color: 'white !important',
        width: '200px',
        height: '40px',
        textTransform: 'initial',
      },
      containedPrimary: {
        backgroundColor: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
        },
      },
    },
  },
});

export const reviewTheme = createMuiTheme({
  palette: {
    primary: { main: '#1093FF' },
    secondary: { main: '#052A4E' },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export const filledButtonTheme = createMuiTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        borderRadius: '30px !important',
        backgroundColor: '#1093FF !important',
        color: 'white !important',
        width: '317px',
      },
      containedPrimary: {
        backgroundColor: '#1093FF',
        '&:hover': {
          backgroundColor: '#1093FF',
        },
      },
    },
    MuiButton: {
      label: {
        fontSize: '16px',
      },
    },
  },
});

// Review Page Components
export const TableHeaderCell = withStyles(theme => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#052A4E',
    textAlign: 'center',
    border: '0',
  },
}))(TableCell);

export const TableHeader = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    borderRadius: '0px 12px 12px 12px',
    border: '0 0 0 0',
  },
}))(MuiTableHead);

export const TableBodyStyle = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    // boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
  },
}))(TableBody);

export const TableItemBlue = withStyles(() => ({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#052A4E',
  },
}))(TableCell);

export const TableItemUnderline = withStyles(() => ({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#052A4E',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))(TableCell);

export const TableItemBlack = withStyles(() => ({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000',
  },
}))(TableCell);

// Circle Progress Page components

export const CircleHead = withStyles(() => ({
  root: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '34px',
    textAlign: 'center',
    color: '#052A4E', //blue
  },
}))(Typography);

export const CircleText = withStyles(() => ({
  root: {
    fontSize: '22px',
    lineHeight: '27px',
    textAlign: 'center',
    color: '#82828E', // grey
  },
}))(Typography);

export const dotTheme = createMuiTheme({
  overrides: {
    MuiBadge: {
      anchorOriginTopLeftRectangle: {
        transform: 'scale(1) translate(110%, 145%) !important',
      },
    },
  },
});

// Intro scren hat
// export const badgeTheme = createMuiTheme({
//   overrides: {
//     MuiBadge: {
//       anchorOriginTopLeftRectangle: {
//         transform: 'scale(1) translate(-32%, 80%)',
//       },
//     },
//   },
// });

export const StatusToolTip = withStyles({
  tooltip: {
    backgroundColor: '#ffffff',
    boxShadow: '1px 1px 6px 4px rgba(1, 54, 3, 0.25)',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600,
    color: '#052A4E', //blue
    padding: '16px',
  },
  // popper: {
  //   transform: 'scale(10) translate(900%,)',
  // },
  arrow: {
    color: '#ffffff',
  },
})(Tooltip);

// Result Report Page
export const TableHeadCell = withStyles(theme => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#052A4E',
    textAlign: 'center',
    border: '0',
    padding: '32px 16px 12px',
  },
}))(TableCell);
