/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { primaryButtonTheme } from '../../../../assets/css/MuiStyles';
import {
  NextButton,
  PreviousButton,
} from '../../../../assets/css/StyledComponent';
import {
  getCollege,
  getDegree,
  getDepartment,
  getUniversity,
  updateEducationInfo,
} from '../../../../redux/action/Profile';
import { getStudentInfo } from '../../../../redux/action/Student';
import { routePaths } from '../../../../routes/RoutePath';
import DatePicker from '../../../../utils/components/DatePicker';
import Autocomplete from '../../../../utils/components/DropDown';
import TextField from '../../../../utils/components/TextField';
import { dateFormat } from '../../../../utils/methods/DateFormat';
import { isEmpty } from '../../../../utils/Validation';

class EducationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      degreeType: null,
      degreeTypeErr: false,
      degreeTypeMsg: '',
      college: null,
      collegeErr: false,
      collegeMsg: '',
      startDate: '',
      startDateErr: false,
      startDateMsg: '',
      EndDate: '',
      EndDateMsg: '',
      EndDateErr: false,
      department: null,
      departmentErr: false,
      departmentMsg: '',
      university: null,
      universityErr: '',
      universityMsg: '',
    };
  }

  componentDidMount() {
    this.props.getDegree();
    this.props.getCollege();
    this.props.getDepartment();
    this.props.getUniversity();
    this.props.getStudentInfo();
  }

  handleSubmit = () => {
    const { degreeType, college, startDate, EndDate, department, university } =
      this.state;
    var valid = false;
    if (isEmpty(startDate)) {
      this.setState({
        startDateErr: true,
        startDateMsg: 'Please fill the required field',
      });
      valid = true;
    } else if (new Date(this.state.startDate).getFullYear() < 1950) {
      this.setState({
        startDateErr: true,
        startDateMsg: 'Date of birth can be beyond 1st Jan 1950',
      });
      valid = true;
    } else {
      this.setState({
        startDateErr: false,
        startDateMsg: '',
      });
    }

    if (this.state.degreeType === null) {
      this.setState({
        degreeTypeErr: true,
        degreeTypeMsg: 'Please fill the required field',
      });
      valid = true;
    }

    if (this.state.college === null) {
      this.setState({
        collegeErr: true,
        collegeMsg: 'Please fill the required field',
      });
      valid = true;
    }

    if (this.state.department === null) {
      this.setState({
        departmentErr: true,
        departmentMsg: 'Please fill the required field',
      });
      valid = true;
    }

    if (this.state.university === null) {
      this.setState({
        universityErr: true,
        universityMsg: 'Please fill the required field',
      });
      valid = true;
    }

    if (isEmpty(EndDate)) {
      this.setState({
        EndDateErr: true,
        EndDateMsg: 'Please fill the required field',
      });
      valid = true;
    } else if (new Date(this.state.EndDate).getFullYear() < 1950) {
      this.setState({
        EndDateErr: true,
        EndDateMsg: 'Date of birth can be beyond 1st Jan 1950',
      });
      valid = true;
    } else if (this.state.startDate >= this.state.EndDate) {
      this.setState({
        EndDateErr: true,
        EndDateMsg: 'End date should be greater than Start date',
      });
      valid = true;
    } else {
      this.setState({
        EndDateErr: false,
        EndDateMsg: '',
      });
    }

    if (
      !isEmpty(degreeType) &&
      !isEmpty(college) &&
      !isEmpty(department) &&
      !isEmpty(university) &&
      !isEmpty(startDate) &&
      !isEmpty(EndDate) &&
      !(new Date(this.state.startDate).getFullYear() < 1950) &&
      !(new Date(this.state.EndDate).getFullYear() < 1950)
    ) {
      let obj = {
        degree: this.state.degreeType,
        college: this.state.college,
        department: this.state.department,
        university: this.state.university,
        startDate: dateFormat(new Date(this.state.startDate)),
        endDate: dateFormat(new Date(this.state.EndDate)),
      };
      if (!valid) {
        this.props.updateEducationInfo(obj, res => {
          this.props.getStudentInfo();
          this.props.history.push(routePaths.profileDetail);
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      const { degree, college, startDate, endDate, department, university } =
        this.props.user;
      this.setState({
        degreeType: degree,
        college: college,
        department: department,
        university: university,
        startDate: startDate ? new Date(startDate) : '',
        EndDate: endDate ? new Date(endDate) : '',
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container className={'grid__container'} spacing={3}>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <Autocomplete
              id={'no-outline'}
              options={this.props.degree || []}
              getOptionLabel={option => option.name}
              fullWidth
              value={this.state.degreeType}
              onChange={(e, value) =>
                this.setState({
                  degreeType: value,
                  degreeTypeErr: false,
                  degreeTypeMsg: '',
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('Degree Type')}
                  required
                  variant="standard"
                  error={this.state.degreeTypeErr}
                  helperText={t(this.state.degreeTypeMsg)}
                  autoComplete={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <Autocomplete
              id={'no-outline'}
              creatable={true}
              options={this.props.college || []}
              // getOptionLabel={(option) => option.name}
              optionLabel={'name'}
              onChange={(e, value) =>
                this.setState({
                  college: value,
                  collegeErr: false,
                  collegeMsg: '',
                })
              }
              value={this.state.college}
              textProps={{
                required: true,
                label: t('College'),
                error: this.state.collegeErr,
                helperText: this.state.collegeMsg,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <Autocomplete
              id="no-outline"
              options={this.props.department || []}
              getOptionLabel={option => option.name}
              onChange={(e, value) =>
                this.setState({
                  department: value,
                  departmentErr: false,
                  departmentMsg: '',
                })
              }
              value={this.state.department}
              textProps={{
                label: t('Department'),
                required: true,
                error: this.state.departmentErr,
                helperText: this.state.departmentMsg,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <Autocomplete
              id="no-outline"
              options={this.props.university || []}
              getOptionLabel={option => option.name}
              onChange={(e, value) =>
                this.setState({
                  university: value,
                  universityErr: false,
                  universityMsg: '',
                })
              }
              value={this.state.university}
              textProps={{
                label: t('University'),
                required: true,
                error: this.state.universityErr,
                helperText: this.state.universityMsg,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                <DatePicker
                  id={'no-outline'}
                  onChange={date => {
                    this.setState({ startDateMsg: '', startDateErr: false });
                    this.setState({ startDate: date });
                  }}
                  fullWidth
                  value={this.state.startDate}
                  error={this.state.startDateErr}
                  helperText={t(this.state.startDateMsg)}
                  label={t('Start Date')}
                  disableFuture
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                <DatePicker
                  id={'no-outline'}
                  onChange={date => {
                    this.setState({ EndDateMsg: '', EndDateErr: false });
                    this.setState({ EndDate: date });
                  }}
                  fullWidth
                  value={this.state.EndDate}
                  error={this.state.EndDateErr}
                  helperText={t(this.state.EndDateMsg)}
                  label={t('End Date')}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container justify={'space-between'}>
            <Grid item xs={12} sm={4}>
              <ThemeProvider theme={primaryButtonTheme}>
                <PreviousButton
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    this.props.history.push(routePaths.employeeDetail)
                  }
                >
                  {t('Previous')}
                </PreviousButton>
              </ThemeProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              lg={6}
              xl={6}
              className={'button_alignment'}
            >
              <ThemeProvider theme={primaryButtonTheme}>
                <NextButton
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  {t('Submit')}
                </NextButton>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}></Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    educationalInfo: state.profileReducer.employeeInfo,
    degree: state.profileReducer.degree,
    college: state.profileReducer.college,
    department: state.profileReducer.department,
    university: state.profileReducer.university,
  };
};

export default connect(mapStateToProps, {
  updateEducationInfo,
  getDegree,
  getCollege,
  getUniversity,
  getDepartment,
  getStudentInfo,
})(withTranslation()(EducationDetail));
