/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Backdrop, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  CalendarGuide,
  PendingGuide,
  ProgressGuide,
  StrengthGuide,
  SubjectGuide,
  TopicGuide,
} from '../../../../assets/css/StyledDashboardComponent';
import CalendarPointArrow from '../../../../assets/images/CalendarPointArrow.png';
import ProgressArrow from '../../../../assets/images/ProgressArrow.png';
import StrengthWeaknessArrow from '../../../../assets/images/StrengthWeaknessArrow.png';
import TopicOfTheDayArrow from '../../../../assets/images/TopicOfTheDayArrow.png';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  paper: {
    zIndex: '1100 !important',
  },
});

/**
 *
 * @param {Boolean} isOpen to open the content
 * @returns
 */

function GuideContent({ isOpen, handleClick, focusComponent }) {
  useEffect(() => {
    var appBarElement = document.getElementById('app-bar-style');
    var headerBottomPaperElement = document.getElementById('main-container');
    if (document.querySelector('.user_guide'))
      document.querySelector('.user_guide').remove();

    if (focusComponent) {
      const newDiv = document.createElement('div');
      newDiv.className = 'user_guide';
      ReactDOM.render(
        renderArrowAndText(),
        document.querySelector(focusComponent).appendChild(newDiv)
      );

      if (focusComponent === '.on-focus-6th') {
        appBarElement.style.position = 'static';
        headerBottomPaperElement.style.paddingTop = 0;
      } else {
        appBarElement.style.position = 'fixed';
        headerBottomPaperElement.removeAttribute('style');
      }

      const element = document.querySelectorAll(focusComponent);

      // Adding the focus-on class when the user click the user guide
      [...element].map(item => {
        item.classList.add('focus-on');
      });

      // scrolling for the appropriate component

      if (focusComponent === '.on-focus-1st') {
        document.body.scroll({
          top: 120,
          left: 0,
          behavior: 'smooth',
        });
      }
      if (focusComponent === '.on-focus-2nd') {
        document.body.scroll({
          top: 520,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (focusComponent === '.on-focus-3rd') {
        document.body.scroll({
          top: 120,
          left: 0,
          behavior: 'smooth',
        });
      }

      if (focusComponent === '.on-focus-4th') {
        document
          .querySelector(focusComponent)
          .scrollIntoView({ behavior: 'smooth' });
      }

      if (focusComponent === '.on-focus-5th') {
        document
          .querySelector(focusComponent)
          .scrollIntoView({ behavior: 'smooth' });
      }

      if (focusComponent === '.on-focus-6th') {
        document.body.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }

      // Removing Focus class when the user click the user guide
      return () =>
        [...element].map(item => {
          item.classList.remove('focus-on');
        });
    } else {
      appBarElement.style.position = 'fixed';
      headerBottomPaperElement.removeAttribute('style');
    }
  }, [focusComponent]);

  const classes = useStyles();

  const renderArrowAndText = () => {
    switch (focusComponent) {
      case '.on-focus-1st': {
        return (
          <div>
            <img
              src={CalendarPointArrow}
              alt=""
              className="calendar-arrow-position"
            />
            <CalendarGuide className="guide-text">
              This Section has the Calender Showing the task for Each day.
            </CalendarGuide>
          </div>
        );
      }

      case '.on-focus-2nd': {
        return (
          <div>
            <img
              src={CalendarPointArrow}
              alt=""
              className="strength-weakness-arrow"
            />
            <StrengthGuide className="guide-text">
              This Section will show your Strengths & Weakness and how you can
              improve.
            </StrengthGuide>
          </div>
        );
      }

      case '.on-focus-3rd': {
        return (
          <div>
            <img
              src={ProgressArrow}
              alt=""
              className="topic-of-the-day-arrow"
            />
            <TopicGuide className="guide-text">
              You will get to see all your tasks lined for the day here. Please
              ensure to complete those on time.
            </TopicGuide>
          </div>
        );
      }

      case '.on-focus-4th': {
        return (
          <div>
            <img
              src={TopicOfTheDayArrow}
              alt=""
              className="pending-topic-arrow"
            />
            <PendingGuide className="guide-text">
              You will get to see the tasks which you were supposed to complete
              earlier but haven’t done yet.
            </PendingGuide>
          </div>
        );
      }

      case '.on-focus-5th': {
        return (
          <div>
            <img
              src={StrengthWeaknessArrow}
              alt=""
              className="progress-tracker-arrow"
            />
            <ProgressGuide className="guide-text">
              You will find your time spent learning the course here.
            </ProgressGuide>
          </div>
        );
      }

      case '.on-focus-6th': {
        return (
          <div>
            <img src={ProgressArrow} alt="" className="subject-arrow" />
            <SubjectGuide className="guide-text">
              You can access the entire course from here.
            </SubjectGuide>
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Backdrop
      className={`${classes.paper} ${classes.root}`}
      open={isOpen}
      onClick={handleClick}
    />
  );
}

export default GuideContent;
