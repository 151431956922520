/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import '../../assets/css/App.css';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  BodyText,
  DialogButtonBox,
  DialogDiv,
  DialogHeadText,
  IconContainer,
} from '../../assets/css/StyledComponent';



export default function DialogComponent(props) {
  const { open, dialogContent, handleButton1Click, handleButton2Click,handleCloseIconClick } = props;
  if (dialogContent !== null)
    return (
      <Dialog
        open={open}
        maxWidth={'sm'}
        PaperProps={{ style: { borderRadius: '8px', minWidth: '645px' } }}
        fullWidth
      >
        
        <DialogDiv>
       
          <Grid container direction="column" alignItems="center">
         
            <Grid item xs={12}>
              <IconContainer>{dialogContent.icon}</IconContainer>
            </Grid>
          </Grid>
          <DialogHeadText>{dialogContent.title}</DialogHeadText>
          {dialogContent.body !== undefined && (
            <BodyText>{dialogContent.body}</BodyText>
          )}
          {dialogContent.content && (<div>{dialogContent.content}</div>)}
          <DialogButtonBox>
            {!dialogContent.isSubmitOnly &&
            <><Button
              color="primary"
              variant="outlined"
              className={'button-style'}
              onClick={handleButton1Click}
            >
              {dialogContent.button1}
            </Button>
            <div style={{ width: '40px' }} />
            </>}
            <Button
              color="primary"
              variant="contained"
              className={'button-style'}
              onClick={handleButton2Click}
            >
              {dialogContent.button2}
            </Button>
          </DialogButtonBox>
        </DialogDiv>
      </Dialog>
    );
  else return null;
}
