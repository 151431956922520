/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid, Dialog, Box } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DialogBox,
  DialogButtonBox,
  DialogHeadText,
  FlexView,
  ReviseButton,
  Close,
  Content,
} from '../../../assets/css/StyledComponent.js';
import CloseIcon from '@material-ui/icons/Close';

class DialogComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dialogOpen,
      handlePopUpClose,
      handleReviseButtonClick,
      handleNewConceptButtonClick,
    } = this.props;

    return (
      <Dialog
        open={dialogOpen}
        maxWidth={'sm'}
        PaperProps={{ style: { borderRadius: '8px' } }}
        fullWidth
      >
        <DialogBox>
          <Box>
            <Content>
              <Close>
                <CloseIcon
                  onClick={handlePopUpClose}
                  style={{ fill: '#1093FF' }}
                />
              </Close>
              <Box>
                <DialogHeadText>
                  {'Choose the content you wanna Revise?'}
                </DialogHeadText>
              </Box>

              <Box pt={10}>
                <DialogButtonBox>
                  <FlexView>
                    <ReviseButton onClick={handleReviseButtonClick}>
                      {'Revise the exisiting one'}
                    </ReviseButton>
                    <ReviseButton onClick={handleNewConceptButtonClick}>
                      {'New Concept Videos'}
                    </ReviseButton>
                  </FlexView>
                </DialogButtonBox>
              </Box>
            </Content>
          </Box>
        </DialogBox>
      </Dialog>
    );
  }
}

export default connect()(DialogComponent);
