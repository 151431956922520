/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, LinearProgress } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CustomTypo,
  FlexFiller,
  FlexViewContainer,
} from '../../../assets/css/StyledComponent';
import { FlexView } from '../../../assets/css/StyledDashboardComponent';
import {
  ActionLink,
  BottomIcon,
  BoxContainer,
  Card,
  ContinueLink,
  FlexRow,
  Progress,
  ResultIcon,
  StartLink,
  TestContainer,
  TestSub,
  TestTitle,
  TopicImg,
} from '../../../assets/css/StyledOfficialGuideComponents';
import complete from '../../../assets/icons/complete.svg';
import clock from '../../../assets/images/ClockImage.png';
import easy from '../../../assets/images/easy.png';
import hard from '../../../assets/images/Liner.png';
import medium from '../../../assets/images/medium.png';
import notepad from '../../../assets/images/notepad.png';
import { MONTHS, QS_PROPS } from '../../../constant/Variables';
import topicReport from '../../../assets/icons/topicReport.svg';
import QueryString from 'qs';

const STATUS_OPTIONS = {
  RETAKE: {
    buttonText: 'Re-attempt',
    button: ActionLink,
  },
  PROGRESS: {
    buttonText: 'Continue',
    button: ContinueLink,
  },
  START: {
    buttonText: 'Start',
    button: StartLink,
  },
};

const ICONS = {
  EASY: easy,
  MEDIUM: medium,
  HARD: hard,
};

const EXAM_PROGRESS = ['RETAKE', 'PROGRESS'];

class GreCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t, greOG_Data, handleButtonClick, handleReportClick } = this.props;
    const { test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    return (
      <Grid container spacing={4} style={style.overflow}>
        {greOG_Data &&
          greOG_Data.length !== 0 &&
          greOG_Data.map(item => {
            let date = new Date(item.startDate);
            let startDate = `${date.getDate()} ${
              MONTHS[date.getMonth()]
            } ${date.getFullYear()}`;
            const BUTTON = STATUS_OPTIONS[item.status]['button'];
            return (
              <Grid item xs={12} sm={6}>
                <Card id={item.id}>
                  <BoxContainer>
                    <FlexRow>
                      <TestContainer>
                        <TopicImg src={notepad} />
                        <TestTitle>{t(item.title)}</TestTitle>
                      </TestContainer>
                      <BUTTON
                        onClick={() => handleButtonClick(item.id, item.status)}
                        className={'on-boost-mode'}
                      >
                        {t(STATUS_OPTIONS[item.status]['buttonText'])}
                      </BUTTON>
                    </FlexRow>
                    <FlexView>
                      {EXAM_PROGRESS.includes(item.status) && (
                        <TestSub>{startDate}</TestSub>
                      )}
                    </FlexView>
                    <Progress>
                      <FlexRow>
                        {item.status === 'RETAKE' ? (
                          <FlexViewContainer gridGap={'8px'}>
                            <ResultIcon src={complete} />
                            <CustomTypo {...style.progressText}>
                              {t('Completed')}
                            </CustomTypo>
                          </FlexViewContainer>
                        ) : item.status === 'PROGRESS' ? (
                          <FlexViewContainer
                            minWidth={'100%'}
                            padding={'0 8px'}
                          >
                            <CustomTypo
                              {...style.progressText}
                            >{`${item.progress}%`}</CustomTypo>
                            <FlexFiller />
                            <CustomTypo {...style.progressText}>
                              {t('In Progress')}
                            </CustomTypo>
                          </FlexViewContainer>
                        ) : (
                          <Box pl={1}>
                            <CustomTypo {...style.progressText}>
                              {'Progress'}
                            </CustomTypo>
                          </Box>
                        )}
                      </FlexRow>

                      <LinearProgress
                        variant={'determinate'}
                        style={style.progressBar}
                        value={
                          EXAM_PROGRESS.includes(item.status)
                            ? item.progress
                            : 0
                        }
                      />
                    </Progress>

                    <FlexViewContainer padding={'20px 10px 10px'}>
                      <FlexViewContainer>
                        <BottomIcon src={ICONS[item.difficultyLevel]} />
                        <CustomTypo
                          {...style.bottomText}
                          className={'capitalize'}
                        >
                          {t(item.difficultyLevel.toLowerCase())}
                        </CustomTypo>
                      </FlexViewContainer>

                      <FlexViewContainer>
                        <BottomIcon src={clock} />
                        <CustomTypo {...style.bottomText}>
                          {`${item.duration} Mins`}
                        </CustomTypo>
                      </FlexViewContainer>

                      <FlexViewContainer>
                        <BottomIcon src={notepad} />
                        <CustomTypo {...style.bottomText}>
                          {item.status === 'RETAKE'
                            ? `${item.score}/${item.totalQuestions}`
                            : `${item.totalQuestions} Q`}
                        </CustomTypo>
                      </FlexViewContainer>

                      {item.status === 'RETAKE' ? (
                        <FlexViewContainer>
                          <BottomIcon src={topicReport} />
                          <CustomTypo
                            style={{ cursor: 'pointer' }}
                            {...style.bottomText}
                            onClick={() => {
                              handleReportClick(item.testExecutionId);
                            }}
                          >
                            {'Report'}
                          </CustomTypo>
                        </FlexViewContainer>
                      ) : (
                        ''
                      )}
                      <div />
                    </FlexViewContainer>
                  </BoxContainer>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    );
  }
}

export default withRouter(GreCard);

export const style = {
  progressBar: {
    background: 'rgba(215, 212, 214, 0.5)',
    margin: '6px 0',
  },
  bottomText: {
    textColor: '#052a4e',
    padding: '3px 0 0',
    fontWeight: 500,
  },
  progressText: {
    textColor: '#838383',
    padding: '3px 0 0',
    fontWeight: 500,
  },
  overflow: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 260px)',
  },
};
