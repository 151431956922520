/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Container, Grid, ThemeProvider } from '@material-ui/core';
import _ from 'lodash';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { questionBankTheme } from '../../../../assets/css/MuiStyles';
import RocketIcon from '../../../../assets/icons/BlueRocket.svg';
import { QS_PROPS } from '../../../../constant/Variables';
import {
  getAllQuestions,
  getAllTopics,
  getTopicDetail,
} from '../../../../redux/action/Dashboard';
import { difficultyLevelChange } from '../../../../redux/action/DashboardLanding';
import { routePaths } from '../../../../routes/RoutePath';
import BreadCrumbs from '../../../../utils/BreadCrumbs';
import DialogComponent from '../../../../utils/components/DialogComponent';
import simulateMouseClick from '../../../../utils/methods/SimulateMouseClick';
import QuestionCard from './QuestionCard';
import TopicTestCard from './TopicTestCard';

class QuestionBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      level: [],
      questions: null,
      topics: null,
      breadCrumbData: null,
      boosterDialog: false,
      currenElement: null,
    };
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.DEFAULT_LEVEL = 'HARD,MEDIUM,EASY';
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // Score boost route
    window.document.addEventListener('click', this.handleGeneralClick, true);
    window.document.addEventListener('change', this.handleGeneralClick, true);

    const { topicId, type, level } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    this.props.getAllQuestions(
      level ? level : this.props.level.join(','),
      topicId,
      response => {
        this.setState({
          questions: response.data,
          level: level ? level.split(',') : [],
        });

        // Boost mode
        if (level) this.props.difficultyLevelChange(level.split(','));
      }
    );

    this.props.getAllTopics(topicId, response => {
      this.setState({
        topics: response.data,
      });
    });

    this.props.getTopicDetail(topicId, '', type, res => {
      this.setState({ breadCrumbData: res });
    });
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
  }

  handleChange = (event, id) => {
    const { topicId } = QueryString.parse(this.props.location.search, QS_PROPS);
    const {
      target: { name, checked },
    } = event;

    let arr = [];
    if (checked) {
      arr = this.props.level;
      arr.push(name);
    } else {
      arr = this.props.level.filter(i => i !== name);
    }
    this.props.getAllQuestions(arr.join(','), topicId, response => {
      this.setState({
        questions: response.data,
      });
    });
    this.props.difficultyLevelChange(arr);
  };

  handleQuestionCardClick = (
    questionId,
    testExecutionId,
    index,
    isAnswered
  ) => {
    this.props.history.push(
      `${routePaths.dashboard.question}?questionId=${questionId}&testQuestionId=${testExecutionId}&index=${index}&isAnswered=${isAnswered}`
    );
  };

  handleTopicTestClick = testQuestionId => {
    this.props.history.push(
      routePaths.dashboard.instruction + '?testQuestionId=' + testQuestionId
    );
  };

  handleGeneralClick(e) {
    const { boost } = QueryString.parse(this.props.location.search, QS_PROPS);

    const clsName = e.target.className.baseVal || e.target.className;
    if (boost && _.isString(clsName) && clsName.indexOf('on-boost-mode') > -1) {
      e.preventDefault();
      e.target.onclick = null;
      e.stopPropagation();
      this.setState({ boosterDialog: true, currenElement: e.target });
    }
  }

  handleButton1Click = () => {
    const { topicId } = QueryString.parse(this.props.location.search, QS_PROPS);

    window.document.removeEventListener('click', this.handleGeneralClick, true);
    window.document.removeEventListener(
      'change',
      this.handleGeneralClick,
      true
    );
    this.props.history.replace(
      `${routePaths.dashboard.questionBank}?topicId=${topicId}`
    );
    simulateMouseClick(this.state.currenElement);
    this.setState({ boosterDialog: false });
  };

  render() {
    const { t } = this.props;

    const { topicId, boost } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    return (
      <ThemeProvider theme={questionBankTheme}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <BreadCrumbs
                topics={this.state.breadCrumbData}
                isQuestionBank={true}
                goBack={() =>
                  this.props.history.push(
                    `${routePaths.dashboard.taskDetail}?topicId=${topicId}&prevPage=question_bank`
                  )
                }
                backEvent={!boost}
              />
              {this.state.questions && (
                <QuestionCard
                  handleChange={this.handleChange}
                  onClick={this.handleQuestionCardClick}
                  data={this.state.questions}
                  t={t}
                  level={this.props.level}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TopicTestCard
                item={this.state.topics}
                onClick={this.handleTopicTestClick}
                t={t}
              />
            </Grid>
          </Grid>
        </Container>
        <DialogComponent
          open={this.state.boosterDialog}
          dialogContent={{
            icon: <img src={RocketIcon} alt="" />,
            title: t(`You are current on the track to boost your score`),
            body: t('Try pushing your limits to give your best'),
            button1: t('Quit for now'),
            button2: t('Continue'),
          }}
          handleButton1Click={this.handleButton1Click}
          handleButton2Click={() => this.setState({ boosterDialog: false })}
        />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    questionsResponse: state.DashBoardReducer.allQuestions,
    topicsResponse: state.DashBoardReducer.allTopics,
    topics: state.dashboardReducer.topic,
    level: state.dashboardLandingReducer.level,
  };
};

export default connect(mapStateToProps, {
  getAllQuestions,
  getAllTopics,
  getTopicDetail,
  difficultyLevelChange,
})(withRouter(withTranslation()(QuestionBank)));
