/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment } from 'react';
import TextField from '../../../../utils/components/TextField';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CreatableDropdown({
  id,
  value,
  name,
  options,
  optionLabel,
  filterOptions,
  textProps,
  onChange,
}) {
  return (
    <Autocomplete
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option[optionLabel];
      }}
      renderInput={params => (
        <TextField
          {...params}
          autoComplete={true}
          variant={'standard'}
          {...textProps}
        />
      )}
      renderOption={option => {
        let arr = value ? [...value] : [];
        let selected = arr.some(
          item =>
            (option.id && option.id === item.id) || option.name === item.name
        );
        return (
          <Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {option[optionLabel]}
          </Fragment>
        );
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disableCloseOnSelect
      debug
      multiple
      freeSolo
    />
  );
}

export default CreatableDropdown;
