import { Grid, LinearProgress } from '@material-ui/core';
import React, { Component } from 'react';
import { FlexFiller } from '../../../../assets/css/StyledComponent';
import { FlexView } from '../../../../assets/css/StyledDashboardComponent';

import {
  ActionLink,
  BottomContent,
  BottomIcon,
  Box,
  BoxContainer,
  Card,
  Content,
  ContinueLink,
  FlexContainer,
  FlexRow,
  MarginContainer,
  Progress,
  ResultIcon,
  StartLink,
  SubTitle,
  TestContainer,
  TestSub,
  TestTitle,
  TopicImg,
} from '../../../../assets/css/StyledTopicComponent';
import notepad from '../../../../assets/images/notepad.png';
import { MONTHS } from '../../../../constant/Variables';
import Easy from '../../../../assets/images/Easy.svg';
import Hard from '../../../../assets/images/Hard.svg';
import Medium from '../../../../assets/images/Medium.svg';
import complete from '../../../../assets/icons/complete.svg';
import clock from '../../../../assets/images/ClockImage.png';
import topicReport from '../../../../assets/icons/topicReport.svg';

export default class Topics extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const { handleButtonClick, handleReportClick } = this.props;
    return (
      <MarginContainer>
        <Card>
          <FlexContainer>
            <Box>
              <FlexView>
                <TopicImg src={notepad} />
                <SubTitle>{t('Topic Test')}</SubTitle>
              </FlexView>
            </Box>
          </FlexContainer>
        </Card>
        {this.props.item &&
          this.props.item.map((item, index) => {
            let date = new Date(item.startDate);
            let startDate =
              date.getDate() +
              ' ' +
              MONTHS[date.getMonth()] +
              ' ' +
              date.getFullYear();
            return (
              <Grid container>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Card key={index}>
                    <BoxContainer>
                      <FlexRow>
                        <TestContainer>
                          <TestTitle className={'capitalize'}>
                            {item.difficultyLevel?.toLowerCase()}
                          </TestTitle>
                          {item.status === 'RETAKE' ? (
                            <TestSub>{startDate}</TestSub>
                          ) : (
                            ''
                          )}
                          {item.status === 'PROGRESS' ? (
                            <TestSub>{startDate}</TestSub>
                          ) : (
                            ''
                          )}
                        </TestContainer>
                        {item.status === 'RETAKE' ? (
                          <ActionLink
                            onClick={() =>
                              handleButtonClick(item.id, item.allowHard)
                            }
                            className="on-boost-mode"
                          >
                            {t('Re-take')}
                          </ActionLink>
                        ) : (
                          ''
                        )}
                        {item.status === 'PROGRESS' ? (
                          <ContinueLink
                            onClick={() =>
                              handleButtonClick(item.id, item.allowHard)
                            }
                            className="on-boost-mode"
                          >
                            {t('Continue')}
                          </ContinueLink>
                        ) : (
                          ''
                        )}
                        {item.status === 'START' ? (
                          <StartLink
                            onClick={() =>
                              handleButtonClick(item.id, item.allowHard)
                            }
                            className="on-boost-mode"
                          >
                            {t('Start')}
                          </StartLink>
                        ) : (
                          ''
                        )}
                      </FlexRow>

                      <Progress>
                        <FlexRow>
                          {item.status === 'RETAKE' ? (
                            <Content>
                              {item.status === 'RETAKE' ? (
                                <ResultIcon src={complete} />
                              ) : (
                                <span>{item.progress + '%'}&nbsp;</span>
                              )}
                              <span>{t('Completed')}</span>
                            </Content>
                          ) : (
                            ''
                          )}
                          {item.status === 'PROGRESS' ? (
                            <Content>
                              {item.status === 'PROGRESS' ? (
                                <span>{item.progress + '%'}&nbsp;</span>
                              ) : (
                                ''
                              )}
                              <span>{t('Completed')}</span>
                              <FlexFiller />
                              <Box>{t('In Progress')}</Box>
                            </Content>
                          ) : (
                            ''
                          )}
                        </FlexRow>

                        <LinearProgress
                          variant="determinate"
                          value={
                            item.status === 'PROGRESS'
                              ? item.progress
                              : item.status === 'START'
                              ? 0
                              : item.status === 'RETAKE'
                              ? item.progress
                              : ''
                          }
                        />
                      </Progress>

                      <FlexRow>
                        <BottomContent>
                          {item.difficultyLevel === 'EASY' ? (
                            <BottomIcon src={Easy} />
                          ) : (
                            ''
                          )}
                          {item.difficultyLevel === 'MEDIUM' ? (
                            <BottomIcon src={Medium} />
                          ) : (
                            ''
                          )}
                          {item.difficultyLevel === 'HARD' ? (
                            <BottomIcon src={Hard} />
                          ) : (
                            ''
                          )}
                          <span className="capitalize">
                            {item.difficultyLevel.toLowerCase()}
                          </span>
                        </BottomContent>

                        <BottomContent>
                          <BottomIcon src={clock} />
                          <span>{item.duration} Mins</span>
                        </BottomContent>

                        <BottomContent>
                          <BottomIcon src={notepad} />
                          <span>
                            {item.status === 'RETAKE'
                              ? item.score + '/' + item.totalQuestions
                              : item.totalQuestions + ' Q.'}
                          </span>
                        </BottomContent>
                        {item.status === 'RETAKE' ? (
                          <BottomContent>
                            <BottomIcon src={topicReport} />
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleReportClick(item.testExecutionId)
                              }
                            >
                              {'Report'}
                            </span>
                          </BottomContent>
                        ) : (
                          ''
                        )}
                      </FlexRow>
                    </BoxContainer>
                  </Card>
                </Grid>
              </Grid>
            );
          })}
      </MarginContainer>
    );
  }
}
