/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Typography } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 89vh;
  overflow: hidden;
  background-color: #f7f8fc;
`;

export const IconButton = withStyles({
  root: {
    paddingTop: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})(MuiIconButton);

export const RightText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #052a4e;
  width: 110px;
`;

export const CalendarBody = styled.div`
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  height: 100%;
  padding: 22px 40px;
  overflow-x: scroll;
  height: 68vh;
  display: flex;
  /* width: 56vw; */
`;

export const Card = withStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    height: '392px',
    minWidth: '322px',
    margin: '12px',
  },
})(MuiCard);

export const CardHeader = withStyles({
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#052A4E',
  },
})(MuiCardHeader);

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Task = styled.div`
  font-style: normal;
  font-weight: ${props =>
    props.status === 'COMPLETED'
      ? 'normal'
      : props.status === 'TODO'
      ? 300
      : props.status === 'PROGRESS'
      ? 600
      : ''};
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Day = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: #052a4e;
  width: 60px;
`;

export const LeftBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
`;

export const BodyTable = withStyles({
  root: {
    borderCollapse: 'separate',
    borderSpacing: '4px 32px',
    height: '56vh',
    overflow: 'scroll',
  },
})(MuiTable);

export const HeaderTable = withStyles({
  root: {
    height: '68px',
    boxShadow: '1px 0px 7px rgba(183, 222, 255, 0.5)',
  },
})(MuiTable);

export const TableHeadRow = withStyles({
  root: {},
})(MuiTableRow);

export const TableContainer = withStyles({
  root: {
    marginTop: '6px',
    width: '100%',
    maxHeight: '50vh',
  },
})(MuiTableContainer);

export const TableCell = withStyles({
  root: {},
  stickyHeader: {
    background: '#FFFFFF',
  },
})(MuiTableCell);

export const TableHead = withStyles({
  root: {
    background: '#FFFFFF',
    borderRadius: '8px 8px 0px 0px',
    boxShadow: '1px 0px 7px rgba(183, 222, 255, 0.5)',
  },
})(MuiTableHead);

export const DateBox = styled.div`
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  height: 300px;
  overflow: hidden;
  background: white;

  &:hover {
    // height: auto;
    box-shadow: 0px 0px 20px #b7deff;
    overflow: auto;
    position: absolute;
    top: 0px;
    z-index: 10;
    min-height: 330px;
  }
`;

export const InnerDateBox = styled.div`
  padding: 16px;
  background: ${props =>
    props.isToday
      ? `linear-gradient(
      180deg,
      #daeeff 35.42%,
      rgba(255, 255, 255, 0.4) 100%
    )`
      : 'white'};
  height: 100%;

  &:hover {
    height: auto;
    min-height: 300px;
  }
`;

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1px 1px 6px 6px rgba(78, 191, 243, 0.25)',
    fontSize: 11,
    width: 347,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export const WebinarIconButton = styled(MuiIconButton)`
  padding: 0;
`;

export const Popper = styled.div`
  z-index: 11;
  background: #ffffff;
  box-shadow: 0px 0px 20px #b7deff;
  /* background: honeydew; */
  border-radius: 8px;
  width: 300px;
  min-height: 100px;
  padding: 20px;
  z-index: 11;
`;

export const PopperMain = styled.div``;

export const PopperTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #1093ff;
`;

export const PopperTime = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  text-align: center;
`;

export const WebinarBox = styled.div`
  padding: 8px;
  cursor: pointer;

  border-bottom: ${props => {
    return props.lastItem ? 'none' : '0.5px solid rgba(16, 147, 255, 0.5)';
  }};
`;

export const CommentText = styled(Typography)`
  font-weight: bold !important;
`;
