import styled from 'styled-components';

export const replayIconStyle = {
  fill: 'white',
  height: '3em',
  width: '3em',
};

export const Message = styled.div`
  font-size: 0.8em;
  color: white;
`;

export const EmptyBox = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 10;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: ${props => (props.show ? 'flex' : 'none')};
  cursor: pointer;
`;

export const Container = styled.div`
  height: auto;
  width: 100%;
`;

export const InnerDiv = styled.div`
  width: 100%;
  border: none;
  max-width: 100%;
  position: relative;
  max-height: 100%;
  padding-bottom: 56.2499%;
`;


export const QuestionPopup = styled.div`
 display:block;
font-weight: 400;
font-size: 20px;
color:white;
padding:4px;
text-align:center;

min-height: 10%;

background: linear-gradient(180deg, #0E1042 2.6%, rgba(58, 104, 146, 0.91) 100%);
border: 1.5px solid #FFFFFF;
backdrop-filter: blur(2px);
border-radius: 200px;`
;


 