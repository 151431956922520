import mixpanel from "mixpanel-browser";
// mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);

let env_check = false;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
    if (env_check) mixpanel.people.increment("Visit number");
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
    set_once: (props) => {
      if (env_check) mixpanel.people.set_once(props);
    },
    increment: (name) => {
      if (env_check) mixpanel.people.increment(name, 1);
    },
  },
};

export let Mixpanel = actions;
