/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Doughnut as Graph } from 'react-chartjs-2';
import styled from 'styled-components';

const Doughnut = ({ data, animation, maintainAspectRatio }) => {
  const ref = useRef();

  console.log(data, 'data');
  const plugins = [
    {
      beforeDraw: function (chart) {
        if (chart.options.elements.center) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 180).toFixed(2);
          ctx.font = `${fontSize}em Montserrat`;
          ctx.fillStyle = '#1093FF';
          ctx.textBaseline = 'top';
          var center = chart.options.elements.center;
          var text = center.text,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      },
    },
  ];

  // const options = {
  //   plugins: {
  //     tooltip: false,
  //   },
  //   animation: animation,
  //   maintainAspectRatio: maintainAspectRatio,
  // };

  const options = {
    elements: {
      center: {
        text: data.percentage + '%',
      },
    },
  };
  useEffect(() => {
    if (
      ref &&
      ref.current &&
      ref.current.chartInstance &&
      ref.current.chartInstance.update
    ) {
      ref.current.chartInstance.update();
    }
  }, [data]);

  useEffect(() => {
    if (
      ref &&
      ref.current &&
      ref.current.chartInstance &&
      ref.current.chartInstance.update
    ) {
      ref.current.chartInstance.update();
    }
  }, [data]);

  return (
    <div>
      <GraphContainer isDefaultCircle={!Boolean(data.percentage)}>
        <Graph data={data} ref={ref} plugins={plugins} options={options} />
      </GraphContainer>
    </div>
  );
};

export default Doughnut;

const GraphContainer = styled.div`
  width: 150px;
  canvas {
    background: ${({ isDefaultCircle }) =>
      isDefaultCircle &&
      ' radial-gradient( circle at center,rgba(0,0,0,0) 0, rgba(0,0,0,0) 40%, rgba(240,242,248,255) 5%, rgba(240,242,248,255) 50%, rgba(240,242,248,255) 64%, rgba(0,0,0,0) 65%, rgba(0,0,0,0) 100%)'}
`;
