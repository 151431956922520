/**
 * (c) CareerLabs. All rights reserved.
 **/
import { GET } from '../../axios/PublicAxios';
import { PUT, GET as Get, IMAGE__PUT } from '../../axios/Axios';
import { COLLEGE, PROFILE } from '../Action';
import Cookies from 'js-cookie';
import { decrypt } from '../../utils/Crypto';
import { DEFAULT_URL } from '../../api/API';
import { catchError } from '../../utils/methods/Helper';

export const getAspirationUniversity = () => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/aspiration/university`,
      {},
      response => {
        dispatch({
          type: COLLEGE.getAspirationUniversity,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getDegree = () => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/degrees`,
      {},
      response => {
        dispatch({
          type: COLLEGE.getDegree,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getCollege = () => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/colleges`,
      {},
      response => {
        dispatch({
          type: COLLEGE.getCollege,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getDepartment = () => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/departments`,
      {},
      response => {
        dispatch({
          type: COLLEGE.getDepartment,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getUniversity = () => {
  return dispatch => {
    GET(
      `${DEFAULT_URL}/university`,
      {},
      response => {
        dispatch({
          type: COLLEGE.getUniversity,
          payload: response.data,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updateProfileSetup = (data, callback) => {
  return dispatch => {
    const studentId =
      localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}?page=setup`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.updateSetup,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updateProfile = (data, callback) => {
  return dispatch => {
    const studentId =
      localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}?page=personal`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.updateProfile,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updateEmployeeInfo = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/experience`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.updateEmployeeInfo,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const updateEducationInfo = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/educationInfo`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.updateEducationalInfo,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const uploadProfileImage = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    IMAGE__PUT(
      `${DEFAULT_URL}/lms/student/${studentId}/profileUpload`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.uploadProfileImage,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const viewProfileImage = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    Get(
      `${DEFAULT_URL}/lms/student/${studentId}/profileImage`,
      {},
      response => {
        dispatch({
          type: PROFILE.getProfileImage,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error);
        dispatch({
          type: PROFILE.getProfileImage,
          payload: null,
        });
      }
    );
  };
};

export const getCourses = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    if (studentId)
      Get(
        `${DEFAULT_URL}/lms/students/${studentId}/products`,
        {},
        response => {
          dispatch({
            type: PROFILE.getCourses,
            payload: response.data,
          });
          callback(response.data);
        },
        error => {
          // console.log(error);
        }
      );
  };
};

export const changePassword = (data, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/changePassword`,
      data,
      {},
      response => {
        dispatch({
          type: PROFILE.changePassword,
          payload: response.data,
        });
        callback(response.data.success);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const checkUserLogin = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    Get(
      `${DEFAULT_URL}/lms/check/validation/student/${studentId}`,
      null,
      response => {
        callback(response.data);
      },
      error => {
        callback(catchError(error));
      }
    );
  };
};
