/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Checkbox, Grid, Radio, Typography } from '@material-ui/core';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import {
  BundleDiv,
  FormControlLabel2,
  FormGroup,
  QuestionDiv,
  RadioGroup,
} from '../../../../../assets/css/GmatStyles';
import {
  DividerLine,
  TextBox,
  TextBoxField,
  TextBoxValueShow,
  TextUnitField,
  TextWrapper,
} from '../../../../../assets/css/test/TestComponent';
import { QS_PROPS } from '../../../../../constant/Variables';
import Latex from '../../../../../utils/LatexViewer';
import BundleComp from '../../exam/Bundle';
// import Radio from '@mui/material/Radio';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TabPanel from '@mui/material/Tabs';
import { Fragment } from 'react';
import LatexViewer from '../../../../../utils/LatexViewer';
import ClariDropDown from '../../../../../utils/components/ClariDropDown';

const SingleSelect = (testResponse, state, onSelect) => {
  if (testResponse.data.isHaveDescription || testResponse.data.isHaveImage) {
    return (
      <Grid container>
        <Grid item xs={6} className="blue_border">
          <QuestionDiv>
            {testResponse.data.isHaveDescription && (
              <Typography variant="h4" color="textPrimary">
                <Latex math={testResponse.data.description} />
              </Typography>
            )}
            {testResponse.data.isHaveImage && (
              <img src={testResponse.data.imgURL} />
            )}
          </QuestionDiv>
        </Grid>
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
            <RadioGroup value={state.selectedChoice}>
              {testResponse.data.choices.map((choice, index) => (
                <FormControlLabel2
                  control={<Radio color="secondary" />}
                  value={choice.id}
                  onChange={onSelect}
                  label={
                    <Typography variant="h4" color="textPrimary">
                      {choice.text ? (
                        <Latex math={choice.text} />
                      ) : (
                        <img src={choice.choiceImage} alt="" />
                      )}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </QuestionDiv>
        </Grid>
      </Grid>
    );
  } else
    return (
      // ------------- Single Select With Image Choice and Text ------------------
      <QuestionDiv>
        <Typography variant="h5" color="textPrimary">
          <Latex math={testResponse.data.question} />
        </Typography>
        <RadioGroup value={state.selectedChoice}>
          {testResponse.data.choices.map((choice, index) => (
            <FormControlLabel2
              control={<Radio color="secondary" />}
              value={choice.id}
              onChange={onSelect}
              label={
                <Typography variant="h4" color="textPrimary">
                  {choice.text ? (
                    <Latex math={choice.text} />
                  ) : (
                    <img src={choice.choiceImage} alt="" />
                  )}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </QuestionDiv>
    );
};

const Passage = (testResponse, state, onTextChange, onFractionChange) => (
  <Grid container>
    {console.log(testResponse.data.type)}
    <Grid item xs={6} className="blue_border">
      <Box display="flex" height="100%" justifyContent="space-between">
        <QuestionDiv>
          {/* <Typography variant="h5" color="textPrimary">
            <Latex math={testResponse.data.question} />
          </Typography> */}
          {testResponse.data.isHaveDescription && (
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.description} />
            </Typography>
          )}
          {testResponse.data.type === 'SUBJECTIVE' &&
          // testResponse.data.optionalType === 'NUMERICAL_ENTRY'
          testResponse.data.isHaveDescription === false ? (
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
          ) : (
            <></>
          )}
          {testResponse.data.type === 'FRACTION' &&
            testResponse.data.isHaveDescription && (
              <Typography variant="h5" color="textPrimary">
                <Latex math={testResponse.data.question} />
              </Typography>
            )}
          {testResponse.data.isHaveImage && (
            <img src={testResponse.data.imgURL} />
          )}
        </QuestionDiv>
      </Box>
    </Grid>
    <Grid item xs={6}>
      {testResponse.data.type === 'SUBJECTIVE' &&
      testResponse.data.optionalType === 'NUMERICAL_ENTRY' ? (
        <div>
          <QuestionDiv>
            {testResponse.data.isHaveDescription && (
              <Typography variant="h5" color="textPrimary">
                <Latex math={testResponse.data.question} />
              </Typography>
            )}
            <TextWrapper>
              <div style={{ display: 'flex' }}>
                <TextBoxField
                  value={state.textAnswer}
                  onChange={onTextChange}
                  type={'number'}
                />
                {testResponse.data.unit !== null ? (
                  <TextUnitField>{testResponse.data.unit}</TextUnitField>
                ) : null}
              </div>
            </TextWrapper>
          </QuestionDiv>
        </div>
      ) : testResponse.data.type === 'FRACTION' ? (
        <QuestionDiv>
          <TextWrapper>
            <div style={{ display: 'flex' }}>
              <div>
                <TextBoxValueShow
                  onChange={onTextChange}
                  value={state.textAnswer}
                  type={'number'}
                />

                <DividerLine />
                <TextBoxValueShow
                  onChange={onFractionChange}
                  value={state.answer1}
                  type={'number'}
                />
              </div>
              {testResponse.data.unit !== null ? (
                <TextUnitField>{testResponse.data.unit}</TextUnitField>
              ) : null}
            </div>
          </TextWrapper>
        </QuestionDiv>
      ) : (
        <QuestionDiv>
          {testResponse.data.isHaveDescription && (
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
          )}
          <TextBox
            type={'text'}
            placeholder={'Enter your answer here'}
            onChange={onTextChange}
            value={state.textAnswer}
          />
        </QuestionDiv>
      )}
    </Grid>
  </Grid>
);

const MultiChoice = (testResponse, state, onMultiSelect) => {
  if (testResponse.data.isHaveDescription || testResponse.data.isHaveImage) {
    return (
      <Grid container>
        <Grid item xs={6} className="blue_border">
          <QuestionDiv>
            {testResponse.data.isHaveDescription && (
              <Typography variant="h4" color="textPrimary">
                <Latex math={testResponse.data.description} />
              </Typography>
            )}
            {testResponse.data.isHaveImage && (
              <img src={testResponse.data.imgURL} />
            )}
          </QuestionDiv>
        </Grid>
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>
            <FormGroup value={state.selectedChoice}>
              {testResponse.data.choices.map((choice, index) => (
                <FormControlLabel2
                  control={<Checkbox color="secondary" />}
                  value={choice.id}
                  checked={state.selectedChoice.indexOf(choice.id) > -1}
                  onChange={onMultiSelect}
                  label={
                    <Typography variant="h4" color="textPrimary">
                      {choice.text ? (
                        <Latex math={choice.text} />
                      ) : (
                        <img src={choice.choiceImage} alt="" />
                      )}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </QuestionDiv>
        </Grid>
      </Grid>
    );
  } else
    return (
      // ------------- Multi Select With Image Choice and Text ------------------
      <QuestionDiv>
        <Typography variant="h5" color="textPrimary">
          <Latex math={testResponse.data.question} />
        </Typography>
        <FormGroup value={state.selectedChoice}>
          {testResponse.data.choices.map((choice, index) => (
            <FormControlLabel2
              control={<Checkbox color="secondary" />}
              value={choice.id}
              checked={state.selectedChoice.indexOf(choice.id) > -1}
              onChange={onMultiSelect}
              label={
                <Typography variant="h4" color="textPrimary">
                  {choice.text ? (
                    <Latex math={choice.text} />
                  ) : (
                    <img src={choice.choiceImage} alt="" />
                  )}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </QuestionDiv>
    );
};

const Bundle = (testResponse, state, onBundleChange) => {
  return (
    <Grid container>
      <Grid
        item
        xs={testResponse.data.isHaveDescription === false ? 12 : 6}
        className="blue_border"
      >
        <QuestionDiv>
          {testResponse.data.isHaveDescription === false && (
            <>
              <Typography variant="h5" color="textPrimary">
                <Latex math={testResponse.data.question} />
              </Typography>
              <BundleDiv>
                <BundleComp
                  bundleLength={testResponse.data.totalBundle}
                  choices={testResponse.data.choices}
                  onChange={onBundleChange}
                  selectedChoice={state.bundleSelect}
                />
              </BundleDiv>
            </>
          )}
          {testResponse.data.isHaveDescription && (
            <Typography variant="h4" color="textPrimary">
              <Latex math={testResponse.data.description} />
            </Typography>
          )}
          {testResponse.data.isHaveImage && (
            <>
              <img src={testResponse.data.imgURL} />
              <BundleDiv>
                <BundleComp
                  bundleLength={testResponse.data.totalBundle}
                  choices={testResponse.data.choices}
                  onChange={onBundleChange}
                  selectedChoice={state.bundleSelect}
                />
              </BundleDiv>
            </>
          )}
        </QuestionDiv>
      </Grid>
      {testResponse.data.isHaveDescription === true && (
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography variant="h5" color="textPrimary">
              <Latex math={testResponse.data.question} />
            </Typography>

            <BundleDiv>
              <BundleComp
                bundleLength={testResponse.data.totalBundle}
                choices={testResponse.data.choices}
                onChange={onBundleChange}
                selectedChoice={state.bundleSelect}
              />
            </BundleDiv>
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};

const Twopart = (testResponse, state, handleChangeTwo, handleChangeTwo1) => {
  return (
    <Grid container>
      <Grid
        item
        xs={testResponse.data.isHaveDescription === false ? 12 : 6}
        className="blue_border"
      >
        {testResponse.data.description !== '' && (
          <QuestionDiv>
            <Box
              display={'flex'}
              className={'overflow-scroll'}
              height={'inherit'}
            >
              <Box flex={1} className={'question-left-container'}>
                <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
                  <Typography variant="h4" color="textPrimary">
                    <Latex math={testResponse.data.description} />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </QuestionDiv>
        )}
        {testResponse.data.isHaveDescription !== '' && (
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography variant="h5" color="textPrimary">
                  <Latex math={testResponse.data.question} />
                </Typography>
              </FormLabel>
              <table className="two-part-table">
                <tr>
                  <th>{testResponse.data.firstSelectedName}</th>
                  <th>{testResponse.data.secondSelectedName}</th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((item, index) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={`left-${index}`}
                              value={
                                state.bundleSelectedChoice[0]?.id === item.id
                                  ? state.bundleSelectedChoice[0]?.correctChoice
                                  : ''
                              }
                              onChange={event => handleChangeTwo(event, index)}
                            >
                              <FormControlLabel
                                value={'true'}
                                control={<Radio />}
                                style={{ margin: '0px' }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((item, index) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={`right-${index}`}
                              value={
                                state.bundleSelectedChoice[1]?.id === item.id
                                  ? state.bundleSelectedChoice[1]?.wrongChoice
                                  : ''
                              }
                              onChange={event => handleChangeTwo1(event, index)}
                            >
                              <FormControlLabel
                                value={'true'}
                                control={<Radio />}
                                style={{ margin: '0px' }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((choice, index) => (
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: '10px' }}
                        >
                          {choice.text ? (
                            <Latex math={choice.text} />
                          ) : (
                            <img src={choice.choiceImage} alt="" />
                          )}
                        </Typography>
                      ))}
                    </div>
                  </td>
                </tr>
              </table>
            </FormControl>
          </QuestionDiv>
        )}
      </Grid>
      {testResponse.data.isHaveDescription === true && (
        <Grid item xs={6}>
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography variant="h5" color="textPrimary">
                  <Latex math={testResponse.data.question} />
                </Typography>
              </FormLabel>
              <table className="two-part-table">
                <tr>
                  <th>{testResponse.data.firstSelectedName}</th>
                  <th>{testResponse.data.secondSelectedName}</th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((item, index) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={`left-${index}`}
                              value={
                                state.bundleSelectedChoice[0]?.id === item.id
                                  ? state.bundleSelectedChoice[0]?.correctChoice
                                  : ''
                              }
                              onChange={event => handleChangeTwo(event, index)}
                            >
                              <FormControlLabel
                                value={'true'}
                                control={<Radio />}
                                style={{ margin: '0px' }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((item, index) => {
                        return (
                          <Grid item>
                            <RadioGroup
                              row
                              style={{
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={`right-${index}`}
                              value={
                                state.bundleSelectedChoice[1]?.id === item.id
                                  ? state.bundleSelectedChoice[1]?.wrongChoice
                                  : ''
                              }
                              onChange={event => handleChangeTwo1(event, index)}
                            >
                              <FormControlLabel
                                value={'true'}
                                control={<Radio />}
                                style={{ margin: '0px' }}
                              />
                            </RadioGroup>
                          </Grid>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {testResponse.data.choices.map((choice, index) => (
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{ margin: '10px' }}
                        >
                          {choice.text ? (
                            <Latex math={choice.text} />
                          ) : (
                            <img src={choice.choiceImage} alt="" />
                          )}
                        </Typography>
                      ))}
                    </div>
                  </td>
                </tr>
              </table>
            </FormControl>
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};

const Binary = (testResponse, state, handleChangeBinary) => {
  const handleCheck = item => {
    const selectedChoice = state?.bundleSelectedChoice.find(
      choice => choice.id === item.id
    );
    return selectedChoice ? selectedChoice.selectedValue : '';
  };
  return (
    <Grid container>
      {testResponse.data.description !== '' && (
        <Grid
          item
          xs={testResponse.data.isHaveDescription === false ? 12 : 6}
          className="blue_border"
        >
          <QuestionDiv>
            <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
              <Latex math={testResponse.data.description} />
            </Box>
          </QuestionDiv>
        </Grid>
      )}
      {testResponse.data.description === '' && (
        <Grid item xs={12}>
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ marginBottom: '15px' }}
                >
                  <Latex math={testResponse.data.question} />
                </Typography>
                <Box display={'flex'}>
                  <Typography variant="h5" color="textPrimary">
                    <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    <span>No</span>
                  </Typography>
                </Box>
              </FormLabel>
              {testResponse.data.choices.map((item, index) => {
                return (
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{
                          alignItems: 'center',
                          height: '100%',
                          padding: '0px',
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={handleCheck(item)}
                        onChange={event => handleChangeBinary(event, index)}
                      >
                        <FormControlLabel
                          value={'true'}
                          control={<Radio />}
                          style={{ marginBottom: '0px' }}
                        />
                        <FormControlLabel
                          value={'false'}
                          control={<Radio />}
                          style={{
                            marginRight: '0px',
                            marginBottom: '0px',
                            margin: '0px',
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <Latex math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </QuestionDiv>
        </Grid>
      )}
      {testResponse.data.isHaveDescription === true && (
        <Grid item xs={6}>
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ marginBottom: '15px' }}
                >
                  <Latex math={testResponse.data.question} />
                </Typography>
                <Box display={'flex'}>
                  <Typography variant="h5" color="textPrimary">
                    <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    <span>No</span>
                  </Typography>
                </Box>
              </FormLabel>
              {testResponse.data.choices.map((item, index) => {
                return (
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{
                          alignItems: 'center',
                          height: '100%',
                          padding: '0px',
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={handleCheck(item)}
                        onChange={event => handleChangeBinary(event, index)}
                      >
                        <FormControlLabel
                          value={'true'}
                          control={<Radio />}
                          style={{ marginBottom: '0px' }}
                        />
                        <FormControlLabel
                          value={'false'}
                          control={<Radio />}
                          style={{
                            marginRight: '0px',
                            marginBottom: '0px',
                            margin: '0px',
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <Latex math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};

const getOption = option => {
  return [{ text: 'Select', id: 'default', disabled: true }, ...option];
};

const math = ii => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(ii, 'text/html').body.textContent;
  return doc;
};

const DropDown = (testResponse, state, handleDropDown) => {
  return (
    <Grid container>
      <Grid
        item
        xs={testResponse.data.isHaveDescription === false ? 12 : 6}
        className="blue_border"
      >
        {testResponse.data.description !== '' ? (
          <QuestionDiv>
            <Box fontSize={16} color={'#052A4E'} lineHeight={'30px'}>
              <Latex math={testResponse.data.description} />
            </Box>
          </QuestionDiv>
        ) : (
          <Grid item xs={12}>
            <QuestionDiv>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ marginBottom: '15px' }}
              >
                <Latex math={testResponse.data.question} />
              </Typography>
              {testResponse.data.choices.map((item, index) => {
                const bundle =
                  state?.bundleSelectedChoice.length !== 0 &&
                  state?.bundleSelectedChoice.filter(
                    bundle => bundle.id === item.id
                  );
                return (
                  <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: '10px', lineHeight: '35px' }}
                  >
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <span>
                        <span>
                          {math(item.text)}
                          <ClariDropDown
                            items={getOption(item.multiChoices)}
                            value={bundle[0]?.value || 'default'}
                            onChange={e => handleDropDown(e, index, item)}
                            label="Select"
                          />
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
            </QuestionDiv>
          </Grid>
        )}
      </Grid>
      {testResponse.data.isHaveDescription === true && (
        <Grid item xs={6}>
          <QuestionDiv>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{ marginBottom: '15px' }}
            >
              <Latex math={testResponse.data.question} />
            </Typography>
            {testResponse.data.choices.map((item, index) => {
              return (
                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                  <Grid item xs style={{ alignSelf: 'center' }}>
                    <span>
                      <span>
                        {math(item.text)}
                        <ClariDropDown
                          items={getOption(item.multiChoices)}
                          value={
                            state?.bundleSelectedChoice[index]?.value ||
                            'default'
                          }
                          onChange={e => handleDropDown(e, index, item)}
                          label="Select"
                        />
                      </span>
                    </span>
                  </Grid>
                </Grid>
              );
            })}
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};

const MultiTab = (
  testResponse,
  state,
  handleChangeBinary,
  tabValue,
  tabValues,
  handleChangeTab
) => {
  const handleCheck = item => {
    const selectedChoice = state?.bundleSelectedChoice.find(
      choice => choice.id === item.id
    );
    return selectedChoice ? selectedChoice.selectedValue : '';
  };
  return (
    <Grid container>
      <Grid item xs={6} className="blue_border">
        <QuestionDiv>
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={handleChangeTab}
                value={tabValue}
                aria-label="lab API tabs example"
              >
                {tabValues.map(value => (
                  <Tab
                    key={value}
                    label={`Item ${value + 1}`}
                    value={value}
                    // {...a11yProps(value)}
                  />
                ))}
              </Tabs>
            </Box>
            <Box
              fontSize={16}
              color={'#052A4E'}
              lineHeight={'30px'}
              padding={'10px 12px'}
            >
              {tabValues.map(index => {
                return (
                  <Fragment key={index}>
                    <div
                      hidden={tabValue !== index}
                      style={{ padding: '15px' }}
                    >
                      <TabPanel
                        value={tabValue}
                        index={index}
                        className="tabs-question-tab"
                      >
                        <Box
                          fontSize={16}
                          color={'#052A4E'}
                          lineHeight={'30px'}
                        >
                          <LatexViewer
                            math={
                              testResponse.data.multiDescription[tabValue][
                                'description'
                              ]
                            }
                          />
                        </Box>
                      </TabPanel>
                    </div>
                  </Fragment>
                );
              })}
            </Box>
          </>
        </QuestionDiv>
      </Grid>

      {testResponse.data.type === 'MULTI_TAB' && (
        <Grid item xs={6}>
          <QuestionDiv>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ marginBottom: '15px' }}
                >
                  <Latex math={testResponse.data.question} />
                </Typography>
                <Box display={'flex'}>
                  <Typography variant="h5" color="textPrimary">
                    <span>Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    <span>No</span>
                  </Typography>
                </Box>
              </FormLabel>
              {testResponse.data.choices.map((item, index) => {
                return (
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item>
                      <RadioGroup
                        row
                        style={{
                          alignItems: 'center',
                          height: '100%',
                          padding: '0px',
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="row-radio-buttons-group"
                        value={handleCheck(item)}
                        onChange={event => handleChangeBinary(event, index)}
                      >
                        <FormControlLabel
                          value={'true'}
                          control={<Radio />}
                          style={{ marginBottom: '0px' }}
                        />
                        <FormControlLabel
                          value={'false'}
                          control={<Radio />}
                          style={{
                            marginRight: '0px',
                            marginBottom: '0px',
                            margin: '0px',
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs style={{ alignSelf: 'center' }}>
                      <Latex math={item.text} />
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </QuestionDiv>
        </Grid>
      )}
    </Grid>
  );
};
function Test({
  testResponse,
  state,
  onSelect,
  startTest2,
  location,
  onTextChange,
  onMultiSelect,
  onBundleChange,
  getTestSection,
  onFractionChange,
  handleChangeBinary,
  handleChangeTwo,
  handleChangeTwo1,
  handleDropDown,
}) {
  const { testQuestionSetId } = QueryString.parse(location.search, QS_PROPS);

  const [tabValue, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const tabValues = [0, 1, 2];

  useEffect(() => {
    startTest2(testQuestionSetId, res => {
      let userProduct = JSON.parse(localStorage.getItem('userProduct'));
      userProduct.testExecutionId = res.data.testExecutionId;
      localStorage.setItem('userProduct', JSON.stringify(userProduct));
    });
    getTestSection();
  }, []);

  if (testResponse !== null) {
    if (testResponse.data.type === 'SINGLE_SELECT') {
      return SingleSelect(testResponse, state, onSelect);
    } else if (testResponse.data.type === 'SUBJECTIVE')
      return Passage(testResponse, state, onTextChange, onFractionChange);
    else if (testResponse.data.type === 'MULTI_CHOICE')
      return MultiChoice(testResponse, state, onMultiSelect);
    else if (testResponse.data.type === 'BUNDLE')
      return Bundle(testResponse, state, onBundleChange);
    else if (testResponse.data.type === 'BINARY')
      return Binary(testResponse, state, handleChangeBinary);
    else if (testResponse.data.type === 'TWO_PART')
      return Twopart(testResponse, state, handleChangeTwo, handleChangeTwo1);
    else if (testResponse.data.type === 'DROP_DOWN')
      return DropDown(testResponse, state, handleDropDown);
    else if (testResponse.data.type === 'MULTI_TAB')
      return MultiTab(
        testResponse,
        state,
        handleChangeBinary,
        tabValue,
        tabValues,
        handleChangeTab
      );
    else return null;
  }
  return null;
}

export default Test;
