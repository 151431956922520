import { MenuItem, ThemeProvider } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import {
  listItem,
  StyledSelect,
} from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import {
  HeadDiv,
  Heading,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';

const defaultObj = {
  id: 'all',
  name: 'all',
  title: 'All',
};

export default function Header(props) {
  const {
    subjectData,
    conceptData,
    handleChange,
    handleConceptChange,
    subjectValue,
    conceptValue,
    questionType,
    questionTypes = [],
    onChange,
    isQuestionDropdown,
  } = props;
  return (
    <HeadDiv>
      <Heading>Question Bank</Heading>
      <SelectBox>
        <ThemeProvider theme={listItem}>
          <StyledSelect
            style={style.dropDownStyle}
            name="subjectValue"
            onChange={handleChange}
            value={subjectValue}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
          >
            {[...subjectData].map(subject => {
              return <MenuItem value={subject.id}>{subject.title}</MenuItem>;
            })}
          </StyledSelect>
          <StyledSelect
            style={style.dropDownStyle}
            name="conceptValue"
            onChange={handleConceptChange}
            value={conceptValue}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
          >
            {[defaultObj, ...conceptData].map(concept => {
              return <MenuItem value={concept.id}>{concept.title}</MenuItem>;
            })}
          </StyledSelect>
          <StyledSelect
            style={{
              minWidth: '250px',
              maxWidth: '250px',
              opacity: !isQuestionDropdown(subjectData, subjectValue) && 0.2,
            }}
            name="questionType"
            onChange={onChange}
            value={questionType}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
            // opacity={!isQuestionDropdown(subjectData, subjectValue) && 0.2}
            disabled={!isQuestionDropdown(subjectData, subjectValue)}
          >
            {[defaultObj, ...questionTypes].map(type => {
              return <MenuItem value={type.title}>{type.title}</MenuItem>;
            })}
          </StyledSelect>
        </ThemeProvider>
      </SelectBox>
    </HeadDiv>
  );
}

const style = {
  dropDownStyle: {
    minWidth: '250px',
    maxWidth: '250px',
  },
};
