/**
 * (c) CareerLabs. All rights reserved.
 **/
 import { Box, Button } from '@material-ui/core';
 import Dialog from '@material-ui/core/Dialog';
 import React from 'react';
 import '../../../assets/css/App.css';
 import {
   DialogButtonBox,
   DialogContainer,
   DialogHeadText,
   DialogText,
 } from '../../../assets/css/StyledComponent';
 import CloseIcon from '@material-ui/icons/Close';
 import { Close } from '../../../assets/css/StyledComponent.js';
 import { ReportProblemRounded } from '@material-ui/icons';
 
 export default function DailogComponent(props) {
   const { open, handlePopUpClose, handleButtonClick1 } = props;
 
   return (
     <Dialog
       open={open}
       maxWidth={'sm'}
       PaperProps={{ style: { borderRadius: '8px' } }}
       fullWidth
     >
       <DialogContainer>
         <Box>
           {/* <Close>
              <CloseIcon onClick={handlePopUpClose} style={{ fill: '#1093FF' }} />
            </Close> */}
           <Box>
             <DialogHeadText>
               <ReportProblemRounded
                 style={{
                   fill: '#8B1A10',
                   width: '10%',
                   height: '20%',
                 }}
               />
             </DialogHeadText>
 
             <DialogText>
               <b>
                 {
                   'To avoid incorrect progress tracking, please complete the easy and medium first to attempt hard level test'
                 }
               </b>
             </DialogText>
 
             <DialogButtonBox>
               <Button
                 color="primary"
                 variant="outlined"
                 className={'button-style'}
                 onClick={handleButtonClick1}
               >
                 {'Yes'}
               </Button>
               <div style={{ width: '40px' }} />
               <Button
                 color="primary"
                 variant="outlined"
                 className={'button-style'}
                 onClick={handlePopUpClose}
               >
                 {'No'}
               </Button>
             </DialogButtonBox>
           </Box>
         </Box>
       </DialogContainer>
     </Dialog>
   );
 }
 
