import { Grid, Box } from '@material-ui/core';
import React, { Component } from 'react';
import {
  PieChartContainer,
  ReportCardContainer,
  ChartTitle,
  ChartValue,
  Card,
  CardHeader,
  Indicator,
  CardHeaderTitle,
  CardPerentage,
  Item,
  CardFooter,
  FlexView,
  Card1,
  Card2,
  SubTitle,
  ReportText,
  CardContent,
  CardItem,
  ItemCaption,
  ItemIcon,
  Gap,
  Image,
  ImageText,
  Item2,
} from '../../../../../assets/css/GreReportStyledComponent';
import Circle from '../../../../../assets/icons/circle.svg';
import Doughnut from '../../../course/graph/Doughnut';
import InfoIcon from '../../../../../assets/icons/info_outline1.svg';

export default class ReportCard extends Component {
  render() {
    const { item } = this.props;

    var data = {
      datasets: [
        {
          label: 'Test Summary',
          data: [
            item.insights?.donut.percentage,
            100 - item.insights?.donut.percentage,
          ],
          backgroundColor: ['#7380F2', '#FEBB2C', '#F85E7E'],
        },
      ],
      percentage: item.insights?.donut.percentage || '',
      summaryName: item.insights?.donut.title || '',
      total: item.insights?.donut.totalQuestion,
      count: item.insights?.donut.score,
      text: item.insights?.time,
      level: item.insights?.difficultyLevel,
      subject: item.insights?.subjects,
    };
    return (
      <Card1>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={12} md={4}>
            <PieChartContainer>
              <Item width={170}>
                <Doughnut data={data} />
              </Item>
              <Item2 display={'flex'} direction={'column'}>
                <ChartTitle>{data.summaryName}</ChartTitle>
                <ChartValue>
                  {data.count}/{data.total}
                </ChartValue>
              </Item2>
            </PieChartContainer>
          </Grid>

          {/* <Grid item xs={12} sm={2} md={2}>
            {data?.subject?.map(item => {
              return (
                <Card>
                  <CardHeader>
                    
                    <Indicator color={'#FEBB2C'} />
                    <CardHeaderTitle>{item.name}</CardHeaderTitle>
                  </CardHeader>

                  <CardFooter>
                    {item.score}

                    {item.percentage !== null ? (
                      <CardPerentage>{item.percentage}%</CardPerentage>
                    ) : null}
                  </CardFooter>
                </Card>
              );
            })}
          </Grid> */}

          <Grid item xs={12} md={6} sm={4}>
            <Card>
              <FlexView>
                {data?.level?.map(item => {
                  return (
                    <Card1>
                      <CardHeader>
                        <Indicator
                          color={
                            item.name === 'EASY'
                              ? '#1093FF'
                              : item.name === 'MEDIUM'
                              ? '#FEBB2C'
                              : item.name === 'HARD'
                              ? '#FE2C9D'
                              : ''
                          }
                        />
                        <CardHeaderTitle>
                          <b>{item.name}</b>
                        </CardHeaderTitle>
                      </CardHeader>

                      <CardFooter>
                        {item.score}
                        {item.percentage !== null ? (
                          <CardPerentage>{item.percentage}%</CardPerentage>
                        ) : null}
                      </CardFooter>
                    </Card1>
                  );
                })}
              </FlexView>
              {data.text === null ? (
                ''
              ) : (
                <>
                  <ReportText>{data.text?.title}</ReportText>
                  <CardContent>
                    <CardItem>
                      <ItemIcon>
                        <img src={Circle} />
                      </ItemIcon>
                      <ItemCaption flex={1} mt={'2px'} pl={'5px'}>
                        {'Mean Time'}
                      </ItemCaption>

                      <ItemIcon>
                        <img
                          title={'Percentile Rankings'}
                          src={InfoIcon}
                          alt={''}
                        />
                      </ItemIcon>
                      <ImageText flex={1} mt={'2px'} pl={'5px'}>
                        {data.text?.minutes} mins
                      </ImageText>
                    </CardItem>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Card1>
    );
  }
}
