/**
 * (c) CareerLabs. All rights reserved.
 **/
import { MenuItem } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { SelectBox } from '../../../assets/css/dashboard/viewmore/StyledStrengthComponent';
import { Container2 } from '../../../assets/css/StyledPractice';
import {
  HeadDiv,
  Heading,
  listItem,
  StyledSelect,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';

const TITLE = 'Mistake Log';

const defaultObj = {
  id: 'all',
  name: 'all',
  title: 'All',
};

export default function Top(props) {
  const {
    handleConceptChange,
    testType: testTypes,
    testTypeValue,
    subjects,
    subjectsValue,
    concepts,
    conceptsValue,
    handleTestTypeChange,
    handleSubjectChange,
    t,
    questionType,
    questionTypes = [],
    onChange,
    isQuestionDropdown,
  } = props;

  return (
    <HeadDiv>
      <Heading>{t(TITLE)}</Heading>
      <Container2 p="8px">
        <ThemeProvider theme={listItem}>
          <SelectBox>
            {/* <StyledSelect
              style={{ minWidth: '150px' }}
              IconComponent={ExpandMore}
              onChange={handleTestTypeChange}
              disableUnderline
              value={testTypeValue}
              MenuProps={DROP_DOWN_PROPS}
            >
              <MenuItem value="default" disabled>
                Test Type
              </MenuItem>
              {testTypes !== null &&
                testTypes.data.map(testType => (
                  <MenuItem key={testType.id} value={testType.id}>
                    {testType.title}
                  </MenuItem>
                ))}
            </StyledSelect> */}

            <StyledSelect
              style={{ minWidth: '150px' }}
              IconComponent={ExpandMore}
              onChange={handleSubjectChange}
              disableUnderline
              value={subjectsValue}
              MenuProps={DROP_DOWN_PROPS}
            >
              <MenuItem value="default" disabled>
                Subject
              </MenuItem>
              {subjects.length !== 0 &&
                subjects.data.map(subject => (
                  <MenuItem key={subject.id} value={subject.id}>
                    {subject.title}
                  </MenuItem>
                ))}
            </StyledSelect>

            <StyledSelect
              style={{ minWidth: '150px' }}
              IconComponent={ExpandMore}
              onChange={handleConceptChange}
              disableUnderline
              value={conceptsValue}
              MenuProps={DROP_DOWN_PROPS}
            >
              <MenuItem value="default" disabled>
                Concept
              </MenuItem>
              {[defaultObj, ...((concepts && concepts.data) || [])].map(
                concept => (
                  <MenuItem key={concept.id} value={concept.id}>
                    {concept.title}
                  </MenuItem>
                )
              )}
            </StyledSelect>
            <StyledSelect
              style={{ minWidth: '150px',opacity:!isQuestionDropdown(subjects, subjectsValue) && 0.2}}
              name="questionType"
              onChange={onChange}
              value={questionType}
              IconComponent={ExpandMore}
              disableUnderline
              MenuProps={DROP_DOWN_PROPS}
               disabled={!isQuestionDropdown(subjects, subjectsValue)}
            >
              <MenuItem value="default" disabled>
                Question Type
              </MenuItem>
              {[defaultObj, ...questionTypes].map(type => {
                return <MenuItem value={type.title}>{type.title}</MenuItem>;
              })}
            </StyledSelect>
            <StyledSelect
              style={{ minWidth: '150px' }}
              IconComponent={ExpandMore}
              onChange={handleTestTypeChange}
              disableUnderline
              value={testTypeValue}
              MenuProps={DROP_DOWN_PROPS}
            >
              <MenuItem value="default" disabled>
                Test Type
              </MenuItem>
              {testTypes !== null &&
                testTypes.data.map(testType => (
                  <MenuItem key={testType.id} value={testType.id}>
                    {testType.title}
                  </MenuItem>
                ))}
            </StyledSelect>
          </SelectBox>
          <span style={{ paddingRight: '10px' }}>
            <StyledSelect
              name=""
              IconComponent={ExpandMore}
              disableUnderline
              onChange={handleConceptChange}
              value={'sortbydate'}
              disabled
              MenuProps={DROP_DOWN_PROPS}
            >
              <MenuItem value="sortbydate">{t('Sort by date')}</MenuItem>
            </StyledSelect>
          
          </span>
        </ThemeProvider>
      </Container2>
    </HeadDiv>
  );
}
