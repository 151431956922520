/**
 * (c) CareerLabs. All rights reserved.
 **/
import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid, TextField, ThemeProvider } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import {
  CardShadow,
  CardView,
  CustomHelperText,
  TopGridSubPara,
  TopTitle,
} from '../../../../assets/css/StyledComponent';
import { getGoals } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { updateExamDetails } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import Button from '../../../../utils/components/Button';
import { isEmpty, isNumbers } from '../../../../utils/Validation';
import { dateFormat } from '../../../../utils/methods/DateFormat';
import { CLEVERTAP } from '../../../../clevertap/Config';
import { EVENTS } from '../../../../clevertap/Events';

class BeforeGmatScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: null,
      scoreErr: false,
      scoreMsg: '',
      date: null,
      dateErr: false,
      dateMsg: '',
      minScore: 0,
      maxScore: 0,
      isLoading: false,
    };
  }
  handleKeyPress = () => {};
  componentDidMount() {
    this.props.getCourses();
    this.props.getGoals(response => {
      if (response.success) {
        this.setState({
          score: response.data.score !== 0 ? response.data.score : null,
        });
        if (response.data.completedExamDate)
          this.setState({
            date: new Date(response.data.completedExamDate),
          });
      }
      if (response.data.marks) {
        this.setState({
          minScore: Math.min(
            ...response.data.marks.map(item => parseInt(item.value))
          ),
          maxScore: Math.max(
            ...response.data.marks.map(item => parseInt(item.value))
          ),
        });
      }
    });
  }

  handleClick = () => {
    this.setState({ isLoading: true });
    if (isEmpty(this.state.score)) {
      this.setState({
        scoreErr: true,
        scoreMsg: 'Please fill the required field',
        isLoading: false,
      });
    } else if (this.state.score < this.state.minScore) {
      this.setState({
        scoreErr: true,
        scoreMsg: `It should accept ${this.state.minScore} to ${this.state.maxScore}`,
        isLoading: false,
      });
    } else if (this.state.score > this.state.maxScore) {
      this.setState({
        scoreErr: true,
        scoreMsg: `It should accept ${this.state.minScore} to ${this.state.maxScore}`,
        isLoading: false,
      });
    } else {
      this.setState({
        scoreErr: false,
        scoreMsg: ``,
      });
    }

    if (isEmpty(this.state.date)) {
      this.setState({
        dateErr: true,
        dateMsg: 'Please fill the required field',
        isLoading: false,
      });
    } else if (
      !isEmpty(this.state.score) &&
      !isEmpty(this.state.date) &&
      parseInt(this.state.score) >= this.state.minScore &&
      parseInt(this.state.score) <= this.state.maxScore
    ) {
      let obj = {
        productName: JSON.parse(window.localStorage.getItem('userProduct'))
          .productName,
        score: this.state.score,
        completedExamDate: dateFormat(new Date(this.state.date)),
      };
      this.props.updateExamDetails(obj, res => {
        this.setState({ isLoading: false });
        this.props.getCourses();
        this.props.history.push(routePaths.start);
        // this.props.history.push(routePaths.introduction);
      });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <CardView>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            {' '}
            <Box pt={5} pb={5}>
              {' '}
              <TopTitle>
                {t(
                  `What was your ${
                    JSON.parse(window.localStorage.getItem('userProduct'))
                      .productName
                  } score?`
                )}
              </TopTitle>{' '}
            </Box>
          </Grid>
          <Grid md={4} sm={4} xs={12}>
            <Box pt={2} pb={1}>
              <TopGridSubPara>{t('Enter your score *')}</TopGridSubPara>
            </Box>
          </Grid>
          <Grid xs={12}></Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box pb={2}>
              <CardShadow style={{ padding: 10 }}>
                <TextField
                  id="no-outline"
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  name="score"
                  placeholder={t('Your score here')}
                  value={this.state.score !== 0 ? this.state.score : null}
                  onKeyPress={evt => {
                    if (isNumbers(evt)) evt.preventDefault();
                  }}
                  onChange={e => {
                    this.setState({ scoreMsg: '', scoreErr: false });
                    this.setState({ score: e.target.value });
                  }}
                ></TextField>
              </CardShadow>
              {this.state.scoreErr && (
                <CustomHelperText>{t(this.state.scoreMsg)}</CustomHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid md={4} xs={12} sm={4}>
            <Box pb={1}>
              <TopGridSubPara>{t('Date of last exam *')}</TopGridSubPara>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={4} sm={4}>
            <CardShadow style={{ padding: 10 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="MM/dd/yyyy"
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  inputProps={{ readOnly: true, id: 'no-outline' }}
                  placeholder={t('Your date here')}
                  value={this.state.date}
                  onChange={date => {
                    this.setState({ dateMsg: '', dateErr: false });
                    this.setState({ date: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </CardShadow>
            {this.state.dateErr && (
              <CustomHelperText>{t(this.state.dateMsg)}</CustomHelperText>
            )}
          </Grid>

          <Grid item xs={12}></Grid>
          <Box pt={15}></Box>
          <ThemeProvider theme={buttonTheme}>
            <Button
              className={'btn__alignment'}
              variant="outlined"
              color="primary"
              onClick={() => this.props.history.push(routePaths.test)}
            >
              {t('Back')}
            </Button>
          </ThemeProvider>
          <Grid item md={1} sm={1} xs={12}></Grid>
          <ThemeProvider theme={buttonTheme}>
            <Button
              className={'btn__alignment'}
              variant="contained"
              color="primary"
              onClick={this.handleClick}
              loading={this.state.isLoading}
            >
              {t('Next')}
            </Button>
          </ThemeProvider>
        </Grid>
      </CardView>
    );
  }
}

const mapStateToProps = state => {
  return {
    examDetailsResponse: state.testReducer.examDetails,
    goal: state.CourseReducer.goals,
  };
};

export default connect(mapStateToProps, {
  updateExamDetails,
  getCourses,
  getGoals,
})(withTranslation()(BeforeGmatScore));
