import React, { useEffect, useRef, useState } from 'react';

const MathElement = ({ math }) => {
  const ref = React.createRef();
  // const [ref,setRef]=useState();
  const element = math || '';
  // useEffect(() => {
  //   renderMath();
  // }, []);

  useEffect(() => {
    renderMath();
  }, [element]);

  const renderMath = () => {
    if (window.MathJax)
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, element]);
  };

  return (
    <div
      className="rc-passage-position"
      ref={ref}
      key={Math.random(0, 20).toString()}
      dangerouslySetInnerHTML={{ __html: element }}
    />
  );
};

export default React.memo(MathElement);
