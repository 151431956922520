import React from 'react';
import {
  createTheme,
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  MenuItem,
  withStyles,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const DropDownIcon = withStyles({
  root: {
    fill: '#323232',
  },
})(ArrowDropDownIcon);

const selectTheme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        marginLeft: '1rem',
        width: '130px',
      },
    },
    MuiInputBase: {
      root: {
        width: '130px',
        height: '35px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'rgba(5, 42, 78, 0.05)',
        },
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '20px',
        color: '#052A4E',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
      formControl: {
        transform: 'translate(8px, 8px) scale(0.9)',
      },
    },
    MuiListItem: {
      root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '20px',
        color: '#052A4E',
      },
    },
  },
});
export default function CKEditorDropDown(props) {
  // label and items array as props
  const { label, items, value, disabled } = props;
  if (props.value !== null || undefined)
    return (
      <React.Fragment>
        <ThemeProvider theme={selectTheme}>
          <FormControl disabled={disabled}>
            <InputLabel id={label}>{label}</InputLabel>

            <Select
              variant="outlined"
              labelId={label}
              label={label}
              value={value}
              key={value}
              IconComponent={DropDownIcon}
              {...props}
            >
              {props.items &&
                Array.isArray(props.items) &&
                props.items.length !== 0 &&
                props.items.map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    disabled={item.disabled}
                  >
                    {item.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </ThemeProvider>
      </React.Fragment>
    );
  else return null;
}
