/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Typography } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { Alert } from '@material-ui/lab';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../assets/css/Cal.css';
import {
  BodyTable,
  CommentText,
  Container,
  DateBox,
  HeaderTable,
  InnerDateBox,
  TableCell,
  TableContainer,
  TableHeadRow,
} from '../../../assets/css/dashboard/CalendarComponents';
import {
  CalendarCard,
  CalendarDate,
  CalendarDay,
  CalendarDayDivider,
  DayTask,
  DayTitle,
  DayTopic,
} from '../../../assets/css/StyledComponent';
import StatusIcon from '../../../assets/icons/StatusIcon';
import { EVENTS } from '../../../clevertap/Events';
import { getStudyPlan } from '../../../redux/action/Dashboard';
import { removeWebinar } from '../../../redux/action/DashboardLanding';
import { routePaths } from '../../../routes/RoutePath';
import LandingCard from '../../../utils/studyPlans/LandingCard';
import Navigation from '../../../utils/studyPlans/Navigation';
import WebinarCard from './components/WebinarCard';
import clevertap from 'clevertap-web-sdk';
import { QS_PROPS } from '../../../constant/Variables';
import QueryString from 'qs';
class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: -1,
      selectedTopicId: null,
      selectedTopicLength: 0,
      currentMonth: -1,
      studyPlan: '',
      calenderArrLen: 0,
      year: new Date().getFullYear(),
      hovered: false,
    };
  }

  weekDays = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

  componentDidMount() {
    this.props.getStudyPlan(response => {
      this.setState({
        studyPlan: response.data,
      });
      this.setState({
        selectedMonth: response.data[this.state.calenderArrLen].month,
        currentMonth: response.data[this.state.calenderArrLen].month,
        year: response.data[this.state.calenderArrLen].year,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.calenderArrLen !== prevState.calenderArrLen) {
      this.setState({
        selectedMonth:
          this.props.studyPlan.data[this.state.calenderArrLen].month,
        currentMonth:
          this.props.studyPlan.data[this.state.calenderArrLen].month,
        year: this.props.studyPlan.data[this.state.calenderArrLen].year,
      });
    }
  }

  handleTaskClick = (id, taskObj) => {
    const { month } = QueryString.parse(this.props.location.search, QS_PROPS);
    clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Watched source': 'Via study plan click',
    });
    this.props.history.push({
      pathname: routePaths.dashboard.taskDetail,
      search: `?topicId=${id}&prevPage=study_plan_calendar&month=${month}`,
    });
  };

  handleDateClick = currentDate => {
    this.props.history.push(
      `${routePaths.dashboard.dailyCalendar}?currentDate=${currentDate.split('T')[0]
      }`
    );
  };

  handleNextClick = () => {
    if (this.props.studyPlan.data.length - 1 !== this.state.calenderArrLen) {
      this.setState({
        calenderArrLen: this.state.calenderArrLen + 1,
      });
    }
  };

  handlePreviousClick = () => {
    if (this.state.calenderArrLen > 0) {
      this.setState({
        calenderArrLen: this.state.calenderArrLen - 1,
      });
    }
  };

  handleMouseHover = (data, e) => {
    this.setState({
      selectedTopicId: data.date || '',
      selectedTopicLength: data.topics.length,
    });
  };

  renderTableBody = month => {
    const { studyPlan } = this.props;
    if (studyPlan.success) {
      var obj;
      var arr = [];
      for (let j = 0; j < studyPlan.data.length; j++) {
        if (studyPlan.data[j].month === month) {
          for (let i = 0; i < studyPlan.data[j].days.length; i++) {
            obj = {
              ...obj,
              [studyPlan.data[j].days[i].day]: studyPlan.data[j].days[i],
            };

            if (i === studyPlan.data[j].days.length - 1) {
              arr.push(obj);
              obj = null;
            }
            if (i !== 0 && (i + 1) % 7 === 0) {
              arr.push(obj);
              obj = null;
            }
          }
        }
      }
      return arr.map((data, index) => {
        if (data)
          return (
            <TableRow id={index}>
              <TableCell
                className={`table_cell_align ${data.Mon && data.Mon.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Mon && <CalendarDayDivider />}
                {data.Mon && this.renderCard(data.Mon)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Tue && data.Tue.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Tue && <CalendarDayDivider />}
                {data.Tue && this.renderCard(data.Tue)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Wed && data.Wed.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Wed && <CalendarDayDivider />}
                {data.Wed && this.renderCard(data.Wed)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Thu && data.Thu.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Thu && <CalendarDayDivider />}
                {data.Thu && this.renderCard(data.Thu)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Fri && data.Fri.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Fri && <CalendarDayDivider />}
                {data.Fri && this.renderCard(data.Fri)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Sat && data.Sat.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Sat && <CalendarDayDivider />}
                {data.Sat && this.renderCard(data.Sat)}
              </TableCell>
              <TableCell
                className={`table_cell_align ${data.Sun && data.Sun.isToday ? 'tody-background' : ''
                  }`}
              >
                {data.Sun && <CalendarDayDivider />}
                {data.Sun && this.renderCard(data.Sun)}
              </TableCell>
            </TableRow>
          );
      });
    }
  };

  renderCard(data) {
    return (
      <DateBox
        key={data.date}
        onMouseOver={e => this.handleMouseHover(data, e)}
      >
        <InnerDateBox isToday={data.isToday}>
          <CalendarDayDivider id="calendar-divider" />
          <div className="top-date-container">
            <CalendarDate
              onClick={() => this.handleDateClick(data.currentDate)}
            >
              {data.date}
            </CalendarDate>
            <WebinarCard data={data} />
          </div>
          {data.topics
            .slice(
              0,
              data.date === this.state.selectedTopicId
                ? this.state.selectedTopicLength
                : 3
            )
            .map((item, index) => {
              return (
                <div
                  id={index}
                  onClick={() =>
                    this.handleTaskClick(item.id, {
                      conceptName: item.concept,
                      topicName: item.title,
                    })
                  }
                  className="cursor"
                >
                  <Box pt={2} className={'calendar_align'}>
                    <div>
                      <img
                        className={'task_status'}
                        src={StatusIcon(item.status)}
                        alt={''}
                      />
                    </div>
                    <Box pl={1}>
                      <DayTitle>{item.title}</DayTitle>
                    </Box>
                  </Box>
                  <Box pt={2} pl={3}>
                    <DayTask>
                      {item.count} Task &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {item.duration}
                    </DayTask>
                  </Box>
                  <Box pt={2} pl={3}>
                    <DayTopic>{item.concept}</DayTopic>
                  </Box>
                </div>
              );
            })}
        </InnerDateBox>
      </DateBox>
    );
  }

  render() {
    return (
      <Container>
        {/* Navigation Route */}
        <Box pt={2}>
          <Navigation backPath={routePaths.dashboard.home} />
        </Box>
        {JSON.parse(localStorage.getItem('userProduct')) &&
          !JSON.parse(localStorage.getItem('userProduct'))
            .isCalibrationCompleted && (
            <Box padding={'0px 48px'}>
              <Alert severity="warning">
                <CommentText variant={'body1'}>
                  {
                    'Study Plan Not Customized due to lack of Calibration Test Results'
                  }
                </CommentText>
              </Alert>
            </Box>
          )}
        {/* Landing card */}
        <Box pt={2}>
          <LandingCard
            data={this.state.studyPlan}
            month={this.state.currentMonth - 1}
            next={this.handleNextClick}
            prev={this.handlePreviousClick}
            currentLen={this.state.calenderArrLen}
            year={this.state.year}
          />
        </Box>
        {/* calendar-date study plan */}
        <Box
          pt={3}
        // style={{ paddingTop: '24px', overflowY: 'scroll', height: '100%' }}
        >
          <CalendarCard>
            <HeaderTable>
              <TableHeadRow>
                {this.weekDays.map((days, index) => (
                  <TableCell id={index}>
                    <Box>
                      <CalendarDay
                        id={index}
                        color={
                          new Date().getDay() === index + 1 &&
                            new Date().getMonth() ===
                            this.state.currentMonth - 1 &&
                            new Date().getFullYear() === this.state.year
                            ? '#1093FF'
                            : null
                        }
                      >
                        {days}
                      </CalendarDay>
                    </Box>
                  </TableCell>
                ))}
              </TableHeadRow>
            </HeaderTable>
            <TableContainer>
              {/* body */}
              <BodyTable>
                <TableBody>
                  {this.renderTableBody(this.state.currentMonth)}
                </TableBody>
              </BodyTable>
            </TableContainer>
          </CalendarCard>
        </Box>
        <Box height="8vh" />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    studyPlan: state.DashBoardReducer.studyPlan,
    user: state.studentReducer.user,
  };
};

export default connect(mapStateToProps, { getStudyPlan, removeWebinar })(
  withRouter(MonthlyCalendar)
);