/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, Snackbar } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Rating } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageLoader from '../../../utils/components/PageLoader';
import { AddNoteButton, ToolTip } from '../../../assets/css/MuiStyles';
import { AddNote, TaskContainer } from '../../../assets/css/StyledComponent';
import AddNotesIcon from '../../../assets/icons/addNotes.svg';
import RocketIcon from '../../../assets/icons/BlueRocket.svg';
import BookmarkIcon from '../../../assets/icons/Bookmarks.svg';
import FeedbackIcon from '../../../assets/icons/Feedback.svg';
import { QS_PROPS } from '../../../constant/Variables';
import { updatePlayTime } from '../../../redux/action/Course';
import Backdrop from '@mui/material/Backdrop';
import {
  addNotes,
  getNotesWithTask,
  getStartInVideo,
  getTopic,
  getTopicDetail,
  pauseTask,
  removeNote,
  setNoteChanges,
  updateCompletedTask,
  videoAnswer,
} from '../../../redux/action/Dashboard';
import { AddBookmarks } from '../../../redux/action/Practice';
import { routePaths } from '../../../routes/RoutePath';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import DialogComponent from '../../../utils/components/DialogComponent';
import simulateMouseClick from '../../../utils/methods/SimulateMouseClick';
import LeftCard from './LeftCard';
import { RenderAddNotePopup } from './Notes';
import RightCard from './RightCard';
import TaskTitleCard from './TaskTitleCard';

class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingPoint: 0,
      taskId: '',
      topicDetail: null,
      selectedStep: null,
      currentStatus: '',
      isBookmarked: false,
      dialogOpen: false,
      feedOpen: false,
      notesPopup: false,
      commentBoxOpen: false,
      noteData: [],
      menuAnchorEl: null,
      editNoteError: false,
      editableId: null,
      boosterDialog: false,
      currenElement: null,
      loading: true,
      addNotesPopupOpen: false,
      highlightText: '',
      notes: '',
      isAddNotes: false,
      selectedNotes: '',
      popoverX: 0,
      popoverY: 0,
      selectedNoteId: '',
      start: 0,
      end: 38,
      popoverTask: false,
      addNotesTop: 300,
      addNotesRight: 100,
      snackBarOpen: false,
      snackBarSeverity: 'warning',
      message: 'Kindly select your Rating',
      feedObj: {},
      videoId: null,
      feedbackCount: 0,
      selectedTask: [],
      activeVideo: null,
      time: '',
      testAttempted: false,
      isShowVideoQuestion: false,
      vdoQuest: {},
      correctChoice: [],
      videoAnswer: {},
      greenAnswer: false,
      selected: null,
      correctVideoAnswer: {},
      subject: null,
      month: null,
    };
    this.timer = null;

    this.time = 0;
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.videosObj = {};
    this.videoId = [];
  }

  menu = [
    {
      name: 'edit',
      text: 'Edit',
    },
    {
      name: 'delete',
      text: 'Delete',
    },
  ];

  // Get task video feedback session storage and to show the feedback popup
  renderFeedBack = () => {
    let taskVideoFeedback = window.sessionStorage.getItem(
      'task_video_feedback'
    );
    if (taskVideoFeedback) {
      taskVideoFeedback = JSON.parse(taskVideoFeedback);
      if (taskVideoFeedback.feedOpen) {
        this.setState({
          feedOpen: taskVideoFeedback.feedOpen,
          feedbackCount: 0,
          feedObj: taskVideoFeedback.feedObj,
          videoId: taskVideoFeedback.videoId,
        });
      }
    }
  };

  componentDidMount() {
    // localStorage.removeItem('vdoCompleted');
    window.document.addEventListener('click', this.handleGeneralClick, true);
    localStorage.setItem('URLPath', window.location.search);
    window.scrollTo(0, 0);

    const { topicId, taskId, type, isNewVideos, prevPage, month } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.setState({ subject: prevPage, month: month });

    if (!taskId)
      this.props.getTopicDetail(topicId, '', type, isNewVideos, response => {
        this.renderFeedBack();

        for (let i = 0; i < response.data.tasks.length; i++) {
          if (
            response.data.tasks[i].status === 'TODO' ||
            response.data.tasks[i].status === 'PROGRESS'
          ) {
            this.props.getNotesWithTask(response.data.tasks[i].id);
            return this.setState({
              topicDetail: response,
              selectedStep: response.data.tasks[i].id,
              currentStatus: response.data.tasks[i].status,
              isBookmarked: response.data.tasks[i].isBookmarked,
              selectedTask:
                response.data.tasks.length !== 0
                  ? response.data.tasks.map(item => item.id)
                  : [],
              // activeVideo:
              //   response.data.tasks[0]?.['contentVideo']?.[0]?.['id'],
              activeVideo: response.data.tasks[i].type === "TEXT" ? response.data.tasks[i]?.id :
                (response.data.tasks[i].contentVideo.filter(
                  item => item.status !== "COMPLETED"
                )[0]?.['id']),
              loading: false,
            });
          }
        }
        this.setState({
          selectedStep: response.data.tasks[0].id,
          topicDetail: response,
          selectedTask:
            response.data.tasks.length !== 0
              ? response.data.tasks.map(item => item.id)
              : [],
          activeVideo: response.data.tasks[0]?.['contentVideo']?.[0]?.['id'],
          loading: false,
        });
      });
    else
      this.props.getTopicDetail(
        topicId,
        taskId,
        type,
        isNewVideos,
        response => {
          for (let i = 0; i < response.data.tasks.length; i++) {
            if (response.data.tasks[i].id === taskId) {
              this.props.getNotesWithTask(taskId);
              this.renderFeedBack();

              return this.setState({
                topicDetail: response,
                selectedStep: taskId,
                currentStatus: response.data.tasks[i].status,
                isBookmarked: response.data.tasks[i].isBookmarked,
                selectedTask:
                  response.data.tasks.length !== 0
                    ? response.data.tasks.map(item => item.id)
                    : [],
                activeVideo: response.data.tasks.filter(
                  item => item.type === "TEXT" && item.id === taskId
                )?.[0]?.type === "TEXT" ? (response.data.tasks.filter(
                  item => item.id === taskId
                )[0]?.['id']) : (response.data.tasks.filter(
                  item => item.id === taskId
                )[0]?.['contentVideo']?.[0]?.['id']),
                // activeVideo: response.data.tasks.filter(
                //   item => item.id === taskId
                // )[0]?.['contentVideo']?.[0]?.['id'],
                loading: false,
              });
            }
          }
        }
      );

    this.timerFunction('start');
    this.textSelection();
    this.highlightText();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedStep } = this.state;
    console.log(prevProps, 'fjhfhhgghghghgh');
    const notes = this.props.notes.data;
    console.log(this.props.vdoCompleted, 'ddfff');
    //  console.log(this.props.vdoC "vdoCopleted")
    // this.setState({ testAttempted: false });

    if (prevProps.notes.data !== notes) {
      this.setState({ noteData: notes });
      this.highlightText(notes);
    }
    if (selectedStep && prevState.selectedStep !== selectedStep) {
      this.props.getNotesWithTask(selectedStep);
    }
    console.log(JSON.parse(localStorage.getItem('vdoCompleted')));
    if (localStorage.getItem('vdoCompleted')) {
      const { topicId, type, boost, isNewVideos } = QueryString.parse(
        this.props.location.search,
        QS_PROPS
      );
      let obj = {
        taskId: this.state.selectedStep,
        videoId: this.state.activeVideo,
        time: this.time,
        type: type ? type.toUpperCase() : 'NORMAL',
        isNewVideos: Boolean(isNewVideos),
      };
      this.props.updateCompletedTask(obj, type, isNewVideos, res => {
        if (res.success) {
          console.log(res, 'completedheck');
          this.setNextTaskAndTaskContent();

          this.props.getTopicDetail(
            topicId,
            this.state.selectedStep,
            type,
            isNewVideos,
            response => {
              this.setState({
                topicDetail: response,
                loading: false,
              });
            }
          );
        }
      });
    }
    //    if(vdoCompleted === true)
    //    {
    //    this.props.updateCompletedTask(obj, type, isNewVideos, res => {
    //     if (res.success) {
    //       this.setNextTaskAndTaskContent();

    //       this.props.getTopicDetail(
    //         topicId,
    //         this.state.selectedStep,
    //         type,
    //         isNewVideos,
    //         response => {
    //           this.setState({
    //             topicDetail: response,
    //             loading: false,
    //           });
    //         }
    //       );
    //     }
    //   });
    // }
  }

  componentWillUnmount() {
    // localStorage.removeItem('vdoCompleted');
    console.log('hiiiiii');
    const { type, isNewVideos } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    this.timerFunction('stop');
    let obj = {
      taskId: this.state.selectedStep,
      videoId: this.state.activeVideo,
      time: this.time,
      type: type ? type.toUpperCase() : 'NORMAL',
      isNewVideos: Boolean(isNewVideos),
    };

    this.props.pauseTask(obj, () => { });

    window.document.removeEventListener('click', this.handleGeneralClick, true);
  }

  timerFunction = (operation, item) => {
    if (operation === 'start') {
      this.timer = setInterval(() => {
        this.time = this.time + 1;
      }, 1000);
    } else if (operation === 'stop') {
      clearInterval(this.timer);
      this.setState({ time: 0 });
      this.timer = null;
    }
  };

  textSelection = () => {
    var popover = document.getElementById('text-popover');
    popover.style.display = 'none';
    var p = document.getElementsByTagName('p');

    for (let i = 0; i < p.length; i++) {
      p[i].addEventListener('mouseup', e => {
        if (
          window.getSelection &&
          window.getSelection().toString().length > 0
        ) {
          var text = window.getSelection().toString();
          this.setState({ highlightText: text });
          var position = window
            .getSelection()
            .getRangeAt(0)
            .getBoundingClientRect();
          this.setState({
            addNotesTop: position.top - 240 > 150 ? position.top - 240 : 150,
            // addNotesRight: 100,
          });
          popover.style.display = 'block';
          let topValue = position.top + (position.height / 2 - 32);
          popover.style.top = `${topValue}px`;
        } else popover.style.display = 'none';
      });
    }
  };

  /**
   * Replacing Html entities with Strings
   * @param {String} str
   */
  convertHtmlToText(str) {
    str = str.replace(/<\/?span[^>]*>/g, '');
    var translate_re =
      /&(nbsp|amp|quot|lt|gt|apos|cent|pound|yen|euro|copy|reg);/g;

    var translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
      apos: "'",
      cent: '¢',
      pound: '£',
      yen: '¥',
      euro: '€',
      copy: '©',
      reg: '®',
    };

    return str.replace(translate_re, (match, entity) => translate[entity]);
  }

  highlightText = (data = []) => {
    var mark = document.getElementsByTagName('mark');
    var p = document.getElementsByTagName('p');
    for (let i = 0; i < p.length; i++) {
      data.map(item => {
        var cleanText = item.data.trim();
        let newText = this.convertHtmlToText(p[i].innerHTML).replace(
          cleanText,
          `<mark id='${item.id}' >${item.data}</mark>`
        );
        p[i].innerHTML = newText;
      });
    }

    for (let i = 0; i < mark.length; i++) {
      mark[i].addEventListener('click', e => {
        var rect = document
          .getElementById('text-container')
          .getBoundingClientRect();
        var markRect = document
          .getElementById(e.currentTarget.id)
          .getBoundingClientRect();

        this.setState({
          popoverX: Math.abs(rect.top - markRect.top),
          popoverY: Math.abs(rect.y - markRect.y),
          selectedNoteId: e.currentTarget.id,
          popoverTask: true,
        });
      });
    }
  };

  handleGeneralClick(e) {
    const { boost } = QueryString.parse(this.props.location.search, QS_PROPS);

    const clsName = e.target.className.baseVal || e.target.className;
    if (boost && _.isString(clsName) && clsName.indexOf('on-boost-mode') > -1) {
      e.preventDefault();
      e.target.onclick = null;
      e.stopPropagation();
      this.setState({ boosterDialog: true, currenElement: e.target });
    }
  }

  handleLeftCardClick = (e, newTaskId) => {
    e.stopPropagation();
    e.preventDefault();
    const { topicId, type, isNewVideos, boost } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let obj = {
      taskId: this.state.selectedStep,
      videoId: this.state.activeVideo,
      time: this.time,
      type: type ? type.toUpperCase() : 'NORMAL',
      isNewVideos: Boolean(isNewVideos),
    };

    this.props.pauseTask(obj, res => {
      if (res.success) {
        return this.props.getTopicDetail(
          topicId,
          newTaskId,
          type,
          isNewVideos,
          response => {
            for (let i = 0; i < response.data.tasks.length; i++) {
              if (response.data.tasks[i].id === newTaskId) {
                this.setState({
                  topicDetail: response,
                  currentStatus: response.data.tasks[i].status,
                });
              }
            }
          }
        );
      }
    });

    this.setState({
      testAttempted: false,
      selectedStep: newTaskId,
      activeVideo: e.target.id,
      isBookmarked: Boolean(
        this.props.topics &&
        this.props.topics.data.contents
          .filter(item => item.id === newTaskId)
          .pop()?.isBookmarked
      ),
    });

    this.timerFunction('stop');

    //---------- Restarting the timer --------------
    this.time = 0;
    this.timerFunction('start');

    var element = document.getElementsByTagName('mark'),
      index;
    for (index = element.length - 1; index >= 0; index--) {
      var textNode = document.createTextNode(element[index].innerText);
      element[index].parentNode.replaceChild(textNode, element[index]);
    }

    if (boost) {
      if (type) {
        if (isNewVideos) {
          this.props.history.replace(
            `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${newTaskId}&type=${type}&boost=${boost}&isNewVideos=${isNewVideos}`
          );
        } else {
          this.props.history.replace(
            `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${newTaskId}&type=${type}&boost=${boost}`
          );
        }
      } else {
        this.props.history.replace(
          `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${newTaskId}&boost=${boost}`
        );
      }
    } else
      this.props.history.replace(
        `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${newTaskId}`
      );
  };

  setNextTaskAndTaskContent = () => {
    localStorage.removeItem('vdoCompleted');
    let tasks = this.state.topicDetail?.data?.tasks || [];
    let currentTask = tasks.find(task => task.id === this.state.selectedStep);
    if (
      currentTask &&
      currentTask.contentVideo &&
      currentTask.contentVideo.length
    ) {
      let contentVideo = currentTask.contentVideo.find(
        video =>
          video.status !== 'COMPLETED' && video.id !== this.state.activeVideo
      );
      if (contentVideo) {
        this.setState({
          testAttempted: false,
          selectedStep: currentTask.id,
          activeVideo: contentVideo.id,
          currentStatus: currentTask.status,
        });
        return;
      }
    }
    tasks = tasks.filter(
      item => item.status !== 'COMPLETED' && item.id !== this.state.selectedStep
    );
    if (tasks.length)
      var loop = true;
    if (loop) {
      tasks.forEach(task => {
        if (loop) {
          if (task.contentVideo && task.contentVideo.length) {
            let contentVideo = task.contentVideo.find(
              video => video.status !== 'COMPLETED'
            );
            if (contentVideo) {
              let newSelectedTask = [...this.state.selectedTask];
              if (!newSelectedTask.includes(task.id))
                newSelectedTask.push(task.id);
              this.setState({
                selectedStep: task.id,
                activeVideo: contentVideo.id,
                currentStatus: task.status,
                selectedTask: newSelectedTask,
              });
            }
            loop = false;
          }
          else {
            let newSelectedTask = [...this.state.selectedTask];
            newSelectedTask.push(task.id);
            this.setState({
              selectedStep: task.id,
              activeVideo: task.id,
              currentStatus: task.status,
              selectedTask: newSelectedTask,
            });
            loop = false;
          }
        }
      });
    }
  };

  handleReadClick = () => {
    this.setState({ loading: true });
    const { topicId, type, boost, isNewVideos } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );

    let obj = {
      taskId: this.state.selectedStep,
      videoId: this.state.activeVideo,
      time: this.time,
      type: type ? type.toUpperCase() : 'NORMAL',
      isNewVideos: Boolean(isNewVideos),
    };

    this.timerFunction('stop');

    this.props.updateCompletedTask(obj, type, isNewVideos, res => {
      if (res.success) {
        this.setNextTaskAndTaskContent();

        this.props.getTopicDetail(
          topicId,
          this.state.selectedStep,
          type,
          isNewVideos,
          response => {
            this.setState({
              topicDetail: response,
              loading: false,
            });
          }
        );
      }
    });

    //---------- Restarting the timer --------------
    this.time = 0;
    this.timerFunction('start');
  };

  handleVideoQuestion = (e, index) => {
    const { videosObj } = this;

    videosObj.pause();
    this.setState({
      isShowVideoQuestion: true,
    });
    // let vido ='bf7496f0-ed36-4871-b303-c00af041f9be';
    this.props.getStartInVideo(this.state.activeVideo, response => {
      if (response.success) {
        // console.log(response.data);
        this.setState({
          vdoQuest: response.data,
          //  ihandleVideoQsShowVideoQuestion: true,
        });
      }
    });
  };

  handleBookmarkClick = () => {
    if (this.state.isBookmarked) {
      this.setState({ dialogOpen: true });
    } else this.handleButton2Click();
  };

  handleButton2Click = () => {
    const currentTask = this.state.topicDetail.data.tasks
      .filter(item => item.id === this.state.selectedStep)
      .pop();

    let bookmarkData = { id: currentTask.id, type: 'task' };
    this.props.AddBookmarks(bookmarkData, response => {
      if (response.success) {
        this.setState({
          isBookmarked: response.data.isBookmarked,
          dialogOpen: false,
        });
      }
    });
  };

  // Video ending api call after the feedbackCount
  handleButtonClick = () => {
    const { topicId, type, isNewVideos } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const { feedbackCount } = this.state;
    if (feedbackCount === 0 || feedbackCount === null) {
      this.setState({
        snackBarOpen: !this.state.snackBarOpen,
      });
    } else {
      let payload = {
        ...this.state.feedObj,
        feedbackCount: this.state.feedbackCount,
        taskId: this.state.selectedStep,
        videoId: this.state.activeVideo,
        time: this.time,
        type: type ? type.toUpperCase() : 'NORMAL',
      };
      this.props.updatePlayTime(this.state.videoId, payload, res => {
        if (res) {
          console.log(res, 'fdffdjdfjj');
          this.props.getTopicDetail(
            topicId,
            this.state.selectedStep,
            type,
            isNewVideos,
            response => {
              this.setState({
                topicDetail: response,
              });
            }
          );
          this.setState({
            feedOpen: false,
            feedbackCount: feedbackCount,
            feedObj: {},
            videoId: null,
          });
          window.sessionStorage.removeItem('task_video_feedback');
        }
      });
    }
  };

  handleFeedBack = (feedObj, videoId) => {
    window.sessionStorage.setItem(
      'task_video_feedback',
      JSON.stringify({
        feedbackCount: 0,
        feedObj: feedObj,
        videoId: videoId,
        feedOpen: true,
      })
    );
    this.setState({
      feedOpen: true,
      feedbackCount: 0,
      feedObj: feedObj,
      videoId: videoId,
    });
  };

  handleNotesIconClick = () => {
    this.setState({ notesPopup: !this.state.notesPopup });
  };

  handleMenuItem = name => {
    const { noteId, notes, highlightText, selectedStep } = this.state;
    this.setState({ menuAnchorEl: null });
    if (name === 'edit') {
      this.setState({ editableId: noteId });
    }
    if (name === 'delete') {
      this.props.removeNote(selectedStep, noteId, response => {
        if (response.success) {
          this.props.getNotesWithTask(selectedStep);
          this.setState({
            noteId: null,
            editableId: null,
          });

          // Remove Highlighted Text
          var element = document.getElementsByTagName('mark'),
            index;
          for (index = element.length - 1; index >= 0; index--) {
            if (element[index]['id'] === noteId) {
              var textNode = document.createTextNode(element[index].innerText);
              element[index].parentNode.replaceChild(textNode, element[index]);
            }
          }
        }
      });
    }
    if (name === 'resize') {
      const { subjectId, conceptId, id } = this.props.topics.data;
      this.props.history.push(
        `${routePaths.dashboard.notes}?subjectId=${subjectId}&conceptId=${conceptId}&topicId=${id}`
      );
    }
    if (name === 'addNotes') {
      this.setState({ addNotesPopupOpen: true });
    }
    if (name === 'notesSave' && notes.trim().length !== 0) {
      var notesData = { data: highlightText, notes: notes };
      this.props.addNotes(selectedStep, notesData, response => {
        if (response.success) {
          this.props.getNotesWithTask(selectedStep);
          this.setState({
            addNotesPopupOpen: false,
            highlightText: '',
            notes: '',
          });
        }
      });
    }
    if (name === 'cancel') {
      this.setState({ addNotesPopupOpen: false });
    }
    var popover = document.getElementById('text-popover');
    popover.style.display = 'none';
  };

  handleSave = index => {
    const { noteData, noteId, selectedStep } = this.state;
    var data = {
      id: noteId,
      notes: noteData[index].notes,
    };

    if (noteData[index].notes.trim().length !== 0) {
      this.setState({ editableId: null });
      this.props.setNoteChanges(selectedStep, noteId, data, response => {
        if (response.success) {
          this.setState({
            highlightText: '',
          });
        }
      });
    }
  };

  updateNoteValue = (index, value, data) => {
    var tempData = [];
    tempData = data;
    tempData[index].notes = value;
    return tempData;
  };

  handleChange = (index, e) => {
    const { value } = e.target;
    const data = this.updateNoteValue(index, value, this.state.noteData);
    this.setState({
      editNoteError: value.trim().length === 0,
      noteData: data,
    });
  };

  handleTextChange = e => {
    this.setState({ notes: e.target.value });
  };

  handleMenuClick = (id, event) => {
    this.setState({ menuAnchorEl: event.currentTarget, noteId: id });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handlePopoverTaskClose = () => {
    this.setState({ popoverTask: false });
  };

  handleArrowClick = (start, end) => {
    this.setState({ start: start, end: end });
  };
  handleSackBarToggle = () => {
    this.setState({
      snackBarOpen: !this.state.snackBarOpen,
    });
  };

  /**
   * Getting video object from the player
   * @param {Object} vdoObj
   */
  getVideoObj = (vdoObj, index) => {
    this.videosObj = vdoObj;
    this.setState({
      testAttempted: false,
    });
  };

  handleSelect = (mychoice, index) => {
    console.log('hi');

    let trueAnswer = this.state.videoAnswer.filter(
      ans => ans.correctChoice === true
    );
    // this.setState({
    //   selected: mychoice,
    // });
    console.log(this.state.selected, 'selected');
    console.log(mychoice, 'myChoice');
    console.log(this.state.correctVideoAnswer[0].id, 'trueAnswer');
    if (
      this.state.selected === mychoice &&
      this.state.selected === this.state.correctVideoAnswer[0].id
    ) {
      return 'select';
    } else if (
      this.state.selected === mychoice &&
      this.state.selected !== this.state.correctVideoAnswer[0].id
    ) {
      return 'wrong';
    } else if (mychoice === this.state.correctVideoAnswer[0].id) {
      return 'select';
    }
  };

  handleVideoAnswer = (mychoice, index) => {
    let choiceObj = {
      testExecutionId: this.state.vdoQuest.testExecutionId,
      questionId: this.state.vdoQuest.id,
      choices: [mychoice],
    };
    this.props.videoAnswer(this.state.activeVideo, choiceObj, response => {
      if (response.success) {
        this.setState({
          videoAnswer: response.data,
        });

        let trueAnswer = this.state.videoAnswer.filter(
          ans => ans.correctChoice === true
        );
        this.setState({
          correctVideoAnswer: trueAnswer,
        });

        this.setState({
          selected: mychoice,
        });
      }
    });
    setTimeout(() => {
      const { videosObj } = this;

      videosObj.play();
      this.setState({
        isShowVideoQuestion: false,
        testAttempted: true,
        vdoQuest: {},
        videoAnswer: {},
        selected: null,
        correctVideoAnswer: {},
      });
    }, 5000);
  };

  handleVideoNoteClick = (e, index) => {
    const { selectedStep, addNotesPopupOpen, highlightText } = this.state;

    const { videosObj } = this;
    const { topics } = this.props;
    const time = videosObj.currentTime;
    const hhmmss = new Date(time * 1000).toISOString().substr(11, 8);
    videosObj.pause();
    const title =
      topics &&
      topics.data.contents
        .filter(item => item.id === selectedStep && item)
        .pop().contentVideo[index].title;

    if (highlightText && addNotesPopupOpen) {
      this.setState({
        highlightText: '',
        addNotesPopupOpen: false,
      });
    } else {
      this.setState({
        highlightText: `${title} ${hhmmss}`,
        addNotesPopupOpen: true,
      });
    }
  };

  handleTaskClick = id => {
    let arr = [...this.state.selectedTask];
    let index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else arr.push(id);
    this.setState({
      selectedTask: arr,
    });
  };

  handleBack = () => {
    const { prevPage, CalenderPopup, topicId } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    const { subject, month } = this.state;
    if (prevPage === 'notes' || subject === 'notes') {
      console.log('notes');
      this.props.history.push(`${routePaths.dashboard.notes}`);
    } else if (prevPage === 'bookmarks' || subject === 'bookmarks') {
      console.log('bookmark');
      this.props.history.push(`${routePaths.dashboard.bookmarks}`);
    } else if (
      prevPage === 'subject_concepts' ||
      subject === 'subject_concepts' ||
      prevPage === 'topic_test'
    ) {
      console.log('subject');
      this.props.history.push(`${routePaths.dashboard.subject}`);
    } else if (CalenderPopup === 'CalenderPopup') {
      this.props.history.push(`${routePaths.dashboard.home}`);
    } else if (prevPage === 'study_plan_calendar' || subject === "study_plan_calendar") {
      this.props.history.push(
        `${routePaths.dashboard.studyPlan}?month=${month}`
      );
    } else if (prevPage === 'diagnostic_engine') {
      this.props.history.push(
        `${routePaths.dashboard.diagnosticEngine}?topicId=${topicId}`
      );
    } else this.props.history.goBack();
    // this.props.history.push(`${routePaths.dashboard.subject}`);
  };

  render() {
    const { boost } = QueryString.parse(this.props.location.search, QS_PROPS);
    const { t } = this.props;
    const {
      addNotesPopupOpen,
      highlightText,
      notes,
      isAddNotes,
      selectedNotes,
      popoverX,
      popoverY,
      start,
      end,
      popoverTask,
      selectedNoteId,
      addNotesRight,
      addNotesTop,
      loading,
    } = this.state;
    const leftCardProps = {
      location: this.props.location,
      history: this.props.history,
      topicResponse: this.props.topics && this.props.topics.data,
      selectedStep: this.state.selectedStep,
      activeVideo: this.state.activeVideo,
      selectedTask: this.state.selectedTask,
      handleLeftCardClick: this.handleLeftCardClick,
      handleTaskClick: this.handleTaskClick,
      t: t,
    };

    const notesProps = {
      data: this.state.noteData,
      menu: this.menu,
      noteData: this.state.noteData,
      anchorEl: this.state.menuAnchorEl,
      error: this.state.editNoteError,
      editableId: this.state.editableId,
      commentBoxOpen: this.state.notesPopup,
      onCloseClick: this.handleNotesIconClick,
      handleMenuItem: this.handleMenuItem,
      handleMenu: this.handleMenuClick,
      handleMenuClose: this.handleMenuClose,
      handleChange: this.handleChange,
      handleSave: this.handleSave,
      addNotesPopupOpen: addNotesPopupOpen,
      highlightText: highlightText,
      notes: notes,
      isAddNotes: isAddNotes,
      selectedNotes: selectedNotes,
      popoverX: popoverX,
      popoverY: popoverY,
      selectedNoteId: selectedNoteId,
      start: start,
      end: end,
      popoverTask: popoverTask,
      handlePopoverTaskClose: this.handlePopoverTaskClose,
      handleArrowClick: this.handleArrowClick,
      t: t,
    };

    const filterTask =
      this.props.topics &&
      this.props.topics.data.tasks
        .filter(item => item.id === this.state.selectedStep)
        .pop();
    const rightCardProps = {
      demo: filterTask,
      content:
        this.props.topics &&
        this.props.topics.data.contents
          .filter(item => item.id === this.state.selectedStep && item)
          .pop(),
      handleReadClick: this.handleReadClick,
      isShowVideoQuestion: this.state.isShowVideoQuestion,
      testAttempted: this.state.testAttempted,

      handleVideoQuestion: this.handleVideoQuestion,
      vdoQuest: this.state.vdoQuest,
      correctVideoAnswer: this.state.correctVideoAnswer,
      currentStatus: this.state.currentStatus,
      handleBookmarkClick: this.handleBookmarkClick,
      isBookmarked: this.state.isBookmarked,
      handleNotesIconClick: this.handleNotesIconClick,
      notesProps: notesProps,
      t: t,
      loading: this.state.loading,
      getVideoObj: this.getVideoObj,

      handleFeedBack: this.handleFeedBack,
      handleVideoNoteClick: this.handleVideoNoteClick,
      handleVideoAnswer: this.handleVideoAnswer,
      greenAnswer: this.state.greenAnswer,
      selected: this.state.selected,
      handleSelect: this.handleSelect,

      isHideMarkAsComplete: Boolean(
        filterTask &&
        filterTask['contentVideo'].some(
          item =>
            item.id === this.state.activeVideo && item.status === 'COMPLETED'
        )
      ),
      activeVideo: this.state.activeVideo,
      selectedTask: this.state.selectedTask,
    };
    // if(this.state?.loading === true)
    // {
    //   return(
    //     <Backdrop/>
    //   )
    // }
    // else{
    return (
      <TaskContainer>
        <AddNote id={'text-popover'}>
          <ToolTip title={'Add Notes'} placement="left" arrow>
            <AddNoteButton
              onClick={() => this.handleMenuItem('addNotes')}
              disableRipple
            >
              <img src={AddNotesIcon} alt="" />
            </AddNoteButton>
          </ToolTip>
        </AddNote>

        <Box paddingBottom={2}>
          <BreadCrumbs
            topics={this.state.topicDetail}
            goBack={this.handleBack}
            //   this.props.history.push(`${routePaths.dashboard.subject}`)
            // }
            backEvent
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TaskTitleCard
              topicResponse={this.props.topics && this.props.topics.data}
              t={this.props.t}
            />
          </Grid>
          <Grid item xs={3}>
            <LeftCard {...leftCardProps} />
          </Grid>

          <Grid item xs={9}>
            <RightCard {...rightCardProps} />
          </Grid>
        </Grid>

        {/* Popup components */}

        {
          addNotesPopupOpen && (
            <RenderAddNotePopup
              notes={notes}
              handleTextChange={this.handleTextChange}
              handleMenuItem={this.handleMenuItem}
              right={addNotesRight}
              top={addNotesTop}
            />
          )
        }

        <DialogComponent
          open={this.state.dialogOpen}
          dialogContent={{
            icon: <img src={BookmarkIcon} alt="" />,
            title: t(`Are you sure you want remove bookmark?`),
            button1: t('Cancel'),
            button2: t('Yes'),
          }}
          handleButton2Click={this.handleButton2Click}
          handleButton1Click={() => this.setState({ dialogOpen: false })}
        />
        <DialogComponent
          open={this.state.boosterDialog}
          dialogContent={{
            icon: <img src={RocketIcon} alt="" />,
            title: t(`You are current on the track to boost your score`),
            body: t('Try pushing your limits to give your best'),
            button1: t('Quit for now'),
            button2: t('Continue'),
          }}
          handleButton1Click={() => {
            window.document.removeEventListener(
              'click',
              this.handleGeneralClick,
              true
            );
            simulateMouseClick(this.state.currenElement);
          }}
          handleButton2Click={() => this.setState({ boosterDialog: false })}
        />
        {/* Popup Component for feedbackCount */}
        <DialogComponent
          open={this.state.feedOpen}
          dialogContent={{
            icon: <img src={FeedbackIcon} alt="" />,
            title: `Hope we are helping you!`,
            body: 'Try pushing your limits to give your best',

            content: (
              <div
                style={{
                  textAlign: 'center',
                  lineHeight: '20px',
                  paddingTop: '12px',
                }}
              >
                <Rating
                  name="size-large"
                  value={this.state.feedbackCount}
                  style={{ fontSize: 'xxx-large' }}
                  onChange={(e, newValue) => {
                    this.setState({
                      feedbackCount: newValue,
                    });
                  }}
                />
              </div>
            ),
            button2: t('Submit'),
            isSubmitOnly: true,
          }}
          handleButton2Click={this.handleButtonClick}
        />

        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleSackBarToggle}
          TransitionComponent={Slide}
        >
          <Alert severity={this.state.snackBarSeverity} variant="filled">
            {this.state.message}
          </Alert>
        </Snackbar>

        <Backdrop
          sx={{ color: '#fff', zIndex: '1000', backdropFilter: 'blur(5px)' }}
          open={this.state.loading}
        >
          <PageLoader />
        </Backdrop>
      </TaskContainer>
    );
    // }
  }
}

const mapStateToProps = state => {
  return {
    topics: state.dashboardReducer.topicDetails,
    notes: state.DashBoardReducer.taskNotes,
  };
};

export default connect(mapStateToProps, {
  updateCompletedTask,
  getTopic,
  pauseTask,
  getTopicDetail,
  AddBookmarks,
  getNotesWithTask,
  removeNote,
  setNoteChanges,
  addNotes,
  updatePlayTime,
  getStartInVideo,
  videoAnswer,
})(withRouter(withTranslation()(TaskDetail)));