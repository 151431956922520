/**
 * (c) CareerLabs. All rights reserved.
 **/
import { withStyles } from '@material-ui/core';
import styled from 'styled-components';
import MuiButton from '../../../utils/components/Button';

export const Container = styled.div`
  /* background: #f2f9ff; */
  padding: 20px;
`;

export const Header = styled.header`
  display: flex;
`;

export const Title = styled.h1`
  flex: 1;
  font-style: normal;
  font-weight: ${props => props.fw || 500};
  font-size: 34px;
  line-height: 41px;
  color: #052a4e;
`;

export const ReportTime = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;

export const Section = styled.section`
  padding-top: 4vh;
`;

export const TabContainer = styled.div`
  display: flex;
  position: relative;
`;

export const TabTitle = styled.div`
  flex: 1;
  display: flex;
  grid-gap: 35px;
`;

export const Button = withStyles({
  root: {
    width: '395px',
    padding: '10px',
    borderRadius: '30px',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
})(MuiButton);

export const ReportCardContainer = styled.div`
  background: #ffffff;
  border-radius: 0px 12px 12px 12px;
  position: relative;
  padding: 15px;
`;

export const SummaryText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: end;
  color: #1093ff;
`;

export const PieChartContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  width: ${props => (props.width ? props.width + 'px' : 'unset')};
  padding: 10px;
`;

export const ChartTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: #686868;
  padding-bottom: 10px;
  display: ${props => props.display};
  flex-direction: ${props => props.direction};
`;

export const ChartValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 26px;
  color: #052a4e;
  display: flex;
`;

export const Card = styled.div`
  width: 100%;
  padding: ${props => props.padding || '10px'};
  margin: ${({ margin }) => margin};
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
`;

export const CardHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 2;
`;

export const Indicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
`;

export const CardHeaderTitle = styled.div`
  padding-left: 10px;
`;

export const CardBody = styled.div`
  flex: 1;
  font-style: normal;
  font-size: 34px;
  line-height: 2;
  color: #052a4e;
`;

export const CardFooter = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1093ff;
  text-align: right;
  font-weight: 600;
`;

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding: 10px 0px;
`;

export const CardContent = styled.div``;

export const CardItem = styled.div`
  display: flex;
  padding: 10px 0px;
`;

export const ItemIcon = styled.div`
  padding-right: 15px;
`;

export const ItemCaption = styled.div`
  font-style: normal;
  font-weight: ${props => props.fw || 'normal'};
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  flex: ${props => props.flex || 'unset'};
  margin-top: ${props => props.mt || '0px'};
  padding-left: ${props => props.pl || '0px'};
`;

export const PieChartWrapper = styled.div`
  position: relative;
  margin-top: -30px;
`;

export const PieChartDescription = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  text-align: center;
`;

export const PieChartLabel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  color: #002d18;
`;

export const PieChartValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #002d18;
`;

export const BottomContainer = styled.div`
  padding: 12px;
`;

export const ListItemHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  color: #686868;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Bold = styled.b`
  font-size: ${props => props.size || '34px'};
  font-weight: ${props => props.fw || 'normal'};
  color: ${props => props.color || '#052A4E'};
  padding-left: ${props => props.pl || 'unset'};
`;

export const ListItemContainer = styled.div`
  padding: 10px;
`;

export const ListHeader = styled.div`
  display: flex;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  align-items: center;
  padding: 5px;
  margin: 10px 0px;
`;

export const ListItemTitle = styled.div`
  flex: ${props => props.flex || 'unset'};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;

export const Children = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #052a4e;
  width: 100%;
  padding: 10px 5px;
`;

export const ViewContainer = styled.div`
  min-height: 100vh;
  background: #f7f8fc;
  padding: 40px;

  @media (max-width: 1024px) {
    padding: 20px;
  }
`;

export const ReportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InsideBox = styled.div`
  padding-bottom: 12px;
`;

export const ReportTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  color: #052a4e;
`;

export const NoteImg = styled.img`
  width: 30.78px;
  height: 32px;
  margin-right: 12px;
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
  padding-left: 20px;
  padding-top: 25px;
`;

export const ReportDate = styled.div`
  font-size: 16px;
  color: #052a4e;
`;

export const DoneButton = styled.button`
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #f2f2f2;
  background: #1093ff;
  border-radius: 30px;
  padding: 10px 78px;
  text-decoration: none;
  margin-top: 5px;
  border: none;
  cursor: pointer;
`;

export const TableBodyPad = styled.div`
  padding: 0px 20px;
`;

export const TableHeaderBorder = styled.div``;

export const DownloadButton = styled(MuiButton)`
  padding: 10px 25px !important;
  border-radius: 30px !important;
  margin-right: 20px !important;
`;
