/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { useState, useEffect } from 'react';

export default function Timer({
  time,
  getRemainingTime = () => {},
  reset,
  isHour = true,
}) {
  const [seconds, setSeconds] = useState(time / 1000);

  function pad(d) {
    return d < 10 ? '0' + d.toString() : d.toString();
  }
  useEffect(() => {
    getTime();
    var interval;
    if (seconds !== 0) {
      interval = setInterval(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
    }
    if (reset && seconds !== 0) {
      clearInterval(interval);
      interval = setInterval(() => setSeconds(seconds - 1), 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (reset) {
      setSeconds(time / 1000);
    }
  }, [reset]);

  var timestamp = seconds;

  var hours = Math.floor(timestamp / 60 / 60);

  var minutes = Math.floor(timestamp / 60) - hours * 60;

  var second = timestamp % 60;

  const getTime = () => {
    getRemainingTime(seconds);
  };

  return (
    <div className="timer">
      {`${isHour ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(second)}`}
    </div>
  );
}
