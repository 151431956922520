/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Body,
  Container,
  Header,
} from '../../../../assets/css/dashboard/Landing';
import { FlexFiller } from '../../../../assets/css/StyledComponent';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ConceptTitle,
  ProgressIcon,
  TaskTitle,
  Time,
  TopicContent,
  TopicTitle,
  TypeIcon,
} from '../../../../assets/css/StyledDashboard';
import {
  TextIcon,
  TextVideoIcon,
  VideoIcon,
} from '../../../../assets/icons/DashboardIcons';
import StatusIcon from '../../../../assets/icons/StatusIcon';
import NoTodayTask from '../../../../assets/images/emptyPendingTopics.svg';
import { EVENTS } from '../../../../clevertap/Events';
import { routePaths } from '../../../../routes/RoutePath';
import clevertap from 'clevertap-web-sdk';
class TodayTopicCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTopic: null,
    };
  }

  // Open close accordion
  handleAccordionClick = e => {
    if (parseInt(e.currentTarget.id) === this.state.activeTopic)
      this.setState({ activeTopic: null });
    else this.setState({ activeTopic: parseInt(e.currentTarget.id) });
  };

  // Icon colored based on the status
  getIcon = ({ type, status }) => {
    if (type === 'TEXT') return TextIcon(status);
    else if (type === 'VIDEO') return VideoIcon(status);
    else if (type === 'TEXT_VIDEO') return TextVideoIcon(status);
  };

  // Routing to task detail page
  handleTaskClick = (event, topicId, taskId, taskObj) => {
    event.stopPropagation();
    clevertap.event.push(EVENTS.LMSUserLandsOnTheTopicPage, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
      'Watched source': 'Via daily tasks - dashboard section',
    });
    clevertap.event.push(EVENTS.LMSUserClicksTheDailyTask, {
      'Concept name': taskObj.conceptName,
      'Topic name': taskObj.topicName,
      'Task name': taskObj.taskName,
    });
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskId}&type=normal&prevPage=dashboard_todays_tasks`
    );
  };

  render() {
    const { t } = this.props;
    if (this.props.todayTask.length > 0) {
      return (
        <Container>
          <Header color={'#0455A6'}>{t('Topics for the Day')}</Header>
          <Body>
            {this.props.todayTask.map((item, index) => {
              return (
                <Accordion
                  id={index}
                  onClick={this.handleAccordionClick}
                  square
                  expanded={this.state.activeTopic === index}
                >
                  <AccordionSummary>
                    {this.state.activeTopic === index ? (
                      <RemoveOutlinedIcon className={'open-close-icon'} />
                    ) : (
                      <AddOutlinedIcon className={'open-close-icon'} />
                    )}
                    <TopicTitle>{item.topicName}</TopicTitle>
                    <FlexFiller />
                    <ConceptTitle>{item.conceptName}</ConceptTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.tasks.map(task => {
                      return (
                        <TopicContent>
                          <ProgressIcon>
                            <img src={StatusIcon(task.status)} alt="" />
                          </ProgressIcon>
                          <TypeIcon>
                            {this.getIcon({
                              status: task.status,
                              type: task.type,
                            })}
                          </TypeIcon>
                          <TaskTitle
                            status={task.status}
                            onFocus={event => event.stopPropagation()}
                            aria-label="task"
                            onClick={event =>
                              this.handleTaskClick(event, item.id, task.id, {
                                conceptName: item.conceptName,
                                topicName: item.topicName,
                                taskName: task.name,
                              })
                            }
                          >
                            {task.name}
                          </TaskTitle>
                          <FlexFiller />
                          <Time>{task.duration}</Time>
                        </TopicContent>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Body>
        </Container>
      );
    }
    // Empty card

    return (
      <Container>
        <Header color={'#0455A6'}>{t('Topics for the Day')}</Header>
        <Body>
          <div className="empty-card">
            <img
              src={NoTodayTask}
              alt={'no pending task'}
              width="70%"
              height="70%"
            />
            <Box
              color={'#052A4E'}
              fontSize={16}
              textAlign={'center'}
              fontWeight={500}
            >
              No Today Topics
            </Box>
          </div>
        </Body>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    todayTask: state.dashboardLandingReducer.todayTask,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(TodayTopicCard)));
