/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, IconButton } from '@material-ui/core';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import React from 'react';
import { Grid } from '../../../assets/css/GreStyles';
import { ToolTip } from '../../../assets/css/MuiStyles';
import '../../../assets/css/Style.css';
import {
  CommentIcon,
  DividerBookmark,
  PlayerBox,
  SubHeading,
  Title,
  TopGridSubPara,
} from '../../../assets/css/StyledComponent';
import { QuestionPopup } from '../../../assets/css/VideoPlayerStyles';
import Comment from '../../../assets/icons/comment.svg';
import ClockImage from '../../../assets/images/ClockImage.png';
import { RenderBookMark } from '../../../utils/components/Bookmark';
import Button from '../../../utils/components/Button';
import VideoPlayer from '../../../utils/VideoPlayer';
import { Notes, RenderHighlightedTask } from './Notes';

function RightCard(props) {
  const { t } = props;


  return (
    <div
      className={'main-card-align left-container-task-view'}
      id={'text-container'}
    >
      <Box p={3} pl={3} pr={3} pb={13} height="inherit">
        <DividerBookmark>
          <div style={{ padding: '4px 20px 0 0' }}>
            <RenderBookMark
              bookMarked={props.isBookmarked}
              onClick={props.handleBookmarkClick}
            />
          </div>
        </DividerBookmark>
        <ToolTip title={'All Notes'} placement="left" arrow>
          <CommentIcon>
            <IconButton onClick={props.handleNotesIconClick}>
              <img src={Comment} alt="" />
            </IconButton>
          </CommentIcon>
        </ToolTip>
        <SubHeading>{props.content && props.content.title}</SubHeading>
        <Box pt={2} pb={2} className={'left-side-card-text'}>
          <img src={ClockImage} alt="clockImage" style={{ width: 17 }} />
          &nbsp;&nbsp;&nbsp;
          {props.content && props.content.duration} Min
        </Box>
        <TopGridSubPara minHeight={'517px'}>
          <RenderHighlightedTask {...props.notesProps} />
          {props.activeVideo &&
            props.content &&
            props.content.contentVideo.map((item, index) => {
              return (
                item.id === props.activeVideo && (
                  <PlayerBox>
                    <Title>{item.title}</Title>

                    <div className="video-box">
                      <div className="icon-box-task-details">
                        <IconButton
                          onClick={e => props.handleVideoNoteClick(e, index)}
                        >
                          <PostAddRoundedIcon
                            color="primary"
                            style={{ fontSize: 'xx-large' }}
                          />
                        </IconButton>
                      </div>
                      {console.log(props.greenAnswer, 'green')}
                      {props.isShowVideoQuestion && (
                        <>
                          <div className="video-question" />
                          <div className="videoQuestion-box">
                            <div className="videoIn-box">
                              <div style={{ flex: '1' }}>
                                <div>
                                  <Grid container spacing={1}>
                                    {props.vdoQuest && (
                                      <Grid item md={12}>
                                        <QuestionPopup>
                                          <p
                                            className={'copy__allowed'}
                                            dangerouslySetInnerHTML={{
                                              __html: props.vdoQuest.question,
                                            }}
                                          />
                                        </QuestionPopup>
                                      </Grid>
                                    )}

                                    {props.vdoQuest?.choices?.map(
                                      (item, index) => {
                                        return (
                                          <Grid item md={6}>
                                            <div
                                              className={`video-choice  ${props.selected &&
                                                props.handleSelect(
                                                  item.id,
                                                  index
                                                )
                                                }`}
                                              onClick={() =>
                                                props.selected
                                                  ? {}
                                                  : props.handleVideoAnswer(
                                                    item.id,
                                                    index
                                                  )
                                              }
                                            >
                                              {`${String.fromCharCode(
                                                index + 65
                                              )}.  ${item.text}`}
                                              {/* {item.text} */}
                                            </div>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <VideoPlayer
                        videoId={item.id}
                        feedbackCount={item.feedbackCount}
                        getVideoObj={props.getVideoObj}
                        handleFeedBack={props.handleFeedBack}
                        index={index}
                        isVideoPlayedLimit={true}
                        videoName={item.title}
                        taskName={props.content.title}
                        conceptName={props.conceptName}
                        topicName={props.topicName}
                        time={item.time}
                        isVideoQuestion={
                          Boolean(item.time) && !Boolean(item.isTestAttempted)
                        }
                        handleVideoQuestion={e =>
                          props.handleVideoQuestion(e, index)
                        }
                        isShowVideoQuestion={props.isShowVideoQuestion}
                        testAttempted={props.testAttempted}
                        selected={props.selected}
                        vdoQuest={props.vdoQuest}
                        handleSelect={props.handleSelect}
                        vdoCompleted={props.vdoCompleted}

                      ></VideoPlayer>
                    </div>
                  </PlayerBox>
                )
              );
            })}
          <p
            className={'copy__allowed'}
            dangerouslySetInnerHTML={{
              __html: props.content && props.content.content,
            }}
          />
        </TopGridSubPara>
      </Box>

      <div className={'bottom-card-align'}>
        {props.demo?.type === "TEXT" && props.demo?.status === "COMPLETED" ?
          (< div style={{ height: '46px' }} />
          )
          : !props.isHideMarkAsComplete
            ? (

              <Button
                loading={props.loading}
                variant="outlined"
                color="primary"
                className={'footer-align'}
                onClick={props.handleReadClick}
              >
                {t('Mark as Read')}
              </Button>
            ) : (
              <div style={{ height: '46px' }} />
            )}
      </div>
      <Notes {...props.notesProps} />
    </div>
  );
}

export default RightCard;