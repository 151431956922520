/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, Hidden, ThemeProvider } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import {
  Bullet,
  CardContainer,
  CardShadow,
  FlatText,
  InputContainer,
  SubHeading,
  SubPara,
  TimeNumber,
  TimeText,
  TopGridSubPara,
  TopSubPara,
  TopTitle,
} from '../../../../assets/css/StyledComponent';
import clocks from '../../../../assets/images/clocks.png';
import { IMAGE_URL, QS_PROPS } from '../../../../constant/Variables';
import { getCourseImage } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import {
  getInstructions,
  updateExamDetails,
} from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import Button from '../../../../utils/components/Button';
import PageLoader from '../../../../utils/components/PageLoader';
class CalibrationTestInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: '',
      descriptive: '',
      totalQuestion: '77',
      totalQuantQues: '(31)',
      totalVerbalQues: '(36)',
      testHours: '2',
      testMins: '7',
      quantTime: '62 min',
      verbalTime: '65 min',
      isLoading: false,
    };
  }

  handleClick = () => {
    this.setState({ isLoading: true });
    let obj = {
      productName: JSON.parse(window.localStorage.getItem('userProduct'))
        .productName,
    };
    this.props.updateExamDetails(obj, res => {
      this.setState({ isLoading: false });
      this.props.getCourses();
      if (
        JSON.parse(window.localStorage.getItem('userProduct')).testExecutionId
      ) {
        this.props.history.push(
          routePaths.exam +
            `?testQuestionId=${
              JSON.parse(window.localStorage.getItem('userProduct'))
                .testQuestionSetId
                ? JSON.parse(window.localStorage.getItem('userProduct'))
                    .testQuestionSetId
                : null
            }`
        );
      } else {
        localStorage.setItem(
          'calibrationSection',
          JSON.stringify({ section: 1 })
        );
        this.props.history.push(routePaths.testInstructionSection);
      }
    });
  };

  componentDidMount() {
    const { questionSet } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function () {
    //   window.history.go(1);
    // };

    // window.history.forward()
    this.props.getInstructions(questionSet, response => {});
    this.props.getCourses();
    let obj = {
      courseId: JSON.parse(window.localStorage.getItem('userProduct')).id,
      endPoint: 'instructions',
    };
    this.props.getCourseImage(obj, response => {
      this.setState({ img: IMAGE_URL + response.data.value });
    });
  }

  convertTime = time => {
    let totalMinutes = Math.floor(time / 60);
    let minutes = Math.floor(totalMinutes % 60);
    let hours = Math.floor(totalMinutes / 60);
    if (hours === 0)
      return (
        <>
          <TimeNumber>{minutes}</TimeNumber>
          <TimeText>min</TimeText>
        </>
      );
    if (hours !== 0) {
      // let time = { hours: hours, minutes: minutes };
      return (
        <>
          <TimeNumber>{hours}</TimeNumber>
          <TimeText>h</TimeText>&nbsp;
          <TimeNumber>{minutes}</TimeNumber>
          <TimeText>min</TimeText>
        </>
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.props.getInstructionsResponse !== null ? (
          this.props.getInstructionsResponse.data && (
            <>
              <div style={{ position: 'relative' }}>
                <img
                  src={clocks}
                  alt="timer images"
                  className={'background-img'}
                />
              </div>
              <Box pt={1} pb={4}>
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  justify="center"
                >
                  <InputContainer>
                    <Grid item xs={12}>
                      <Box pt={6} pl={3}>
                        <TopTitle>
                          {t(
                            `${
                              JSON.parse(
                                window.localStorage.getItem('userProduct')
                              ).productName
                                ? JSON.parse(
                                    window.localStorage.getItem('userProduct')
                                  ).productName === 'Aptitude test'
                                  ? 'APTITUDE Calibration Test'
                                  : `${
                                      JSON.parse(
                                        window.localStorage.getItem(
                                          'userProduct'
                                        )
                                      ).productName
                                    } Calibration test`
                                : null
                            }`
                          )}
                        </TopTitle>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box pt={3}>
                        <TopSubPara>
                          {t(
                            'Calibration test helps us to give you the best study plan.'
                          )}
                        </TopSubPara>
                      </Box>
                    </Grid>
                    <Box pt={3} pb={3}>
                      <CardShadow>
                        {!this.props.getInstructionsResponse.data.exist ? (
                          <Grid className="tops__grid">
                            <Grid item md={3} xs={12}>
                              <Box className={'card_spacer'}>
                                <TopGridSubPara>
                                  {t('Total Questions')}
                                </TopGridSubPara>
                              </Box>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                className={'fir__container'}
                              >
                                <Grid
                                  item
                                  md={2}
                                  className={'second__container'}
                                >
                                  {this.props.getInstructionsResponse.data &&
                                    this.props.getInstructionsResponse.data
                                      .totalQuestion}
                                </Grid>

                                <Grid item md={1}>
                                  {' '}
                                  <div
                                    className={'card__divider'}
                                    style={{
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={9}
                                  xs={12}
                                  className={'third__container'}
                                >
                                  {this.props.getInstructionsResponse.data &&
                                    this.props.getInstructionsResponse.data.testNames.map(
                                      item => (
                                        <Grid className={'fourth__container'}>
                                          <Grid>{item.name}</Grid>
                                          <FlatText>
                                            ({item.totalQuestion})
                                          </FlatText>
                                        </Grid>
                                      )
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid md={1}>
                              <Box className={'card_spacer'}></Box>
                              <Hidden smDown>
                                <Grid
                                  className={'card__divider'}
                                  style={{
                                    marginTop: 25,
                                    position: 'relative',
                                    left: 40,
                                  }}
                                />
                              </Hidden>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Box className={'card_spacer'}>
                                {' '}
                                <TopGridSubPara>
                                  {t('Test Duration')}
                                </TopGridSubPara>
                              </Box>

                              <Grid
                                item
                                md={12}
                                xs={12}
                                className={'fir__container'}
                              >
                                <Grid
                                  className={'card__divider'}
                                  style={{ visibility: 'hidden' }}
                                />
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                  className={'fivth__container'}
                                >
                                  {this.convertTime(
                                    this.props.getInstructionsResponse.data
                                      .totalTimeSec
                                  )}
                                  {/*
                                      <TestTime>
                                        {this.props.getInstructionsResponse
                                          .data &&
                                          this.props.getInstructionsResponse
                                            .data.totalTime}
                                      </TestTime>
                                     */}
                                </Grid>
                                <Hidden mdUp>
                                  {' '}
                                  <div
                                    className={'card__divider'}
                                    style={{ marginRight: 5 }}
                                  />
                                </Hidden>
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                  className={'third__container'}
                                >
                                  {this.props.getInstructionsResponse.data &&
                                    this.props.getInstructionsResponse.data.testNames.map(
                                      item => (
                                        <Grid className={'fourth__container'}>
                                          <Grid>{item.name}</Grid>
                                          <FlatText>{item.time}</FlatText>
                                        </Grid>
                                      )
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={12}
                              sm={5}
                              className={'btn-alignment'}
                            >
                              <ThemeProvider theme={buttonTheme}>
                                <Button
                                  className={'btn__alignment'}
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={this.handleClick}
                                  loading={this.state.isLoading}
                                >
                                  {JSON.parse(
                                    window.localStorage.getItem('userProduct')
                                  ).testExecutionId
                                    ? t('Continue')
                                    : t("Let's Begin")}
                                </Button>
                              </ThemeProvider>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Grid item container md={8}>
                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={20}
                                    color={'#052A4E'}
                                    fontWeight={600}
                                  >
                                    {t('Total Questions')}
                                  </Box>
                                  <Box
                                    fontSize={20}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={600}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.totalQuestion
                                    }
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={20}
                                    color={'#052A4E'}
                                    fontWeight={600}
                                  >
                                    {t('Total Duration')}
                                  </Box>
                                  <Box
                                    fontSize={20}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={600}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.totalTime
                                    }
                                  </Box>
                                </Box>
                              </Grid>

                              {/* --------------- */}

                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={16}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {t('Questions Left')}
                                  </Box>
                                  <Box
                                    fontSize={16}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.left
                                    }
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={16}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {t('Time Left')}
                                  </Box>
                                  <Box
                                    fontSize={16}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.timeLeft
                                    }
                                  </Box>
                                </Box>
                              </Grid>

                              {/* ----------- */}

                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={16}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {t('Questions Attempts')}
                                  </Box>
                                  <Box
                                    fontSize={16}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.attempt
                                    }
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md={5}>
                                <Box display={'flex'} padding={'15px 20px'}>
                                  <Box
                                    flex={1}
                                    fontSize={16}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {t('Time Spend')}
                                  </Box>
                                  <Box
                                    fontSize={16}
                                    pr={10}
                                    color={'#052A4E'}
                                    fontWeight={500}
                                  >
                                    {
                                      this.props.getInstructionsResponse.data
                                        .exist.timeSpend
                                    }
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid item md={4}>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                height={'100%'}
                                justifyContent={'flex-end'}
                                pr={3}
                                pl={3}
                              >
                                <ThemeProvider theme={buttonTheme}>
                                  <Button
                                    className={'btn__alignment'}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={this.handleClick}
                                    style={{ marginTop: 0 }}
                                    loading={this.state.isLoading}
                                  >
                                    {JSON.parse(
                                      window.localStorage.getItem('userProduct')
                                    ).testExecutionId
                                      ? t('Continue')
                                      : t("Let's begin")}
                                  </Button>
                                </ThemeProvider>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </CardShadow>
                    </Box>
                    <CardShadow>
                      <CardContainer>
                        <div className={'card__image'}>
                          <Grid item md={9} xs={12}>
                            <Box pb={2}>
                              {' '}
                              <SubHeading>{t('Test Instructions')}</SubHeading>
                            </Box>
                            <Box pb={2}>
                              <TopGridSubPara>
                                {this.props.getInstructionsResponse.data &&
                                  this.props.getInstructionsResponse.data
                                    .instructionTitle}
                              </TopGridSubPara>
                            </Box>
                            {this.props.getInstructionsResponse.data &&
                              this.props.getInstructionsResponse.data.instruction.map(
                                item => (
                                  <Box pb={2}>
                                    <div className={'bullet_with__text'}>
                                      {' '}
                                      <div>
                                        {' '}
                                        <Bullet />
                                      </div>{' '}
                                      <SubPara>{item}</SubPara>
                                    </div>
                                  </Box>
                                )
                              )}
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={12}
                            className={'btn__container'}
                          >
                            <img src={this.state.img} alt={''}></img>
                          </Grid>
                        </div>
                      </CardContainer>
                    </CardShadow>
                  </InputContainer>
                </Grid>
              </Box>
            </>
          )
        ) : (
          <PageLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getInstructionsResponse: state.testReducer.instructions,
    examDetailsResponse: state.testReducer.examDetails,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, {
  getInstructions,
  updateExamDetails,
  getCourseImage,
  getCourses,
})(withTranslation()(CalibrationTestInstruction));
