/**
 * (c) CareerLabs. All rights reserved.
 **/

import { GET, PUT, POST, DELETE } from '../../axios/Axios';
import { DEFAULT_URL } from '../../api/API';
import {
  DASHBOARD_LANDING,
  // DASHBOARD_PASSAGE,
  DASHBOARD_QUESTION_BANK,
} from '../Action';
import Cookies from 'js-cookie';
import { decrypt } from '../../utils/Crypto';
import { catchError } from '../../utils/methods/Helper';

export const setFieldValue = (fieldName, fieldValue) => {
  return dispatch =>
    dispatch({
      type: DASHBOARD_LANDING.setFieldValue,
      fieldName: fieldName,
      fieldValue: fieldValue,
    });
};

export const getReport = order => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/timeSpend`,
      {
        order: order,
      },
      response => {
        dispatch({
          type: DASHBOARD_LANDING.getReport,
          payload: response.data.data,
          name: order,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getOneQuestion = (obj, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  const data = {};
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${obj.testQuestionSetId}/question/${obj.questionId}?type=${obj.type}`,
      data,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.singleQuestion,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getQuestionAnswer = (data, type, isBookmarked, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/answers?type=${type}&isBookmarked=${isBookmarked}`,
      data,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.questionAnswer,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};
// api/v1/lms/student/{studentId}/product/{productId}/concept/{conceptId}/wrongtopic
export const getWrongCalibration = (callback, subjectId) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/a71ffb8d-5a1b-49b4-bc46-aae42a5bc595/product/17620fb8-7d1a-4f3f-9678-bca86a486dd8/concept/7a04e4e9-8f7c-45c8-9617-47c9e8e81847/wrongtopic
      `,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.wrongCalibration,
          payload: response.data.data,
        });
        // callback(response.data.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};
export const getWrongConcept = subjectId => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/a71ffb8d-5a1b-49b4-bc46-aae42a5bc595/subject/d95b0a23-dc6f-40f4-aa75-5a6620eebeaa/wrongconcept`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.wrongConcept,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getAnswerExplanation = (data, isBookmarked, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/testQuestionSet/${data.testQuestionSetId}/question/${data.questionId}/answer?isBookmarked=${isBookmarked}`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.answerExplanation,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getProgressTracker = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/timeSpend?order=progressTracker`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.progressTracker,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getProgressStatus = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans/status?status=statusProgressTracker`,
      {},
      response => {
        // console.log(response);
        dispatch({
          type: DASHBOARD_LANDING.progressStatus,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getDiagnosticEngineByTopic = (topicId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/strengthWeakness`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.diagnosticEngine,
          payload: response.data,
        });
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const sendContactMentorMail = (topicId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/strengthWeakness`,
      null,
      {
        type: 'DECONTACTMENTORMAIL',
      },
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const markDiagnosticEngineCompleted = topicId => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/topic/${topicId}/strengthWeakness`,
      null,
      {
        type: 'DECOMPLETED',
      },
      () => {},
      error => {
        // console.log(error)
      }
    );
  };
};

export const visitedDiagnosticEngine = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return () => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/product/${productId}/goalSettings`,
      {
        isVisitedDiagnosticEngine: true,
      },
      null,
      () => {},
      error => {
        // console.log(error);
      }
    );
  };
};

export const removeDemo = (visitedPage, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  return () => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}?page=demo`,
      {
        visitedPage: visitedPage,
      },
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const getWebinar = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/product/${productId}/wallpost/webinar`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.webinar,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const registerUser = (webinarId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/webinar/registration/${webinarId}`,
      {},
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const removeWebinar = (webinarId, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    PUT(
      `${DEFAULT_URL}/lms/students/${studentId}/webinar/unregistration/${webinarId}`,
      {},
      {},
      response => {
        callback(response.data);
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getWebinarFilter = () => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/students/${studentId}/postCategories`,
      {},
      response => {
        dispatch({
          type: DASHBOARD_LANDING.webinarFilter,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};
export const getWebinarConceptFilter = () => {
  // const studentId =
  //   localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;
  let params = { productId: productId, allowSubject: false };

  return dispatch => {
    GET(
      `${DEFAULT_URL}/lms/webinar/concepts`,
      { ...params },

      response => {
        dispatch({
          type: DASHBOARD_LANDING.webinarConceptFilter,
          payload: response.data.data,
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getPosts = (
  { bodyObj, pageNo, queryText, size, conceptId },
  callback
) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/product/${productId}/wallpost`,
      bodyObj,
      {
        page: pageNo,
        size: size,
        q: queryText,
        conceptId: conceptId === 'null' ? null : conceptId,
      },

      response => {
        if (queryText === 'createdAt')
          dispatch({
            type: DASHBOARD_LANDING.getRecentPosts,
            payload: response.data.data,
          });
        else if (queryText === 'totalViews') {
          dispatch({
            type: DASHBOARD_LANDING.getPopularPosts,
            payload: response.data.data,
          });
        }
        callback(response.data.data);
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const search = ({ bodyObj, pageNo, queryText, size }, callback) => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/students/${studentId}/wallpost/search/video`,
      bodyObj,
      { page: pageNo, size: size, q: queryText },

      response => {
        dispatch({
          type: DASHBOARD_LANDING.search,
          payload: response.data.data,
        });
        callback(response.data.success);
      },

      error => {
        // console.log(error);
      }
    );
  };
};

export const difficultyLevelChange = arr => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_LANDING.difficultyLevelChange,
      payload: arr,
    });
  };
};

export const questionBankState = obj => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_QUESTION_BANK.questionBankState,
      payload: obj,
    });
  };
};
export const deleteStudy = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;

  return dispatch => {
    DELETE(
      `${DEFAULT_URL}/student/${studentId}/product/${productId}/studentstudyplandeletion
      `,
      {},

      response => {
        console.log(response, 'sd');
        dispatch({
          type: DASHBOARD_LANDING.deleteStudy,
          payload: response.data,
        });
        callback(response.data);
      },

      error => {
        // console.log(error);
      }
    );
  };
};
export const getAllDashboard = callback => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));
  const productId = localStorage.getItem('userProduct')
    ? JSON.parse(localStorage.getItem('userProduct')).id
    : null;
  return dispatch => {
    POST(
      `${DEFAULT_URL}/lms/student/${studentId}/product/${productId}/studyPlans`,
      null,
      { field: 'dashboard' },
      response => {
        callback(response.data);
        // First Slider Card
        dispatch({
          type: DASHBOARD_LANDING.getDashboardStatus,
          payload: response.data.data.status,
        });

        // Second Slider Card
        dispatch({
          type: DASHBOARD_LANDING.getDashboardDiagnosticStatus,
          payload: response.data.data.statusDiagnosticEngine,
        });
        //Calibration Card
        dispatch({
          type: DASHBOARD_LANDING.getDasboardCalibration,
          payload: response.data.data.calibrationTestStatus,
        });
        // Time Spent Weekly

        dispatch({
          type: DASHBOARD_LANDING.getReport,
          payload: response.data.data.weekly,
          name: 'weekly',
        });

        // Time Spent Monthly
        dispatch({
          type: DASHBOARD_LANDING.getReport,
          payload: response.data.data.monthly,
          name: 'monthly',
        });

        // Doughnut Card
        dispatch({
          type: DASHBOARD_LANDING.getConcept,
          payload: response.data.data.conceptStatus,
        });

        // Progress Tracker Banner
        dispatch({
          type: DASHBOARD_LANDING.progressStatus,
          payload: response.data.data.statusProgressTracker,
        });

        // Progress Tracker
        dispatch({
          type: DASHBOARD_LANDING.progressTracker,
          payload: response.data.data.progressTracker,
        });

        // Today tasks
        dispatch({
          type: DASHBOARD_LANDING.getTodayTask,
          payload: response.data.data.todayTopicNew,
        });

        // Pending tasks
        dispatch({
          type: DASHBOARD_LANDING.getPendingTask,
          payload: response.data.data.pendingTopicNew,
        });

        // Strengths
        dispatch({
          type: DASHBOARD_LANDING.getStrengthTask,
          payload: response.data.data.strength,
        });

        // Weakness
        dispatch({
          type: DASHBOARD_LANDING.getWeakTask,
          payload: response.data.data.weak,
        });

        // Loading Complete
        dispatch({
          type: DASHBOARD_LANDING.loading,
          payload: false,
        });
      },
      error => {
        // console.log(error);
      }
    );
  };
};

export const isVisited = page => {
  const studentId =
    localStorage.getItem('studentId') || decrypt(Cookies.get('studentId'));

  return dispatch => {
    GET(
      `${DEFAULT_URL}/students/${studentId}/visitedPage`,
      { page: page },
      response => {
        dispatch({
          type: DASHBOARD_LANDING.isVisited,
          payload: { [page]: response.data.data.isVisited },
        });
      },
      error => {
        // console.log(error)
      }
    );
  };
};

export const getSubjectAndConceptByTopic = (topicId, callback) => {
  return () => {
    GET(
      `${DEFAULT_URL}/lms/concept/topic/${topicId}`,
      {},
      response => {
        callback(response.data);
      },
      error => {
        callback(catchError(error));
      }
    );
  };
};
