/**
 * (c) CareerLabs. All rights reserved.
 **/
import { ClickAwayListener, Grid, Popper } from '@material-ui/core';
import React from 'react';
import {
  ProgressBox,
  ProgressHeader,
  MonthStyle,
  Icon,
  ActionBox,
  WeekDay,
  TopicText,
  TopicStatusIcon,
  TopicBox,
  Iconbutton,
  PopperBox,
  PopperText1,
  PopperText2,
  PopperButton,
} from '../../../assets/css/StyledDashboardComponent.js';
import leftIcon from '../../../assets/images/leftIcon.png';
import rightIcon from '../../../assets/images/rightIcon.png';
import todo from '../../../assets/icons/todo.svg';
import complete from '../../../assets/icons/completed.svg';
import progress from '../../../assets/icons/Progress.svg';

export default props => {
  const {
    handleWeekChange,
    activeWeek,
    weekData,
    weekLength,
    handleTopicClick,
    topicId,
    popperAnchorEl,
    handlePopperClose,
    handlePopperOpen,
    handlePopperButtonClick,
    popperData,
    getDateString,
  } = props;

  return (
    <>
      <ProgressBox>
        <Grid container spacing={2}>
          <Grid item sm={7}>
            <ProgressHeader>Weekly update </ProgressHeader>
          </Grid>
          <Grid item sm={5}>
            <ActionBox>
              {weekData && activeWeek > 0 && (
                <Iconbutton id={1} name="prev" onClick={handleWeekChange}>
                  <Icon src={leftIcon} />
                </Iconbutton>
              )}
              <MonthStyle>{weekData && weekData.weekTitle}</MonthStyle>
              {weekData && activeWeek < weekLength - 1 && (
                <Iconbutton id={2} name="next" onClick={handleWeekChange}>
                  <Icon src={rightIcon} />
                </Iconbutton>
              )}
            </ActionBox>
          </Grid>
        </Grid>
      </ProgressBox>
      {weekData &&
        weekData.days.map(item => (
          <>
            <WeekDay>{item.title}</WeekDay>

            <Grid container spacing={2} className={'topic__pad'}>
              {popperData && (
                <ClickAwayListener onClickAway={handlePopperClose}>
                  <Popper
                    id={topicId}
                    open={Boolean(popperAnchorEl)}
                    anchorEl={popperAnchorEl}
                    placement="top-end"
                  >
                    <PopperBox>
                      {popperData.assignDate !== null && (
                        <>
                          <PopperText1>{popperData.assignDateText}</PopperText1>
                          <PopperText2>
                            {getDateString(new Date(popperData.assignDate))}
                          </PopperText2>
                        </>
                      )}
                      {/* 2021-09-27 */}
                      {popperData.completeDate !== null && (
                        <>
                          <PopperText1>
                            {popperData.completeDateText}
                          </PopperText1>
                          <PopperText2>
                            {getDateString(new Date(popperData.completeDate))}
                          </PopperText2>
                        </>
                      )}
                      {popperData.status.toLowerCase() !== 'completed' && (
                        <PopperButton
                          id={topicId}
                          onClick={handlePopperButtonClick}
                        >
                          {popperData.buttonText}
                        </PopperButton>
                      )}
                    </PopperBox>
                  </Popper>
                </ClickAwayListener>
              )}
              {item.topics.length !== 0 &&
                item.topics.map(topic => (
                  <Grid item xs={12} sm={6} lg={4}>
                    <TopicBox
                      id={topic.id}
                      onClick={
                        item.status
                          ? e => handleTopicClick(topic.tasks, topic.topic, e)
                          : () => {}
                      }
                      disabled={!item.status}
                    >
                      <TopicText disabled={!item.status}>
                        {topic.topic}
                      </TopicText>
                      <TopicStatusIcon
                        id={topic.id}
                        onMouseOver={e =>
                          handlePopperOpen(
                            item.status,
                            {
                              assignDate: topic.assignDate,
                              completeDate: topic.completeDate,
                              status: topic.status,
                            },
                            e
                          )
                        }
                        src={
                          topic.status.toLowerCase() === 'todo'
                            ? todo
                            : topic.status.toLowerCase() === 'completed'
                            ? complete
                            : topic.status.toLowerCase() === 'progress'
                            ? progress
                            : null
                        }
                        disabled={!item.status}
                      />
                    </TopicBox>
                  </Grid>
                ))}
            </Grid>
          </>
        ))}
    </>
  );
};
