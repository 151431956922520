/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import { Avatar, ListItemIcon, ListItem } from '@material-ui/core';

export const Container = styled.div`
  background: #f7f8fc;
  height: 89vh;
  padding: 16px 40px;
`;

export const BorderCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  padding-top: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const EndFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SelectBox = styled.div`
  background: #eaf6ff;
  box-shadow: inset 1px 1px 1px rgba(16, 147, 255, 0.25);
  border-radius: 30px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;

  margin-right: 5px;
  padding: 5px;
  min-width: max-content;
`;

export const TitleBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleImg = styled.img`
  width: 54px;
  height: 54px;
  margin-right: 16px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1093ff;
`;

export const SubHeader = styled.div`
  padding-right: 8px;
  font-size: 12px;
  color: #052a4e;
`;

export const TextAvatar = styled(Avatar)`
  border: 4px solid ${props => (props.color ? '#FF928E' : '#92E3A9')};
  font-weight: bold;
  font-size: 16px !important;
  color: #052a4e !important;
  background-color: #ffffff !important;
`;

export const CardTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #052a4e;
  padding-right: 12px;
`;

export const AvatarImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const TopicHeader = styled.div`
  border-top: 1px solid rgb(16 147 255 / 10%);
  font-weight: 500;
  font-size: 16px;
  color: #1093ff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const StyledTopicBox = styled.div`
  border-top: 1px solid #1093ff;
  background: #f3faff;
  box-shadow: 0px 0px 7px rgb(183 222 255 / 50%);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 5px 20px;
`;

export const TypeIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const TypeHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #052a4e;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;
`;

export const RightArrow = styled.div`
  background: ${props => (props.color === true ? '#1093FF' : '#C5E7FF')};
  color: ${props => (props.color === true ? '#FFFFFF' : '#1093FF')};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

export const StyledListItem = styled(ListItem)`
  padding: 4px 16px !important;
  background-color: #f3faff !important;
  box-shadow: 0px 0px 1px 1px rgb(183 222 255 / 40%);
  border-radius: 8px !important;
`;

export const SideIcon = styled.img`
  width: ${props => (props.size === true ? '16px' : '24px')};
  height: ${props => (props.size === true ? '16px' : '24px')};
  margin: ${props =>
    props.size === true ? '0px 19px 0px 4px' : '0px 15px 0px 0px'};
  min-height: 24px;
`;

export const SideContent = styled.div`
  font-size: 14px;
  color: #052a4e;
`;

export const RightContent = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #052a4e;
`;

export const TypeContainer = styled.div`
  padding: 16px 15px 6px 15px;
`;

export const SideContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InsideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BottomArrow = styled.div`
  background: #1093ff;
  color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  position: absolute;
  left: calc(50% - 16px);
  padding: 4px;
  bottom: -16px;
  cursor: pointer;
`;

export const TopicBottomArrow = styled.div`
  background: rgba(16, 147, 255, 0.8);
  color: #ffffff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  position: absolute;
  left: calc(50% - 14px);
  padding: 4px;
  bottom: -16px;
  cursor: pointer;
`;

export const SkillContainer = styled.div`
  display: flex;
  padding-right: 12px;
  align-items: center;
`;

export const SkillContent = styled.div`
  font-size: 14px;
  color: #1093ff;
  padding-right: 10px;
  white-space: nowrap;
`;
export const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const Divider = styled.div`
  position: absolute;
  opacity: 0.1;
  border-right: 2px solid #1093ff !important;
  width: 0;
  left: 50%;
  height: calc(100% - 28px);
  top: 16px;

  @media (max-width: 630px) {
    display: none;
  }
`;

export const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyImg = styled.img`
  width: 300px;
  height: 300px;
  opacity: 0.2;
`;

export const EmptyContent = styled.div`
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px;
  color: #052a4e;
`;

export const EmptyTypeContent = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #052a4e;
  margin: 0px auto;
  padding: 50px 0px 30px 0px;
  text-align: center;
`;

export const SelectFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const InnerDiv = styled.div`
  padding: 0px 20px;
  overflow: auto;
  height: 67vh;

  @media only screen and (min-width: ${1478}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 67vh;
  }

  @media only screen and (min-width: ${1680}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 68vh;
  }

  @media only screen and (min-width: ${1848}px) {
    /* CSS that should be displayed if width is equal to or greater than 991px goes here */
    height: 69vh;
  }
`;
