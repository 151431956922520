/**
 * (c) CareerLabs. All rights reserved.
 **/
import Dialog from '@material-ui/core/Dialog';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from 'react';
import '../../../../assets/css/App.css';
import { CloseIconButton } from '../../../../assets/css/StyledDashboardComponent';
import {
  FlexButtonBox,
  StyledClickButton,
  TargetDateDialogBody1,
  TargetDateDialogBody2,
  TargetDateDialogContainer,
  CloseIconBox,
  CalendarImg,
  TargetDateDialogBody3,
} from '../../../../assets/css/StyledComponent';
import calendar from '../../../../assets/icons/targetDateCalendar.svg';

export default props => {
  const {
    open,
    dialogData,
    handleContinueTargetDateClick,
    handleCloseIconClick,
    t,
  } = props;
  if (dialogData !== null)
    return (
      <Dialog
        open={open}
        maxWidth={'sm'}
        PaperProps={{ className: 'target__date__dialog' }}
        fullWidth
      >
        <TargetDateDialogContainer>
          <CloseIconBox>
            <CloseIconButton onClick={handleCloseIconClick}>
              <CloseRoundedIcon className={'closeIcon__color'} />
            </CloseIconButton>
          </CloseIconBox>
          <CalendarImg src={calendar} />
          <TargetDateDialogBody1>
            {t(dialogData.bodyText1)}
          </TargetDateDialogBody1>
          <TargetDateDialogBody2>
            {t(dialogData.bodyText2[0])}
            <b>{t(dialogData.body2HighlightedText[0])}</b>
            {t(dialogData.bodyText2[1])}
            <b>{t(dialogData.body2HighlightedText[1])}</b>
            {t(dialogData.bodyText2[2])}
          </TargetDateDialogBody2>
          <FlexButtonBox>
            <StyledClickButton outlined={true} onClick={handleCloseIconClick}>
              {t(dialogData.buttonText1)}
            </StyledClickButton>
            <StyledClickButton
              outlined={false}
              onClick={handleContinueTargetDateClick}
            >
              {t(dialogData.buttonText2)}
            </StyledClickButton>
          </FlexButtonBox>
          <TargetDateDialogBody3>
            <b className={'text_font_weight__500'}>{'Note: '}</b>
            {t(dialogData.bodyText3)}
          </TargetDateDialogBody3>
        </TargetDateDialogContainer>
      </Dialog>
    );
  else return null;
};
