/**
 * (c) CareerLabs. All rights reserved.
 **/
export const AUTHENTICATION = {
  validate: 'validate',
  login: 'login',
  validateUser: 'validateUser',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
};

export const PROFILE = {
  updateEducationalInfo: 'updateEducationalInfo',
  updateEmployeeInfo: 'updateEmployeeInfo',
  updateProfile: 'updateProfile',
  updateSetup: 'updateSetup',
  getProfileImage: 'getProfileImage',
  uploadProfileImage: 'uploadProfileImage',
  getCourses: 'getCourses',
  changePassword: 'changPassword',
};

export const STUDENT = {
  getStudent: 'getStudent',
  userLogout: 'userLogout',
};

export const COLLEGE = {
  getAspirationUniversity: 'getAspirationUniversity',
  getDegree: 'getDegree',
  getCollege: 'getCollege',
  getDepartment: 'getDepartment',
  getUniversity: 'getUniversity',
};

// export const TEST = {
//   startTest: 'startTest',
// };

export const COURSE = {
  getCourseImage: 'getCourseImage',
  getCourseData: 'getCourseData',
  getInsights: 'getInsights',
  getQbankInsights: 'getQbankInsights',
  getGoals: 'getGoals',
};
export const TEST = {
  startTest: 'startTest',
  pauseTest: 'pauseTest',
  testAnswers: 'testAnswers',
  viewExplanation: 'viewExplanation',
  updateExamDetails: 'updateExamDetails',
  submitAnswer: 'submitAnswer',
  getInstructions: 'getInstructions',
  personalizedStudyPlan: 'personalizedStudyPlan',
  getTestSectionInstruction: 'getTestSectionInstruction',
  cleanUp: 'cleanUp',
  startTest2: 'startTest2',
  getIntroductionVideo: 'getIntroductionVideo',
  getIntroductionVideos: 'getIntroductionVideos',
};

export const DASHBOARD = {
  getStudyPlan: 'getStudyPlan',
  getStudyPlanDetails: 'getStudyPlanDetails',
  getPlanDetails: 'getPlanDetails',
  getTopic: 'getTopic',
  getSubjectDetails: 'getSubjectDetails',
  updateCompletedTask: 'updateTaskCompleted',
  getAllQuestions: 'getAllQuestions',
  getAllTopics: 'getAllTopics',
  pauseTask: 'pauseTask',
  retakeExam: 'retakeExam',
  topicTestInstruction: 'topicTestInstruction',
  questionBankTestInstruction: 'questionBankTestInstruction',
  getReport: 'getReport',
  getQbankReport: 'getQbankReport',
  getStrengthsAndWeakness: 'getStrengthsAndWeakness',
  getNotesWithTopic: 'getNotesWithTopic',
  getNotesWithTask: 'getNotesWithTask',
  getTopicDetails: 'getTopicDetails',
  addNotes: 'addNotes',
  getAllNotes: 'getAllNotes',
  inVideoQuestion: 'inVideoQuestion',
  updateVideoAnswer: 'updateVideoAnswer',
  studyPlanByDate: 'studyPlanByDate',
  getAllSubjectsByProduct: 'getAllSubjectsByProduct',
  getAllConceptsBySubject: 'getAllConceptsBySubject',
  getAllTopicsByConcept: 'getAllTopicsByConcept',
  AllNote: 'AllNote',
};

const DASHBOARD_LANDING = {
  getDashboardStatus: 'getDashboardStatus',
  getConcept: 'getConcept',
  getPendingTask: 'getPendingTask',
  getTodayTask: 'getTodayTask',
  getStrengthTask: 'getStrengthTask',
  getWeakTask: 'getWeakTask',
  getReport: 'getReport',
  getQbankReport: 'getQbankReport',
  singleQuestion: 'singleQuestion',
  questionAnswer: 'questionAnswer',
  answerExplanation: 'answerExplanation',
  deleteStudy: 'deleteStudy',
  topicTestInstruction: 'topicTestInstruction',
  progressTracker: 'progressTracker',
  wrongConcept: 'wrongConcept',
  wrongCalibration: 'wrongCalibration',
  progressStatus: 'progressStatus',
  diagnosticEngine: 'diagnosticEngine',
  getDashboardDiagnosticStatus: 'getDashboardDiagnosticStatus',
  getDasboardCalibration: 'getDasboardCalibration',
  webinar: 'webinar',
  webinarFilter: 'webinarFilter',
  webinarConceptFilter: 'webinarConceptFilter',
  difficultyLevelChange: 'difficultyLevelChange',
  getRecentPosts: 'getRecentPosts',
  getPopularPosts: 'getPopularPosts',
  search: 'search',
  loading: 'loading',
  isVisited: 'isVisited',
  setFieldValue: 'setFieldValue',
};

export { DASHBOARD_LANDING };

export const DASHBOARD_QUESTION_BANK = {
  getStudyPlanSubjects: 'getStudyPlanSubjects',
  getStudyPlanConcepts: 'getStudyPlanConcepts',
  getStudyPlanTopics: 'getStudyPlanTopics',
  getStudyPlanQuestions: 'getStudyPlanQuestions',
  questionBankState: 'questionBankState',
  getStudyPlanConceptTopics: 'getStudyPlanConceptTopics',
};

export const PRACTICE = {
  getTestType: 'getTestType',
  getSubjectsConcepts: 'getSubjectConcepts',
  getTopicsQuestions: 'getTopicsQuestions',
  addBookmarks: 'addBookmarks',
  getBookmarks: 'getBookmarks',
  getConceptAndSubject: 'getConceptAndSubject',
  getTopicsAndTasksAndQuestions: 'getTopicsAndTasksAndQuestions',
  bookmarkState: 'bookmarkState',
  mistakeLogState: 'mistakeLogState',
  getOfficialGuideDetails: 'getOfficialGuideDetails',
  loader: 'practiceLoader',
  getOfficialGuideTopicDetail: 'getOfficialGuideTopicDetail',
  getAllQuestionTypes: 'getAllQuestionTypes',
  setSolveByPassageState: 'setSolveByPassageState',
  getPassageList: 'getPassageList',
  getPassageQuestions: 'getPassageQuestions',
  setFieldValue: 'setFieldValue',
  setTopicTestState: 'setTopicTestState',
  getTopicTestName: 'getTopicTestName',
};

export const OFFICIAL_GUIDE = {
  getGreOGDetails: 'getGreOGDetails',
  getGmatOGQuestions: 'getGmatOGQuestions',
  setOfficialGuideState: 'setOfficialGuideState',
  setFieldValue: 'setOfficialGuideFieldValue',
  getGreOgReport: 'getGreOgReport',
  getGreAttemptedTests: 'getGreAttemptedTests',
  getOgAttemptedTests: 'getOgAttemptedTests',
  getGreOgOldReport: 'getGreOgOldReport',
  gmatOgReport: 'gmatOgReport',
  overAllReport: 'overAllReport',
  getExcel: 'getExcel',
  getGmatExcel: 'getGmatExcel',
};
