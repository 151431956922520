/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Table, TableContainer, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  StatusToolTip,
  TableBodyStyle,
  TableHeader,
  TableHeaderCell,
  TableItemBlack,
  TableItemBlue,
  TableItemUnderline,
} from '../../../../assets/css/Styles';
import Status1 from '../../../../assets/images/status1.png';
import Status2 from '../../../../assets/images/status2.png';
import Status3 from '../../../../assets/images/status3.png';
import { getCoursesData } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { routePaths } from '../../../../routes/RoutePath';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const userProduct = JSON.parse(localStorage.getItem('userProduct'));
    this.props.getCoursesData(
      localStorage.getItem('testExecutionId'),
      responseData => {
        this.setState({ data: responseData.data });
      }
    );
  }

  convertTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (minutes !== 0 && seconds !== 0) return minutes + 'm ' + seconds + 's';
    if (minutes === 0) return seconds + 's';
    if (seconds === 0) return minutes + 'm';
  }

  render() {
    const { t } = this.props;
    return (
      <TableContainer>
        <Table className={'review__table'}>
          <TableHeader>
            <TableHeaderCell>{t('Que No')}</TableHeaderCell>
            <TableHeaderCell style={{ textAlign: 'left' }}>
              {t('Topic')}
            </TableHeaderCell>
            <TableHeaderCell className={'text__align'}>
              {t('Concept')}
            </TableHeaderCell>
            <TableHeaderCell className={'text__align'}>
              {t('Difficulty')}
            </TableHeaderCell>
            <TableHeaderCell>{t('Time Taken')}</TableHeaderCell>
            <TableHeaderCell>{t('Cumulative Time')}</TableHeaderCell>
            <TableHeaderCell>{t('Attempt Status')}</TableHeaderCell>
          </TableHeader>
          <TableBodyStyle>
            {this.state.data.map(item => {
              return (
                <TableRow id={item.quesNo} className={'review__table__row'}>
                  <TableItemBlue>{`Ques ${item.quesNo}`}</TableItemBlue>
                  <TableItemUnderline
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      this.props.history.push(
                        `${routePaths.answer}/${item.quesNo}`
                      );
                    }}
                  >
                    {item.topic}
                  </TableItemUnderline>
                  <TableItemBlue className={'text__align'}>
                    {item.concept}
                  </TableItemBlue>
                  <TableItemBlack className={'text__align'}>
                    {item.difficulty}
                  </TableItemBlack>
                  <TableItemBlack>
                    {this.convertTime(item.timeTaken)}
                  </TableItemBlack>
                  <TableItemBlack>
                    {this.convertTime(item.cumulativeTime)}
                  </TableItemBlack>
                  <TableItemBlack>
                    {
                      <StatusToolTip
                        arrow
                        placement="bottom-end"
                        title={item.attemptStatus}
                      >
                        <img
                          src={
                            item.attemptStatus === 'Not Attempted'
                              ? Status1
                              : item.attemptStatus === 'Correct'
                              ? Status2
                              : item.attemptStatus === 'Incorrect'
                              ? Status3
                              : null
                          }
                          alt={item.attempStatus}
                        />
                      </StatusToolTip>
                    }
                  </TableItemBlack>
                </TableRow>
              );
            })}
          </TableBodyStyle>
        </Table>
      </TableContainer>
    );
  }
}
const mapStateToProps = state => {
  return {
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, { getCourses, getCoursesData })(
  withRouter(withTranslation()(Review))
);
