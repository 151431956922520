/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Select,
  withStyles,
  TableBody,
  TableCell,
  Accordion as MuiAccordion,
} from '@material-ui/core';
import styled from 'styled-components';
import { MuiButton } from './MuiStyles';
// import MuiButton from '../../../utils/components/Button';

export const Container = styled.div`
  /* background: #f2f9ff; */
  background: #ffffff;
  padding: 40px;
`;

export const Header = styled.header`
  display: flex;
`;
export const ExcelButton = styled(MuiButton)`
  min-width: 303px !important;
  height: 40px;
  margin-bottom: 5px;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
`;
export const Title = styled.h1`
  flex: 1;
  font-style: normal;
  font-weight: ${props => props.fw || 500};
  font-size: 34px;
  line-height: 41px;
  color: #052a4e;
`;

export const ReportTitle = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: -30px;
`;

export const ReportTime = styled.h4`
  font-style: normal;
  font-weight: 400px;
  font-size: 17px;
  line-height: 20px;
  color: #052a4e;
  text-align: right;
  margin-top: -10px;
`;

export const StyledSelect = withStyles(theme => ({
  root: {
    width: '150px',
    height: '20px',
    background: '#FFFFFF',
    border: '0.5px solid #1093FF',
    borderRadius: '20px',
    boxSizing: 'borderBox',
    marginTop: '-35px',
    color: '#000000',
    padding: '8px 15px',
    textAlign: 'center',
  },
  select: {
    paddingRight: '40px !important',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '30px !important',
    marginLeft: '10px',
  },
  icon: {
    marginRight: '10px',
    color: '#1093FF',
    marginTop: '-17px',
  },
}))(Select);

export const Accordion = withStyles({
  root: {
    boxShadow: 'unset',
    borderRadius: '8px',
    margin: '8px 0',
    cursor: 'default',

    '&::before': {
      background: '#FFFFFF',
      top: '0',
    },
  },

  expanded: {
    background: '#ffffff',
    marginTop: '8px !important',

    '&:first-child': {
      marginTop: '8px',
    },
  },
})(MuiAccordion);

export const FlexView = styled.div`
  display: flex;
  align-items: center;
  gap: 1vh;
`;

export const SubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 20px;
  text-align: center;
  color: #1093ff;
  padding-bottom: 7px;
`;
export const ReportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`;
export const InsideBox = styled.div`
  // padding-bottom: 12px;
  margin-left: 20px;
`;

export const DownloadButton = styled.button`
  display: block;
  flex-direction: row;
  padding: 8px 46px;
  width: auto;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #f2f2f2;
  background: #1093ff;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-top: 8px;
`;

export const ActionButton = styled(MuiButton)`
  min-width: 203px !important;
  height: 40px;
  margin-bottom: 5px;
  text-align: center;
  text-transform: none !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  background: #1093ff !important;
  border-radius: 30px !important;
`;

export const ReportCardContainer = styled.div`
  background: #ffffff;
  border-radius: 0px 12px 12px 12px;
  position: relative;
  padding: 15px;
`;

export const PieChartContainer = styled.div`
  display: flex;
  justify-content: space-between,
  align-items: center;
`;

export const ChartTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400px;
  font-size: 21px;
  color: #686868;
  padding-bottom: 10px;
  display: ${props => props.display};
  flex-direction: ${props => props.direction};
`;

export const ChartValue = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #052a4e;
  display: flex;
`;

export const Card = styled.div`
  width: 100%;
  padding: ${props => props.padding || '15px'};
  background: #f7f7f7;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 2;
`;

export const TopicCardHeader = styled.div`
  overflow: scroll;
  ::-webkit-scrollbar {
    display: block !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  <<<<<<<
    HEAD
    =======>>>>>>>84ab8a6514d9ad148b5040e996b732e9a7d30334
    ::-webkit-scrollbar-thumb {
    background: #a2d5ff;
    border-radius: 25px;
  }
`;

export const Indicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
  margin-top: 3px;
  gap: 1vh;
`;

export const Indicator2 = styled.div`
  height: 11px;
  width: 10px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
  margin-top: 3px;
  gap: 1vh;
`;

export const CardHeaderTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #686868;
  padding-left: 16px;
`;

export const CardFooter = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #002d18;
  text-align: left;
  margin-top: 15%;
`;

export const Item = styled.div`
  width: ${props => (props.width ? props.width + 'px' : 'unset')};
`;

export const Item2 = styled.div`
  width: ${props => (props.width ? props.width + 'px' : 'unset')};
  align-items: center;
  margin-top: 25px;
`;

export const CardPerentage = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1093ff;
  text-align: right;
  font-weight: 600;
  margin-top: -10%;
`;

export const Card1 = styled.div`
  width: 100%;
  padding: ${props => props.padding || '10px'};
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
  padding-bottom: ${props => props.pb};
`;

export const Card2 = styled.div`
  width: 100%;
  padding: ${props => props.padding || '10px'};
  margin: ${({ margin }) => margin};
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: ${props => props.position || 'unset'};
  max-height: ${props => props.maxHeight || 'unset'};
  min-height: ${props => props.minHeight || 'unset'};
  margin-top: 15px;
`;

export const ReportText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
  padding-top: 4%;
  padding-left: 1%;
  padding-bottom: 2%;
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
`;

export const CardItem = styled.div`
  display: flex;
  padding: 10px 3px;
  width: 100%;
`;

export const ItemCaption = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;
export const ItemIcon = styled.div`
  padding-right: 15px;
  padding-left: 30px;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 15%;
`;

export const ImageText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #052a4e;
`;

export const ErrorTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #052a4e;
  padding-top: 25px;
  padding-bottom: 5px;
`;

export const ReportSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #1093ff;
  margin-top: -40px;
  cursor: pointer;
`;

export const CardTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const CardSubTitle = styled.div`
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 0px;
`;

export const CardSubTitle1 = styled.div`
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 0px;
  max-width: 50%;
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -20px;
`;

export const CardIndicator = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color || '#bbb'};
  border-radius: 66%;
`;

export const ItemCaption2 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0a66c2;
  text-align: center;
`;

export const ItemCaption3 = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0a66c2;
  text-align: center;
`;

export const Percentage = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #0a66c2;
  padding-left: 3rem;
`;

export const FlexEnded = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexView2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vh;
`;

export const TableBodyStyle = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
  },
}))(TableBody);

export const TableHeaderCell = withStyles(theme => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#052A4E',
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
  },
}))(TableCell);

// export const Card1 = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 4vh;
// `;
