/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Dialog, ThemeProvider, Typography } from '@material-ui/core';
import React from 'react';
import {
  CloseSharp,
  gmatTheme,
  IconButton,
} from '../../../../../../assets/css/GmatStyles';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../../../assets/css/GreStyles';
import LoadingSpinners from '../../../../../../utils/components/LoadingSpinner';

function Confirmation({ onYes, onNo, open,loading }) {
  return (
    <ThemeProvider theme={gmatTheme}>
      <Dialog fullWidth maxWidth={'xs'} open={open}>
        <DialogTitle disableTypography>
          <span className="confirmation_dialog_header">
            Answer Confirmation
          </span>
          <IconButton onClick={onNo}>
            <CloseSharp color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h4" className="confirmation_dialog_body">
            Click Yes to confirm your answer and continue to the next Question
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={loading} onClick={onYes} style={loading===true?{background:"grey",color:"#fff"}:{color:"#fff"}}>
          <Box
            display={'flex'}
            gridGap={15}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {loading &&<LoadingSpinners type="circle" />}
            <Box>Yes</Box>
          </Box>
          </Button>
          <Button variant="contained" onClick={onNo}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Confirmation;
