import React from 'react';
import {
  ThemeProvider,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Card,
  Box,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { listItem } from '../../../assets/css/dashboard/viewmore/StrengthStyles';
import {
  StyledSelect,
  MuiDivider,
  FilterContainer,
  QuestionDiv,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { questionBankTheme } from '../../../assets/css/MuiStyles';
import {
  ResultIcon,
  QuestionResultBox,
} from '../../../assets/css/StyledQuestionComponent';
import correctCircle from '../../../assets/icons/correctCircle.svg';
import incorrectCircle from '../../../assets/icons/incorrectCircle.svg';
import Latex from '../../../utils/LatexViewer';
import { DROP_DOWN_PROPS } from '../../../constant/Variables';
import { TableItemUnderline } from '../../../assets/css/Styles';
import { routePaths } from '../../../routes/RoutePath';
import { useHistory } from 'react-router';

export default function Right(props) {
  const {
    attemptStatus: attemptStatusText,
    handleAttemptStatus,
    passageQuestions,
    difficultyLevel,
    handleCheckBox,
    handleClick,
  } = props;

  const history = useHistory();

  const allStatus = [
    { value: 'ALL', title: 'All' },
    { value: 'ATTEMPTED', title: 'Attempted' },
    { value: 'NOTATTEMPTED', title: 'Not Attempted' },
  ];
  const allQuestion =
    passageQuestions?.question?.length !== 0
      ? passageQuestions?.question?.map(({ question }) => question.id)
      : [];

  // console.log(passageQuestions,"passageQuestions")

  return (
    <div>
      <FilterContainer p="0 0 20px 0">
        <ThemeProvider theme={listItem}>
          <StyledSelect
            onChange={handleAttemptStatus}
            value={attemptStatusText}
            IconComponent={ExpandMore}
            disableUnderline
            MenuProps={DROP_DOWN_PROPS}
          >
            {allStatus.map(status => {
              return <MenuItem value={status.value}>{status.title}</MenuItem>;
            })}
          </StyledSelect>
          <MuiDivider orientation="horizontal" />
          <ThemeProvider theme={questionBankTheme}>
            <FormGroup style={{ paddingLeft: '11px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="EASY"
                    checked={
                      difficultyLevel && difficultyLevel.indexOf('EASY') > -1
                    }
                    onChange={handleCheckBox}
                  />
                }
                label="Easy"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="MEDIUM"
                    checked={
                      difficultyLevel && difficultyLevel.indexOf('MEDIUM') > -1
                    }
                    onChange={handleCheckBox}
                  />
                }
                label="Moderate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="HARD"
                    checked={
                      difficultyLevel && difficultyLevel.indexOf('HARD') > -1
                    }
                    onChange={handleCheckBox}
                  />
                }
                label="Hard"
              />
            </FormGroup>
          </ThemeProvider>
        </ThemeProvider>
      </FilterContainer>
      <QuestionDiv>
        {passageQuestions &&
        passageQuestions.question &&
        passageQuestions.question.length !== 0
          ? passageQuestions.question.map(
              ({ question, isAnswered, attemptStatus }, index) => {
                return (
                  <Card
                    padding={'10px 30px'}
                    display={'flex'}
                    gridGap={10}
                    color={'#052A4E'}
                    fontSize={14}
                    alignItems="center"
                    style={{
                      backgroundColor: isAnswered ? '#F7F8FC' : '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    className={'question-card-question-bank-style'}
                  >
                    <Box
                      padding={'10px 30px'}
                      display={'flex'}
                      gridGap={10}
                      color={'#052A4E'}
                      fontSize={14}
                      alignItems="baseline"
                      isAnswered={isAnswered}
                      onClick={() =>
                        // handleClick(question.id, testQuestionId, isAnswered)
                        handleClick(allQuestion, question.testQuestionsSetId)
                      }
                    >
                      <Box>{index + 1}.</Box>
                      <Box
                        whiteSpace={'break-spaces'}
                        lineHeight={1.6}
                        flex={1}
                        overflow="auto"
                      >
                        <Latex math={question.question} />
                      </Box>
                    </Box>
                    {isAnswered && (
                      <QuestionResultBox>
                        <TableItemUnderline
                          style={{
                            margin: '0px 1.5em',
                            fontWeight: '600',
                            color: '#1093ff',
                            fontSize: '14px',
                            width: 'max-content',
                          }}
                          onClick={() => {
                            history.push(
                              `${routePaths.answer}/${question.id}?questionId=${question.id}&ViewExplain=solveByPassage`
                            );
                          }}
                        >
                          View Explanation
                        </TableItemUnderline>
                        <span
                          style={{ display: 'flex' }}
                          isAnswered={isAnswered}
                          onClick={() =>
                            // handleClick(question.id, testQuestionId, isAnswered)
                            handleClick(
                              allQuestion,
                              question.testQuestionsSetId
                            )
                          }
                        >
                          <div>
                            <ResultIcon
                              src={
                                attemptStatus ? correctCircle : incorrectCircle
                              }
                            />
                          </div>
                          <div style={{ marginRight: '2em' }}>
                            <span>
                              {attemptStatus ? 'Correct' : 'Incorrect'}
                            </span>
                          </div>
                        </span>
                      </QuestionResultBox>
                    )}
                  </Card>
                );
              }
            )
          : 'No Questions'}
      </QuestionDiv>
    </div>
  );
}
