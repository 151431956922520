/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Dialog } from '@material-ui/core';

const Model = ({ open, onClose, onCancel, onSubmit, children }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="responsive-dialog-title"
    maxWidth={'sm'}
    fullWidth={'sm'}
  >
    {children}
  </Dialog>
);

export default Model;
