/**
 * (c) CareerLabs. All rights reserved.
 **/
import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  withStyles,
} from '@material-ui/core';

export const Accordion = withStyles({
  root: {
    boxShadow: 'unset',
    borderRadius: '8px',
    margin: '8px 0',
    cursor: 'default',

    '&::before': {
      background: '#FFFFFF',
      top: '0',
    },
  },

  expanded: {
    background: '#f3faff',
    marginTop: '8px !important',

    '&:first-child': {
      marginTop: '8px',
    },
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 7px rgba(183, 222, 255, 0.5)',
    borderRadius: '8px',
    minHeight: '64px',
  },
})(MuiAccordionSummary);

export const TopicTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #052a4e;
`;

export const ConceptTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: rgba(5, 42, 78, 0.4);
`;

export const AccordionDetails = withStyles({
  root: {
    background: '#f3faff',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
})(MuiAccordionDetails);

export const TopicContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  fill: yellow !important;
`;

export const ProgressIcon = styled.div`
  padding-right: 16px;
`;

export const TypeIcon = styled.div`
  padding-right: 16px;
`;

export const TaskTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: ${props =>
    props.status === 'COMPLETED'
      ? '#8294A7'
      : props.status === 'TODO'
      ? '#0A66C2'
      : props.status === 'PROGRESS'
      ? '#052A4E'
      : ''};
`;

export const Time = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #052a4e;
`;

export const DateLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;

  text-align: end;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-right: 13px;
`;

export const Body = styled.div`
  padding: 8px 16px;
  overflow: hidden;
  overflow-y: auto;
  height: 488px;

  display: flex;
  flex-direction: column;
`;

// export const Container = styled(Card)`
//   background: #ffffff;
//   box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
//   border-radius: 8px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
// `;
