/**
 * (c) CareerLabs. All rights reserved.
 **/
import { AUTHENTICATION } from '../../redux/Action';

const initialState = {
  validateResponse: [],
  user: [],
  LoginData: [],
  forgotPasswordData: [],
  resetPasswordData: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION.validate:
      return {
        ...state,
        validateResponse: action.payload,
      };
    case AUTHENTICATION.login:
      return {
        ...state,
        user: action.payload,
      };
    case AUTHENTICATION.loginPhoneNo:
      return {
        ...state,
        OtpResponse: action.payload,
      };
    case AUTHENTICATION.forgotPassword:
      return {
        ...state,
        forgotPasswordData: action.forgotPasswordData,
      };
    case AUTHENTICATION.resetPassword:
      return {
        ...state,
        resetPasswordData: action.resetPasswordData,
      };

    default:
      break;
  }
  return state;
};

export default Reducer;
