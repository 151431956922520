import { Dialog, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const VideoPopup = props => {
  const { firstImage, ImageClickClose, firstImage1, ImageClickClose1 } = props;

  return (
    <div>
      <Dialog
        open={firstImage}
        maxWidth="md"
        fullWidth
        // onClose={ImageClickClose}
        style={{ overflow: 'auto' }}
        className={'VideoPopup'}
      >
        <IconButton
          onClick={ImageClickClose}
          style={{ position: 'absolute', top: '6vh', left: '20px' }}
        >
          <CloseRoundedIcon style={{ fill: '#fff', fontSize: '5vh' }} />
        </IconButton>
        <video autoPlay controls>
          <source
            src="https://assets.thecareerlabs.com/lms/Welcome-to-the-course.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Dialog>

      <Dialog
        open={firstImage1}
        maxWidth="md"
        fullWidth
        // onClose={ImageClickClose1}
        style={{ overflow: 'auto' }}
        className={'VideoPopup'}
      >
        <IconButton
          onClick={ImageClickClose1}
          style={{ position: 'absolute', top: '6vh', left: '20px' }}
        >
          <CloseRoundedIcon style={{ fill: '#fff', fontSize: '5vh' }} />
        </IconButton>
        <video autoPlay controls>
          <source
            src="https://assets.thecareerlabs.com/lms/loqui.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Dialog>
    </div>
  );
};

export default VideoPopup;
