/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MarginContainer = styled.div`
  @media (min-width: 768px) {
    margin-right: 0px;
  }

  @media (min-width: 1024px) {
    margin-right: 20px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
`;

export const Card = styled.div`
  margin-bottom: 13px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 8px;
  white-space: nowrap;
  background-color: ${props => props.color || '#fff'};
  background: ${props => (props.isAnswered ? '#F7F8FC' : '#FFFFFF')};
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Box = styled.div`
  position: relative;
  display: grid;
  padding: 12px 16px;
`;

export const Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #052a4e;
`;

export const ImgContainer = styled.h2`
  display: flex;
  padding-left: 16px;
  align-items: center;
  justify-content: center;
`;

export const InfinityContent = styled.span`
  position: absolute;
  font-weight: 600;
  font-size: 22px;
  color: #052a4e;
`;

export const SubTitle = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #1093ff;
`;

export const PositionImg = styled.img`
  height: 68px;
`;

export const ImgIcon = styled.img`
  width: 50px;
  height: 28px;
  padding-right: 10px;
`;

export const QuestionBox = styled.div`
  padding: 20px;
  display: flex;
  white-space: break-spaces;
`;

export const Span = styled.span`
  padding-right: 8px;
`;

export const QuestionContainer = styled.div`
  margin: 16px 0px;
`;

export const QuestionResultBox = styled.div`
  display: flex;
  /* padding: 0px 20px; */
  font-size: 12px;
  align-items: center;
  position: ${props => (props.attemptStatus ? 'relative' : 'unset')};
  left: ${props => (props.attemptStatus ? '-9px' : 'unset')};
`;

export const ResultIcon = styled.img`
  padding-right: 8px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #5c5c5c;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: none;
  }
`;

export const TopicContainer = styled.div`
  padding: 16px;
  min-height: 100vh;
`;

export const BoxContainer = styled.div`
  padding: 16px;
  margin: 16px 0px 32px;
`;

export const TopicImg = styled.img`
  margin-right: 16px;
  width: 30.8px;
  height: 32.02px;
`;

export const FlexRow = styled.div`
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TestContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const TestTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #052a4e;
  padding-right: 10px;
  white-space: normal;
`;

export const TestSub = styled.div`
  font-size: 12px;
  /* display: contents; */
  color: #5c5c5c;
`;

export const ActionLink = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: none;
  background: white;
  text-align: center;
  cursor: pointer;
  color: #1093ff;

  @media (min-width: 1024px) {
    font-size: 12px;
  }
`;

export const ContinueLink = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: #1093ff;
  padding: 2px 10px;
  box-shadow: 0px 0px 7px rgba(183, 222, 255, 0.5);
  border-radius: 30px;
  border: 1px solid #1093ff;
  cursor: pointer;
`;

export const StartLink = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #1093ff;
  padding: 2px 20px;
  border: 1px solid #1093ff;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 7px rgba(183, 222, 255, 0.5));
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
`;

export const BottomContent = styled.div`
  padding-top: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #052a4e;
  display: flex;
  align-items: center;
`;

export const BottomIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 6px;
`;

export const Content = styled.div`
  padding-top: 12px;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052a4e;
  padding-bottom: 8px;
  width: 100%;
`;

export const Progress = styled.div`
  padding-top: 26px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px 16px;
`;

export const NoBookmark = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #052a4e;
  text-align: center;
  padding: 150px 0;
`;

export const NoTask = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #052a4e;
  text-align: center;
  padding: 150px 0;
`;
