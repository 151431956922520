/**
 * (c) CareerLabs. All rights reserved.
 **/
import React, { Component } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
  CardContainer,
  ProgressTrackerTitle,
  Selectbox,
  StyledButton,
} from '../../../../assets/css/StyledDashboardComponent.js';
import Chart from '../graph/AreaChart';
import { DROP_DOWN_PROPS } from '../../../../constant/Variables.js';
export default class AreachartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spent: '1',
    };
  }

  spentChange = event => {
    this.setState({ spent: event.target.value });
  };

  render() {
    const { t } = this.props;
    return (
      <Card>
        <CardHeader
          action={
            <CardContainer>
              <Selectbox>
                <Select
                  id="spent-select"
                  defaultValue={1}
                  onChange={this.props.onChange}
                  label="Spent"
                  IconComponent={ExpandMore}
                  disableUnderline
                  value={this.props.value}
                  MenuProps={DROP_DOWN_PROPS}
                >
                  <MenuItem value={1}>Daily</MenuItem>
                  <MenuItem value={2}>Weekly</MenuItem>
                </Select>
              </Selectbox>
              <StyledButton
                onClick={this.props.handleProgressTracker}
                className={'btn__active'}
              >
                {t('View More')}
              </StyledButton>
            </CardContainer>
          }
          subheader={
            <ProgressTrackerTitle>{t('Progress Tracker')}</ProgressTrackerTitle>
          }
        />

        <CardContent style={{ paddingBottom: '0', paddingTop: '0' }}>
          <h4>{this.props.data !== null && this.props.data.totalTimeSpend}</h4>
          {this.props.data !== null && (
            <Chart data={this.props.data} value={this.props.value} />
          )}
        </CardContent>
      </Card>
    );
  }
}
