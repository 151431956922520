/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React from 'react';
import '../../../../assets/css/Style.css';
import {
  PlayerBox,
  SubHeading,
  Title,
  TopGridSubPara,
} from '../../../../assets/css/StyledComponent';
import VideoPlayer from '../../../../utils/VideoPlayer';


function RightCard(props) {
  console.log(props.vdoQuest,"wererw")

  return (
    <div
      className={'main-card-align official-guide-right-task-card'}
      id={'text-container'}
    >
      <Box p={3} pl={3} pr={3} pb={6} height="inherit">
        <SubHeading style={{ paddingBottom: '15px' }}>
          {props.content && props.content.title}
        </SubHeading>
        <TopGridSubPara>
          {props.content &&
            props.content.contentVideo.map((item, index) => {
            console.log(item.title)
               return (
                <PlayerBox>
                  <Title>{item.title}</Title>
                  <VideoPlayer
                    videoId={item.id}
                    getVideoObj={props.getVideoObj}
                    index={index}
                    vdoQuest={props.vdoQuest}
                  
                   
                 >
                   
                    </VideoPlayer> 
                </PlayerBox>
              );
            })}
          <p
            className={'copy__allowed'}
            dangerouslySetInnerHTML={{
              __html: props.content && props.content.content,
            }}
          />
        </TopGridSubPara>
      </Box>
    </div>
  );
}

export default RightCard;
