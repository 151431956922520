/**
 * (c) CareerLabs. All rights reserved.
 **/
import { PRACTICE } from '../Action';

const initialState = {
  testType: null,
  subjectsConcepts: null,
  topicsQuestions: null,
  bookmarks: null,
  conceptsSubjects: null,
  bookmarkLandingData: null,
  bookmarkState: {
    subjectValue: 'default',
    conceptValue: 'default',
    activeLeftTab: 'default',
    activeTab: 0,
  },
  mistakeLogState: {
    testTypeValue: 'default',
    subjectsValue: 'default',
    conceptsValue: 'default',
    activeTopic: 'all',
    questionTypes: [],
    questionType: 'default',
  },
  solveByPassageState: {
    subjectValue: null,
    conceptValue: null,
    topicValue: null,
    activePassage: null,
    attemptStatus: 'ALL',
    difficultyLevel: [],
  },
  officialGuideDetails: null,
  officialGuideTopicDetails: null,
  loading: false,
  allQuestionTypes: null,
  passageList: {},
  passageQuestions: {},

  topicTestState: {
    subjectValue: 'all',
    conceptValue: 'all',
    topicValue: 'all',
    difficultyLevel: [],
    attemptStatus: 'ALL',
  },
  topicTestName: null,
};

const PracticeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRACTICE.getTestType: {
      return {
        ...state,
        testType: action.payload,
      };
    }

    case PRACTICE.getSubjectsConcepts: {
      return {
        ...state,
        subjectsConcepts: action.payload,
      };
    }

    case PRACTICE.getTopicsQuestions: {
      return {
        ...state,
        topicsQuestions: action.payload,
      };
    }
    case PRACTICE.addBookmarks: {
      return {
        ...state,
        bookmarks: action.payload,
      };
    }
    case PRACTICE.getBookmarks: {
      return {
        ...state,
        bookmarks: action.payload,
      };
    }
    case PRACTICE.getConceptAndSubject: {
      return {
        ...state,
        conceptsSubjects: action.payload,
      };
    }
    case PRACTICE.getTopicsAndTasksAndQuestions: {
      return {
        ...state,
        bookmarkLandingData: action.payload,
      };
    }
    case PRACTICE.bookmarkState: {
      return {
        ...state,
        bookmarkState: action.payload,
      };
    }

    case PRACTICE.mistakeLogState: {
      return {
        ...state,
        mistakeLogState: action.payload,
      };
    }

    case PRACTICE.setSolveByPassageState: {
      return {
        ...state,
        solveByPassageState: action.payload,
      };
    }

    case PRACTICE.getOfficialGuideDetails: {
      return {
        ...state,
        officialGuideDetails: action.payload,
        loading: action.loading || false,
      };
    }

    case PRACTICE.loader: {
      return {
        ...state,
        loading: action.loading === false ? false : true,
      };
    }

    case PRACTICE.getOfficialGuideTopicDetail: {
      return {
        ...state,
        officialGuideTopicDetails: action.payload,
        loading: action.loading || false,
      };
    }

    case PRACTICE.getAllQuestionTypes: {
      return {
        ...state,
        allQuestionTypes: action.payload,
      };
    }

    case PRACTICE.getPassageList: {
      return {
        ...state,
        passageList: action.payload,
      };
    }

    case PRACTICE.getPassageQuestions: {
      return {
        ...state,
        passageQuestions: action.payload,
        loading: action.loading || false,
      };
    }

    case PRACTICE.setFieldValue: {
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };
    }

    case PRACTICE.setTopicTestState: {
      return {
        ...state,
        topicTestState: action.payload,
      };
    }
    case PRACTICE.getTopicTestName: {
      return {
        ...state,
        topicTestName: action.payload,
      };
    }
    default:
      break;
  }
  return state;
};

export default PracticeReducer;
