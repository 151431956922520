/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import { BookmarkButton } from '../../assets/css/MuiStyles';
import BookmarkIcon from '../../assets/icons/BookmarkOutlined.svg';
import BookmarkFillIcon from '../../assets/icons/BookmarkFilled.svg';
import Arrow from '../../assets/icons/DemoGuideArrow.svg';
import {
  DemoGuideContainer,
  DemoGuideBox,
  DemoArrow,
  DemoBookmark,
  DemoText,
} from '../../assets/css/StyledComponent';
import * as track from '../../mixpanel/Events';

export const RenderBookMark = props => {
  return props.demoBookmark ? (
    <>
      <DemoGuideContainer>
        <DemoBookmark>
          <BookmarkButton style={{ background: '#FFFFFF' }} {...props}>
            <img
              src={props.bookMarked ? BookmarkFillIcon : BookmarkIcon}
              alt=""
            />
          </BookmarkButton>
        </DemoBookmark>
        <DemoArrow className="bookmark__arrow">
          <img src={Arrow} alt="" />
        </DemoArrow>
        <DemoText>Add to Bookmarks</DemoText>
        <DemoGuideBox></DemoGuideBox>
      </DemoGuideContainer>
    </>
  ) : (
    <BookmarkButton
      {...props}
      onClick={e => {
        track.bookmarkPageLanding({
          'Product Name': JSON.parse(localStorage.getItem('userProduct'))
            .productName,
        });
        props.onClick(e);
      }}
    >
      <img src={props.bookMarked ? BookmarkFillIcon : BookmarkIcon} alt="" />
    </BookmarkButton>
  );
};
