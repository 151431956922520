/**
 * (c) CareerLabs. All rights reserved.
 **/
import Button from '@material-ui/core/Button';
import Loading from './LoadingSpinner';
import { Box } from '@material-ui/core';

const LoadingButton = props => (
  <Button {...props}>
    <Box
      display={'flex'}
      gridGap={10}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {props.loading && <Loading type={'circle'} />}
      <Box fontSize={18}>{props.children}</Box>
    </Box>
  </Button>
);

export default LoadingButton;
