/**
 * (c) CareerLabs. All rights reserved.
 **/
import { TEST } from '../Action';

const initialState = {
  questions: [],
  answers: [],
  updateExamDetails: [],
  instructions: null,
  personalStudyPlans: null,
  testSection: {},
  sectionOrder: null,
  testResponse: null,
  testAnswers: null,
  viewExplanation: null,
  videos: {},
  introduction: [],
};

const TestReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST.startTest: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    case TEST.submitAnswer: {
      return {
        ...state,
        answers: action.payload,
        testResponse: action.payload,
      };
    }
    case TEST.testAnswers: {
      return {
        ...state,
        testAnswers: action.payload.data,
      };
    }

    case TEST.viewExplanation: {
      return {
        ...state,
        viewExplanation: action.payload.data,
      };
    }

    case TEST.updateExamDetails: {
      return {
        ...state,
        examDetails: action.payload,
      };
    }
    case TEST.getInstructions: {
      return {
        ...state,
        instructions: action.payload,
      };
    }
    case TEST.personalizedStudyPlan: {
      return {
        ...state,
        personalStudyPlans: action.payload,
      };
    }
    case TEST.getTestSectionInstruction: {
      return {
        ...state,
        testSection: action.payload,
      };
    }
    case TEST.cleanUp: {
      return {
        ...state,
        testSection: {},
      };
    }

    case TEST.startTest2: {
      return {
        ...state,
        testResponse: action.payload,
      };
    }

    case TEST.getIntroductionVideo: {
      return {
        ...state,
        videos: action.payload,
      };
    }

    case TEST.getIntroductionVideo: {
      return {
        ...state,
        introduction: action.payload,
      };
    }
    default:
      break;
  }
  return state;
};

export default TestReducer;
