export const TextVideoIcon = status => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0321 17.937V2.97656H14.1975C13.2295 2.97656 12.4375 3.76861 12.4375 4.73661V18.6146C12.9039 18.1922 13.5243 17.937 14.1975 17.937H20.0321ZM13.7311 5.39663H18.7385C18.9805 5.39663 19.1785 5.59464 19.1785 5.83664C19.1785 6.08307 18.9805 6.27665 18.7385 6.27665H13.7311C13.4891 6.27665 13.2911 6.08307 13.2911 5.83664C13.2911 5.59464 13.4891 5.39663 13.7311 5.39663ZM13.7311 8.47671H18.7385C18.9805 8.47671 19.1785 8.67473 19.1785 8.91673C19.1785 9.16316 18.9805 9.35674 18.7385 9.35674H13.7311C13.4891 9.35674 13.2911 9.16316 13.2911 8.91673C13.2911 8.67473 13.4891 8.47671 13.7311 8.47671ZM13.7311 11.5568H18.7385C18.9805 11.5568 19.1785 11.7548 19.1785 11.9968C19.1785 12.2432 18.9805 12.4368 18.7385 12.4368H13.7311C13.4891 12.4368 13.2911 12.2432 13.2911 11.9968C13.2911 11.7548 13.4891 11.5568 13.7311 11.5568ZM13.2911 15.0769C13.2911 14.8349 13.4891 14.6369 13.7311 14.6369H18.7385C18.9805 14.6369 19.1785 14.8349 19.1785 15.0769C19.1785 15.3233 18.9805 15.5169 18.7385 15.5169H13.7311C13.4891 15.5169 13.2911 15.3233 13.2911 15.0769Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M22.5606 4.29883H20.914V18.8171H14.1995C13.7655 18.8171 13.3504 18.9767 13.0303 19.2668L12.4395 19.8021L11.9999 20.2003L11.5594 19.8016L10.969 19.2672C10.648 18.9769 10.233 18.8171 9.79938 18.8171H3.0849V4.29883H1.44001C1.1968 4.29883 1 4.49585 1 4.73884V20.5793C1 20.8223 1.1968 21.0193 1.44001 21.0193H22.5606C22.8038 21.0193 23.0006 20.8223 23.0006 20.5793V4.73884C23.0006 4.49585 22.8038 4.29883 22.5606 4.29883Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M11.5594 18.6146V4.73661C11.5594 3.76861 10.7718 2.97656 9.79938 2.97656H3.96484V17.937H9.79938C10.477 17.937 11.093 18.1922 11.5594 18.6146ZM9.61899 10.1092L6.5301 13.2068C6.44649 13.2904 6.33211 13.3345 6.2177 13.3345C6.1605 13.3345 6.1033 13.3257 6.0505 13.3037C5.88329 13.2333 5.77769 13.0748 5.77769 12.8945V6.70348C5.77769 6.52309 5.88329 6.36466 6.0505 6.29427C6.2133 6.22826 6.4025 6.26347 6.5301 6.39106L9.61899 9.48876C9.7906 9.66035 9.7906 9.93756 9.61899 10.1092Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
    </svg>
  );
};

export const VideoIcon = status => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.48609 4.48566 0 10 0C15.5143 0 20 4.48609 20 10C20 15.5139 15.5143 20 10 20C4.48566 20 0 15.5139 0 10ZM8.14211 14.0992L13.9754 10.3492C14.0943 10.2726 14.1667 10.1404 14.1667 9.9988C14.1667 9.8572 14.0943 9.72494 13.9754 9.64845L8.14211 5.89845C8.01434 5.81501 7.84914 5.81056 7.7173 5.88298C7.58301 5.95622 7.5 6.09623 7.5 6.2488V13.7488C7.5 13.9014 7.58301 14.0414 7.7173 14.1146C7.77914 14.1484 7.84832 14.1655 7.91668 14.1655C7.9948 14.1655 8.07375 14.1435 8.14211 14.0992Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
    </svg>
  );
};

export const TextIcon = status => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.20117 15.798V5.16797H1.87717V14.744C1.87682 14.7921 1.88721 14.8398 1.9076 14.8834C1.92798 14.9271 1.95785 14.9657 1.99501 14.9963C2.03218 15.027 2.07572 15.0489 2.12245 15.0606C2.16919 15.0724 2.21794 15.0735 2.26517 15.064C2.49317 15.018 2.72117 14.984 2.95117 14.956L3.06917 14.942C3.29117 14.916 3.51317 14.9 3.73317 14.89H3.84917C4.31063 14.8764 4.77247 14.8958 5.23117 14.948L5.34717 14.962C5.56917 14.99 5.79117 15.026 6.01317 15.068L6.13117 15.092C6.59863 15.1896 7.059 15.3186 7.50917 15.478L7.62917 15.52C7.85117 15.6 8.07317 15.688 8.29317 15.784L8.33517 15.802L1.20117 15.798Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M9.67461 17.4829H1.80061L1.72461 17.0469H9.67461V17.4829Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M9.67519 16.6137H1.44319L1.36719 16.1797H9.62919L9.67519 16.2457V16.6137Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M9.67506 15.7305L9.60106 15.6885C9.41306 15.5865 9.22506 15.4885 9.03706 15.3985L8.98506 15.3725C8.78506 15.2765 8.58506 15.1905 8.38506 15.1065L8.22706 15.0405C8.03906 14.9665 7.85106 14.8965 7.66506 14.8405L7.60106 14.8125C7.40106 14.7445 7.20106 14.6845 7.00106 14.6285L6.83306 14.5825C6.64706 14.5345 6.45906 14.4885 6.27306 14.4505L6.21706 14.4365C6.01706 14.3965 5.81706 14.3645 5.61706 14.3345L5.44706 14.3125C5.25706 14.2885 5.06706 14.2685 4.87506 14.2545H4.83306C4.63306 14.2405 4.43306 14.2365 4.23306 14.2345H4.15106H4.08106C3.84906 14.2345 3.61706 14.2345 3.38506 14.2625H3.27306C3.02906 14.2825 2.78306 14.3105 2.53906 14.3485V2.65055C4.90706 2.25055 7.31106 2.74055 9.68506 4.11255L9.67506 15.7305Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M10.3262 4.11336C12.7002 2.74136 15.1042 2.24936 17.4722 2.65136V14.3554C17.3222 14.3334 17.1722 14.3114 17.0222 14.2954C16.9422 14.2954 16.8622 14.2794 16.7822 14.2734C16.7022 14.2674 16.6422 14.2574 16.5702 14.2534C16.4982 14.2494 16.3942 14.2534 16.3062 14.2414H16.1202H15.6682C15.5742 14.2414 15.4822 14.2414 15.3902 14.2414H15.2142L14.9402 14.2634L14.7622 14.2794C14.6702 14.2794 14.5802 14.3014 14.4882 14.3154C14.4282 14.3154 14.3682 14.3294 14.3082 14.3394L14.0402 14.3994L13.8542 14.4314L13.6002 14.4814L13.4002 14.5254L13.1422 14.5994L12.9422 14.6534L12.6882 14.7294L12.4882 14.7914L12.2422 14.8774L12.0422 14.9534L11.8002 15.0374L11.5842 15.1254L11.3522 15.2254L11.1322 15.3254L10.9042 15.4354L10.6782 15.5474L10.4582 15.6614L10.3282 15.7314L10.3262 4.11336Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M18.2002 17.4829H10.3262V17.0469H18.2822L18.2002 17.4829Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M18.5582 16.6137H10.3262V16.2457L10.3722 16.1797H18.6342L18.5582 16.6137Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
      <path
        d="M18.8 15.798H11.666L11.708 15.78C11.928 15.684 12.15 15.596 12.37 15.516L12.494 15.472C12.9428 15.3136 13.4019 15.186 13.868 15.09L13.988 15.064C14.21 15.022 14.43 14.986 14.652 14.958L14.774 14.944C15.23 14.8917 15.6892 14.8723 16.148 14.886H16.27C16.49 14.896 16.71 14.912 16.93 14.936L17.052 14.952C17.28 14.98 17.508 15.014 17.736 15.06C17.7832 15.0695 17.832 15.0684 17.8787 15.0566C17.9255 15.0449 17.969 15.023 18.0062 14.9923C18.0433 14.9617 18.0732 14.9231 18.0936 14.8794C18.114 14.8358 18.1244 14.7881 18.124 14.74V5.16797H18.8V15.798Z"
        fill={
          status === 'COMPLETED'
            ? '#8294A7'
            : status === 'TODO'
            ? '#0A66C2'
            : status === 'PROGRESS'
            ? '#052A4E'
            : ''
        }
      />
    </svg>
  );
};
