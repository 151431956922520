import { Table, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  StatusToolTip,
  TableHeadCell,
  TableHeader,
  TableItemBlack,
  TableItemUnderline,
} from '../../../../../assets/css/Styles';
import { TableBodyStyle } from '../../../../../assets/css/GreReportStyledComponent';
import Status1 from '../../../../../assets/images/status1.png';
import Status2 from '../../../../../assets/images/status2.png';
import Status3 from '../../../../../assets/images/status3.png';
import { QS_PROPS } from '../../../../../constant/Variables';
import { routePaths } from '../../../../../routes/RoutePath';
import QueryString from 'qs';

class ErrorLog extends Component {
  constructor(props) {
    super(props);
  }

  convertTime(time) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (minutes !== 0 && seconds !== 0) return minutes + 'm ' + seconds + 's';
    if (minutes === 0) return seconds + 's';
    if (seconds === 0) return minutes + 's';
  }
  render() {
    const { tableData } = this.props;
    const { testExecutionId, test_type, og_course } = QueryString.parse(
      this.props.location.search,
      QS_PROPS
    );
    return (
      <TableContainer>
        <Table
          className={'review__table'}
          style={{ paddingTop: 0, marginLeft: '-20px' }}
        >
          <TableHeader>
            <TableHeadCell>Que No</TableHeadCell>
            <TableHeadCell>Section</TableHeadCell>

            <TableHeadCell>Difficulty</TableHeadCell>
            <TableHeadCell>Time Taken</TableHeadCell>
            <TableHeadCell>Correct Answer</TableHeadCell>
            <TableHeadCell>Attempt Status</TableHeadCell>
          </TableHeader>
          {tableData.errorLog?.map(item => {
            return (
              <TableBodyStyle>
                <TableRow>
                  <TableItemUnderline
                    style={{ cursor: 'pointer', color: '#2A94F4' }}
                    onClick={() => {
                      this.props.history.push(
                        `${routePaths.answer}/${item.quesNo}?type=TOPIC&testExecutionId=${testExecutionId}&test_type=${test_type}&og_course=${og_course}`
                      );
                    }}
                  >
                    {`Ques ${item.quesNo}`}
                  </TableItemUnderline>
                  <TableItemBlack>
                    {item?.section === null ? '--' : item?.section}
                  </TableItemBlack>

                  <TableItemBlack>{item.difficulty}</TableItemBlack>
                  <TableItemBlack>
                    {this.convertTime(item.timeTaken)}
                  </TableItemBlack>
                  <TableItemBlack>
                    {item?.correctAnswer?.length > 5
                      ? `${item?.correctAnswer?.substring(0, 32)}...`
                      : item.correctAnswer}
                  </TableItemBlack>
                  <StatusToolTip
                    arrow
                    placement="bottom-start"
                    title={item.attemptStatus}
                  >
                    <TableItemBlack>
                      {
                        <img
                          src={
                            item.attemptStatus === 'Not Attempted'
                              ? Status1
                              : item.attemptStatus === 'Correct'
                              ? Status2
                              : item.attemptStatus === 'Incorrect'
                              ? Status3
                              : null
                          }
                          alt={item.attemptStatus}
                        />
                      }
                    </TableItemBlack>
                  </StatusToolTip>
                </TableRow>
              </TableBodyStyle>
            );
          })}
        </Table>
      </TableContainer>
    );
  }
}

export default connect()(withRouter(withTranslation()(ErrorLog)));
