/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Url } from '../../../../assets/css/StyledComponent';
import { getStudentInfo } from '../../../../redux/action/Student';
import { routePaths } from '../../../../routes/RoutePath';
import { formattedDate, getDate } from '../../../../utils/Validation';
import EducationDetail from './EducationDetail';
import EmployeeDetail from './EmployeeDetail';
import PersonalDetail from './PersonalDetail';
import Back from '../../../../utils/components/Back';

class ProfileDetail extends Component {
  componentDidMount() {
    this.props.getStudentInfo();
  }

  handleGoBack = () => {
    this.props.history.push(routePaths.allCourses);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Back onClick={this.handleGoBack} />
        {this.props.user !== null
          ? this.props.user.length !== 0 && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box pt={3} pb={3} display={'flex'}>
                    <h1 className="title">
                      {t('Profile Update')}&nbsp;&nbsp;&nbsp;
                      <Url
                        onClick={() =>
                          this.props.history.push(routePaths.personalDetail)
                        }
                      >
                        {t('Edit')}
                      </Url>
                    </h1>
                  </Box>
                </Grid>
                {/* Personal Detail */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <PersonalDetail
                    name={
                      this.props.user.firstName +
                        ' ' +
                        this.props.user.lastName || ''
                    }
                    address={
                      this.props.user.address !== null
                        ? `${this.props.user.address.suitNoApartmentNo} , ${
                            this.props.user.address.streetAddressOne
                          } - ${this.props.user.address.streetAddressTwo} , ${
                            this.props.user.address.landMark
                              ? `${this.props.user.address.landMark}  , `
                              : ''
                          }${this.props.user.address.city}, ${
                            this.props.user.address.state
                          } - ${this.props.user.address.pincode}`
                        : ''
                    }
                    dob={formattedDate(getDate(this.props.user.dob)) || ''}
                    email={this.props.user.email || ''}
                    phoneNumber={this.props.user.phoneNumber || ''}
                    facebook={this.props.user.faceBookUrl || ''}
                    linkedIn={this.props.user.linkedInUrl || ''}
                    twitter={this.props.user.twitterUrl || ''}
                    t={t}
                    history={this.props.history}
                    location={this.props.location}
                  />
                </Grid>

                {this.props.user.employment &&
                  this.props.user.employment.length !== 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box pt={3} pb={3}>
                        <h1 className="sub__title">
                          {t('Employment details')}
                        </h1>
                      </Box>
                    </Grid>
                  )}

                <Grid xs={12} sm={12} md={12} lg={12} container spacing={1}>
                  {this.props.user.employment &&
                    this.props.user.employment.length !== 0 &&
                    this.props.user.employment.map(item => {
                      return (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <EmployeeDetail
                            role={item.role}
                            type={
                              item.employmentType === 'FULL_TIME'
                                ? 'Full Time'
                                : item.employmentType === 'PART_TIME'
                                ? 'Part Time'
                                : ''
                            }
                            company={item.organization}
                            startDate={new Date(item.startDate).getFullYear()}
                            endDate={
                              item.endDate === null
                                ? 'Present'
                                : new Date(item.endDate).getFullYear()
                            }
                            roleDescription={item.description}
                            t={t}
                          />
                        </Grid>
                      );
                    })}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box mt={5} pb={3}>
                    <h1 className="sub__title">{t('Education Details')}</h1>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <EducationDetail
                        degree={
                          this.props.user.degree !== null
                            ? this.props.user.degree.name
                            : ''
                        }
                        department={
                          this.props.user.department !== null
                            ? this.props.user.department.name
                            : ''
                        }
                        college={
                          this.props.user.college !== null
                            ? this.props.user.college.name
                            : ''
                        }
                        university={
                          this.props.user.university !== null
                            ? this.props.user.university.name
                            : ''
                        }
                        startDate={
                          (this.props.user.startDate &&
                            new Date(
                              this.props.user.startDate
                            ).getFullYear()) ||
                          ''
                        }
                        endDate={
                          (this.props.user.startDate &&
                            new Date(this.props.user.endDate).getFullYear()) ||
                          ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.studentReducer.user,
    courses: state.profileReducer.courses,
  };
};

export default connect(mapStateToProps, { getStudentInfo })(
  withTranslation()(ProfileDetail)
);
