/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, ClickAwayListener, IconButton } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useEffect, useState } from 'react';
import {
  getFormatDate,
  getWeekOfMonth,
  getWeeksDiff,
  nextWeek,
  weekCount,
} from '../../methods/Helper';
import './style.css';

const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

function getDateFromMonth(month, year) {
  var daysInMonth = [];
  for (let i = 1; i <= 32 - new Date(year, month, 32).getDate(); i++) {
    let date = new Date(year, month, i);
    let obj = {
      day: weekDays[date.getDay()],
      date: date.getDate(),
      month: date.getMonth(),
      year: year,
      dayIndex: date.getDay(),
    };
    daysInMonth.push(obj);
  }
  daysInMonth.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
  let dummyLen = daysInMonth[0].dayIndex,
    i = 0;
  while (i < dummyLen) {
    let dummyObj = {
      day: '',
      date: '',
      month: '',
    };
    daysInMonth.unshift(dummyObj);
    i++;
  }
  return daysInMonth;
}

export default function WeeklyCalendar({
  month,
  year,
  currentWeek,
  renderContainer,
  studyPlanStartDate,
  studyPlanEndDate,
  onHover,
  onMouseOut,
  onClose,
  onMonthChange,
}) {
  const [m, setM] = useState(month - 1);
  const [y, setY] = useState(year);
  const [week, setWeek] = useState(currentWeek);
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const onPrevClick = () => {
    if (week > 1) setWeek(week - 1);
    else {
      if (m <= 0) {
        setM(11);
        setY(y - 1);
        setWeek(weekCount(y - 1, 12));
      } else {
        setM(m - 1);
        setWeek(weekCount(y, m));
      }
    }
  };

  const onNextClick = () => {
    const weekCountOfMonth = weekCount(y, m + 1);
    if (weekCountOfMonth !== week) setWeek(week + 1);
    else {
      if (m < 11) {
        setM(m + 1);
        setWeek(1);
      } else {
        setM(0);
        setY(y + 1);
        setWeek(1);
      }
    }
  };

  useEffect(() => {
    onMonthChange(m, year);
  }, [m]);

  // Get days of Week based on month and year
  const daysOfWeek = getDateFromMonth(m, y).filter(
    (_, index) =>
      index < week * 7 && ((week > 1 && index >= (week - 1) * 7) || week === 1)
  );

  // Get start date in a current week
  const currentWeekStartDate = daysOfWeek
    .filter(item => Boolean(item.date))
    .pop().date;

  // Get first date in a current week
  const currentWeekFirstDate = new Date(
    `${y}-${m + 1}-${currentWeekStartDate}`
  );
  currentWeekFirstDate.setDate(
    currentWeekFirstDate.getDate() - currentWeekFirstDate.getDay()
  );

  // Get last date in a current week
  const currentWeekLastDate = new Date(`${y}-${m + 1}-${currentWeekStartDate}`);
  currentWeekLastDate.setDate(
    currentWeekLastDate.getDate() + (6 - currentWeekLastDate.getDay())
  );

  // Get first date in a study plan start week
  const studyPlanWeekFirstDate = new Date(studyPlanStartDate);
  studyPlanWeekFirstDate.setDate(
    studyPlanWeekFirstDate.getDate() - studyPlanWeekFirstDate.getDay()
  );

  // Get last date in a study plan end week
  const studyPlanWeekLastDate = new Date(studyPlanEndDate);
  studyPlanWeekLastDate.setDate(
    studyPlanWeekLastDate.getDate() + (6 - studyPlanWeekLastDate.getDay())
  );

  // Get week difference
  const studyPlanWeekDifference = getWeeksDiff(
    new Date(studyPlanStartDate).getTime(),
    new Date(currentWeekLastDate).getTime()
  );

  // Check if the study plan weeks or not
  const isStudyPlanWeeks =
    studyPlanWeekFirstDate.getTime() <= currentWeekLastDate.getTime() &&
    studyPlanWeekLastDate.getTime() >= currentWeekLastDate.getTime();

  const disabledNextButton =
    getFormatDate(studyPlanWeekLastDate) === getFormatDate(currentWeekLastDate);

  const disabledPrevButton =
    getFormatDate(studyPlanWeekFirstDate) ===
    getFormatDate(currentWeekFirstDate);

  const renderWeekTitle = () => {
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    let currentWeek = getWeekOfMonth(new Date());
    let activeWeek = getWeekOfMonth(new Date(currentWeekLastDate));
    var result = '';
    if (currentMonth === m && currentYear === y && activeWeek === currentWeek)
      result = 'This week';
    else if (nextWeek(new Date(currentWeekLastDate))) result = 'Next week';
    else if (isStudyPlanWeeks) result = `Week ${studyPlanWeekDifference + 1}`;
    return result;
  };

  return (
    <Box className="App" minHeight={226}>
      <div className={'month-container'}>
        <Box
          padding={'5px'}
          fontWeight={600}
          fontSize={16}
          lineHeight={'20px'}
          color={'#052A4E'}
          display={'flex'}
          alignItems={'center'}
          borderBottom={'0.5px solid #1093FF'}
          gridGap={10}
        >
          <Box flex={1}>{renderWeekTitle()}</Box>

          <Box paddingRight={'20px'}>{months[m] + ' ' + y}</Box>
          <Box display={'flex'} gridGap={10}>
            <IconButton
              style={{
                backgroundColor: '#1093FF',
                opacity: disabledPrevButton && 0.5,
              }}
              size={'small'}
              onClick={onPrevClick}
              disabled={disabledPrevButton}
            >
              <ChevronLeft style={{ color: '#fff' }} />
            </IconButton>
            <IconButton
              style={{
                backgroundColor: '#1093FF',
                opacity: disabledNextButton && 0.5,
              }}
              size={'small'}
              onClick={onNextClick}
              disabled={disabledNextButton}
            >
              <ChevronRight style={{ color: '#fff' }} />
            </IconButton>
          </Box>
        </Box>
      </div>
      <ol id="days-of-week" className="day-of-week">
        {weekDays.map(item => (
          <li>{item}</li>
        ))}
      </ol>
      <ClickAwayListener onClickAway={onClose}>
        <ol id="calendar-days" className="days-grid" onMouseLeave={onMouseOut}>
          {daysOfWeek.map((item, index) => {
            return (
              <>
                <li
                  className={
                    item.month === new Date().getMonth() &&
                    item.date === new Date().getDate() &&
                    item.year === new Date().getFullYear()
                      ? 'active-date calendar-day'
                      : 'calendar-day'
                  }
                  key={index}
                  onMouseEnter={e => onHover(e, item)}
                >
                  <span>{item.date}</span>
                </li>
                {renderContainer(item)}
              </>
            );
          })}
        </ol>
      </ClickAwayListener>
    </Box>
  );
}
