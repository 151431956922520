import {
  Box as MuiBox,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  Box,
  Card,
  FlexBox,
  FlexContainer,
  ImgContainer,
  ImgIcon,
  InfinityContent,
  MarginContainer,
  PositionImg,
  QuestionResultBox,
  ResultIcon,
  SubTitle,
  Title,
} from '../../../../assets/css/StyledQuestionComponent';
import correctCircle from '../../../../assets/icons/correctCircle.svg';
import incorrectCircle from '../../../../assets/icons/incorrectCircle.svg';
import infinity from '../../../../assets/images/infinity.png';
import questionBankIcon from '../../../../assets/images/questionAnswer.png';
import LatexViewer from '../../../../utils/LatexViewer';

export default class QuestionCard extends Component {
  handleQuestionCardClick = () => [];

  render() {
    const { data, t, level } = this.props;

    return (
      <MarginContainer>
        <Card>
          <FlexContainer>
            <Box>
              <Title>{this.props.data && this.props.data.title}</Title>
              <FlexBox>
                <ImgIcon src={questionBankIcon} />
                <SubTitle>{t('Question Bank')}</SubTitle>
              </FlexBox>
            </Box>

            <Box>
              <FlexBox>
                <FormGroup>
                  <FormControlLabel
                    checked={this.props.level.indexOf('EASY') > -1}
                    control={
                      <Checkbox
                        onChange={this.props.handleChange}
                        name="EASY"
                        checked={level.indexOf('EASY') > -1}
                        inputProps={{ className: 'on-boost-mode non-display' }}
                      />
                    }
                    label={t('Easy')}
                    className="on-boost-mode"
                  />
                  <FormControlLabel
                    checked={this.props.level.indexOf('MEDIUM') > -1}
                    control={
                      <Checkbox
                        onChange={this.props.handleChange}
                        name="MEDIUM"
                        checked={level.indexOf('MEDIUM') > -1}
                        inputProps={{ className: 'on-boost-mode non-display' }}
                      />
                    }
                    label={t('Moderate')}
                    className="on-boost-mode"
                  />
                  <FormControlLabel
                    checked={this.props.level.indexOf('HARD') > -1}
                    control={
                      <Checkbox
                        onChange={this.props.handleChange}
                        name="HARD"
                        checked={level.indexOf('HARD') > -1}
                        inputProps={{ className: 'on-boost-mode non-display' }}
                      />
                    }
                    label={t('Hard')}
                    className="on-boost-mode"
                  />
                </FormGroup>
              </FlexBox>
            </Box>
          </FlexContainer>
        </Card>
        <MuiBox mt={2}>
          {data.questions.map(
            ({ question, isAnswered, attemptStatus }, index) => {
              return (
                <Card
                  isAnswered={isAnswered}
                  onClick={() =>
                    this.props.onClick(
                      question.id,
                      data.id,
                      index + 1,
                      isAnswered
                    )
                  }
                >
                  <MuiBox
                    padding={'10px 30px'}
                    display={'flex'}
                    gridGap={10}
                    color={'#052A4E'}
                    fontSize={14}
                  >
                    <MuiBox>{index + 1}.</MuiBox>
                    <MuiBox
                      whiteSpace={'break-spaces'}
                      lineHeight={1.6}
                      flex={1}
                    >
                      <LatexViewer math={question.question} />
                    </MuiBox>
                    {isAnswered ? (
                      <QuestionResultBox attemptStatus={attemptStatus}>
                        <div>
                          <ResultIcon
                            src={
                              attemptStatus ? correctCircle : incorrectCircle
                            }
                          />
                        </div>
                        <div>
                          <span>{attemptStatus ? 'Correct' : 'Incorrect'}</span>
                        </div>
                      </QuestionResultBox>
                    ) : (
                      ''
                    )}
                  </MuiBox>
                </Card>
              );
            }
          )}
        </MuiBox>
      </MarginContainer>
    );
  }
}
