/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { dashboardRoutes } from '../../routes/RoutePath';

export default class DashboardLayout extends Component {
  render() {
    return (
      <div>
        <Box id="main-container">
          {/* <BreadCrumbs />                 */}
          <Switch>
            {dashboardRoutes.map(item => {
              return <Route {...item} />;
            })}
          </Switch>
        </Box>
      </div>
    );
  }
}
