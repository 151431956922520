/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Grid } from '@material-ui/core';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BodyContainer } from '../../../assets/css/StyledDashboardComponent.js';
import Completed from '../../../assets/icons/completed.svg';
import Progress from '../../../assets/icons/Progress.svg';
import Todo from '../../../assets/icons/todo.svg';
import { QS_PROPS } from '../../../constant/Variables';
import {
  getDiagnosticEngineByTopic,
  getSubjectAndConceptByTopic,
  markDiagnosticEngineCompleted,
  sendContactMentorMail,
  visitedDiagnosticEngine,
} from '../../../redux/action/DashboardLanding';
import { routePaths } from '../../../routes/RoutePath';
import BreadCrumbs from '../../../utils/BreadCrumbs';
import PageLoader from '../../../utils/components/PageLoader';
import DialogComponent from './DialogComponent.jsx';
import Main from './Main';
import { Task, Test, Text } from './Types';

const Icons = { COMPLETED: Completed, PROGRESS: Progress, TODO: Todo };

const Types = {
  TOPICTEST: Test,
  QBTESTEASY: Test,
  QBTESTHARD: Test,
  TASK: Task,
  TEXT: Text,
};

class DiagnosticEngine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      type: null,
      subjectId: null,
      conceptId: null,
      subjectName: null,
      topicId: null,
      dialogOpen: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { topicId } = QueryString.parse(this.props.location.search, QS_PROPS);

    if (topicId) {
      this.props.getSubjectAndConceptByTopic(topicId, res => {
        if (res.success) {
          this.setState({
            subjectId: res.data.subjectId,
            conceptId: res.data.conceptId,
            subjectName: res.data.subjectName,
          });
        }
      });
    }

    this.getDiagnosticEngine(topicId, true);

    if (
      localStorage.getItem('userProduct') &&
      JSON.parse(localStorage.getItem('userProduct'))
        .isVisitedDiagnosticEngine === false
    )
      this.props.visitedDiagnosticEngine();
  }

  getDiagnosticEngine = (topicId, completedCall) => {
    this.props.getDiagnosticEngineByTopic(topicId, response => {
      if (response.success) {
        const { contents, position } = response.data;
        this.setState({
          activeStep: position - 1,
          type: contents.length !== 0 && contents[position - 1]['type'],
          topicId: topicId,
        });
        if (
          completedCall &&
          contents.length !== 0 &&
          contents[position - 1]['type'] === 'TEXT'
        )
          this.props.markDiagnosticEngineCompleted(topicId);
      }
    });
  };

  handleNext = e => {
    const { name } = e.currentTarget;
    const {
      type,
      topicId,
      activeStep,
      subjectId,
      conceptId,
      subjectName,
      dialogOpen,
    } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;

    if (name && name === 'Next') {
      this.setState(prevState => {
        if (data['contents'][prevState.activeStep + 1]['isAllow'] === true) {
          if (
            topicId &&
            data['contents'][prevState.activeStep + 1]['type'] === 'TEXT'
          )
            this.props.markDiagnosticEngineCompleted(topicId);
          return {
            activeStep: prevState.activeStep + 1,
            type: data
              ? data['contents'][prevState.activeStep + 1]['type']
              : null,
          };
        }
      });
    } else {
      if (type === 'TASK' && topicId) {
        if (data['contents'][activeStep]['title'] === 'Revise Task') {
          if (data['contents'][activeStep]['isNewVideosPresent']) {
            this.setState({
              dialogOpen: true,
            });
          } else {
            let taskObj = data['contents'][activeStep]['content'].find(
              ({ status }) => status !== 'COMPLETED'
            );
            this.props.history.push(
              `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskObj.id}&type=revise&boost=true&prevPage=diagnostic_engine`
            );
          }
        } else {
          // this.props.history.push(
          //   `${routePaths.dashboard.taskDetail}?topicId=${topicId}&boost=true&prevPage=diagnostic_engine`
          let taskObj = data['contents'][activeStep]['content'].find(
            ({ status }) => status !== 'COMPLETED'
          );
          if (taskObj && taskObj.isTest) {
            localStorage.setItem('diagnosticEngine', true);

            this.props.history.push(
              `${routePaths.dashboard.instruction}?testQuestionId=${taskObj.id}&diagnostic=diagnostic-engine&topicId=${topicId}`
            );
          } else
            this.props.history.push(
              `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskObj.id}&boost=true&prevPage=diagnostic_engine`
              // `${routePaths.dashboard.taskDetail}?topicId=${topicId}&boost=true&prevPage=diagnostic_engine`
              // `${routePaths.dashboard.taskDetail}?topicId=${topicId}&boost=true`
            );
        }
      }

      if (type === 'TOPICTEST' && topicId) {
        const testQuestionId = this.getTestQuestionSetId(
          data.contents[activeStep].content
        );

        localStorage.setItem('boostScore', true);

        this.props.history.push(
          `${routePaths.dashboard.instruction}?testQuestionId=${testQuestionId}`
        );
      }

      // EASY and MEDIUM Question Bank
      if (type === 'QBTESTEASY' && topicId) {
        localStorage.setItem('questionBank', true);

        this.props.history.push(
          `${routePaths.dashboard.solveByQuestion}?subjectId=${subjectId}&conceptId=${conceptId}&topicId=${topicId}&level=MEDIUM,EASY&boost=true`
        );
      }

      // HARD Question Bank
      if (type === 'QBTESTHARD' && topicId) {
        localStorage.setItem('questionBank', true);
        this.props.history.push(
          `${routePaths.dashboard.solveByQuestion}?subjectId=${subjectId}&conceptId=${conceptId}&topicId=${topicId}&level=HARD&boost=true`
        );
      }

      if (type === 'TEXT') {
        if (name === 'Contact Mentor')
          this.props.markDiagnosticEngineCompleted(topicId);
        this.props.sendContactMentorMail(topicId, response => {
          if (response.success) {
            this.getDiagnosticEngine(topicId, false);
          }
        });
        if (name === 'Back to home')
          this.props.history.push(routePaths.dashboard.home);
        if (name === 'Back to Strength and Weakness')
          this.props.history.push(routePaths.dashboard.strengthsWeakness);
      }
    }
  };

  getTestQuestionSetId = arr => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].testStatus === 'TODO' || arr[i].testStatus === 'PROGRESS')
        return arr[i].id;
    }
  };

  handleSteperClick = e => {
    console.log('kar');
    const { id } = e.currentTarget;
    const { topicId } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;
    this.setState({
      activeStep: parseInt(id),
      type: data ? data['contents'][id]['type'] : null,
    });

    // if (topicId && data['contents'][id]['type'] === 'TEXT')
    //   this.props.markDiagnosticEngineCompleted(topicId);
  };

  handleTask = (e, taskObj) => {
    console.log('sdcdscv');
    const { id } = e.currentTarget;
    const { type, topicId, activeStep } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;
    if (type === 'TASK' && topicId && data) {
      if (data['contents'][activeStep]['title'] === 'Revise Task') {
        this.props.history.push(
          `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${id}&type=revise&boost=true&prevPage=diagnostic_engine`
        );
      } else {
        if (taskObj.isTest) {
          localStorage.setItem('diagnosticEngine', true);
          this.props.history.push(
            `${routePaths.dashboard.instruction}?testQuestionId=${id}`
          );
        } else {
          this.props.history.push(
            `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${id}&boost=true&prevPage=diagnostic_engine`
          );
        }
      }
    }
  };

  handleTestClick = e => {
    console.log('gh');
    const { id } = e.currentTarget;
    console.log(id, 'id');
    const { type, topicId, activeStep } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;
    if (type === 'TASK' && topicId && data)
      if (data['contents'][activeStep]['title'] === 'All Task') {
        this.props.history.push(
          `${routePaths.dashboard.instruction}?testQuestionId=${id}`
        );
      }
  };

  handlePopUpClose = () => {
    this.setState({
      ...this.state,
      dialogOpen: false,
    });
  };

  handleReviseButtonClick = () => {
    const { topicId } = QueryString.parse(this.props.location.search, QS_PROPS);
    const { type, activeStep } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;
    let taskObj = data['contents'][activeStep]['content'].find(
      ({ status }) => status !== 'COMPLETED'
    );

    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskObj.id}&type=revise&boost=true&prevPage=diagnostic_engine`
    );
  };

  handleNewConceptButtonClick = () => {
    const { topicId } = QueryString.parse(this.props.location.search, QS_PROPS);
    const { type, activeStep } = this.state;
    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;
    let taskObj = data['contents'][activeStep]['content'].find(
      ({ status }) => status !== 'COMPLETED'
    );
    this.props.history.push(
      `${routePaths.dashboard.taskDetail}?topicId=${topicId}&taskId=${taskObj.id}&type=revise&boost=true&isNewVideos=true&prevPage=diagnostic_engine`
    );
  };

  render() {
    const { activeStep, type, dialogOpen } = this.state;

    const {
      handleNext,
      handleSteperClick,
      handleTask,
      handleTestClick,
      handlePopUpClose,
      handleReviseButtonClick,
      handleNewConceptButtonClick,
    } = this;

    const data = this.props.diagnosticEngine
      ? this.props.diagnosticEngine.data
      : null;

    const breadCrumbsProps = {
      topics: {
        data: {
          customData: [
            {
              name: 'Home',
              path: routePaths.dashboard.home,
            },
            {
              name: 'Strength & weakness',
              path: routePaths.dashboard.strengthsWeakness,
            },
            {
              name: 'Diagnostic Engine',
              path: routePaths.dashboard.diagnosticEngine,
            },
          ],
        },
      },
      backEvent: true,
      goBack: () => {
        this.props.history.push(routePaths.dashboard.strengthsWeakness);
      },
    };

    return (
      <BodyContainer>
        {data && type ? (
          <Grid container>
            <Grid item xs={12}>
              <BreadCrumbs {...breadCrumbsProps} />
            </Grid>
            <Grid item xs={12}>
              <Main
                steps={
                  data && data.contents.length !== 0
                    ? data.contents.map((item, index) => ({
                      id: index,
                      icon: Icons[item.status],
                      label: item.title,
                      isAllow: item.isAllow,
                    }))
                    : []
                }
                activeStep={activeStep}
                handleNext={handleNext}
                handleSteperClick={handleSteperClick}
                handleTask={handleTask}
                handleTestClick={handleTestClick}
                Type={Types[type]}
                data={data}
                Icons={Icons}
              />
            </Grid>
          </Grid>
        ) : (
          <PageLoader />
        )}
        <DialogComponent
          dialogOpen={dialogOpen}
          handlePopUpClose={handlePopUpClose}
          handleReviseButtonClick={handleReviseButtonClick}
          handleNewConceptButtonClick={handleNewConceptButtonClick}
        />
      </BodyContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.dashboardLandingReducer,
  };
};

export default connect(mapStateToProps, {
  getDiagnosticEngineByTopic,
  sendContactMentorMail,
  markDiagnosticEngineCompleted,
  visitedDiagnosticEngine,
  getSubjectAndConceptByTopic,
})(DiagnosticEngine);
