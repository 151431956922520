import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';

// px conversion ratio at mm => 72dpi
// Formula 1/(25.4mm / 72dpi)
const RATE = 2.83464566929;

// A4 210mm x 297mm
const PAGE_WIDTH = 210 * RATE;
// const PAGE_HEIGHT = 297 * RATE;

// TODO: Temporarily placed. Modify later to match page design
const CONTENT_WIDTH = 210 * RATE;
const CONTENT_HEIGHT = 230 * RATE;
const PAGE_MARGINS: [number, number] = [0 * RATE, 0 * RATE];

//  for type-script
// interface PdfProps {
//   dataUrl: string;
//   pageSize?: {
//     width: number;
//     height: number;
//   };
//   pageOrientation?: string;
//   contentSize?: {
//     width: number;
//     height: number;
//   };
//   pageMargins?: [number, number];
// }

/**
 * Generate PDF from HTML
 * @param {HTMLElement} element
 */
export async function createPdfFromHtml(element, file) {
  const pdfProps = await createPdfProps(element);
  createPdf(pdfProps, file);
  console.log(pdfProps, 'popop');
}

/**
 * Create PdfProps for PDF output
 * @param {HTMLElement} element
 * @returns {Promise<PdfProps>}
 */
async function createPdfProps(element) {
  // run html2canvas
  const options = {
    // HACK: Scale is a fixed value so that the canvas size does not change depending on the browser. I set it to 2, which is large enough to prevent blurring in IE
    scale: 2,
  };
  const canvas = await html2canvas(element, options);
  const dataUrl = canvas.toDataURL();

  const pdfProps = {
    dataUrl,
    pageSize: {
      width: CONTENT_WIDTH + 200,
      height: 'auto',
    },
    pageOrientation: 'PORTRAIT',
    contentSize: {
      width: CONTENT_WIDTH + 190,
    },
    pageMargins: PAGE_MARGINS,
  };

  return pdfProps;
}

/**
 * Output PDF with encoded image URL pasted
 * @param {PdfProps} pdfProps
 */
function createPdf(pdfProps, file) {
  const { dataUrl, contentSize, pageMargins } = pdfProps;
  const pageSize = pdfProps.pageSize;
  const pageOrientation = pdfProps.pageOrientation;

  const documentDefinitions = {
    pageSize,
    pageOrientation,
    content: {
      image: dataUrl,
      ...contentSize,
    },
    pageMargins,
  };

  pdfMake.createPdf(documentDefinitions).download(file);
}
