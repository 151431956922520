import React, { Component } from 'react';
import TaskDetail from './TaskDetail';

class Index extends Component {
  render() {
    return <TaskDetail />;
  }
}

export default Index;
