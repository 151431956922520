/**
 * (c) CareerLabs. All rights reserved.
 **/
import {
  Box,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { connect, useDispatch } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import {
  AuthTitle,
  InputBoxContainer,
  SubTitle,
  Url,
} from '../../../assets/css/StyledComponent';
import {
  buttonTheme,
  colorScheme,
  useStyles,
} from '../../../assets/css/Styles';
import * as track from '../../../mixpanel/Events';
import { login, validate } from '../../../redux/action/Authentication';
import { getCourses } from '../../../redux/action/Profile';
import { routePaths } from '../../../routes/RoutePath';
import Button from '../../../utils/components/Button';
import simulateMouseClick from '../../../utils/methods/SimulateMouseClick';
import { isEmpty, isOtp } from '../../../utils/Validation';
import clevertap from 'clevertap-web-sdk';
import { getCleverTabCommonProperties } from '../../../redux/action/Test';
import { EVENTS } from '../../../clevertap/Events';

function OtpVerify({ t, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const handleChange = otp => {
    setOtp(otp);
  };

  const [state, setState] = useState({ error: false, errorMsg: '' });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const inputFields = document.getElementsByClassName('otp__style');
    const verifyBtn = document.querySelector('.verify-button');

    // Focussing on the first text field
    inputFields[0].focus();

    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        simulateMouseClick(verifyBtn);
      }
    };

    document.body.addEventListener('keydown', listener);
    return () => {
      document.body.removeEventListener('keydown', listener);
    };
  }, []);

  const handleClick = () => {
    setLoading(true);
    if (!isEmpty(otp) && isOtp(otp)) {
      let obj = {
        phoneNumber: window.sessionStorage.getItem('phone'),
        otp: otp,
        source: 'LMS',
      };

      props.login(obj, response => {
        setLoading(false);
        if (response.success) {
          track.noOfTimeLogin('Phone number');
          track.successfulLogin({}, true);

          /* Getting common_properties from localStorage and send it onUserLogin. */
          setTimeout(() => {
            dispatch(
              getCleverTabCommonProperties(res => {
                console.log(res, 'getCleverTabCommonProperties-1');
                if (res.success) {
                  let common_properties = JSON.parse(
                    window.localStorage.getItem('user_common_properties') ||
                      '{}'
                  );
                  clevertap.onUserLogin.push({
                    Site: {
                      ...common_properties,
                    },
                  });
                }
              })
            );
          }, 3000);
          /* Getting common_properties from localStorage and send it onUserLogin. */

          /* Event triggers when user adds their user id and password and logged in to the platform */
          clevertap.event.push(EVENTS.LMSUserLoggedIn, {
            'Count of login': response.data.lmsLoginCount,
          });
          /* Event triggers when user adds their user id and password and logged in to the platform */

          if (response.data.isProfileSetup === 'INCOMPLETE') {
            props.history.push(routePaths.profileSetup);
          } else {
            props.getCourses(response => {
              let acceptCourse = response.data.find(
                product =>
                  product.isStudyPlanCreated &&
                  !(
                    (product.isProductActive && product.isProductExpired) ||
                    !product.isProductActive
                  ) &&
                  !product.isCoursePaused
              );
              if (acceptCourse) {
                localStorage.setItem(
                  'userProduct',
                  JSON.stringify(acceptCourse)
                );
                props.history.push(routePaths.dashboard.home);
              } else {
                props.history.push(routePaths.allCourses);
              }
            });
          }
        } else {
          track.successfulLogin({}, false);
          setState({
            ...state,
            error: true,
            errorMsg: 'Invalid OTP',
          });
        }
      });
    } else {
      setLoading(false);
      setState({ ...state, error: true, errorMsg: 'Please enter OTP' });
    }
  };

  const handleResendClick = () => {
    let obj = {
      phoneNumber: window.sessionStorage.getItem('phone'),
      source: 'LMS',
    };

    props.validate(obj);
  };

  useEffect(() => {
    window.sessionStorage.setItem('isLogout', false);
  });

  const time = new Date();
  time.setSeconds(time.getSeconds() + 30);

  return (
    <ThemeProvider theme={colorScheme}>
      <AuthTitle>{t('Enter OTP')}</AuthTitle>
      <SubTitle>
        {t('A Security code has been send to your mobile number')}
      </SubTitle>
      <Grid container alignItems="center" direction="row">
        <Typography variant="subtitle1" color="secondary">
          <Box fontWeight="Bold">{window.sessionStorage.getItem('phone')}</Box>
        </Typography>
        <IconButton onClick={() => props.history.goBack()} small>
          <CreateIcon color="secondary" />
        </IconButton>
      </Grid>
      <InputBoxContainer>
        <OtpInput
          inputStyle={'otp__style'}
          value={otp}
          onChange={handleChange}
          numInputs={4}
          isInputNum
          hasErrored={true}
          errorStyle={'otp__error'}
          containerStyle={{ justifyContent: 'space-around' }}
          id={'otpInput-btn'}
        />
        {state.error ? (
          <Typography variant="caption" className={classes.error}>
            {t(state.errorMsg)}
          </Typography>
        ) : null}
        <InputBoxContainer>
          <Timer t={t} expiryTimestamp={time} onClick={handleResendClick} />
        </InputBoxContainer>
        <ThemeProvider theme={buttonTheme}>
          <Button
            className={'verify-button'}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClick}
            id={'otpVerify-btn'}
            loading={isLoading}
          >
            {t('Verify')}
          </Button>
        </ThemeProvider>
      </InputBoxContainer>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return { loginResponse: state.AuthReducer.loginResponse };
};
export default connect(mapStateToProps, { login, validate, getCourses })(
  withTranslation()(OtpVerify)
);

function Timer({ expiryTimestamp, onClick, t }) {
  const handleLinkClick = () => {
    onClick();
    const time = new Date();
    time.setSeconds(time.getSeconds() + 30);
    restart(time);
  };

  const { seconds, restart, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp,
  });

  const getZero = seconds => (seconds.toString().length < 2 ? 0 : null);
  return (
    <Grid container direction="row">
      <Typography className={'resend__style'} color="secondary">
        {t('Didn’t Receive OTP ? ')}&nbsp;
      </Typography>
      <Typography color="secondary">
        <Url onClick={handleLinkClick} disabled={isRunning}>
          {t('Resend OTP')}
        </Url>
      </Typography>
      &nbsp;&nbsp;&nbsp;
      <Typography color="secondary" className={'resend__style'}>
        {`${t('in')} 00:`}
        {getZero(seconds)}
        {seconds}
      </Typography>
    </Grid>
  );
}
