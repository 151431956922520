/**
 * (c) CareerLabs. All rights reserved.
 **/
import { Box, Grid, ThemeProvider, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/App.css';
import { buttonTheme } from '../../../../assets/css/MuiStyles';
import { DateCard, YearCard } from '../../../../assets/css/StyledComponent';
import backArrow from '../../../../assets/images/back_arrow_large.png';
import NextArrow from '../../../../assets/images/NextArrow.png';
import { MONTHS } from '../../../../constant/Variables';
import { getGoals } from '../../../../redux/action/Course';
import { getCourses } from '../../../../redux/action/Profile';
import { updateExamDetails } from '../../../../redux/action/Test';
import { routePaths } from '../../../../routes/RoutePath';
import Button from '../../../../utils/components/Button';
import ExamTargetDialogBox from './ExamTargetDialogBox';
import { dateFormat } from '../../../../utils/methods/DateFormat';
import { CLEVERTAP } from '../../../../clevertap/Config';
import { EVENTS } from '../../../../clevertap/Events';

class Exam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monthYear: [],
      selectedMonth: '',
      days: [],
      selectedDate: '',
      start: 0,
      end: 8,
      count: 0,
      isLoading: false,
      dialogOpen: false,
      dialogData: null,
    };
  }

  componentDidMount() {
    var data = this.getMonthAndYear();
    var day = this.getDaysInMonth(
      this.months.findIndex(item => item === data[0].month),
      data[0].year
    );
    this.setState({ monthYear: data });
    this.props.getGoals(response => {
      if (response.success) {
        const { targetDate } = response.data;
        if (targetDate) {
          let date = new Date(targetDate);

          day = this.getDaysInMonth(date.getMonth(), date.getFullYear());

          let arr = [...day.days];
          this.setState({ days: arr });

          // data[0].month = MONTHS[date.getMonth()];
          // data[0].year = date.getFullYear();
          this.setState({
            selectedMonth: data.find(
              item => item.month === MONTHS[date.getMonth()]
            ),
            selectedDate: date,
          });

          let currentDate = date;
          let startCount =
            Math.round(parseInt(currentDate.getDate()) / 8) === 0
              ? 1 * 8
              : Math.round(parseInt(currentDate.getDate()) / 8) * 8;

          this.setState({
            start: startCount - 8,
            end: startCount,
            count: Math.round(parseInt(currentDate.getDate()) / 8),
          });
        } else {
          let arr = [...day.days];
          this.setState({ days: arr });
          this.setState({ selectedMonth: data[0], selectedDate: new Date() });
          let currentDate = new Date();

          if (currentDate.getDate() >= 8 && currentDate.getDate() <= 16) {
            this.setState({ start: 8, end: 16, count: 2 });
          } else if (
            currentDate.getDate() >= 16 &&
            currentDate.getDate() <= 24
          ) {
            this.setState({ start: 16, end: 24, count: 3 });
          } else if (
            currentDate.getDate() >= 24 &&
            currentDate.getDate() <= 32
          ) {
            this.setState({ start: 24, end: 32, count: 4 });
          } else if (currentDate.getDate() >= 0 && currentDate.getDate() <= 8) {
            this.setState({ start: 0, end: 8, count: 1 });
          }
        }
      }
    });
    this.props.getCourses();
  }

  getMonthAndYear() {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const arr = [];
    const today = new Date();

    for (let i = 0; i <= 6; i++) {
      let month = today.getMonth() + i;
      let year = today.getFullYear();
      let obj = {};
      if (month === 12) {
        obj = {
          year: new Date(year + 1, 0, 1).getFullYear(),
          month: months[0],
        };
      } else {
        obj = {
          year: new Date(year, month, 1).getFullYear(),
          month: months[new Date(year, month, 1).getMonth()],
        };
      }
      arr.push(obj);
    }

    return arr;
  }

  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    var dayNames = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      dayNames.push(new Date(date).getDay());
      date.setDate(date.getDate() + 1);
    }
    return { days, dayNames };
  }

  weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  handleClick = () => {
    let obj = {
      targetDate: dateFormat(new Date(this.state.selectedDate)),
      productName: JSON.parse(window.localStorage.getItem('userProduct'))
        .productName,
    };
    this.props.updateExamDetails(obj, res => {
      this.setState({ isLoading: false });
      if (
        JSON.parse(window.localStorage.getItem('userProduct')).productName ===
        'Aptitude test'
      ) {
        //this.props.history.push(routePaths.start);
        this.props.history.push(routePaths.introduction);
      } else this.props.history.push(routePaths.score);
    });
  };

  handleNext = () => {
    const { end, count } = this.state;

    if (count < 4) {
      this.setState({
        start: end,
        end: end + 8,
        count: count + 1,
      });
    }
  };

  handlePrevious = () => {
    const { start, count } = this.state;
    if (count > 1) {
      this.setState({
        start: start - 8,
        end: start,
        count: count - 1,
      });
    }
  };

  handleContinueTargetDateClick = () => {
    this.handleClick();
  };

  handleCloseIconClick = () => {
    this.setState({
      dialogOpen: false,
      dialogData: null,
    });
  };

  handleNextClick = () => {
    var expiryDate = JSON.parse(
      window.localStorage.getItem('userProduct')
    ).expiryDate;
    var targetDate = this.state.selectedDate;
    if (
      expiryDate &&
      this.state.selectedDate &&
      new Date(targetDate) > new Date(expiryDate)
    ) {
      this.setState({
        dialogOpen: true,
        dialogData: {
          bodyText1:
            'Your selected exam date is not matching with the product expiration date',
          bodyText2: ['Would you like to ', ' or ', ' with selected date?'],
          body2HighlightedText: ['change the exam date', 'continue'],
          bodyText3:
            'The study plan will be based on the target date you select.',
          buttonText1: 'Change the exam date',
          buttonText2: 'Continue with selected date',
        },
      });
    } else {
      this.setState({ isLoading: true });
      this.handleClick();
    }
  };

  render() {
    const { dialogOpen, dialogData } = this.state;
    const { handleCloseIconClick, handleContinueTargetDateClick } = this;
    const { t } = this.props;
    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={'gmat_grid_container'}
        >
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={5}>
              <h2 className={'gmat_title'}>
                {t(
                  `Hey when is your ${
                    JSON.parse(window.localStorage.getItem('userProduct'))
                      .productName === 'Aptitude test'
                      ? 'Interview date?'
                      : `${
                          JSON.parse(window.localStorage.getItem('userProduct'))
                            .productName
                        } exam?`
                  }`
                )}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box pt={3}>
              <Typography className={'gmat_subtitle'}>
                {t(
                  `Maximum target date should be ${
                    JSON.parse(window.localStorage.getItem('userProduct'))
                      .productName === 'Aptitude test'
                      ? '6'
                      : '7'
                  } months from the current date.`
                )}
              </Typography>
            </Box>
          </Grid>
          {/* Month and Year Card */}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            className={'card_align'}
          >
            {this.state.monthYear.map(item => (
              <Box pt={5} style={{ margin: '0px 7px' }}>
                <YearCard
                  style={{
                    backgroundColor:
                      this.state.selectedMonth === item ? '#1093FF' : 'white',
                  }}
                  onClick={() => {
                    this.setState({ selectedMonth: item });
                    var day = this.getDaysInMonth(
                      this.months.findIndex(i => i === item.month),
                      item.year
                    );
                    let arr = [...day.days];
                    this.setState({ days: arr });
                  }}
                >
                  <Typography
                    // className={"gmat_card_content"}
                    style={{
                      color:
                        this.state.selectedMonth === item
                          ? '#FFFFFF'
                          : '#052A4E',
                    }}
                  >
                    {item.month + " ' " + item.year}
                  </Typography>
                </YearCard>
              </Box>
            ))}
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '45px 7px',
              // width: "600px"
            }}
          >
            <Box>
              <DateCard onClick={this.handlePrevious}>
                <img
                  src={backArrow}
                  alt="backArrow"
                  style={{ cursor: 'pointer' }}
                />
              </DateCard>
            </Box>
            <Box
              className={'date_card'}
              display="flex"
              width="590px"
              height="90px"
              style={{
                margin: '0px 7px',
              }}
            >
              {this.state.days
                .slice(this.state.start, this.state.end)
                .map(item => (
                  <Box style={{ margin: '0px 7px' }}>
                    <DateCard
                      style={{
                        backgroundColor:
                          new Date(this.state.selectedDate).getDate() ===
                            new Date(item).getDate() &&
                          new Date(this.state.selectedDate).getMonth() ===
                            new Date(item).getMonth()
                            ? '#1093FF'
                            : 'white',
                      }}
                      onClick={() => {
                        if (item >= new Date().setHours(0, 0, 0, 0))
                          this.setState({ selectedDate: item });
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            this.state.selectedDate === item
                              ? '#FFFFFF'
                              : '#686868',
                        }}
                      >
                        <Typography
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color:
                              new Date(this.state.selectedDate).getDate() ===
                                new Date(item).getDate() &&
                              new Date(this.state.selectedDate).getMonth() ===
                                new Date(item).getMonth()
                                ? '#FFFFFF'
                                : '#686868',
                          }}
                        >
                          {this.weekDays[new Date(item.toString()).getDay()]}
                        </Typography>
                        <Typography
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color:
                              new Date(this.state.selectedDate).getDate() ===
                                new Date(item).getDate() &&
                              new Date(this.state.selectedDate).getMonth() ===
                                new Date(item).getMonth()
                                ? '#FFFFFF'
                                : '#686868',
                          }}
                        >
                          {new Date(item.toString()).getDate()}
                        </Typography>
                      </Typography>
                    </DateCard>
                  </Box>
                ))}
            </Box>
            <Box>
              <DateCard
                onClick={this.state.count < 4 ? this.handleNext : () => {}}
              >
                <img
                  src={NextArrow}
                  alt="NextArrow"
                  style={{ cursor: 'pointer' }}
                />
              </DateCard>
            </Box>
          </Grid>

          <Grid item md={12} xs={12}>
            <Box>
              <Typography className={'target_exam_align'}>
                {t(
                  `${
                    JSON.parse(window.localStorage.getItem('userProduct'))
                      .productName === 'Aptitude test'
                      ? 'My Interview Date is'
                      : 'My Target Exam Date'
                  }`
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box pt={1}>
              <h1 className={'target_exam_date_align'}>
                {this.state.selectedDate &&
                  new Date(this.state.selectedDate.toString()).getDate()}
                &nbsp;
                {this.state.selectedDate &&
                  this.months[new Date(this.state.selectedDate).getMonth()]}
                &nbsp;
                {this.state.selectedMonth
                  ? this.state.selectedMonth.year
                  : new Date().getFullYear()}
                &nbsp;
              </h1>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  className={'gmat_button_align'}
                  variant="contained"
                  color="primary"
                  onClick={this.handleNextClick}
                  loading={this.state.isLoading}
                >
                  {t('Next')}
                </Button>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
        <ExamTargetDialogBox
          open={dialogOpen}
          dialogData={dialogData}
          handleContinueTargetDateClick={handleContinueTargetDateClick}
          handleCloseIconClick={handleCloseIconClick}
          t={t}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    examDetailsResponse: state.testReducer.examDetails,
    goal: state.CourseReducer.goals,
  };
};

export default connect(mapStateToProps, {
  updateExamDetails,
  getCourses,
  getGoals,
})(withTranslation()(Exam));
