/**
 * (c) CareerLabs. All rights reserved.
 **/
import React from 'react';
import {
  LeftContainer,
  TopicTitleWhite,
  TopicTitleBlue,
  TopicTitleBlur,
} from '../../../assets/css/totalquestionbank/TotalQuestionBankComponents';
import { totalCount } from './Index';

const addAllTopics = (topics, count) => {
  if (topics.data.length === 0) return [{ topic: 'No Mistakes' }];
  if (topics.data.length === 1) return topics.data;
  else return [{ id: 'default', topic: 'All', count }, ...topics.data];
};

export default function Left(props) {
  const { topics, activeTopic, handleActiveTopic, t } = props;

  if (topics !== null) {
    let count = totalCount(topics.data);
    let allTopics = addAllTopics(topics, count);

    return (
      <LeftContainer>
        {allTopics.map(topic => {
          return (
            <>
              {topics.data.length === 0 ? (
                <TopicTitleBlur key={topic.id}>{t(topic.topic)}</TopicTitleBlur>
              ) : topic.id === activeTopic ? (
                <TopicTitleBlue
                  key={topic.id}
                  onClick={() => handleActiveTopic(topic.id)}
                >
                  {topic.topic}({topic.count})
                </TopicTitleBlue>
              ) : (
                <TopicTitleWhite
                  key={topic.id}
                  onClick={() => handleActiveTopic(topic.id)}
                >
                  {topic.topic}({topic.count})
                </TopicTitleWhite>
              )}
            </>
          );
        })}
      </LeftContainer>
    );
  } else return null;
}
