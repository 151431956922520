import { Button, Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import {
  ButtonBox,
  H1,
  Popup,
  PopupH1,
  PopupHead,
} from '../../../../assets/css/StyledSupport';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function TimerPopup(props) {
  const { dialogState, dialogClose, dialogYesBtn, dialogNoBtn, testFilterId } =
    props;
  return (
    <Dialog open={dialogState} maxWidth="false">
      <Popup>
        <PopupHead>
          <IconButton onClick={dialogClose}>
            <CloseRoundedIcon style={{ fill: '#1093FF', fontSize: '25' }} />
          </IconButton>
        </PopupHead>
        <PopupH1>
          {testFilterId === null
            ? 'Do you want to write this as a timed test?'
            : 'Where would you like to begin the test again?'}
        </PopupH1>
        <ButtonBox>
          <Button
            variant="contained"
            color="primary"
            className={'round-button margin-style-right'}
            onClick={dialogYesBtn}
          >
            {testFilterId === null ? 'Yes' : 'Re-start'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={'round-button margin-style-left'}
            onClick={dialogNoBtn}
          >
            {testFilterId === null ? 'No' : 'Resume'}
          </Button>
        </ButtonBox>
      </Popup>
    </Dialog>
  );
}
export default TimerPopup;
